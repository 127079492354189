import { ref } from "vue";

import constructFetchError from "./constructFetchError";

import axios from "axios";

export function useLocationCharges() {
    const locationCharges = ref(null);
    const totalRecords = ref(null);

    const getLocationCharges = async (location, params) => {
        try {
            const getLocationChargesResponse = await axios.get(route('api.locations.location-charges.index', {
                location: location,
                ...params
            }));
            locationCharges.value = getLocationChargesResponse.data.data;
            totalRecords.value = getLocationChargesResponse.data.meta?.total || locationCharges.value.length;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching location charges.');
        }
    };

    const postLocationCharge = async (location, locationCharge) => {
        try {
            const postLocationChargeResponse = await axios.post(route('api.locations.location-charges.store', {
                location: location
            }), locationCharge);
            return postLocationChargeResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while saving location charge.');
        }
    };
    
    return { locationCharges, totalRecords, getLocationCharges, postLocationCharge };
}
