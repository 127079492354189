import { ref } from "vue";

import constructFetchError from "./constructFetchError";

import axios from 'axios';

export function useEmployeeCommissions() {
    const employeeCommissions = ref(null);
    const employeeCommissionsFromOtherStore = ref(null);
    const locationsNotInCommission = ref(null);
    const actualCommission = ref(null);

    const getEmployeeCommissions = async (employeeId, startTimePeriod, endTimePeriod, location) => {
        try {
            const getEmployeeCommissionsResponse = await axios.get(route('api.employees.commissions.index', {
                employee: employeeId,
                LOCATION: location,
            }), {
                params: {
                    START_TIME_PERIOD: startTimePeriod,
                    END_TIME_PERIOD: endTimePeriod,
                }
            });
            employeeCommissions.value = getEmployeeCommissionsResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching employee commissions.');
        }
    };

    const getEmployeeCommissionsFromOtherStore = async (employeeId, startTimePeriod, endTimePeriod, location) => {
        try {
            const getEmployeeCommissionsFromOtherStoreResponse = await axios.get(route('api.employees.commissions.from-other-store.index', {
                employee: employeeId,
                LOCATION: location,
            }), {
                params: {
                    START_TIME_PERIOD: startTimePeriod,
                    END_TIME_PERIOD: endTimePeriod,
                }
            });
            employeeCommissionsFromOtherStore.value = getEmployeeCommissionsFromOtherStoreResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching employee commissions from other store.');
        }
    };

    const getLocationsNotInCommission = async (employeeId, startTimePeriod, endTimePeriod, location) => {
        try {
            const getLocationsNotInCommissionResponse = await axios.get(
                route('api.employees.commissions.locations-not-in-commission.index', {
                    employee: employeeId,
                    LOCATION: location,
                }), {
                    params: {
                        START_TIME_PERIOD: startTimePeriod,
                        END_TIME_PERIOD: endTimePeriod,
                    }
                });
            locationsNotInCommission.value = getLocationsNotInCommissionResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching locations not in commission.');
        }
    };

    const getActualCommission = async (employeeId, startDate, endDate, location) => {
        try {
            const getActualCommissionResponse = await axios.get(route('api.employees.actual-commissions.show', {
                employee: employeeId,
                LOCATION: location,
                START_DATE: startDate,
                END_DATE: endDate,
            }));
            actualCommission.value = getActualCommissionResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching actual commission.');
        }
    };

    const putActualCommission = async (employeeId, startDate, endDate, location, commission) => {
        try {
            const putActualCommissionResponse = await axios.put(route('api.employees.actual-commissions.update', {
                employee: employeeId,
                LOCATION: location,
                START_DATE: startDate,
                END_DATE: endDate,
            }), commission);
            return putActualCommissionResponse;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while updating actual commission.');
        }
    };
    
    return { employeeCommissions, getEmployeeCommissions,
        employeeCommissionsFromOtherStore, getEmployeeCommissionsFromOtherStore,
        locationsNotInCommission, getLocationsNotInCommission,
        actualCommission, getActualCommission,
        putActualCommission };
}
