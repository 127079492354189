<template>
    <Card>
        <template #header>
            Select Style
        </template>
        <DataTable :loading="isLoading" lazy :value="styles" :total-records="totalRecords"
            paginator :rows="10" @page="e => { onPage(e); fetchStyles(); }"
            filter-display="row" v-model:filters="filters" @filter="tableOnFilter"
            sort-mode="multiple" @sort="e => { onSort(e); fetchStyles(); }"
            v-model:selection="style" selection-mode="single"
            :pt="{ table: { class: 'table table-bordered table-hover' } }">
            <Column field="STYLE" header="Style" sortable
                :pt="{ filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                    <Button type="button" class="btn btn-primary" @click="filterCallback()">
                        Search
                    </Button>
                </template>
            </Column>
            <template #empty>
                <div class="text-center">
                    <span v-if="!location">Please choose a location.</span>
                    <span v-else>No data.</span>
                </div>
            </template>
        </DataTable>
        <template #footer>
            <div class="text-end">
                <button type="button" class="btn btn-primary me-1 mb-1"
                    :disabled="!location"
                    @click="_ => { generateCurrentInventoryReport('current-inventory-detail-report'); }">
                    Current Inventory (Detail)
                </button>
                <button type="button" class="btn btn-primary me-1 mb-1"
                    :disabled="!location"
                    @click="_ => { generateCurrentInventoryReport('current-inventory-summary-report'); }">
                    Current Inventory (Summary)
                </button>
                <button type="button" class="btn btn-primary me-1 mb-1"
                    :disabled="!location"
                    @click="_ => { generateCurrentInventoryReport('latest-prices-summary-report'); }">
                    Latest Prices (Summary)
                </button>
            </div>
        </template>
    </Card>
</template>

<script setup>
import { ref, watch } from 'vue';

import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useStyles } from '@/composables/data/styles';

import Card from '@/components/utils/Card.vue';

import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

const props = defineProps({
    location: String
});

const emit = defineEmits(['error']);

const { params, filters, onPage, onFilter, onSort } = useDataTableParams(['STYLE']);

const { styles, totalRecords, getStylesInLocation } = useStyles();

const style = ref(null);

const isLoading = ref(false);

const fetchTimeout = ref(null);

async function fetchStyles() {
    styles.value = null;
    if(!props.location)
        return;

    isLoading.value = true;
    try {
        await getStylesInLocation(props.location, params.value);
    } catch(e) {
        emit('error', e.message);
    }
    isLoading.value = false;
}

function tableOnFilter(e) {
    onFilter(e);
    style.value = null;
    if(fetchTimeout.value)
        clearTimeout(fetchTimeout.value);
    fetchTimeout.value = setTimeout(fetchStyles, 300);
}

watch(() => props.location, fetchStyles);

function generateCurrentInventoryReport(reportResource) {
    window.open(route(`api.locations.${reportResource}.show`, {
        location: props.location,
        STYLE: style.value?.STYLE
    }));
}
</script>
