<template>
    <div v-if="isOpen" class="pop-up d-flex justify-content-center align-items-center">
        <div class="container w-50 bg-light p-3 rounded">
            <div class="d-flex justify-content-end">
                <button type="button" class="btn-close" @click="onClose"></button>
            </div>
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['isOpen', 'onClose'],
    }
</script>

<style scoped>
.pop-up {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: rgb(0, 0, 0, 0.5);
}

.container {
    height: max-content;
}

section {
    padding: 1rem;
    background-color: #f9fafb;
    border-radius: 0.5rem;
    border: 2px solid #d1d5db;
}
</style>
