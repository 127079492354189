<template>
    <div v-if="isOpen" class="pop-up d-flex justify-content-center align-items-center">
        <Card>
            <template #header>
                <div class="d-flex justify-content-between pt-2">
                    <h4 class="title">Create/Update Raw Order</h4>
                    <button type="button" class="btn-close" @click="emit('close')"></button>
                </div>
            </template>
            <FormInput type="text" v-model="form.ORDER_ID" disabled
                label="Order ID" id-prefix="orderId"
                class="mb-3" />
            <FormInput type="date" v-model="form.DATE_ORDERED"
                label="Date Ordered" id-prefix="dateOrdered"
                :errors="errors.DATE_ORDERED"
                class="mb-3" />
            <FormInput type="text" v-model="form.SUPPLIER_ID" disabled
                label="Supplier" id-prefix="supplier"
                :errors="errors.SUPPLIER_ID"
                class="mb-1"
                :form-text="selectedRelationshipsDisplay.supplier?.SUPPLIER_NAME" />
            <div class="text-end mb-3">
                <button type="button" class="btn btn-sm btn-primary"
                    @click="_ => { isSelectOrderSupplierPopupOpen = true; }">
                    Select
                </button>
            </div>
            <FormInput type="text" v-model="form.STYLE"
                label="Style" id-prefix="style"
                :errors="errors.STYLE"
                class="mb-3" />
            <FormInput type="text" v-model="form.ORDER_PO_ID"
                label="Order PO ID" id-prefix="orderPOID"
                :errors="errors.ORDER_PO_ID"
                class="mb-1"
                :form-text="selectedRelationshipsDisplay.purchase_order?.PURCHASE_ORDER_NUMBER" />
            <div class="text-end mb-3">
                <button type="button" class="btn btn-sm btn-primary"
                    @click="_ => { isSelectPurchaseOrderPopupOpen = true; }">
                    Select
                </button>
            </div>
            <FormInput type="number" step=".01" v-model="form.UNIT_PRICE"
                label="Unit Price" id-prefix="unitPrice"
                :errors="errors.UNIT_PRICE"
                class="mb-3" />
            <FormInput type="number" v-model="form.QUANTITY"
                label="Quantity" id-prefix="quantity"
                :errors="errors.QUANTITY"
                class="mb-3" />
            <div v-if="rawOrder">
                <label>Match: </label>
                <span v-if="rawOrder.QUANTITY == rawOrder.BREAKDOWNS_SUM_QUANTITY" class="badge bg-success ms-2">
                    Match
                </span>
                <span v-else class="badge bg-danger ms-2">
                    Not Match
                </span>
            </div>
            <template #footer>
                <div class="text-end">
                    <button type="button" class="btn btn-primary me-2"
                        :disabled="loadingFlagStore.has('insertRawOrder') || loadingFlagStore.has('updateRawOrder')"
                        @click="_ => {
                            if(!rawOrder) {
                                insertRawOrder();
                            } else {
                                updateRawOrder();
                            }
                        }">
                        <span v-if="loadingFlagStore.has('insertRawOrder') || loadingFlagStore.has('updateRawOrder')"
                            class="spinner-border spinner-border-sm"
                            role="status" aria-hidden="true"></span>
                        Save
                    </button>
                    <button type="button" class="btn btn-outline-secondary"
                        @click="reset">
                        Clear
                    </button>
                </div>
            </template>
        </Card>
    </div>

    <SelectOrderSupplierPopup :is-open="isSelectOrderSupplierPopupOpen"
        @close="_ => { isSelectOrderSupplierPopupOpen = false; }"
        @select="selectedOrderSupplier => {
            form.SUPPLIER_ID = selectedOrderSupplier.SUPPLIER_ID;
            selectedRelationshipsDisplay.supplier = selectedOrderSupplier;
            isSelectOrderSupplierPopupOpen = false;
        }" />

    <SelectPurchaseOrderPopup :is-open="isSelectPurchaseOrderPopupOpen"
        @close="_ => { isSelectPurchaseOrderPopupOpen = false; }"
        @select="selectedPurchaseOrder => {
            form.ORDER_PO_ID = selectedPurchaseOrder.ORDER_PO_ID;
            selectedRelationshipsDisplay.purchase_order = selectedPurchaseOrder;
            isSelectPurchaseOrderPopupOpen = false;
        }" />
</template>

<script setup>
import { inject, ref, watch } from 'vue';

import { useRawOrders } from '@/composables/data/rawOrders';
import { useToastsStore } from '@/stores/toasts';

import Card from '@/components/utils/Card.vue';
import FormInput from '@/components/utils/FormInput.vue';
import SelectPurchaseOrderPopup from '@/components/utils/popups/SelectPurchaseOrderPopup.vue';
import SelectOrderSupplierPopup from '@/components/utils/popups/SelectOrderSupplierPopup.vue';

const props = defineProps({
    purchaseOrder: Object,
    isOpen: Boolean,
});

const emit = defineEmits(['success', 'close']);

const toasts = useToastsStore();

const { postRawOrder, putRawOrder } = useRawOrders();

const rawOrder = defineModel();

const form = ref({
    ORDER_ID: null,
    DATE_ORDERED: null,
    SUPPLIER_ID: null,
    STYLE: null,
    ORDER_PO_ID: null,
    UNIT_PRICE: null,
    QUANTITY: null,
});

const selectedRelationshipsDisplay = ref({});

const errors = ref({});

const isSelectOrderSupplierPopupOpen = ref(false);
const isSelectPurchaseOrderPopupOpen = ref(false);

const loadingFlagStore = inject('loadingFlagStore');

watch(rawOrder, () => {
    form.value = Object.assign({
        ORDER_ID: null,
        DATE_ORDERED: null,
        SUPPLIER_ID: null,
        STYLE: null,
        ORDER_PO_ID: props.purchaseOrder?.ORDER_PO_ID,
        UNIT_PRICE: null,
        QUANTITY: null,
    }, rawOrder.value);
    selectedRelationshipsDisplay.value.supplier = rawOrder.value?.supplier;
    selectedRelationshipsDisplay.value.purchase_order = rawOrder.value?.purchase_order ?? props.purchaseOrder;
    errors.value = {};
});

watch(() => props.purchaseOrder, () => {
    if(!rawOrder.value) {
        form.value.ORDER_PO_ID = props.purchaseOrder?.ORDER_PO_ID;
        selectedRelationshipsDisplay.value.purchase_order = props.purchaseOrder;
    }
});

function reset() {
    rawOrder.value = null;
    if(!rawOrder.value) {
        form.value = {
            ORDER_ID: null,
            DATE_ORDERED: null,
            SUPPLIER_ID: null,
            STYLE: null,
            ORDER_PO_ID: props.purchaseOrder?.ORDER_PO_ID,
            UNIT_PRICE: null,
            QUANTITY: null,
        };
        selectedRelationshipsDisplay.value.purchase_order = props.purchaseOrder;
    }
    errors.value = {};
}

async function insertRawOrder() {
    errors.value = {};

    loadingFlagStore.value.add('insertRawOrder');
    try {
        const newRawOrder = await postRawOrder(form.value);
        toasts.add('SUCCESS', 'Success', 'Successfully saved raw order');
        rawOrder.value = newRawOrder;
        emit('success');
    } catch(e) {
        errors.value = e.errors ?? {};
        toasts.add('ERROR', 'Error', e.message);
    }
    loadingFlagStore.value.delete('insertRawOrder');
}

async function updateRawOrder() {
    errors.value = {};

    loadingFlagStore.value.add('updateRawOrder');
    try {
        const newRawOrder = await putRawOrder(rawOrder.value.ORDER_ID, form.value);
        toasts.add('SUCCESS', 'Success', 'Successfully updated raw order');
        rawOrder.value = newRawOrder;
        emit('success');
    } catch(e) {
        errors.value = e.errors ?? {};
        toasts.add('ERROR', 'Error', e.message);
    }
    loadingFlagStore.value.delete('updateRawOrder');
}
</script>

<style scoped>
.pop-up {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: rgb(0, 0, 0, 0.5);
}

.pop-up .card {
    width: 50%;
}

@media (max-width: 1199.98px) {
    .pop-up .card {
        width: 70%;
    }
}

@media (max-width: 991.98px) {
    .pop-up .card {
        width: 85%;
    }
}

@media (max-width: 767.98px) {
    .pop-up .card {
        width: 100%;
    }
}
</style>
