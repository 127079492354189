<template>
    <Card>
        <template #header>
            {{ title }}
        </template>
        <DataTable :value="barcodes"
            paginator :rows="10"
            filter-display="row" v-model:filters="filters"
            sort-mode="multiple"
            :pt="{ table: { class: 'table table-bordered table-hover' } }">
            <Column field="RAW" header="Raw" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
                bodyCell: { class: 'barcode'} }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value"
                        @input="filterCallback"
                        class="form-control" placeholder="Search" />
                    <Button type="button" class="btn btn-primary"
                        @click="filterCallback">
                        Search
                    </Button>
                </template>
            </Column>
            <template #footer>
                There are {{ barcodes?.length ?? 0 }} records.
            </template>
            <template #empty>
                <div class="text-center">
                    No data.
                </div>
            </template>
        </DataTable>
    </Card>
</template>

<script setup>
import { useDataTableParams } from '@/composables/data/dataTableParams';

import Card from '@/components/utils/Card';

import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

const props = defineProps({
    barcodes: Array,
    title: String,
});

const { filters } = useDataTableParams(['RAW']);
</script>
