import { ref } from "vue";

import constructFetchError from "./constructFetchError";

import axios from 'axios';

export function useReconRawFulls() {
    const reconRawFullsNotInStore = ref(null);
    const reconRawFullsNotInStoreTotalRecords = ref(null);

    const reconRawFullsInStore = ref(null);
    const reconRawFullsInStoreTotalRecords = ref(null);

    const reconRawFullsInStoreNotInInvCheck = ref(null);
    const reconRawFullsInStoreNotInInvCheckTotalRecords = ref(null);

    const getReconRawFullsNotInStore = async (timePeriod, location, style, params) => {
        try {
            const getReconRawFullsResponse = await axios.get(route('api.recon-raw-fulls.not-in-store.index', {
                TIME_PERIOD: timePeriod,
                LOCATION: location,
                STYLE: style,
                ...params,
            }));
            reconRawFullsNotInStore.value = getReconRawFullsResponse.data.data;
            reconRawFullsNotInStoreTotalRecords.value = getReconRawFullsResponse.data.meta.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching recon data.');
        }
    };

    const getReconRawFullsInStore = async (timePeriod, location, inventoryDone, style, params) => {
        try {
            const getReconRawFullsResponse = await axios.get(route('api.recon-raw-fulls.in-store-and-in-inventory-check.index', {
                TIME_PERIOD: timePeriod,
                LOCATION: location,
                INVENTORY_DONE: inventoryDone,
                STYLE: style,
                ...params,
            }));
            reconRawFullsInStore.value = getReconRawFullsResponse.data.data;
            reconRawFullsInStoreTotalRecords.value = getReconRawFullsResponse.data.meta.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching recon data.');
        }
    };

    const getReconRawFullsInStoreNotInInvCheck = async (timePeriod, location, inventoryDone, style, params) => {
        try {
            const getReconRawFullsResponse = await axios.get(route('api.recon-raw-fulls.in-store-but-not-in-inventory-check.index', {
                TIME_PERIOD: timePeriod,
                LOCATION: location,
                INVENTORY_DONE: inventoryDone,
                STYLE: style,
                ...params,
            }));
            reconRawFullsInStoreNotInInvCheck.value = getReconRawFullsResponse.data.data;
            reconRawFullsInStoreNotInInvCheckTotalRecords.value = getReconRawFullsResponse.data.meta.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching recon data.');
        }
    };
    
    return { reconRawFullsNotInStore, reconRawFullsNotInStoreTotalRecords, getReconRawFullsNotInStore,
        reconRawFullsInStore, reconRawFullsInStoreTotalRecords, getReconRawFullsInStore,
        reconRawFullsInStoreNotInInvCheck, reconRawFullsInStoreNotInInvCheckTotalRecords, getReconRawFullsInStoreNotInInvCheck };
}
