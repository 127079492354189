<template>
    <div class="row g-3 mb-3">
        <div class="col-md-4">
            <ReconInfoGenerationsTableCard />
        </div>
        <div class="col-md-8">
            <ReconItemsResolvedTableCard :refresh-flag="reconItemsResolvedTableRefreshFlag"
                v-model="selectedReconItemResolved" />
        </div>
    </div>
    <div class="row g-3 mb-3">
        <div class="col-md-4">
            <ReconItemsNotFoundInMainTableCard v-model="selectedReconItemNotFoundInMain"
                :refresh-flag="reconItemsNotFoundInMainTableRefreshFlag" />
        </div>
        <div class="col-md-4">
            <ReconItemsLatestLocationNotInMainTableCard v-model="selectedReconItemNotInMain"
                :refresh-flag="reconItemsLatestLocationNotInMainTableRefreshFlag">
                <template #footer>
                    <div class="text-end">
                        <button type="button" class="btn btn-primary"
                            @click="openReturnCommentPopup">
                            Return (2) to MAIN_1
                        </button>
                    </div>
                </template>
            </ReconItemsLatestLocationNotInMainTableCard>
        </div>
        <div class="col-md-4">
            <ReconItemsReplacementTableCard v-model="selectedReconItemReplacement"
                :refresh-flag="reconItemsReplacementTableRefreshFlag">
                <template #footer>
                    <div class="text-end">
                        <button type="button" class="btn btn-primary"
                            @click="replaceRRWithOriginalBarcode">
                            Found RR, update (3) with (1)
                        </button>
                    </div>
                </template>
            </ReconItemsReplacementTableCard>
        </div>
    </div>
    <div class="row g-3">
        <div class="col-md-6">
            <InventoryRecordsOfSelectedBarcodeTableCard
                :style="barcodeToDisplay.style"
                :date="barcodeToDisplay.date"
                :serial="barcodeToDisplay.serial"
                :refresh-flag="barcodeLogsRefreshFlag" />
        </div>
        <div class="col-md-6">
            <InventoryChecksOfSelectedBarcodeTableCard
                :barcode="barcodeToDisplayConstructed"
                :refresh-flag="barcodeLogsRefreshFlag" />
        </div>
    </div>

    <SelectCommentForReturningBarcodeToMainWarehousePopup :is-open="isSelectCommentPopupOpen"
        @close="_ => { isSelectCommentPopupOpen = false; }"
        @select="returnBarcodeToMainWarehouse" />
</template>

<script setup>
import { computed, ref, watch } from 'vue';

import { useLoadingFlagsStore } from '@/stores/loadingFlags';
import { useToastsStore } from '@/stores/toasts';
import { useReconMainWarehouseActions } from '@/composables/data/reconMainWarehouseActions';

import Card from '@/components/utils/Card.vue';
import ReconInfoGenerationsTableCard from './ReconInfoGenerationsTableCard.vue';
import ReconItemsNotFoundInMainTableCard from './ReconItemsNotFoundInMainTableCard.vue';
import ReconItemsLatestLocationNotInMainTableCard from './ReconItemsLatestLocationNotInMainTableCard.vue';
import ReconItemsReplacementTableCard from './ReconItemsReplacementTableCard.vue';
import InventoryRecordsOfSelectedBarcodeTableCard from './InventoryRecordsOfSelectedBarcodeTableCard.vue';
import InventoryChecksOfSelectedBarcodeTableCard from './InventoryChecksOfSelectedBarcodeTableCard.vue';
import SelectCommentForReturningBarcodeToMainWarehousePopup from './SelectCommentForReturningBarcodeToMainWarehousePopup.vue';

import { constructBarcode, deconstructBarcode } from '@/utils/barcodes';
import ReconItemsResolvedTableCard from './ReconItemsResolvedTableCard.vue';

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const {
    postRRToOriginalReplacement,
    postMainWarehouseReturn,
} = useReconMainWarehouseActions();

const selectedReconItemResolved = ref(null);

const selectedReconItemNotFoundInMain = ref(null);
const selectedReconItemNotInMain = ref(null);
const selectedReconItemReplacement = ref(null);

const reconItemsResolvedTableRefreshFlag = ref(false);
const reconItemsNotFoundInMainTableRefreshFlag = ref(false);
const reconItemsLatestLocationNotInMainTableRefreshFlag = ref(false);
const reconItemsReplacementTableRefreshFlag = ref(false);

const barcodeLogsRefreshFlag = ref(false);

const barcodeToDisplay = ref({
    style: null,
    date: null,
    serial: null,
});

const isSelectCommentPopupOpen = ref(false);

const barcodeToDisplayConstructed = computed(() => {
    try {
        return constructBarcode(
            barcodeToDisplay.value.style,
            barcodeToDisplay.value.date,
            barcodeToDisplay.value.serial
        );
    } catch (e) {
        return null;
    }
});

watch(selectedReconItemResolved, () => {
    barcodeToDisplay.value = {
        style: selectedReconItemResolved.value?.STYLE,
        date: selectedReconItemResolved.value?.DATE,
        serial: selectedReconItemResolved.value?.SERIAL,
    };
});

watch(selectedReconItemNotFoundInMain, () => {
    barcodeToDisplay.value = {
        style: selectedReconItemNotFoundInMain.value?.STYLE,
        date: selectedReconItemNotFoundInMain.value?.DATE,
        serial: selectedReconItemNotFoundInMain.value?.SERIAL,
    };
});

watch(selectedReconItemNotInMain, () => {
    if (selectedReconItemNotInMain.value) {
        barcodeToDisplay.value = deconstructBarcode(selectedReconItemNotInMain.value?.RAW);
    } else {
        barcodeToDisplay.value = {
            style: null,
            date: null,
            serial: null,
        };
    }
});

watch(selectedReconItemReplacement, () => {
    barcodeToDisplay.value = {
        style: selectedReconItemReplacement.value?.STYLE,
        date: selectedReconItemReplacement.value?.DATE,
        serial: selectedReconItemReplacement.value?.SERIAL,
    };
});

async function replaceRRWithOriginalBarcode() {
    if (!selectedReconItemNotFoundInMain.value) {
        toasts.add('ERROR', 'Error', 'Please select a barcode from Table (1).');
        return;
    }

    if (!selectedReconItemReplacement.value) {
        toasts.add('ERROR', 'Error', 'Please select a RR barcode from Table (3).');
        return;
    }

    const originalBarcode = constructBarcode(
        selectedReconItemNotFoundInMain.value.STYLE,
        selectedReconItemNotFoundInMain.value.DATE,
        selectedReconItemNotFoundInMain.value.SERIAL,
    );
    
    const replacementBarcode = constructBarcode(
        selectedReconItemReplacement.value.STYLE,
        selectedReconItemReplacement.value.DATE,
        selectedReconItemReplacement.value.SERIAL,
    );

    if (!window.confirm(`This action will replace RR ${replacementBarcode} with ${originalBarcode}. Proceed?`)) {
        return;
    }

    loadingFlags.add('replaceRRWithOriginalBarcode');
    try {

        await postRRToOriginalReplacement(originalBarcode, replacementBarcode);
        toasts.add('SUCCESS', 'Success', `Successfully replaced RR ${replacementBarcode} with ${originalBarcode}.`);
    
        reconItemsResolvedTableRefreshFlag.value = !reconItemsResolvedTableRefreshFlag.value;
        reconItemsNotFoundInMainTableRefreshFlag.value = !reconItemsNotFoundInMainTableRefreshFlag.value;
        reconItemsReplacementTableRefreshFlag.value = !reconItemsReplacementTableRefreshFlag.value;
        barcodeLogsRefreshFlag.value = !barcodeLogsRefreshFlag.value;
    } catch (e) {
        toasts.add('ERROR', 'Error', e.message);
    }
    loadingFlags.delete('replaceRRWithOriginalBarcode');
}

function openReturnCommentPopup() {
    if (!selectedReconItemNotInMain.value) {
        toasts.add('ERROR', 'Error', 'Please select a barcode from Table (2).');
        return;
    }

    isSelectCommentPopupOpen.value = true;
}

async function returnBarcodeToMainWarehouse(comment) {
    isSelectCommentPopupOpen.value = false;
    loadingFlags.add('returnBarcodeToMainWarehouse');
    try {
        await postMainWarehouseReturn(selectedReconItemNotInMain.value.RAW, comment.COMMENT_ID);
        toasts.add('SUCCESS', 'Success', `Successfully returned barcode ${selectedReconItemNotInMain.value.RAW}.`);

        reconItemsResolvedTableRefreshFlag.value = !reconItemsResolvedTableRefreshFlag.value;
        reconItemsLatestLocationNotInMainTableRefreshFlag.value = !reconItemsLatestLocationNotInMainTableRefreshFlag.value;
        barcodeLogsRefreshFlag.value = !barcodeLogsRefreshFlag.value;
    } catch (e) {
        toasts.add('ERROR', 'Error', e.message);
    }
    loadingFlags.delete('returnBarcodeToMainWarehouse');
}
</script>
