<template>
    <Card>
        <div class="row g-3">
            <div class="col-7">
                <Card>
                    <template #header>
                        Select a Location
                    </template>
                    <LocationTable v-model="location"
                        @error="message => {
                            addFlashMessage('ERROR', message);
                            scrollToTop();
                        }" />
                </Card>
            </div>
            <div class="col-5 py-5 px-3">
                <FormSelect label="Start Date:" v-model="startDate"
                    :options="timePeriods"
                    :option-to-string="timePeriod => timePeriod.START_DATE"
                    :option-value="timePeriod => timePeriod.START_DATE"
                    class="mb-3"
                    select-class="form-select-sm"
                    id-prefix="startDate"
                    :errors="errors.START_DATE"/>
                <FormSelect label="End Date:" v-model="endDate"
                    :options="timePeriods"
                    :option-to-string="timePeriod => timePeriod.END_DATE"
                    :option-value="timePeriod => timePeriod.END_DATE"
                    class="mb-3"
                    select-class="form-select-sm"
                    id-prefix="endDate"
                    :errors="errors.END_DATE"/>
                <div class="d-flex mt-4">
                    <div class="p-1 w-50">
                        <button class="btn btn-primary w-100"
                            :disabled="!location || !startDate || !endDate"
                            @click="setReportType('DELIVERY')">Delivery Reports</button>
                    </div>
                    <div class="p-1 w-50">
                        <button class="btn btn-primary w-100"
                            :disabled="!location || !startDate || !endDate"
                            @click="setReportType('PULL-OUT')">Pull-out Reports</button>
                    </div>
                </div>
            </div>
            <div v-if="selectedReportType" class="col-12">
                <Card>
                    <template #header>{{ selectedReportType }} REPORTS</template>
                    <DataTable :loading="isLoading" lazy :value="comments" :total-records="totalRecords"
                        paginator :rows="10" @page="e => { onPage(e); fetchReports(); }"
                        :pt="{ table: { class: 'table table-bordered table-hover' } }">
                        <Column field="COMMENT_ID" header="COMMENT_ID"/>
                        <Column field="COMMENT_RPT_DATE_ORIG" header="COMMENT_RPT_DATE_ORIG"/>
                        <Column field="COMMENT_RPT_DATE" header="COMMENT_RPT_DATE"/>
                        <Column field="COMMENT_TEXT" header="COMMENT_TEXT"/>
                        <Column v-if="selectedReportType === 'PULL-OUT'" field="PREV_LOC" header="PREV_LOC"/>
                        <Column field="QTY" header="QTY"/>
                        <Column field="TOTAL_AMOUNT" header="TOTAL_AMOUNT"/>
                        <Column field="CREATED_BY" header="CREATED_BY"/>
                        <template #empty>No data.</template>
                    </DataTable>
                </Card>
            </div>
        </div>
    </Card>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import Card from '@/components/utils/Card.vue';
import LocationTable from '@/components/utils/tables/LocationTable';
import FormSelect from '@/components/utils/FormSelect.vue';

import handleFetchErrors from "@/utils/handleFetchErrors";
import scrollToTop from "@/utils/scrollToTop";

import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useTimePeriods } from '@/composables/data/timePeriods';
import { useComments } from '@/composables/data/comments';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';

const startDate = ref(null);
const endDate = ref(null);
const location = ref(null);
const selectedReportType = ref(null);
const isLoading = ref(false);
const errors = ref({});

const { params, onPage } = useDataTableParams([
    'COMMENT_ID', 'COMMENT_RPT_DATE_ORIG',
    'COMMENT_RPT_DATE', 'QTY', 'TOTAL_AMOUNT',
    'COMMENT_TEXT', 'CREATED_BY', 'PREV_LOC'
]);

const { comments, totalRecords, getDeliveryPulloutReports } = useComments();
const { timePeriods, getTimePeriodsLatest } = useTimePeriods();

onMounted(async () => {
    await getTimePeriodsLatest({
        PAYTYPE: 'YEARLY'
    });
})

function setReportType(reportType) {
    selectedReportType.value = reportType;
    fetchReports();
}

async function fetchReports() {
    errors.value = {};
    isLoading.value = true;
    try {
        await getDeliveryPulloutReports(
            startDate.value,
            endDate.value,
            location.value.LOCATION,
            params.value,
            selectedReportType.value
        );
    } catch (e) {
        comments.value = [];
        console.log(e);
        if (e.status == 422) {
            errors.value = e.errors;
        } else {
            handleFetchErrors(e, 'Error while fetching reports.');
            scrollToTop();
        }
    }
    isLoading.value = false;
}
</script>
