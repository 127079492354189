import { ref } from "vue";

import constructFetchError from "./constructFetchError";

import axios from 'axios';

export function useDepartments() {
    const departments = ref(null);

    const getDepartments = async () => {
        try {
            const getDepartmentsResponse = await axios.get(route('api.departments.index'));
            departments.value = getDepartmentsResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching departments.');
        }
    };
    
    return { departments, getDepartments };
}
