<template>
    <Card class="mb-3">
        <template #header>
            Style Robinsons
        </template>
        <form>
            <div v-if="isLoading" class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
            <FormSelect label="Color" v-model="robinsonsGenericSku.COLOR" :options="colors"
                is-horizontal id-prefix="color" class="mb-3"
                select-class="form-select-sm" :errors="errors.COLOR"
                @keydown-enter="focusNextInput('genericRobinsonsSkuFormInput')" />
            <FormInput label="Generic SKU" type="text" v-model="robinsonsGenericSku.ROBINSONS_SKU"
                is-horizontal id-prefix="genericRobinsonsSku" class="mb-3"
                input-class="form-control-sm" :errors="errors.ROBINSONS_SKU" />
            <button type="button" class="btn btn-primary me-1"
                :disabled="!style.STYLE || isLoading || isSubmitting"
                @click="saveButtonOnClick">
                <span v-if="isSubmitting" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Save
            </button>
        </form>
    </Card>
</template>

<script setup>
import { ref, watch } from 'vue';

import Card from '../../../utils/Card.vue';
import FormInput from '../../../utils/FormInput.vue';
import FormSelect from '../../../utils/FormSelect.vue';

import axios from 'axios';

import handleFetchErrors from "../../../../utils/handleFetchErrors";
import scrollToTop from "../../../../utils/scrollToTop";

import { useFlashMessages } from "../../../../composables/flashmessages";

const { addFlashMessage } = useFlashMessages();

const props = defineProps({
    style: Object,
    colors: Array,
    class: String
});

const errors = ref({});

const robinsonsGenericSku = ref({});

const isLoading = ref(false);
const isSubmitting = ref(false);

watch(() => props.style, async () => {
    errors.value = {};
    robinsonsGenericSku.value = {};
    if(props.style?.STYLE == null)
        return;

    isLoading.value = true;
    try {
        const robinsonsGenericSkuResponse = await axios.get(
            route('api.styles.robinsons-generic-sku.index', {
                style: props.style.STYLE
            }));
        robinsonsGenericSku.value = robinsonsGenericSkuResponse.data.data;
    } catch(e) {
        console.log(e);
        robinsonsGenericSku.value = {};
        if(!e.response || e.response.status != 404) {
            handleFetchErrors(e, 'Error while fetching Robinsons Generic SKU.');
            scrollToTop();
        }
    }
    isLoading.value = false;
});

async function saveButtonOnClick() {
    errors.value = {};
    isSubmitting.value = true;
    try {
        const saveRobinsonsSkuGenericResponse = await axios.post(
            route('api.styles.robinsons-generic-sku.store', {
                style: props.style.STYLE
            }), robinsonsGenericSku.value);
        robinsonsGenericSku.value = saveRobinsonsSkuGenericResponse.data.data;
        addFlashMessage('SUCCESS', 'Successfully saved Robinsons SKU ' +
            robinsonsGenericSku.value.ROBINSONS_SKU + '. ');
    } catch(e) {
        console.log(e);
        errors.value = handleFetchErrors(e, 'Error while fetching Robinsons SKU.');
    }
    isSubmitting.value = false;
    scrollToTop();
}
</script>
