<template>
    <div>
        <div
            v-if="isLoading"
            class="position-fixed start-0 end-0 top-0 bottom-0 d-flex justify-content-center align-items-center toast-shadow"
        >
            <div class="spinner-border text-primary" role="status"></div>
        </div>
        <div
            v-if="isToastSuccess"
            class="position-fixed start-0 end-0 top-0 bottom-0 d-flex justify-content-center align-items-center toast-shadow"
        >
            <div class="w-50 toast show bg-success" role="alert" aria-live="assertive" aria-atomic="true">
                <div class="toast-header">
                    <h6 class="me-auto text-black m-0">Success!</h6>
                    <button @click="hideToast()" type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
                    <div class="toast-body text-white p-4 fs-1.4">
                    {{ toastMessage }}
                    </div>
            </div>
        </div>
        <div
            v-if="isToastError"
            class="position-fixed start-0 end-0 top-0 bottom-0 d-flex justify-content-center align-items-center toast-shadow"
        >
            <div class="w-50 toast show bg-danger" role="alert" aria-live="assertive" aria-atomic="true">
                <div class="toast-header">
                    <h6 class="me-auto text-black m-0">Error!</h6>
                    <button @click="hideToast()" type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
                    <div class="toast-body text-white p-4 fs-1.4">
                    {{ toastMessage }}
                    </div>
            </div>
        </div>
        <div class="card p-4 main-card" id="voucher-main-card">
            <section class="w-100 mb-1">
                <div class="d-flex w-100">
                    <div class="d-flex w-50 mb-1 me-1">
                        <div class="dropdownContainer position-relative ms-1 w-25" ref="dropdownContainer">
                            <button
                                @click="isListVisible = !isListVisible"
                                class="dropdown-text text-dark btn btn-primary w-100 d-flex justify-content-between"
                            >
                                <span>{{ glId }}</span>
                                <span><i class="fa-solid fa-angle-down"></i></span>
                            </button>
                            <ul v-if="isListVisible" class="dropdown-list position-absolute top-100 start-0 list-group">
                                <li
                                    v-for="gl in glAccounts"
                                    @click="selectGL(gl)"
                                    class="dropdown list-group-item list-group-item-action d-flex w-100"
                                    :class="{ 'selected-dropdown': gl.GL_ID === glId }"
                                >
                                    <div class="d-flex w-50">
                                        <div class="w-50">
                                            <p class="text-end pe-4 m-0">{{ gl.GL_ID }}</p>
                                        </div>
                                        <div class="w-50">
                                            <p class="m-0">{{ gl.CLASS }}</p>
                                        </div>
                                    </div>
                                    <div class="w-50">
                                        <p class="m-0">{{ gl.ACCTNAME }}</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <select
                            v-model="listType"
                            class="form-select ms-1 w-25"
                            @change="glIdAfterUpdate()"
                        >
                            <option value="ALL">ALL</option>
                            <option value="AJ">AJ</option>
                            <option value="AR">AR</option>
                            <option value="CC">CC</option>
                            <option value="CK">CK</option>
                            <option value="CS">CS</option>
                        </select>
                        <select
                            v-model="clearType"
                            class="form-select ms-1 w-25"
                            @change="glIdAfterUpdate()"
                        >
                            <option value="ALL">ALL</option>
                            <option value="CLEARED ONLY">CLEARED ONLY</option>
                            <option value="NOT CLEARED ONLY">NOT CLEARED ONLY</option>
                        </select>
                    </div>
                    <div class="d-flex justify-content-center w-50 mb-1">
                        <button
                            class="btn btn-primary w-50"
                            @click="generateCheckRegister()"
                        >
                            GENERATE CHECK REGISTER
                        </button>
                    </div>
                </div>
                <div class="d-flex w-50 mb-1">
                    <label class="form-label w-25 my-auto ms-1">VOUCHER START DATE:</label>
                    <input
                        v-model="startDate"
                        @keyup.enter="glIdAfterUpdate()"
                        type="date"
                        class="form-control w-25 ms-1"
                    >
                    <label class="form-label w-25 my-auto ps-5 ms-1">CUR BAL (CLEAR):</label>
                    <input
                        v-model="currentBalanceClear"
                        type="text"
                        class="form-control w-25 ms-1"
                        disabled
                    >
                </div>
                <div class="d-flex">
                    <div class="d-flex w-50 mb-1">
                        <label class="form-label w-25 my-auto ms-1">VOUCHER END DATE:</label>
                        <input
                            v-model="endDate"
                            @keyup.enter="glIdAfterUpdate()"
                            type="date"
                            class="form-control w-25 ms-1"
                        >
                        <label class="form-label w-25 my-auto ps-5 ms-1">CUR BAL (ALL):</label>
                        <input
                            v-model="currentBalanceAll"
                            type="text"
                            class="form-control w-25 ms-1"
                            disabled
                        >
                    </div>
                    <div class="d-flex w-50 mb-1">
                        <button
                            class="btn btn-primary w-25 ms-3"
                            data-bs-toggle="modal"
                            data-bs-target="#printCheckRegisterModal"
                        >
                            PRINT REGISTER
                        </button>
                        <button
                            class="btn btn-primary w-25 ms-3"
                            @click="showVoucher()"
                        >
                            SHOW VOUCHER
                        </button>
                        <button
                            class="btn btn-primary w-25 ms-3"
                            @click="clearCheck()"
                        >
                            CLEAR CHECK
                        </button>
                    </div>
                </div>
            </section>
            <section>
                <div class="table-wrapper mb-3">
                    <table
                        class="table table-sm table-bordered table-striped table-hover"
                    >
                        <thead>
                            <tr>
                                <th scope="col">VOUCHER_DATE</th>
                                <th scope="col">CHECK_NUM</th>
                                <th scope="col">PAYEE_DATE</th>
                                <th scope="col">VOUCHER_ID</th>
                                <th scope="col">SEQ</th>
                                <th scope="col">CLR</th>
                                <th scope="col">AMT</th>
                                <th scope="col">RTOTAL_CLEAR</th>
                                <th scope="col">RTOTAL_ALL</th>
                                <th scope="col">DESCRIPTION</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="ck in checkRegisterList"
                                :class="{ 'selected-row': ck.VOUCHER_ID === selectedId && ck.SEQ === selectedSeq }"
                                @click="selectRow(ck.VOUCHER_ID, ck.SEQ)"
                                class="text-smol"
                            >
                                <td>{{ ck.VOUCHER_DATE }}</td>
                                <td>{{ ck.CHECK_NUM }}</td>
                                <td>{{ ck.PAYEE_DATE }}</td>
                                <td>{{ ck.VOUCHER_ID }}</td>
                                <td>{{ ck.SEQ }}</td>
                                <td>{{ ck.CLR }}</td>
                                <td>{{ ck.AMT }}</td>
                                <td>{{ ck.RTOTAL_CLEAR }}</td>
                                <td>{{ ck.RTOTAL_ALL }}</td>
                                <td>{{ ck.DESCRIPTION }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </div>
        <PrintModal
            :pdf-link="printCheckRegisterPDFLink"
            :spreadsheet-link="printCheckRegisterSpreadsheetLink"
            title="Print Check Register" id="printCheckRegisterModal"
        />
    </div>
</template>

<script setup>
    import { onMounted, ref, onBeforeUnmount, watch, computed } from "vue";
    import PrintModal from '../../utils/PrintModal.vue';

    const startDate = ref('');
    const endDate = ref('');
    const glId = ref(0);
    const glAccounts = ref([]);
    const listType = ref('');
    const listClause = ref('');
    const clearType = ref('');
    const clearClause = ref('');
    const checkRegisterList = ref([]);
    const currentBalanceAll = ref('');
    const currentBalanceClear = ref('');
    const selectedId = ref('');
    const selectedSeq = ref('');

    const isListVisible = ref(false);
    const isLoading = ref(false);
    const isToastSuccess = ref(false);
    const isToastError = ref(false);
    const toastMessage = ref("");

    onMounted(() => {
        initialValues();
        getGLAccounts();
        glIdAfterUpdate();
        listTypeAfterUpdate();
        clearTypeAfterUpdate();
    })

    onMounted(() => {
      document.addEventListener('click', closeList);
    });

    onBeforeUnmount(() => {
      document.removeEventListener('click', closeList);
    });

    watch(listType, () => {
        listTypeAfterUpdate();
    })

    watch(clearType, () => {
        clearTypeAfterUpdate();
    })

    function listTypeAfterUpdate() {
        if (listType.value == 'ALL')
            listClause.value = '';
        else
            listClause.value = ` SUBSTRING(VOUCHER_ID,3,2) = '${listType.value}'`;
    }

    function clearTypeAfterUpdate() {
        switch (clearType.value) {
            case 'ALL':
                clearClause.value = '';
                break;
            case 'CLEARED ONLY':
                clearClause.value = " CHECK_CLEAR = 'Y'";
                break;
            case 'NOT CLEARED ONLY':
                clearClause.value = " CHECK_CLEAR = 'N'";
                break;
            default:
                break;
        }
    }

    function initialValues() {
        startDate.value = '0001-01-01';
        endDate.value = '9999-12-31';
        listType.value = 'ALL';
        clearType.value = 'ALL';
        glId.value = 8;
    }

    async function glIdAfterUpdate() {
        isLoading.value = true;

        try {
            const response = await axios.post(route('api.reconCheckRegister.filter'), {
                START_DATE: startDate.value,
                END_DATE: endDate.value,
                LIST_CLAUSE: listClause.value,
                CLEAR_CLAUSE: clearClause.value,
                GL_ID: glId.value,
            });
            isLoading.value = false;
            if (response.data.length !== 0) {
                checkRegisterList.value = response.data;
                const lastIndex = response.data.length - 1;
                currentBalanceAll.value = response.data[lastIndex].RTOTAL_ALL;
                currentBalanceClear.value = response.data[lastIndex].RTOTAL_CLEAR;
                resetRow();
            }
        } catch (error) {
            isLoading.value = false;
            isToastError.value = true;
            toastMessage.value = error.response.data.message;
        }
    }

    async function getGLAccounts() {
        try {
            const response = await axios.post(route('api.gl.filter'), {
                BANKS: true
            });
            glAccounts.value = response.data[0];
        } catch (error) {
            isToastError.value = true;
            toastMessage.value = error.response.data.message;
        }
    }

    function closeList(event) {
        const dropdownContainer = document.querySelector('.dropdownContainer');
        if (dropdownContainer && !dropdownContainer.contains(event.target)) {
            isListVisible.value = false;
        }
    };

    function selectGL(gl) {
        glId.value = gl.GL_ID;
        isListVisible.value = false;
        glIdAfterUpdate();
    }

    function selectRow(id, seq) {
        selectedId.value = id;
        selectedSeq.value = seq;
    }

    function resetRow() {
        selectedId.value = '';
        selectedSeq.value = '';
    }

    function showVoucher() {
        if (selectedId.value === '') {
            isToastError.value = true;
            toastMessage.value = 'Please select a voucher';
            return;
        }
        const fullUrl = `update-voucher/${selectedId.value}`;
        window.location.href = fullUrl;
    };

    async function clearCheck() {
        if (selectedId.value === '') {
            isToastError.value = true;
            toastMessage.value = 'Please select a voucher';
            return;
        }
        let clearCount = 0;
        let unclearCount = 0;
        try {
            const response = await axios.get(route('api.vouchergl.show', { id:  `${selectedId.value}_${selectedSeq.value}` }));
            if (response.data[0][0].CHECK_CLEAR === 'Y'){
                const result = await updateCheckClear('N');
                if (result.data.message === 'Success')
                    unclearCount += 1;
            } else {
                const result = await updateCheckClear('Y');
                if (result.data.message === 'Success')
                    clearCount += 1;
            }
            isToastSuccess.value = true;
            toastMessage.value = `${clearCount} checks CLEARED; ${unclearCount} checks UNCLEARED`;
        } catch (error) {
            isToastError.value = true;
            toastMessage.value = error.response.data.message;
        }
    }

    async function updateCheckClear(value) {
        try {
            const response = await axios.patch(route('api.vouchergl.update', {id : `${selectedId.value}_${selectedSeq.value}` }), {
                CHECK_CLEAR: value
            });
            return response;
        } catch (error) {
            isToastError.value = true;
            toastMessage.value = error.response.data.message;
        }
    }

    async function generateCheckRegister() {
        try {
            isLoading.value = true;
            const response = await axios.post(route('api.reconCheckRegister.generate'), {
                IS_CHECK: true,
            });
            isLoading.value = false;
            if (response.data[0] === 'Success') {
                isToastSuccess.value = true;
                toastMessage.value = 'CHECK REGISTER generated.';
            } else {
                isToastError.value = true;
                toastMessage.value = 'Failed to generate register.';
            }

        } catch (error) {
            isLoading.value = false;
            isToastError.value = true;
            toastMessage.value = error.response.data.message;
        }
    }

    const printCheckRegisterPDFLink = computed(() => {
        return route('print.checkRegister', {
            START_DATE: startDate.value,
            END_DATE: endDate.value,
            LIST_CLAUSE: listClause.value,
            CLEAR_CLAUSE: clearClause.value,
            GL_ID: glId.value,
            IS_REPORT: true
        });
    });

    const printCheckRegisterSpreadsheetLink = computed(() => {
        return route('api.vouchers.checkregister-spreadsheet', {
            START_DATE: startDate.value,
            END_DATE: endDate.value,
            LIST_CLAUSE: listClause.value,
            CLEAR_CLAUSE: clearClause.value,
            GL_ID: glId.value,
            IS_REPORT: true
        });
    });

    function hideToast() {
        isToastError.value = false;
        isToastSuccess.value = false;
    }
</script>

<style scoped>
section {
    padding: 1rem;
    background-color: #f9fafb;
    border-radius: 0.5rem;
    border: 2px solid #d1d5db;
}

.main-card {
    display: flex;
    column-gap: 0.5rem;
    width: 100%;
    flex-wrap: wrap;
}

.table-wrapper {
    width: 100%;
    height: 30rem;
    overflow: auto;
    background-color: #FFF;
}

thead {
    top: 0;
    margin: 0;
    position: sticky;
    background-color: white;
    backdrop-filter: blur(100px);
}

th {
    backdrop-filter: blur(100px);
    font-size: 0.75rem;
}

td {
    max-width: 30vw;
    font-size: 0.8rem;
}

td:hover {
    cursor: pointer;
}
.selected-row {
    background-color: lightslategrey;
}

.dropdown {
    cursor: pointer;
    padding: 3px;
}

.dropdown:hover {
    background-color: #0d6efd;
    color: white;
}

.selected-dropdown {
    background-color: #0d6efd;
    color: white;
}

.dropdown-list {
    z-index: 1;
    width: 250%;
    height: 20rem;
    overflow-y: auto;
}

.dropdown-text {
    background-color: #F9FAFB;
    border: 1px solid #CED4DA;
}

.toast-shadow {
    padding: 3;
    z-index: 11;
    background: rgb(0, 0, 0, 0.5);
}
</style>
