import { ref } from "vue";

export function useDepartments() {
  const departments = ref([]);

  const fetchData = async () => {
    try {
      const response = await fetch(route('api.departments.index'));
      const data = await response.json();
      departments.value = data.data;
    } catch (error) {
      console.log(error);
    }
  };

  fetchData();

  return { departments };
}
