<template>
    <Card :class="class">
        <template #header>
            Location Keys
        </template>
        <DataTable :loading="isLoading" lazy :value="locationKeys" :total-records="totalRecords"
            paginator :rows="10" @page="e => { onPage(e); fetchLocationKeys(); }"
            filter-display="row" v-model:filters="filters" @filter="tableOnFilter"
            sort-mode="multiple" @sort="e => { onSort(e); fetchLocationKeys(); }"
            v-model:selection="selectedLocationKey" selection-mode="single"
            :pt="{ table: { class: 'table table-bordered table-hover' } }">
            <Column field="LOCATION_KEY" header="Location Key" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value"
                        @input="filterCallback"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <template #empty>
                <div class="text-center">
                    <template v-if="!location">Please choose a location.</template>
                    <template v-else>No data.</template>
                </div>
            </template>
        </DataTable>
        <template #footer>
            <div class="text-end">
                <button type="button" class="btn btn-outline-danger"
                    :disabled="!selectedLocationKey || isDeleting"
                    @click="destroyLocationKey">
                    <span v-if="isDeleting" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Delete Selected
                </button>
            </div>
        </template>
    </Card>
</template>

<script setup>
import { ref, watch } from 'vue';

import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useLocationKeys } from '@/composables/data/locationKeys';

import Card from '@/components/utils/Card.vue';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

const props = defineProps({
    location: String,
    refreshFlag: null,
    class: String,
});

const emit = defineEmits(['delete-success', 'error']);

const { params, filters, onPage, onFilter, onSort } = useDataTableParams(
    ['LOCATION_KEY']
);

const { locationKeys, totalRecords, getLocationKeys, deleteLocationKey } = useLocationKeys();

const selectedLocationKey = defineModel();

const isLoading = ref(false);
const isDeleting = ref(false);

const fetchTimeout = ref(null);

async function fetchLocationKeys() {
    locationKeys.value = null;

    if(!props.location)
        return;

    isLoading.value = true;
    try {
        await getLocationKeys(props.location);
    } catch(e) {
        emit('error', e.messsage);
    }
    isLoading.value = false;
}

function tableOnFilter(e) {
    onFilter(e);
    if (fetchTimeout.value)
        clearTimeout(fetchTimeout.value);
    fetchTimeout.value = setTimeout(fetchLocationKeys, 300);
}

watch(() => props.location, fetchLocationKeys);
watch(() => props.refreshFlag, fetchLocationKeys);

async function destroyLocationKey() {
    if(window.prompt('Type DELETE to proceed') != 'DELETE')
        return;

    isDeleting.value = true;
    try {
        await deleteLocationKey(props.location, selectedLocationKey.value.id);
        emit('delete-success', 'Successfully deleted location key.');
        fetchLocationKeys();
    } catch(e) {
        emit('error', e.message);
    }
    isDeleting.value = false;
}
</script>
