<template>
    <div v-if="isOpen" class="pop-up d-flex justify-content-center align-items-center">
        <Card>
            <template #header>
                <div class="d-flex justify-content-between pt-2">
                    <h4 class="title">Update Location Store Code</h4>
                    <button type="button" class="btn-close" @click="close"></button>
                </div>
            </template>
            <div class="row">
                <div class="col-6">
                    <LocationSelectTableCard v-model="selectedLocation" class="w-100"/>
                </div>
                <div class="col-6">
                    <Card class="w-100">
                        <template #header>System Location Information</template>
                        <div>
                            <FormInput type="text" label="STORE_CODE:"
                                v-model="selectedLocation.STORE_CODE"
                                is-horizontal id-prefix="systemStoreCode" class="mb-1"
                                input-class="form-control" disabled/>
                            <FormInput type="text" label="LOCATION:"
                                v-model="selectedLocation.LOCATION"
                                is-horizontal id-prefix="systemLocation" class="mb-1"
                                input-class="form-control" disabled/>
                            <FormInput type="text" label="DESCRIPTION:"
                                v-model="selectedLocation.DESCRIPTION"
                                is-horizontal id-prefix="systemDescription" class="mb-1"
                                input-class="form-control" disabled/>
                        </div>
                    </Card>
                    <Card class="mt-2 w-100">
                        <template #header>CSV Location Information</template>
                        <div>
                            <FormInput type="text" label="STORE_CODE:"
                                v-model="csvData.store_code"
                                is-horizontal id-prefix="csvStoreCode" class="mb-1"
                                input-class="form-control" disabled/>
                            <FormInput type="text" label="STORE_NAME:"
                                v-model="csvData.store_name"
                                is-horizontal id-prefix="csvStoreName" class="mb-1"
                                input-class="form-control" disabled/>
                        </div>
                    </Card>
                    <div class="d-flex justify-content-center">
                        <button type="button" class="btn btn-primary mt-4 w-50"
                            :disabled="!selectedLocation.LOCATION" @click="update">
                            Update
                        </button>
                    </div>
                </div>
            </div>
        </Card>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';

import Card from "@/components/utils/Card.vue";
import FormInput from "@/components/utils/FormInput.vue";
import LocationSelectTableCard from '@/components/utils/LocationSelectTableCard.vue';

const props = defineProps({
    isOpen: Boolean,
    csvData: Object,
});

const emit = defineEmits(['success', 'error', 'close', 'location']);

const selectedLocation = ref({
    LOCATION: null,
    STORE_CODE: null,
    DESCRIPTION: null,
});

watch(() => props.csvData.system_location, () => {
    if(props.csvData.system_location !== 'No record.') {
        selectedLocation.value = {
            LOCATION: props.csvData.system_location,
            STORE_CODE: props.csvData.system_store_code,
            DESCRIPTION: props.csvData.system_description,
        }
    }
})

function close() {
    selectedLocation.value = {
        LOCATION: null,
        STORE_CODE: null,
        DESCRIPTION: null,
    };
    emit('close');
}

async function update() {
    try {
        const response = await axios.patch(route('api.location.update-store-code', {
                location: selectedLocation.value.LOCATION
            }),
            {
                STORE_CODE: props.csvData.store_code
            }
        );

        emit('success', response.data.message);
        emit('location', response.data.location);
        close();
    } catch (e) {
       console.log(e);
       emit('error', 'Failed to update STORE_CODE. Please contact your administrator.')
    }
}
</script>

<style scoped>
.pop-up {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: rgb(0, 0, 0, 0.5);
}

.pop-up .card {
    width: 100%;
}

@media (min-width: 768px) {
    .pop-up .card {
        width: 85%;
    }
}

@media (min-width: 992px) {
    .pop-up .card {
        width: 70%;
    }
}
</style>
