<template>
    <div
        v-if="isToastError"
        class="position-fixed start-0 end-0 top-0 bottom-0 d-flex justify-content-center align-items-center toast-shadow"
    >
        <div class="toast show bg-danger w-50" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="toast-header">
                <h6 class="me-auto text-black m-0">Error!</h6>
                <button @click="hideToast()" type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
                <div class="toast-body text-white p-4 fs-1.4">
                {{ toastMessage }}
                </div>
        </div>
    </div>
    <div v-if="isOpen" class="pop-up d-flex justify-content-center align-items-center">
        <div class="container w-75 bg-light p-3 rounded">
            <form action="" method="POST">
                <div class="d-flex justify-content-between">
                    <h5 class="title">{{ title != null ? title : 'SELECT EMPLOYEE' }}</h5>
                    <button type="button" class="btn-close" @click="close()"></button>
                </div>
                <section>
                    <div class="d-flex">
                        <div class="d-flex w-50">
                            <button v-if="!onlyShowEmployees.size || onlyShowEmployees.has('ACCOUNTING')"
                                @click="filterToDepartment('ACCOUNTING', 'equals')" type="button" class="btn btn-primary w-50 m-1">ACCOUNTING</button>
                            <button v-if="!onlyShowEmployees.size || onlyShowEmployees.has('ADMIN')"
                                @click="filterToDepartment('ADMIN', 'equals')" type="button" class="btn btn-primary w-50 m-1">ADMIN</button>
                        </div>
                        <div class="d-flex w-50">
                            <button v-if="!onlyShowEmployees.size || onlyShowEmployees.has('CUTTING')"
                                @click="filterToDepartment('CUTTING', 'equals')" type="button" class="btn btn-primary w-50 m-1">CUTTING</button>
                            <button v-if="!onlyShowEmployees.size || onlyShowEmployees.has('EXTRA')"
                                @click="filterToDepartment('EXTRA', 'startsWith')" type="button" class="btn btn-primary w-50 m-1">EXTRA</button>
                        </div>
                        <div class="d-flex w-50">
                            <button v-if="!onlyShowEmployees.size || onlyShowEmployees.has('INDIRECT LABOR') || onlyShowEmployees.has('PRODQC') "
                                @click="filterToDepartment(['INDIRECT LABOR', 'PRODQC'], 'in')" type="button" class="btn btn-primary w-50 m-1">INDIRECT LABOR</button>
                            <button v-if="!onlyShowEmployees.size || onlyShowEmployees.has('RESIGNED')"
                                @click="filterToDepartment('RESIGNED', 'equals')" type="button" class="btn btn-primary w-50 m-1">RESIGNED</button>
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="d-flex w-50">
                            <button v-if="!onlyShowEmployees.size || onlyShowEmployees.has('MAINTENANCE')"
                                @click="filterToDepartment('MAINTENANCE', 'equals')" type="button" class="btn btn-primary w-50 m-1">MAINTENANCE</button>
                            <button v-if="!onlyShowEmployees.size || onlyShowEmployees.has('MARKETING')"
                                @click="filterToDepartment('MARKETING', 'equals')" type="button" class="btn btn-primary w-50 m-1">MARKETING</button>
                        </div>
                        <div class="d-flex w-50">
                            <button v-if="!onlyShowEmployees.size || onlyShowEmployees.has('PRODUCTION')"
                                @click="filterToDepartment('PRODUCTION', 'equals')" type="button" class="btn btn-primary w-50 m-1">PRODUCTION</button>
                            <button v-if="!onlyShowEmployees.size || onlyShowEmployees.has('RESEARCH AND DEV')"
                                @click="filterToDepartment('RESEARCH AND DEV', 'equals')" type="button" class="btn btn-primary w-50 m-1">R AND D</button>
                        </div>
                        <div class="d-flex w-50">
                            <button v-if="!onlyShowEmployees.size || onlyShowEmployees.has('SALES')"
                                @click="filterToDepartment('S', 'startsWith')" type="button" class="btn btn-primary w-50 m-1">SALES</button>
                            <button v-if="!onlyShowEmployees.size || onlyShowEmployees.has('CONSTRUCTION')"
                                @click="filterToDepartment('CONSTRUCTION', 'equals')" type="button" class="btn btn-primary w-50 m-1">CONSTRUCTION</button>
                        </div>
                    </div>
                </section>
                <section>
                    <h6>SEARCH</h6>
                    <div class="d-flex">
                        <div class="d-flex flex-column w-75">
                            <div class="d-flex">
                                <label for="FIRST_NAME" class="form-label w-25">FIRST NAME:</label>
                                <input v-model="filters.FNAME.value" type="text" class="form-control m-1 w-50">
                            </div>
                            <div class="d-flex">
                                <label for="LAST_NAME" class="form-label w-25">LAST NAME:</label>
                                <input v-model="filters.LNAME.value" type="text" class="form-control m-1 w-50">
                            </div>
                        </div>
                        <div class="d-flex justify-content-center align-items-center w-25">
                            <button type="button" class="btn btn-primary w-75 h-75" @click="fetchEmployees">SEARCH</button>
                        </div>
                    </div>
                </section>
                <section>
                    <DataTable
                        :loading="isLoading"
                        lazy
                        :value="employees"
                        :total-records="totalRecords"
                        paginator
                        :rows="10"
                        @page="e => { onPage(e); fetchEmployees(); }"
                        filter-display="row"
                        v-model:filters="filters"
                        @filter="tableOnFilter"
                        selection-mode="single"
                        v-model:selection="employee"
                        :pt="{ table: { class: 'table table-bordered table-hover' } }">
                        <Column field="EMPID" header="ID" :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' } }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback"
                                    class="form-control" placeholder="Search" />
                            </template>
                        </Column>
                        <Column field="PAYTYPE" header="Paytype" :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' } }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback"
                                    class="form-control" placeholder="Search" />
                            </template>
                        </Column>
                        <Column field="FNAME" header="First Name" :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' } }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback"
                                    class="form-control" placeholder="Search" />
                            </template>
                        </Column>
                        <Column field="LNAME" header="Last Name" :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' } }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback"
                                    class="form-control" placeholder="Search" />
                            </template>
                        </Column>
                        <Column field="DEPT" header="Department" :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' } }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText type="text" v-model="filterModel.value" @input="e => {
                                    filterModel.matchMode = 'contains';
                                    filterCallback(e);
                                }"
                                    class="form-control" placeholder="Search" />
                            </template>
                        </Column>
                    </DataTable>
                </section>
                <section>
                    <button type="button" class="btn btn-primary w-100"
                        :disabled='!employee'
                        @click="handleSubmit">
                        SELECT EMPLOYEE
                    </button>
                </section>
            </form>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';

import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useEmployees } from '@/composables/data/employees';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

const props = defineProps({
    isOpen: Boolean,
    onClose: Function,
    onSelectEmployee: Function,
    onlyShow: Array,
    title: String,
});

const { params, filters, onPage, onFilter, onSort } = useDataTableParams([
    'EMPID', 'PAYTYPE', 'FNAME', 'LNAME', 'DEPT'
]);

const { employees, totalRecords, getEmployees } = useEmployees();

const employee = ref(null);

const toastMessage = ref("");
const isToastError = ref(false);
const onlyShowEmployees = ref(new Set(props.onlyShow));

const isLoading = ref(false);

const fetchTimeout = ref(null);

async function fetchEmployees() {
    isLoading.value = true;
    try {
        await getEmployees(params.value);
    } catch (e) {
        console.error(e);
        isToastError.value = true;
        toastMessage.value = e.message;
    }
    isLoading.value = false;
}

function tableOnFilter(e) {
    onFilter(e);
    if(fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(fetchEmployees, 300);
}

onMounted(fetchEmployees);

async function filterToDepartment(dept, matchMode) {
    filters.value.DEPT = {
        value: dept,
        matchMode,
    };
    tableOnFilter();
}

function handleSubmit() {
    if (!employee.value) {
        isToastError.value = true;
        toastMessage.value = 'No employee selected.'
    } else {
        props.onSelectEmployee(employee.value);
        close();
    }
}

function close() {
    props.onClose();
}

function hideToast() {
    isToastError.value = false;
}
</script>

<style scoped>
.pop-up {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: rgb(0, 0, 0, 0.5);
}

.container {
    height: max-content;
}

section {
    padding: 1rem;
    background-color: #f9fafb;
    border-radius: 0.5rem;
    border: 2px solid #d1d5db;
}

.table-container {
  height: 250px;
  overflow-y: auto;
}

.table-container thead th {
  position: sticky;
  top: 0;
  background-color: #f9fafb;
}

.table-container tbody tr {
    cursor: pointer;
}

.selected-row {
    background-color: lightslategrey;
}

.toast-shadow {
    padding: 3;
    z-index: 11;
    background: rgb(0, 0, 0, 0.5);
}
</style>
