<template>
    <Card>
        <template #header>
            Location Charge Form
        </template>
        <FormInput type="date" v-model="locationCharge.START_DATE"
            label="Start Date" id-prefix="startDate"
            :errors="errors.START_DATE"
            @keydown-enter="_ => { focusnext('endDateFormInput'); }"
            class="mb-3" />
        <FormSelect v-model="locationCharge.CHARGE_NAME"
            :options="[
                'DISCOUNTS',
                'COMMISSIONS',
                'SHIPPING',
                'STORAGE',
                'UTILITIES',
                'TELEPHONE',
                'SUPPLIES',
                'SALE_EVENTS',
                'CREDITABLE_TAX',
                'UNIFORM',
                'PRICE_TAG',
                'E2E_CHARGE',
                'STORE_MAIN',
                'MAIL_POST',
                'SAL_WAGES',
                'BANK_CHARGES',
                'TRANSPORT',
                'REPRESENT',
                'MISCL',
                'ADS',
                'AR_CASH',
                'FLOATING_ADJ'
            ]"
            label="Name" id-prefix="name"
            :errors="errors.CHARGE_NAME"
            @keydown-enter="_ => { focusnext('typeFormInput'); }"
            class="mb-3" />
        <FormSelect v-model="locationCharge.CHARGE_TYPE"
            :options="[
                { value: 'P', verbose: 'PERCENTAGE' },
                { value: 'F', verbose: 'FLAT FEE' },
                { value: 'C', verbose: 'CALCULATION' }
            ]"
            :option-value="chargeType => chargeType.value"
            :option-to-string="chargeType => [chargeType.value, chargeType.verbose].join(' | ')"
            label="Type" id-prefix="type"
            :errors="errors.CHARGE_TYPE"
            @keydown-enter="_ => { focusnext('valueFormInput'); }"
            class="mb-3" />
        <FormInput type="text" v-model="locationCharge.CHARGE_VALUE"
            label="Value" id-prefix="value"
            :errors="errors.CHARGE_VALUE"
            @keydown-enter="_ => {
                if(locationCharge.id)
                    focusnext('updateButton');
                else
                    focusnext('insertButton');
            }"
            class="mb-1" />
        <div class="text-end">
            <button type="button" class="btn btn-sm btn-outline-primary"
                @click="_ => { isViewingHelper = true; }">
                View Helper for Calculations
            </button>
        </div>
        <template #footer>
            <div class="text-end">
                <button type="button" class="btn btn-primary me-1"
                    id="insertButton" :disabled="!location"
                    @click="insertLocationCharge">
                    Insert
                </button>
                <button type="button" class="btn btn-outline-secondary"
                    @click="reset">
                    Reset
                </button>
            </div>
        </template>
    </Card>
    <ValidVariablesHelperPopup :is-open="isViewingHelper"
        @close="_ => { isViewingHelper = false; }" />
</template>

<script setup>
import { ref, watch } from 'vue';

import { useLocationCharges } from '@/composables/data/locationCharges';

import Card from '@/components/utils/Card';
import FormInput from '@/components/utils/FormInput';
import FormSelect from '@/components/utils/FormSelect';

import ValidVariablesHelperPopup from './ValidVariablesHelperPopup.vue';

import focusnext from '@/utils/focusnext';

const props = defineProps({
    location: String,
    locationCharge: Object,
});

const emit = defineEmits(['success', 'warning', 'error', 'reset']);

const { postLocationCharge } = useLocationCharges();

const locationCharge = ref({
    id: null,
    CHARGE_NAME: null,
    START_DATE: null,
    CHARGE_TYPE: null,
    CHARGE_VALUE: null,
});

const errors = ref({});

const isViewingHelper = ref(false);

watch(() => props.locationCharge, () => {
    if(!props.locationCharge)
        locationCharge.value = {
            id: null,
            CHARGE_NAME: null,
            START_DATE: null,
            CHARGE_TYPE: null,
            CHARGE_VALUE: null,
        };

    else
        locationCharge.value = Object.assign({}, props.locationCharge);
});

function reset() {
    locationCharge.value = {
        id: null,
        CHARGE_NAME: null,
        START_DATE: null,
        CHARGE_TYPE: null,
        CHARGE_VALUE: null,
    };
    emit('reset');
}

async function insertLocationCharge() {
    errors.value = {};
    try {
        await postLocationCharge(props.location, locationCharge.value);
        emit('success', 'Successfully saved location charge.');
        emit('reset');
    } catch(e) {
        errors.value = e.errors ?? {};
        emit('error', e.message);
    }
}
</script>
