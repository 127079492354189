<template>
    <Card>
        <div class="row g-3">
            <div class="col-6 py-5 px-3">
                <div class="d-flex">
                    <FormInput label="Start Date:" v-model="reportParams.START_DATE"
                        type="date"
                        class="mb-3 w-100"
                        input-class="form-control-sm"
                        id-prefix="startDate"
                        is-horizontal
                        :errors="errors.START_DATE"/>
                    <FormInput label="End Date:" v-model="reportParams.END_DATE"
                        type="date"
                        class="mb-3 ms-2 w-100"
                        input-class="form-control-sm"
                        is-horizontal
                        id-prefix="endDate"
                        :errors="errors.END_DATE"/>
                </div>
                <div class="d-flex">
                    <FormInput label="Prev. Location:"
                        class="mb-3 w-75"
                        is-horizontal
                        disabled
                        input-class="form-control-sm"
                        v-model="reportParams.PREV_LOC"/>
                    <button class="btn btn-primary btn-sm mb-4 ms-3 px-2"
                        @click="_ => { isSelectingLocation = true; }">Select</button>
                    <button class="btn btn-primary btn-sm mb-4 ms-3 px-2"
                        @click="_ => { reportParams.PREV_LOC = null; }">Reset</button>
                </div>
                <div class="d-flex">
                    <FormInput label="Style:"
                        class="mb-3 w-75"
                        is-horizontal
                        input-class="form-control-sm"
                        disabled
                        v-model="reportParams.STYLE"/>
                    <button class="btn btn-primary btn-sm mb-4 ms-3 px-2"
                        @click="_ => { isSelectingStyle = true; }">Select</button>
                    <button class="btn btn-primary btn-sm mb-4 ms-3 px-2"
                        @click="_ => { reportParams.STYLE = null; }">Reset</button>
                </div>
                <div class="d-flex">
                    <FormInput label="Color:"
                        class="mb-3 w-75"
                        is-horizontal
                        input-class="form-control-sm"
                        disabled
                        v-model="reportParams.COLOR"/>
                    <button class="btn btn-primary btn-sm mb-4 ms-3 px-2"
                        @click="_ => { isSelectingColor = true; }">Select</button>
                    <button class="btn btn-primary btn-sm mb-4 ms-3 px-2"
                        @click="_ => { reportParams.COLOR = null; }">Reset</button>
                </div>
            </div>
            <div class="col-6 py-5 px-3">
                <div class="d-flex">
                    <FormInput label="Employee ID:"
                        class="mb-3 w-75"
                        is-horizontal
                        input-class="form-control-sm"
                        disabled
                        v-model="reportParams.EMPID"/>
                    <button class="btn btn-primary btn-sm mb-4 ms-3 px-2"
                        @click="_ => { isSelectingEmployee = true; }">Select</button>
                    <button class="btn btn-primary btn-sm mb-4 ms-3 px-2"
                        @click="_ => { reportParams.EMPID = null; }">Reset</button>
                </div>
                <div class="d-flex">
                    <FormInput label="Price:"
                        class="mb-3 w-75"
                        is-horizontal
                        input-class="form-control-sm"
                        v-model="reportParams.PRICE"/>
                </div>
                <div class="d-flex">
                    <FormInput label="Receipt No:"
                        class="mb-3 w-75"
                        is-horizontal
                        input-class="form-control-sm"
                        v-model="reportParams.RECEIPT_NO"/>
                </div>
                <div class="d-flex">
                    <FormInput label="Sale type:"
                        class="mb-3 w-75"
                        is-horizontal
                        input-class="form-control-sm"
                        v-model="reportParams.SALE_TYPE"/>
                </div>
            </div>
            <div class="col-12">
                <button class="btn btn-primary mb-4 ms-3 px-2"
                    @click="fetchReport">Display Sales Report</button>
            </div>
            <div class="col-12">
                <Card>
                    <template #header>SALES REPORT</template>
                    <DataTable :loading="isLoading" lazy :value="inventoryRecords" :total-records="inventoryTotalRecords"
                        paginator :rows="10" @page="e => { onPage(e); fetchReport(); }"
                        :pt="{ table: { class: 'table table-bordered table-hover' } }">
                        <Column field="STYLE" header="STYLE"/>
                        <Column field="PREV_LOC" header="PREV_LOC"/>
                        <Column field="SOLD_DT" header="SOLD_DT"/>
                        <Column field="BARCODE" header="BARCODE" :pt="{
                            bodyCell: { class: 'barcode' } }"/>
                        <Column field="COLOR" header="COLOR"/>
                        <Column field="PRICE" header="PRICE"/>
                        <Column field="EMPID" header="EMPID"/>
                        <Column field="RECEIPT_NO" header="RECEIPT_NO"/>
                        <Column field="SALE_TYPE" header="SALE_TYPE"/>
                        <Column field="DISCOUNT" header="DISCOUNT"/>
                        <Column field="SELL_TO_ID" header="SELL_TO_ID"/>
                        <Column field="CREATED_BY" header="CREATED_BY"/>
                        <Column field="SEQ_NO" header="SEQ_NO"/>
                        <Column field="COMMENT_ID" header="COMMENT_ID"/>
                        <Column field="SELL_TO_TYPE" header="SELL_TO_TYPE"/>
                        <template #loading>
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            Loading report...
                        </template>
                        <template #empty>No data.</template>
                    </DataTable>
                </Card>
            </div>
        </div>
    </Card>
    <SelectLocation :is-open="isSelectingLocation"
        @select="handleSelectLocation"
        @close="_ => { isSelectingLocation = false; }"/>
    <SelectStyle :is-open="isSelectingStyle"
        @select="handleSelectStyle"
        @close="_ => { isSelectingStyle = false; }" />
    <SelectColor :is-open="isSelectingColor"
        @select="handleSelectColor"
        @close="_ => { isSelectingColor = false; }" />
    <SelectEmployee
        :is-open="isSelectingEmployee"
        :onClose="_ => { isSelectingEmployee = false; }"
        :onSelectEmployee="handleSelectEmployee"/>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';
import Card from '@/components/utils/Card.vue';
import FormInput from '@/components/utils/FormInput.vue';

import SelectLocation from '@/components/utils/popups/SelectLocationPopup.vue';
import SelectColor from '@/components/utils/SelectColor';
import SelectStyle from '@/components/utils/style/SelectStyle';
import SelectEmployee from '@/components/utils/SelectEmployee.vue';

import handleFetchErrors from "@/utils/handleFetchErrors";
import scrollToTop from "@/utils/scrollToTop";

import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useInventory } from '@/composables/data/inventory';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';

const isLoading = ref(false);
const errors = ref({});
const isSelectingLocation = ref(false);
const isSelectingStyle = ref(false);
const isSelectingColor = ref(false);
const isSelectingEmployee = ref(false);

const { params, onPage } = useDataTableParams([
        'PREV_LOC', 'STYLE', 'PRICE', 'EMPID',
        'RECEIPT_NO', 'SALE_TYPE', 'COLOR'
    ],
);

const { inventoryRecords, inventoryTotalRecords, getInventorySales} = useInventory();

const reportParams = ref({
    START_DATE: null,
    END_DATE: null,
    PREV_LOC: null,
    STYLE: null,
    COLOR: null,
    EMPID: null,
    PRICE: null,
    RECEIPT_NO: null,
    SALE_TYPE: null,
});

function handleSelectLocation(selectedLocation) {
    reportParams.value.PREV_LOC = selectedLocation.LOCATION
    isSelectingLocation.value = false;
}

function handleSelectColor(selectedColor) {
    reportParams.value.COLOR = selectedColor.COLOR
    isSelectingColor.value = false;
}

function handleSelectStyle(selectedStyle) {
    reportParams.value.STYLE = selectedStyle.STYLE
    isSelectingStyle.value = false;
}

function handleSelectEmployee(selectedEmployee) {
    reportParams.value.EMPID = selectedEmployee.EMPID;
    isSelectingEmployee.value = false;
}

async function fetchReport() {
    errors.value = {};
    isLoading.value = true;
    try {
        await getInventorySales(reportParams.value, params.value);
    } catch (e) {
        inventoryRecords.value = [];
        console.log(e);
        if (e.status == 422) {
            errors.value = e.errors;
        } else {
            handleFetchErrors(e, 'Error while fetching reports.');
            scrollToTop();
        }
    }
    isLoading.value = false;
}
</script>
