import { ref } from 'vue';

import axios from 'axios';

import constructFetchError from "./constructFetchError";

export function useAccountPaymentDetails() {
    const paymentDetails = ref(null);
    const paymentSummary = ref(null);

    const getPaymentDetails = async (accountId) => {
        try {
            const getPaymentDetailsResponse = await axios.get(route('api.accounts.payment-details.show', {
                account: accountId
            }));
            paymentDetails.value = getPaymentDetailsResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching payment details.');
        }
    };

    const getPaymentSummary = async (accountId) => {
        try {
            const getPaymentSummaryResponse = await axios.get(route('api.accounts.payment-summary.show', {
                account: accountId
            }));
            paymentSummary.value = getPaymentSummaryResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching payment summary.');
        }
    };

    return { paymentDetails, getPaymentDetails, paymentSummary, getPaymentSummary };
}
