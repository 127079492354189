import { ref } from 'vue';

import axios from 'axios';

import constructFetchError from "./constructFetchError";

export function useEmployeeLoans() {
    const employeeLoans = ref(null);

    const getEmployeeOpenLoans = async (employeeId, params) => {
        try {
            const getEmployeeOpenLoansResponse = await axios.get(route('api.employees.loans.index', {
                employee: employeeId,
                STATUS: 'O',
                ...params
            }));
            employeeLoans.value = getEmployeeOpenLoansResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching employee loans.');
        }
    };

    const getCloseableLoans = async () => {
        try {
            let getCloseableLoansResponse = await axios.get(route('api.loans.index', {
                'STATUS': 'O',
                'INCLUDE_FIELDS': 'TOTAL_PAYMENT',
                'REMAIN_AMT_LTE': 0.1,
            }));
            employeeLoans.value = getCloseableLoansResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching employee loans.');
        }
    };

    const postLoanClosures = async (loanIds) => {
        try {
            return await axios.post(route('api.loans.closures.store'), {
                LOANS: loanIds
            });
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while closing loans.');
        }
    };

    const postLoanClosure = async (employeeId, loanId, voucherId) => {
        try {
            return await axios.post(route('api.employees.loans.closures.store', {
                employee: employeeId,
                employee_loan: loanId,
                VOUCHER_ID: voucherId
            }));
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, "Error while closing loan.");
        }
    };

    const postGovtLoanClosure = async (employeeId, loanId, reason) => {
        try {
            return await axios.post(route('api.employees.loans.govt.closures.store', {
                employee: employeeId,
                employee_loan: loanId,
            }), {
                CLOSURE_REASON: reason
            });
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, "Error while closing gov't loan.");
        }
    };

    return {
        employeeLoans,
        getEmployeeOpenLoans,
        getCloseableLoans,
        postLoanClosures,
        postLoanClosure,
        postGovtLoanClosure,
    };
}
