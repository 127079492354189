import { ref } from "vue";

import axios from "axios";

import constructFetchError from "./constructFetchError";

export function usePurchaseOrders() {
    const purchaseOrders = ref(null);
    const totalRecords = ref(null);

    const getPurchaseOrders = async (params) => {
        try {
            const getPurchaseOrdersResponse = await axios.get(route('api.purchase-orders.index', params));
            purchaseOrders.value = getPurchaseOrdersResponse.data.data;
            totalRecords.value = getPurchaseOrdersResponse.data.meta.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching purchase orders.');
        }
    };

    const postPurchaseOrder = async (purchaseOrder) => {
        try {
            const postPurchaseOrderResponse = await axios.post(route('api.purchase-orders.store'), purchaseOrder);
            return postPurchaseOrderResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while saving purchase order.');
        }
    };

    const putPurchaseOrder = async (purchaseOrderId, purchaseOrder) => {
        try {
            const putPurchaseOrderResponse = await axios.put(route('api.purchase-orders.update', {
                purchase_order: purchaseOrderId
            }), purchaseOrder);
            return putPurchaseOrderResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while updating purchase order.');
        }
    };
    
    return {
        purchaseOrders,
        totalRecords,
        getPurchaseOrders,
        postPurchaseOrder,
        putPurchaseOrder
    };
}
