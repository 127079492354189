<template>
    <div v-if="isOpen"
        class="pop-up d-flex justify-content-center align-items-center">
        <div class="container bg-light px-0 py-3 rounded">
            <div class="d-flex justify-content-between align-items-center px-4">
                <h4 class="title mb-0 fw-bold">
                    <i class="icon ph-bold ph-palette me-2"></i>Select Color
                </h4>
                <button type="button"
                    class="btn-close"
                    @click="emit('close')">
                </button>
            </div>
            <hr />
            <div class="modal-container px-4">
                <div class="modal-padding-container">
                    <DataTable :value="colors"
                        paginator
                        :rows="10"
                        v-model:filters="filters"
                        filter-display="row"
                        v-model:selection="selectedColor"
                        selection-mode="single"
                        :pt="{ table: { class: 'table table-bordered table-hover' } }">
                        <Column
                            field="COLOR"
                            header="Color"
                            :pt="{
                                filterInput: { class: 'input-group input-group-sm' },
                                filterMenuButton: { class: 'd-none' },
                                headerFilterClearButton: { class: 'd-none' },
                            }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText
                                    type="text"
                                    v-model="filterModel.value"
                                    @input="filterCallback()"
                                    class="form-control"
                                    placeholder="Search by Color"
                                />
                            </template>
                        </Column>
                        <template #empty>
                            <div class="text-center py-2">
                                <i class="icon ph-bold ph-database me-2"></i>No colors.
                            </div>
                        </template>
                    </DataTable>
                </div>
                <div class="modal-padding-container">
                    <div class="text-end">
                        <button type="button"
                            class="btn btn-primary"
                            :disabled="!selectedColor"
                            @click="(_) => {  emit('select', selectedColor); }">
                            <i class="icon ph-bold ph-hand-pointing me-2"></i>Select Color
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useColors } from "@/composables/data/colors";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";

const props = defineProps({
    isOpen: Boolean,
});

const emit = defineEmits(["select", "error"]);

const { filters } = useDataTableParams(["COLOR"]);

const { colors, getActiveColors } = useColors();

const selectedColor = ref(null);

onMounted(async () => {
    try {
        await getActiveColors();
    } catch (e) {
        emit("error", e.message);
    }
});
</script>
