<template>
    <Card class="mb-3">
        <template #header>
            Style SM
        </template>
        <form>
            <FormInput type="text" v-model="form.SM_SKU"
                label="Generic SKU" id-prefix="genericSMSku"
                is-horizontal class="mb-3"
                input-class="form-control-sm" :errors="errors.SM_SKU"
                @keydown-enter="focusnext('saveButton')" />
            <button type="button" class="btn btn-primary me-1" id="saveButton"
                :disabled="!style.STYLE || isSubmitting"
                @click="saveSku">
                <span v-if="isSubmitting" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Save
            </button>
        </form>
    </Card>
</template>

<script setup>
import { ref, watch } from 'vue';

import { useFlashMessages } from "@/composables/flashmessages";
import { useSMGenericSkus } from "@/composables/data/smGenericSkus";

import Card from '@/components/utils/Card.vue';
import FormInput from '@/components/utils/FormInput.vue';

import scrollToTop from "@/utils/scrollToTop";
import focusnext from '@/utils/focusnext';

const { addFlashMessage } = useFlashMessages();

const props = defineProps({
    style: Object,
    class: String
});

const emit = defineEmits(['done']);

const { putSMGenericSku } = useSMGenericSkus();

const form = ref({
    SM_SKU: null
});

const errors = ref({});

const isSubmitting = ref(false);

watch(() => props.style, async () => {
    errors.value = {};
    form.value = Object.assign({ SM_SKU: null }, props.style);
});

async function saveSku() {
    errors.value = {};

    isSubmitting.value = true;
    try {
        await putSMGenericSku(props.style.STYLE, form.value);
        addFlashMessage('SUCCESS', `Successfully saved Generic SM SKU ${form.value.SM_SKU}.`);
        emit('done');
    } catch(e) {
        errors.value = e.errors ?? {};
        addFlashMessage('ERROR', e.message);
    }
    isSubmitting.value = false;
    scrollToTop();
}
</script>
