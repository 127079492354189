<template>
    <div class="row g-3">
        <div class="col-md-4">
            <StyleImageCard :style="selectedStyle?.STYLE" class="mb-3" />
            <StyleSelectionCard @select="style => selectedStyle = style" />
        </div>
        <div class="col-md-8">
            <StylePriceCard :style="selectedStyle" class="mb-3"
                @success="message => {
                    addFlashMessage('SUCCESS', message);
                    scrollToTop();
                }"
                @error="message => {
                    addFlashMessage('ERROR', message);
                    scrollToTop();
                }" />
            <StyleSkuPriceCard :style="selectedStyle" class="mb-3"
                @success="message => {
                    addFlashMessage('SUCCESS', message);
                    scrollToTop();
                }"
                @error="message => {
                    addFlashMessage('ERROR', message);
                    scrollToTop();
                }" />
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';

import StylePriceCard from './StylePriceCard.vue';
import StyleSelectionCard from './StyleSelectionCard.vue';
import StyleSkuPriceCard from './StyleSkuPriceCard.vue';

import StyleImageCard from '../../../utils/StyleImageCard.vue';

import scrollToTop from "../../../../utils/scrollToTop";

import { useFlashMessages } from "../../../../composables/flashmessages";

const { addFlashMessage } = useFlashMessages();

const selectedStyle = ref();
</script>
