import { ref } from 'vue';

import axios from 'axios';

import constructFetchError from "./constructFetchError";

export function useLandedCosts() {
    const landedCosts = ref(null);
    const landedCostsTotalRecords = ref(null);
    const noLandedCosts = ref(null);
    const noLandedCostsTotalRecords = ref(null);

    const getLandedCosts = async(params) => {
        try {
            const getLandedCostsResponse = await axios.get(route('api.landed-costs.index', {
                ...params
            }));
            landedCosts.value = getLandedCostsResponse.data.data;
            landedCostsTotalRecords.value = getLandedCostsResponse.data.meta.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching landed costs.');
        }
    };

    const getNoLandedCosts = async (params) => {
        try {
            const getNoLandedCostsResponse = await axios.get(route('api.landed-costs.not-set.index', {
                ...params
            }));
            noLandedCosts.value = getNoLandedCostsResponse.data.data;
            noLandedCostsTotalRecords.value = getNoLandedCostsResponse.data.meta.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching landed costs.');
        }
    };

    const getLandedCostsByStyle = async (style, params) => {
        try {
            const getLandedCostsByStyleResponse = await axios.get(route('api.landed-costs.by-style.index', {
                style: style,
                ...params
            }));
            landedCosts.value = getLandedCostsByStyleResponse.data.data;
            landedCostsTotalRecords.value = getLandedCostsByStyleResponse.data.meta.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching landed costs.');
        }
    };

    const updateLandedCost = async (style, date, landedCost) => {
        try {
            return await axios.put(route('api.landed-costs.update', {
                style: style,
                date: date,
            }), {
                LANDED_COST: landedCost
            });
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while updating landed cost.');
        }
    };

    return { landedCosts, landedCostsTotalRecords, getLandedCosts, getLandedCostsByStyle,
        noLandedCosts, noLandedCostsTotalRecords, getNoLandedCosts,
        updateLandedCost };
}
