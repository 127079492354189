<style scoped>
#dependentsTableContainer {
    height: 350px;
    overflow-y: auto;
}

#dependentsTableContainer table thead {
    position: sticky;
    top: 0;
    background-color: var(--bs-card-bg);
}
</style>

<template>
    <form @submit.prevent="updateEmployee" class="mb-3">
        <div class="row g-3 mb-3">
            <div class="col-md-8">
                <Card>
                    <template #header>
                        Selected Employee
                    </template>
                    <div class="row">
                        <div class="col-sm-3">
                            <FormInput type="text" label="ID" v-model="employee.EMPID"
                                id-prefix="empid" input-class="form-control-sm"
                                disabled />
                        </div>
                        <div class="col-sm-3">
                            <FormInput type="text" label="First Name" v-model="employee.FNAME"
                                id-prefix="fname" input-class="form-control-sm"
                                disabled />
                        </div>
                        <div class="col-sm-3">
                            <FormInput type="text" label="Middle Name" v-model="employee.MNAME"
                                id-prefix="fname" input-class="form-control-sm"
                                disabled />
                        </div>
                        <div class="col-sm-3">
                            <FormInput type="text" label="Last Name" v-model="employee.LNAME"
                                id-prefix="fname" input-class="form-control-sm"
                                disabled />
                        </div>
                    </div>
                </Card>
            </div>
            <div class="col-md-4">
                <Card>
                    <div class="d-grid gap-3">
                        <button type="button" class="btn btn-primary"
                            @click="_ => { isSelectEmployeePopupOpen = true; }">
                            Select Employee
                        </button>
                    </div>
                </Card>
            </div>
        </div>
        <Card>
            <template #header>
                Employee Details
            </template>
            <div class="row mb-3">
                <div class="col-md-4">
                    <FormSelect label="Civil Status" v-model="employee.CIVIL_STATUS"
                        :options="civilStatuses"
                        :option-to-string="civilStatus => civilStatus.verbose"
                        :option-value="civilStatus => civilStatus.value"
                        id-prefix="civilStatus" select-class="form-select-sm"
                        :errors="employeeErrors.CIVIL_STATUS"
                        @keydown-enter="focusNextInput('presentAddressFormInput')" />
                </div>
            </div>
            <FormInput type="text" label="Present Address" v-model="employee.PRESENT_ADDRESS"
                id-prefix="presentAddress" input-class="form-control-sm" class="mb-3"
                :errors="employeeErrors.PRESENT_ADDRESS"
                @keydown-enter="focusNextInput('permanentAddressFormInput')" />
            <FormInput type="text" label="Permanent Address" v-model="employee.PERMANENT_ADDRESS"
                id-prefix="permanentAddress" input-class="form-control-sm" class="mb-3"
                :errors="employeeErrors.PERMANENT_ADDRESS"
                @keydown-enter="focusNextInput('spouseNameFormInput')" />
            <FormInput type="text" label="Spouse Name" v-model="employee.SPOUSE_NAME"
                id-prefix="spouseName" input-class="form-control-sm" class="mb-3"
                :errors="employeeErrors.SPOUSE_NAME"
                @keydown-enter="focusNextInput('motherMaidenNameFormInput')" />
            <FormInput type="text" label="Mother's Full Maiden Name" v-model="employee.MOTHER_MAIDEN_NAME"
                id-prefix="motherMaidenName" input-class="form-control-sm" class="mb-3"
                :errors="employeeErrors.MOTHER_MAIDEN_NAME"
                @keydown-enter="focusNextInput('updateEmployeeButton')" />
            <button type="button" class="btn btn-primary me-1" id="updateEmployeeButton"
                @click="updateEmployee" :disabled="employee.EMPID == null">
                Update Details
            </button>
            <button type="reset" class="btn btn-outline-secondary"
                @click="resetEmployeeDetails">
                Reset Fields
            </button>
        </Card>
    </form>
    <div class="row g-3">
        <div class="col-md-8">
            <Card>
                <template #header>
                    Employee Dependents
                </template>
                <div class="table-responsive" id="dependentsTableContainer">
                    <table class="table table-bordered table-hover">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Gender</th>
                                <th>Status</th>
                                <th>Birthdate</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="employee.EMPID == null">
                                <td colspan="4" class="text-center">Please select an employee.</td>
                            </tr>
                            <tr v-else-if="dependents?.length == 0">
                                <td colspan="4" class="text-center">No dependents.</td>
                            </tr>
                            <tr v-else v-for="dependentEntry in dependents"
                                :class="{'bg-primary text-white': dependent.id == dependentEntry.id}"
                                @click="employeeDependentRowOnClick(dependentEntry)">
                                <td>{{ dependentEntry.NAME }}</td>
                                <td>{{ dependentEntry.GENDER }}</td>
                                <td>{{ dependentEntry.STATUS }}</td>
                                <td>{{ dependentEntry.BIRTHDATE }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Card>
        </div>
        <div class="col-md-4">
            <form @submit.prevent="saveDependent">
                <Card>
                    <template #header>
                        Add/Remove Dependent
                    </template>
                    <FormInput type="text" label="Name" v-model="dependent.NAME"
                        id-prefix="name" input-class="form-control-sm" class="mb-3"
                        :errors="errors.NAME"
                        @keydown-enter="focusNextInput('genderFormInput')" />
                    <FormSelect label="Gender" v-model="dependent.GENDER"
                        :options="genders"
                        :option-to-string="(gender) => gender.verbose"
                        :option-value="(gender) => gender.value"
                        id-prefix="gender" select-class="form-select-sm" class="mb-3"
                        :errors="errors.GENDER"
                        @keydown-enter="focusNextInput('dependentCivilStatusFormInput')" />
                    <FormSelect label="Civil Status" v-model="dependent.STATUS"
                        :options="civilStatuses"
                        :option-to-string="civilStatus => civilStatus.verbose"
                        :option-value="civilStatus => civilStatus.value"
                        id-prefix="dependentCivilStatus" select-class="form-select-sm" class="mb-3"
                        :errors="errors.STATUS"
                        @keydown-enter="focusNextInput('birthdateFormInput')" />
                    <FormInput type="date" label="Birthdate" v-model="dependent.BIRTHDATE"
                        id-prefix="birthdate" input-class="form-control-sm" class="mb-3"
                        :errors="errors.BIRTHDATE"
                        @keydown-enter="focusNextInput('addDependentButton')" />
                    <div class="d-grid gap-3">
                        <button type="submit" class="btn btn-primary" id="addDependentButton"
                            :disabled="employee.EMPID == null || isSaving">
                            Add Dependent
                            <div v-if="isSaving" class="spinner-border text-light" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </button>
                        <button type="button" class="btn btn-danger"
                            :disabled="employee.EMPID == null || dependent.id == null || isDeleting"
                            @click="removeDependent">
                            Remove Dependent
                        </button>
                        <button type="reset" class="btn btn-outline-secondary"
                            @click="resetDependentFields">
                            Reset Fields
                        </button>
                    </div>
                </Card>
            </form>
        </div>
    </div>

    <SelectEmployee :is-open="isSelectEmployeePopupOpen"
        :on-close="_ => { isSelectEmployeePopupOpen = false; }"
        :on-select-employee="onEmployeeSelect" />
</template>

<script setup>
import { ref } from "vue";

import Card from "@/components/utils/Card.vue";
import FormInput from "@/components/utils/FormInput.vue";
import FormSelect from "@/components/utils/FormSelect.vue";
import SelectEmployee from "@/components/utils/SelectEmployee.vue";

import axios from "axios";

import focusNextInput from "../../../utils/focusnext";
import handleFetchErrors from "../../../utils/handleFetchErrors";
import scrollToTop from "../../../utils/scrollToTop";

import { useFlashMessages } from "../../../composables/flashmessages";

const { addFlashMessage } = useFlashMessages();

const employeeErrors = ref({});
const errors = ref({});

const genders = [
    { value: 'F', verbose: 'Female' },
    { value: 'M', verbose: 'Male' },
];

const civilStatuses = [
    { value: 'S', verbose: 'Single' },
    { value: 'M', verbose: 'Married' },
    { value: 'W', verbose: 'Widowed' },
    { value: 'X', verbose: 'Separated' },
];

const dependents = ref();

const employee = ref({});
const dependent = ref({});

const isSelectEmployeePopupOpen = ref(false);

const isEmployeeUpdating = ref(false);
const isSaving = ref(false);
const isDeleting = ref(false);

const onEmployeeSelect = (selectedEmployee) => {
    employee.value = selectedEmployee;
    fetchDependents();
};

const updateEmployee = async () => {
    isEmployeeUpdating.value = true;
    employeeErrors.value = {};
    try {
        const updateEmployeeResponse = await axios.patch(route('api.employees.update', {
            employee: employee.value.EMPID
        }), employee.value);
        addFlashMessage('SUCCESS', 'Successfully updated employee.');
        employee.value = updateEmployeeResponse.data.data;
    } catch (e) {
        console.log(e);
        employeeErrors.value = handleFetchErrors(e, 'Error while updating employee.');
    }
    scrollToTop();
    isEmployeeUpdating.value = false;
};

function resetEmployeeDetails() {
    employee.value = {
        ...employee.value,
        CIVIL_STATUS: null,
        PRESENT_ADDRESS: null,
        PERMANENT_ADDRESS: null,
        SPOUSE_NAME: null,
        MOTHER_MAIDEN_NAME: null
    };
}

async function fetchDependents() {
    try {
        const dependentsResponse = await axios.get(route('api.employees.dependents.index', {
            employee: employee.value.EMPID
        }));
        dependents.value = dependentsResponse.data.data;
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, 'Error while fetching dependents.');
        scrollToTop();
    }
}

function employeeDependentRowOnClick(clickedDependent) {
    dependent.value = clickedDependent;
}

const saveDependent = async () => {
    isSaving.value = true;
    try {
        await axios.post(route('api.employees.dependents.store', {
            employee: employee.value.EMPID
        }), dependent.value);
        addFlashMessage('SUCCESS', 'Successfully added dependent.');
        fetchDependents();
        dependent.value = {};
        errors.value = {};
    } catch (e) {
        console.log(e);
        errors.value = handleFetchErrors(e, 'Error while saving dependent.');
    }
    scrollToTop();
    isSaving.value = false;
};

const removeDependent = async (id) => {
    isDeleting.value = true;
    try {
        await axios.delete(route('api.employees.dependents.destroy', {
            employee: employee.value.EMPID,
            dependent: dependent.value.id
        }));
        addFlashMessage('SUCCESS', 'Successfully removed dependent.');
        fetchDependents();
        dependent.value = {};
        errors.value = {};
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, 'Error while removing dependent.');
    }
    scrollToTop();
    isDeleting.value = false;
};

function resetDependentFields() {
    dependent.value = {};
}
</script>
