<template>
    <div v-if="isOpen" class="pop-up d-flex justify-content-center align-items-center">
        <Card>
            <template #header>
                <div class="d-flex justify-content-between pt-2">
                    <h4 class="title">Upload Images</h4>
                    <button type="button" class="btn-close" @click="emit('close')"></button>
                </div>
            </template>
            <p><b>NOTE:</b> Make sure filename matches the corresponding Style.</p>
            <div class="mb-3">
                <label for="imageFileInput" class="form-label">Images</label>
                <input type="file" multiple required ref="imageFilesInput"
                    accept="image/png, image/gif, image/jpeg"
                    @change="e => { imageFiles = e.target.files; }"
                    class="form-control form-control-sm" />
            </div>
            <div class="text-end">
                <button type="button" class="btn btn-primary"
                    :disabled="isUploading"
                    @click="uploadImages">
                    <span v-if="isUploading" class="spinner-border spinner-border-sm"
                        role="status" aria-hidden="true"></span>
                    Upload
                </button>
            </div>
            <div v-if="currentUploadingImageFileName" class="text-center mb-3">
                <span class="spinner-border spinner-border-sm"
                    role="status" aria-hidden="true"></span>
                Currently uploading {{ currentUploadingImageFileName }}...
            </div>
            <div class="row g-3">
                <div class="col-md-6">
                    <h5 class="text-success">Successfully Uploaded</h5>
                    <DataTable :value="successImages" paginator :rows="10"
                        :pt="{ table: { class: 'table table-sm table-bordered table-hover' }}">
                        <Column field="name" header="Name" />
                        <template #empty>
                            <div class="text-center">
                                No data yet.
                            </div>
                        </template>
                    </DataTable>
                </div>
                <div class="col-md-6">
                    <h5 class="text-danger">For Correction of Name (no STYLE found)</h5>
                    <DataTable :value="errorImages" paginator :rows="10" edit-mode="cell"
                        @cell-edit-complete="onErrorTableCellEditComplete"
                        :pt="{ table: { class: 'table table-sm table-bordered table-hover' }}">
                        <Column field="name" header="Name">
                            <template #editor="{ data, field }">
                                <FormInput type="text" v-model="data[field]"
                                    input-class="form-control-sm"
                                    hide-label is-horizontal />
                            </template>
                        </Column>
                        <Column field="status" header="Status">
                            <template #body="{ data }">
                                <span v-if="data.status == 'NO_STYLE'" class="badge bg-danger">
                                    No Style
                                </span>
                                <span v-else-if="data.status == 'UPLOADING'" class="badge bg-primary">
                                    Uploading
                                </span>
                            </template>
                        </Column>
                        <template #empty>
                            <div class="text-center">
                                No data yet.
                            </div>
                        </template>
                    </DataTable>
                    <div class="text-end">
                        <button type="button" class="btn btn-primary"
                            :disabled="isUploading"
                            @click="uploadErrorImages">
                            <span v-if="isUploading" class="spinner-border spinner-border-sm"
                                role="status" aria-hidden="true"></span>
                            Reupload
                        </button>
                    </div>
                </div>
            </div>
        </Card>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';

import { useStyles } from '@/composables/data/styles';

import Card from '@/components/utils/Card.vue';
import FormInput from '@/components/utils/FormInput.vue';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';

const props = defineProps({
    isOpen: Boolean,
});

const emit = defineEmits(['error', 'close']);

const { postStyleImage } = useStyles();

const imageFilesInput = ref(null);
const imageFiles = ref(null);

const currentUploadingImageFileName = ref(null);

const successImages = ref(null);
const errorImages = ref(null);

const isUploading = ref(false);

async function postImages(imageFiles) {
    isUploading.value = true;

    const withSuccess = [];
    const withErrors = [];

    for(const imageFile of imageFiles) {
        try {
            const formData = new FormData();
            formData.append('image', imageFile);
            currentUploadingImageFileName.value = imageFile.name;
            await postStyleImage(imageFile.name.split('.')[0], formData);
            withSuccess.unshift({ name: imageFile.name });
        } catch(e) {
            currentUploadingImageFileName.value = null;
            if(e.status == 404) {
                withErrors.push({ name: imageFile.name, status: 'NO_STYLE', actualFile: imageFile });
            } else {
                throw e;
            }
        }
    }
    currentUploadingImageFileName.value = null;
    isUploading.value = false;

    return { withSuccess, withErrors };
}

async function uploadImages() {
    successImages.value = [];
    errorImages.value = [];
    
    try {
        const { withSuccess, withErrors } = await postImages(imageFiles.value);
        successImages.value = withSuccess;
        errorImages.value = withErrors;
    } catch(e) {
        emit('error', e.message);
    }
}

function onErrorTableCellEditComplete(event) {
    const { data, newValue, field } = event;
    data[field] = newValue;

    data.actualFile = new File(
        [data.actualFile.slice(0, data.actualFile.size, data.actualFile.type)],
        newValue, { type: data.actualFile.type });
}

async function uploadErrorImages() {
    errorImages.value = errorImages.value.map((errorImage, index) => ({ ...errorImage, index, status: 'UPLOADING' }));
    const toReupload = [...errorImages.value.map(errorImage => {
        errorImage.actualFile.index = errorImage.index;
        return errorImage.actualFile;
    })];

    try {
        const { withSuccess, withErrors } = await postImages(toReupload);
        successImages.value = withSuccess.concat(successImages.value);
        errorImages.value = withErrors;
    } catch(e) {
        emit('error', e.message);
    }
}

watch(() => props.isOpen, () => {
    if(props.isOpen) {
        imageFiles.value = null;

        successImages.value = [];
        errorImages.value = [];
    }
});
</script>

<style scoped>
.pop-up {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: rgb(0, 0, 0, 0.5);
}

.pop-up .card {
    width: 100%;
}

@media (min-width: 768px) {
    .pop-up .card {
        width: 85%;
    }
}

@media (min-width: 992px) {
    .pop-up .card {
        width: 70%;
    }
}
</style>
