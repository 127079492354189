<template>

    <div class="row g-3 mb-3">
        <div class="col-md-6">
            <form @submit.prevent="saveNewBarcodeInput">
                <Card>
                    <template #header>
                        Input Styles
                    </template>
                    <div class="row g-1">
                        <div class="col">
                            <FormInput type="text" label="Style" v-model="newBarcodeInput.STYLE"
                                id-prefix="style" class="mb-3" input-class="form-control-sm"
                                :errors="errors.STYLE"
                                @keydown-enter="focusNextInput('colorFormInput')" />
                        </div>
                        <div class="col">
                            <FormInput type="text" label="Color" v-model="newBarcodeInput.COLOR"
                                id-prefix="color" class="mb-3" input-class="form-control-sm"
                                :errors="errors.COLOR"
                                @keydown-enter="focusNextInput('dateFormInput')" />
                        </div>
                        <div class="col">
                            <FormInput type="year" label="Date" v-model="newBarcodeInput.DATE"
                                id-prefix="date" class="mb-3" input-class="form-control-sm"
                                :errors="errors.DATE"
                                @keydown-enter="focusNextInput('countFormInput')" />
                        </div>
                        <div class="col">
                            <FormInput type="number" label="Count" v-model="newBarcodeInput.COUNT"
                                id-prefix="count" class="mb-3" input-class="form-control-sm"
                                :errors="errors.COUNT"
                                @keydown-enter="focusNextInput('priceFormInput')" />
                        </div>
                        <div class="col">
                            <FormInput type="number" step=".02" label="Price" v-model="newBarcodeInput.PRICE"
                                id-prefix="price" class="mb-3" input-class="form-control-sm"
                                :errors="errors.PRICE"
                                @keydown-enter="focusNextInput('enterToTableButton')" />
                        </div>
                    </div>

                    <template #footer>
                        <div class="text-end">
                            <button type="submit" class="btn btn-sm btn-primary" id="enterToTableButton">
                                Enter to Table
                            </button>
                        </div>
                    </template>
                </Card>
            </form>
        </div>
    </div>
    <div class="row g-3">
        <div class="col-md-6">
            <Card class="h-100">
                <template #header>
                    Barcodes to Generate
                </template>
                <DataTable :value="barcodesToGenerate" paginator :rows="10"
                    filter-display="row" v-model:filters="filters.barcodesToGenerate"
                    :pt="{ table: { class: 'table table-bordered table-hover' } }">
                    <Column field="STYLE" header="Style" :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' } }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText type="text" v-model="filterModel.value" @keydown="filterCallback()"
                                class="form-control" placeholder="Search" />
                            <Button type="button" class="btn btn-primary" @click="filterCallback()">
                                Search
                            </Button>
                        </template>
                    </Column>
                    <Column field="COLOR" header="Color" :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' } }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText type="text" v-model="filterModel.value" @keydown="filterCallback()"
                                class="form-control" placeholder="Search" />
                            <Button type="button" class="btn btn-primary" @click="filterCallback()">
                                Search
                            </Button>
                        </template>
                    </Column>
                    <Column field="DATE" header="Date" :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' } }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText type="text" v-model="filterModel.value" @keydown="filterCallback()"
                                class="form-control" placeholder="Search" />
                            <Button type="button" class="btn btn-primary" @click="filterCallback()">
                                Search
                            </Button>
                        </template>
                    </Column>
                    <Column field="COUNT" header="Count" :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' } }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText type="text" v-model="filterModel.value" @keydown="filterCallback()"
                                class="form-control" placeholder="Search" />
                            <Button type="button" class="btn btn-primary" @click="filterCallback()">
                                Search
                            </Button>
                        </template>
                    </Column>
                    <Column field="PRICE" header="Price" :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' } }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText type="text" v-model="filterModel.value" @keydown="filterCallback()"
                                class="form-control" placeholder="Search" />
                            <Button type="button" class="btn btn-primary" @click="filterCallback()">
                                Search
                            </Button>
                        </template>
                    </Column>
                    <template #empty>No inputs.</template>
                </DataTable>
                <template #footer>
                    <div class="text-end">
                        <button type="button" class="btn btn-sm btn-outline-danger me-2"
                            @click="clearBarcodesToGenerate">
                            Clear
                        </button>
                        <button type="button" class="btn btn-sm btn-primary"
                            @click="generateBarcodes">
                            Generate Barcodes
                        </button>
                    </div>
                </template>
            </Card>
        </div>
        <div class="col-md-6">
            <Card class="h-100">
                <template #header>
                    Generated Barcodes
                </template>
                <DataTable :value="generatedBarcodes" paginator :rows="10"
                    filter-display="row" v-model:filters="filters.generatedBarcodes"
                    :pt="{ table: { class: 'table table-bordered table-hover' } }">
                    <Column field="BARCODE" header="Barcode" :pt="{
                            bodyCell: { class: 'barcode' },
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' } }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText type="text" v-model="filterModel.value" @keydown="filterCallback()"
                                class="form-control" placeholder="Search" />
                            <Button type="button" class="btn btn-primary" @click="filterCallback()">
                                Search
                            </Button>
                        </template>
                    </Column>
                    <Column field="COLOR" header="Color" :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' } }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText type="text" v-model="filterModel.value" @keydown="filterCallback()"
                                class="form-control" placeholder="Search" />
                            <Button type="button" class="btn btn-primary" @click="filterCallback()">
                                Search
                            </Button>
                        </template>
                    </Column>
                    <Column field="PRICE" header="Price" :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' } }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText type="text" v-model="filterModel.value" @keydown="filterCallback()"
                                class="form-control" placeholder="Search" />
                            <Button type="button" class="btn btn-primary" @click="filterCallback()">
                                Search
                            </Button>
                        </template>
                    </Column>
                    <template #empty>No barcodes.</template>
                </DataTable>
                <template #footer>
                    <div class="text-end">
                        <button type="button" class="btn btn-sm btn-outline-danger me-2"
                            @click="clearGeneratedBarcodes">
                            Clear
                        </button>
                    </div>
                </template>
            </Card>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';

import Card from '../../../utils/Card.vue';
import FormInput from '../../../utils/FormInput.vue';

import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';
import { FilterMatchMode } from 'primevue/api';

import focusNextInput from '../../../../utils/focusnext';
import handleFetchErrors from "../../../../utils/handleFetchErrors";
import scrollToTop from '../../../../utils/scrollToTop';

import { useFlashMessages } from '../../../../composables/flashmessages';

const { addFlashMessage } = useFlashMessages();

const filters = ref({
    barcodesToGenerate: {
        STYLE: { value: null, matchMode: FilterMatchMode.CONTAINS },
        COLOR: { value: null, matchMode: FilterMatchMode.CONTAINS },
        DATE: { value: null, matchMode: FilterMatchMode.CONTAINS },
        COUNT: { value: null, matchMode: FilterMatchMode.CONTAINS },
        PRICE: { value: null, matchMode: FilterMatchMode.CONTAINS },
    },
    generatedBarcodes: {
        BARCODE: { value: null, matchMode: FilterMatchMode.CONTAINS },
        COLOR: { value: null, matchMode: FilterMatchMode.CONTAINS },
        PRICE: { value: null, matchMode: FilterMatchMode.CONTAINS },
    },
});

const barcodesToGenerate = ref();
const generatedBarcodes = ref();

const errors = ref({});

const newBarcodeInput = ref({});

async function fetchBarcodesToGenerate() {
    try {
        const newBarcodeInputsResponse = await axios.get(route('api.new-barcode-inputs.index'));
        barcodesToGenerate.value = newBarcodeInputsResponse.data.data;
    } catch(e) {
        console.log(e);
        handleFetchErrors(e, 'Error while fetching barcodes to generate.');
        scrollToTop();
    }
}

async function fetchGeneratedBarcodes() {
    try {
        const newBarcodeOutputsResponse = await axios.get(route('api.new-barcode-outputs.index'));
        generatedBarcodes.value = newBarcodeOutputsResponse.data.data;
    } catch(e) {
        console.log(e);
        handleFetchErrors(e, 'Error while fetching generated barcodes.');
        scrollToTop();
    }
}

onMounted(() => {
    fetchBarcodesToGenerate();
    fetchGeneratedBarcodes();
});

async function saveNewBarcodeInput() {
    errors.value = {};
    try {
        await axios.post(route('api.new-barcode-inputs.store'), newBarcodeInput.value);
        addFlashMessage('SUCCESS', 'Successfully saved style for barcode generation.');
        newBarcodeInput.value = {};
        fetchBarcodesToGenerate();
    } catch(e) {
        console.log(e);
        errors.value = handleFetchErrors(e, 'Error while saving new barcode input.');
    }
    scrollToTop();
}

async function generateBarcodes() {
    try {
        const newBarcodeOutputsResponse = await axios.post(route('api.new-barcode-inputs.movements.store'));
        generatedBarcodes.value = newBarcodeOutputsResponse.data.data;
        addFlashMessage('SUCCESS', 'Successfully generated barcodes.');
    } catch(e) {
        console.log(e);
        handleFetchErrors(e, 'Error while generating barcodes.');
    }
    scrollToTop();
}

async function clearBarcodesToGenerate() {
    if(window.prompt('Type "CLEAR" to clear table.') != 'CLEAR')
        return;

    try {
        await axios.delete(route('api.new-barcode-inputs.destroy-all'));
        addFlashMessage('SUCCESS', 'Successfully cleared barcodes to generate.');
        fetchBarcodesToGenerate();
    } catch(e) {
        console.log(e);
        handleFetchErrors(e, 'Error while deleting barcodes to generate.');
    }
    scrollToTop();
}

async function clearGeneratedBarcodes() {
    if(window.prompt('Type "CLEAR" to clear table.') != 'CLEAR')
        return;

    try {
        await axios.delete(route('api.new-barcode-outputs.destroy-all'));
        addFlashMessage('SUCCESS', 'Successfully cleared generated barcodes.');
        fetchGeneratedBarcodes();
    } catch(e) {
        console.log(e);
        handleFetchErrors(e, 'Error while deleting generated barcodes.');
    }
    scrollToTop();
}
</script>
