<template>
    <Card class="mb-3">
        <template #header>
            Style Landmark
        </template>
        <form>
            <div v-if="isLoading" class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
            <FormInput label="Landmark SKU" type="text" v-model="landmarkSku.LANDMARK_SKU"
                is-horizontal id-prefix="landmarkSku" class="mb-3"
                input-class="form-control-sm" :errors="errors.LANDMARK_SKU" />
            <button type="button" class="btn btn-primary me-1"
                :disabled="!style.STYLE || isLoading || isSubmitting"
                @click="saveButtonOnClick">
                <span v-if="isSubmitting" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Save
            </button>
        </form>
    </Card>
</template>

<script setup>
import { ref, watch } from 'vue';

import Card from '../../../utils/Card.vue';
import FormInput from '../../../utils/FormInput.vue';

import axios from 'axios';

import handleFetchErrors from "../../../../utils/handleFetchErrors";
import scrollToTop from "../../../../utils/scrollToTop";

import { useFlashMessages } from "../../../../composables/flashmessages";

const { addFlashMessage } = useFlashMessages();

const props = defineProps({
    style: Object,
    class: String
});

const errors = ref({});

const landmarkSku = ref({});

const isLoading = ref(false);
const isSubmitting = ref(false);

watch(() => props.style, async () => {
    errors.value = {};
    landmarkSku.value = {};
    if(props.style?.STYLE == null)
        return;

    isLoading.value = true;
    try {
        const landmarkSkuResponse = await axios.get(
            route('api.styles.landmark-sku.index', {
                style: props.style.STYLE
            }));
        landmarkSku.value = landmarkSkuResponse.data.data;
    } catch(e) {
        console.log(e);
        landmarkSku.value = {};
        if(!e.response || e.response.status != 404) {
            handleFetchErrors(e, 'Error while fetching Landmark SKU.');
            scrollToTop();
        }
    }
    isLoading.value = false;
});

async function saveButtonOnClick() {
    errors.value = {};
    isSubmitting.value = true;
    try {
        const saveLandmarkSkuResponse = await axios.post(
            route('api.styles.landmark-sku.store', {
                style: props.style.STYLE
            }), landmarkSku.value);
        landmarkSku.value = saveLandmarkSkuResponse.data.data;
        addFlashMessage('SUCCESS', 'Successfully saved Landmark SKU ' +
            landmarkSku.value.LANDMARK_SKU + '. ');
    } catch(e) {
        console.log(e);
        errors.value = handleFetchErrors(e, 'Error while saving Landmark SKU.');
    }
    isSubmitting.value = false;
    scrollToTop();
}
</script>
