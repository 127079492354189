<template>
    <Card>
        <template #header>
            Dates
        </template>
        <DataTable lazy :loading="isLoading" :value="inventoryDates" :total-records="totalRecords"
            paginator :rows="10" @page="e => { onPage(e); fetchInventoryDates(); }"
            filter-display="row" v-model:filters="filters" @filter="tableOnFilter"
            v-model:selection="selectedDate" selection-mode="single"
            sort-mode="multiple" @sort="e => { onSort(e); fetchInventoryDates(); }"
            :pt="{ table: { class: 'table table-bordered table-hover' } }">
            <Column field="DATE" header="Date" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                    <Button type="button" class="btn btn-primary" @click="filterCallback()">
                        Search
                    </Button>
                </template>
            </Column>
            <template #empty>
                <div class="text-center">
                    No data.
                </div>
            </template>
        </DataTable>
    </Card>
</template>

<script setup>
import { onMounted, ref } from 'vue';

import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useInventoryDates } from '@/composables/data/inventoryDates';

import Card from '@/components/utils/Card';

import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

const emit = defineEmits(['error']);

const { params, filters, onPage, onFilter, onSort } = useDataTableParams(['DATE']);

const { inventoryDates, totalRecords, getInventoryDates } = useInventoryDates();

const selectedDate = defineModel();

const isLoading = ref(false);

const fetchTimeout = ref(null);

async function fetchInventoryDates() {
    inventoryDates.value = null;

    isLoading.value = true;
    try {
        await getInventoryDates(params.value);
    } catch(e) {
        emit('error', e.message);
    }
    isLoading.value = false;
}

async function tableOnFilter(e) {
    onFilter(e);
    if(fetchTimeout.value)
        clearTimeout(fetchTimeout.value);
    fetchTimeout.value = setTimeout(fetchInventoryDates, 300);
}

onMounted(fetchInventoryDates);
</script>
