<template>
    <div v-if="isOpen" class="pop-up d-flex justify-content-center align-items-center">
        <Card>
            <template #header>
                <div class="d-flex justify-content-between pt-2">
                    <h4 class="title">Voucher Image</h4>
                    <button type="button" class="btn-close" @click="emit('close')"></button>
                </div>
            </template>
            <div v-if="noImageFound" class="text-center text-danger">
                No Gov't. Voucher uploaded with this name. Please upload first using the Update Voucher page.
            </div>
            <div v-else-if="isImageLoading" class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
            <img v-if="!noImageFound" :src="imageURL" :alt="govtVoucherId + ' voucher image'"
                @load="_ => { isImageLoading = false; }"
                @error="noImageFound" />
        </Card>
    </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';

import Card from "@/components/utils/Card.vue";

const props = defineProps({
    govtVoucherId: String,
    isOpen: Boolean,
});

const emit = defineEmits(['error', 'close']);

const imageURL = ref(null);
const isImageLoading = ref(false);

const noImageFound = ref(false);

async function loadImage() {
    imageURL.value = null;

    if(!props.govtVoucherId) {
        return;
    }

    noImageFound.value = false;
    try {
        isImageLoading.value = true;
        imageURL.value = (await axios.get(route('api.vouchers.govt.image.show', {
            govt_voucher_id: props.govtVoucherId
        }))).data.data.url;
    } catch(e) {
        isImageLoading.value = false;
        if(e.response.status != 404) {
            emit('error', e.message);
        } else {
            noImageFound.value = true;
        }
    }
}

onMounted(loadImage);

watch(() => [props.isOpen, props.govtVoucherId], () => {
    if(props.isOpen) {
        loadImage();
    }
});
</script>

<style scoped>
.pop-up {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: rgb(0, 0, 0, 0.5);
}

.pop-up .card {
    width: 100%;
}

@media (min-width: 768px) {
    .pop-up .card {
        width: 85%;
    }
}

@media (min-width: 992px) {
    .pop-up .card {
        width: 70%;
    }
}
</style>
