<template>
    <div v-if="isOpen" class="pop-up d-flex justify-content-center align-items-center">
        <Card>
            <template #header>
                <div class="d-flex justify-content-between pt-2">
                    <h4 class="title">Select Employee</h4>
                    <button type="button" class="btn-close" @click="emit('close')"></button>
                </div>
            </template>
            <DataTable :loading="isLoading" :value="employees"
                paginator :rows="10"
                v-model:filters="filters" filter-display="row"
                v-model:selection="selectedEmployee" selection-mode="single"
                :pt="{ table: { class: 'table table-bordered table-hover' } }">
                <Column field="EMPID" header="ID"
                    :pt="{ filterInput: { class: 'input-group input-group-sm' },
                        filterMenuButton: { class: 'd-none' },
                        headerFilterClearButton: { class: 'd-none' } }">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                            class="form-control" placeholder="Search" />
                    </template>
                </Column>
                <Column field="FNAME" header="First Name"
                    :pt="{ filterInput: { class: 'input-group input-group-sm' },
                        filterMenuButton: { class: 'd-none' },
                        headerFilterClearButton: { class: 'd-none' } }">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                            class="form-control" placeholder="Search" />
                    </template>
                </Column>
                <Column field="LNAME" header="Last Name"
                    :pt="{ filterInput: { class: 'input-group input-group-sm' },
                        filterMenuButton: { class: 'd-none' },
                        headerFilterClearButton: { class: 'd-none' } }">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                            class="form-control" placeholder="Search" />
                    </template>
                </Column>
                <Column field="DEPT" header="Dept"
                    :pt="{ filterInput: { class: 'input-group input-group-sm' },
                        filterMenuButton: { class: 'd-none' },
                        headerFilterClearButton: { class: 'd-none' } }">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                            class="form-control" placeholder="Search" />
                    </template>
                </Column>
                <template #loading>
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    Loading employees...
                </template>
                <template #empty>No employees.</template>
            </DataTable>
            <template #footer>
                <div class="d-grid">
                    <button type="button" class="btn btn-primary"
                        :disabled="!selectedEmployee"
                        @click="_ => { emit('select', selectedEmployee); }">
                        Select Employee
                    </button>
                </div>
            </template>
        </Card>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';

import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useEmployees } from '@/composables/data/employees';

import Card from "@/components/utils/Card.vue";

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

const props = defineProps({
    isOpen: Boolean,
    startTimePeriod: Number,
    endTimePeriod: Number,
    location: String,
});

const emit = defineEmits(['select', 'error', 'close']);

const { filters } = useDataTableParams(['EMPID', 'FNAME', 'LNAME', 'DEPT']);

const { employees, getEmployeesEligibleForCommission } = useEmployees();

const selectedEmployee = ref(null);

const isLoading = ref(false);

watch(() => [props.startTimePeriod, props.endTimePeriod, props.location], async () => {
    employees.value = null;

    if(!props.startTimePeriod || !props.endTimePeriod || !props.location)
        return;

    isLoading.value = true;
    try {
        await getEmployeesEligibleForCommission(props.startTimePeriod, props.endTimePeriod, props.location);
    } catch(e) {
        emit('error', e.message);
    }
    isLoading.value = false;
});
</script>

<style scoped>
.pop-up {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: rgb(0, 0, 0, 0.5);
}

.pop-up .card {
    width: 100%;
}

@media (min-width: 768px) {
    .pop-up .card {
        width: 85%;
    }
}

@media (min-width: 992px) {
    .pop-up .card {
        width: 70%;
    }
}
</style>
