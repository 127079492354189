// New component handler mechanism. Returns directly an object with a message and optional validation errors.
// TODO: Refactor current fetchers to use this
export default function constructFetchError(e, genericErrorMessage) {
    const details = {};
    if(e.response) {
        details.status = e.response.status;
        details.message = `${genericErrorMessage} Message: ${e.response.data.message}`;
        if(e.response.status == 422)
            details.errors = e.response.data.errors;
    } else {
        details.message = `${genericErrorMessage} Please contact your administrator.`;
    }
    return details;
}
