<template>
    <div v-if="isOpen" class="pop-up d-flex justify-content-center align-items-center">
        <form @submit.prevent="updatePriceOfBarcodeSequence">
            <Card>
                <template #header>
                    <div class="d-flex justify-content-between pt-2">
                        <h4 class="title">Change Price</h4>
                        <button type="button" class="btn-close" @click="emit('close')"></button>
                    </div>
                </template>
                <FormInput type="number" step=".01" v-model="price"
                    label="New Price" id-prefix="price"
                    :errors="errors.PRICE" />
                <template #footer>
                    <div class="d-grid">
                        <button type="submit" class="btn btn-primary"
                            :disabled="isUpdating">
                            <span v-if="isUpdating" class="spinner-border spinner-border-sm" role="status"
                                aria-hidden="true"></span>
                            Update
                        </button>
                    </div>
                </template>
            </Card>
        </form>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';

import { useInventory } from '@/composables/data/inventory';

import Card from "@/components/utils/Card.vue";
import FormInput from "@/components/utils/FormInput.vue";

const { updatePrice } = useInventory();

const props = defineProps({
    inventoryRecord: Object,
    isOpen: Boolean,
});

const emit = defineEmits(['update']);

const price = ref(null);

const errors = ref({});

const isUpdating = ref(false);

async function updatePriceOfBarcodeSequence() {
    errors.value = {};
    isUpdating.value = true;
    try {
        await updatePrice(props.inventoryRecord.STYLE, props.inventoryRecord.DATE,
            props.inventoryRecord.SERIAL, props.inventoryRecord.SEQ_NO,
            price.value);
        emit('success', 'Successfully updated price.');
    } catch(e) {
        errors.value = e.errors ?? {};
        if(e.status != 422)
            emit('error', e.message);
    }
    isUpdating.value = false;
}

watch(() => props.isOpen, () => {
    price.value = null;
});
</script>

<style scoped>
.pop-up {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: rgb(0, 0, 0, 0.5);
}
</style>
