<template>
    <div class="row justify-content-center">
        <div class="col-md-6">
            <Card>
                <template #header>
                    Interval Reports
                </template>
                <FormInput type="date" v-model="startDate"
                    label="Start Date" id-prefix="startDate"
                    :errors="errors.start_date"
                    class="mb-3" />
                <FormInput type="date" v-model="endDate"
                    label="End Date" id-prefix="endDate"
                    :errors="errors.end_date"
                    class="mb-3" />
                <FormInput type="number" v-model="empid" disabled
                    label="Employee ID" id-prefix="empid"
                    :errors="errors.empid"
                    class="mb-1" />
                <div class="text-end">
                    <button type="button" class="btn btn-primary"
                        @click="_ => { isSelectEmployeePopupOpen = true; }">
                        Select
                    </button>
                </div>
                <template #footer>
                    <div class="text-end">
                        <button type="button" class="btn btn-primary me-1"
                            :disabled="!startDate || !endDate"
                            @click="generateIntervalReport('dow')">
                            Days of Work
                        </button>
                        <button type="button" class="btn btn-primary"
                            :disabled="!startDate || !endDate"
                            @click="generateIntervalReport('incentives')">
                            Incentive
                        </button>
                    </div>
                </template>
            </Card>
        </div>
    </div>
    <SelectEmployee :is-open="isSelectEmployeePopupOpen"
        :on-close="_ => { isSelectEmployeePopupOpen = false; }"
        :on-select-employee="employee => {
            isSelectEmployeePopupOpen = false;
            empid = employee.EMPID;
        }" />
</template>

<script setup>
import { ref } from 'vue';

import Card from '@/components/utils/Card.vue';
import FormInput from '@/components/utils/FormInput.vue';

import SelectEmployee from '@/components/utils/SelectEmployee.vue';

const startDate = ref(null);
const endDate = ref(null);
const empid = ref(null);

const errors = ref({});

const isSelectEmployeePopupOpen = ref(false);

function generateIntervalReport(type) {
    window.open(route('api.employees.interval-report.show', {
        START_DATE: startDate.value,
        END_DATE: endDate.value,
        EMPID: empid.value,
        TYPE: type
    }));
}
</script>
