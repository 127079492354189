<template>
    <div class="row g-3 mb-3">
        <div class="col-lg-8">
            <fieldset class="h-100">
                <legend>
                    <i class="icon ph-bold ph-barcode me-2"></i>BARCODE Reports
                </legend>
                <div class="row g-1">
                    <div class="col-4 mb-3">
                        <button type="submit"
                            class="btn btn-sm btn-primary w-100 px-1"
                            @click="searchBarcode">
                            <i class="icon ph-bold ph-magnifying-glass me-2"></i>SEARCH
                        </button>
                    </div>
                    <div class="col-8 mb-3">
                        <FormInput
                            type="text"
                            v-model="barcodeInput"
                            label="Barcode"
                            hide-label
                            id-prefix="barcodeInput"
                            @keydown-enter="searchBarcode"
                            input-class="form-control-sm"
                        />
                    </div>
                </div>
                <div class="row g-1">
                    <div class="col-xl-4 col-md-6 col-12 d-grid">
                        <button class="btn btn-primary btn-sm w-100"
                            :disabled="!style || !date || !serial || isDeletingBarcode"
                            @click="deleteBarcode">
                            <span v-if="isDeletingBarcode"
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true">
                            </span>
                            <i class="icon ph-bold ph-trash me-2"></i>DELETE BARCODE
                        </button>
                    </div>
                    <div class="col-xl-4 col-md-6 col-12 d-grid">
                        <button class="btn btn-primary btn-sm w-100"
                            :disabled="!style || !date || !serial || isUpdatingColor"
                            @click="(_) => { isUpdateColorPopupOpen = true; }">
                            <span v-if="isUpdatingColor"
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true">
                            </span>
                            <i class="icon ph-bold ph-palette me-2"></i>CHANGE COLOR
                        </button>
                    </div>
                    <div class="col-xl-4 col-md-6 col-12 d-grid">
                        <button class="btn btn-primary btn-sm w-100"
                            :disabled="!selectedInventoryRecord?.SOLD_DT"
                            @click="(_) => { isUpdateSoldDatePopupOpen = true; }">
                            <i class="icon ph-bold ph-calendar-check me-2"></i>CHANGE SOLD_DT
                        </button>
                    </div>
                    <div class="col-xl-4 col-md-6 col-12 d-grid">
                        <button class="btn btn-primary btn-sm w-100"
                            :disabled="!selectedInventoryRecord"
                            @click="(_) => { isUpdateSaleTypePopupOpen = true; }">
                            <i class="icon ph-bold ph-tag me-2"></i>CHANGE SALE_TYPE
                        </button>
                    </div>
                    <div class="col-xl-4 col-md-6 col-12 d-grid">
                        <button class="btn btn-primary btn-sm w-100"
                            :disabled="!selectedInventoryRecord?.PRICE || selectedInventoryRecord.PRICE == 0"
                            @click="(_) => { isUpdatePricePopupOpen = true; }">
                            <i class="icon ph-bold ph-currency-dollar me-2"></i>CHANGE PRICE
                        </button>
                    </div>
                    <div class="col-xl-4 col-md-6 col-12 d-grid">
                        <button class="btn btn-primary btn-sm w-100"
                            :disabled="!selectedInventoryRecord?.SOLD_DT"
                            @click="(_) => { isUpdateSellerPopupOpen = true; }">
                            <i class="icon ph-bold ph-user me-2"></i>CHANGE SELLER
                        </button>
                    </div>
                    <div class="col-xl-4 col-md-6 col-12 d-grid">
                        <button class="btn btn-primary btn-sm w-100"
                            :disabled="!style || !date || !serial || isDeletingLastSaleOrReturn"
                            @click="deleteCurrentSaleOrReturnOfBarcode">
                            <span v-if="isDeletingLastSaleOrReturn"
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true">
                            </span>
                            <i class="icon ph-bold ph-clock me-2"></i>DELETE LAST (SALE/RETURN)
                        </button>
                    </div>
                    <div class="col-xl-4 col-md-6 col-12 d-grid">
                        <button class="btn btn-primary btn-sm w-100"
                            :disabled="!style || !date || !serial || isDeletingLastRecord"
                            @click="deleteCurrentRecordOfBarcode">
                            <span v-if="isDeletingLastRecord"
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true">
                            </span>
                            <i class="icon ph-bold ph-file me-2"></i>DELETE LAST RECORD
                        </button>
                    </div>
                </div>
            </fieldset>
        </div>
        <div class="col-lg-4">
            <InventoryChecksOfBarcodeTableCard
                :barcode="barcodeSearch"
                :refresh-flag="refreshFlag"
                @error="(message) => { addFlashMessage('ERROR', message); scrollToTop(); }"
                class="h-100"
            />
        </div>
    </div>
    <InventoryRecordsOfBarcodeTableCard
        :style="style"
        :date="date"
        :serial="serial"
        :refresh-flag="refreshFlag"
        v-model="selectedInventoryRecord"
    />
    <!-- For updating color-->
    <SelectColor
        :is-open="isUpdateColorPopupOpen"
        @select="updateColorOfBarcode"
        @close="(_) => { isUpdateColorPopupOpen = false; }"
        @error="
            (message) => {
                isUpdateColorPopupOpen = false;
                isUpdatingColor = false;
                addFlashMessage('ERROR', message);
                scrollToTop();
            }
        "
    />
    <UpdateSoldDateFormPopup
        :is-open="isUpdateSoldDatePopupOpen"
        :inventory-record="selectedInventoryRecord"
        @close="(_) => { isUpdateSoldDatePopupOpen = false; }"
        @success="
            (message) => {
                isUpdateSoldDatePopupOpen = false;
                refreshFlag = !refreshFlag;
                addFlashMessage('SUCCESS', message);
                scrollToTop();
            }
        "
        @error="
            (message) => {
                isUpdateSoldDatePopupOpen = false;
                addFlashMessage('ERROR', message);
                scrollToTop();
            }
        "
    />
    <UpdateSaleTypeFormPopup
        :is-open="isUpdateSaleTypePopupOpen"
        :inventory-record="selectedInventoryRecord"
        @close="(_) => { isUpdateSaleTypePopupOpen = false; }"
        @success="
            (message) => {
                isUpdateSaleTypePopupOpen = false;
                refreshFlag = !refreshFlag;
                addFlashMessage('SUCCESS', message);
                scrollToTop();
            }
        "
        @error="
            (message) => {
                isUpdateSaleTypePopupOpen = false;
                addFlashMessage('ERROR', message);
                scrollToTop();
            }
        "
    />
    <UpdatePriceFormPopup
        :is-open="isUpdatePricePopupOpen"
        :inventory-record="selectedInventoryRecord"
        @close="(_) => { isUpdatePricePopupOpen = false; }"
        @success="
            (message) => {
                isUpdatePricePopupOpen = false;
                refreshFlag = !refreshFlag;
                addFlashMessage('SUCCESS', message);
                scrollToTop();
            }
        "
        @error="
            (message) => {
                isUpdatePricePopupOpen = false;
                addFlashMessage('ERROR', message);
                scrollToTop();
            }
        "
    />
    <UpdateSellerFormPopup
        :is-open="isUpdateSellerPopupOpen"
        :inventory-record="selectedInventoryRecord"
        @close="(_) => { isUpdateSellerPopupOpen = false; }"
        @success="
            (message) => {
                isUpdateSellerPopupOpen = false;
                refreshFlag = !refreshFlag;
                addFlashMessage('SUCCESS', message);
                scrollToTop();
            }
        "
        @error="
            (message) => {
                isUpdateSellerPopupOpen = false;
                addFlashMessage('ERROR', message);
                scrollToTop();
            }
        "
    />
</template>

<script setup>
import { ref } from "vue";
import { useFlashMessages } from "@/composables/flashmessages";
import { useLegacyBarcodes } from "@/composables/data/legacyBarcodes";
import { useInventory } from "@/composables/data/inventory";
import FormInput from "@/components/utils/FormInput.vue";
import InventoryChecksOfBarcodeTableCard from "./InventoryChecksOfBarcodeTableCard.vue";
import InventoryRecordsOfBarcodeTableCard from "./InventoryRecordsOfBarcodeTableCard.vue";
import SelectColor from "@/components/utils/SelectColor.vue";
import UpdateSoldDateFormPopup from "./UpdateSoldDateFormPopup.vue";
import UpdateSaleTypeFormPopup from "./UpdateSaleTypeFormPopup.vue";
import UpdatePriceFormPopup from "./UpdatePriceFormPopup.vue";
import UpdateSellerFormPopup from "./UpdateSellerFormPopup.vue";
import scrollToTop from "@/utils/scrollToTop";
import { deconstructBarcode } from "@/utils/barcodes";

const {
    updateColor,
    deleteAsSingle,
    deleteCurrentSaleOrReturn,
    deleteCurrentRecord,
} = useInventory();

const { getNewBarcode } = useLegacyBarcodes();

const { addFlashMessage } = useFlashMessages();

const barcodeInput = ref(null);
const barcodeSearch = ref(null);

const style = ref(null);
const date = ref(null);
const serial = ref(null);

const refreshFlag = ref(false);

const selectedInventoryRecord = ref(null);

const isUpdateColorPopupOpen = ref(false);
const isUpdateSoldDatePopupOpen = ref(false);
const isUpdateSaleTypePopupOpen = ref(false);
const isUpdatePricePopupOpen = ref(false);
const isUpdateSellerPopupOpen = ref(false);

const isUpdatingColor = ref(false);
const isDeletingBarcode = ref(false);
const isDeletingLastSaleOrReturn = ref(false);
const isDeletingLastRecord = ref(false);

async function searchBarcode() {
    style.value = null;
    date.value = null;
    serial.value = null;
    barcodeSearch.value = barcodeInput.value;
    refreshFlag.value = !refreshFlag.value;

    if (!barcodeInput.value) {
        addFlashMessage("ERROR", "Empty barcode.");
        scrollToTop();
        return;
    }

    // Check if barcode is legacy, then replace
    try {
        const newBarcode = await getNewBarcode(barcodeInput.value);
        alert(`Replacing Legacy Barcode ${barcodeInput.value} with ${newBarcode}.`);
        barcodeInput.value = newBarcode;
    } catch (e) {}

    barcodeSearch.value = barcodeInput.value;

    selectedInventoryRecord.value = null;

    try {
        const deconstructedBarcode = deconstructBarcode(barcodeInput.value);
        style.value = deconstructedBarcode.style;
        date.value = deconstructedBarcode.date;
        serial.value = deconstructedBarcode.serial;
    } catch (e) {
        addFlashMessage("ERROR", e.message);
        scrollToTop();
    }
}

async function updateColorOfBarcode(color) {
    isUpdateColorPopupOpen.value = false;

    isUpdatingColor.value = true;
    try {
        await updateColor(style.value, date.value, serial.value, color.COLOR);
        addFlashMessage("SUCCESS", "Successfully updated color of barcode.");
        refreshFlag.value = !refreshFlag.value;
    } catch (e) {
        addFlashMessage("ERROR", e.message);
    }
    isUpdatingColor.value = false;
    scrollToTop();
}

async function deleteBarcode() {
    if (window.prompt("Type DELETE to delete barcode.") != "DELETE") {
        return;
    }

    const description = window.prompt("Specify reason for deleting.");

    if (!description) {
        return;
    }

    isDeletingBarcode.value = true;
    try {
        await deleteAsSingle(
            style.value,
            date.value,
            serial.value,
            description
        );
        addFlashMessage("SUCCESS", "Successfully deleted barcode.");
        refreshFlag.value = !refreshFlag.value;
    } catch (e) {
        addFlashMessage("ERROR", e.message);
    }
    isDeletingBarcode.value = false;
    scrollToTop();
}

async function deleteCurrentRecordOfBarcode() {
    if (window.prompt("Type DELETE BC to delete last record.") != "DELETE BC") {
        return;
    }

    const description = window.prompt("Specify reason for deleting.");

    if (!description) {
        return;
    }

    isDeletingLastRecord.value = true;
    try {
        await deleteCurrentRecord(
            style.value,
            date.value,
            serial.value,
            description
        );
        addFlashMessage("SUCCESS", "Successfully deleted last record.");
        refreshFlag.value = !refreshFlag.value;
    } catch (e) {
        addFlashMessage("ERROR", e.message);
    }
    isDeletingLastRecord.value = false;
    scrollToTop();
}

async function deleteCurrentSaleOrReturnOfBarcode() {
    if (window.prompt("Type DELETE BC to delete last sale/return.") != "DELETE BC") {
        return;
    }

    isDeletingLastSaleOrReturn.value = true;
    try {
        await deleteCurrentSaleOrReturn(style.value, date.value, serial.value);
        addFlashMessage("SUCCESS", "Successfully deleted last sale/return.");
        refreshFlag.value = !refreshFlag.value;
    } catch (e) {
        addFlashMessage("ERROR", e.message);
    }
    isDeletingLastSaleOrReturn.value = false;
    scrollToTop();
}
</script>
