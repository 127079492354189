import constructFetchError from "./constructFetchError";

import axios from 'axios';

export function useLegacyBarcodes() {
    const getNewBarcode = async (legacyBarcode) => {
        try {
            const getNewBarcodeResponse = await axios.get(route('api.legacy-barcodes.show', {
                legacy_barcode: legacyBarcode
            }));
           return getNewBarcodeResponse.data.data.BARCODE_NEW;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching legacy barcode.');
        }
    };
    
    return { getNewBarcode };
}
