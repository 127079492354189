<template>
    <div v-if="isOpen" class="pop-up d-flex justify-content-center align-items-center">
        <Card>
            <template #header>
                <div class="d-flex justify-content-between pt-2">
                    <h4 class="title">Valid Variables</h4>
                    <button type="button" class="btn-close" @click="emit('close')"></button>
                </div>
            </template>
            <p><b>Note:</b> Avoid circular references in formulas.</p>
            <ul>
                <li>DISCOUNTS</li>
                <li>COMMISSIONS</li>
                <li>SHIPPING</li>
                <li>STORAGE</li>
                <li>UTILITIES</li>
                <li>TELEPHONE</li>
                <li>SUPPLIES</li>
                <li>SALE_EVENTS</li>
                <li>CREDITABLE_TAX</li>
                <li>UNIFORM</li>
                <li>PRICE_TAG</li>
                <li>E2E_CHARGE</li>
                <li>STORE_MAIN</li>
                <li>MAIL_POST</li>
                <li>SAL_WAGES</li>
                <li>BANK_CHARGES</li>
                <li>TRANSPORT</li>
                <li>REPRESENT</li>
                <li>MISCL</li>
                <li>ADS</li>
                <li>AR_CASH</li>
                <li>FLOATING_ADJ</li>
            </ul>
        </Card>
    </div>
</template>

<script setup>
import Card from "@/components/utils/Card.vue";

const props = defineProps({
    isOpen: Boolean,
});

const emit = defineEmits(['close']);
</script>

<style scoped>
.pop-up {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: rgb(0, 0, 0, 0.5);
}

.pop-up .card {
    width: 100%;
}

@media (min-width: 768px) {
    .pop-up .card {
        width: 85%;
    }
}

@media (min-width: 992px) {
    .pop-up .card {
        width: 70%;
    }
}
</style>
