<template>
    <div class="row g-3">
        <div class="col-md-8">
            <ColorTableCard v-model="selectedColor"
                :refresh-flag="colorTableRefreshFlag"
                @error="message => {
                    addFlashMessage('ERROR', message);
                    scrollToTop();
                }" />
        </div>
        <div class="col-md-4">
            <Card>
                <template #header>
                    Color Form
                </template>
                <FormInput type="text" v-model="color.COLOR"
                    label="Color" id-prefix="color"
                    class="mb-3"
                    @keydown-enter="focusnext('isActiveFormInput')"
                    :errors="errors.COLOR" />
                <FormSelect v-model="color.IS_ACTIVE"
                    label="Is Active" id-prefix="isActive"
                    :options="[
                        { value: 1, verbose: 'Active' },
                        { value: 0, verbose: 'Inactive' },
                    ]"
                    :option-value="option => option.value"
                    :option-to-string="option => option.verbose"
                    @keydown-enter="_ => {
                        if(selectedColor) {
                            focusnext('updateButton');
                        } else {
                            focusnext('insertButton');
                        }
                    }"
                    :errors="errors.IS_ACTIVE" />
                <template #footer>
                    <div class="text-end">
                        <button type="button" class="btn btn-primary me-2"
                            id="insertButton"
                            :disabled="selectedColor"
                            @click="insertColor">
                            Insert
                        </button>
                        <button type="button" class="btn btn-primary me-2"
                            id="updateButton"
                            :disabled="!selectedColor"
                            @click="updateColor">
                            Update
                        </button>
                        <button type="button" class="btn btn-outline-secondary"
                            @click="reset">
                            Reset
                        </button>
                    </div>
                </template>
            </Card>
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';

import { useFlashMessages } from '@/composables/flashmessages';
import { useColors } from '@/composables/data/colors';

import Card from '@/components/utils/Card.vue';
import FormInput from '@/components/utils/FormInput.vue';
import FormSelect from '@/components/utils/FormSelect.vue';

import ColorTableCard from './ColorTableCard.vue';

import scrollToTop from '@/utils/scrollToTop';
import focusnext from '@/utils/focusnext';

const { addFlashMessage } = useFlashMessages();

const colorTableRefreshFlag = ref(false);

const { postColor, putColor } = useColors();

const selectedColor = ref(null);

const color = ref({
    COLOR: null,
    IS_ACTIVE: null,
});

const errors = ref({});

const isLoading = ref(false);

watch(selectedColor, () => {
    color.value = Object.assign({
        COLOR: null,
        IS_ACTIVE: null
    }, selectedColor.value);
});

function reset() {
    errors.value = {};
    selectedColor.value = null;
}

async function insertColor() {
    errors.value = {};

    isLoading.value = true;
    try {
        await postColor(color.value);
        addFlashMessage('SUCCESS', 'Successfully saved color.');
        selectedColor.value = Object.assign({}, color.value);
        colorTableRefreshFlag.value = !colorTableRefreshFlag.value;
    } catch(e) {
        errors.value = e.errors ?? {};
        addFlashMessage('ERROR', e.message);
    }
    scrollToTop();
    isLoading.value = false;
}

async function updateColor() {
    errors.value = {};

    isLoading.value = true;
    try {
        await putColor(selectedColor.value.COLOR, color.value);
        addFlashMessage('SUCCESS', 'Successfully updated color.');
        selectedColor.value = Object.assign({}, color.value);
        colorTableRefreshFlag.value = !colorTableRefreshFlag.value;
    } catch(e) {
        errors.value = e.errors ?? {};
        addFlashMessage('ERROR', e.message);
    }
    scrollToTop();
    isLoading.value = false;
}
</script>
