import { ref } from "vue";

import constructFetchError from "./constructFetchError";

export function useJoborderNewOrderDetails() {
    const orderDetails = ref(null);

    const getOrderDetails = async (joborderNewJONO) => {
        try {
            const orderDetailsResponse = await axios.get(route('api.joborder-new.order-details.show', {
                joborder_new: joborderNewJONO
            }));
            orderDetails.value = orderDetailsResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching order details.');
        }
    };
    
    return { orderDetails, getOrderDetails };
}
