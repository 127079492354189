import { ref } from 'vue';

import axios from 'axios';

import constructFetchError from "./constructFetchError";

export function useSampleCost() {
    const sampleCost = ref({
        COST_MATERIAL: null,
        COST_CUTTING: null,
        COST_ASSEMBLY: null,
        COST_OTHER: null,
        MSRP: null,
        FACTOR: null,
    });

    const getSampleCost = async (sampleId) => {
        try {
            const sampleCostResponse = await axios.get(route('api.samples.sample-cost.show', {
                sample: sampleId
            }));
            sampleCost.value = sampleCostResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching sample cost.');
        }
    };
    
    const putSampleCost = async (sampleId, sampleCost) => {
        try {
            const sampleCostResponse = await axios.put(route('api.samples.sample-cost.update', {
                sample: sampleId,
            }), sampleCost);
            return sampleCostResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while updating sample cost.');
        }
    };

    return { sampleCost, getSampleCost, putSampleCost };
}
