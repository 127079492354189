<template>
    <Card :class="class">
        <template #header>
            Check Leaves
        </template>
        <div class="row g-3">
            <div class="col-md-6">
                <h5>Leaves This Year</h5>
                <DataTable :loading="loadingFlagStore.has('fetchLeaves')" :value="otherEmployeeLeaves"
                    paginator :rows="10"
                    :pt="{ table: { class: 'table table-sm table-bordered table-hover' }}"
                    class="mb-3">
                    <Column field="NAME" header="Name" sortable :pt="{
                        filterInput: { class: 'input-group input-group-sm' },
                        filterMenuButton: { class: 'd-none' },
                        headerFilterClearButton: { class: 'd-none' } }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                                class="form-control" placeholder="Search" />
                        </template>
                    </Column>
                    <Column field="DATE" header="Date" sortable :pt="{
                        filterInput: { class: 'input-group input-group-sm' },
                        filterMenuButton: { class: 'd-none' },
                        headerFilterClearButton: { class: 'd-none' } }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                                class="form-control" placeholder="Search" />
                        </template>
                    </Column>
                    <Column field="RATE" header="Rate" sortable :pt="{
                        filterInput: { class: 'input-group input-group-sm' },
                        filterMenuButton: { class: 'd-none' },
                        headerFilterClearButton: { class: 'd-none' } }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                                class="form-control" placeholder="Search" />
                        </template>
                    </Column>
                    <Column field="HOURS" header="Hours" sortable :pt="{
                        filterInput: { class: 'input-group input-group-sm' },
                        filterMenuButton: { class: 'd-none' },
                        headerFilterClearButton: { class: 'd-none' } }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                                class="form-control" placeholder="Search" />
                        </template>
                    </Column>
                    <template #empty>
                        <div class="text-center">
                            <template v-if="!employee">
                                Please select an employee.
                            </template>
                            <template v-else>
                                No leaves.
                            </template>
                        </div>
                    </template>
                </DataTable>
                <h5 class="mb-3">Leaves Inputted Now</h5>
                <DataTable :loading="loadingFlagStore.has('fetchLeaves')" :value="currentEmployeeLeaves"
                    paginator :rows="10"
                    :pt="{ table: { class: 'table table-sm table-bordered table-hover' }}">
                    <Column field="NAME" header="Name" sortable :pt="{
                        filterInput: { class: 'input-group input-group-sm' },
                        filterMenuButton: { class: 'd-none' },
                        headerFilterClearButton: { class: 'd-none' } }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                                class="form-control" placeholder="Search" />
                        </template>
                    </Column>
                    <Column field="DATE" header="Date" sortable :pt="{
                        filterInput: { class: 'input-group input-group-sm' },
                        filterMenuButton: { class: 'd-none' },
                        headerFilterClearButton: { class: 'd-none' } }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                                class="form-control" placeholder="Search" />
                        </template>
                    </Column>
                    <Column field="RATE" header="Rate" sortable :pt="{
                        filterInput: { class: 'input-group input-group-sm' },
                        filterMenuButton: { class: 'd-none' },
                        headerFilterClearButton: { class: 'd-none' } }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                                class="form-control" placeholder="Search" />
                        </template>
                    </Column>
                    <Column field="HOURS" header="Hours" sortable :pt="{
                        filterInput: { class: 'input-group input-group-sm' },
                        filterMenuButton: { class: 'd-none' },
                        headerFilterClearButton: { class: 'd-none' } }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                                class="form-control" placeholder="Search" />
                        </template>
                    </Column>
                    <template #empty>
                        <div class="text-center">
                            <template v-if="!employee">
                                Please select an employee.
                            </template>
                            <template v-else>
                                No leaves.
                            </template>
                        </div>
                    </template>
                </DataTable>
            </div>
            <div class="col-md-6">
                <h5>Leaves Taken This Year: {{ timePeriodYear }}</h5>
                <template v-if="loadingFlagStore.has('fetchLeaves')">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    Calculating leaves...
                </template>
                <template v-else>
                    <p class="form-text">Please click on Check Leaves button to see taken leaves and updated calculation.</p>
                    <FormInput label="Incentives (SALES)" v-model="incentivesSalesTotalHours"
                        is-horizontal disabled id-prefix="incentivesSales" class="mb-3" />
                    <FormInput label="Incentives (REG)" v-model="incentivesRegTotalHours"
                        is-horizontal disabled id-prefix="incentivesReg" class="mb-3" />
                    <FormInput label="Years Stayed" v-model="yearsStayed"
                        is-horizontal disabled id-prefix="yearsStayed" class="mb-3" />
                    <div>All Leaves</div>
                    <div class="row">
                        <div class="col-6">
                            <FormInput label="Total Leaves (Days)" v-model="totalLeavesDays"
                                hide-label disabled id-prefix="totalLeavesDays" class="mb-3" />
                        </div>
                        <div class="col-6">
                            <FormInput label="Total Leaves (Hours)" v-model="totalLeavesHours"
                                hide-label disabled id-prefix="totalLeavesHours" class="mb-3" />
                        </div>
                    </div>
                    <FormInput label="Available Leaves this Time Period" v-model="availableLeaves"
                        is-horizontal disabled id-prefix="availableLeaves" class="mb-3" />
                    <FormInput label="Total Tabulation" v-model="totalTabulation"
                        is-horizontal disabled id-prefix="totalTabulation" />
                </template>
            </div>
        </div>
        <template #footer>
            <button type="button" class="btn btn-primary" :disabled="!employee"
                @click="fetchLeaves">
                Check Leaves
            </button>
        </template>
    </Card>
</template>

<script setup>
import { computed, inject, ref, watch } from 'vue';

import Card from '@/components/utils/Card.vue';
import FormInput from '@/components/utils/FormInput.vue';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

import moment from 'moment';
import axios from 'axios';

const props = defineProps({
    employee: Object,
    timePeriod: Object,
    class: String,
});

const emit = defineEmits(['employee-leaves-change', 'error']);

const loadingFlagStore = inject('loadingFlagStore');

const employeeLeaves = ref(null);

const otherEmployeeLeaves = ref(null);
const currentEmployeeLeaves = ref(null);

const timePeriodYear = computed(() => {
    if(!props.timePeriod)
        return null;
    timePeriodYear.value = moment(props.timePeriod.START_DATE).year();
});

const incentivesSalesTotalHours = ref(null);
const incentivesRegTotalHours = ref(null);
const yearsStayed = ref(null);
const totalLeavesDays = ref(null);
const totalLeavesHours = computed(() => totalLeavesDays.value ? totalLeavesDays.value * 8 : null);
const availableLeaves = defineModel('availableLeavesDays');
const totalTabulation = ref(null);

watch(() => props.employee, () => {
    if(props.employee)
        fetchLeaves();
});

async function fetchLeaves() {
    employeeLeaves.value = null;

    if(!props.employee || !props.timePeriod)
        return;

    loadingFlagStore.value.add('fetchLeaves');
    try {
        const leavesResponse = await axios.get(route('api.employees.hours.index', [props.employee.EMPID]), {
            params: {
                JONO: 'LEAVE',
                YEAR: new Date(props.timePeriod.START_DATE).getFullYear()
            }
        });
        employeeLeaves.value = leavesResponse.data.data
            .filter(employeeLeave => employeeLeave.HOURS > 0)
            .map(employeeLeave => ({ ...employeeLeave, NAME: `${props.employee.FNAME} ${props.employee.LNAME}`}));

        // Segregate display of leaves outside time period and leaves within time period
        const timePeriodStartDate = moment(props.timePeriod.START_DATE, 'YYYY-MM-DD');
        const timePeriodEndDate = moment(props.timePeriod.END_DATE, 'YYYY-MM-DD');

        otherEmployeeLeaves.value = employeeLeaves.value.filter(leave => {
            const leaveDate = moment(leave.DATE, 'YYYY-MM-DD');
            return leaveDate.isBefore(timePeriodStartDate) || leaveDate.isAfter(timePeriodEndDate);
        });
        
        currentEmployeeLeaves.value = employeeLeaves.value.filter(leave => {
            const leaveDate = moment(leave.DATE, 'YYYY-MM-DD');
            return leaveDate.isSameOrAfter(timePeriodStartDate) && leaveDate.isSameOrBefore(timePeriodEndDate);
        });

        // Days of work
        const dowResponse = await axios.get(route('api.employees.dow', {
            employee: props.employee.EMPID,
            year: moment(props.timePeriod.START_DATE).year()
        }));
        totalTabulation.value = dowResponse.data.data;

        incentivesSalesTotalHours.value = otherEmployeeLeaves.value.filter((leave) =>
            leave.JONO === 'LEAVE' && leave.STYLE === 'INCENTIVE (SALES)'
        ).reduce((totalHours, leave) => totalHours += leave.HOURS, 0);

        incentivesRegTotalHours.value = otherEmployeeLeaves.value.filter((leave) =>
            leave.JONO === 'LEAVE' && leave.STYLE === 'INCENTIVE (REG)'
        ).reduce((totalHours, leave) => totalHours += leave.HOURS, 0);

        yearsStayed.value = moment().diff(props.employee.HIREDATE, 'years');

        // Total Leaves
        const totalLeavesResponse = await axios.get(route('api.employees.total-leaves.show', {
            employee: props.employee.EMPID,
            TIME_PERIOD: props.timePeriod.TIME_PERIOD
        }));
        totalLeavesDays.value = totalLeavesResponse.data.data;

        if(props.employee.DEPT.includes("SALES") || props.employee.DEPT.includes("SC"))
            availableLeaves.value = (totalLeavesHours.value - incentivesSalesTotalHours.value) / 8;

        else
            availableLeaves.value = (totalLeavesHours.value - incentivesRegTotalHours.value) / 8;
    } catch (e) {
        console.log(e);
        emit('error', 'Unable to fetch employee leaves. Please contact your administrator.');
    }
    loadingFlagStore.value.delete('fetchLeaves');
}
</script>
