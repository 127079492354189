<template>
    <Card :class="class">
        <template #header>
            Commissions
        </template>
        <div class="row g-3 mb-3">
            <div class="col-md-3">
                <FormInput type="text"
                    label="Based last year period" id-prefix="forwardedAmt"
                    is-horizontal />
            </div>
            <div class="col-md-3">
                <FormInput v-model="commissionTotalAmountDisplay" type="number" step=".01" disabled
                    label="Comm. Amt." id-prefix="commissionAmt"
                    is-horizontal />
            </div>
            <div class="col-md-3">
                <FormInput v-model="commissionTotalMissingDisplay" type="number" step=".01" disabled
                    label="Miss. Amt." id-prefix="missingAmt"
                    is-horizontal />
            </div>
            <div class="col-md-3">
                <FormInput v-model="commissionNetDisplay" type="number" step=".01" disabled
                    label="Net Amt." id-prefix="netAmt"
                    is-horizontal />
            </div>
        </div>
        <DataTable :loading="isLoading" :value="employeeCommissions"
            paginator :rows="10"
            v-model:filters="filters" filter-display="row"
            :pt="{ table: { class: ' table table-bordered table-hover' } }">
            <Column field="TIME_PERIOD" header="Time Period" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="PERIOD" header="Period" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="EMP_NAME" header="Employee" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="SM_COMMISSION" header="SM Commission" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="SM_MISSING" header="SM Missing" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="MEGA_COMMISSION" header="Mega Commission" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="MEGA_MISSING" header="Mega Missing" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="SM_FINAL" header="SM Final" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="MEGA_FINAL" header="Mega Final" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="FINAL" header="Final" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <template #empty>
                <div class="text-center">
                    No data yet.
                </div>
            </template>
        </DataTable>
    </Card>
</template>

<script setup>
import { computed, ref, watch } from 'vue';

import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useEmployeeCommissions } from '@/composables/data/employeeCommissions';

import Card from '@/components/utils/Card.vue';
import FormInput from '@/components/utils/FormInput.vue';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

const props = defineProps({
    startTimePeriod: Number,
    endTimePeriod: Number,
    location: String,
    employeeId: Number,
    class: String,
});

const emit = defineEmits(['update-net-amt', 'error']);

const { filters } = useDataTableParams(['TIME_PERIOD', 'PERIOD', 'EMP_NAME',
    'SM_COMMISSION', 'SM_MISSING', 'MEGA_COMMISSION', 'MEGA_MISSING',
    'SM_FINAL', 'MEGA_FINAL', 'FINAL']);

const { employeeCommissions, getEmployeeCommissions } = useEmployeeCommissions();

const commissionTotalAmount = computed(() =>
    employeeCommissions.value?.reduce(
        (total, commission) => total + commission.SM_COMMISSION + commission.MEGA_COMMISSION, 0) ?? 0
);
const commissionTotalMissing = computed(() =>
    employeeCommissions.value?.reduce(
        (total, commission) => total + commission.SM_MISSING + commission.MEGA_MISSING, 0) ?? 0
);
const commissionNet = computed(() => commissionTotalAmount.value - commissionTotalMissing.value);

const commissionTotalAmountDisplay = computed(() => commissionTotalAmount.value.toFixed(2));
const commissionTotalMissingDisplay = computed(() => commissionTotalMissing.value.toFixed(2));
const commissionNetDisplay = computed(() => commissionNet.value.toFixed(2));

const isLoading = ref(false);

watch(() => [props.employeeId], async () => {
    employeeCommissions.value = null;

    if(!props.employeeId || !props.startTimePeriod || !props.endTimePeriod || !props.location)
        return;

    isLoading.value = true;
    try {
        await getEmployeeCommissions(props.employeeId, props.startTimePeriod, props.endTimePeriod, props.location);
    } catch(e) {
        emit('error', e.message);
    }
    isLoading.value = false;
});

watch(commissionNet, () => {
    emit('update-net-amt', commissionNet.value);
});
</script>
