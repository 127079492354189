<style scoped>
#importedHoursTableContainer {
    max-height: 400px;
    overflow-y: scroll;
}
</style>

<template>
    <div class="row mb-3">
        <div class="col-sm-6 col-md-4">
            <Card>
                <h2 class="h3">Employee Hours i-Time</h2>
                <p>Last File Uploaded: {{ lastFileUploadedName ?? 'No file yet.' }}</p>
                <form @submit.prevent="itimeFileOnSubmit">
                    <div class="mb-3">
                        <label for="itimeFileInput" class="form-label">Upload a file</label>
                        <input type="file" ref="itimeFileInput"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            id="itimeFileInput" class="form-control"
                            @change="itimeFileOnChange" required />
                    </div>
                    <button type="submit" class="btn btn-primary me-2"
                        :disabled="isSubmitting">
                        Save
                        <div class="spinner-border spinner-border-sm" role="status" v-if="isSubmitting">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </button>
                    <button type="button" class="btn btn-secondary"
                        @click="clearFlashMessages">
                        Dismiss Notifications
                    </button>
                </form>
            </Card>
        </div>
        <div class="col-sm-6 col-md-8">
            <Card>
                <template #header>
                    Import Errors
                </template>
                <DataTable :value="importErrors" paginator :rows="10"
                    :pt="{ table: { class: 'table table-bordered table-hover' } }">
                    <Column field="status" header="Status">
                        <template #body="{ data }">
                            <span v-if="data.status == 'ERROR'" class="badge bg-danger">
                                Error
                            </span>
                            <span v-else-if="data.status == 'WARNING'" class="badge bg-warning">
                                Warning
                            </span>
                        </template>
                    </Column>
                    <Column field="field" header="Field" />
                    <Column field="message" header="Message" />
                    <template #empty>
                        <div class="text-center">
                            No errors.
                        </div>
                    </template>
                </DataTable>
            </Card>
        </div>
    </div>
    <Card>
        <div class="row g-3 mb-3">
            <div class="col-md-6">
                <h2 class="h3 mb-3">Current Imported Hours</h2>
            </div>
            <div class="col-md-6 text-end">
                <button type="button" class="btn btn-outline-secondary"
                    :disabled="isDeleting" @click="deleteOnClick">
                    Reset Imported Hours
                    <div class="spinner-border spinner-border-sm" role="status" v-if="isDeleting">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </button>
            </div>
        </div>
        <DataTable
            :value="currentImportedHours"
            paginator
            :rows="10"
            :pt="{ table: { class: 'table table-bordered table-hover' } }">
            <Column field="EMPLOYEE_NUMBER" header="Employee Number" />
            <Column field="mapping.employee.EMPID" header="Employee ID" />
            <Column field="mapping.employee.FNAME" header="First Name" />
            <Column field="mapping.employee.LNAME" header="Last Name" />
            <Column field="IN1" header="In 1" />
            <Column field="OUT1" header="Out 1" />
            <Column field="IN2" header="In 2" />
            <Column field="OUT2" header="Out 2" />
            <Column field="IN3" header="In 3" />
            <Column field="OUT3" header="Out 3" />
            <template #empty>
                <div class="text-center">
                    No data yet.
                </div>
            </template>
        </DataTable>
    </Card>
</template>

<script setup>
import { ref, onMounted } from "vue";

import Card from "@/components/utils/Card.vue";

import axios from "axios";

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';

import handleFetchErrors from "@/utils/handleFetchErrors";
import scrollToTop from "@/utils/scrollToTop";

import { useFlashMessages } from "@/composables/flashmessages";

const { addFlashMessage, clearFlashMessages } = useFlashMessages();

const isSubmitting = ref(false);
const isDeleting = ref(false);

const importErrors = ref(null);

const lastFileUploadedName = ref(null);
const itimeFileInput = ref();
const itimeFile = ref(null);

const currentImportedHours = ref(null);

onMounted(async() => {
    try {
        const latestSpreadsheetResponse = await axios.get(
            route('api.itime-spreadsheets.latest'));
        lastFileUploadedName.value = latestSpreadsheetResponse.data.data.filename;
        await fetchCurrentImportedHours();
    } catch(e) {
        console.log(e);
        handleFetchErrors(e, 'Error while fetching latest spreadsheet information.');
        scrollToTop();
    }

});

function itimeFileOnChange(event) {
    const file = event.target.files[0];
    itimeFile.value = file;
}

async function fetchCurrentImportedHours() {
    try {
        const currentImportedHoursResponse = await axios.get(
            route('api.sm-itime.all'));
        currentImportedHours.value = currentImportedHoursResponse.data.data;
    } catch(e) {
        console.log(e);
        handleFetchErrors(e, 'Error while fetching imported hours.');
        scrollToTop();
    }
}

async function itimeFileOnSubmit() {
    importErrors.value = null;

    isSubmitting.value = true;
    try {
        const formData = new FormData();
        formData.append("spreadsheet", itimeFile.value);
        const response = await axios.post(route('api.itime-spreadsheets.store'), formData);
        addFlashMessage('SUCCESS', response.data.message);
        if(response.data.errors) {
            importErrors.value = Object.entries(response.data.errors).reduce((importErrors, [field, errors]) => {
                return [
                    ...importErrors,
                    ...errors.map(error => ({
                        status: 'ERROR',
                        field: field,
                        message: error
                    })),
                ];
            }, []);
        }
        if(response.data.warnings) {
            importErrors.value = Object.entries(response.data.warnings).reduce((importErrors, [field, warnings]) => {
                return [
                    ...importErrors,
                    ...warnings.map(warning => ({
                        status: 'WARNING',
                        field: field,
                        message: warning
                    })),
                ];
            }, []);
        }
        itimeFileInput.value.value = null;
        itimeFile.value = null;
        lastFileUploadedName.value = response.data.data.filename;
        await fetchCurrentImportedHours();
    } catch(e) {
        console.log(e);
        if(e.response) {
            if(e.response.status == 413)
                addFlashMessage('ERROR', 'File is too large.');
            else {
                addFlashMessage('ERROR', e.response.data.message);
                if(e.response.data.errors)
                    Object.entries(e.response.data.errors).forEach(([field, errors]) => {
                        errors.forEach(error => {
                            addFlashMessage('ERROR', error);
                        });
                    });
            }
        } else {
            addFlashMessage('ERROR', 'Error while submitting spreadsheet. ' +
                'Please contact your administrator.');
        }
    }
    isSubmitting.value = false;
}

async function deleteOnClick() {
    if(prompt("Are you sure? Enter 'DELETE' to continue.") !== 'DELETE')
        return;

    isDeleting.value = true;
    try {
        const response = await axios.delete(route('api.sm-itime.destroy'));
        addFlashMessage('SUCCESS', response.data.message);
        await fetchCurrentImportedHours();
    } catch(e) {
        console.log(e);
        if(e.response) {
            addFlashMessage('ERROR', e.response.data.message);
        } else {
            addFlashMessage('ERROR', `An error occurred while connecting to servers.
                Please contact your administrator.`);
        }
    }
    isDeleting.value = false;
}
</script>
