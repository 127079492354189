<template>
    <div v-if="isOpen" class="pop-up d-flex justify-content-center align-items-center">
        <Card :class="class">
            <template #header>
                <div class="d-flex justify-content-between pt-2">
                    <h4 class="title">Payment Details</h4>
                    <button type="button" class="btn-close" @click="emit('close')"></button>
                </div>
            </template>
            <DataTable :value="paymentSummary"
                paginator :rows="10"
                filter-display="row" v-model:filters="filters"
                :pt="{ table: { class: 'table table-bordered table-hover' } }">
                <Column field="VOUCHER_ID" header="Voucher ID" :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText type="text" v-model="filterModel.value" @keydown="filterCallback()"
                            class="form-control" placeholder="Search" />
                        <Button type="button" class="btn btn-primary" @click="filterCallback()">
                            Search
                        </Button>
                    </template>
                </Column>
                <Column field="DESCRIPTION" header="Description" :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText type="text" v-model="filterModel.value" @keydown="filterCallback()"
                            class="form-control" placeholder="Search" />
                        <Button type="button" class="btn btn-primary" @click="filterCallback()">
                            Search
                        </Button>
                    </template>
                </Column>
                <Column field="GL_ID" header="GL ID" :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText type="text" v-model="filterModel.value" @keydown="filterCallback()"
                            class="form-control" placeholder="Search" />
                        <Button type="button" class="btn btn-primary" @click="filterCallback()">
                            Search
                        </Button>
                    </template>
                </Column>
                <Column field="CLASS" header="Class" :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText type="text" v-model="filterModel.value" @keydown="filterCallback()"
                            class="form-control" placeholder="Search" />
                        <Button type="button" class="btn btn-primary" @click="filterCallback()">
                            Search
                        </Button>
                    </template>
                </Column>
                <Column field="ACCTNAME" header="Account Name" :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText type="text" v-model="filterModel.value" @keydown="filterCallback()"
                            class="form-control" placeholder="Search" />
                        <Button type="button" class="btn btn-primary" @click="filterCallback()">
                            Search
                        </Button>
                    </template>
                </Column>
                <Column field="GL_AMT" header="Amount" :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText type="text" v-model="filterModel.value" @keydown="filterCallback()"
                            class="form-control" placeholder="Search" />
                        <Button type="button" class="btn btn-primary" @click="filterCallback()">
                            Search
                        </Button>
                    </template>
                </Column>
                <Column field="PAYEE_DATE" header="Payee Date" :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText type="text" v-model="filterModel.value" @keydown="filterCallback()"
                            class="form-control" placeholder="Search" />
                        <Button type="button" class="btn btn-primary" @click="filterCallback()">
                            Search
                        </Button>
                    </template>
                </Column>
                <template #empty>
                    <div class="text-center">
                        No payment details.
                    </div>
                </template>
            </DataTable>
        </Card>
    </div>
</template>

<script setup>
import { watch } from 'vue';

import { useFlashMessages } from '@/composables/flashmessages';

import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useAccountPaymentDetails } from '@/composables/data/accountPaymentDetails';

import Card from '@/components/utils/Card.vue';

import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

const { addFlashMessage } = useFlashMessages();

const { paymentSummary, getPaymentSummary } = useAccountPaymentDetails();

const props = defineProps({
    account: Object,
    class: String,
    isOpen: Boolean,
});

const emit = defineEmits(['close']);

const { filters } = useDataTableParams(['VOUCHER_ID', 'DESCRIPTION', 'GL_ID', 'CLASS', 'ACCTNAME', 'GL_AMT', 'PAYEE_DATE']);

async function fetchPaymentSummary() {
    paymentSummary.value = null;
    if(!props.account)
        return;
    try {
        await getPaymentSummary(props.account.ACCOUNT_ID);
    } catch(e) {
        addFlashMessage('ERROR', e.message);
    }
}

watch(() => props.account, fetchPaymentSummary);
</script>

<style scoped>
.pop-up {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: rgb(0, 0, 0, 0.5);
}

.pop-up .card {
    width: 100%;
}

@media (min-width: 768px) {
    .pop-up .card {
        width: 85%;
    }
}

@media (min-width: 992px) {
    .pop-up .card {
        width: 70%;
    }
}
</style>
