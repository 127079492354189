<template>
    <Card>
        <div class="row g-3 mb-3">
            <div class="col-md-6">
                <Card>
                    <div class="mb-3">
                        <label for="openTimePeriodDisplay" class="form-label">
                            Open Time Period:
                        </label>
                        <input type="text" :value="openTimePeriod?.TIME_PERIOD"
                            id="openTimePeriodDisplay" class="form-control" disabled />
                        <div class="form-text">Click on either Weekly or Bimonthly to select an open time period.</div>
                    </div>
                    <button type="button" class="btn btn-primary me-2"
                        @click="fetchPayslips('WEEKLY')">
                        Weekly
                    </button>
                    <button type="button" class="btn btn-primary"
                        @click="fetchPayslips('BIMONTHLY')">
                        Bimonthly
                    </button>
                </Card>
            </div>
            <div class="col-md-6">
                <Card>
                    <div class="row g-3 mb-3">
                        <div class="col-md-6">
                            <label for="firstNameFilter" class="form-label">
                                First Name
                            </label>
                            <input type="text" v-model="firstNameFilter"
                                placeholder="Type first name here..."
                                id="firstNameFilter" class="form-control" />
                            <div class="form-text">Type a name here to filter your results.</div>
                        </div>
                        <div class="col-md-6">
                            <label for="lastNameFilter" class="form-label">
                                Last Name
                            </label>
                            <input type="text" v-model="lastNameFilter"
                                placeholder="Type last name here..."
                                id="lastNameFilter" class="form-control" />
                        </div>
                    </div>
                    <button type="button" class="btn btn-primary d-block"
                        :disabled="openTimePeriod == null"
                        @click="fetchPayslipsOnOpenTimePeriod">
                        Search
                    </button>
                </Card>
            </div>
        </div>
        <Card>
            <div v-if="!isLoading" class="table-responsive" id="payslipsTableContainer">
                <table class="table table-bordered table-hover">
                    <thead>
                        <tr>
                            <th>Employee ID</th>
                            <th>Time Period</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Department</th>
                            <th>Last Name</th>
                            <th>First Name</th>
                            <th>Hours</th>
                            <th>Final Pay</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="payslipList == null">
                            <td colspan="9" class="text-center">
                                Click on a time period to view payslips.
                            </td>
                        </tr>
                        <tr v-else-if="payslipList.length == 0">
                            <td colspan="9" class="text-center">
                                No payslips available for this time period.
                            </td>
                        </tr>
                        <tr v-else v-for="payslip in payslipList"
                            :class="{ 'bg-primary text-white': payslip === selectedPayslip }"
                            @click="payslipRowOnClick(payslip)">
                            <td>{{ payslip.employee.EMPID }}</td>
                            <td>{{ payslip.timePeriod.TIME_PERIOD }}</td>
                            <td>{{ payslip.timePeriod.START_DATE }}</td>
                            <td>{{ payslip.timePeriod.END_DATE }}</td>
                            <td>{{ payslip.employee.DEPT }}</td>
                            <td>{{ payslip.employee.LNAME }}</td>
                            <td>{{ payslip.employee.FNAME }}</td>
                            <td>{{ payslip.HOURS }}</td>
                            <td>{{ payslip.FINAL }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div v-else class="text-center mb-3">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <button type="button" class="btn btn-danger" :disabled="selectedPayslip == null"
                @click="deletePayslipButtonOnClick">Delete Payslip</button>
        </Card>
    </Card>
</template>

<script setup>
import { ref } from "vue";

import Card from '../../../utils/Card.vue';

import axios from "axios";

import handleFetchErrors from "../../../../utils/handleFetchErrors";
import scrollToTop from "../../../../utils/scrollToTop";

import { useFlashMessages } from "../../../../composables/flashmessages";

const { addFlashMessage } = useFlashMessages();

const openTimePeriod = ref(null);
const firstNameFilter = ref("");
const lastNameFilter = ref("");
const clickedPaytype = ref(null);

const isLoading = ref(false);
const payslipList = ref(null);
const selectedPayslip = ref(null);

async function fetchPayslips(paytype) {
    selectedPayslip.value = null;
    clickedPaytype.value = paytype;

    try {
        const openTimePeriodResponse = await axios.get(route('api.time-periods.index'), {
            params: {
                STATUS: 'O',
                PAYTYPE: paytype
            }
        });

        const openTimePeriods = openTimePeriodResponse.data.data;
        if(openTimePeriods.length == 0 ) {
            addFlashMessage('WARNING', `There are no open time periods with this paytype.`);
            return;
        } else if(openTimePeriods.length > 1) {
            addFlashMessage('WARNING', `Multiple time periods are open.
                Please check on time period maintenance.`);
            return;
        }

        openTimePeriod.value = openTimePeriodResponse.data.data[0];
        await fetchPayslipsOnOpenTimePeriod();
    } catch(e) {
        console.log(e);
        handleFetchErrors(e, 'Error while fetching open time period.');
        scrollToTop();
    }
}

async function fetchPayslipsOnOpenTimePeriod() {
    isLoading.value = true;
    try {
        const payslipsResponse = await axios.get(route('api.payslips.index', {
            filters: {
                TIME_PERIOD: {
                    value: openTimePeriod.value.TIME_PERIOD,
                    matchMode: 'equals',
                },
                FNAME: {
                    value: firstNameFilter.value,
                    matchMode: 'contains'
                },
                LNAME: {
                    value: lastNameFilter.value,
                    matchMode: 'contains'
                }
            }
        }));
        payslipList.value = payslipsResponse.data.data;
    } catch(e) {
        console.log(e);
        handleFetchErrors(e, 'Error while fetching payslips.');
        scrollToTop();
    }
    isLoading.value = false;
}

function payslipRowOnClick(payslip) {
    selectedPayslip.value = payslip;
}

async function deletePayslipButtonOnClick() {
    if(window.prompt("Are you sure? Enter 'DELETE' to continue.") !== "DELETE") {
        window.alert("Invalid keyword. Will not proceed.");
        return;
    }

    try {
        await axios.delete(route('api.payslips.destroy', [selectedPayslip.value.id]));
        addFlashMessage('SUCCESS', `Succesfully deleted payslip.`);
        fetchPayslips(clickedPaytype.value);
    } catch(e) {
        console.log(e);
        handleFetchErrors(e, 'Error while deleting payslip.');
        scrollToTop();
    }
}
</script>
