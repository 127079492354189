<template>
    <div v-if="isOpen" class="pop-up d-flex justify-content-center align-items-center">
        <form @submit.prevent="updateSellerOfBarcodeSequence">
            <Card>
                <template #header>
                    <div class="d-flex justify-content-between pt-2">
                        <h4 class="title">Change Seller</h4>
                        <button type="button" class="btn-close" @click="emit('close')"></button>
                    </div>
                </template>
                <div v-if="isLoadingEmployees" class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                <FormSelect label="Employee" v-model="seller"
                    :options="employees" :disabled="isLoadingEmployees"
                    :option-to-string="employee => [employee.EMPID, employee.FNAME + ' ' + employee.LNAME].join(' | ')"
                    id-prefix="employee"
                    :errors="errors.EMPID"
                    @keydown-enter="focusnext('updateButton')" />
                <template #footer>
                    <div class="d-grid">
                        <button type="submit" class="btn btn-primary"
                            id="updateButton"
                            :disabled="isUpdating">
                            <span v-if="isUpdating" class="spinner-border spinner-border-sm" role="status"
                                aria-hidden="true"></span>
                            Update
                        </button>
                    </div>
                </template>
            </Card>
        </form>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';

import { useInventory } from '@/composables/data/inventory';
import { useEmployeeLocations } from "@/composables/data/employeeLocations";

import Card from "@/components/utils/Card.vue";
import FormSelect from "@/components/utils/FormSelect.vue";

import focusnext from '@/utils/focusnext';

const { updateSeller } = useInventory();

const props = defineProps({
    inventoryRecord: Object,
    isOpen: Boolean,
});

const emit = defineEmits(['update']);

const { employeeLocations, getEmployeeLocations } = useEmployeeLocations();

const employees = ref(null);

const seller = ref(null);

const errors = ref({});

const isLoadingEmployees = ref(false);

const isUpdating = ref(false);

async function updateSellerOfBarcodeSequence() {
    errors.value = {};
    isUpdating.value = true;
    try {
        await updateSeller(props.inventoryRecord.STYLE, props.inventoryRecord.DATE,
            props.inventoryRecord.SERIAL, props.inventoryRecord.SEQ_NO,
            seller.value?.EMPID);
        emit('success', 'Successfully updated seller.');
    } catch(e) {
        errors.value = e.errors ?? {};
        if(e.status != 422)
            emit('error', e.message);
    }
    isUpdating.value = false;
}

async function fetchAvailableEmployees() {
    isLoadingEmployees.value = true;
    try {
        await getEmployeeLocations({
            LOCATION: props.inventoryRecord.PREV_LOC,
            START_DATE_LTE: props.inventoryRecord.SOLD_DT,
            END_DATE_GTE: props.inventoryRecord.SOLD_DT
        });
        employees.value = employeeLocations.value.map(employeeLocation => employeeLocation.employee);
    } catch(e) {
        emit('error', e.message);
    }
    isLoadingEmployees.value = false;
}

watch(() => props.isOpen, async () => {
    seller.value = null;

    if(props.inventoryRecord.SOLD_DT)
        await fetchAvailableEmployees();
});
</script>

<style scoped>
.pop-up {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: rgb(0, 0, 0, 0.5);
}
</style>
