<template>
    <div class="row mb-3">
        <div class="col-md-4">
            <Card class="mb-3">
                <template #header>
                    Select Time Period
                </template>
                <TimePeriodTable v-model="selectedTimePeriod"
                    show-from-current-date paytype="MONTHLY"
                    :hide-properties="new Set(['PAYTYPE', 'STATUS'])"
                    :sort="[{ field: 'TIME_PERIOD', order: -1 }]" />
            </Card>
            <Card class="mb-3">
                <template #header>
                    Select Location
                </template>
                <LocationTable v-model="selectedLocation" />
            </Card>
            <Card>
                <template #header>
                    Upload CSV
                </template>
                <div>
                    <label for="dailySalesFileInput">Daily Sales File (csv)</label>
                    <input type="file" accept=".csv" ref="dailySalesFileInput" class="form-control"
                        @change="e => { dailySalesFile = e.target.files[0]; }" />
                </div>
                <template #footer>
                    <div class="text-end">
                        <button type="button" class="btn btn-primary"
                            :disabled="!selectedTimePeriod"
                            @click="uploadDailySalesFile">
                            Upload Sales
                        </button>
                    </div>
                </template>
            </Card>
        </div>
        <div class="col-md-8">
            <form @submit.prevent="updateDailySales">
                <Card>
                    <template #header>
                        Update Daily Sales
                    </template>
                    <div v-if="isLoading" class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <template v-for="dailySale in dailySales">
                        <div class="row g-3 mb-1">
                            <div class="col-4">
                                <FormInput type="number" step=".01"
                                    v-model="dailySale.GROSS_QUANTITY"
                                    label="Quantity" :id-prefix="`qty-${dailySale.DATE}`"
                                    input-class="form-control-sm" is-horizontal />
                            </div>
                            <div class="col-4">
                                <FormInput type="date" disabled
                                    v-model="dailySale.DATE"
                                    label="Date" :id-prefix="`date-${dailySale.DATE}`"
                                    input-class="form-control-sm" is-horizontal />
                            </div>
                            <div class="col-4">
                                <FormInput type="number" step=".01"
                                    v-model="dailySale.GROSS_AMOUNT"
                                    label="Amount" :id-prefix="`amount-${dailySale.DATE}`"
                                    input-class="form-control-sm" is-horizontal />
                            </div>
                        </div>
                    </template>
                    <template #footer>
                        <div class="row g-3">
                            <div class="col-4">
                                <FormInput type="number" step=".01" disabled
                                    v-model="totalGrossAmount"
                                    label="Total Amt." id-prefix="totalGrossAmount"
                                    is-horizontal />
                            </div>
                            <div class="col-4">
                                <FormInput type="number" disabled
                                    v-model="totalGrossQuantity"
                                    label="Total Qty." id-prefix="totalGrossQuantity"
                                    is-horizontal />
                            </div>
                            <div class="col-4 text-end">
                                <button type="submit" class="btn btn-primary"
                                    :disabled="!selectedTimePeriod || !selectedLocation">
                                    Update
                                </button>
                            </div>
                        </div>
                    </template>
                </Card>
            </form>
        </div>
    </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';

import { useFlashMessages } from '@/composables/flashmessages';
import { useSalesActualDetails } from '@/composables/data/salesActualDetails';

import Card from '@/components/utils/Card';
import FormInput from '@/components/utils/FormInput';
import LocationTable from '@/components/utils/tables/LocationTable';
import TimePeriodTable from '@/components/utils/tables/TimePeriodTable';

import moment from 'moment';

import scrollToTop from '@/utils/scrollToTop';

const { addFlashMessage } = useFlashMessages();

const selectedTimePeriod = ref(null);
const selectedLocation = ref(null);

const dailySales = ref(null);

const isLoading = ref(false);

const { salesActualDetails, getSalesActualDetailsInMonthAndLocation,
    putSalesActualDetailsInMonthAndLocation, postSalesActualDetailFile } = useSalesActualDetails();

const totalGrossAmount = computed(() => parseFloat(salesActualDetails.value?.reduce(
    (total, salesActualDetail) => total + salesActualDetail.GROSS_AMOUNT, 0).toFixed(2) ?? 0));
const totalGrossQuantity = computed(() => parseFloat(salesActualDetails.value?.reduce(
    (total, salesActualDetail) => total + salesActualDetail.GROSS_QUANTITY, 0).toFixed(2) ?? 0));

const dailySalesFileInput = ref(null);
const dailySalesFile = ref(null);

async function fetchDailySales() {
    dailySales.value = null;

    if(!selectedTimePeriod.value || !selectedLocation.value)
        return;

    isLoading.value = true;

    const timePeriodStartDate = moment(selectedTimePeriod.value.START_DATE, 'YYYY-MM-DD');
    const timePeriodEndDate = moment(selectedTimePeriod.value.END_DATE, 'YYYY-MM-DD');

    try {
        await getSalesActualDetailsInMonthAndLocation(timePeriodStartDate.format('YYYY-MM'),
            selectedLocation.value.LOCATION);
    } catch(e) {
        addFlashMessage('ERROR', e.message);
        scrollToTop();
        isLoading.value = false;
        return;
    }

    let currentDate = timePeriodStartDate.clone();

    const newDailySales = [];
    while(currentDate.isSameOrBefore(timePeriodEndDate)) {
        // Fetch current details if exists
        const currentDetail = salesActualDetails.value.filter((salesActualDetail) =>
            moment(salesActualDetail.SOLD_DT, 'YYYY-MM-DD').isSame(currentDate))[0];

        newDailySales.push({
            DATE: currentDate.format('YYYY-MM-DD'),
            GROSS_AMOUNT: currentDetail?.GROSS_AMOUNT ?? null,
            GROSS_QUANTITY: currentDetail?.GROSS_QUANTITY ?? null,
        });
        currentDate = currentDate.add(1, 'days');
    }
    dailySales.value = newDailySales;

    isLoading.value = false;
}

watch([selectedTimePeriod, selectedLocation], fetchDailySales);

async function updateDailySales() {
    try {
        await putSalesActualDetailsInMonthAndLocation(
            moment(selectedTimePeriod.value.START_DATE, 'YYYY-MM-DD').format('YYYY-MM'),
            selectedLocation.value.LOCATION,
            dailySales.value);
        addFlashMessage('SUCCESS', 'Successfully updated daily sales.');
        fetchDailySales();
    } catch(e) {
        addFlashMessage('ERROR', e.message);
    }
    scrollToTop();
}

async function uploadDailySalesFile() {
    try {
        const formData = new FormData();
        formData.append("sales_file", dailySalesFile.value);
        await postSalesActualDetailFile(
            moment(selectedTimePeriod.value.START_DATE, 'YYYY-MM-DD').format('YYYY-MM'),
            selectedLocation.value.LOCATION,
            formData);
        addFlashMessage('SUCCESS', 'Successfully updated daily sales from file.');
        fetchDailySales();
    } catch(e) {
        addFlashMessage('ERROR', e.message);
    }
    scrollToTop();
}
</script>
