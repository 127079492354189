<template>
    <div v-if="isOpen" class="pop-up d-flex justify-content-center align-items-center">
        <Card>
            <template #header>
                <div class="d-flex justify-content-between pt-2">
                    <h4 class="title">Select Margins</h4>
                    <button type="button" class="btn-close" @click="emit('close')"></button>
                </div>
            </template>
            <div class="px-3">
                <FormInput type="number" required
                    label="Top:" class="mb-3" is-horizontal
                    input-class="w-75" v-model="margin.top"/>
                <FormInput type="number" required
                    label="Left:" class="mb-3" is-horizontal
                    input-class="w-75" v-model="margin.left"/>
                <FormInput type="number" required
                    label="Right:" class="mb-3" is-horizontal
                    input-class="w-75" v-model="margin.right"/>
                <FormInput type="number" required
                    label="Bottom:" class="mb-3" is-horizontal
                    input-class="w-75" v-model="margin.bottom"/>
            </div>
            <template #footer>
                <div class="d-grid">
                    <button type="button" class="btn btn-primary"
                        @click="generateReport">
                        Generate Comment Summary
                    </button>
                </div>
            </template>
        </Card>
    </div>
</template>

<script setup>
import { ref } from 'vue';

import Card from "@/components/utils/Card.vue";
import FormInput from "@/components/utils/FormInput.vue";

const props = defineProps({
    commentId: Number,
    isOpen: Boolean,
});

const emit = defineEmits(['close']);

const margin = ref({
    top: 10,
    left: 10,
    right: 10,
    bottom: 20,
})

function generateReport() {
    window.open(route(`api.reports.inventory.comment-report-generator`, {
        REPORT_TYPE: 'comment-summary',
        COMMENT_ID: props.commentId,
        MARGIN_TOP: margin.value.top,
        MARGIN_LEFT: margin.value.left,
        MARGIN_RIGHT: margin.value.right,
        MARGIN_BOTTOM: margin.value.bottom
    }), '_blank');
}
</script>

<style scoped>
.pop-up {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: rgb(0, 0, 0, 0.5);
}

.pop-up .card {
    width: 100%;
}

@media (min-width: 768px) {
    .pop-up .card {
        width: 40%;
    }
}

@media (min-width: 992px) {
    .pop-up .card {
        width: 30%;
    }
}
</style>
