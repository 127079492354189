import { ref } from 'vue';

import axios from 'axios';

import constructFetchError from "./constructFetchError";

export function useCompanies() {
    const companies = ref(null);

    const getCompanies = async () => {
        try {
            const getCompaniesResponse = await axios.get(route('api.companies.index'));
            companies.value = getCompaniesResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching companies.');
        }
    };

    return { companies, getCompanies };
}
