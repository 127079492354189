<template>
    <Card class="mb-3">
        <template #header>
            Select Employee
        </template>
        <div class="row g-3">
            <div class="col-md-3">
                <FormInput type="text" v-model="selectedEmployeeId"
                    label="Employee ID" id-prefix="empid"
                    disabled />
            </div>
            <div class="col-md-3">
                <FormInput type="text" v-model="selectedEmployeeDepartment"
                    label="Department" id-prefix="department"
                    disabled />
            </div>
            <div class="col-md-3">
                <FormInput type="text" v-model="selectedEmployeeFirstName"
                    label="First Name" id-prefix="firstName"
                    disabled />
            </div>
            <div class="col-md-3">
                <FormInput type="text" v-model="selectedEmployeeLastName"
                    label="Last Name" id-prefix="lastName"
                    disabled />
            </div>
        </div>
        <template #footer>
            <div class="text-end">
                <button type="button" class="btn btn-primary"
                    @click="_ => { isSelectEmployeePopupOpen = true; }">
                    Select
                </button>
            </div>
        </template>
    </Card>

    <div class="row g-3">
        <div class="col-md-3">
            <Card>
                <template #header>
                    Specify Date Range
                </template>
                <FormInput type="date" v-model="startDateFilter"
                    label="Start Date" id-prefix="startDate"
                    class="mb-3" />
                <FormInput type="date" v-model="endDateFilter"
                    label="End Date" id-prefix="endDate" />
                <template #footer>
                    <div class="text-end">
                        <button type="button" class="btn btn-primary"
                            @click="applyDateFilters">
                            Apply
                        </button>
                    </div>
                </template>
            </Card>
        </div>
        <div class="col-md-9">
            <EmployeeNidekaHoursTableCard :employee-id="selectedEmployee?.EMPID"
                :start-date="appliedFilters.startDate"
                :end-date="appliedFilters.endDate" />
        </div>
    </div>


    <SelectEmployee :is-open="isSelectEmployeePopupOpen"
        :on-close="_ => { isSelectEmployeePopupOpen = false; }"
        :on-select-employee="employee => {
            selectedEmployee = employee;
            isSelectEmployeePopupOpen = false;
        }" />
</template>

<script setup>
import { computed, ref } from 'vue';

import Card from "@/components/utils/Card.vue";
import FormInput from "@/components/utils/FormInput.vue";
import SelectEmployee from "@/components/utils/SelectEmployee.vue";
import EmployeeNidekaHoursTableCard from './EmployeeNidekaHoursTableCard.vue';

const selectedEmployee = ref(null);

const selectedEmployeeId = computed(() => selectedEmployee.value?.EMPID);
const selectedEmployeeDepartment = computed(() => selectedEmployee.value?.DEPT);
const selectedEmployeeFirstName = computed(() => selectedEmployee.value?.FNAME);
const selectedEmployeeLastName = computed(() => selectedEmployee.value?.LNAME);

const isSelectEmployeePopupOpen = ref(false);

const startDateFilter = ref(null);
const endDateFilter = ref(null);

const appliedFilters = ref({
    startDate: null,
    endDate: null,
});

function applyDateFilters() {
    appliedFilters.value.startDate = startDateFilter.value;
    appliedFilters.value.endDate = endDateFilter.value;
}
</script>
