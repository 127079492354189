import { ref } from "vue";

import constructFetchError from "./constructFetchError";

import axios from "axios";

export function useLocationKeys() {
    const locationKeys = ref(null);
    const totalRecords = ref(null);

    const getLocationKeys = async (location, params) => {
        try {
            const getLocationKeysResponse = await axios.get(route('api.locations.location-keys.index', {
                location: location,
                ...params
            }));
            locationKeys.value = getLocationKeysResponse.data.data;
            totalRecords.value = getLocationKeysResponse.data.meta.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching location keys.');
        }
    };

    const postLocationKey = async (location, locationKey) => {
        try {
            const postLocationKeyResponse = await axios.post(route('api.locations.location-keys.store', {
                location: location
            }), locationKey);
            return postLocationKeyResponse;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while inserting location key.');
        }
    };

    const deleteLocationKey = async (location, locationKeyId) => {
        try {
            const deleteLocationKeyResponse = await axios.delete(route('api.locations.location-keys.destroy', {
                location: location,
                location_key: locationKeyId
            }));
            return deleteLocationKeyResponse;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while deleting location key.');
        }
    };
    
    return { locationKeys, totalRecords, getLocationKeys, postLocationKey, deleteLocationKey };
}
