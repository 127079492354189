                                                                                                                                                                                                                                                                                                                                                                                                 <template>
    <Card>
        <template #header>
            Inventory Checks
        </template>
        <DataTable :loading="isLoading" lazy :value="inventoryChecks" :total-records="inventoryChecksTotalRecords"
            paginator :rows="10" @page="e => { onPage(e); fetchInventoryChecks(); }"
            filter-display="row" v-model:filters="filters" @filter="tableOnFilter"
            sort-mode="multiple" @sort="e => { onSort(e); fetchInventoryChecks(); }"
            v-model:selection="selectedInventoryCheck" selection-mode="single"
            :pt="{ table: { class: 'table table-bordered table-hover' } }">
            <Column field="LOCATION" header="Location" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                    <Button type="button" class="btn btn-primary" @click="filterCallback()">
                        Search
                    </Button>
                </template>
            </Column>
            <Column field="INVENTORY_DONE" header="Inventory Done" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                    <Button type="button" class="btn btn-primary" @click="filterCallback()">
                        Search
                    </Button>
                </template>
            </Column>
            <Column field="RAW" header="Raw" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
                bodyCell: { class: 'barcode' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                    <Button type="button" class="btn btn-primary" @click="filterCallback()">
                        Search
                    </Button>
                </template>
            </Column>
            <Column field="STYLE" header="Style" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                    <Button type="button" class="btn btn-primary" @click="filterCallback()">
                        Search
                    </Button>
                </template>
            </Column>
            <Column field="COLOR" header="Color" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                    <Button type="button" class="btn btn-primary" @click="filterCallback()">
                        Search
                    </Button>
                </template>
            </Column>
            <template #empty>
                <div class="text-center">
                    No data.
                </div>
            </template>
        </DataTable>
        <template #footer>
            <div class="text-end">
                <button type="button" class="btn btn-primary me-1"
                    :disabled="!selectedInventoryCheck || isUpdating"
                    @click="updateRaw">
                    <span v-if="isUpdating" class="spinner-border spinner-border-sm"
                            role="status" aria-hidden="true"></span>
                    Update Raw
                </button>
                <button type="button" class="btn btn-outline-danger"
                    :disabled="!selectedInventoryCheck || isDeleting"
                    @click="deleteRaw">
                    <span v-if="isDeleting" class="spinner-border spinner-border-sm"
                            role="status" aria-hidden="true"></span>
                    Delete
                </button>
            </div>
        </template>
    </Card>
</template>

<script setup>
import { ref, watch } from 'vue';

import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useInventoryChecks } from '@/composables/data/inventoryChecks';

import Card from '@/components/utils/Card.vue';

import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

import { deconstructBarcode } from '@/utils/barcodes';

const props = defineProps({
    barcode: String,
});

const emit = defineEmits(['success', 'error']);

const { params, filters, onPage, onFilter, onSort } = useDataTableParams(
    ['LOCATION', 'INVENTORY_DONE', 'RAW', 'STYLE', 'COLOR']
);

const { inventoryChecks, inventoryChecksTotalRecords, getInventoryChecksOfBarcode,
    updateInventoryCheck, deleteInventoryCheck } = useInventoryChecks();

const isLoading = ref(false);

const fetchTimeout = ref(null);

const selectedInventoryCheck = ref(null);

const isUpdating = ref(false);
const isDeleting = ref(false);

async function fetchInventoryChecks() {
    inventoryChecks.value = null;

    if(!props.barcode)
        return;

    isLoading.value = true;
    try {
        await getInventoryChecksOfBarcode(props.barcode, params.value);
    } catch(e) {
        emit('error', e.message);
    }
    isLoading.value = false;
}

function tableOnFilter(e) {
    onFilter(e);
    if(fetchTimeout.value)
        clearTimeout(fetchTimeout.value);
    fetchTimeout.value = setTimeout(fetchInventoryChecks, 300);
}

watch(() => props.barcode, fetchInventoryChecks);

async function updateRaw() {
    const newRaw = window.prompt('Enter new RAW:');

    if(!newRaw) {
        emit('error', 'Cannot use blank barcode.');
        return;
    }

    isUpdating.value = true;
    try {
        await updateInventoryCheck(selectedInventoryCheck.value.id, {
            ...selectedInventoryCheck.value,
            RAW: newRaw,
            STYLE: deconstructBarcode(newRaw).style,
        });
        emit('success', 'Successfully updated barcode.');
        fetchInventoryChecks();
    } catch(e) {
        emit('error', e.message);
    }
    isUpdating.value = false;
}

async function deleteRaw() {
    if(window.prompt("Type 'DELETE BC' to continue:") != 'DELETE BC')
        return;

    isDeleting.value = true;
    try {
        await deleteInventoryCheck(selectedInventoryCheck.value.id);
        emit('success', 'Successfully deleted barcode.');
        fetchInventoryChecks();
    } catch(e) {
        emit('error', e.message);
    }
    isDeleting.value = false;
}
</script>
