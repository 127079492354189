<template>
    <div>
        <div
            v-if="isLoading"
            class="position-fixed start-0 end-0 top-0 bottom-0 d-flex justify-content-center align-items-center toast-shadow"
        >
            <div class="spinner-border text-primary" role="status"></div>
        </div>
        <div
            v-if="isToastSuccess"
            class="position-fixed start-0 end-0 top-0 bottom-0 d-flex justify-content-center align-items-center toast-shadow"
        >
            <div class="w-50 toast show bg-success" role="alert" aria-live="assertive" aria-atomic="true">
                <div class="toast-header">
                    <h6 class="me-auto text-black m-0">Success!</h6>
                    <button @click="hideToast()" type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
                    <div class="toast-body text-white p-4 fs-1.4">
                    {{ toastMessage }}
                    </div>
            </div>
        </div>
        <div
            v-if="isToastError"
            class="position-fixed start-0 end-0 top-0 bottom-0 d-flex justify-content-center align-items-center toast-shadow"
        >
            <div class="w-50 toast show bg-danger" role="alert" aria-live="assertive" aria-atomic="true">
                <div class="toast-header">
                    <h6 class="me-auto text-black m-0">Error!</h6>
                    <button @click="hideToast()" type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
                    <div class="toast-body text-white p-4 fs-1.4">
                    {{ toastMessage }}
                    </div>
            </div>
        </div>
        <div
            class="card p-4 voucher-main-card"
            id="voucher-main-card"
        >
                <div class="d-flex flex-column align-items-center">
                    <section class="grid-section d-flex flex-row p-3 w-75">
                        <div class="w-50 d-flex flex-column justify-content-center align-items-center">
                            <h2>Create Voucher</h2>
                        </div>
                        <div class="w-50 d-flex flex-column">
                            <div class="d-flex flex-row mb-1">
                                <button
                                    @click="setVoucher('CS')"
                                    type="button"
                                    class="btn btn-primary w-50"
                                >
                                    NEW CASH AP
                                </button>
                                <button
                                    @click="setVoucher('CK')"
                                    type="button"
                                    class="btn btn-primary w-50 ms-1"
                                >
                                    NEW CHECK AP
                                </button>
                            </div>
                            <div class="d-flex flex-row mb-1">
                                <button
                                    @click="setVoucher('AR')"
                                    type="button"
                                    class="btn btn-primary w-50"
                                >
                                    NEW AR
                                </button>
                                <button
                                    @click="setVoucher('CC')"
                                    type="button"
                                    class="btn btn-primary w-50 ms-1"
                                    :disabled="!isAdmin"
                                >
                                    NEW DISB
                                </button>
                            </div>
                            <div class="d-flex flex-row mb-1">
                                <button
                                    @click="setVoucher('AJ')"
                                    type="button"
                                    class="btn btn-primary w-50"
                                >
                                    NEW ADJ
                                </button>
                            </div>
                        </div>
                    </section>
                    <section class="grid-section d-flex flex-column p-3 w-75">
                        <div v-if="isAdmin" class="w-100">
                            <div class="d-flex flex-row">
                                <label for="PAYEE_TYPE" class="form-label my-auto w-25">PAYEE TYPE:</label>
                                <div class="dropdownContainer position-relative w-25 mb-1" ref="dropdownContainer">
                                    <button
                                        @click="isListVisible = !isListVisible"
                                        class="dropdown-text text-dark btn btn-primary w-100 d-flex justify-content-between"
                                     >
                                        <span>{{ payeeTypeText }}</span>
                                        <span><i class="fa-solid fa-angle-down"></i></span>
                                    </button>
                                    <ul v-if="isListVisible" class="dropdown-list position-absolute top-100 start-0 list-group w-100">
                                        <li @click="searchPayee('I')" class="dropdown list-group-item list-group-item-action">INTERNAL</li>
                                        <li @click="searchPayee('E')" class="dropdown list-group-item list-group-item-action">EXTERNAL</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="d-flex flex-row">
                                <label for="PAYEE_ID" class="form-label my-auto w-25">PAYEE ID:</label>
                                <input v-model="payeeId" type="text" class="form-control w-25 mb-1" required disabled>
                            </div>
                            <div class="d-flex flex-row">
                                <span class="w-25"></span>
                                <p class="m-0 w-50 p-2">{{ payeeText }}</p>
                            </div>
                        </div>
                        <div class="d-flex flex-row">
                            <label for="COMPANY" class="form-label my-auto w-25">COMPANY:</label>
                            <select id="company" v-model="company" name="COMPANY" class="form-select w-50 mb-1" required>
                                <option
                                    v-for="company in companies"
                                    :value="company.COMPANY_CODE"
                                >
                                    {{ company.COMPANY_DESCRIPTION }}
                                </option>
                            </select>
                        </div>
                        <div class="d-flex flex-row">
                            <label for="VOUCHER_ID" class="form-label my-auto w-25">VOUCHER #:</label>
                            <input v-model="voucherId" type="text" class="form-control w-50 mb-1" required disabled>
                        </div>
                        <div class="d-flex flex-row">
                            <label for="VOUCHER_DATE" class="form-label my-auto w-25">VOUCHER DATE:</label>
                            <input v-model="voucherDate" type="date" class="form-control w-50 mb-1" required disabled>
                        </div>
                        <div class="d-flex flex-row">
                            <label for="VOUCHER_TYPE" class="form-label my-auto w-25">VOUCHER TYPE:</label>
                            <input v-model="voucherTypeText" type="text" class="form-control w-50 mb-1" disabled>
                            <div class="d-flex justify-content-center w-25">
                                <button type="button" class="btn btn-primary w-75" @click="openSelectAccount('VOUCHER')" :disabled="voucherIdType === 'CS'">SEARCH BANK</button>
                            </div>
                        </div>
                        <div class="d-flex flex-row">
                            <label for="VOUCHER_BANK" class="form-label my-auto w-25">VOUCHER BANK:</label>
                            <input v-model="accountId" type="number" class="form-control w-25 mb-1" disabled>
                            <input v-model="accountName" type="text" class="form-control w-25 mb-1">
                        </div>
                        <div class="d-flex flex-row">
                            <label for="CHECK_NUMBER" class="form-label my-auto w-25">CHECK #:</label>
                            <input
                                id="check-number"
                                v-model="checkNumber"
                                type="text"
                                class="form-control w-50 mb-1"
                                :required="voucherIdType === 'CK'"
                                @keyup.enter="focusNextInput('voucher-amount')"
                            >
                            <p class="w-25 text-center my-auto">(values will be calculated)</p>
                        </div>
                        <div class="d-flex flex-row">
                            <label for="AMOUNT" class="form-label my-auto w-25">AMOUNT:</label>
                            <input
                                id="voucher-amount"
                                v-model="voucherAmount"
                                @keyup.enter="computedAmount"
                                type="text"
                                class="form-control w-50 mb-1"
                                required>
                        </div>
                        <div class="d-flex flex-row justify-content-center">
                            <p>{{ isNaN(voucherAmount) || voucherAmount == '' || voucherAmount == '0' || voucherAmount == '0.00' ? '0.00' : `${Number(voucherAmount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} (${computedAmountInWords})` }}</p>
                        </div>
                        <div class="d-flex flex-row">
                            <label for="DESCRIPTION" class="form-label my-auto w-25">DESCRIPTION:</label>
                            <input
                                id="description"
                                @keyup.enter="focusNextInput('memo')"
                                v-model="description"
                                type="text"
                                class="form-control w-50 mb-1"
                                required
                            >
                        </div>
                        <div class="d-flex flex-row">
                            <label for="MEMO" class="form-label my-auto w-25">MEMO:</label>
                            <textarea
                                id="memo"
                                v-model="memo"
                                class="form-control w-75 mb-1"
                            ></textarea>
                        </div>
                        <div v-if="!isAdmin">
                            <div class="d-flex flex-row">
                                <label for="PAYEE_TYPE" class="form-label my-auto w-25">PAYEE TYPE:</label>
                                <div class="dropdownContainer position-relative w-25 mb-1" ref="dropdownContainer">
                                    <button
                                        @click="isListVisible = !isListVisible"
                                        class="dropdown-text text-dark btn btn-primary w-100 d-flex justify-content-between"
                                    >
                                        <span>{{ payeeTypeText }}</span>
                                        <span><i class="fa-solid fa-angle-down"></i></span>
                                    </button>
                                    <ul v-if="isListVisible" class="dropdown-list position-absolute top-100 start-0 list-group w-100">
                                        <li @click="searchPayee('I')" class="dropdown list-group-item list-group-item-action">INTERNAL</li>
                                        <li @click="searchPayee('E')" class="dropdown list-group-item list-group-item-action">EXTERNAL</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="d-flex flex-row">
                                <label for="PAYEE_ID" class="form-label my-auto w-25">PAYEE ID:</label>
                                <input
                                    v-model="payeeId"
                                    type="text"
                                    class="form-control w-25 mb-1"
                                    required
                                    disabled
                                >
                                    <p class="m-0 w-50 p-2">{{ payeeText }}</p>
                            </div>
                        </div>
                        <div class="d-flex flex-row">
                            <label for="PAYEE_DATE" class="form-label my-auto w-25">PAYEE DATE:</label>
                            <input v-model="payeeDate" type="date" class="form-control w-50 mb-1">
                        </div>
                        <div class="d-flex flex-row">
                            <label for="PREPARED_BY" class="form-label my-auto w-25">PREPARED BY:</label>
                            <input :value="username" type="text" class="form-control w-50 mb-1" required disabled>
                        </div>
                        <div class="d-flex mt-3 justify-content-center">
                            <button type="submit" @click="submitForm" class="btn btn-primary w-75">CREATE VOUCHER</button>
                        </div>
                    </section>
                </div>
            <SelectAccount
                :isOpen="isSelectAccountOpen"
                :onClose="() => closeSelectAccount()"
                :onSelectAccount="handleSelectAccount"
                :voucherType="voucherIdType"
            />
            <SelectEmployee
                :isOpen="isSelectEmployeeOpen"
                :onClose="() => closeSelectEmployee()"
                :onSelectEmployee="handleSelectEmployee"
            />
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, watch, onBeforeUnmount } from 'vue';
import { computed } from 'vue';
import numberToWords from 'number-to-words';
import axios from 'axios';

import SelectAccount from '../../utils/SelectAccount.vue';
import SelectEmployee from '../../utils/SelectEmployee.vue';

    const voucherIdType = ref('');
    const voucherIdCodeType = ref('');
    const company = ref('');
    const companies = ref([]);
    const voucherId = ref('');
    const voucherDate = ref('');
    const voucherTypeText = ref('');
    const accountId = ref(0);
    const accountName = ref('');
    const checkNumber = ref('');
    const voucherAmount = ref('');
    const description = ref('');
    const memo = ref('');
    const payeeType = ref('');
    const payeeId = ref('');
    const payeeText = ref('');
    const payeeDate = ref('');
    const username = ref('');
    const glId = ref('');
    const selectAccountType = ref('');
    const isSelectAccountOpen = ref(false);
    const isSelectEmployeeOpen = ref(false);
    const isLoading = ref(false);
    const isToastSuccess = ref(false);
    const isToastError = ref(false);
    const toastMessage = ref("");
    const isListVisible = ref(false);
    const payeeTypeText = ref('');
    const isPayee = ref(false);
    const isAdmin = ref(false);

    async function submitForm() {
        try {
            if (voucherIdType.value === 'AJ')
                glId.value = 13;
            else
                glId.value = await selectAccountGL(accountId.value);

            if (company.value == ''){
                isToastError.value = true;
                toastMessage.value = 'Enter Company';
                return;
            }
            if (voucherTypeText.value == 'CHECK' && accountId.value == 0 && voucherIdCodeType.value != 'AR'){
                isToastError.value = true;
                toastMessage.value = 'Check voucher requires bank information.';
                return;
            }
            if (voucherTypeText.value == 'CHECK' && checkNumber.value == '' && voucherIdCodeType.value != 'AR'){
                isToastError.value = true;
                toastMessage.value = 'Check voucher requires check number.';
                return;
            }

            const formDataVoucher = {
                VOUCHER_ID: voucherId.value,
                VOUCHER_DATE: voucherDate.value,
                VOUCHER_AMT: parseFloat(removeCommas(voucherAmount.value)).toFixed(2),
                DESCRIPTION: description.value,
                MEMO: memo.value,
                PAYEE_TYPE: payeeType.value,
                PAYEE_ID: payeeId.value,
                PREPARED_BY: username.value,
                LAST_UPDATED_BY: username.value,
                GL_ID: glId.value,
                PAYEE_DATE: payeeDate.value,
                CHECK_BANK: accountId.value !== '' ? accountId.value : 0,
                CHECK_NUM: checkNumber.value !== '' ? checkNumber.value : null,
            }

            const response = await axios.post(route('api.vouchers.store'), formDataVoucher);
            if (response.status === 200 || response.status === 201) {
                isToastSuccess.value = true;
                toastMessage.value = `Voucher ${voucherId.value} created.`;
                resetForm();
            }
        } catch (error) {
            isToastError.value = true;
            toastMessage.value = error.response.data.message;
        }
    }

    async function selectAccountGL(id) {
        try {
            const response = await axios.get(route('api.accounts.index'), {
                params: {
                    accountId: id
                }
            });;
            return response.data.data[0].ACCOUNT_GL;
        } catch (error) {
            isToastError.value = true;
            toastMessage.value = error.response.data.message;
        }
    }

    function resetForm() {
        voucherIdType.value = '';
        company.value = '';
        voucherId.value = '';
        voucherTypeText.value = '';
        voucherIdCodeType.value = '';
        accountId.value = '';
        accountName.value = '';
        checkNumber.value = '';
        voucherAmount.value = '';
        description.value = '';
        memo.value = '';
        payeeType.value = '';
        payeeTypeText.value = '';
        payeeId.value = '';
        payeeText.value = '';
        payeeDate.value = '';
        selectAccountType.value = '';
        isSelectAccountOpen.value = false;
        isSelectEmployeeOpen.value = false;
    }

    function getCurrentDate() {
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().split('T')[0];
        return formattedDate;
    }

    function getCurrentYear() {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        return currentYear;
    }

    function setupInitialValues () {
        voucherDate.value = getCurrentDate();
        username.value = "/" + document.querySelector('#create-voucher').getAttribute('data-username');
    }

    onMounted(() => {
        setupInitialValues();
        getCompanies();
        checkDisbursementPermission();
    });

    async function checkDisbursementPermission() {
        try {
            const response = await axios.get(route('api.voucher.disbursementPermission'));
            isAdmin.value = response.data;
        } catch (e) {
            console.log(e);
        }
    }

    async function getCompanies() {
        try {
            const response = await axios.get(route('api.companies.index'));
            companies.value = response.data.data;
        } catch (error) {
            isToastError.value = true;
            toastMessage.value = error.response.data.message;
        }
    }

    //Generate voucher id
    watch([company, voucherIdCodeType], () => {
        if (company.value != '' && voucherIdCodeType.value != '') {
            generateVoucherId();
        }
    });

    async function generateVoucherId() {
        const voucherCode = company.value + "-" + voucherIdCodeType.value + getCurrentYear();
        try {
            const response = await axios.get(route('api.voucher.max'), {
                params: {
                    voucherCode: voucherCode,
                },
            });
            const paddedCount = String(response.data.maxVoucherId + 1).padStart(5, '0');
            voucherId.value = voucherCode + "-" + paddedCount;
        } catch (error) {
            isToastError.value = true;
            toastMessage.value = error.response.data.message;
        }
    }

    function setVoucher(type) {
        if (company.value != '' || type === 'CC') {
            const voucherTitle = document.getElementById('create-voucher-title');
            voucherIdType.value = type;
            switch (type) {
                case 'CS':
                    accountId.value = 0;
                    accountName.value = '"CASH"'
                    voucherTypeText.value = 'CASH';
                    voucherIdCodeType.value = type;
                    voucherTitle.innerText = 'Create Cash Voucher';
                    focusNextInput('check-number');
                break;
                case 'CK':
                    voucherTitle.innerText = 'Create Check Voucher';
                    openSelectAccount('VOUCHER');
                    break;
                case 'AR':
                    voucherTitle.innerText = 'Create AR Voucher';
                    openSelectAccount('VOUCHER');
                    break;
                case 'AJ':
                    voucherTypeText.value = 'CASH';
                    accountId.value = 0;
                    accountName.value = '"CASH"';
                    voucherIdCodeType.value = type;
                    voucherTitle.innerText = 'Create Adjustment Voucher';
                    focusNextInput('check-number');
                break;
                case 'CC':
                    voucherTitle.innerText = 'Create Disbursement Voucher';
                    openSelectEmployee();
                break;
                default:
                break;
            }
        } else {
            isToastError.value = true;
            toastMessage.value = 'Please select a company.';
        }
    }

    function openSelectAccount(type) {
        selectAccountType.value = type;
        isSelectAccountOpen.value = true;
    }

    function closeSelectAccount() {
        isSelectAccountOpen.value = false;
    }

    function handleSelectAccount(selectedAccount, voucherType) {
        if (selectAccountType.value == 'VOUCHER') {
            if (selectedAccount.ACCOUNT_NAME === 'CASH') {
                voucherTypeText.value = 'CASH';
            } else {
                voucherTypeText.value = 'CHECK';
            }
            voucherIdCodeType.value = voucherType;
            accountId.value = selectedAccount.ACCOUNT_ID;
            accountName.value = selectedAccount.ACCOUNT_NAME;
            focusNextInput('check-number');
            return;
        }
        isPayee.value = false;
        payeeType.value = 'E';
        payeeTypeText.value = 'EXTERNAL';
        payeeId.value = selectedAccount.ACCOUNT_ID;
        payeeText.value = selectedAccount.ACCOUNT_NAME;
    };

    function openSelectEmployee() {
        isSelectEmployeeOpen.value = true;
    }

    function closeSelectEmployee() {
        isSelectEmployeeOpen.value = false;
    }

    async function handleSelectEmployee(selectedEmployee) {
        if(isAdmin.value){
            try {
                company.value = selectedEmployee.COMPANY_CODE;
                voucherTypeText.value = 'CHECK';
                voucherIdCodeType.value = 'CC';
                switch (selectedEmployee.PAYMENT_FLAG) {
                    case '4':
                        getBank(689);
                        break;
                    case '2':
                        getBank(1);
                        break;
                    default:
                        break;
                }
                focusNextInput('check-number');
            } catch (error) {
                isToastError.value = true;
                toastMessage.value = error.response.data.message;
            }
        }
        payeeType.value = 'I';
        payeeTypeText.value = 'INTERNAL';
        payeeId.value = selectedEmployee.EMPID;
        payeeText.value = selectedEmployee.FNAME + " " + selectedEmployee.LNAME
    };

    async function getBank(id) {
        const response = await axios.get(route('api.accounts.show', {id: id}));
        accountId.value = response.data.data.ACCOUNT_ID;
        accountName.value = response.data.data.ACCOUNT_NAME;
    }

    function searchPayee(type) {
        isListVisible.value = false;
        switch (type) {
            case 'E':
                openSelectAccount('PAYEE');
                isPayee.value = true;
                break;
            case 'I':
                openSelectEmployee();
                break;
            case '':
                isToastError.value = true;
                toastMessage.value = 'Please select a payee type.';
                break;
            default:
                break;
        }
    }

    const computedAmount = () => {
        try {
            const result = eval(voucherAmount.value);
            if (isNaN(result) || !isFinite(result)) {
                return NaN;
            }
            voucherAmount.value = result.toFixed(2);
            focusNextInput('description')
        } catch (error) {
            return NaN;
        }
    };

    const formatAmountInWords = (amount) => {
        try {
            const [wholePart, decimalPart] = amount.split('.');
            const wholeWords = numberToWords.toWords(parseInt(wholePart));
            return `${wholeWords} and ${decimalPart == undefined || decimalPart == "" ? 0 : decimalPart}/100 pesos only`;
        } catch (error) {
            return '0.00';
        }
    };

    const computedAmountInWords = computed(() => {
        try {
            return `${formatAmountInWords(voucherAmount.value)}`;
        } catch (error) {
            return '0.00';
        }
    });

    function removeCommas(amountString) {
        return parseFloat(amountString.replace(/,/g, ''));
    }

    onMounted(() => {
      document.addEventListener('click', closeList);
    });

    const closeList = (event) => {
      const dropdownContainer = document.querySelector('.dropdownContainer');
      if (dropdownContainer && !dropdownContainer.contains(event.target)) {
        isListVisible.value = false;
      }
    };

    onBeforeUnmount(() => {
      document.removeEventListener('click', closeList);
    });

    function hideToast() {
        isToastError.value = false;
        isToastSuccess.value = false;
    }

    function focusNextInput(nextInputId) {
        // Find the next input field using ID
        const nextInput = document.querySelector('#' + nextInputId);

        if (nextInput) {
            nextInput.focus();
        }
    };
</script>

<style scoped>
section {
    padding: 1rem;
    background-color: #f9fafb;
    border-radius: 0.5rem;
    border: 2px solid #d1d5db;
}

.voucher-main-card {
    display: flex;
    column-gap: 0.5rem;
    width: 100%;
    flex-wrap: wrap;
}


.dropdown {
    cursor: pointer;
    padding: 3px;
}

.dropdown:hover {
    background-color: #0d6efd;
    color: white;
}

.toast-shadow {
    padding: 3;
    z-index: 11;
    background: rgb(0, 0, 0, 0.5);
}

.dropdown-list {
    z-index: 1;
}

.dropdown-text {
    background-color: #F9FAFB;
    border: 1px solid #CED4DA;
}
</style>
