import { ref } from "vue";

import axios from "axios";

import constructFetchError from "./constructFetchError";

export function useRawOrderBreakdowns() {
    const rawOrderBreakdowns = ref(null);
    const totalRecords = ref(null);

    const getRawOrderBreakdowns = async (rawOrderId, params) => {
        try {
            const getRawOrderBreakdownsResponse = await axios.get(route('api.raw-orders.raw-order-breakdowns.index', {
                raw_order: rawOrderId,
                ...params
            }));
            rawOrderBreakdowns.value = getRawOrderBreakdownsResponse.data.data;
            totalRecords.value = getRawOrderBreakdownsResponse.data.meta.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching raw order breakdowns.');
        }
    };

    const postRawOrderBreakdown = async (rawOrderId, rawOrderBreakdown) => {
        try {
            const postRawOrderBreakdownResponse = await axios.post(route('api.raw-orders.raw-order-breakdowns.store', {
                raw_order: rawOrderId,
            }), rawOrderBreakdown);
            return postRawOrderBreakdownResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while saving raw order breakdown.');
        }
    };

    const putRawOrderBreakdown = async (rawOrderId, rawOrderBreakdownId, rawOrderBreakdown) => {
        try {
            const putRawOrderBreakdownResponse = await axios.put(route('api.raw-orders.raw-order-breakdowns.update', {
                raw_order: rawOrderId,
                raw_order_breakdown: rawOrderBreakdownId,
            }), rawOrderBreakdown);
            return putRawOrderBreakdownResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while updating raw order breakdown.');
        }
    };
    
    return {
        rawOrderBreakdowns,
        totalRecords,
        getRawOrderBreakdowns,
        postRawOrderBreakdown,
        putRawOrderBreakdown,
    };
}
