<template>
    <Card class="mb-3">
        <template #header>
            Style KCC
        </template>
        <form>
            <div v-if="isLoading" class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
            <FormInput label="KCC SKU" type="text" v-model="kccSku.KCC_SKU"
                is-horizontal id-prefix="kccSku" class="mb-3"
                input-class="form-control-sm" :errors="errors.KCC_SKU" />
            <button type="button" class="btn btn-primary me-1"
                :disabled="!style.STYLE || isLoading || isSubmitting"
                @click="saveButtonOnClick">
                <span v-if="isSubmitting" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Save
            </button>
        </form>
    </Card>
</template>

<script setup>
import { ref, watch } from 'vue';

import Card from '../../../utils/Card.vue';
import FormInput from '../../../utils/FormInput.vue';

import axios from 'axios';

import handleFetchErrors from "../../../../utils/handleFetchErrors";
import scrollToTop from "../../../../utils/scrollToTop";

import { useFlashMessages } from "../../../../composables/flashmessages";

const { addFlashMessage } = useFlashMessages();

const props = defineProps({
    style: Object,
    class: String
})

const errors = ref({});

const kccSku = ref({});

const isLoading = ref(false);
const isSubmitting = ref(false);

watch(() => props.style, async () => {
    errors.value = {};
    kccSku.value = {};

    if(props.style?.STYLE == null)
        return;

    isLoading.value = true;
    try {
        const kccSkuResponse = await axios.get(
            route('api.styles.kcc-sku.index', {
                style: props.style.STYLE
            }));
        kccSku.value = kccSkuResponse.data.data;
    } catch(e) {
        console.log(e);
        kccSku.value = {};
        if(!e.response || e.response.status != 404) {
            handleFetchErrors(e, 'Error while fetching KCC SKU.');
            scrollToTop();
        }
    }
    isLoading.value = false;
});

async function saveButtonOnClick() {
    errors.value = {};
    isSubmitting.value = true;
    try {
        const saveKCCSkuResponse = await axios.post(
            route('api.styles.kcc-sku.store', {
                style: props.style.STYLE
            }), kccSku.value);
        kccSku.value = saveKCCSkuResponse.data.data;
        addFlashMessage('SUCCESS', 'Successfully saved KCC SKU ' +
            kccSku.value.KCC_SKU + '. ');
    } catch(e) {
        console.log(e);
        if(e.response && e.response.status == 404) return;
        errors.value = handleFetchErrors(e, 'Error while saving KCC SKU.');
    }
    isSubmitting.value = false;
    scrollToTop();
}
</script>
