<template>
    <div
        class="card p-4 employee-maintenance-main-card"
        id="employee-maintenance-main-card"
    >
        <div
            v-if="isLoading"
            class="position-fixed start-0 end-0 top-0 bottom-0 d-flex justify-content-center align-items-center toast-shadow"
        >
            <div class="spinner-border text-primary" role="status"></div>
        </div>
        <div
            v-if="isToastSuccess"
            class="position-fixed start-0 end-0 top-0 bottom-0 d-flex justify-content-center align-items-center toast-shadow"
        >
            <div class="w-50 toast show bg-success" role="alert" aria-live="assertive" aria-atomic="true">
                <div class="toast-header">
                    <h6 class="me-auto text-black m-0">Success!</h6>
                    <button @click="hideToast()" type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
                    <div class="toast-body text-white p-4 fs-1.4">
                    {{ toastMessage }}
                    </div>
            </div>
        </div>
        <div
            v-if="isToastError"
            class="position-fixed start-0 end-0 top-0 bottom-0 d-flex justify-content-center align-items-center toast-shadow"
        >
            <div class="w-50 toast show bg-danger" role="alert" aria-live="assertive" aria-atomic="true">
                <div class="toast-header">
                    <h6 class="me-auto text-black m-0">Error!</h6>
                    <button @click="hideToast()" type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
                    <div class="toast-body text-white p-4 fs-1.4">
                    {{ toastMessage }}
                    </div>
            </div>
        </div>
        <message-box
            :isOpen="openMessageBox"
            :onClose="() => setCloseMessageBox()"
        >
            <section>
                <h5>Are you sure you want to approve the voucher for {{ titleMessageBox }}?</h5>
                <div class="d-flex justify-content-center">
                    <div class="d-flex justify-content-between w-50">
                        <button
                            type="button"
                            class="btn btn-primary w-50 m-2"
                            @click="handleAnswer()"
                        >
                            Yes
                        </button>
                        <button
                            type="button"
                            class="btn btn-primary w-50 m-2"
                            @click="setCloseMessageBox()"
                        >
                            No
                        </button>
                    </div>
                </div>
            </section>
        </message-box>
        <div class="d-flex flex-row">
            <section class="grid-section d-flex flex-row p-3 w-25">
                <div class="d-flex flex-column w-25 m-auto flex-grow-1">
                    <div class="d-flex flex-column flex-grow-1 mb-1">
                        <label
                            class="d-flex text-nowrap me-1 w-25"
                            for="START_DATE"
                            >START DATE:
                        </label>
                        <input
                            type="date"
                            class="form-control form-control-sm"
                            v-model="startDate"
                        />
                    </div>
                    <div class="d-flex flex-column mb-1">
                        <label
                            class="d-flex flex-row text-nowrap me-1 w-25"
                            for="END_DATE"
                            >END DATE:
                        </label>
                        <input
                            type="date"
                            class="form-control form-control-sm mb-1"
                            v-model="endDate"
                        />
                    </div>
                    <button
                        type="button"
                        class="btn btn-primary"
                        id="date-search-button"
                        @click="getVoucherList(); clearVoucherSelection();"
                    >
                        SEARCH BY DATE
                    </button>
                </div>
            </section>
            <section class="grid-section d-flex flex-row p-3 w-50">
                <div class="d-flex flex-column w-50 m-1 flex-grow-1">
                    <div class="d-flex flex-row">
                        <h5>SEARCH VOUCHERS NEEDING...</h5>
                    </div>
                    <div class="d-flex flex-row mb-1">
                        <button
                            type="button"
                            class="btn btn-primary w-50 me-1"
                            @click="sortVouchers('MANAGER_APPROVAL')"
                            hidden
                        >
                            MANAGER APPROVAL
                        </button>
                        <button
                            type="button"
                            class="btn btn-primary w-50 me-1"
                            @click="sortVouchers('ACCOUNTING_CODE')"
                        >
                            ACCOUNTING CODE
                        </button>
                        <button
                            type="button"
                            class="btn btn-primary w-50 me-1"
                            @click="sortVouchers('ACCOUNTING_CODE_APPROVAL')"
                        >
                            ACCOUNTING CODE APPROVAL
                        </button>
                        <button
                            type="button"
                            class="btn btn-primary w-50 me-1"
                            @click="sortVouchers('UNCLEARED_CHECKS')"
                            hidden
                        >
                            UNCLEARED CHECKS
                        </button>
                    </div>
                    <div class="d-flex flex-row mb-1">
                        <button
                            type="button"
                            class="btn btn-primary w-50 me-1"
                            @click="sortVouchers('SCANS')"
                            hidden
                        >
                            SCANS
                        </button>
                    </div>
                    <section>
                        <div class="d-flex flex-row my-3">
                            <label class="me-1" for="COMPANY_CODE"
                                >COMPANY:
                            </label>
                            <select
                                name="COMPANY_CODE"
                                class="form-select form-select-sm"
                                v-model="company"
                            >
                                <option value="ALL" selected>All</option>
                                <option v-for="company in companies" :value="company.COMPANY_CODE">{{ company.COMPANY_DESCRIPTION }}</option>
                            </select>
                        </div>
                        <div class="mt-auto">
                            <input
                                type="text"
                                class="form-control"
                                v-model="searchWord"
                                aria-describedby="emailHelp"
                                placeholder="Enter Search"
                            />
                        </div>
                        <div class="d-flex flex-row mt-auto">
                            <button
                                type="button"
                                class="btn btn-primary w-25 me-1"
                                @click="search('description')"
                            >
                                SEARCH DESC
                            </button>
                            <button
                                type="button"
                                class="btn btn-primary w-25 me-1"
                                @click="search('amount')"
                            >
                                SEARCH AMT
                            </button>
                            <button
                                type="button"
                                class="btn btn-primary w-25 me-1"
                                @click="search('id')"
                            >
                                SEARCH ID
                            </button>
                            <button
                                type="button"
                                class="btn btn-primary w-25 me-1"
                                @click="fixNoEntry()"
                            >
                                FIX NO ENTRY
                            </button>
                        </div>
                    </section>
                </div>
            </section>
            <section class="grid-section d-flex flex-row p-3 w-25">
                <div class="d-flex flex-column w-25 m-auto flex-grow-1">
                    <button class="btn btn-primary w-100 mb-1"
                            @click="_ => { isUploadVoucherOpen = true; }">UPLOAD VOUCHERS</button>
                    <button class="btn btn-primary w-100 mb-1"
                            @click="_ => { isUploadGovtVouchersOpen = true; }">UPLOAD GOV'T VOUCHERS</button>
                    <button type="button" class="btn btn-primary w-100 mb-1"
                        @click="_ => { isVoucherImagesPopupOpen = true; }" :disabled="!selectedVoucherId"
                    >
                        SHOW VOUCHER
                    </button>
                    <button
                        type="button"
                        class="btn btn-primary w-100 mb-1"
                        :disabled="selectedVoucherId == null"
                        @click="printCashVoucher()"
                    >
                        PRINT CASH VOUCHER
                    </button>
                    <button
                        type="button"
                        class="btn btn-primary w-100 mb-1"
                        :disabled="selectedVoucherId == null"
                        @click="printCheckVoucher()"
                    >
                        PRINT CHECK VOUCHER
                    </button>
                    <button
                        type="button"
                        class="btn btn-primary w-100 mb-1"
                        :disabled="selectedVoucherId == null"
                        @click="printBDOCheck()">
                        PRINT BDO CHECK
                    </button>
                    <button
                        type="button"
                        class="btn btn-primary w-100 mb-1"
                        :disabled="!selectedVoucherId"
                        @click="redirectToUpdateVoucher"
                    >
                        UPDATE VOUCHER ADMIN
                    </button>
                    <button
                        type="button"
                        class="btn btn-primary w-100 mb-1"
                        :disabled="selectedVoucherId == null"
                        data-bs-toggle="modal"
                        data-bs-target="#printVoucherModal"
                    >
                        VOUCHER RPT
                    </button>
                </div>
            </section>
        </div>
        <section class="grid-section d-flex flex-column p-3 mb-2 w-100">
            <div class="import-hours-nideka-table-wrapper mb-3">
                <table
                    class="employee-violations-table table table-sm table-bordered table-striped table-hover"
                >
                    <thead>
                        <tr>
                            <th scope="col">ID</th>
                            <th scope="col">DATE</th>
                            <th scope="col">AMT</th>
                            <th scope="col">DEBIT</th>
                            <th scope="col">CREDIT</th>
                            <th scope="col">DESCRIPTION</th>
                            <th scope="col">PAYEE_DATE</th>
                            <th scope="col">PREPARED_BY</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="obj in voucherData"
                            :key="obj.VOUCHER_ID"
                            :class="{ 'selected-row': obj.VOUCHER_ID === selectedVoucherId }"
                            @click="gl_info(obj.VOUCHER_ID)"
                        >
                            <td>{{ obj.VOUCHER_ID }}</td>
                            <td>{{ obj.VOUCHER_DATE }}</td>
                            <td>{{ formatWithComma(obj.VOUCHER_AMT) }}</td>
                            <td>{{ formatWithComma(obj.DEBIT) }}</td>
                            <td>{{ formatWithComma(obj.CREDIT) }}</td>
                            <td>({{ obj.NAME }}) {{ obj.DESCRIPTION }}</td>
                            <td>{{ obj.PAYEE_DATE }}</td>
                            <td>{{ obj.PREPARED_BY }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="d-flex flex-row">
                <div class="d-flex form-group flex-row w-50 align-items-center">
                    <label for="exampleFormControlInput1" class="text-nowrap"
                        >MANAGER RVW:
                    </label>
                    <input
                        type="text"
                        class="form-control mx-1 w-75"
                        v-model="managerApprove"
                        disabled
                    />
                    <button
                        type="button"
                        class="btn btn-primary w-25 mx-1"
                        @click="handleApproval('MANAGEMENT')"
                        :disabled="!selectedVoucherId || managerApprove"
                    >
                        APPROVE
                    </button>
                </div>
                <div class="d-flex form-group flex-row w-50 align-items-center">
                    <label for="exampleFormControlInput1" class="text-nowrap"
                        >ACCTING RVW:
                    </label>
                    <input
                        type="text"
                        class="form-control mx-1 w-75"
                        v-model="accountingApprove"
                        disabled
                    />
                    <button
                        type="button"
                        class="btn btn-primary w-25 mx-1"
                        @click="handleApproval('ACCOUNTING')"
                        :disabled="!selectedVoucherId || accountingApprove"
                    >
                        APPROVE
                    </button>
                </div>
            </div>
        </section>
        <section class="grid-section d-flex flex-column p-3 mb-2 w-100">
            <h2>GL ITEMS</h2>
            <div class="d-flex flex-row">
                <div class="d-flex flex-column w-100">
                    <div class="d-flex form-group flex-row mb-1 w-75">
                        <div class="d-flex form-group w-50">
                            <label
                                class="text-nowrap w-25 my-auto"
                            >
                                VOUCHER AMT:
                            </label>
                            <input
                                type="text"
                                class="form-control w-50 ms-2"
                                v-model="voucherAmt"
                                disabled
                            />
                        </div>
                        <div class="d-flex form-group w-50">
                            <label
                                class="text-nowrap w-25 my-auto"
                            >
                                DEBIT-CREDIT:
                            </label>
                            <input
                                type="text"
                                class="form-control w-50 ms-2"
                                v-model="glBalance.BALANCE"
                                disabled
                            />
                        </div>
                    </div>
                    <div class="d-flex form-group flex-row mb-1 w-75">
                        <div class="d-flex w-50">
                            <label
                                class="text-nowrap w-25 my-auto"
                            >
                                TOTAL DEBIT:
                            </label>
                            <input
                                type="text"
                                class="form-control w-50 ms-2"
                                v-model="glBalance.DEBIT"
                                disabled
                            />
                        </div>
                    </div>
                    <div class="d-flex form-group flex-row mb-2 w-100">
                        <div class="w-75">
                            <div class="d-flex w-50">
                                <label
                                    class="text-nowrap w-25 my-auto"
                                >
                                    TOTAL CREDIT:
                                </label>
                                <input
                                    type="text"
                                    class="form-control w-50 ms-2"
                                    v-model="glBalance.CREDIT"
                                    disabled
                                />
                            </div>
                        </div>
                        <div class="d-flex w-25">
                            <button
                                type="button"
                                class="btn btn-primary text-nowrap w-auto"
                                @click="clearCheck()"
                            >
                                CLEAR CHECK
                            </button>
                            <label
                                class="text-nowrap w-50 my-auto text-end"
                                for="CHECK_CLEAR"
                                >CHECK CLEAR:
                            </label>
                            <select
                                name="CHECK_CLEAR"
                                class="form-select w-25"
                                v-model="checkClear"
                            >
                                <option value=""></option>
                                <option value="Y">Y</option>
                                <option value="N">N</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="import-hours-nideka-table-wrapper mb-3">
                <table
                    class="employee-violations-table table table-sm table-bordered table-striped table-hover"
                >
                    <thead>
                        <tr>
                            <th scope="col">SEQ</th>
                            <th scope="col">GL_ID</th>
                            <th scope="col">CLASS</th>
                            <th scope="col">ACCTNAME</th>
                            <th scope="col">DEBIT</th>
                            <th scope="col">CREDIT</th>
                            <th scope="col">JOBOR</th>
                            <th scope="col">JONO</th>
                            <th scope="col">STYLE</th>
                            <th scope="col">PAYEE</th>
                            <th scope="col">GL_PAY</th>
                            <th scope="col">PAYEE_NAME</th>
                            <th scope="col">CHECK</th>
                            <th scope="col">TAXRA</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="obj in glData"
                            :key="obj.GL_SEQ_NO"
                            :class="{ 'selected-row': obj.GL_SEQ_NO === selectedSeqNo }"
                            @click="gl_specifics(obj.GL_SEQ_NO)"
                        >
                            <td>{{ obj.GL_SEQ_NO }}</td>
                            <td>{{ obj.GL_ID }}</td>
                            <td>{{ obj.CLASS }}</td>
                            <td>{{ obj.ACCTNAME }}</td>
                            <td>{{ formatWithComma(obj.DEBIT) }}</td>
                            <td>{{ formatWithComma(obj.CREDIT) }}</td>
                            <td>{{ obj.JOBORDER_ID }}</td>
                            <td>{{ obj.JONO }}</td>
                            <td>{{ obj.STYLE }}</td>
                            <td>{{ obj.GL_PAY_TYPE }}</td>
                            <td>{{ obj.GL_PAY_ID }}</td>
                            <td>{{ obj.PAYEE_NAME }}</td>
                            <td>{{ obj.CHECK_CLEAR }}</td>
                            <td>{{ obj.TAXRATE }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="d-flex flex-row row">
                <div class="col-5 d-flex flex-row flex-grow-1 mb-1">
                    <div class="d-flex w-75">
                        <select
                            class="form-select ms-1 w-50"
                            v-model="className"
                        >
                            <option v-for="item in glAcctnameClass" :value="item">{{ item }}</option>
                        </select>
                        <select
                            class="form-select ms-1 w-50"
                            v-model="acctname"
                            @change="glAccountSelection()"
                        >
                            <option value=""></option>
                            <option v-for="item in glAcctname" :value="item.ACCTNAME">{{ item.ACCTNAME }}</option>
                        </select>
                        <input
                            type="text"
                            class="form-control w-25 ms-1"
                            v-model="glId"
                        />
                    </div>
                    <select
                        class="form-select ms-1 w-25"
                        v-model="debitCredit"
                    >
                        <option value="debit">DEBIT</option>
                        <option value="credit">CREDIT</option>
                    </select>
                </div>
                <div class="col-7 d-flex mb-1 row">
                    <div class="col-7 d-flex">
                        <div class="d-flex w-75">
                            <input
                                type="text"
                                class="form-control w-50 ms-1"
                                v-model="debitCreditAmt"
                            />
                            <select
                                class="form-select w-50 ms-1"
                                v-model="taxrate"
                                @change="handleTaxrate()"
                            >
                                <option value="0">NO VAT</option>
                                <option value="10">10% VAT</option>
                                <option value="11">11% VAT</option>
                                <option value="12">12% VAT</option>
                            </select>
                        </div>
                        <p class="mb-0 w-25 p-2">{{ taxrateText.toFixed(2) }}</p>
                    </div>
                    <div class="col-5 d-flex">
                        <button
                            type="button"
                            class="btn btn-primary ms-1 w-50"
                            :disabled="!selectedSeqNo"
                            @click="deleteGLEntry()"
                        >
                            DELETE
                        </button>
                        <button v-if="!selectedSeqNo" type="button"
                            class="btn btn-primary ms-1 w-50" @click="insertGlEntry('insert')"
                            :disabled="!selectedVoucherId || !glId || !debitCredit">INSERT</button>
                        <button v-if="selectedSeqNo" type="button"
                            class="btn btn-primary ms-1 w-50" @click="insertGlEntry('update')"
                            :disabled="!selectedVoucherId || !glId || !debitCredit">UPDATE</button>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-row justify-content-between my-1">
                <div class="d-flex flex-column w-25">
                    <div class="d-flex mb-1">
                        <input
                            type="text"
                            class="form-control ms-1 w-25"
                            v-model="jobOrder"
                        />
                        <label
                            for="exampleFormControlInput1"
                            class="text-nowrap w-25 my-auto ms-1"
                            >{{ jono }}
                        </label>
                        <label
                            for="exampleFormControlInput1"
                            class="text-nowrap w-25 my-auto ms-1"
                            >{{ style }}
                        </label>
                    </div>
                    <div class="d-flex">
                        <input
                            type="text"
                            class="form-control ms-1 w-25"
                            v-model="glPay"
                        />
                        <label
                            for="exampleFormControlInput1"
                            class="text-nowrap ms-1 w-25 my-auto"
                        >{{ payeeName }}
                        </label>
                    </div>
                </div>
                <div class="d-flex flex-column w-75">
                    <div class="d-flex flex-row mb-1">
                        <div class="d-flex flex-row w-50">
                            <button
                                type="button"
                                class="btn btn-primary text-nowrap mx-1"
                                @click="_ => { isSelectJobOrderOpen = true; }"
                            >
                                SEARCH JOBORDER
                            </button>
                            <input
                                type="text"
                                class="form-control w-25"
                                placeholder=""
                                v-model="checkBank"
                            />
                            <label
                                for="exampleFormControlInput1"
                                class="text-nowrap w-25 m-auto"
                                >{{ bankName }}
                            </label>
                        </div>
                        <div class="d-flex flex-row w-50">
                            <button
                                type="button"
                                class="btn btn-primary text-nowrap mx-1"
                                @click="openSelectAccount('CHECK')"
                            >
                                SEARCH BANK
                            </button>
                            <label
                                for="exampleFormControlInput1"
                                class="text-nowrap my-auto mx-1"
                                >CHECK #
                            </label>
                            <input
                                type="text"
                                class="form-control"
                                placeholder=""
                                v-model="checkNumber"
                            />
                        </div>
                    </div>
                    <div class="d-flex flex-row row">
                        <div class="col-5 d-flex">
                            <div class="dropdownContainer position-relative w-50 mb-1 ms-1" ref="dropdownContainer">
                                <button
                                    @click="isListVisible = !isListVisible"
                                    class="dropdown-text btn btn-primary w-100 d-flex justify-content-between"
                                >
                                    <span>{{ payeeTypeText }}</span>
                                    <span><i class="fa-solid fa-angle-down"></i></span>
                                </button>
                                <ul v-if="isListVisible" class="dropdown-list position-absolute top-100 start-0 list-group w-100">
                                    <li @click="searchPayee('I')" class="dropdown list-group-item list-group-item-action">INTERNAL</li>
                                    <li @click="searchPayee('E')" class="dropdown list-group-item list-group-item-action">EXTERNAL</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-7 d-flex">
                            <label
                                for="exampleFormControlInput1"
                                class="text-nowrap my-auto ms-4"
                                >INVOICE NUM:
                            </label>
                            <input
                                type="text"
                                class="form-control ms-1 w-50"
                                placeholder=""
                                v-model="invoiceNumber"
                            />
                            <label
                                for="exampleFormControlInput1"
                                class="text-nowrap my-auto ms-4"
                                >PAYEE DATE:
                            </label>
                            <input
                                type="date"
                                class="form-control ms-1 w-50"
                                placeholder=""
                                v-model="payeeDate"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <SelectAccount
            :isOpen="isSelectAccountOpen"
            :onClose="_ => { isSelectAccountOpen = false; }"
            :onSelectAccount="handleSelectAccount"
        />
        <SelectEmployee
            :isOpen="isSelectEmployeeOpen"
            :onClose="_ => { isSelectEmployeeOpen = false; }"
            :onSelectEmployee="handleSelectEmployee"
        />
        <SelectJobOrder
            :is-open="isSelectJobOrderOpen"
            @select="handleSelectJobOrder"
            @close="_ => { isSelectJobOrderOpen = false; }"
        />
        <PrintModal
            :pdf-link="printVoucherPDFLink"
            :spreadsheet-link="printVoucherSpreadsheetLink"
            title="Print Voucher" id="printVoucherModal"
        />
        <UploadVoucher :is-open="isUploadVoucherOpen"
            @close="_ => { isUploadVoucherOpen = false; }" />
        <UploadGovtVouchersPopup :is-open="isUploadGovtVouchersOpen"
            @close="_ => { isUploadGovtVouchersOpen = false; }"/>
        <VoucherImagesPopup :voucher-id="selectedVoucherId"
            :is-open="isVoucherImagesPopupOpen"
            @close="_ => { isVoucherImagesPopupOpen = false }" />
    </div>
</template>

<script setup>
    import { ref, onMounted, watch, computed, onBeforeUnmount } from "vue";
    import numberToWords from 'number-to-words';

    import SelectAccount from '@/components/utils/SelectAccount.vue';
    import SelectEmployee from '@/components/utils/SelectEmployee.vue';
    import SelectJobOrder from '@/components/utils/SelectJobOrder.vue';
    import PrintModal from '@/components/utils/PrintModal.vue'
    import UploadVoucher from "@/components/vouchers/popups/UploadVoucher.vue";
    import UploadGovtVouchersPopup from "../popups/UploadGovtVouchersPopup.vue";
    import VoucherImagesPopup from "@/components/utils/popups/VoucherImagesPopup.vue";

    const openMessageBox = ref(false);
    const titleMessageBox = ref('');
    const voucherData = ref([]);
    const glData = ref([]);
    const startDate = ref("");
    const endDate = ref("");
    const companies = ref([]);
    const company = ref("ALL");
    const searchWord = ref("");
    const managerApprove = ref(null);
    const accountingApprove = ref(null);
    const voucherAmt = ref(0);
    const className = ref("");
    const acctname = ref("");
    const debitCreditAmt = ref('');
    const debitCredit = ref('debit');
    const taxrate = ref(0);
    const taxrateText = ref(0);
    const jobOrder = ref("");
    const jono = ref('NONE');
    const style = ref('NONE');
    const glPay = ref('');
    const glId = ref('');
    const payeeName = ref('NONE');
    const payeeType = ref('');
    const payeeTypeText = ref('');
    const checkNumber = ref(null);
    const checkBank = ref(null);
    const checkClear = ref('');
    const bankName = ref('NONE');
    const invoiceNumber = ref('');
    const payeeDate = ref(null);
    const selectedVoucherId = ref(null);
    const selectedSeqNo = ref(null);
    const username = ref('');
    const glAcctnameClass= ref([]);
    const glAcctname= ref([]);
    const isSelectAccountOpen = ref(false);
    const isSelectEmployeeOpen = ref(false);
    const isSelectJobOrderOpen = ref(false);
    const selectedPayeeType = ref('');
    const isLoading = ref(false);
    const isToastSuccess = ref(false);
    const isToastError = ref(false);
    const toastMessage = ref("");
    const isListVisible = ref(false);
    const glBalance = ref({
        DEBIT: 0,
        CREDIT: 0,
        BALANCE: 0
    })
    const isUploadVoucherOpen = ref(false);
    const isUploadGovtVouchersOpen = ref(false);
    const isVoucherImagesPopupOpen = ref(false);

    const closeList = (event) => {
      const dropdownContainer = document.querySelector('.dropdownContainer');
      if (dropdownContainer && !dropdownContainer.contains(event.target)) {
        isListVisible.value = false;
      }
    };

    onMounted(() => {
      document.addEventListener('click', closeList);
    });

    onBeforeUnmount(() => {
      document.removeEventListener('click', closeList);
    });

    onMounted(async () => {
        try {
            username.value = "/" + document.querySelector('#update-voucher').getAttribute('data-username');
            // Initial values for startDate and endDate
            const today = new Date();
            endDate.value = formatDate(today);
            startDate.value = formatDate(getDateDaysAgo(today, 10));
            const id = document.querySelector('#update-voucher').getAttribute('data-item');
            if(id == '')
                getVoucherList();
            else
                getVoucher(id);
            getCompanies();
            getGlClass();
            getGlName();
        } catch (error) {
            isToastError.value = true;
            toastMessage.value = error.response.data.message;
        }
    })

    watch(className, () => {
        getGlName();
    })

    watch(taxrate, () => {
        handleTaxrate();
    })

    async function getCompanies() {
        try {
            const response = await axios.get(route('api.companies.index'));
            companies.value = response.data.data;
        } catch (error) {
            isToastError.value = true;
            toastMessage.value = error.response.data.message;
        }
    }
    async function getVoucherList() {
        isLoading.value = true;
        try {
            const response = await axios.post(route('api.voucher.filter'), {
                START_DATE: startDate.value,
                END_DATE: endDate.value,
                COMPANY_CODE: company.value,
            });
            voucherData.value = response.data;
            isLoading.value = false;
        } catch (error) {
            isToastError.value = true;
            toastMessage.value = error.response.data.message;
            isLoading.value = false;
        }
    }

    async function getVoucher(id) {
        isLoading.value = true;
        try {
            const response = await axios.post(route('api.voucher.filter'), {
                VOUCHER_ID: id,
            });
            voucherData.value = response.data;
            isLoading.value = false;
        } catch (error) {
            isToastError.value = true;
            toastMessage.value = error.response.data.message;
            isLoading.value = false;
        }
    }

    async function getGlClass() {
        try {
            const response = await axios.post(route('api.gl.filter'), {
                class: true
            });
            glAcctnameClass.value = response.data[0];
        } catch (error) {
            isToastError.value = true;
            toastMessage.value = error.response.data.message;
        }
    }

    async function getGlName() {
        try {
            const response = await axios.post(route('api.gl.filter'), {
                search: className.value !== "" ? className.value : 'empty',
            });
            glAcctname.value = response.data[0];
        } catch (error) {
            isToastError.value = true;
            toastMessage.value = error.response.data.message;
        }
    }

    async function search(type) {
        if (!searchWord.value) {
            getVoucherList();
            clearVoucherSelection();
            return;
        }

        try {
            const response = await axios.post(route('api.voucher.filter'), {
                SEARCH_WORD: searchWord.value,
                SEARCH_TYPE: type,
                COMPANY_CODE: company.value,
            });
            voucherData.value = response.data;
            clearVoucherSelection();
        } catch (error) {
            isToastError.value = true;
            toastMessage.value = error.response.data.message;
        }
    }

    async function sortVouchers(type) {
        isLoading.value = true;
        try {
            const response = await axios.post(route('api.voucher.filter'), {
                SORT: type,
                COMPANY_CODE: company.value,
            });
            isLoading.value = false;
            voucherData.value = response.data.reverse();
            clearVoucherSelection();
        } catch (error) {
            isLoading.value = false;
            isToastError.value = true;
            toastMessage.value = error.response.data.message;
        }
    }

    async function fixNoEntry() {
        isLoading.value = true;
        try {
            const response = await axios.post(route('api.vouchergl.fixNoEntry'), {
                USER: username.value
            });
            isLoading.value = false;
            if (response.status === 200 || response.status === 201) {
                getVoucherList();
                isToastSuccess.value = true;
                toastMessage.value = response.data[0];
            }
        } catch (error) {
            isLoading.value = false;
            isToastError.value = true;
            toastMessage.value = error.response.data.message;
        }
    }

    function handleApproval(type) {
        titleMessageBox.value = type;

        if (type == 'MANAGEMENT') {
            setOpenMessageBox();
            return;
        }

        handleAnswer();
    }

    function setOpenMessageBox () {
        openMessageBox.value = true;
    }

    function setCloseMessageBox () {
        openMessageBox.value = false;
    }

    async function handleAnswer () {
        if (titleMessageBox.value == 'MANAGEMENT') {
            try {
                const response = await axios.post(route('api.voucher-manager-approval.store'), {
                    VOUCHER_ID: selectedVoucherId.value,
                    APPROVED_BY: username.value,
                });
                if (response.status === 200 || response.status === 201) {
                    managerApprove.value = response.data.data.APPROVED_BY;
                    isToastSuccess.value = true;
                    toastMessage.value = "VOUCHER has been approved by MANAGEMENT.";
                }
            } catch (error) {
                isToastError.value = true;
                toastMessage.value = error.response.data.message;
            }
            setCloseMessageBox();
        } else {
            try {
                const response = await axios.post(route('api.voucher-accounting-approval.store'), {
                    VOUCHER_ID: selectedVoucherId.value,
                    APPROVED_BY: username.value,
                });
                if (response.status === 200 || response.status === 201) {
                    accountingApprove.value = response.data.data.APPROVED_BY;
                    isToastSuccess.value = true;
                    toastMessage.value = "VOUCHER has been approved by ACCOUNTING supervisor.";
                }
            } catch (error) {
                isToastError.value = true;
                toastMessage.value = error.response.data.message;
            }
            setCloseMessageBox();
        }
    }

    function formatDate(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
    }

    function getDateDaysAgo(date, days) {
        const result = new Date(date);
        result.setDate(date.getDate() - days);
        return result;
    }

    async function gl_info(id) {
        try {
            const response = await axios.get(route('api.voucher-manager-approval.show', { id: id }))
            managerApprove.value = response.data[0].APPROVED_BY;
        } catch (error) {
            isToastError.value = true;
            console.log('Manager', error)
            toastMessage.value = error.response.data.message;
        }

        try {
            const response = await axios.get(route('api.voucher-accounting-approval.show', { id: id }))
            accountingApprove.value = response.data[0].APPROVED_BY;
        } catch (error) {
            console.log('Accounting', error)
            isToastError.value = true;
            toastMessage.value = error.response.data.message;
        }

        try {
            checkClear.value = 'N';
            selectedVoucherId.value = id;
            const foundVoucher = voucherData.value.find(voucher => voucher.VOUCHER_ID === id);
            voucherAmt.value = formatWithComma(foundVoucher.VOUCHER_AMT);
            clearGLFields();
        } catch (error) {
            isToastError.value = true;
            toastMessage.value = error.response.data.message;
        }

        try {
            isLoading.value = true;
            const response = await axios.post(route('api.voucher.gl'), {
                VOUCHER_ID: id,
            });
            glData.value = response.data.info;
            glBalance.value = {
                DEBIT: formatWithComma(response.data.totalDebit),
                CREDIT: formatWithComma(response.data.totalCredit),
                BALANCE: formatWithComma(response.data.balance)
            }
            updateVoucherAmountTotal(response.data);
            isLoading.value = false;
        } catch (error) {
            isLoading.value = false;
            isToastError.value = true;
            toastMessage.value = error.response.data.message;
        }
    }

    async function gl_specifics(id) {
        selectedSeqNo.value = id;
        glData.value.forEach((element) => {
            if (id == element.GL_SEQ_NO) {
                if (element.DEBIT > 0) {
                    debitCredit.value = 'debit';
                    debitCreditAmt.value = formatWithComma(element.DEBIT);
                }
                if(element.CREDIT > 0) {
                    debitCredit.value = 'credit';
                    debitCreditAmt.value = formatWithComma(element.CREDIT);
                }
                jono.value = element.JONO;
                glId.value = element.GL_ID;
                style.value = element.STYLE;
                taxrate.value = element.TAXRATE;
                glPay.value = element.GL_PAY_ID;
                className.value = element.CLASS;
                acctname.value = element.ACCTNAME;
                bankName.value = element.BANK_NAME;
                jobOrder.value = element.JOBORDER_ID;
                payeeName.value = element.PAYEE_NAME;
                payeeDate.value = element.PAYEE_DATE;
                checkBank.value = element.CHECK_BANK;
                payeeType.value = element.GL_PAY_TYPE;
                payeeTypeText.value = element.GL_PAY_TYPE === 'E' ? 'EXTERNAL' : 'INTERNAL';
                checkNumber.value = element.CHECK_NUM === null ? "" : element.CHECK_NUM;
                invoiceNumber.value = element.INVOICE_NUM;
                checkClear.value = element.CHECK_CLEAR;
                handleTaxrate();
            }
        });
    }

    function printCashVoucher() {
        if (selectedVoucherId.value == null) {
            isToastError.value = true;
            toastMessage.value = "No VOUCHER selected.  Nothing to print.";
            return;
        }

        window.open(route('print.cashVoucher', {
            VOUCHER_ID: selectedVoucherId.value,
        }), '_blank');
    }

    async function printCheckVoucher() {
        if (selectedVoucherId.value == null) {
            isToastError.value = true;
            toastMessage.value = "No VOUCHER selected.  Nothing to print.";
            return;
        }

        window.open(route('print.checkVoucher', {
            VOUCHER_ID: selectedVoucherId.value,
        }), '_blank');
    }

    function printBDOCheck() {
        const vouchertype = selectedVoucherId.value.substring(2,4);
        if (selectedVoucherId.value == null) {
            isToastError.value = true;
            toastMessage.value = "No VOUCHER selected.  Nothing to print.";
        } else if (vouchertype !== 'CK') {
            isToastError.value = true;
            toastMessage.value = "Only CHECK vouchers can be printed.";
        } else {
            //Format the amount into text
            const foundVoucher = voucherData.value.find(voucher => voucher.VOUCHER_ID === selectedVoucherId.value);
            const formattedAmount = formatAmountInWords(foundVoucher.VOUCHER_AMT);
            //Generate the PDF
            window.open(route('print.bdoCheck', {
                VOUCHER_ID: selectedVoucherId.value,
                AMT: formattedAmount,
            }), '_blank');
        }
    }

    const formatAmountInWords = (amount) => {
        try {
            const [wholePart, decimalPart] = amount.split('.');
            const wholeWords = numberToWords.toWords(parseInt(wholePart));
            const capitalizedWords = `${wholeWords.charAt(0).toUpperCase()}${wholeWords.slice(1)}`;

            return `${capitalizedWords} and ${decimalPart == undefined || decimalPart == "" ? 0 : decimalPart}/100 pesos only`;
        } catch (error) {
            return '0.00';
        }
    };

    const printVoucherPDFLink = computed(() => {
        if(!selectedVoucherId.value)
            return;

        return route('print.finVoucherAllGeneric2', {
            VOUCHER_ID: selectedVoucherId.value
        });
    });

    const printVoucherSpreadsheetLink = computed(() => {
        if(!selectedVoucherId.value)
            return;

        return route('api.vouchers.voucherrpt-spreadsheet', {
            VOUCHER_ID: selectedVoucherId.value
        });
    });

    async function clearCheck() {
        try {
            if (selectedVoucherId.value == null) {
                isToastError.value = true;
                toastMessage.value = "No voucher selected.";
            } else if (selectedSeqNo.value == null) {
                isToastError.value = true;
                toastMessage.value = "No GL item selected.";
            } else {
                isLoading.value = true;
                const response = await axios.get(route('api.gl-accounts.show', { id:  glId.value }));
                isLoading.value = false;
                if (response.data[0].GL_TYPE === null && checkBank.value === 0) {
                    isToastError.value = true;
                    toastMessage.value = "No BANK_ID specified.";
                } else if (response.data[0].GL_TYPE === null && checkNumber.value === "") {
                    isToastError.value = true;
                    toastMessage.value = "No CHECK_NUM specified.";
                } else if (checkClear.value !== 'N' && checkClear.value !== 'Y') {
                    isToastError.value = true;
                    toastMessage.value = "CHECK_CLEAR must be 'Y' or 'N'.";
                } else {
                    updateCheckClear();
                }
            }
        } catch (error) {
            isLoading.value = false;
            isToastError.value = true;
            toastMessage.value = error.response.data.message;
        }
    }

    async function updateCheckClear() {
        try {
            isLoading.value = true;
            const response = await axios.patch(route('api.vouchergl.update', {id : `${selectedVoucherId.value}_${selectedSeqNo.value}` }), {
                CHECK_CLEAR: checkClear.value
            });
            isLoading.value = false;
            if (response.data.message === 'Success') {
                gl_info(selectedVoucherId.value);
                isToastSuccess.value = true;
                toastMessage.value = "GL entry, CHECK_CLEAR flag updated.";
            } else {
                isToastError.value = true;
                toastMessage.value = "Failed to update GL entry.";
            }
        } catch (error) {
            isLoading.value = false;
            isToastError.value = true;
            toastMessage.value = error.response.data.message;
        }
    }

    async function deleteGLEntry() {
        try {
            if (selectedVoucherId.value == null) {
                isToastError.value = true;
                toastMessage.value = "No voucher selected.";
            } else if (selectedSeqNo.value == null) {
                isToastError.value = true;
                toastMessage.value = "No GL item selected.";
            } else {
                isLoading.value = true;
                const response = await axios.delete(route('api.vouchergl.destroy', {id : `${selectedVoucherId.value}_${selectedSeqNo.value}` }));
                isLoading.value = false;
                if (response.data.message === 'Success') {
                    getVoucherList();
                    gl_info(selectedVoucherId.value);
                    isToastSuccess.value = true;
                    toastMessage.value = "GL entry deleted.";
                } else {
                    isToastError.value = true;
                    toastMessage.value = "Failed to delete GL entry.";
                }
            }
        } catch (error) {
            isLoading.value = false;
            isToastError.value = true;
            toastMessage.value = error.response.data.message;
        }
    }

    async function insertGlEntry(type) {
        try {
            const maxSeq = glData.value[glData.value.length - 1].GL_SEQ_NO + 1;
            const formDataGL = {
                VOUCHER_ID: selectedVoucherId.value,
                GL_SEQ_NO: maxSeq,
                GL_ID: glId.value,
                GL_AMT: debitCredit.value === 'debit' ? parseFloat(formatWithoutComma(debitCreditAmt.value)).toFixed(2) : parseFloat(-formatWithoutComma(debitCreditAmt.value)).toFixed(2),
                CREATED_BY: username.value,
                JOBORDER_ID: jobOrder.value,
                GL_PAY_TYPE: payeeType.value,
                GL_PAY_ID: glPay.value,
                PAYEE_DATE: payeeDate.value ? payeeDate.value : glData.value[glData.value.length - 1].PAYEE_DATE,
                CHECK_BANK: checkBank.value ? checkBank.value : 0,
                CHECK_NUM: checkNumber.value,
                CHECK_CLEAR: checkClear.value,
                TAXRATE: taxrate.value,
                LAST_UPDATED_BY: username.value,
                INVOICE_NUM: invoiceNumber.value,
            }
            if (type === 'insert') {
                isLoading.value = true;
                const response = await axios.post(route('api.vouchergl.store'), formDataGL);
                isLoading.value = false;
                gl_info(selectedVoucherId.value);
                clearGLFields();
                isToastSuccess.value = true;
                toastMessage.value = 'GL entry added.';
            } else {
                isLoading.value = true;
                const response = await axios.patch(route('api.vouchergl.update', {id : `${selectedVoucherId.value}_${selectedSeqNo.value}` }), {
                    GL_ID: glId.value,
                    GL_AMT: debitCredit.value === 'debit' ? parseFloat(formatWithoutComma(debitCreditAmt.value)).toFixed(2) : parseFloat(-formatWithoutComma(debitCreditAmt.value)).toFixed(2),
                    JOBORDER_ID: jobOrder.value,
                    GL_PAY_TYPE: payeeType.value,
                    GL_PAY_ID: glPay.value,
                    PAYEE_DATE: payeeDate.value,
                    CHECK_BANK: checkBank.value ? checkBank.value : 0,
                    CHECK_NUM: checkNumber.value,
                    CHECK_CLEAR: checkClear.value,
                    TAXRATE: taxrate.value,
                    LAST_UPDATED_BY: username.value,
                    INVOICE_NUM: invoiceNumber.value,
                });
                isLoading.value = false;
                gl_info(selectedVoucherId.value);
                isToastSuccess.value = true;
                toastMessage.value = "GL entry, CHECK_CLEAR flag updated.";
            }
        } catch (error) {
            isLoading.value = false;
            isToastError.value = true;
            toastMessage.value = error.response.data.message;
        }
    }

    function updateVoucherAmountTotal(data) {
        let voucher = voucherData.value.find(voucher => voucher.VOUCHER_ID === selectedVoucherId.value);
        if (voucher) {
            voucher.DEBIT = parseFloat(data.totalDebit);
            voucher.CREDIT = parseFloat(data.totalCredit);
        }
    }

    function glAccountSelection() {
        const foundGLAccount = glAcctname.value.find(gl => gl.ACCTNAME === acctname.value);
        glId.value = foundGLAccount.GL_ID;
    }

    function handleTaxrate() {
        if(taxrate.value != 0) {
            taxrateText.value = (formatWithoutComma(debitCreditAmt.value) / 1.12) * (taxrate.value / 100);
            return;
        }
        taxrateText.value = 0;
    }

    function openSelectAccount(type) {
        selectedPayeeType.value = type;
        isSelectAccountOpen.value = true;
    }

    function handleSelectAccount(selectedAccount) {
        if (selectedPayeeType.value == 'CHECK') {
            checkBank.value = selectedAccount.ACCOUNT_ID;
            bankName.value = selectedAccount.ACCOUNT_NAME;
        } else if (selectedPayeeType.value == 'PAYEE') {
            payeeType.value = 'E';
            payeeTypeText.value = 'EXTERNAL';
            glPay.value = selectedAccount.ACCOUNT_ID;
            payeeName.value = selectedAccount.ACCOUNT_NAME;
        }
    }

    function handleSelectEmployee(selectedEmployee) {
        payeeType.value = 'I';
        payeeTypeText.value = 'INTERNAL';
        glPay.value = selectedEmployee.EMPID;
        payeeName.value = selectedEmployee.FNAME + " " + selectedEmployee.LNAME
    };

    function handleSelectJobOrder(selectedJobOrder) {
        jono.value = selectedJobOrder.JONO;
        style.value = selectedJobOrder.STYLE;
        jobOrder.value = selectedJobOrder.JOBORDER_ID;
        isSelectJobOrderOpen.value = false;
    };

    function searchPayee(type) {
        isListVisible.value = false;
        switch (type) {
            case 'E':
                openSelectAccount('PAYEE');
                break;
            case 'I':
                isSelectEmployeeOpen.value = true;
                break;
            case '':
                isToastError.value = true;
                toastMessage.value = 'Please select a payee type!'
            default:
                break;
        }
    }

    function formatWithComma(value){
        return Number(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    }

    function formatWithoutComma(value) {
        const numericValue = Number(value.replace(/,/g, ''));
        return numericValue.toFixed(2);
    }

    function hideToast() {
        isToastError.value = false;
        isToastSuccess.value = false;
    }

    function clearVoucherSelection() {
        clearGLFields();
        glData.value = [];
        voucherAmt.value = '';
        managerApprove.value = null;
        accountingApprove.value = null;
        selectedVoucherId.value = null;
        glBalance.value = {
            CREDIT: 0,
            DEBIT: 0,
            BALANCE: 0,
        }
    }

    function clearGLFields() {
        glId.value = '';
        glPay.value = 0;
        taxrate.value = 0;
        jobOrder.value = 0;
        acctname.value = '';
        jono.value = 'NONE';
        checkBank.value = null;
        className.value = '';
        payeeType.value = '';
        payeeTypeText.value = '';
        payeeDate.value = null;
        style.value = 'NONE';
        checkNumber.value = null;
        debitCredit.value = 'debit';
        bankName.value = 'NONE';
        payeeName.value = 'NONE';
        invoiceNumber.value = '';
        debitCreditAmt.value = '0.00';
        selectedSeqNo.value = null;
    }

    function redirectToUpdateVoucher() {
        window.open(route('voucher.update-voucher-admin', {
            VOUCHER_ID: selectedVoucherId.value,
        }));
    }
</script>
<style scoped>
section {
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: #f9fafb;
    border: 2px solid #d1d5db;
}

.horizontal-form-control-4-container {
    display: grid;
    column-gap: 1rem;
    grid-template-columns: repeat(4, 1fr);
}

.employee-maintenance-main-card {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    column-gap: 0.5rem;
}

.import-hours-nideka-table-wrapper {
    width: 100%;
    height: 20rem;
    overflow-y: auto;
    background-color: #FFF;
}

.select-employee-table {
    cursor: pointer;
}

.selected-row {
    background-color: lightslategrey;
}

.select-employee-table-wrapper {
    height: 16rem;
    overflow-y: auto;
}

.table-striped tbody:nth-child(even) {
    background-color: white;
}

thead {
    top: 0;
    margin: 0;
    position: sticky;
    background-color: white;
    backdrop-filter: blur(100px);
}

th {
    backdrop-filter: blur(100px);
}

td {
    max-width: 30vw;
}

td:hover {
    cursor: pointer;
}

.dropdown {
    cursor: pointer;
    padding: 3px;
}

.dropdown:hover {
    background-color: #0d6efd;
    color: white;
}

.toast-shadow {
    padding: 3;
    z-index: 11;
    background: rgb(0, 0, 0, 0.5);
}
.dropdown-list {
    z-index: 1;
}

.dropdown-text {
    background-color: #F9FAFB;
    border: 1px solid #CED4DA;
    color: black;
}
</style>
