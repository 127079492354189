import { ref } from 'vue';

import constructFetchError from "./constructFetchError";

import axios from 'axios';

export function useReconMasterlists() {
    const reconMasterlistGenerations = ref(null);
    const totalRecords = ref(null);

    const getReconMasterlistGenerations = async (params) => {
        try {
            const getReconMasterlistGenerationsResponse = await axios.get(route('api.recon-masterlist-generations.index', params));
            reconMasterlistGenerations.value = getReconMasterlistGenerationsResponse.data.data;
            totalRecords.value = getReconMasterlistGenerationsResponse.data.meta.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching recon masterlist generations.');
        }
    };

    const postReconMasterlistGeneration = async (timePeriod) => {
        try {
            const postReconMasterlistGenerationResponse = await axios.post(
                route('api.time-periods.recon-masterlist-generations.store', {
                    time_period: timePeriod
                }));
            return postReconMasterlistGenerationResponse;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while generating recon masterlist.');
        }
    };
    
    return { postReconMasterlistGeneration,
        reconMasterlistGenerations, totalRecords, getReconMasterlistGenerations};
}
