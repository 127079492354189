<template>
    <Card :class="class">
        <template #header>
            2. RECON_RAW_FULL (in store, in INV_CHECK, OK)
        </template>
        <DataTable :loading="isTableLoading" lazy :value="reconRawFullsInStore" :total-records="reconRawFullsInStoreTotalRecords"
            paginator :rows="10" @page="e => { onPage(e); fetchInStore(); }"
            v-model:filters="filters" filter-display="row" @filter="tableOnFilter"
            sort-mode="multiple" @sort="e => { onSort(e); fetchInStore(); }"
            v-model:selection="selectedRecord" selection-mode="single"
            :pt="{ table: { class: 'table table-sm table-bordered table-hover recon-table' } }">
            <Column field="STYLE" header="Style" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="DATE" header="Date" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="SERIAL" header="Serial" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="COLOR" header="Color" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="SEQ_NO" header="Seq" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="EVENT_FLAG" header="EVNT" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="ADD_FLAG" header="ADDF" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="TYPE_FLAG" header="TYPE" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <template #empty>
                <div class="text-center">
                    No data.
                </div>
            </template>
        </DataTable>
    </Card>
</template>

<script setup>
import { ref, watch } from 'vue';

import Card from '@/components/utils/Card.vue';

import { useFlashMessages } from '@/composables/flashmessages';
import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useReconRawFulls } from '@/composables/data/reconRawFulls';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

import scrollToTop from '@/utils/scrollToTop';

const props = defineProps({
    location: String,
    timePeriod: Number,
    inventoryDone: String,
    style: String,
    refreshFlag: Boolean,
    class: String,
});

const { addFlashMessage } = useFlashMessages();

const { params, filters, onPage, onFilter, onSort } = useDataTableParams(
    ['STYLE', 'DATE', 'SERIAL', 'COLOR', 'SEQ_NO', 'EVENT_FLAG', 'ADD_FLAG', 'TYPE_FLAG']);

const { reconRawFullsInStore, reconRawFullsInStoreTotalRecords, getReconRawFullsInStore } = useReconRawFulls();

const isTableLoading = ref(false);

const fetchTimeout = ref(null);

const selectedRecord = ref(null);

const selectedBagIdentifier = defineModel('selectedBagIdentifier');

async function fetchInStore() {
    reconRawFullsInStore.value = null;

    if(!props.location || !props.timePeriod || !props.inventoryDone || !props.style)
        return;

    isTableLoading.value = true;
    try {
        await getReconRawFullsInStore(props.timePeriod, props.location, props.inventoryDone,
            props.style, params.value);
    } catch(e) {
        addFlashMessage('ERROR', e.message);
        scrollToTop();
    }
    isTableLoading.value = false;
}

async function tableOnFilter(e) {
    onFilter(e);
    if(fetchTimeout.value)
        clearTimeout(fetchTimeout.value);
    fetchTimeout.value = setTimeout(fetchInStore, 300);
}

watch(selectedRecord, () => {
    if(!selectedRecord.value)
        return;

    selectedBagIdentifier.value = {
        STYLE: selectedRecord.value.STYLE,
        DATE: selectedRecord.value.DATE,
        SERIAL: selectedRecord.value.SERIAL,
        SEQ_NO: selectedRecord.value.SEQ_NO,
        NOBC_SOURCE: 'LOCATION',
    };
});

watch(() => props.style, fetchInStore);

watch(() => props.refreshFlag, fetchInStore);
</script>
