<style scoped>
.pop-up {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: rgb(0, 0, 0, 0.5);
}

.pop-up .card {
    width: 100%;
}

@media (min-width: 768px) {
    .pop-up .card {
        width: 70%;
    }
}

@media (min-width: 992px) {
    .pop-up .card {
        width: 50%;
    }
}

.image-files-container {
    max-height: 300px;
    overflow-y: auto;
    border: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 15px;
}
</style>

<template>
    <div v-if="isOpen" class="pop-up d-flex justify-content-center align-items-center">
        <Card>
            <template #header>
                <div class="d-flex justify-content-between pt-2">
                    <h4 class="title">Upload Vouchers</h4>
                    <button type="button" class="btn-close" @click="emit('close')"></button>
                </div>
            </template>
            <Card class="w-100">
                <template #header>
                    Images
                </template>
                <form @submit.prevent="imageFileOnSubmit">
                    <div v-if="imageFiles" class="image-files-container">
                        <div v-for="(file, index) in imageFiles" :key="index">
                            <p class="small">
                                {{ file.file.name }} -
                                <span
                                    v-if="file.status === 'Success'"
                                    class="badge bg-success"
                                >
                                    Success
                                </span>
                                <span
                                    v-if="file.status === 'Pending'"
                                    class="badge bg-warning"
                                >
                                    Pending
                                </span>
                                <span
                                    v-if="file.status === 'Uploading'"
                                    class="badge bg-info"
                                >
                                    Uploading
                                </span>
                                <span
                                    v-if="file.status === 'Failed'"
                                    class="badge bg-danger"
                                >
                                    Failed
                                </span>
                            </p>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="imageFileInput" class="form-label">Upload image</label>
                        <input type="file" required ref="imageFileInput"
                            accept="image/jpeg"
                            @change="imageFileOnChange"
                            id="imageFileInput" class="form-control form-control-sm" multiple />

                    </div>
                    <button type="submit" class="btn btn-primary"
                        :disabled="isUploading">
                        <span v-if="isUploading" class="spinner-border spinner-border-sm" role="status"
                            aria-hidden="true"></span>
                        Save Image
                    </button>
                </form>
            </Card>
        </Card>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';

import Card from '@/components/utils/Card.vue';

const props = defineProps({
    voucher: String,
    isOpen: Boolean,
});

const emit = defineEmits(['close']);

const imageFileInput = ref(null);
const imageFiles = ref();

const isUploading = ref(false);

watch(() => props.isOpen, () => {
    imageFiles.value = null;
    imageFileInput.value = null;
})

function imageFileOnChange(event) {
    imageFiles.value = Array.from(event.target.files).map(file => ({
        file,
        status: 'Pending'
    }));
}

async function imageFileOnSubmit() {
    isUploading.value = true;
    try {
        for (const imageFile of imageFiles.value) {
            const formData = new FormData();
            formData.append("image", imageFile.file);

            imageFile.status = 'Uploading';

            const response = await axios.post(route('api.voucher.image.store'), formData);

            if(response.data.success)
                imageFile.status = 'Success';
            else
                imageFile.status = 'Failed';
        }
    } catch(e) {
        console.log(e);
    }
    isUploading.value = false;
}
</script>
