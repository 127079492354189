<template>
    <Card :class="class">
        <template #header>
            SM SKU
        </template>
        <DataTable :loading="isLoading" :value="smSkus" table-class="table table-bordered table-hover"
            paginator :rows="10" class="mb-3"
            v-model:selection="selectedSMSku"
            selection-mode="single" data-key="id">
            <Column field="id" header="ID" />
            <Column field="COLOR" header="Color" />
            <Column field="SM_SKU" header="SM SKU" />
            <template #empty>No SM Skus found.</template>
        </DataTable>
        <form>
            <FormSelect label="Color" v-model="smSku.COLOR" :options="colors"
                is-horizontal id-prefix="color" class="mb-3"
                select-class="form-select-sm" :errors="errors.COLOR"
                @keydown-enter="focusNextInput('smSkuFormInput')" />
            <FormInput label="SM SKU" type="text" v-model="smSku.SM_SKU"
                is-horizontal id-prefix="smSku" class="mb-3"
                input-class="form-control-sm" :errors="errors.SM_SKU" />
            <button type="button" class="btn btn-primary me-1"
                :disabled="!style.STYLE || smSku.id != null || isLoading || isInserting"
                @click="insertButtonOnClick">
                <span v-if="isInserting" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Insert
            </button>
            <button type="button" class="btn btn-primary me-1"
                :disabled="!style.STYLE || smSku.id == null || isLoading || isUpdating"
                @click="updateButtonOnClick">
                <span v-if="isUpdating" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Update
            </button>
            <button type="reset" class="btn btn-outline-secondary"
                @click="clearButtonOnClick">
                Clear
            </button>
        </form>
    </Card>
</template>

<script setup>
import { ref, watch } from 'vue';

import Card from '../../../utils/Card.vue';
import FormInput from '../../../utils/FormInput.vue';
import FormSelect from '../../../utils/FormSelect.vue';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';

import focusNextInput from '../../../../utils/focusnext';
import handleFetchErrors from "../../../../utils/handleFetchErrors";
import scrollToTop from "../../../../utils/scrollToTop";

import { useFlashMessages } from "../../../../composables/flashmessages";

const { addFlashMessage } = useFlashMessages();

import axios from 'axios';

const props = defineProps({
    style: Object,
    colors: Array,
    class: String
});

const errors = ref({});

const smSkus = ref();

const selectedSMSku = ref({
    id: null,
    STYLE: null,
    COLOR: null,
    SM_SKU: null
});

const smSku = ref({});

const isLoading = ref(false);
const isInserting = ref(false);
const isUpdating = ref(false);

async function loadSMSkus() {
    isLoading.value = true;
    try {
        const smSkusResponse = await axios.get(route('api.styles.sm-skus.index', {
            style: props.style.STYLE
        }));
        smSkus.value = smSkusResponse.data.data;
    } catch(e) {
        console.log(e);
        handleFetchErrors(e, 'Error while fetching SM SKUs.');
        scrollToTop();
    }
    isLoading.value = false;
}

watch(selectedSMSku, () => {
    errors.value = {};
    smSku.value = Object.assign({}, selectedSMSku.value);
});

watch(() => props.style, async () => {
    errors.value = {};
    smSku.value = {};
    if(props.style?.STYLE == null)
        return;
    await loadSMSkus();
});

async function insertButtonOnClick() {
    errors.value = {};
    isInserting.value = true;
    try {
        const saveSMSkuResponse = await axios.post(
            route('api.styles.sm-skus.store', {
                style: props.style.STYLE
            }), smSku.value);
        smSku.value = saveSMSkuResponse.data.data;
        addFlashMessage('SUCCESS', 'Successfully created SM SKU ' +
            smSku.value.SM_SKU + '.');
        loadSMSkus();
    } catch(e) {
        console.log(e);
        errors.value = handleFetchErrors(e, 'Error while saving SM SKU.');
    }
    isInserting.value = false;
    scrollToTop();
}

async function updateButtonOnClick() {
    errors.value = {};
    isUpdating.value = true;
    try {
        const updateSMSkuResponse = await axios.put(
            route('api.styles.sm-skus.update', {
                style: props.style.STYLE,
                sm_sku: smSku.value.id
            }), smSku.value);
        smSku.value = updateSMSkuResponse.data.data;
        addFlashMessage('SUCCESS', 'Successfully updated SM SKU ' +
            smSku.value.SM_SKU + '.');
        loadSMSkus();
    } catch(e) {
        console.log(e);
        errors.value = handleFetchErrors(e, 'Error while updating SM SKUs.');
    }
    isUpdating.value = false;
    scrollToTop();
}

function clearButtonOnClick() {
    selectedSMSku.value = {
        id: null,
        STYLE: null,
        COLOR: null,
        SM_SKU: null
    };
    errors.value = {};
}
</script>
