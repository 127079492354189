<template>
    <Card :class="class">
        <template #header>
            No Barcodes
        </template>
        <DataTable :loading="isLoading" lazy :value="inventoryNoBarcodes" :total-records="inventoryNoBarcodesTotalRecords"
            paginator :rows="10" @page="e => { onPage(e); fetchInventoryNoBarcodes(); }"
            filter-display="row" v-model:filters="filters" @filter="tableOnFilter"
            @sort="e => { onSort(e); fetchInventoryNoBarcodes(); }"
            v-model:selection="selectedRecord" selection-mode="single"
            :pt="{ table: { class: tableClass } }">
            <Column field="STYLE" header="Style" sortable
                :pt="{ filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" :disabled="style != null" />
                    <Button type="button" class="btn btn-primary" @click="filterCallback()"
                        :disabled="style != null">
                        Search
                    </Button>
                </template>
            </Column>
            <Column field="COMMENT_ID" header="ID"
                :pt="{ filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                    <Button type="button" class="btn btn-primary" @click="filterCallback()">
                        Search
                    </Button>
                </template>
            </Column>
            <Column field="ROWNUM" header="Rownum"
                :pt="{ filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                    <Button type="button" class="btn btn-primary" @click="filterCallback()">
                        Search
                    </Button>
                </template>
            </Column>
            <Column field="PREV_LOC" header="Prev. Loc."
                :pt="{ filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" disabled />
                    <Button type="button" class="btn btn-primary" @click="filterCallback()" disabled>
                        Search
                    </Button>
                </template>
            </Column>
            <Column field="LOCATION" header="Location"
                :pt="{ filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                    <Button type="button" class="btn btn-primary" @click="filterCallback()">
                        Search
                    </Button>
                </template>
            </Column>
            <Column field="PRICE" header="Price"
                :pt="{ filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                    <Button type="button" class="btn btn-primary" @click="filterCallback()">
                        Search
                    </Button>
                </template>
            </Column>
            <Column field="SOLD_DT" header="Sold Dt."
                :pt="{ filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                    <Button type="button" class="btn btn-primary" @click="filterCallback()">
                        Search
                    </Button>
                </template>
            </Column>
            <Column field="COLOR" header="Color"
                :pt="{ filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                    <Button type="button" class="btn btn-primary" @click="filterCallback()">
                        Search
                    </Button>
                </template>
            </Column>
            <template #empty>No data.</template>
        </DataTable>
        <template #footer>
            <div class="text-end">
                <button type="button" class="btn btn-primary"
                    :disabled="!selectedRecord"
                    @click="_ => { isNoBarcodeMovementsTablePopupOpen = true; }">
                    View Movements
                </button>
            </div>
        </template>
    </Card>
    <NoBarcodeMovementsTablePopup :noBarcodeEntry="selectedNoBarcode"
        :is-open="isNoBarcodeMovementsTablePopupOpen"
        @close="_ => { isNoBarcodeMovementsTablePopupOpen = false; }"
        @error="message => {
            isNoBarcodeMovementsTablePopupOpen = false;
            emit('error', message);
        }" />
</template>

<script setup>
import { computed, ref, watch } from 'vue';

import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useInventoryNoBarcodes } from '@/composables/data/inventoryNoBarcodes';

import Card from '@/components/utils/Card.vue';

import NoBarcodeMovementsTablePopup from './NoBarcodeMovementsTablePopup.vue';

import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

const props = defineProps({
    location: String,
    style: String,
    tableClass: String,
    class: String,
});

const emit = defineEmits(['error']);

const tableClass = computed(() => {
    let tableClass = 'table table-bordered table-hover';
    if(props.tableClass)
        tableClass += ' ' + props.tableClass;
    return tableClass;
});

const { params, filters, onPage, onFilter, onSort } = useDataTableParams(
    ['STYLE', 'COMMENT_ID', 'ROWNUM', 'PREV_LOC', 'LOCATION', 'PRICE', 'SOLD_DT', 'COLOR']);

const { inventoryNoBarcodes, inventoryNoBarcodesTotalRecords, getInventoryNoBarcodes } = useInventoryNoBarcodes();

const selectedRecord = ref(null);

const selectedNoBarcode = computed(() => {
    if(!selectedRecord.value)
        return null;
    return {
        STYLE: selectedRecord.value.STYLE,
        COMMENT_ID: selectedRecord.value.COMMENT_ID,
        ROWNUM: selectedRecord.value.ROWNUM,
    };
})

const fetchTimeout = ref(null);

const isLoading = ref(false);

const isNoBarcodeMovementsTablePopupOpen = ref(false);

async function fetchInventoryNoBarcodes() {
    inventoryNoBarcodes.value = null;

    if(!props.location)
        return;

    isLoading.value = true;
    try {
        await getInventoryNoBarcodes({
            ...params.value,
            // Custom filter (LOCATION or PREV_LOC)
            LOCATION_OR_PREV_LOC: props.location
        });
    } catch(e) {
        emit('error', e.message);
    }
    isLoading.value = false;
}

function tableOnFilter(e) {
    onFilter(e);
    if(fetchTimeout.value)
        clearTimeout(fetchTimeout.value);
    fetchTimeout.value = setTimeout(fetchInventoryNoBarcodes, 300);
}

watch(() => props.location, fetchInventoryNoBarcodes);

watch(() => props.style, async () => {
    filters.value.STYLE.value = props.style;
    onFilter();
    await fetchInventoryNoBarcodes();
});
</script>
