<template>
    <div class="menu-btn"
        @click="toggleSidebar">
        <i class="ph-bold ph-caret-left"></i>
    </div>

    <div class="outer-head">
        <div class="head">
            <div class="user-img">
                <img src="/img/kimbel-single-icon.png">
            </div>
            <div class="user-details mx-auto">
                <img src="/img/kimbel-white.png" class="logo">
            </div>
        </div>
    </div>

    <div class="nav">
        <div class="search-container mb-3">
            <input type="text" class="w-100" v-model="searchInput" />
            <i class="icon ph-bold ph-magnifying-glass"></i>
        </div>

        <template v-for="category in finalNavigation">
            <div class="menu">
                <p class="title">{{ category.title }}</p>
                <ul>
                    <li v-for="item in category.items" :data-title="item.title" :class="{ 'active': item.isActive }">
                        <template v-if="!item.items">
                            <a :href="item.link">
                                <i class="icon ph-bold" :class="item.class"></i>
                                <div v-if="item.lock" class="locked-text">
                                    <span class="text">{{ item.title }}</span><br/>
                                    <small>{{ item.lock() }}</small>
                                </div>
                                <span v-else class="text">{{ item.title }}</span>
                            </a>
                        </template>
                        <template v-else>
                            <a href="#"
                                @click="_ => { item.isActive = !item.isActive; }">
                                <i class="icon ph-bold" :class="item.class"></i>
                                <span class="text">{{ item.title }}</span>
                                <i class="arrow ph-bold ph-caret-down"></i>
                            </a>
                            <ul class="sub-menu" :data-title="item.title" :class="{ 'd-block': item.isActive }">
                                <li v-for="subitem in item.items" :data-title="subitem.title">
                                    <a :href="subitem.link">
                                        <i class="icon ph-bold" :class="subitem.class"></i>
                                        <span class="text">{{ subitem.title }}</span>
                                    </a>
                                </li>
                            </ul>
                        </template>
                    </li>
                </ul>
            </div>
        </template>
    </div>
    <div class="footer-side">
        <div class="menu">
            <p class="title">Account</p>
            <ul>
                <li class="text-white account-border-bottom">
                    <div class="user-account d-flex">
                        <i class="icon ph-bold ph-user me-2"></i>
                        <span class="text">{{ loggedInUser.user?.name }}</span>
                    </div>
                </li>
                <li>
                    <a href="javascript:void(0)" @click="logout">
                        <i class="icon ph-bold ph-sign-out"></i>
                        <span class="text">Logout</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';

import { useLoadingFlagsStore } from '@/stores/loadingFlags';
import { useToastsStore } from '@/stores/toasts';
import { useLoggedInUserStore } from '@/stores/loggedInUser';
import { usePageLocks } from '@/composables/data/pageLocks';

const navigation = [
    {
        title: "MAIN",
        items: [
            {
                title: "Dashboard",
                class: "ph-house-simple",
                link: route("dashboard"),
            },
            {
                title: "New Comment",
                class: "ph-chat-teardrop-dots",
                link: route("activity.new-comment"),
            },
            {
                title: "Update Comment",
                class: "ph-note-pencil",
                link: route("activity.update-comment"),
            },
            {
                title: "Material Catalog",
                class: "ph-list-dashes",
                link: route("activity.material-catalog")
            },
            {
                title: "Update SP Status",
                class: "ph-activity",
                link: route("activity.update-sp-status"),
            },
            {
                title: "Sampling Maintenance",
                class: "ph-ruler",
                link: route("activity.sampling-maintenance"),
            },
            {
                title: "Create SM Pullout Letter",
                class: "ph-envelope",
                link: route("activity.create-sm-pullout-letter"),
            },
        ],
    },
    {
        title: "BARCODES",
        items: [
            {
                title: "Lock Barcode Pages",
                class: "ph-lock",
                link: route("activity.lock-barcode-pages"),
                lock: () => {
                    const lockedBy = pageLocks.value?.filter(pageLocks => pageLocks.page == 'barcodes')[0]?.user.name ?? "nobody";
                    return `Locked by: ${lockedBy}`
                },
            },
            {
                title: "Unlock Barcode Pages",
                class: "ph-lock-key-open",
                link: route("activity.unlock-barcode-pages"),
            },
            {
                title: "Barcodes",
                class: "ph-barcode",
                items: [
                    {
                        title: "Generate New Barcodes",
                        link: route("activity.generate-new-barcodes"),
                    },
                    {
                        title: "Write New Barcodes",
                        link: route("activity.write-new-barcodes"),
                    },
                ],
            },
            {
                title: "Unified Barcodes/NOBC",
                class: "ph-list",
                link: route("activity.inventory-unified"),
            },
        ],
    },
    {
        title: "INVENTORY",
        items: [
            {
                title: "Style Maintenance",
                class: "ph-suitcase-rolling",
                link: route("inventory.style-maintenance"),
            },
            {
                title: "Style Price",
                class: "ph-tag",
                link: route("inventory.style-price"),
            },
            {
                title: "Report Recon (Full)",
                class: "ph-database",
                link: route("inventory.report-recon-full"),
            },
            {
                title: "Recon Main Warehouse",
                class: "ph-database",
                link: route("inventory.recon-main-warehouse"),
            },
            {
                title: "Physical Inventory Checking",
                class: "ph-list-checks",
                link: route("inventory.physical-inventory-checking"),
            },
            {
                title: "Physical Inventory Reports",
                class: "ph-notebook",
                link: route("inventory.physical-inventory-reports"),
            },
            {
                title: "Update Inventory Check",
                class: "ph-note-pencil",
                link: route("inventory.update-inventory-check"),
            }
        ],
    },
    {
        title: "REPORTS",
        items: [
            {
                title: "Inventory - Reports",
                class: "ph-clipboard-text",
                items: [
                    {
                        title: "Style Reports - Inventory",
                        link: route("report.inventory.style-reports"),
                    },
                    {
                        title: "Barcode Reports",
                        link: route("report.inventory.barcode-reports"),
                    },
                    {
                        title: "Comment Reports",
                        link: route("report.inventory.comment-reports"),
                    },
                    {
                        title: "Monthly Reports",
                        link: route("report.inventory.monthly-reports"),
                    },
                    {
                        title: "Topseller Reports",
                        link: route("report.inventory.topseller-reports"),
                    },
                    {
                        title: "Delivery and Pull-out Reports",
                        link: route("report.inventory.delivery-pullout-reports"),
                    },
                    {
                        title: "Quantity per Store",
                        link: route("report.inventory.quantity-per-store"),
                    },
                    {
                        title: "Sales Reports",
                        link: route("report.inventory.sales-reports"),
                    },
                    {
                        title: "Monthly Discrepancy Report",
                        link: route("report.inventory.monthly-discrepancy"),
                    },
                    {
                        title: "Delivery Receipt Report",
                        link: route("report.inventory.delivery-receipt"),
                    },
                    {
                        title: "Location Detail Report",
                        link: route("report.inventory.location-detail-report"),
                    },
                    {
                        title: "Recon Lost Report",
                        link: route("report.inventory.recon-lost-reports"),
                    },
                ],
            },
            {
                title: "Accounting - Reports",
                class: "ph-calculator",
                items: [
                    {
                        title: "Monthly Reports",
                        link: route("report.accounting.monthly-reports"),
                    },
                    {
                        title: "Location Reports",
                        link: route("report.accounting.location-reports"),
                    },
                    {
                        title: "Interval Reports",
                        link: route("report.accounting.interval-reports"),
                    },
                    {
                        title: "Yearly Reports",
                        link: route("report.accounting.yearly-reports"),
                    },
                    {
                        title: "Custom Reports",
                        link: route("report.accounting.custom-reports"),
                    },
                    {
                        title: "Commission Report",
                        link: route("report.accounting.commission-report"),
                    },
                    {
                        title: "Employee Reports",
                        link: route("report.accounting.employee-reports"),
                    },
                    {
                        title: "Loan Reports",
                        link: route("report.accounting.loan-reports"),
                    },
                ],
            },
            {
                title: "Payroll - Reports",
                class: "ph-wallet",
                items: [
                    {
                        title: "Time Period Reports",
                        link: route("report.payroll.time-period-reports"),
                    },
                    {
                        title: "Monthly Reports",
                        link: route("report.payroll.monthly-reports"),
                    },
                    {
                        title: "Attendance Reports",
                        link: route("report.payroll.attendance-reports"),
                    },
                ],
            },
        ],
    },
    {
        title: "VOUCHERS",
        items: [
            {
                title: "Create Voucher",
                class: "ph-file-plus",
                link: route("voucher.create-voucher"),
            },
            {
                title: "Update Voucher",
                class: "ph-pencil-line",
                link: route("voucher.update-voucher"),
            },
            {
                title: "GL Balance",
                class: "ph-scales",
                link: route("voucher.gl-balances"),
            },
            {
                title: "Payroll Vouchers",
                class: "ph-wallet",
                link: route("voucher.payroll-vouchers"),
            },
        ],
    },
    {
        title: "PAYSLIP",
        items: [
            {
                title: "Payroll",
                class: "ph-wallet",
                items: [
                    {
                        title: "Create Payslips",
                        link: route("payslip.create-payslip"),
                    },
                    {
                        title: "Delete Payslips",
                        link: route("payslip.delete-payslip"),
                    },
                    {
                        title: "Print Payslips",
                        link: route("report.accounting.time-period-reports"),
                    },
                    {
                        title: "Create Loan",
                        link: route("catalog.create-loan"),
                    },
                    {
                        title: "Close Loan",
                        link: route("catalog.close-loans"),
                    },
                    {
                        title: "Create Payroll",
                        link: route("payslip.create-payroll"),
                    },
                    {
                        title: "Create Payroll Report",
                        link: route("payslip.create-payroll-report"),
                    },
                ],
            },
            {
                title: "Enter Hours",
                class: "ph-clock",
                link: route("payslip.enter-hours"),
            },
            {
                title: "Import Hours (Sales)",
                class: "ph-download-simple",
                link: route("payslip.import-hours-sales"),
            },
            {
                title: "Import Hours Nideka",
                class: "ph-download-simple",
                link: route("payslip.import-hours-nideka"),
            },
            {
                title: "Employee SM Mapping",
                class: "ph-user-gear",
                link: route("payslip.employee-sm-mapping"),
            },
            {
                title: "Sales Leaves",
                class: "ph-briefcase",
                link: route("payslip.sales-leaves"),
            },
        ],
    },
    {
        title: "CATALOG",
        items: [
            {
                title: "Employee Maintenance",
                class: "ph-user-circle",
                link: route("catalog.employee-maintenance"),
            },
            {
                title: "Employee Dependent",
                class: "ph-users",
                link: route("catalog.employee-dependents"),
            },
            {
                title: "Employee Violations",
                class: "ph-warning-circle",
                link: route("catalog.employee-violations"),
            },
            {
                title: "Change Rate",
                class: "ph-currency-dollar",
                link: route("catalog.change-rate"),
            },
            {
                title: "Create Loan",
                class: "ph-plus-circle",
                link: route("catalog.create-loan"),
            },
            {
                title: "Close Loans",
                class: "ph-x",
                link: route("catalog.close-loans"),
            },
            {
                title: "Assign Employees to Store",
                class: "ph-storefront",
                link: route("catalog.employee-location"),
            },
            {
                title: "Joborder Maintenance",
                class: "ph-address-book",
                link: route("catalog.joborder-maintenance"),
            },
            {
                title: "Landed Cost",
                class: "ph-package",
                link: route("catalog.landed-costs"),
            },
            {
                title: "Location Cost",
                class: "ph-map-pin",
                link: route("catalog.location-costs"),
            },
            {
                title: "Update Store Summary",
                class: "ph-note-pencil",
                link: route("catalog.update-store-summary"),
            },
            {
                title: "User Permissions",
                class: "ph-shield-check",
                link: route("catalog.user-permissions"),
            },
            {
                title: "Location Keys",
                class: "ph-gps-fix",
                link: route("catalog.location-keys"),
            },
            {
                title: "Company Event Maintenance",
                class: "ph ph-calendar",
                link: route("catalog.company-event-maintenance"),
            },
        ],
    },
    {
        title: "MAINTENANCE",
        items: [
            {
                title: "Time Period Maintenance",
                class: "ph-calendar",
                link: route("catalog.time-period-maintenance"),
            },
            {
                title: "Account Maintenance",
                class: "ph-bank",
                link: route("catalog.account-maintenance"),
            },
            {
                title: "Account Type Maintenance",
                class: "ph-archive-box",
                link: route("catalog.account-type-maintenance"),
            },
            {
                title: "Location Maintenance",
                class: "ph-map-pin",
                link: route("catalog.location-maintenance"),
            },
        ],
    },
    {
        title: "PURCHASE ORDERS",
        items: [
            {
                title: "Order Supplier Maintenance",
                class: "ph-file-text",
                link: route("po.supplier-maintenance"),
            },
            {
                title: "Purchase Orders",
                class: "ph-file-text",
                link: route("po.purchase-orders"),
            },
        ],
    },
    {
        title: "ADMIN",
        items: [
            {
                title: "Upload Sales Report",
                class: "ph-upload-simple",
                link: route("admin.upload-sales-report"),
            },
            {
                title: "Upload Remittance Advice",
                class: "ph-file-text",
                link: route("admin.upload-remittance-advice"),
            },
        ],
    },
];

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();
const loggedInUser = useLoggedInUserStore();

const searchInput = ref(null);

const finalNavigation = ref(navigation);

const { pageLocks, getPageLocks } = usePageLocks();

function toggleSidebar() {
    /**
     * NOTE: This is implemented this way because the base tech is rendered by Blade,
     * and the insides of the sidebar is a Vue component. To hook the sidebar up, we have to statically
     * place the <sidebar> element inside blade for now, and it manages the container's classes.
     *
     * TODO: After refactoring to Inertia.js, refactor this section to use a ref.
     */
    const sidebarElement = document.getElementById("sidebar");
    if (sidebarElement.classList.contains("active")) {
        sidebarElement.classList.remove("active");
    } else {
        sidebarElement.classList.add("active");
    }
}

watch(searchInput, () => {
    if (!searchInput.value) {
        finalNavigation.value = navigation;
    } else {
        const filteredNavigation = [];

        const search = searchInput.value.toLowerCase();

        for (const category of navigation) {
            const filteredItems = [];

            for (const item of category.items) {
                if (item.title.toLowerCase().includes(search) ||
                    item.items?.filter(subitem => subitem.title.toLowerCase().includes(search)).length > 0) {
                    filteredItems.push(item);
                }
            }

            if (filteredItems.length > 0) {
                filteredNavigation.push({
                    ...category,
                    items: filteredItems
                });
            }
        }

        finalNavigation.value = filteredNavigation;
    }
});

async function fetchPageLocks() {
    loadingFlags.add('fetchPageLocks');
    try {
        await getPageLocks();
    } catch(e) {
        toasts.add('ERROR', 'Error', e.message);
    }
    loadingFlags.delete('fetchPageLocks');
}

onMounted(() => {
    fetchPageLocks();
});

async function logout() {
    loadingFlags.add('logout');
    try {
        await axios.post(route('logout'));
        window.location.reload();
    } catch (e) {
        toasts.add('ERROR', 'Error', e.message);
    }
    loadingFlags.delete('logout');
}
</script>
