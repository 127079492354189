import { ref } from "vue";

import constructFetchError from "./constructFetchError";

import axios from 'axios';

export function useReconInfoGenerations() {
    const reconInfoGenerations = ref(null);
    const totalRecords = ref(null);

    const getReconInfoGenerations = async (params) => {
        try {
            const getReconInfoGenerationsResponse = await axios.get(route('api.recon-info-generations.index', params));
            reconInfoGenerations.value = getReconInfoGenerationsResponse.data.data;
            totalRecords.value = getReconInfoGenerationsResponse.data.meta.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching recon parms.');
        }
    };
    
    return { reconInfoGenerations, totalRecords, getReconInfoGenerations };
}
