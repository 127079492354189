<template>
    <Card>
        <template #header>
            Closeable Loans
        </template>
        <DataTable
            :loading="isLoading"
            :value="employeeLoans"
            paginator
            :rows="10"
            filter-display="row"
            v-model:filters="filters"
            selection-mode="single"
            v-model:selection="selectedEmployeeLoan"
            :pt="{ table: { class: 'table table-bordered table-hover' } }">
            <Column field="LOANID" header="Loan ID" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="LOANTYPE" header="Type" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="employee.FNAME" header="First Name" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="employee.LNAME" header="Last Name" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="employee.DEPT" header="Department" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="DATE" header="Date" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="STATUS" header="Status" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="INIT_AMT" header="Initial Amount" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="TOTAL_PAYMENT" header="Total Payment" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="REMAIN_AMT" header="Remaining" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="AMT_WEEK" header="Amt. per Week" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="NUM_WEEKS" header="# of Weeks" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="DESCRIPTION" header="Description" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <template #empty>
                <div class="text-center">
                    No closeable loans.
                </div>
            </template>
            <template #footer>
                {{ employeeLoans?.length ?? 0 }} total loans to close
            </template>
        </DataTable>
        <template #footer>
            <div class="text-end">
                <button type="button" class="btn btn-primary me-2"
                    :disabled="!selectedEmployeeLoan || isClosing"
                    @click="closeSelectedLoan">
                    <span v-if="isClosing" class="spinner-border spinner-border-sm"
                        role="status" aria-hidden="true"></span>
                    Close Selected
                </button>
                <button type="button" class="btn btn-primary"
                    :disabled="!employeeLoans?.length || isClosingAll"
                    @click="closeAllLoans">
                    <span v-if="isClosingAll" class="spinner-border spinner-border-sm"
                        role="status" aria-hidden="true"></span>
                    Close All Open Loans
                </button>
            </div>
        </template>
    </Card>
</template>

<script setup>
import { ref, onMounted } from "vue";

import { useFlashMessages } from "@/composables/flashmessages";
import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useEmployeeLoans } from "@/composables/data/employeeLoans";

import Card from "@/components/utils/Card.vue";

import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";

import scrollToTop from "@/utils/scrollToTop";

const { addFlashMessage } = useFlashMessages();

const { filters } = useDataTableParams([
    'LOANID', 'LOANTYPE', 'employee.FNAME', 'employee.LNAME', 'employee.DEPT',
    'DATE', 'STATUS', 'INIT_AMT', 'TOTAL_PAYMENT', 'REMAIN_AMT', 'AMT_WEEK', 'NUM_WEEKS', 'DESCRIPTION'
]);

const { employeeLoans, getCloseableLoans, postLoanClosures } = useEmployeeLoans();

const selectedEmployeeLoan = ref(null);

const isLoading = ref(false);

const isClosing = ref(false);
const isClosingAll = ref(false);

async function fetchClosableLoans() {
    isLoading.value = true;
    try {
        await getCloseableLoans();
        employeeLoans.value = employeeLoans.value.map(loan => ({
            ...loan,
            REMAIN_AMT: (loan.INIT_AMT - loan.TOTAL_PAYMENT).toFixed(2)
        }));
    } catch(e) {
        addFlashMessage('ERROR', e.message);
        scrollToTop();
    }
    isLoading.value = false;
}

onMounted(fetchClosableLoans);

async function closeSelectedLoan() {
    if(window.prompt("Are you sure? Type 'CLOSE' to close selected loan.") != 'CLOSE') {
        window.alert('Invalid keyword. No action performed.');
        return;
    }

    isClosing.value = true;
    try {
        await postLoanClosures([selectedEmployeeLoan.value.LOANID]);
        addFlashMessage('SUCCESS', `Successfully closed LOAN ID ${selectedEmployeeLoan.value.LOANID}.`);
        fetchClosableLoans();
        selectedEmployeeLoan.value = null;
    } catch(e) {
        addFlashMessage('ERROR', e.message);
    }
    isClosing.value = false;
}

async function closeAllLoans() {
    if(window.prompt("Are you sure? Type 'CLOSE ALL' to close all closeable loans.") != 'CLOSE ALL') {
        window.alert('Invalid keyword. No action performed.');
        return;
    }

    isClosingAll.value = true;
    try {
        await postLoanClosures(employeeLoans.value.map(loan => loan.LOANID));
        addFlashMessage('SUCCESS', `Successfully closed ${employeeLoans.value.length} closeable loans.`);
        fetchClosableLoans();
        selectedEmployeeLoan.value = null;
    } catch(e) {
        addFlashMessage('ERROR', e.message);
    }
    isClosingAll.value = false;
    scrollToTop();
}
</script>
