<template>
    <div v-if="isOpen" class="pop-up d-flex justify-content-center align-items-center">
        <Card>
            <template #header>
                <div class="d-flex justify-content-between pt-2">
                    <h4 class="title">UNLOST Barcodes</h4>
                    <button type="button" class="btn-close" @click="emit('close')"></button>
                </div>
            </template>
            <div class="row g-3 mb-3">
                <div class="col-md-6">
                    <FormInput type="text" v-model="timePeriodDisplay" disabled
                        label="Time Period" id-prefix="timePeriodDisplay"
                        is-horizontal class="mb-2" input-class="form-control-sm" />
                    <FormInput type="text" v-model="locationDisplay" disabled
                        label="Location" id-prefix="locationDisplay"
                        is-horizontal class="mb-2" input-class="form-control-sm" />
                </div>
                <div class="col-md-6">
                    <FormInput type="text" v-model="timePeriodStartDateDisplay" disabled
                        label="Start Date" id-prefix="timePeriodStartDateDisplay"
                        is-horizontal class="mb-2" input-class="form-control-sm" />
                    <FormInput type="text" v-model="timePeriodEndDateDisplay" disabled
                        label="End Date" id-prefix="timePeriodEndDateDisplay"
                        is-horizontal class="mb-2" input-class="form-control-sm" />
                </div>
            </div>
            <ReconLostCommentsTable :start-date="timePeriod?.START_DATE"
                :end-date="timePeriod?.END_DATE"
                v-model="selectedComment"
                @error="message => {
                    emit('error', message);
                }"
                class="mb-3" />
            <FlashMessageAlerts :messages="flashMessages" :dismissible="true" />
            <DataTable :loading="isFetchingLostBarcodes" :value="inventoryRecords"
                paginator :rows="10"
                v-model:filters="filters" filter-display="row"
                sort-mode="multiple"
                v-model:selection="selectedBarcodes"
                :pt="{ table: { class: 'table table-sm table-bordered table-hover recon-table' } }">
                <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
                <Column field="STYLE" header="Style" sortable :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                            class="form-control" placeholder="Search" />
                    </template>
                </Column>
                <Column field="DATE" header="Date" sortable :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                            class="form-control" placeholder="Search" />
                    </template>
                </Column>
                <Column field="SERIAL" header="Serial" sortable :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                            class="form-control" placeholder="Search" />
                    </template>
                </Column>
                <Column field="COLOR" header="Color" sortable :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                            class="form-control" placeholder="Search" />
                    </template>
                </Column>
                <Column field="SEQ_NO" header="Seq" sortable :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                            class="form-control" placeholder="Search" />
                    </template>
                </Column>
                <Column field="EVENT_DT" header="Event Date" sortable :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                            class="form-control" placeholder="Search" />
                    </template>
                </Column>
                <Column field="PREV_LOC" header="Prev. Loc" sortable :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                            class="form-control" placeholder="Search" />
                    </template>
                </Column>
                <template #footer>
                    {{ inventoryRecords?.length ?? 0 }} total barcodes.
                </template>
                <template #empty>
                    <div class="text-center">
                        No data yet.
                    </div>
                </template>
            </DataTable>
            <template #footer>
                <div class="d-grid">
                    <button type="button" class="btn btn-primary"
                        :disabled="!selectedComment || !selectedBarcodes?.length || isUndeleting"
                        @click="markAllAsUnlost">
                        <span v-if="isUndeleting" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Unlost All
                    </button>
                </div>
            </template>
        </Card>
    </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';

import { useTimePeriods } from '@/composables/data/timePeriods';
import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useInventory } from '@/composables/data/inventory';
import { useReconActions } from '@/composables/data/reconActions';

import ReconLostCommentsTable from './ReconLostCommentsTable.vue';

import Card from "@/components/utils/Card.vue";
import FormInput from "@/components/utils/FormInput.vue";
import FlashMessageAlerts from '@/components/utils/FlashMessageAlerts.vue';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

const props = defineProps({
    timePeriod: Number,
    location: String,
    inventoryDone: String,
    isOpen: Boolean,
    class: String
});

const emit = defineEmits(['close', 'done', 'error']);

const flashMessages = ref([]);

const { timePeriod, getTimePeriod } = useTimePeriods();

const timePeriodDisplay = computed(() => timePeriod.value?.TIME_PERIOD);
const locationDisplay = computed(() => props.location);
const timePeriodStartDateDisplay = computed(() => timePeriod.value?.START_DATE);
const timePeriodEndDateDisplay = computed(() => timePeriod.value?.END_DATE);

const selectedComment = ref(null);

const isFetchingLostBarcodes = ref(false);

const { filters } = useDataTableParams(
    ['STYLE', 'DATE', 'SERIAL', 'SEQ_NO', 'COLOR', 'PREV_LOC', 'EVENT_DT']);

const { inventoryRecords, getInventoryRecords } = useInventory();

const selectedBarcodes = ref(null);

const { postBarcodeMultipleMissingUndeletion } = useReconActions();

const isUndeleting = ref(false);

async function fetchTimePeriod() {
    timePeriod.value = null;

    if (!props.timePeriod)
        return;

    try {
        await getTimePeriod(props.timePeriod);
    } catch(e) {
        emit('error', e.message);
    }
}

async function fetchLostBarcodes() {
    inventoryRecords.value = null;

    if(!selectedComment.value)
        return;

    isFetchingLostBarcodes.value = true;
    try {
        await getInventoryRecords({
            filters: {
                COMMENT_ID: {
                    value: selectedComment.value.COMMENT_ID,
                    matchMode: 'equals'
                },
                PREV_LOC: {
                    value: props.location,
                    matchMode: 'equals'
                }
            },
        });
    } catch(e) {
        emit('error', e.message);
    }
    isFetchingLostBarcodes.value = false;
}

watch(() => props.timePeriod, fetchTimePeriod);
watch(selectedComment, () => {
    selectedBarcodes.value = null;
    fetchLostBarcodes();
});
watch(() => props.isOpen, () => {
    selectedComment.value = null;
    selectedBarcodes.value = null;
    if (props.isOpen)
        fetchLostBarcodes();
});

async function markAllAsUnlost() {
    isUndeleting.value = true;
    try {
        const response = await postBarcodeMultipleMissingUndeletion(selectedBarcodes.value.map(inventoryLog => ({
            STYLE: inventoryLog.STYLE,
            DATE: inventoryLog.DATE,
            SERIAL: inventoryLog.SERIAL
        })));
        emit('done', response.data.message);
        flashMessages.value = [];
    } catch(e) {
        flashMessages.value.push({type: 'ERROR', content: e.message});
    }
    isUndeleting.value = false;
}
</script>

<style scoped>
.pop-up {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: rgb(0, 0, 0, 0.5);
}

.pop-up .card {
    width: 60%;
}

@media (max-width: 768px) {
    .pop-up .card {
        width: 85%;
    }
}

@media (max-width: 992px) {
    .pop-up .card {
        width: 70%;
    }
}
</style>
