<template>
    <ConfirmationDialog
        :description="`Type the word 'CLOSE' to close this comment.`"
        :text="'CLOSE'"
        @confirmed = "Confirmation"
        @error="message => {
            addFlashMessage('ERROR', message);
        }"/>
    <div class="d-flex justify-content-center">
        <Card class="w-100">
            <h5>Close Comment/Activity</h5>
            <h6>*Warning!* No additional activity may be specified once comment is closed.</h6>
            <div class="row g-3 my-3">
                <div class="col-md-12">
                    <Card>
                        <template #header>
                            Select Comment
                        </template>
                        <div class="mb-3">
                            <DataTable lazy paginator class="sticky-header-footer"
                                :rows="10" :value="commentList" :total-records="totalRecords"
                                filter-display="row" v-model:filters="filters"
                                v-model:selection="selectedComment" selection-mode="single" data-key="COMMENT_ID"
                                @page="e => onPage(e)" @filter="e => onFilter(e)"
                                :row-class="commentRowClass" :pt="{ table: { class: ' table table-bordered table-hover' } }">
                                <Column field="COMMENT_ID" header="ID" :pt="{
                                    filterInput: { class: 'input-group input-group-sm' },
                                    filterMenuButton: { class: 'd-none' },
                                    headerFilterClearButton: { class: 'd-none' } }">
                                    <template #filter="{ filterModel, filterCallback }">
                                        <InputText type="text" v-model="filterModel.value" @keydown="filterCallback()"
                                            class="form-control" placeholder="Search" />
                                        <Button type="button" class="btn btn-primary" @click="filterCallback()">
                                            Search
                                        </Button>
                                    </template>
                                </Column>
                                <Column field="COMMENT_TIMESTAMP" header="Timestamp" :pt="{
                                    filterInput: { class: 'input-group input-group-sm' },
                                    filterMenuButton: { class: 'd-none' },
                                    headerFilterClearButton: { class: 'd-none' } }">
                                    <template #filter="{ filterModel, filterCallback }">
                                        <InputText type="text" v-model="filterModel.value" @keydown="filterCallback()"
                                            class="form-control" placeholder="Search" />
                                        <Button type="button" class="btn btn-primary" @click="filterCallback()">
                                            Search
                                        </Button>
                                    </template>
                                </Column>
                                <Column field="COMMENT_TEXT" header="Text" :pt="{
                                    filterInput: { class: 'input-group input-group-sm' },
                                    filterMenuButton: { class: 'd-none' },
                                    headerFilterClearButton: { class: 'd-none' } }">
                                    <template #filter="{ filterModel, filterCallback }">
                                        <InputText type="text" v-model="filterModel.value" @keydown="filterCallback()"
                                            class="form-control" placeholder="Search" />
                                        <Button type="button" class="btn btn-primary" @click="filterCallback()">
                                            Search
                                        </Button>
                                    </template>
                                </Column>
                                <Column field="LOCATION" header="Location" :pt="{
                                    filterInput: { class: 'input-group input-group-sm' },
                                    filterMenuButton: { class: 'd-none' },
                                    headerFilterClearButton: { class: 'd-none' } }">
                                    <template #filter="{ filterModel, filterCallback }">
                                        <InputText type="text" v-model="filterModel.value" @keydown="filterCallback()"
                                            class="form-control" placeholder="Search" />
                                        <Button type="button" class="btn btn-primary" @click="filterCallback()">
                                            Search
                                        </Button>
                                    </template>
                                </Column>
                                <Column field="DESCRIPTION" header="Description"></Column>
                            </DataTable>
                        </div>
                        <div class="d-flex justify-content-center">
                            <button
                                class="btn btn-primary w-75 mx-auto"
                                data-bs-toggle="modal" data-bs-target="#modalConfirmation"
                                :disabled="selectedComment === null">Permanently Close Comment
                            </button>
                        </div>
                    </Card>
                </div>
            </div>
        </Card>
    </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import axios from "axios";

import Card from '../../../utils/Card.vue';

import handleFetchErrors from "../../../../utils/handleFetchErrors";
import { useFlashMessages } from "../../../../composables/flashmessages";
import scrollToTop from "../../../../../js/utils/scrollToTop";

import ConfirmationDialog from "../../../utils/ConfirmationDialog.vue";

import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';
import { FilterMatchMode } from 'primevue/api';

const { addFlashMessage } = useFlashMessages();

const params = ref({});
const commentList = ref([]);
const selectedComment = ref(null);
const totalRecords = ref();
const filters = ref({
    COMMENT_ID: { value: null, matchMode: FilterMatchMode.CONTAINS },
    COMMENT_TIMESTAMP: { value: null, matchMode: FilterMatchMode.CONTAINS },
    COMMENT_TEXT: { value: null, matchMode: FilterMatchMode.CONTAINS },
    LOCATION: { value: null, matchMode: FilterMatchMode.CONTAINS },
    DESCRIPTION: { value: null, matchMode: FilterMatchMode.CONTAINS },
});

watch(params, getCommentList, { deep: true });

onMounted(async () => {
    params.value = {
        first: 0,
        page: 0,
        rows: 10,
        sortField: null,
        sortOrder: null,
        filters: filters.value,

    };
});

async function getCommentList() {
    try {
        const response = await axios.get(route('api.comments.index', {
            ...params.value,
            COMMENT_STATUS: 'O'
        }))
        commentList.value = response.data.data;
        totalRecords.value = response.data.meta.total;
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, 'Error while fetching comments.');
        scrollToTop();
    }
}

const onPage = (event) => {
    params.value = event;
}

const onFilter = _ => {
    params.value.filters = filters.value;
    params.value.page = 0;
    params.value.first = 0;
}

const commentRowClass = (data) => {
    return [{'bg-primary text-white': data.COMMENT_ID == selectedComment.value?.COMMENT_ID}];
}

async function closeCommentStatus() {
    try {
        const response = await axios.patch(route('api.comments.update',
            { id: selectedComment.value.COMMENT_ID}),{
                COMMENT_STATUS: 'C',
                CLOSE_COMMENT: true,
            });
        scrollToTop();
        addFlashMessage('SUCCESS', response.data.message);
        commentList.value = 
            commentList.value.filter(comment => comment.COMMENT_ID !== selectedComment.value.COMMENT_ID);
        selectedComment.value = null;
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, 'Error while fetching comments.');
        scrollToTop();
    }
}

function Confirmation(result) {
    if (result) {
        closeCommentStatus();
    }
}
</script>
