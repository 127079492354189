<template>
    <SampleTableCard v-model="selectedSampleFromTable"
        :refresh-flag="refreshSamplesTableFlag"
        @error="message => {
            addFlashMessage('ERROR', message);
            scrollToTop();
        }"
        class="mb-3" />
    <div class="row g-3">
        <div class="col-md-8 col-lg-6">
            <SampleFormCard :selected-sample="selectedSampleFromTable"
                @success="(message, sample) => {
                    addFlashMessage('SUCCESS', message);
                    scrollToTop();
                    selectedSampleFromTable = sample;
                    refreshSamplesTableFlag = !refreshSamplesTableFlag;
                }"
                @error="message => {
                    addFlashMessage('ERROR', message);
                    scrollToTop();
                }"
                @reset="_ => selectedSampleFromTable = null" />
        </div>
        <div class="col-md-4 col-lg-6">
            <ImagesCard :sample="selectedSampleFromTable"
                @success="message => {
                    addFlashMessage('SUCCESS', message);
                    scrollToTop();
                }" />
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';

import { useFlashMessages } from '@/composables/flashmessages';

import SampleTableCard from './SampleTableCard.vue';
import SampleFormCard from './SampleFormCard.vue';
import ImagesCard from './ImagesCard.vue';

import scrollToTop from '@/utils/scrollToTop';

const { addFlashMessage } = useFlashMessages();

const selectedSampleFromTable = ref(null);
const refreshSamplesTableFlag = ref(false);
</script>
