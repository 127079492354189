<template>
    <div v-if="isOpen" class="pop-up d-flex justify-content-center align-items-center">
        <Card>
            <template #header>
                <div class="d-flex justify-content-between pt-2">
                    <h4 class="title">History</h4>
                    <button type="button" class="btn-close" @click="emit('close')"></button>
                </div>
            </template>
            <DataTable paginator :rows="10" :value="joborderDetails"
                filter-display="row" v-model:filters="filters"
                :pt="{ table: { class: 'table table-bordered table-hover' } }">
                <Column field="STYLE" header="Style"
                    :pt="{ filterInput: { class: 'input-group input-group-sm' },
                        filterMenuButton: { class: 'd-none' },
                        headerFilterClearButton: { class: 'd-none' } }">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()"
                            class="form-control" placeholder="Search" />
                        <Button type="button" class="btn btn-primary" @click="filterCallback()">
                            Search
                        </Button>
                    </template>
                </Column>
                <Column field="COMPOSITION" header="Composition"
                    :pt="{ filterInput: { class: 'input-group input-group-sm' },
                        filterMenuButton: { class: 'd-none' },
                        headerFilterClearButton: { class: 'd-none' } }">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()"
                            class="form-control" placeholder="Search" />
                        <Button type="button" class="btn btn-primary" @click="filterCallback()">
                            Search
                        </Button>
                    </template>
                </Column>
                <Column field="EVENT_DATE" header="Event Date"
                    :pt="{ filterInput: { class: 'input-group input-group-sm' },
                        filterMenuButton: { class: 'd-none' },
                        headerFilterClearButton: { class: 'd-none' } }">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()"
                            class="form-control" placeholder="Search" />
                        <Button type="button" class="btn btn-primary" @click="filterCallback()">
                            Search
                        </Button>
                    </template>
                </Column>
                <Column field="ACTION" header="Action"
                    :pt="{ filterMenuButton: { class: 'd-none' },
                        headerFilterClearButton: { class: 'd-none' } }">
                    <template #filter="{ filterModel, filterCallback }">
                        <FormSelect v-model="filterModel.value" @change="filterCallback()"
                            :options="[
                                { value: 0, verbose: 'Comment' },
                                { value: 1, verbose: 'Received' },
                                { value: 2, verbose: 'Completed' },
                                { value: 3, verbose: 'Delivered' },
                            ]"
                            :option-value="option => option.value"
                            :option-to-string="option => option.verbose"
                            placeholder="Search" select-class="form-select-sm"
                            hide-label />
                    </template>
                    <template #body="{ data }">
                        <span v-if="data.ACTION == 1" class="badge bg-warning">
                            Received
                        </span>
                        <span v-else-if="data.ACTION == 2" class="badge bg-primary">
                            Completed
                        </span>
                        <span v-else-if="data.ACTION == 3" class="badge bg-success">
                            Delivered
                        </span>
                        <span v-else-if="data.ACTION == 0" class="badge bg-secondary">
                            Comment
                        </span>
                    </template>
                </Column>
                <Column field="QUANTITY" header="Quantity"
                    :pt="{ filterInput: { class: 'input-group input-group-sm' },
                        filterMenuButton: { class: 'd-none' },
                        headerFilterClearButton: { class: 'd-none' } }">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()"
                            class="form-control" placeholder="Search" />
                        <Button type="button" class="btn btn-primary" @click="filterCallback()">
                            Search
                        </Button>
                    </template>
                </Column>
                <Column field="CREATED_BY" header="Created By"
                    :pt="{ filterInput: { class: 'input-group input-group-sm' },
                        filterMenuButton: { class: 'd-none' },
                        headerFilterClearButton: { class: 'd-none' } }">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()"
                            class="form-control" placeholder="Search" />
                        <Button type="button" class="btn btn-primary" @click="filterCallback()">
                            Search
                        </Button>
                    </template>
                </Column>
                <Column field="COMMENT" header="Comment"
                    :pt="{ filterInput: { class: 'input-group input-group-sm' },
                        filterMenuButton: { class: 'd-none' },
                        headerFilterClearButton: { class: 'd-none' } }">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()"
                            class="form-control" placeholder="Search" />
                        <Button type="button" class="btn btn-primary" @click="filterCallback()">
                            Search
                        </Button>
                    </template>
                </Column>
                <Column field="created_at" header="Created At"
                    :pt="{ filterInput: { class: 'input-group input-group-sm' },
                        filterMenuButton: { class: 'd-none' },
                        headerFilterClearButton: { class: 'd-none' } }">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()"
                            class="form-control" placeholder="Search" />
                        <Button type="button" class="btn btn-primary" @click="filterCallback()">
                            Search
                        </Button>
                    </template>
                    <template #body="{ data }">
                        {{ data.created_at ? moment(data.created_at).format('YYYY-MM-DD HH:MM:SS') : null }}
                    </template>
                </Column>
                <Column field="updated_at" header="Updated At"
                    :pt="{ filterInput: { class: 'input-group input-group-sm' },
                        filterMenuButton: { class: 'd-none' },
                        headerFilterClearButton: { class: 'd-none' } }">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()"
                            class="form-control" placeholder="Search" />
                        <Button type="button" class="btn btn-primary" @click="filterCallback()">
                            Search
                        </Button>
                    </template>
                    <template #body="{ data }">
                        {{ data.updated_at ? moment(data.updated_at).format('YYYY-MM-DD HH:MM:SS') : null }}
                    </template>
                </Column>
                <template #empty>No history.</template>
            </DataTable>
        </Card>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';

import { useJoborderDetails } from '@/composables/data/joborderDetails';

import Card from "@/components/utils/Card.vue";
import FormSelect from '@/components/utils/FormSelect.vue';

import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

import moment from 'moment';

const props = defineProps({
    joborderNew: Object,
    refreshFlag: Boolean,
    isOpen: Boolean,
});

const emit = defineEmits(['close']);

const { joborderDetails, getJoborderDetails } = useJoborderDetails();

const filters = ref({
    STYLE: { value: null, matchMode: 'contains' },
    COMPOSITION: { value: null, matchMode: 'contains' },
    ACTION: { value: null, matchMode: 'contains' },
    EVENT_DATE: { value: null, matchMode: 'contains' },
    QUANTITY: { value: null, matchMode: 'contains' },
    CREATED_BY: { value: null, matchMode: 'contains' },
    COMMENT: { value: null, matchMode: 'contains' },
    created_at: { value: null, matchMode: 'contains' },
    updated_at: { value: null, matchMode: 'contains' },
});

async function fetchJoborderDetails() {
    joborderDetails.value = null;
    if(!props.joborderNew)
        return;
    await getJoborderDetails(props.joborderNew.JONO);
}

watch(() => props.joborderNew, fetchJoborderDetails);
watch(() => props.refreshFlag, fetchJoborderDetails);
</script>

<style scoped>
.pop-up {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: rgb(0, 0, 0, 0.5);
}

.pop-up .card {
    width: 100%;
}

@media (min-width: 768px) {
    .pop-up .card {
        width: 85%;
    }
}

@media (min-width: 992px) {
    .pop-up .card {
        width: 70%;
    }
}
</style>