<style scoped>
.wrapper {
    width: 100%;
    height: 15rem;
    overflow-y: auto;
    background-color: #FFF;
}

.wrapper table thead {
    position: sticky;
    top: 0;
    background-color: var(--bs-card-bg);
}
.cursor-pointer {
    cursor: pointer;
}
</style>
<template>
    <Card>
        <template #header>
           ITEM
        </template>
        <div class="row">
            <div class="col-md-6">
                <div class="wrapper">
                    <table class="table table-bordered table-hover">
                        <thead>
                            <tr>
                                <th>ITEM NAME</th>
                                <th>UNIT</th>
                                <th>DESCRIPTION</th>
                                <th>CREATED BY</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="item in accountItemList"
                                :class="{ 'bg-primary text-white': item.id === accountItem.id }"
                                @click="selectAccountItem(item)"
                                class="cursor-pointer"
                            >
                                <td>{{ item.ITEM_NAME }}</td>
                                <td>{{ item.UNIT }}</td>
                                <td>{{ item.DESCRIPTION }}</td>
                                <td>{{ item.CREATED_BY }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-md-6">
                <div>
                    <FormInput type="text" label="ITEM NAME:"
                        v-model="accountItem.ITEM_NAME"
                        is-horizontal id-prefix="itemName" class="mb-1"
                        input-class="form-control-sm"
                        :disabled="disableControl || accountItem.id !== null" :errors="errors.ITEM_NAME"
                        @keydown-enter="focusNextInput('unitFormInput')"/>
                    <FormSelect label="UNIT:"
                        :options="unitList"
                        :option-to-string="(unit) => unit.UNIT"
                        :option-value="(unit) => unit.UNIT"
                        v-model="accountItem.UNIT"
                        id-prefix="unit" is-horizontal
                        class="mb-1" select-class="form-select-sm w-50"
                        :disabled="disableControl" :errors="errors.UNIT"
                        @change="focusNextInput('descriptionFormInput')"
                        @keydown-enter="focusNextInput('descriptionFormInput')"/>
                    <FormTextarea label="DESCRIPTION:"
                        id-prefix="description"
                        v-model="accountItem.DESCRIPTION"
                        input-class="form-control-sm w-100" is-horizontal
                        class="mb-1" :disabled="disableControl"
                        :errors="errors.DESCRIPTION"/>
                </div>
            </div>
        </div>
        <template #footer>
            <div class="d-flex justify-content-center">
                <button
                    class="btn btn-primary w-25 m-1"
                    @click="handleResetAccountItem"
                    :disabled="disableControl"
                >RESET</button>
                <button class="btn btn-primary w-25 m-1"
                    :hidden="accountItem.id !== null"
                    :disabled="disableControl"
                    @click="handleInsertAccountItem">INSERT</button>
                <button class="btn btn-primary w-25 m-1"
                    :hidden="accountItem.id === null"
                    :disabled="disableControl"
                    @click="handleUpdateAccountItem">UPDATE</button>
            </div>
        </template>
    </Card>
</template>

<script setup>
import { ref, watch, onMounted } from "vue";

import Card from "../../../utils/Card.vue";
import FormInput from "../../../utils/FormInput.vue";
import FormSelect from "../../../utils/FormSelect.vue";
import FormTextarea from "../../../utils/FormTextarea.vue";

import axios from "axios";

import focusNextInput from "../../../../utils/focusnext";
import scrollToTop from "../../../../utils/scrollToTop";

const props = defineProps({
    account: Object,
});

const emit = defineEmits(['success', 'error', 'accountItemSelected']);

const errors = ref({});

const accountItemList = ref([]);
const unitList = ref([]);
const accountItem = ref({
    id: null,
    ITEM_NAME: null,
    UNIT: null,
    DESCRIPTION: null,
});
const disableControl = ref(true);

onMounted(() => {
    getUnitList();
});

async function getUnitList() {
    try {
        const response = await axios.get(route('api.units.index'));
        unitList.value = response.data.data;
    } catch(e) {
        console.log(e);
        scrollToTop();
        emit('error', 'An error occurred while fetching units. ' +
            'Please contact your administrator.');
    }
}

watch(() => props.account.ACCOUNT_ID, async() => {
    disableControls(props.account.ACCOUNT_ID);
    handleResetAccountItem();
    getAccountItemList();
});

watch(accountItem, () => {
    emit('accountItemSelected', accountItem.value);
})

async function getAccountItemList() {
    if (props.account.ACCOUNT_ID === 'New Emp') {
        accountItemList.value = [];
        handleResetAccountItem();
        return;
    }

    try {
        const response = await axios.get(route('api.account-items.index'), {
            params: {
                ACCOUNT_ID: props.account.ACCOUNT_ID
            }
        });
        accountItemList.value = response.data.data;
    } catch(e) {
        console.log(e);
        scrollToTop();
        emit('error', 'An error occurred while fetching account items. ' +
            'Please contact your administrator.');
    }
}

function selectAccountItem(item) {
    accountItem.value = {
        id: item.id,
        ITEM_NAME: item.ITEM_NAME,
        UNIT: item.UNIT,
        DESCRIPTION: item.DESCRIPTION,
    }
}

function handleResetAccountItem() {
    accountItem.value = {
        id: null,
        ITEM_NAME: null,
        UNIT: null,
        DESCRIPTION: null,
    }
}

async function handleInsertAccountItem() {
    try {
        const response = await axios.post(route('api.account-items.store'), {
            ACCOUNT_ID: props.account.ACCOUNT_ID,
            ITEM_NAME: accountItem.value.ITEM_NAME,
            UNIT: accountItem.value.UNIT,
            DESCRIPTION: accountItem.value.DESCRIPTION,
        })
        errors.value = {};
        scrollToTop();
        emit('success', response.data);
        getAccountItemList();
        handleResetAccountItem();
    } catch (e) {
        console.log(e);
        if(e.response && e.response.status == 422) {
            errors.value = e.response.data.errors;
        } else {
            scrollToTop();
            emit('error', `An error occurred while saving account item. Please contact your administrator.`);
        }
    }
}

async function handleUpdateAccountItem() {
    try {
        const response = await axios.patch(route('api.account-items.update', { id: accountItem.value.id }), {
            UNIT: accountItem.value.UNIT,
            DESCRIPTION: accountItem.value.DESCRIPTION,
        })
        errors.value = {};
        scrollToTop();
        emit('success', response.data);
        getAccountItemList();
    } catch (e) {
        console.log(e);
        if(e.response && e.response.status == 422) {
            errors.value = e.response.data.errors;
        } else {
            scrollToTop();
            emit('error', `An error occurred while saving account item. Please contact your administrator.`);
        }
    }
}

function disableControls(id) {
    if(id === 'New Emp')
        disableControl.value = true;
    else
        disableControl.value = false;
}
</script>
