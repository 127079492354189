export function useCommentTypes() {
    // Done as a composable type for the future prospect of transforming this as a fetch
    const commentTypes = [
        'Initial Inventory',
        'Delivery',
        'Pull-out',
        'Sales',
        'Reconciliation',
        'New Stock',
        'Return',
        'Replacement'
    ];
    
    return { commentTypes };
}
