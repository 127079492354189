<template>
    <div class="row g-3">
        <div class="col-md-4">
            <Card>
                <template #header>
                    Select Location
                </template>
                <LocationTable :hide-properties="new Set(['TYPE', 'PRICE_TYPE'])"
                    v-model="selectedLocation"
                    :refresh-flag="tableRefreshFlag" />
            </Card>
        </div>
        <div class="col-md-8">
            <Card>
                <template #header>
                    Update Details
                </template>
                <FormInput type="text" v-model="location.LOCATION_KEY2"
                    label="Location Key2" id-prefix="locationKey2"
                    class="mb-3" />
                <FormInput type="text" v-model="location.LOCATION_KEY3"
                    label="Location Key3" id-prefix="locationKey3"
                    class="mb-3" />
                <FormInput type="text" v-model="location.COMPANY_NAME"
                    label="Company Name" id-prefix="companyName"
                    class="mb-3" />
                <FormInput type="text" v-model="location.OFFICE_ADDRESS"
                    label="Office Address" id-prefix="officeAddress"
                    class="mb-3" />
                <FormInput type="text" v-model="location.STORE_CLASS"
                    label="Store Class" id-prefix="storeClass"
                    class="mb-3" />
                <FormInput type="text" v-model="location.STORE_CODE"
                    label="Store Code" id-prefix="storeCode" />
                <template #footer>
                    <div class="text-end">
                        <button type="button" class="btn btn-primary"
                            :disabled="!selectedLocation || isUpdating"
                            @click="saveLocation">
                            <span v-if="isUpdating" class="spinner-border spinner-border-sm" role="status"
                                aria-hidden="true"></span>
                            Update
                        </button>
                    </div>
                </template>
            </Card>
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';

import { useFlashMessages } from '@/composables/flashmessages';
import { useLocations } from '@/composables/data/locations';

import Card from '@/components/utils/Card.vue';
import FormInput from '@/components/utils/FormInput.vue';
import LocationTable from '@/components/utils/tables/LocationTable.vue';

import scrollToTop from '@/utils/scrollToTop';

const { addFlashMessage } = useFlashMessages();

const { updateLocation } = useLocations();

const tableRefreshFlag = ref(false);

const selectedLocation = ref(null);
const location = ref({
    LOCATION_KEY2: null,
    LOCATION_KEY3: null,
    COMPANY_NAME: null,
    OFFICE_ADDRESS: null,
    STORE_CLASS: null,
    STORE_CODE: null,
});

const isUpdating = ref(false);

watch(selectedLocation, () => {
    location.value = {
        LOCATION: selectedLocation.value?.LOCATION,
        LOCATION_KEY2: selectedLocation.value?.LOCATION_KEY2,
        LOCATION_KEY3: selectedLocation.value?.LOCATION_KEY3,
        COMPANY_NAME: selectedLocation.value?.COMPANY_NAME,
        OFFICE_ADDRESS: selectedLocation.value?.OFFICE_ADDRESS,
        STORE_CLASS: selectedLocation.value?.STORE_CLASS,
        STORE_CODE: selectedLocation.value?.STORE_CODE,
    };
});

async function saveLocation() {
    isUpdating.value = true;
    try {
        await updateLocation(location.value.LOCATION, location.value);
        addFlashMessage('SUCCESS', 'Successfully updated location.');
        tableRefreshFlag.value = !tableRefreshFlag.value;
    } catch(e) {
        addFlashMessage('ERROR', e.message);
        scrollToTop();
    }
    isUpdating.value = false;
}
</script>
