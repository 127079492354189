import { ref } from "vue";

import axios from 'axios';

import constructFetchError from "./constructFetchError";

export function useSampleImages() {
    const sampleImages = ref(null);

    const getSampleImages = async (sampleId) => {
        try {
            const getSampleImagesResponse = await axios.get(route('api.samples.sample-images.index', {
                sample: sampleId
            }));
            sampleImages.value = getSampleImagesResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching sample images.');
        }
    };

    const postSampleImage = async (sampleId, formData) => {
        try {
            return await axios.post(route('api.samples.sample-images.store', {
                sample: sampleId
            }), formData);
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while updating sample image.');
        }
    };

    const deleteSampleImage = async (sampleId, sampleImageId) => {
        try {
            return await axios.delete(route('api.samples.sample-images.destroy', {
                sample: sampleId,
                sample_image: sampleImageId
            }));
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while deleting sample image.');
        }
    };
    
    return { sampleImages, getSampleImages, postSampleImage, deleteSampleImage };
}
