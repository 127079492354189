import { ref } from "vue";

import constructFetchError from "./constructFetchError";

export function useInventoryNoBarcodes() {
    const inventoryNoBarcodes = ref(null);
    const inventoryNoBarcodesTotalRecords = ref(null);

    const getInventoryNoBarcodes = async (params) => {
        try {
            const inventoryNoBarcodesResponse = await axios.get(route('api.inventory-no-barcodes.index', params));
            inventoryNoBarcodes.value = inventoryNoBarcodesResponse.data.data;
            inventoryNoBarcodesTotalRecords.value = inventoryNoBarcodesResponse.data.meta.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching no barcode items.');
        }
    };

    const getInventoryNoBarcodesInLocation = async (location, params) => {
        try {
            const inventoryNoBarcodesResponse = await axios.get(route('api.inventory-no-barcodes.from-location.index', {
                ...params,
                location
            }));
            inventoryNoBarcodes.value = inventoryNoBarcodesResponse.data.data;
            inventoryNoBarcodesTotalRecords.value = inventoryNoBarcodesResponse.data.meta.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching no barcode items.');
        }
    };
    
    return { inventoryNoBarcodes, inventoryNoBarcodesTotalRecords,
        getInventoryNoBarcodes, getInventoryNoBarcodesInLocation };
}
