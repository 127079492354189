<template>
    <Card :class="class">
        <template #header>
            Details
        </template>
        <div class="row g-3 mb-3">
            <div class="col-md-4">
                <FormInput type="number" v-model="sampleId"
                    label="Sample ID" id-prefix="sampleId" disabled />
            </div>
            <div class="col-md-4">
                <FormInput type="number" step=".01" v-model="sampleQuantity"
                    label="Sample Qty." id-prefix="totalQuantity" />
            </div>
            <div class="col-md-4">
                <FormInput type="number" step=".01" v-model="detailsTotalPrice"
                    label="Total Price" id-prefix="totalPrice" disabled />
            </div>
            <div class="col-md-6">
                <FormInput type="text" v-model="sampleDescription"
                    label="Description" id-prefix="description" disabled />
            </div>
        </div>
        <DataTable :value="sampleDetailsWithMaterialDetails" paginator :rows="10"
            v-model:selection="selectedSampleDetail" selection-mode="single" data-key="id"
            @row-select="onRowSelect"
            :row-class="data => [{'bg-primary text-white': data.id == selectedSampleDetail?.id}]"
            :pt="{ table: { class: ' table table-bordered table-hover' } }">
            <Column field="MATERIAL" header="Material" />
            <Column field="COLOR" header="Color" />
            <Column field="QTY" header="Unit Qty.">
                <template #body="slotProps">
                    {{ slotProps.data.QTY.toFixed(2) }}
                </template>
            </Column>
            <Column field="TOTAL_QTY" header="Total Qty.">
                <template #body="slotProps">
                    {{ slotProps.data.TOTAL_QTY.toFixed(2) }}
                </template>
            </Column>
            <Column field="UNIT" header="Unit" />
            <Column field="UNIT_PRICE" header="Unit Price">
                <template #body="slotProps">
                    {{ slotProps.data.UNIT_PRICE.toFixed(2) }}
                </template>
            </Column>
            <Column field="TOTAL_PRICE" header="Total Price">
                <template #body="slotProps">
                    {{ slotProps.data.TOTAL_PRICE.toFixed(2) }}
                </template>
            </Column>
            <Column field="CREATED_BY" header="Created By" />
        </DataTable>
    </Card>
</template>

<script setup>
import { computed, ref, watch } from 'vue';

import { useFlashMessages } from '@/composables/flashmessages';

import { useSamples } from '@/composables/data/samples';

import Card from '@/components/utils/Card.vue';
import FormInput from '@/components/utils/FormInput.vue';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';

const { addFlashMessage } = useFlashMessages();

const props = defineProps({
    sampleId: Number,
    sampleQuantity: Number,
    selectedSampleDetail: Object,
    refreshFlag: Number,
    class: String
});

const emit = defineEmits(['sample-quantity-change']);

const { sample, getSample } = useSamples();

const sampleId = computed(() => sample.value?.SAMPLE_ID);
const sampleDescription = computed(() => sample.value?.DESCRIPTION);
const sampleQuantity = ref(100);

const sampleDetailsWithMaterialDetails = computed(() =>
    sample.value?.details?.filter(sampleDetail => sampleDetail.material != null).map(sampleDetail => ({
        ...sampleDetail,
        UNIT: sampleDetail.material.UNIT,
        TOTAL_QTY: sampleDetail.QTY * (sampleQuantity.value ?? 0),
        UNIT_PRICE: sampleDetail.material.PRICE,
        TOTAL_PRICE: sampleDetail.material.PRICE * sampleDetail.QTY * (sampleQuantity.value ?? 0)
    }))
);

const detailsTotalPrice = computed(() =>
    sampleDetailsWithMaterialDetails.value?.reduce((totalPrice, sampleDetail) =>
        totalPrice + sampleDetail.TOTAL_PRICE, 0).toFixed(2) ?? 0
);

const selectedSampleDetail = ref(null);

async function fetchSample() {
    if(!props.sampleId)
        return;

    try {
        await getSample(props.sampleId, {
            include_relations: 'details.material'
        });
    } catch(e) {
        addFlashMessage('ERROR', e.message);
    }
}

watch(() => props.sampleId, fetchSample);
watch(() => props.refreshFlag, fetchSample);

watch(() => props.sampleQuantity, () => {
    sampleQuantity.value = props.sampleQuantity;
});

watch(sampleQuantity, () => {
    if(!sampleQuantity.value)
        sampleQuantity.value = 0;

    emit('sample-quantity-change', sampleQuantity.value);
});

watch(() => props.selectedSampleDetail, () => {
    selectedSampleDetail.value = props.selectedSampleDetail;
});

function onRowSelect() {
    emit('select', selectedSampleDetail.value);
}
</script>
