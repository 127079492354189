<template>
    <div class="row justify-content-center">
        <div class="col-sm-6 col-md-4">
            <form @submit.prevent="mappingOnSubmit">
                <Card>
                    <template #header>
                        Employee SM Mapping
                    </template>
                    <div class="mb-3">
                        <label for="kimbelIDInput" class="form-label">Kimbel Employee ID</label>
                        <input type="text" :value="employee?.EMPID" disabled
                            id="kimbelIDInput" class="form-control mb-1"
                            :class="{'is-invalid': errors.KIMDB_EMPID}" />
                        <p v-if="employee" class="form-text">
                            {{ employee.FNAME }} {{ employee.LNAME }}
                        </p>
                        <div class="text-end mb-1">
                            <button type="button" class="btn btn-sm btn-primary"
                                @click="_ => { isSelectEmployeePopupOpen = true; }">
                                Select
                            </button>
                        </div>
                        <div v-if="errors.KIMDB_EMPID" class="invalid-feedback">
                            <p v-for="error in errors.KIMDB_EMPID">{{ error }}</p>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="smIDInput" class="form-label">SM ID</label>
                        <div v-if="isLoading">
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            Fetching existing mapping...
                        </div>
                        <input type="text" v-model="nidekaEmpid"
                            id="smIDInput" class="form-control"
                            :class="{'is-invalid': errors.NIDEKA_EMPID}"
                            :disabled="isLoading" />
                        <div v-if="errors.NIDEKA_EMPID" class="invalid-feedback">
                            <p v-for="error in errors.NIDEKA_EMPID">{{ error }}</p>
                        </div>
                    </div>
                    <template #footer>
                        <div class="text-end">
                            <button type="button" class="btn btn-outline-secondary me-2"
                                @click="clearOnClick">Clear</button>
                            <button type="submit" class="btn btn-primary">Save</button>
                        </div>
                    </template>
                </Card>
            </form>
        </div>
    </div>
    <SelectEmployee :is-open="isSelectEmployeePopupOpen"
        :on-close="_ => { isSelectEmployeePopupOpen = false; }"
        :on-select-employee="selectedEmployee => {
            employee = selectedEmployee;
            kimbelIDInput = selectedEmployee.EMPID;
            isSelectEmployeePopupOpen = false;
        }" />
</template>

<script setup>
import { ref, watch } from "vue";

import { useFlashMessages } from "@/composables/flashmessages";
import { useSMMappings } from "@/composables/data/employeeMapSM";

import Card from "@/components/utils/Card.vue";
import SelectEmployee from "@/components/utils/SelectEmployee.vue";

import scrollToTop from "@/utils/scrollToTop";

const { addFlashMessage } = useFlashMessages();

const { mapping, getSMMapping, postSMMapping } = useSMMappings();

const errors = ref({});

const employee = ref(null);
const nidekaEmpid = ref(null);

const isSelectEmployeePopupOpen = ref(false);

const isLoading = ref(false);

const fetchTimeout = ref(null);

function clearOnClick() {
    employee.value = null;
    nidekaEmpid.value = null;
    errors.value = {};
}

async function fetchCurrentMapping() {
    isLoading.value = true;
    try {
        await getSMMapping(employee.value.EMPID);
        if(mapping.value) {
            nidekaEmpid.value = mapping.value.NIDEKA_EMPID;
        }
    } catch(e) {
        addFlashMessage('ERROR', e.message);
        scrollToTop();
    }
    isLoading.value = false;
}

watch(employee, () => {
    nidekaEmpid.value = null;
    if(fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }

    if(employee.value) {
        fetchTimeout.value = setTimeout(fetchCurrentMapping, 300);
    }
});

async function mappingOnSubmit() {
    errors.value = {};
    try {
        await postSMMapping(employee.value.EMPID, nidekaEmpid.value);
        addFlashMessage('SUCCESS', `Successfully mapped employee ${employee.value.EMPID} -
            ${employee.value.FNAME} ${employee.value.LNAME}.`);
    } catch(e) {
        if(e.status == 422) {
            errors.value = e.errors ?? {};
        }

        addFlashMessage('ERROR', e.message);
        scrollToTop();
    }
    scrollToTop();
}
</script>
