import { ref } from "vue";

import constructFetchError from "./constructFetchError";

import axios from 'axios';

export function useInventoryCheckCounts() {
    const inventoryCheckCounts = ref(null);
    const duplicateInventoryCheckCountsTotalRecords = ref(null);
    const duplicateInventoryCheckCounts = ref(null);

    const getInventoryCheckCounts = async (location) => {
        try {
            const getInventoryCheckCountsResponse = await axios.get(route('api.inventory-checks.counts-by-location.index', {
                LOCATION: location
            }));
            inventoryCheckCounts.value = getInventoryCheckCountsResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching inventory check counts.');
        }
    };

    const getDuplicateInventoryCheckCounts = async (params) => {
        try {
            const getDuplicateInventoryCheckCountsResponse = await axios.get(route('api.inventory-checks.duplicates.index', params));
            duplicateInventoryCheckCounts.value = getDuplicateInventoryCheckCountsResponse.data.data;
            duplicateInventoryCheckCountsTotalRecords.value = getDuplicateInventoryCheckCountsResponse.data.meta.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching duplicate inventory check counts.');
        }
    };

    const getInventoryCheckTotals = async (location, inventory_done) => {
        try {
            const getInventoryCheckTotalsResponse = await axios.get(route('api.inventory-checks.totals.index', {
                LOCATION: location,
                INVENTORY_DONE: inventory_done
            }));
            return getInventoryCheckTotalsResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching inventory check totals.');
        }
    };
    
    return { inventoryCheckCounts, getInventoryCheckCounts, getInventoryCheckTotals,
        duplicateInventoryCheckCounts, duplicateInventoryCheckCountsTotalRecords, getDuplicateInventoryCheckCounts };
}
