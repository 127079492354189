<template>
    <div v-if="isOpen" class="pop-up d-flex justify-content-center align-items-center">
        <Card>
            <template #header>
                <div class="d-flex justify-content-between pt-2">
                    <h4 class="title">Select Material</h4>
                    <button type="button" class="btn-close" @click="emit('close')"></button>
                </div>
            </template>
            <DataTable lazy paginator :rows="10" :value="materials" :total-records="totalRecords"
                filter-display="row" v-model:filters="filters"
                v-model:selection="selectedMaterial" selection-mode="single" data-key="id"
                @page="e => { onPage(e); fetchMaterials(); }"
                @filter="e => { onFilter(e); fetchMaterials(); }"
                :row-class="data => [{'bg-primary text-white': data.id == selectedMaterial?.id}]"
                :pt="{ table: { class: 'table table-bordered table-hover' } }">
                <Column field="id" header="ID" :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText type="text" v-model="filterModel.value" @keydown="filterCallback()"
                            class="form-control" placeholder="Search" />
                        <Button type="button" class="btn btn-primary" @click="filterCallback()">
                            Search
                        </Button>
                    </template>
                </Column>
                <Column field="MATERIAL" header="Material" :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText type="text" v-model="filterModel.value" @keydown="filterCallback()"
                            class="form-control" placeholder="Search" />
                        <Button type="button" class="btn btn-primary" @click="filterCallback()">
                            Search
                        </Button>
                    </template>
                </Column>
                <Column field="COLOR" header="Color" :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText type="text" v-model="filterModel.value" @keydown="filterCallback()"
                            class="form-control" placeholder="Search" />
                        <Button type="button" class="btn btn-primary" @click="filterCallback()">
                            Search
                        </Button>
                    </template>
                </Column>
                <Column field="UNIT" header="Unit" :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText type="text" v-model="filterModel.value" @keydown="filterCallback()"
                            class="form-control" placeholder="Search" />
                        <Button type="button" class="btn btn-primary" @click="filterCallback()">
                            Search
                        </Button>
                    </template>
                </Column>
                <Column field="PRICE" header="Price" :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText type="text" v-model="filterModel.value" @keydown="filterCallback()"
                            class="form-control" placeholder="Search" />
                        <Button type="button" class="btn btn-primary" @click="filterCallback()">
                            Search
                        </Button>
                    </template>
                </Column>
                <Column field="CREATED_BY" header="Created By" :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText type="text" v-model="filterModel.value" @keydown="filterCallback()"
                            class="form-control" placeholder="Search" />
                        <Button type="button" class="btn btn-primary" @click="filterCallback()">
                            Search
                        </Button>
                    </template>
                </Column>
                <template #empty>No materials.</template>
            </DataTable>
            <template #footer>
                <div class="d-grid">
                    <button type="button" class="btn btn-primary "
                        :disabled="!selectedMaterial"
                        @click="_ => { emit('select', selectedMaterial); }">
                        Select Material
                    </button>
                </div>
            </template>
        </Card>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';

import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useMaterials } from '@/composables/data/materials';

import Card from "@/components/utils/Card.vue";

import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

const props = defineProps({
    isOpen: Boolean
});

const emit = defineEmits(['select']);

const selectedMaterial = ref(null);

const { params, filters, onPage, onFilter } = useDataTableParams(
    ['id', 'MATERIAL', 'COLOR', 'UNIT', 'PRICE', 'CREATED_BY']);

const { materials, totalRecords, getMaterials } = useMaterials();

async function fetchMaterials() {
    await getMaterials(params.value);
}

onMounted(fetchMaterials);
</script>

<style scoped>
.pop-up {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: rgb(0, 0, 0, 0.5);
}

.pop-up .card {
    width: 100%;
}

@media (min-width: 768px) {
    .pop-up .card {
        width: 85%;
    }
}

@media (min-width: 992px) {
    .pop-up .card {
        width: 70%;
    }
}
</style>
