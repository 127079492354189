<template>
    <div class="row mb-3">
        <div class="col-md-4">
            <form @submit.prevent="search">
                <Card>
                    <template #header>
                        Update Inventory Check
                    </template>
                    <FormInput type="text" v-model="barcodeInput"
                        label="Raw" id-prefix="raw"
                        @keydown-enter="search" />
                    <template #footer>
                        <button type="submit" class="btn btn-primary me-2">
                            Search
                        </button>
                    </template>
                </Card>
            </form>
        </div>
    </div>
    <BarcodeInventoryChecksTableCard :barcode="barcodeToCheck"
        @success="message => {
            addFlashMessage('SUCCESS', message);
            scrollToTop();
        }"
        @error="message => {
            addFlashMessage('ERROR', message);
            scrollToTop();
        }" />
</template>

<script setup>
import { ref } from 'vue';

import { useFlashMessages } from '@/composables/flashmessages';

import BarcodeInventoryChecksTableCard from './BarcodeInventoryChecksTableCard.vue';

import Card from '@/components/utils/Card.vue';
import FormInput from '@/components/utils/FormInput.vue';

import scrollToTop from '@/utils/scrollToTop';

const { addFlashMessage } = useFlashMessages();

const barcodeInput = ref(null);

const barcodeToCheck = ref(null);

function search() {
    barcodeToCheck.value = barcodeInput.value;
}
</script>
