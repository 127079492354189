<template>
    <div class="row g-3">
        <div class="col-md-4">
            <Card class="mb-3">
                <template #header>
                    Select Time Period
                </template>
                <FormSelect v-model="paytype"
                    :options="['BIMONTHLY', 'WEEKLY']"
                    label="Select Paytype" id-prefix="paytype" />
            </Card>
            <TimePeriodSelectTableCard v-model="timePeriod"
                :show-only-type="paytype"
                @error="message => {
                    addFlashMessage('ERROR', message);
                    scrollToTop();
                }"
            />
        </div>
        <div class="col-md-8">
            <PayslipsInTimePeriodTableCard :time-period="timePeriod?.TIME_PERIOD" />
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';

import { useFlashMessages } from '@/composables/flashmessages';

import Card from '@/components/utils/Card';
import FormSelect from '@/components/utils/FormSelect';

import TimePeriodSelectTableCard from '@/components/utils/TimePeriodSelectTableCard.vue';
import PayslipsInTimePeriodTableCard from './PayslipsInTimePeriodTableCard.vue';

import scrollToTop from '@/utils/scrollToTop';

const { addFlashMessage } = useFlashMessages();

const paytype = ref(null);
const timePeriod = ref(null);
</script>
