import { ref } from 'vue';

import { FilterMatchMode } from 'primevue/api';

export function useDataTableParams(fields, additionalParams, additionalFilters) {
    const filters = ref(
        {
            ...fields.reduce((filters, field) => ({
                ...filters,
                [field]: { value: null, matchMode: FilterMatchMode.CONTAINS }
            }), {}),
            ...additionalFilters
        }
    );

    const params = ref({
        first: 0,
        page: 0,
        rows: 10,
        sortField: null,
        sortOrder: null,
        filters: filters.value,
        ...additionalParams
    });

    const onPage = event => {
        params.value = {
            ...params.value,
            ...event,
            filters: filters.value,
        };
    };

    const onFilter = _ => {
        params.value = {
            ...params.value,
            filters: filters.value,
            first: 0,
            page: 0,
        };
    };

    const onSort = e => {
        params.value = {
            ...params.value,
            ...e,
            first: 0,
            page: 0,
        };
    };

    return { params, filters, onPage, onFilter, onSort };
}
