<style scoped>
.wrapper {
    width: 100%;
    height: 15rem;
    overflow-y: auto;
    background-color: #FFF;
}
</style>

<template>
    <div id="topOfPage"></div>
    <FlashMessageAlerts :messages="flashMessages" :dismissible="true" class="mb-3" />
    <Card>
        <div class="row g-3 mb-3">
            <div class="col-md-12">
                <Card>
                    <div class="row">
                        <FormInput is-horizontal label="ACCOUNT ID:"
                            class="mb-1 col-md-6" v-model="account.ACCOUNT_ID"
                            input-class="form-control-sm" id-prefix="accountId"
                            disabled type="text"/>
                        <div class="col-md-3 text-end mb-1 p-0">
                            <button @click="openSelectAccount"
                                class="btn btn-primary w-75">SELECT
                            </button>
                        </div>
                        <div class="col-md-3 text-start mb-1 ms-1 p-0">
                            <button @click="handleResetAccount"
                                class="btn btn-primary w-75">RESET</button>
                        </div>
                        <FormSelect label="ACCOUNT TYPE" v-model="account.ACCOUNT_TYPE"
                            id-prefix="accountType" is-horizontal :options="accounttypeList"
                            :option-to-string="(accountType) => accountType.ACCOUNT_TYPE"
                            :option-value="(accountType) => accountType.ACCOUNT_TYPE"
                            class="mb-1 col-md-6" select-class="form-select-sm"
                            @change="() => {
                                focusNextInput('accountNameFormInput');
                                errors.ACCOUNT_TYPE = '';
                            }"
                            :errors="errors.ACCOUNT_TYPE"/>
                        <div class="col-md-3 text-end mb-1 p-0">
                            <button class="btn btn-primary w-75"
                                @click="handleInsertAccount"
                                :disabled="account.ACCOUNT_ID !== 'New Emp'">INSERT
                            </button>
                        </div>
                        <div class="col-md-3 text-start mb-1 ms-1 p-0">
                            <button class="btn btn-primary w-75"
                                @click="handleUpdateAccount"
                                :disabled="account.ACCOUNT_ID === 'New Emp'">UPDATE
                            </button>
                        </div>
                        <FormInput is-horizontal label="ACCOUNT NAME:" class="mb-1 col-md-6"
                            input-class="form-control-sm" id-prefix="accountName"
                            v-model="account.ACCOUNT_NAME"  type="text"
                            @keydown-enter="focusNextInput('accountDescriptionFormInput')"
                            :errors="errors.ACCOUNT_NAME"/>
                        <FormInput is-horizontal label="ACCOUNT DESCRIPTION:"
                            class="mb-1 col-md-11" v-model="account.ACCOUNT_DESCRIPTION" type="text"
                            input-class="form-control-sm" id-prefix="accountDescription"
                            @keydown-enter="focusNextInput('accountAddressFormInput')"
                            :errors="errors.ACCOUNT_DESCRIPTION"/>
                        <FormInput is-horizontal label="ACCOUNT ADDRESS:"
                            class="mb-1 col-md-11" v-model="account.ACCOUNT_ADDRESS"
                            input-class="form-control-sm" id-prefix="accountAddress"
                            type="text" @keydown-enter="focusNextInput('accountContactFormInput')"
                            :errors="errors.ACCOUNT_ADDRESS"/>
                        <FormInput is-horizontal label="ACCOUNT CONTACT:"
                            class="mb-1 col-md-11" v-model="account.ACCOUNT_CONTACT"
                            input-class="form-control-sm" id-prefix="accountContact"
                            type="text" @keydown-enter="focusNextInput('accountTitleFormInput')"
                            :errors="errors.ACCOUNT_CONTACT"/>
                        <FormInput is-horizontal label="ACCOUNT TITLE:"
                            class="mb-1 col-md-11" v-model="account.ACCOUNT_TITLE"
                            input-class="form-control-sm" id-prefix="accountTitle"
                            type="text" @keydown-enter="focusNextInput('accountPhoneFormInput')"
                            :errors="errors.ACCOUNT_TITLE"/>
                        <FormInput is-horizontal label="ACCOUNT PHONE:"
                            class="mb-1 col-md-11" v-model="account.ACCOUNT_PHONE"
                            input-class="form-control-sm" id-prefix="accountPhone"
                            type="text" @keydown-enter="focusNextInput('accountMemoFormInput')"
                            :errors="errors.ACCOUNT_PHONE"/>
                        <FormInput is-horizontal label="ACCOUNT MEMO:"
                            class="mb-1 col-md-11" v-model="account.ACCOUNT_MEMO"
                            input-class="form-control-sm" id-prefix="accountMemo"
                            type="text" :errors="errors.ACCOUNT_MEMO"/>
                        <FormInput is-horizontal label="ACCOUNT GL:"
                            class="mb-1 col-md-11" v-model="account.ACCOUNT_GL"
                            input-class="form-control-sm w-50" id-prefix="accountMemo"
                            type="text" disabled :errors="errors.ACCOUNT_GL"/>
                        <p class="col-md-12 text-center">*If needed, to update ACCOUNT_GL, ask James*</p>
                        <FormInput is-horizontal label="CREATED BY:"
                            class="mb-1 col-md-11" v-model="account.CREATED_BY"
                            input-class="form-control-sm" id-prefix="accountCreatedBy"
                            type="text" disabled :errors="errors.CREATED_BY"/>
                    </div>
                </Card>
            </div>
            <div class="col-md-12">
                <AccountItemCard :account="account"
                    @success="message => {
                        addFlashMessage('SUCCESS', message);
                    }"
                    @error="message => {
                        addFlashMessage('ERROR', message);
                    }"
                    @accountItemSelected="handleAccountItem"/>
            </div>
            <div class="col-md-12">
                <AccountItemPriceCard
                    :item="accountItem"
                    :account="account"
                    @success="message => {
                        addFlashMessage('SUCCESS', message);
                    }"
                    @error="message => {
                        addFlashMessage('ERROR', message);
                    }"/>
            </div>
        </div>
    </Card>
    <SelectAccount
        :isOpen="isSelectAccountOpen"
        :onClose="() => isSelectAccountOpen = false"
        :onSelectAccount="handleSelectedAccount"
        :preventNone="true"/>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";

import FlashMessageAlerts from "../../../utils/FlashMessageAlerts.vue";

import focusNextInput from "../../../../utils/focusnext.js"
import scrollToTop from "../../../../utils/scrollToTop";

import Card from "../../../utils/Card.vue"
import FormInput from "../../../utils/FormInput.vue"
import FormSelect from "../../../utils/FormSelect.vue";
import SelectAccount from "../../../utils/SelectAccount.vue";

import AccountItemCard from "./AccountItemCard.vue";
import AccountItemPriceCard from "./AccountItemPriceCard.vue";

const account = ref({
    ACCOUNT_ID: "New Emp",
    ACCOUNT_TYPE: null,
    ACCOUNT_NAME: null,
    ACCOUNT_DESCRIPTION: null,
    ACCOUNT_ADDRESS: null,
    ACCOUNT_CONTACT: null,
    ACCOUNT_TITLE: null,
    ACCOUNT_PHONE: null,
    CREATED_BY: null,
    ACCOUNT_MEMO: null,
    ACCOUNT_GL: 0,
});

const accountItem = ref({});

const accounttypeList = ref([]);

const flashMessages = ref([]);
const errors = ref({});
const isSelectAccountOpen = ref(false);

onMounted(() => {
    getAccountTypes();
    getUser();
})

async function getAccountTypes() {
    try {
        const response = await axios.get(route('api.account-types.index'));
        accounttypeList.value = response.data.data;
    } catch (e) {
        console.log(e)
        scrollToTop();
        addFlashMessage('ERROR', `An error occurred while fetching account types. Please contact your administrator.`);
    }
}

async function getUser() {
    try {
        const response = await axios.get(route('api.loginuser.index'));
        account.value.CREATED_BY = response.data.data.name_from_email;
    } catch (e) {
      console.log(e)
    }
}

function openSelectAccount() {
    isSelectAccountOpen.value = true;
}

function handleSelectedAccount(selectedAccount) {
    errors.value = {};
    account.value = selectedAccount;
}

async function handleInsertAccount() {
    try {
        const response = await axios.post(route('api.accounts.store'), {
            ACCOUNT_TYPE: account.value.ACCOUNT_TYPE,
            ACCOUNT_NAME: account.value.ACCOUNT_NAME,
            ACCOUNT_DESCRIPTION: account.value.ACCOUNT_DESCRIPTION,
            ACCOUNT_ADDRESS: account.value.ACCOUNT_ADDRESS,
            ACCOUNT_CONTACT: account.value.ACCOUNT_CONTACT,
            ACCOUNT_TITLE: account.value.ACCOUNT_TITLE,
            ACCOUNT_PHONE: account.value.ACCOUNT_PHONE,
            ACCOUNT_MEMO: account.value.ACCOUNT_MEMO,
            ACCOUNT_GL: account.value.ACCOUNT_GL,
        })
        errors.value = {};
        scrollToTop();
        addFlashMessage('SUCCESS', response.data);
        handleResetAccount();
    } catch (e) {
        console.log(e);
        if(e.response && e.response.status == 422) {
            errors.value = e.response.data.errors;
        } else {
            scrollToTop();
            addFlashMessage('ERROR', `An error occurred while saving account. Please contact your administrator.`);
        }
    }
}

async function handleUpdateAccount() {
    try {
        const response = await axios.patch(route('api.accounts.update', { id: account.value.ACCOUNT_ID }), {
            ACCOUNT_TYPE: account.value.ACCOUNT_TYPE,
            ACCOUNT_NAME: account.value.ACCOUNT_NAME,
            ACCOUNT_DESCRIPTION: account.value.ACCOUNT_DESCRIPTION,
            ACCOUNT_ADDRESS: account.value.ACCOUNT_ADDRESS,
            ACCOUNT_CONTACT: account.value.ACCOUNT_CONTACT,
            ACCOUNT_TITLE: account.value.ACCOUNT_TITLE,
            ACCOUNT_PHONE: account.value.ACCOUNT_PHONE,
            ACCOUNT_MEMO: account.value.ACCOUNT_MEMO,
            ACCOUNT_GL: account.value.ACCOUNT_GL,
        })
        errors.value = {};
        scrollToTop();
        addFlashMessage('SUCCESS', response.data);
    } catch (e) {
        console.log(e);
        if(e.response && e.response.status == 422) {
            errors.value = e.response.data.errors;
        } else {
            scrollToTop();
            addFlashMessage('ERROR', `An error occurred while saving account. Please contact your administrator.`);
        }
    }
}

function handleResetAccount() {
    account.value = {
        ACCOUNT_ID: "New Emp",
        ACCOUNT_TYPE: null,
        ACCOUNT_NAME: null,
        ACCOUNT_DESCRIPTION: null,
        ACCOUNT_ADDRESS: null,
        ACCOUNT_CONTACT: null,
        ACCOUNT_TITLE: null,
        ACCOUNT_PHONE: null,
        ACCOUNT_MEMO: null,
        ACCOUNT_GL: 0,
    }
    getUser();
    errors.value = {};
}

function handleAccountItem(selectedItem) {
    accountItem.value = selectedItem;
}

function addFlashMessage(type, content) {
    flashMessages.value.push({ type, content });
}
</script>
