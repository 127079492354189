<style scoped>
.style-image {
    max-height: 300px;
    max-width: 100%;
}
</style>

<template>
    <form @submit.prevent="imageFileOnSubmit">
        <Card :class="class">
            <template #header>
                Image
            </template>
            <div v-if="style" class="mb-3">
                <span v-if="isImageLoading" class="spinner-border spinner-border-sm" role="status"
                    aria-hidden="true"></span>
                <img v-if="!isUploading" :src="imageURL" :alt="style?.STYLE + ' style image'"
                    class="style-image" @load="_ => { isImageLoading = false; }"
                    @error="setDefaultImage" />
            </div>
            <div class="mb-3">
                <label for="imageFileInput" class="form-label">Image</label>
                <input type="file" required ref="imageFileInput"
                    accept="image/png, image/gif, image/jpeg"
                    @change="imageFileOnChange" :errors="errors.image"
                    id="imageFileInput" class="form-control form-control-sm" />
            </div>
            <template #footer>
                <div class="text-end">
                    <button type="button" class="btn btn-outline-primary me-1"
                        @click="_ => { isBulkImageUploadPopupOpen = true; }">
                        Bulk Upload
                    </button>
                    <button type="submit" class="btn btn-primary"
                        :disabled="!style || isUploading">
                        <span v-if="isUploading" class="spinner-border spinner-border-sm" role="status"
                            aria-hidden="true"></span>
                        Save Image
                    </button>
                </div>
            </template>
        </Card>
    </form>

    <BulkImageUploadPopup :is-open="isBulkImageUploadPopupOpen"
        @close="_ => { isBulkImageUploadPopupOpen = false; }"
        @error="message => { emit('error', message); }" />
</template>

<script setup>
import { ref, watch } from 'vue';

import Card from '@/components/utils/Card.vue';

import BulkImageUploadPopup from './BulkImageUploadPopup.vue';

import handleFetchErrors from "@/utils/handleFetchErrors";
import scrollToTop from "@/utils/scrollToTop";

const props = defineProps({
    style: Object,
    class: String
});

const emit = defineEmits(['success', 'error']);

const imageURL = ref(null);

watch(() => props.style, loadStyleImage);

const errors = ref({});

const imageFileInput = ref(null);
const imageFile = ref();

const isUploading = ref(false);
const isImageLoading = ref(false);

const isBulkImageUploadPopupOpen = ref(false);

function imageFileOnChange(event) {
    const file = event.target.files[0];
    imageFile.value = file;
}

async function imageFileOnSubmit() {
    isUploading.value = true;
    try {
        const formData = new FormData();
        formData.append("image", imageFile.value);

        await axios.post(route('api.styles.image.store', {
            style: props.style.STYLE
        }), formData);
        emit('success', 'Successfully saved image.');

        imageFileInput.value.value = null;
        imageFile.value = null;
    } catch(e) {
        console.log(e);
        handleFetchErrors(e, 'Error while saving image.');
    }
    isUploading.value = false;
    scrollToTop();
    loadStyleImage();
}

async function loadStyleImage() {
    if(!props.style) {
        return;
    }

    try {
        isImageLoading.value = true;
        imageURL.value = (await axios.get(route('api.styles.image-url.show', {
            style: props.style
        }))).data.data;
    } catch(e) {
        isImageLoading.value = false;
        setDefaultImage();
        if(e.response.status != 404) {
            addFlashMessage('ERROR', e.message);
            scrollToTop();
        }
    }
}

function setDefaultImage() {
    const noImageURL = `${Ziggy.url}/img/bagnoimage.jpg`;
    if(imageURL.value != noImageURL) {
        isImageLoading.value = true;
        imageURL.value = `${Ziggy.url}/img/bagnoimage.jpg`;
    }
}
</script>
