<template>
    <form @submit.prevent="createLocationKey">
        <Card>
            <template #header>
                Create Location Key
            </template>
            <FormInput type="text" v-model="locationKey.LOCATION_KEY"
                label="Location Key" id-prefix="locationKey"
                :errors="errors.LOCATION_KEY"
                @keydown-enter="_ => { focusnext('createLocationKeyButton'); }" />
            <template #footer>
                <div class="text-end">
                    <button type="submit" class="btn btn-primary" id="createLocationKeyButton"
                        :disabled="!location || isCreating">
                        <span v-if="isCreating" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Create
                    </button>
                </div>
            </template>
        </Card>
    </form>
</template>

<script setup>
import { ref } from 'vue';

import { useLocationKeys } from '@/composables/data/locationKeys';

import Card from '@/components/utils/Card.vue';
import FormInput from '@/components/utils/FormInput.vue';

import focusnext from '@/utils/focusnext';

const props = defineProps({
    location: String,
});

const emit = defineEmits(['success', 'error']);

const locationKey = ref({
    LOCATION_KEY: null
});

const errors = ref({});

const { postLocationKey } = useLocationKeys();

const isCreating = ref(false);

async function createLocationKey() {
    errors.value = {};

    isCreating.value = true;
    try {
        await postLocationKey(props.location, locationKey.value);
        emit('success', 'Successfully saved location key.');
        locationKey.value = {
            LOCATION_KEY: null
        };
    } catch(e) {
        errors.value = e.errors ?? {};
        emit('error', e.message);
    }
    isCreating.value = false;
}
</script>
