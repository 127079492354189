import { ref } from "vue";

import axios from 'axios';

import constructFetchError from "./constructFetchError";

export function useVoucherStoreSummaries() {
    const voucherStoreSummary = ref(null);

    const getVoucherStoreSummary = async (companyCode, timePeriod) => {
        try {
            const getVoucherStoreSummaryResponse = await axios.get(route('api.voucher-store-summaries.show', {
                COMPANY_CODE: companyCode,
                TIME_PERIOD: timePeriod
            }));
            voucherStoreSummary.value = getVoucherStoreSummaryResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching store summary vouchers.');
        }
    };

    const postVoucherStoreSummary = async (companyCode, timePeriod, type, voucherId, locationFilter) => {
        try {
            const postVoucherStoreSummaryResponse = await axios.post(route('api.voucher-store-summaries.store', {
                COMPANY_CODE: companyCode,
                TIME_PERIOD: timePeriod,
                TYPE: type,
                VOUCHER_ID: voucherId,
                location_filter: locationFilter
            }));
            return postVoucherStoreSummaryResponse;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while using voucher for store summary.');
        }
    };

    return { voucherStoreSummary, getVoucherStoreSummary, postVoucherStoreSummary };
}
