<template>
    <div v-if="isOpen" class="pop-up d-flex justify-content-center align-items-center">
        <Card>
            <template #header>
                <div class="d-flex justify-content-between pt-2">
                    <h4 class="title">Select Recon Time Period</h4>
                    <button type="button" class="btn-close" @click="emit('close')"></button>
                </div>
            </template>
            <DataTable lazy :loading="isLoading" :value="reconParms"
                paginator :rows="10" @page="e => { onPage(e); fetchReconParms(); }"
                v-model:filters="filters" filter-display="row" @filter="tableOnFilter"
                sort-mode="multiple" @sort="e => { onSort(e); fetchReconParms(); }"
                selection-mode="single" v-model:selection="selectedReconParm"
                :pt="{ table: { class: 'table table-sm table-bordered table-hover' } }">
                <Column field="TIME_PERIOD" header="Time Period" sortable :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                            class="form-control" placeholder="Search" />
                    </template>
                </Column>
                <Column field="LOCATION" header="Location" sortable :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                            class="form-control" placeholder="Search" />
                    </template>
                </Column>
                <Column field="RECON_RAW_ADJ_START_DATE" header="Adj. Start Date" sortable :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                            class="form-control" placeholder="Search" />
                    </template>
                </Column>
                <Column field="RECON_RAW_ADJ_END_DATE" header="Adj. End Date" sortable :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                            class="form-control" placeholder="Search" />
                    </template>
                </Column>
                <Column field="RECON_FLAG" header="Recon Flag" sortable :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                            class="form-control" placeholder="Search" />
                    </template>
                </Column>
                <template #empty>
                    <div class="text-center">
                        No data yet.
                    </div>
                </template>
            </DataTable>
            <template #footer>
                <div class="d-grid">
                    <button type="button" class="btn btn-primary"
                        :disabled="!selectedReconParm"
                        @click="visitReconInvestigationForm">
                        Select
                    </button>
                </div>
            </template>
        </Card>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';

import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useReconParms } from '@/composables/data/reconParms';

import Card from "@/components/utils/Card.vue";

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

const props = defineProps({
    isOpen: Boolean,
    location: String,
});

const emit = defineEmits(['close', 'error']);

const { params, filters, onPage, onFilter, onSort } = useDataTableParams([
    'TIME_PERIOD', 'LOCATION', 'RECON_RAW_ADJ_START_DATE', 'RECON_RAW_ADJ_END_DATE',
    'INVENTORY_DATE', 'RECON_FLAG',
]);

const { reconParms, getReconParms } = useReconParms();

const selectedReconParm = ref(null);

const isLoading = ref(false);

const fetchTimeout = ref(null);

async function fetchReconParms() {
    reconParms.value = null;

    if(!props.location)
        return;

    isLoading.value = true;
    try {
        await getReconParms({
            ...params.value,
            hardFilters: {
                LOCATION: {
                    value: props.location,
                    matchMode: 'equals'
                }
            }
        });
    } catch(e) {
        emit('error', e.message);
    }
    isLoading.value = false;
}

function tableOnFilter(e) {
    onFilter(e);
    if(fetchTimeout.value)
        clearTimeout(fetchTimeout.value);
    fetchTimeout.value = setTimeout(fetchReconParms, 300);
}

watch(() => props.location, fetchReconParms);

function visitReconInvestigationForm() {
    window.open(route('inventory.report-recon-full.investigation-form', {
        recon_parm: selectedReconParm.value.id
    }));
}
</script>

<style scoped>
.pop-up {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: rgb(0, 0, 0, 0.5);
}

.pop-up .card {
    width: 60%;
}

@media (max-width: 768px) {
    .pop-up .card {
        width: 85%;
    }
}

@media (max-width: 992px) {
    .pop-up .card {
        width: 70%;
    }
}
</style>
