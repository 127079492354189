<template>
    <DataTable
        :loading="loadingFlagStore.has('fetchPurchaseOrders')"
        lazy
        :value="purchaseOrders"
        :total-records="totalRecords"
        paginator
        :rows="10"
        @page="e => { onPage(e); fetchPurchaseOrders(); }"
        filter-display="row"
        v-model:filters="filters"
        @filter="tableOnFilter"
        selection-mode="single"
        v-model:selection="selectedPurchaseOrder"
        data-key="ORDER_PO_ID"
        :pt="{ table: { class: 'table table-bordered table-hover' }}">
        <Column field="ORDER_PO_ID" header="Order PO ID" :pt="{
            filterInput: { class: 'input-group input-group-sm' },
            filterMenuButton: { class: 'd-none' },
            headerFilterClearButton: { class: 'd-none' } }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                    class="form-control" placeholder="Search" />
            </template>
        </Column>
        <Column field="PURCHASE_ORDER_NUMBER" header="Purchase Order Number" :pt="{
            filterInput: { class: 'input-group input-group-sm' },
            filterMenuButton: { class: 'd-none' },
            headerFilterClearButton: { class: 'd-none' } }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                    class="form-control" placeholder="Search" />
            </template>
        </Column>
        <Column field="DATE_ARRIVED" header="Date Arrived" :pt="{
            filterInput: { class: 'input-group input-group-sm' },
            filterMenuButton: { class: 'd-none' },
            headerFilterClearButton: { class: 'd-none' } }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                    class="form-control" placeholder="Search" />
            </template>
        </Column>
        <Column field="supplier.SUPPLIER_NAME" header="Supplier" :pt="{
            filterInput: { class: 'input-group input-group-sm' },
            filterMenuButton: { class: 'd-none' },
            headerFilterClearButton: { class: 'd-none' } }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                    class="form-control" placeholder="Search" />
            </template>
        </Column>
        <Column field="STATUS" header="Status" :pt="{
            filterInput: { class: 'input-group input-group-sm' },
            filterMenuButton: { class: 'd-none' },
            headerFilterClearButton: { class: 'd-none' } }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                    class="form-control" placeholder="Search" />
            </template>
        </Column>
        <slot></slot>
        <template #empty>
            <div class="text-center">
                No data yet.
            </div>
        </template>
    </DataTable>
</template>

<script setup>
import { inject, onMounted, ref, watch } from 'vue';

import { useDataTableParams } from '@/composables/data/dataTableParams';
import { usePurchaseOrders } from '@/composables/data/purchaseOrders';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

const props = defineProps({
    refreshFlag: null,
    class: String,
});

const emit = defineEmits(['error']);

const { params, filters, onPage, onFilter } = useDataTableParams([
    'ORDER_PO_ID', 'PURCHASE_ORDER_NUMBER', 'DATE_ARRIVED', 'supplier.SUPPLIER_NAME', 'STATUS'
]);

const { purchaseOrders, totalRecords, getPurchaseOrders } = usePurchaseOrders();

const selectedPurchaseOrder = defineModel();

const loadingFlagStore = inject('loadingFlagStore');

const fetchTimeout = ref(null);

async function fetchPurchaseOrders() {
    purchaseOrders.value = null;

    loadingFlagStore.value.add('fetchPurchaseOrders');
    try {
        await getPurchaseOrders(params.value);
    } catch(e) {
        emit('error', e.message);
    }
    loadingFlagStore.value.delete('fetchPurchaseOrders');
}

function tableOnFilter(e) {
    onFilter(e);
    if(fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(fetchPurchaseOrders, 300);
}

onMounted(fetchPurchaseOrders);

watch(() => props.refreshFlag, fetchPurchaseOrders);
</script>
