import { ref } from "vue";

import constructFetchError from "./constructFetchError";

import axios from "axios";

export function useJoborders() {
    const joborders = ref(null);
    const totalRecords = ref(null);

    const getJoborders = async (params) => {
        try {
            const getJobordersResponse = await axios.get(route('api.joborders.index', params));
            joborders.value = getJobordersResponse.data.data;
            totalRecords.value = getJobordersResponse.data.meta.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching joborders.');
        }
    };

    const postJoborder = async (joborder) => {
        try {
            const postJoborderResponse = await axios.post(route('api.joborders.store'), joborder);
            return postJoborderResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while saving joborder.');
        }
    };

    const putJoborder = async (joborderId, joborder) => {
        try {
            const putJoborderResponse = await axios.put(route('api.joborders.update', {
                joborder: joborderId,
            }), joborder);
            return putJoborderResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while updating joborder.');
        }
    };

    const postJoborderExtension = async (joborderId, newDate) => {
        try {
            const postJoborderExtensionResponse = await axios.post(route('api.joborders.extensions', {
                joborder: joborderId,
            }), { new_date: newDate });
            return postJoborderExtensionResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while extending joborder.');
        }
    };

    return {
        joborders,
        totalRecords,
        getJoborders,
        postJoborder,
        putJoborder,
        postJoborderExtension,
    };
}
