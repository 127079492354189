import { ref } from "vue";

import constructFetchError from "./constructFetchError";

import axios from 'axios';

export function useBarcodesInventory() {
    const getInventoryRecords = async (barcode) => {
        try {
            const getInventoryRecordsResponse = await axios.get(route('api.inventory.barcodes-inventory.index', {
                barcode: barcode
            }));
            return getInventoryRecordsResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching inventory records.');
        }
    };

    const getBarcodeLatestInformation = async (barcode, priceDate) => {
        try {
            const getBarcodeLatestInformationResponse = await axios.get(route('api.inventory.barcodes-inventory.latest-information.show', {
                barcode: barcode,
                price_date: priceDate,
            }));
            return getBarcodeLatestInformationResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching barcode latest information.');
        }
    };
    
    return { getInventoryRecords, getBarcodeLatestInformation };
}
