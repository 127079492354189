<template>
    <Card :class="class">
        <template #header>
            Orders by STYLE
        </template>
        <DataTable :value="orderDetails"
            v-model:selection="selectedDetail" selection-mode="single"
            :row-class="data => [{
                'bg-primary text-white': data.style == selectedDetail?.style &&
                    data.composition == selectedDetail?.composition
            }]"
            :pt="{ table: { class: 'table table-bordered table-hover' } }">
            <Column field="style" header="Style" />
            <Column field="composition" header="Composition" />
            <Column field="received" header="Received" />
            <Column field="completed" header="Completed" />
            <Column field="delivered" header="Delivered" />
            <template #empty>
                <div class="text-center">
                    No information.
                </div>
            </template>
        </DataTable>
        <template v-if="$slots.footer" #footer>
            <slot name="footer" />
        </template>
    </Card>
</template>

<script setup>
import { ref, watch } from 'vue';

import { useFlashMessages } from '@/composables/flashmessages';

import { useJoborderNewOrderDetails } from '@/composables/data/joborderNewOrderDetails';

import Card from '@/components/utils/Card.vue';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';

const props = defineProps({
    joborderNew: Object,
    refreshFlag: Boolean,
    class: String,
});

const emit = defineEmits('select');

const { addFlashMessage } = useFlashMessages();

const { orderDetails, getOrderDetails } = useJoborderNewOrderDetails();

const selectedDetail = ref(null);

async function fetchOrderDetails() {
    orderDetails.value = null;
    selectedDetail.value = null;

    if(!props.joborderNew)
        return;

    try {
        await getOrderDetails(props.joborderNew.JONO);
    } catch(e) {
        addFlashMessage('ERROR', e.message);
    }
}

watch(() => props.joborderNew, fetchOrderDetails);
watch(() => props.refreshFlag, fetchOrderDetails);

watch(selectedDetail, () => {
    emit('select', selectedDetail.value);
});
</script>
