<template>
    <div class="card p-4 employee-maintenance-main-card" id="employee-maintenance-main-card">
        <section class="grid-section d-flex flex-column p-3 mb-2 w-100">
            <div class="d-flex flex-row w-100 mb-2">
                <div class="d-flex flex-row w-50 flex-grow-1 ">
                    <label class="m-auto text-nowrap w-25" for="COMPANY_CODE">OPEN TIME PERIOD: </label>
                    <input type="" class="form-control ms-1 mb-1 w-75 me-1" />
                </div>
                <div class="d-flex flex-row w-50 flex-grow-1">
                    <label class="ms-1 m-auto text-nowrap w-25" for="COMPANY_CODE">SELECT PAYTYPE: </label>
                    <select name="TIME_PERIOD" class="ms-1 form-select form-select-sm w-75 ">
                        <option value=""></option>
                        <option>WEEKLY</option>
                        <option>BIMONTHLY</option>
                    </select>
                </div>
            </div>
            <div class="d-flex flex-row w-100">
                <div class="d-flex flex-row w-50 flex-grow-1">
                    <label class="m-auto text-nowrap w-25" for="FIRST_NAME">FIRST NAME: </label>
                    <input type="" class="form-control ms-1 mb-1 w-75 me-1" />
                </div>
                <div class="d-flex flex-row w-50 flex-grow-1">
                    <label class="ms-1 m-auto text-nowrap w-25" for="LAST_NAME">LAST NAME: </label>
                    <input type="" class="form-control ms-1 mb-1 w-75" />
                </div>
            </div>
        </section>
        <section class="grid-section d-flex flex-column p-3 mb-2 w-100">
            <table class="employee-violations-table table table-sm table-bordered table-striped table-hover">
                <thead>
                    <tr>
                        <th scope="col">EMPID</th>
                        <th scope="col">TIME_PERIOD</th>
                        <th scope="col">START_DATE</th>
                        <th scope="col">END_DATE</th>
                        <th scope="col">DEPT</th>
                        <th scope="col">LNAME</th>
                        <th scope="col">FNAME</th>
                        <th scope="col">HOURS</th>
                        <th scope="col">FINAL</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="_ in 10">
                        <td>TEST</td>
                        <td>TEST</td>
                        <td>TEST</td>
                        <td>TEST</td>
                        <td>TEST</td>
                        <td>TEST</td>
                        <td>TEST</td>
                        <td>TEST</td>
                        <td>TEST</td>
                    </tr>
                </tbody>
            </table>
            <div class="d-flex flex-row w-50 flex-grow-1 ms-auto mt-2">
                <button type="button" class="btn btn-primary w-100 mb-1">
                    DELETE PAYROLL
                </button>
            </div>
        </section>
    </div>
</template>
<style scoped>
section {
    padding: 1rem;
    background-color: #f9fafb;
    border-radius: 0.5rem;
    border: 2px solid #d1d5db;
}

.horizontal-form-control-4-container {
    display: grid;
    column-gap: 1rem;
    grid-template-columns: repeat(4, 1fr);
}

.employee-maintenance-main-card {
    display: flex;
    column-gap: 0.5rem;
    width: 100%;
    flex-wrap: wrap;
}

.import-hours-nideka-table-wrapper {
    height: 20rem;
    overflow-y: auto;
    width: 100%;
}

.select-employee-table {
    cursor: pointer;
}

.select-employee-table-wrapper {
    height: 16rem;
    overflow-y: auto;
}

.table-striped tbody:nth-child(even) {
    background-color: white;
}

thead {
    top: 0;
    position: sticky;
    background-color: white;
    margin: 0;
    backdrop-filter: blur(100px);
}

th {
    backdrop-filter: blur(100px);
}

td {
    max-width: 30vw;
}

td:hover {
    cursor: pointer;
}
</style>
