<template>
    <DataTable
        :loading="isLoading"
        :value="payslips"
        paginator :rows="10" 
        sort-mode="multiple"
        v-model:filters="filters"
        filter-display="row"
        selection-mode="single"
        v-model:selection="selectedPayslip"
        :pt="{ table: { class: 'table table-bordered table-hover' } }">
        <Column field="employee.EMPID" header="Employee ID" sortable
            :pt="{ filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                    class="form-control" placeholder="Search" />
            </template>
        </Column>
        <Column field="employee.FNAME" header="First Name" sortable
            :pt="{ filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                    class="form-control" placeholder="Search" />
            </template>
        </Column>
        <Column field="employee.LNAME" header="Last Name" sortable
            :pt="{ filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                    class="form-control" placeholder="Search" />
            </template>
        </Column>
        <Column field="employee.DEPT" header="Department" sortable
            :pt="{ filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                    class="form-control" placeholder="Search" />
            </template>
        </Column>
        <Column field="HOURS" header="Hours" sortable
            :pt="{ filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                    class="form-control" placeholder="Search" />
            </template>
        </Column>
        <Column field="DOW" header="DOW" sortable
            :pt="{ filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                    class="form-control" placeholder="Search" />
            </template>
        </Column>
        <Column field="GROSS_PAY" header="Gross" sortable
            :pt="{ filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                    class="form-control" placeholder="Search" />
            </template>
        </Column>
        <Column field="TOTAL_DEDUCT" header="Deduct" sortable
            :pt="{ filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                    class="form-control" placeholder="Search" />
            </template>
        </Column>
        <Column field="FINAL" header="Final" sortable
            :pt="{ filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                    class="form-control" placeholder="Search" />
            </template>
        </Column>
        <template #empty>
            <div class="text-center">
                No payslips.
            </div>
        </template>
    </DataTable>
</template>

<script setup>
import { ref, watch } from 'vue';

import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useLoadingFlagsStore } from '@/stores/loadingFlags';
import { useToastsStore } from '@/stores/toasts';
import { usePayslips } from '@/composables/data/payslips';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

const props = defineProps({
    timePeriod: Number,
});

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const { filters } = useDataTableParams([
    'employee.EMPID',
    'employee.FNAME',
    'employee.LNAME',
    'employee.DEPT',
    'HOURS',
    'DOW',
    'GROSS_PAY',
    'TOTAL_DEDUCT',
    'FINAL'
]);

const { payslips, getPayslips } = usePayslips();

const selectedPayslip = defineModel();

async function fetchPayslips() {
    payslips.value = null;

    if(!props.timePeriod) {
        return;
    }

    loadingFlags.add('fetchPayslips');
    try {
        await getPayslips({
            filters: {
                ...filters.value,
                TIME_PERIOD: {
                    value: props.timePeriod,
                    matchMode: 'equals'
                }
            }
        });
    } catch(e) {
        toasts.add('ERROR', 'Error', e.message);
    }
    loadingFlags.delete('fetchPayslips');
}

watch(() => props.timePeriod, fetchPayslips);
</script>
