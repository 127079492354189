<template>
    <div class="row g-3">
        <div class="col-md-4">
            <Card class="mb-3">
                <template #header>
                    Select YEARLY Time Period
                </template>
                <TimePeriodTable paytype="YEARLY"
                    :sort="[{ field: 'TIME_PERIOD', order: -1 }]"
                    show-from-current-date
                    v-model="timePeriod" />
            </Card>
            <Card>
                <template #header>
                    Select Location
                </template>
                <LocationTable
                    v-model="location" />
            </Card>
        </div>
        <div class="col-md-8">
            <Card>
                <template #header>
                    Recon Lost Comments
                </template>
                <DataTable
                    :loading="isLoading"
                    lazy
                    :value="comments"
                    :total-records="totalRecords"
                    paginator
                    :rows="10"
                    @page="e => { onPage(e); fetchComments(); }"
                    filter-display="row"
                    v-model:filters="filters"
                    @filter="tableOnFilter"
                    selection-mode="single"
                    v-model:selection="comment"
                    :pt="{ table: { class: 'table table-bordered table-hover' }}">
                    <Column field="COMMENT_ID" header="Comment ID" :pt="{
                        filterInput: { class: 'input-group input-group-sm' },
                        filterMenuButton: { class: 'd-none' },
                        headerFilterClearButton: { class: 'd-none' } }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                                class="form-control" placeholder="Search" />
                        </template>
                    </Column>
                    <Column field="COMMENT_TEXT" header="Comment Text" :pt="{
                        filterInput: { class: 'input-group input-group-sm' },
                        filterMenuButton: { class: 'd-none' },
                        headerFilterClearButton: { class: 'd-none' } }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                                class="form-control" placeholder="Search" />
                        </template>
                    </Column>
                    <Column field="COMMENT_RPT_DATE" header="Comment Rpt. Date" :pt="{
                        filterInput: { class: 'input-group input-group-sm' },
                        filterMenuButton: { class: 'd-none' },
                        headerFilterClearButton: { class: 'd-none' } }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                                class="form-control" placeholder="Search" />
                        </template>
                    </Column>
                    <Column field="LOCATION" header="Location" :pt="{
                        filterInput: { class: 'input-group input-group-sm' },
                        filterMenuButton: { class: 'd-none' },
                        headerFilterClearButton: { class: 'd-none' } }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                                class="form-control" placeholder="Search" />
                        </template>
                    </Column>
                    <Column header="Prev. Loc." :pt="{
                        filterInput: { class: 'input-group input-group-sm' },
                        filterMenuButton: { class: 'd-none' },
                        headerFilterClearButton: { class: 'd-none' } }">
                        <template #filter>-</template>
                        <template #body>
                            {{ location.LOCATION }}
                        </template>
                    </Column>
                    <Column field="QTY" header="Qty" :pt="{
                        filterInput: { class: 'input-group input-group-sm' },
                        filterMenuButton: { class: 'd-none' },
                        headerFilterClearButton: { class: 'd-none' } }">
                        <template #filter>-</template>
                    </Column>
                    <Column field="TOTAL_PRICE" header="Total Price" :pt="{
                        filterInput: { class: 'input-group input-group-sm' },
                        filterMenuButton: { class: 'd-none' },
                        headerFilterClearButton: { class: 'd-none' } }">
                        <template #filter>-</template>
                    </Column>
                    <template #empty>
                        <div class="text-center">
                            No data yet.
                        </div>
                    </template>
                </DataTable>
                <template #footer>
                    <div class="text-end">
                        <button type="button" class="btn btn-primary me-2"
                            :disabled="!timePeriod || !location"
                            @click="generateLostTotalsOnYearSpreadsheet">
                            Lost Totals on Year
                        </button>
                        <button type="button" class="btn btn-primary"
                            :disabled="!comment"
                            @click="generateLostBarcodesOnCommentSpreadsheet">
                            Lost Barcodes on Comment
                        </button>
                    </div>
                </template>
            </Card>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';

import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useFlashMessages } from '@/composables/flashmessages';
import { useComments } from '@/composables/data/comments';

import Card from '@/components/utils/Card.vue';
import LocationTable from '@/components/utils/tables/LocationTable.vue';
import TimePeriodTable from '@/components/utils/tables/TimePeriodTable.vue';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

import scrollToTop from '@/utils/scrollToTop';

const { addFlashMessage } = useFlashMessages();

const timePeriod = ref(null);
const location = ref(null);
const comment = ref(null);

const { params, filters, onPage, onFilter } = useDataTableParams([
    'COMMENT_ID', 'COMMENT_TEXT', 'COMMENT_RPT_DATE', 'LOCATION'
]);

const { comments, totalRecords, getLostCommentsWithTotals } = useComments();

const isLoading = ref(false);

const fetchTimeout = ref(null);

async function fetchComments() {
    comments.value = null;
    comment.value = null;

    if(!timePeriod.value || !location.value) {
        return;
    }

    isLoading.value = true;
    try {
        await getLostCommentsWithTotals(
            timePeriod.value.START_DATE,
            timePeriod.value.END_DATE,
            location.value.LOCATION,
            params.value
        );
    } catch(e) {
        addFlashMessage('ERROR', e.message);
        scrollToTop();
    }
    isLoading.value = false;
}

function tableOnFilter(e) {
    onFilter(e);
    if(fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(fetchComments, 300);
}

onMounted(fetchComments);

watch([timePeriod, location], fetchComments);

function generateLostTotalsOnYearSpreadsheet() {
    window.open(route('api.comments.lost-comment-totals-spreadsheet.show', {
        START_DATE: timePeriod.value.START_DATE,
        END_DATE: timePeriod.value.END_DATE,
        LOCATION: location.value.LOCATION
    }));
}

function generateLostBarcodesOnCommentSpreadsheet() {
    window.open(route('api.comments.lost-barcodes-spreadsheet.show', {
        comment: comment.value.COMMENT_ID,
        LOCATION: location.value.LOCATION
    }));
}
</script>
