import { ref } from "vue";

import constructFetchError from "./constructFetchError";

import axios from "axios";

export function useJoborderDetails() {
    const joborderDetails = ref(null);
    const usedStyles = ref(null);

    const getJoborderDetails = async (jono, params) => {
        try {
            const getJoborderDetailsResponse = await axios.get(route('api.joborder-new.joborder-details.index', {
                joborder_new: jono,
                ...params
            }));
            joborderDetails.value = getJoborderDetailsResponse.data.data;
            return joborderDetails.value;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching used joborder details.');
        }
    };

    const getUsedStyles = async (accountId) => {
        try {
            const getUsedStylesResponse = await axios.get(route('api.joborder-details.styles.index', {
                filters: {
                    relation_joborderNew_ACCOUNT_ID: {
                        value: accountId,
                        matchMode: 'equals'
                    }
                }
            }));
            usedStyles.value = getUsedStylesResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching used styles.');
        }
    };

    const postJoborderDetailReception = async (jono, joborderDetail) => {
        try {
            const postJoborderDetailReceptionResponse = await axios.post(route('api.joborder-new.joborder-details.receptions.store', {
                joborder_new: jono
            }), joborderDetail);
            return postJoborderDetailReceptionResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while creating joborder detail.');
        }
    };

    const postJoborderDetailCompletion = async (jono, joborderDetail) => {
        try {
            const postJoborderDetailCompletionResponse = await axios.post(route('api.joborder-new.joborder-details.completions.store', {
                joborder_new: jono
            }), joborderDetail);
            return postJoborderDetailCompletionResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while creating joborder detail.');
        }
    };

    const postJoborderDetailDelivery = async (jono, joborderDetail) => {
        try {
            const postJoborderDetailDeliveryResponse = await axios.post(route('api.joborder-new.joborder-details.deliveries.store', {
                joborder_new: jono
            }), joborderDetail);
            return postJoborderDetailDeliveryResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while creating joborder detail.');
        }
    };

    const postJoborderDetailComment = async (jono, joborderDetail) => {
        try {
            const postJoborderDetailCommentResponse = await axios.post(route('api.joborder-new.joborder-details.comments.store', {
                joborder_new: jono
            }), joborderDetail);
            return postJoborderDetailCommentResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while creating joborder detail comment.');
        }
    };

    return { usedStyles, getUsedStyles, joborderDetails, getJoborderDetails,
        postJoborderDetailReception, postJoborderDetailCompletion,
        postJoborderDetailDelivery, postJoborderDetailComment };
}
