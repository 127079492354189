<template>
    <Card :class="class">
        <template #header>
            JONO
        </template>
        <div class="row g-3 mb-3">
            <div class="col-md-6">
                <FormInput type="text" v-model="descriptionFilter"
                    label="Description" id-prefix="description"
                    is-horizontal />
            </div>
            <div class="col-md-6">
                <button type="button" class="btn btn-primary me-2"
                    @click="searchJoborderNewJONO">
                    Search JONO
                </button>
                <button type="button" class="btn btn-primary me-2"
                    @click="searchJoborderDetailComposition">
                    Search COMP
                </button>
                <button type="button" class="btn btn-primary"
                    @click="searchJoborderNewNotFinished">
                    Not Finished
                </button>
            </div>
        </div>
        <DataTable lazy :value="jobordersNew" :total-records="totalRecords"
            paginator :rows="10" @page="e => { onPage(e); fetchJobordersNew(); }"
            v-model:selection="selectedJoborderNew" selection-mode="single" data-key="JONO"
            :row-class="data => [{ 'bg-primary text-white': data.JONO == selectedJoborderNew?.JONO }]"
            :pt="{ table: { class: 'table table-bordered table-hover' } }">
            <Column field="JONO" header="JONO" />
            <Column field="DESCRIPTION" header="Description" />
            <Column field="DATE_DUE" header="Due Date" />
            <Column field="received" header="Received" />
            <Column field="delivered" header="Delivered" />
            <Column field="percentage" header="Percent">
                <template #body="slotProps">
                    {{ (slotProps.data.percentage * 100).toFixed(2) }}%
                </template>
            </Column>
            <Column field="CREATED_BY" header="Created By" />
            <template #empty>
                <div class="text-center">
                    No JONOs.
                </div>
            </template>
        </DataTable>
    </Card>
</template>

<script setup>
import { ref, watch } from 'vue';

import { useFlashMessages } from '@/composables/flashmessages';

import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useJobordersNew } from '@/composables/data/jobordersNew';

import Card from '@/components/utils/Card.vue';
import FormInput from '@/components/utils/FormInput.vue';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';

const props = defineProps({
    account: Object,
    savedJoborderNew: Object,
    class: String,
});

const emit = defineEmits(['select']);

const { addFlashMessage } = useFlashMessages();

const { params, filters, onPage, onFilter } = useDataTableParams(
    [
        'JONO', 'ACCOUNT_ID', 'DESCRIPTION', 'DATE_DUE', 'CREATED_BY',
        'PO_NUM', 'received', 'delivered', 'percentage'
    ],
    { customFilters: {} }
);

const { jobordersNew, totalRecords, getJobordersNew } = useJobordersNew();

const selectedJoborderNew = ref(null);

const descriptionFilter = ref(null);

async function fetchJobordersNew() {
    try {
        await getJobordersNew(params.value);
    } catch(e) {
        addFlashMessage('ERROR', e.message);
    }
}

function resetFilters() {
    filters.value.JONO.value = null;
    filters.value.relation_joborderDetails_COMPOSITION = null;
    params.value.customFilters.percentage_not_eq = null;
}

watch(() => props.account, () => {
    resetFilters();
    filters.value.ACCOUNT_ID.value = props.account?.ACCOUNT_ID;
    filters.value.ACCOUNT_ID.matchMode = 'equals';
    onFilter();
    fetchJobordersNew();
});

watch(() => props.savedJoborderNew, () => {
    descriptionFilter.value = props.savedJoborderNew?.JONO;
    searchJoborderNewJONO();
    selectedJoborderNew.value = props.savedJoborderNew;
});

watch(selectedJoborderNew, () => {
    emit('select', selectedJoborderNew.value);
});

function searchJoborderNewJONO() {
    resetFilters();
    filters.value.JONO.value = descriptionFilter.value;
    onFilter();
    fetchJobordersNew();
}

function searchJoborderDetailComposition() {
    resetFilters();
    filters.value.relation_joborderDetails_COMPOSITION = {
        value: descriptionFilter.value,
        matchMode: 'contains'
    };
    onFilter();
    fetchJobordersNew();
}

function searchJoborderNewNotFinished() {
    resetFilters();
    params.value.customFilters.percentage_not_eq = 100;
    onFilter();
    fetchJobordersNew();
}
</script>
