<template>
    <Card>
        <div class="row g-3 mb-3">
            <div class="col-md-4">
                <Card>
                    <div class="wrapper">
                        <table class="table table-bordered table-hover">
                            <tbody>
                                <tr
                                    v-for="month in months" :key="month"
                                    :class="{ 'bg-primary text-white': month === selectedMonth }"
                                    @click="selectMonth(month)"
                                >
                                    <td class="cursor-pointer">{{ month }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Card>
            </div>
            <div class="col-md-8">
                <Card>
                    <div class="wrapper d-flex align-items-center justify-content-center flex-column">
                        <FormSelect label="COMMENT_TYPE:" v-model="commentType" :options="commentTypes"
                            id-prefix="CommentType" class="mb-1 w-100" select-class="form-select-sm w-100"
                            is-horizontal/>
                        <FormSelect label="COMMENT_ID:" v-model="selectedComment" :options="commentList"
                            :option-to-string="(commentList) => commentList.COMMENT_ID + ' | ' +
                            commentList.COMMENT_RPT_DATE + ' | ' + commentList.COMMENT_TEXT.substring(0, 45)
                            + ' | ' + commentList.DESCRIPTION + ' | ' + commentList.LOCATION"
                            id-prefix="selectedComment" class="mb-1 w-100" select-class="form-select-sm w-100"
                            is-horizontal @change="getComment"/>
                    </div>
                </Card>
            </div>
            <div class="col-md-10">
                <Card>
                    <div class="m-3">
                        <FormInput type="date" label="COMMENT_TIMESTAMP:"
                            v-model="timestamp"
                            is-horizontal id-prefix="timestamp" class="mb-1"
                            input-class="form-control w-75" disabled/>
                        <FormInput type="date" label="COMMENT_RPT_DATE_ORIG:"
                            v-model="rptDateOrig"
                            is-horizontal id-prefix="rptDateOrig" class="mb-1"
                            input-class="form-control w-75" disabled/>
                        <FormInput type="date" label="COMMENT_RPT_DATE:"
                            v-model="rptDate"
                            is-horizontal id-prefix="rptDate" class="mb-1"
                            input-class="form-control w-75"
                            :errors="errors.COMMENT_RPT_DATE"/>
                        <FormSelect label="PAYEE TYPE" v-model="payeeType"
                            :options="payeeTypes" class="mb-1"
                            :option-to-string="(payeeType) => payeeType.label"
                            :option-value="(payeeType) => payeeType.value"
                            id-prefix="payeeType" select-class="form-select w-75"
                            is-horizontal disabled/>
                        <FormInput type="text" label="PAYEE ID"
                            v-model="payeeId"
                            is-horizontal id-prefix="payeeId" class="mb-1"
                            input-class="form-control w-50"
                            disabled/>
                        <div class="d-flex mb-1">
                            <span class="w-25 ms-1"></span>
                            <p class="ms-1">{{ payeeText }}</p>
                        </div>
                        <div class="d-flex mb-1">
                            <button
                                class="btn btn-primary w-50 ms-1"
                                :disabled="selectedComment == ''"
                                @click="updateCommentRPTDate"
                            >
                                UPDATE</button>
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    </Card>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import axios from "axios";

import Card from '../../../utils/Card.vue';
import FormInput from '../../../utils/FormInput.vue';
import FormSelect from '../../../utils/FormSelect.vue'

import scrollToTop from "../../../../../js/utils/scrollToTop";
import handleFetchErrors from "../../../../utils/handleFetchErrors";
import { useFlashMessages } from "../../../../composables/flashmessages";

const { addFlashMessage } = useFlashMessages();

const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const currentMonth = currentDate.getMonth() + 1;

const years = ref([]);
const months = ref([]);
const commentType = ref('Sales');
const commentList = ref([]);
const selectedMonth = ref('');
const selectedComment = ref([]);
const rptDate = ref('');
const rptDateOrig = ref('');
const timestamp = ref('');
const payeeId = ref('');
const payeeType = ref('');
const payeeText = ref('NONE');
const errors = ref({});

const commentTypes = [
    'Initial',
    'Delivery',
    'Pullout',
    'Sales',
    'Reconciliation',
    'New Stock',
    'Return',
    'Replacement'
]

const payeeTypes = [
    { value: 'E', label: 'EXTERNAL'},
    { value: 'I', label: 'INTERNAL'}
]

onMounted(() => {
    generateYearsAndMonths();

    //set initial value for the selected month
    const formattedCurrentMonth = currentMonth < 10 ? `0${currentMonth}` : `${currentMonth}`;
    selectedMonth.value = `${currentYear}/${formattedCurrentMonth}`;
});

function generateYearsAndMonths() {
    const startYear = 2000;

    for (let year = currentYear; year >= startYear; year--) {
        years.value.push(year);
    }
    for (let year = currentYear; year >= startYear; year--) {
        const endMonth = year === currentYear ? currentMonth : 12;
        for (let month = 12; month >= 1; month--) {
            if (year === currentYear && month > endMonth) {
                continue;
            }
            const formattedMonth = month < 10 ? `0${month}` : `${month}`;
            const yearMonth = `${year}/${formattedMonth}`;
            months.value.push(yearMonth);
        }
    }
}

watch(selectedMonth, getCommentList)

watch(commentType, getCommentList)

function selectMonth(month) {
    selectedMonth.value = month;
}

async function getCommentList() {
    try {
        const response = await axios.get(route('api.comments.index'), {
            params: {
                MONTH: selectedMonth.value,
                COMMENT_TYPE: commentType.value,
                COMMENT_STATUS: 'O'
            }
        })
        commentList.value = response.data.data;
        clearData();
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, 'Error while fetching comments.');
        scrollToTop();
    }
}

async function getComment() {
    try {
        const response = await axios.get(route('api.comments.show', {
            id: selectedComment.value.COMMENT_ID
        }));

        rptDate.value = response.data.data.COMMENT_RPT_DATE;
        rptDateOrig.value = response.data.data.COMMENT_RPT_DATE_ORIG;
        timestamp.value = response.data.data.COMMENT_TIMESTAMP;
        payeeType.value = response.data.data.PAYEE_TYPE;
        payeeId.value = response.data.data.PAYEE_ID;
        getPayeeName(payeeId.value, payeeType.value);
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, 'Error while fetching comments.');
        scrollToTop();
    }
}

async function getPayeeName(id, type) {
    try {
        let response;
        if(type === 'E')
            response = await axios.get(route('api.accounts.show', { id: id }));
        else
            response = await axios.get(route('api.employees.show', { id: id }));
        payeeText.value = type === 'E' ? response.data.data.ACCOUNT_NAME : response.data.data.FNAME + ' ' + response.data.data.LNAME
    } catch (e) {
        console.log(e);
        addFlashMessage('ERROR', `An error occurred while fetching the payee name.
            Please contact your administrator.`);
    }
}

async function updateCommentRPTDate() {
    try {
        const response = await axios.patch(route('api.comments.update', { id: selectedComment.value.COMMENT_ID}),{
            COMMENT_RPT_DATE: rptDate.value,
        })
        addFlashMessage('SUCCESS', response.data.message);
        scrollToTop();
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, 'Error while fetching comments.');
        scrollToTop();
    }
}

function clearData() {
    rptDate.value = '';
    rptDateOrig.value = '';
    timestamp.value = '';
    payeeType.value = '';
    payeeId.value = '';
    payeeText.value = 'NONE';
    selectedComment.value = [];
}
</script>

<style>
.wrapper {
    width: 100%;
    height: 10rem;
    overflow-y: auto;
    background-color: #FFF;
}

.dropdownContainer {
    width: 55.75%;
}

.cursor-pointer {
    cursor: pointer;
}
</style>
