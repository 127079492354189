<template>
    <FormComponent :label="label" :hide-label="hideLabel"
        :id-prefix="idPrefix" :is-horizontal="isHorizontal"
        :class="class">
        <div v-if="isHorizontal" class="col-sm-9">
            <select v-model="model" :disabled="disabled" :required="required"
                :id="idPrefix + 'FormInput'" :class="selectClasses"
                @keydown.enter.prevent="emit('keydown-enter')"
                @change="emit('change')">
                <option :value="null">-- SELECT AN OPTION --</option>
                <option v-if="options != null"
                    v-for="option in options" :value="optionValue(option)">{{ optionToString(option) }}</option>
            </select>
            <div v-if="formText" class="form-text">
                {{ formText }}
            </div>
            <div v-if="errors && errors.length > 0" class="invalid-feedback">
                <p v-for="error in errors">{{ error }}</p>
            </div>
        </div>
        <template v-else>
            <select v-model="model" :disabled="disabled" :required="required"
                :id="idPrefix + 'FormInput'" :class="selectClasses"
                @keydown.enter.prevent="emit('keydown-enter')"
                @change="emit('change')">
                <option :value="null">-- SELECT AN OPTION --</option>
                    <option v-if="options != null"
                        v-for="option in options" :value="optionValue(option)">{{ optionToString(option) }}</option>
            </select>
            <div v-if="formText" class="form-text">
                {{ formText }}
            </div>
            <div v-if="errors && errors.length > 0" class="invalid-feedback">
                <p v-for="error in errors">{{ error }}</p>
            </div>
        </template>
    </FormComponent>
</template>

<script setup>
import { computed } from "vue";

import FormComponent from "./FormComponent.vue";

const props = defineProps({
    label: String,
    hideLabel: Boolean,
    options: Array,
    formText: String,
    optionToString: {
        type: Function,
        default: (option) => option.toString()
    },
    optionValue: {
        type: Function,
        default: (option) => option
    },
    required: Boolean,
    formText: String,
    idPrefix: String,
    disabled: {
        type: Boolean,
        default: false
    },
    isHorizontal: {
        type: Boolean,
        default: false
    },
    class: {
        type: String,
        default: ""
    },
    selectClass: {
        type: String,
        default: ""
    },
    errors: {
        type: Array,
        default: []
    }
});

const emit = defineEmits(['keydown-enter', 'change']);

const model = defineModel();

const hasErrors = computed(() => {
    if(!props.errors)
        return false;
    return props.errors.length > 0;
});

const selectClasses = computed(() => {
    const selectClasses = ["form-select"];
    if(hasErrors.value)
        selectClasses.push("is-invalid");
    if(props.selectClass || props.selectClass.length > 0)
        selectClasses.push(props.selectClass);
    return selectClasses.join(" ");
});
</script>
