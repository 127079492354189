<template>
    <div class="row">
        <div class="col-md-8">
            <Card class="mb-3">
                <template #header>
                    Styles Table
                </template>
                <div class="row">
                    <div class="col-md-4">
                        <FormSelect label="Style Type" v-model="filters.STYLE_TYPE.value"
                            :options="styleTypes"
                            :option-value="styleType => styleType.STYLE_TYPE"
                            :option-to-string="styleType => styleType.STYLE_TYPE"
                            id-prefix="styleTypeMaster" class="mb-3" select-class="form-select-sm"
                            @change="onFilter()" />
                    </div>
                </div>
                <DataTable
                    lazy
                    :loading="isLoading"
                    paginator
                    :rows="10"
                    :total-records="totalRecords"
                    :value="styles"
                    v-model:filters="filters"
                    filter-display="row"
                    data-key="STYLE"
                    v-model:selection="selectedStyle"
                    selection-mode="single"
                    @page="event => onPage(event)"
                    @filter="event => onFilter(event)"
                    :pt="{ table: { class: 'table table-bordered table-hover' } }">
                    <Column field="STYLE" header="Style" :pt="{
                            filterInput: {
                                class: 'input-group input-group-sm'
                            },
                            filterMenuButton: {
                                class: 'd-none'
                            },
                            headerFilterClearButton: {
                                class: 'd-none'
                            }
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                                class="form-control" placeholder="Search" />
                        </template>
                    </Column>
                    <Column field="STYLE_TYPE" header="Style Type" :pt="{
                            filterInput: {
                                class: 'input-group input-group-sm'
                            },
                            filterMenuButton: {
                                class: 'd-none'
                            },
                            headerFilterClearButton: {
                                class: 'd-none'
                            }
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                                class="form-control" placeholder="Search" />
                        </template>
                    </Column>
                    <Column field="CATEGORY" header="Category" :pt="{
                            filterInput: {
                                class: 'input-group input-group-sm'
                            },
                            filterMenuButton: {
                                class: 'd-none'
                            },
                            headerFilterClearButton: {
                                class: 'd-none'
                            }
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                                class="form-control" placeholder="Search" />
                        </template>
                    </Column>
                    <Column field="supplier" header="Supplier"
                        filter-field="relation_supplier_ACCOUNT_NAME"
                        :pt="{ filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' } }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                                class="form-control" placeholder="Search" />
                        </template>
                        <template #body="slotProps">
                            {{ slotProps.data.supplier?.ACCOUNT_NAME }}
                        </template>
                    </Column>
                    <template #footer>
                        <div class="mb-3">
                            Total Records: <b>{{ totalRecords }}</b> styles.
                        </div>
                    </template>
                </DataTable>
            </Card>
        </div>
        <div class="col-md-4">
            <StyleFormCard :style="selectedStyle" :style-types="styleTypes"
                @clear="_ => {
                    selectedStyle = {
                        STYLE: null,
                        STYLE_TYPE: null,
                        CATEGORY: null
                    };
                }" class="mb-3" />
            <ImageUploadCard :style="selectedStyle?.STYLE != null ? selectedStyle : null"
                @success="(message) => {
                    addFlashMessage('SUCCESS', message);
                    scrollToTop();
                }"
                @error="(message) => {
                    addFlashMessage('ERROR', message);
                    scrollToTop();
                }"
                class="mb-3" />
        </div>
    </div>
    <div class="row g-3">
        <div class="col-md-4">
            <SMGenericSkuCard :style="selectedStyle" class="mb-3"
                @done="loadStyles" />
            <SMSkuCard :style="selectedStyle" :colors="colors" />
        </div>
        <div class="col-md-4">
            <RobinsonsGenericSkuCard :style="selectedStyle" :colors="colors" class="mb-3" />
            <RobinsonsSkuCard :style="selectedStyle" :colors="colors" />
        </div>
        <div class="col-md-4">
            <LandmarkSkuCard :style="selectedStyle" class="mb-3" />
            <KCCSkuCard :style="selectedStyle" />
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';

import { useFlashMessages } from '@/composables/flashmessages';
import { useColors } from '@/composables/data/colors';

import Card from '@/components/utils/Card.vue';
import FormSelect from '@/components/utils/FormSelect.vue';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

import ImageUploadCard from './ImageUploadCard.vue';
import KCCSkuCard from './KCCSkuCard.vue';
import LandmarkSkuCard from './LandmarkSkuCard.vue';
import RobinsonsGenericSkuCard from './RobinsonsGenericSkuCard.vue';
import RobinsonsSkuCard from './RobinsonsSkuCard.vue';
import SMGenericSkuCard from './SMGenericSkuCard.vue';
import SMSkuCard from './SMSkuCard.vue';
import StyleFormCard from './StyleFormCard.vue';

import axios from 'axios';

import handleFetchErrors from "@/utils/handleFetchErrors";
import scrollToTop from "@/utils/scrollToTop";

const { addFlashMessage } = useFlashMessages();

const styleTypes = ref();
const { colors, getActiveColors } = useColors();

const totalRecords = ref(0);
const styles = ref();

const selectedStyle = ref({
    STYLE: null,
    STYLE_TYPE: null,
    CATEGORY: null,
    SUPPLIER_ID: null,
    SUPPLIER_ACCOUNT_NAME: null,
    image: null
});

const filters = ref({
    STYLE: { value: null, matchMode: 'contains' },
    STYLE_TYPE: { value: null, matchMode: 'contains' },
    CATEGORY: { value: null, matchMode: 'contains' },
    relation_supplier_ACCOUNT_NAME: { value: null, matchMode: 'contains' }
});

const params = ref({});

const isLoading = ref(false);

const fetchTimeout = ref(null);

onMounted(async () => {
    try {
        const styleTypesResponse = await axios.get(route('api.styles.types.index'));
        styleTypes.value = styleTypesResponse.data.data;
    } catch(e) {
        console.log(e);
        handleFetchErrors(e, 'Error while fetching style types.');
        scrollToTop();
    }
});

onMounted(async () => {
    try {
        await getActiveColors();
        colors.value = colors.value.map(color => color.COLOR);
    } catch(e) {
        console.log(e);
        handleFetchErrors(e, 'Error while fetching colors.');
        scrollToTop();
    }
});

onMounted(async () => {
    params.value = {
        first: 0,
        page: 0,
        rows: 10,
        sortField: null,
        sortOrder: null,
        filters: filters.value
    };

    await loadStyles();
});

async function loadStyles() {
    styles.value = null;

    isLoading.value = true;
    try {
        const stylesResponse = await axios.get(route('api.styles.index', {
            ...params.value,
            include_relations: 'supplier'
        }));
        totalRecords.value = stylesResponse.data.meta.total;
        styles.value = stylesResponse.data.data;
    } catch(e) {
        console.log(e);
        handleFetchErrors(e, 'Error while fetching styles.');
        scrollToTop();
    }
    isLoading.value = false;
}

const onPage = (event) => {
    params.value = event;
    loadStyles();
}

const onFilter = (event) => {
    params.value.filters = filters.value;
    params.value.page = 0;
    params.value.first = 0;

    if (fetchTimeout.value)
        clearTimeout(fetchTimeout.value);

    fetchTimeout.value = setTimeout(loadStyles, 300);
}
</script>
