<style scoped>
#loansTableContainer {
    max-height: 300px;
    overflow-y: scroll;
}

#loansTableContainer thead th {
    background-color: var(--bs-card-bg);
    position: sticky;
    top: 0;
    z-index: 1;
}
</style>

<template>
    <div class="row g-3 mb-3">
        <div class="col-md-6">
            <Card>
                <template #header>
                    Select Employee
                </template>
                <div class="row g-3">
                    <div class="col-md-3">
                        <FormInput type="text" v-model="selectedEmployeeId"
                            label="Employee ID" id-prefix="empid"
                            disabled />
                    </div>
                    <div class="col-md-3">
                        <FormInput type="text" v-model="selectedEmployeeDepartment"
                            label="Department" id-prefix="department"
                            disabled />
                    </div>
                    <div class="col-md-3">
                        <FormInput type="text" v-model="selectedEmployeeFirstName"
                            label="First Name" id-prefix="firstName"
                            disabled />
                    </div>
                    <div class="col-md-3">
                        <FormInput type="text" v-model="selectedEmployeeLastName"
                            label="Last Name" id-prefix="lastName"
                            disabled />
                    </div>
                </div>
                <template #footer>
                    <div class="text-end">
                        <button type="button" class="btn btn-primary"
                            @click="_ => { isSelectEmployeePopupOpen = true; }">
                            Select
                        </button>
                    </div>
                </template>
            </Card>
        </div>
        <div class="col-md-6">
            <CreateLoanCard :employee="selectedEmployee"
                :no-of-weeks-on-paytype="noOfWeeksOnPaytype"
                @voucher-image-view="viewVoucherImage"
                @success="fetchLoans" />
        </div>
    </div>
    <Card>
        <template #header>
            Loans Table
        </template>
        <div class="row justify-content-end g-3 mb-3">
            <div class="col-sm-6 col-md-3">
                <select v-model="loanEmployeePaytypeFilter" class="form-select"
                    @change="fetchAllEmployeeLoans">
                    <option :value="null">-- SELECT PAYTYPE --</option>
                    <option v-for="paytype in Object.keys(loanEmployeePaytypes)" :value="paytype">
                        {{ paytype }}
                    </option>
                </select>
            </div>
        </div>
        <div class="mb-3">
            <button type="button" class="btn btn-primary me-2"
                :disabled="!(selectedLoan && selectedLoan.STATUS == 'O' &&
                    !['SSS', 'SSS CALAMITY', 'PAGIBIG', 'PAGIBIG CALAMITY'].includes(selectedLoan.LOANTYPE))"
                @click="closeLoan">
                Close Loan
            </button>
            <button type="button" class="btn btn-primary me-2"
                :disabled="!(selectedLoan && selectedLoan.STATUS == 'O' &&
                    ['SSS', 'SSS CALAMITY', 'PAGIBIG', 'PAGIBIG CALAMITY'].includes(selectedLoan.LOANTYPE))"
                @click="closeGovtLoan">
                Close Gov't Loan
            </button>
            <button type="button" class="btn btn-primary me-2"
                :disabled="!selectedEmployee"
                @click="exportToExcel">
                Export to Excel
            </button>
            <button type="button" class="btn btn-primary"
                :disabled="!selectedLoan"
                @click="viewVoucherImage(selectedLoan)">
                View Voucher
            </button>
        </div>
        <DataTable
            :loading="loadingFlagStore.has('fetchLoans') || loadingFlagStore.has('fetchAllEmployeeLoans')"
            :value="loans"
            paginator
            :rows="10"
            filter-display="row"
            v-model:filters="filters"
            selection-mode="single"
            v-model:selection="selectedLoan"
            :pt="{ table: { class: 'table table-bordered table-hover' }}">
            <Column field="LOANID" header="Loan ID" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="LOANTYPE" header="Type" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="DATE" header="Date" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="STATUS" header="Status" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="INIT_AMT" header="Initial Amt." :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="PRINCIPAL" header="Principal" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="TOTAL_PAYMENT" header="Total Payment" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="REMAIN_AMT" header="Remaining Amt." :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="AMT_PAYMENT" header="Amt. per Payment" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="NUM_PAYMENTS" header="# of Payments" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="VOUCHER" header="Voucher" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="DESCRIPTION" header="Description" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="DOCNO" header="DOCNO" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="WEEKS_PASSED" header="Weeks Passed" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="DISCREPANCY" header="Discrepancy" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="CREATED_BY" header="Created By" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="CLOSURE_REASON" header="Closure Reason" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <template #empty>
                <div class="text-center">
                    No data yet.
                </div>
            </template>
        </DataTable>
    </Card>

    <SelectEmployee :is-open="isSelectEmployeePopupOpen"
        :on-close="_ => { isSelectEmployeePopupOpen = false; }"
        :on-select-employee="employee => {
            selectedEmployee = null;
            if(employee) {
                selectedEmployee = employee;
                fetchLoans();
            }
        }" />

    <VoucherImagesPopup :is-open="isVoucherImagesPopupOpen"
        :voucher-id="imageVoucherId"
        @close="_ => { isVoucherImagesPopupOpen = false; }" />

    <GovtVoucherImagePopup :is-open="isGovtVoucherImagePopupOpen"
        :govt-voucher-id="imageVoucherId"
        @close="_ => { isGovtVoucherImagePopupOpen = false; }" />
</template>

<script setup>
import { computed, inject, ref } from "vue";

import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useEmployeeLoans } from "@/composables/data/employeeLoans";
import { useToastsStore } from "@/stores/toasts";

import Card from "@/components/utils/Card.vue";
import FormInput from "@/components/utils/FormInput.vue";
import SelectEmployee from "@/components/utils/SelectEmployee.vue";

import GovtVoucherImagePopup from "@/components/utils/popups/GovtVoucherImagePopup.vue";
import VoucherImagesPopup from "@/components/utils/popups/VoucherImagesPopup.vue";

import CreateLoanCard from "./CreateLoanCard.vue";

import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";

import axios from "axios";

const { postLoanClosure, postGovtLoanClosure } = useEmployeeLoans();

const loadingFlagStore = inject('loadingFlagStore');
const toasts = useToastsStore();

const { filters } = useDataTableParams([
    'LOANID',
    'LOANTYPE',
    'DATE',
    'STATUS',
    'INIT_AMT',
    'PRINCIPAL',
    'TOTAL_PAYMENT',
    'REMAIN_AMT',
    'AMT_PAYMENT',
    'NUM_PAYMENTS',
    'VOUCHER',
    'DESCRIPTION',
    'DOCNO',
    'WEEKS_PASSED',
    'DISCREPANCY',
    'CREATED_BY',
    'CLOSURE_REASON',
]);

const loanEmployeePaytypes = {
    "WEEKLY": {
        PAYTYPE: "WEEKLY",
        STATUS: "O"
    },
    "BIMONTHLY (SALES)": {
        PAYTYPE: "BIMONTHLY",
        REPT_GROUP_NOT: "G",
        STATUS: "O"
    },
    "BIMONTHLY (NON-SALES)": {
        PAYTYPE: "BIMONTHLY",
        REPT_GROUP: "G",
        STATUS: "O"
    }
};

const isSelectEmployeePopupOpen = ref(false);

const selectedEmployee = ref(null);

const noOfWeeksOnPaytype = computed(() => {
    if(!selectedEmployee.value) {
        return 0;
    } else if(selectedEmployee.value.PAYTYPE === "WEEKLY") {
        return 1;
    } else if(selectedEmployee.value.PAYTYPE === "BIMONTHLY") {
        return 2;
    }
});

const selectedEmployeeId = computed(() => selectedEmployee.value?.EMPID);
const selectedEmployeeDepartment = computed(() => selectedEmployee.value?.DEPT);
const selectedEmployeeFirstName = computed(() => selectedEmployee.value?.FNAME);
const selectedEmployeeLastName = computed(() => selectedEmployee.value?.LNAME);

const loanEmployeePaytypeFilter = ref(null);

const loans = ref(null);

const selectedLoan = ref(null);

const imageVoucherId = ref(null);
const isVoucherImagesPopupOpen = ref(false);
const isGovtVoucherImagePopupOpen = ref(false);

async function fetchAllEmployeeLoans() {
    selectedEmployee.value = null;
    loans.value = null;

    loadingFlagStore.value.add('fetchAllEmployeeLoans');
    try {
        const loansResponse = await axios.get(route('api.loans.index',
            loanEmployeePaytypes[loanEmployeePaytypeFilter.value]));
        loans.value = loansResponse.data.data.map(loan => {
            const newLoan = Object.assign({}, loan);
            newLoan.AMT_PAYMENT = loan.AMT_WEEK;
            newLoan.NUM_PAYMENTS = loan.NUM_WEEKS;
            return newLoan;
        });
    } catch(e) {
        console.log(e);
        toasts.add('ERROR', 'Error', e.message);
    }
    loadingFlagStore.value.delete('fetchAllEmployeeLoans');
}

async function fetchLoans() {
    loadingFlagStore.value.add('fetchLoans');
    try {
        const loansResponse = await axios.get(route('api.employees.loans.index', {
            employee: selectedEmployee.value.EMPID,
            ORDER_DESC: 'STATUS,LOANID',
            INCLUDE_FIELDS: 'TOTAL_PAYMENT'
        }));
        loans.value = loansResponse.data.data.map(loan => {
            const newLoan = Object.assign({}, loan);
            newLoan.AMT_PAYMENT = loan.AMT_WEEK * noOfWeeksOnPaytype.value;
            newLoan.NUM_PAYMENTS = loan.NUM_WEEKS / noOfWeeksOnPaytype.value;
            return newLoan;
        });
    } catch(e) {
        console.log(e);
        toasts.add('ERROR', 'Error', e.message);
    }
    loadingFlagStore.value.delete('fetchLoans');
}

function exportToExcel() {
    window.open(route('api.employees.loans.spreadsheet.index', {
        employee: selectedEmployee.value.EMPID
    }), '_blank');
}

async function closeLoan() {
    const voucherId = window.prompt("Please input VOUCHER that contains final loan payment information.");

    if(!voucherId) {
        toasts.add('ERROR', 'Error', 'Blank voucher.');
        return;
    }

    loadingFlagStore.value.add('closeLoan');
    try {
        const closureResponse = await postLoanClosure(
            selectedEmployee.value.EMPID,
            selectedLoan.value.LOANID,
            voucherId
        );
        toasts.add('SUCCESS', 'Success', closureResponse.data.message);
        fetchLoans();
    } catch(e) {
        toasts.add('ERROR', 'Error', e.message);
    }
    loadingFlagStore.value.delete('closeLoan');
}

async function closeGovtLoan() {
    const reason = window.prompt("Please enter reason for closing Gov't Loan: ");

    if(!reason) {
        toasts.add('ERROR', 'Error', 'Blank reason.');
        return;
    }

    loadingFlagStore.value.add('closeGovtLoan');
    try {
        const closureResponse = await postGovtLoanClosure(
            selectedEmployee.value.EMPID,
            selectedLoan.value.LOANID,
            reason
        );
        toasts.add('SUCCESS', 'Success', closureResponse.data.message);
        fetchLoans();
    } catch(e) {
        toasts.add('ERROR', 'Error', e.message);
    }
    loadingFlagStore.value.delete('closeGovtLoan');
}

function viewVoucherImage(loan) {
    imageVoucherId.value = loan.VOUCHER;

    if(['SSS', 'SSS CALAMITY', 'PAGIBIG', 'PAGIBIG CALAMITY'].includes(loan.LOANTYPE)) {
        isGovtVoucherImagePopupOpen.value = true;
    } else {
        isVoucherImagesPopupOpen.value = true;
    }
}
</script>
