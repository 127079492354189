<template>
    <Card>
        <template #header>
            No Landed Cost
        </template>
        <DataTable lazy :loading="isLoading" :value="noLandedCosts" :total-records="noLandedCostsTotalRecords"
            paginator :rows="10" @page="e => { onPage(e); fetchNoLandedCosts(); }"
            filter-display="row" v-model:filters="filters" @filter="tableOnFilter"
            v-model:selection="selectedNoLandedCost" selection-mode="single"
            sort-mode="multiple" @sort="e => { onSort(e); fetchNoLandedCosts(); }"
            :pt="{ table: { class: 'table table-bordered table-hover' } }">
            <Column field="STYLE" header="Style" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                    <Button type="button" class="btn btn-primary" @click="filterCallback()">
                        Search
                    </Button>
                </template>
            </Column>
            <Column field="DATE" header="Date" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                    <Button type="button" class="btn btn-primary" @click="filterCallback()">
                        Search
                    </Button>
                </template>
            </Column>
            <template #empty>
                <div class="text-center">
                    No data.
                </div>
            </template>
        </DataTable>
        <template #footer>
            <div class="text-end">
                <button type="button" class="btn btn-primary me-1"
                    :disabled="!selectedNoLandedCost"
                    @click="insertLandedCost">
                    Insert Landed Cost
                </button>
                <button type="button" class="btn btn-primary me-1"
                    :disabled="isLoading"
                    @click="fetchNoLandedCosts">
                    <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Refresh
                </button>
            </div>
        </template>
    </Card>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';

import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useLandedCosts } from '@/composables/data/landedCosts';

import Card from '@/components/utils/Card';

import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

const props = defineProps({
    date: String,
    refreshFlag: Boolean,
});

const emit = defineEmits(['error']);

const { params, filters, onPage, onFilter, onSort } = useDataTableParams(
    ['STYLE', 'DATE']);

const { noLandedCosts, noLandedCostsTotalRecords, getNoLandedCosts,
    updateLandedCost } = useLandedCosts();

const selectedNoLandedCost = defineModel();

const isLoading = ref(false);

const fetchTimeout = ref(null);

async function fetchNoLandedCosts() {
    noLandedCosts.value = null;

    isLoading.value = true;
    try {
        await getNoLandedCosts(params.value);
    } catch(e) {
        emit('error', e.message);
    }
    isLoading.value = false;
}

async function tableOnFilter(e) {
    onFilter(e);
    if(fetchTimeout.value)
        clearTimeout(fetchTimeout.value);
    fetchTimeout.value = setTimeout(fetchNoLandedCosts, 300);
}

onMounted(fetchNoLandedCosts);

watch(() => props.date, () => {
    filters.value.DATE.value = props.date;
    tableOnFilter();
});

async function insertLandedCost() {
    try {
        const newLandedCost = window.prompt('Input new landed cost:');
        await updateLandedCost(selectedNoLandedCost.value.STYLE,
            selectedNoLandedCost.value.DATE,
            newLandedCost);
        emit('update', `Successfully inserted landed cost ${newLandedCost} for ` +
            `STYLE = ${selectedNoLandedCost.value.STYLE} DATE = ${selectedNoLandedCost.value.DATE}.`);
        fetchNoLandedCosts();
    } catch(e) {
        emit('error', e.message);
    }
}

watch(() => props.refreshFlag, fetchNoLandedCosts);
</script>
