<template>
    <Card>
        <template #header>
            No Barcode Items
        </template>
        <DataTable :value="inventoryNoBarcodes"
            v-model:selection="selectedNoBarcodeItem" selection-mode="single" data-key="id"
            :pt="{ table: { class: 'table table-bordered table-hover' } }"
            :row-class="data => ({'bg-primary text-white': data.id == selectedNoBarcodeItem?.id})">
            <Column field="COMMENT_ID" header="Comment" />
            <Column field="STYLE" header="Style" />
            <Column field="ROWNUM" header="Rownum" />
            <Column field="PREV_LOC" header="Prev. Loc." />
            <Column field="PRICE" header="Price" />
            <Column field="SOLD_DT" header="Sold Date" />
            <Column field="COLOR" header="Color" />
            <Column field="SALE_TYPE" header="Sale Type" />
            <Column field="RECEIPT_NO" header="Receipt" />
            <Column field="DISCOUNT" header="Discount" />
            <template #empty>
                <div class="text-center">No items w/o barcodes.</div>
            </template>
        </DataTable>
        <template #footer>
            <div class="text-end">
                <button type="button" class="btn btn-primary me-2"
                    :disabled="!previousLocation || !comment"
                    @click="_ => {
                        getInventoryNoBarcodes({
                            no_paginate: true,
                            filters: {
                                COMMENT_ID: {
                                    value: props.comment.COMMENT_ID,
                                    matchMode: 'equals',
                                },
                                PREV_LOC: {
                                    value: props.previousLocation.LOCATION,
                                    matchMode: 'equals',
                                }
                            }
                        });
                    }">
                    Refresh
                </button>
                <button type="button" class="btn btn-outline-danger"
                    :disabled="!selectedNoBarcodeItem"
                    @click="deleteSelectedNoBarcodeItem">
                    Delete Selected
                </button>
            </div>
        </template>
    </Card>
</template>

<script setup>
import { ref, watch } from 'vue';

import Card from '@/components/utils/Card.vue';

import { useInventoryNoBarcodes } from '@/composables/data/inventoryNoBarcodes';

import { useFlashMessages } from '@/composables/flashmessages';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';

import scrollToTop from '@/utils/scrollToTop';

const { inventoryNoBarcodes, getInventoryNoBarcodes } = useInventoryNoBarcodes();

const props = defineProps({
    previousLocation: Object,
    comment: Object
});

const { addFlashMessage } = useFlashMessages();

const selectedNoBarcodeItem = ref(null); // For deleting

watch(() => [props.previousLocation, props.comment], () => {
    inventoryNoBarcodes.value = null;
});

async function deleteSelectedNoBarcodeItem() {
    try {
        await axios.delete(route('api.inventory-no-barcodes.destroy', {
            inventory_no_barcode: selectedNoBarcodeItem.value.id
        }));
        await getInventoryNoBarcodes({
            no_paginate: true,
            filters: {
                COMMENT_ID: {
                    value: props.comment.COMMENT_ID,
                    matchMode: 'equals',
                },
                PREV_LOC: {
                    value: props.previousLocation.LOCATION,
                    matchMode: 'equals',
                }
            }
        });
        addFlashMessage('SUCCESS', 'Successfully removed no barcode item.');
    } catch(e) {
        addFlashMessage('ERROR', e.message);
    }
    scrollToTop();
}
</script>
