<template>
    <div class="row g-3">
        <div class="col-lg-5">
            <Card>
                <template #header>
                    <h2 class="h3">Select Company and Time Period</h2>
                </template>
                <FormSelect label="COMPANY" v-model="selectedCompany"
                    :options="companiesList" :option-to-string="(company) => company.COMPANY_DESCRIPTION"
                    id-prefix="company" :is-horizontal="true" class="mb-3" />
                <div>
                    <TimePeriodSelectTableCard v-model="selectedTimePeriod"
                        :show-only-type="'MONTHLY'"
                        @error="message => {
                            addFlashMessage('ERROR', message);
                            scrollToTop();
                        }"
                    />
                </div>
            </Card>
        </div>
        <div class="col-lg-7">
            <div class="row g-3">
                <div class="col-lg-4">
                    <Card class="mb-3">
                        <template #header>
                            Costing
                        </template>
                        <div class="d-grid gap-2">
                            <button type="button" class="btn btn-primary"
                                :disabled="!selectedTimePeriod.TIME_PERIOD"
                                @click="openReport('costing-spreadsheet')">
                                1. View Time Reports by JONO
                            </button>
                        </div>
                    </Card>
                    <Card>
                        <template #header>
                            GL Balances
                        </template>
                        <div class="d-grid gap-2">
                            <button type="button" class="btn btn-primary"
                                @click="generateSummaryTransactions"
                                :disabled="!selectedCompany.COMPANY_CODE || !selectedTimePeriod.TIME_PERIOD">
                                <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status"
                                aria-hidden="true"></span>
                                1a. Generate Summary of Transactions
                            </button>
                            <button type="button" class="btn btn-primary"
                                data-bs-toggle="modal"
                                data-bs-target="#printReportSummaryTransactionModal"
                                :disabled="!selectedCompany.COMPANY_CODE || !selectedTimePeriod.TIME_PERIOD">
                                1b. Display Summary of Transactions
                            </button>
                        </div>
                    </Card>
                </div>
                <div class="col-lg-4">
                    <Card class="mb-3">
                        <template #header>
                            Store Reports
                        </template>
                        <div class="d-grid gap-2">
                            <button type="button" class="btn btn-primary"
                                :disabled="!selectedTimePeriod.TIME_PERIOD "
                                @click="visitStoreSummary">
                                View Store Summary
                            </button>
                        </div>
                    </Card>
                    <Card>
                        <template #header>
                            Trial Balances
                        </template>
                        <div class="d-grid gap-2">
                            <button type="button"
                                    class="btn btn-primary"
                                    :disabled="!selectedTimePeriod.TIME_PERIOD || !selectedCompany.COMPANY_CODE"
                                    data-bs-toggle="modal"
                                    data-bs-target="#printReportPurchaseVATModal">
                                Display Purchase VAT Report (detail)
                            </button>
                        </div>
                    </Card>
                </div>
                <div class="col-lg-4">
                    <Card>
                        <template #header>
                            Employee Reports
                        </template>
                        <div class="d-grid gap-2">
                            <button type="button" class="btn btn-primary"
                                :disabled="!selectedCompany.COMPANY_CODE || !selectedTimePeriod.TIME_PERIOD"
                                data-bs-toggle="modal"
                                data-bs-target="#printReportGovernmentContributionsModal">
                                1. Govt. Contributions
                            </button>
                            <button type="button" class="btn btn-primary"
                                :disabled="!selectedCompany.COMPANY_CODE || !selectedTimePeriod.TIME_PERIOD"
                                data-bs-toggle="modal"
                                data-bs-target="#printReportPagibigContributionsModal">
                                2. PAG-IBIG Contributions
                            </button>
                            <button type="button" class="btn btn-primary"
                                :disabled="!selectedCompany.COMPANY_CODE || !selectedTimePeriod.TIME_PERIOD"
                                data-bs-toggle="modal"
                                data-bs-target="#printReportPagibigLoansModal">
                                3. PAG-IBIG Loans
                            </button>
                            <button type="button" class="btn btn-primary"
                                :disabled="!selectedCompany.COMPANY_CODE || !selectedTimePeriod.TIME_PERIOD"
                                data-bs-toggle="modal"
                                data-bs-target="#printReportPagibigCalamityLoansModal">
                                4. PAGIBIG Calamity Loans
                            </button>
                            <button type="button" class="btn btn-primary"
                                :disabled="!selectedCompany.COMPANY_CODE || !selectedTimePeriod.TIME_PERIOD"
                                data-bs-toggle="modal"
                                data-bs-target="#printReportSSSContributionsModal">
                                5. SSS Contributions
                            </button>
                            <button type="button" class="btn btn-primary"
                                :disabled="!selectedCompany.COMPANY_CODE || !selectedTimePeriod.TIME_PERIOD"
                                data-bs-toggle="modal"
                                data-bs-target="#printReportSSSLoansModal">
                                6. SSS Loans
                            </button>
                            <button type="button" class="btn btn-primary"
                                :disabled="!selectedCompany.COMPANY_CODE || !selectedTimePeriod.TIME_PERIOD"
                                data-bs-toggle="modal"
                                data-bs-target="#printReportSSSCalamityLoansModal">
                                7. SSS Calamity Loans
                            </button>
                            <button type="button" class="btn btn-primary"
                                :disabled="!selectedCompany.COMPANY_CODE || !selectedTimePeriod.TIME_PERIOD"
                                data-bs-toggle="modal"
                                data-bs-target="#printReportPhilhealthContributionsModal">
                                8. Philhealth Contributions
                            </button>
                            <button type="button" class="btn btn-primary"
                                :disabled="!selectedCompany.COMPANY_CODE || !selectedTimePeriod.TIME_PERIOD"
                                data-bs-toggle="modal"
                                data-bs-target="#printReportInsuranceContributionsModal">
                                9. Insurance Contributions
                            </button>
                            <button type="button" class="btn btn-primary"
                                :disabled="!selectedCompany.COMPANY_CODE || !selectedTimePeriod.TIME_PERIOD"
                                data-bs-toggle="modal"
                                data-bs-target="#printReportEmployeeSalesModal">
                                10. Employee Sales
                            </button>
                            <button type="button" class="btn btn-primary"
                                :disabled="!selectedCompany.COMPANY_CODE || !selectedTimePeriod.TIME_PERIOD"
                                data-bs-toggle="modal"
                                data-bs-target="#printReportGovernmentContributionsSalesModal">
                                11. Govt. Contribution (SALES)
                            </button>
                        </div>
                    </Card>
                </div>
            </div>
        </div>
        <PrintModal
            :pdf-link="printReportSummaryTransactionPDFLink"
            :spreadsheet-link="printReportSummaryTransactionSpreadsheetLink"
            title="Summary of Transaction" id="printReportSummaryTransactionModal"
        />
        <PrintModal
            :pdf-link="printReportPurchaseVATPDFLink"
            :spreadsheet-link="printReportPurchaseVATSpreadsheetLink"
            title="Purchase VAT Report" id="printReportPurchaseVATModal"
        />
        <PrintModal
            :pdf-link="printReportGovernmentContributionsPDFLink"
            :spreadsheet-link="printReportGovernmentContributionsSpreadsheetLink"
            title="Government Contributions" id="printReportGovernmentContributionsModal"
        />
        <PrintModal
            :pdf-link="printReportPagibigContributionsPDFLink"
            :spreadsheet-link="printReportPagibigContributionsSpreadsheetLink"
            title="PAG-IBIG Contributions" id="printReportPagibigContributionsModal"
        />
        <PrintModal
            :pdf-link="printReportPagibigLoansPDFLink"
            :spreadsheet-link="printReportPagibigLoansSpreadsheetLink"
            title="PAG-IBIG Loans" id="printReportPagibigLoansModal"
        />
        <PrintModal
            :pdf-link="printReportPagibigCalamityLoansPDFLink"
            :spreadsheet-link="printReportPagibigCalamityLoansSpreadsheetLink"
            title="PAG-IBIG Calamity Loans" id="printReportPagibigCalamityLoansModal"
        />
        <PrintModal
            :pdf-link="printReportSSSContributionsPDFLink"
            :spreadsheet-link="printReportSSSContributionsSpreadsheetLink"
            title="SSS Contributions" id="printReportSSSContributionsModal"
        />
        <PrintModal
            :pdf-link="printReportSSSLoansPDFLink"
            :spreadsheet-link="printReportSSSLoansSpreadsheetLink"
            title="SSS Loans" id="printReportSSSLoansModal"
        />
        <PrintModal
            :pdf-link="printReportSSSCalamityLoansPDFLink"
            :spreadsheet-link="printReportSSSCalamityLoansSpreadsheetLink"
            title="SSS Calamity Loans" id="printReportSSSCalamityLoansModal"
        />
        <PrintModal
            :pdf-link="printReportPhilhealthContributionsPDFLink"
            :spreadsheet-link="printReportPhilhealthContributionsSpreadsheetLink"
            title="Philhealth Contributions" id="printReportPhilhealthContributionsModal"
        />
        <PrintModal
            :pdf-link="printReportInsuranceContributionsPDFLink"
            :spreadsheet-link="printReportInsuranceContributionsSpreadsheetLink"
            title="Insurance Contributions" id="printReportInsuranceContributionsModal"
        />
        <PrintModal
            :pdf-link="printReportEmployeeSalesPDFLink"
            :spreadsheet-link="printReportEmployeeSalesSpreadsheetLink"
            title="Employee Sales" id="printReportEmployeeSalesModal"
        />
        <PrintModal
            :pdf-link="printReportGovernmentContributionsSalesPDFLink"
            :spreadsheet-link="printReportGovernmentContributionsSalesSpreadsheetLink"
            title="Government Contributions Sales" id="printReportGovernmentContributionsSalesModal"
        />
    </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';

import { useFlashMessages } from "@/composables/flashmessages";

import Card from '@/components/utils/Card.vue';
import FormSelect from '@/components/utils/FormSelect.vue';
import TimePeriodSelectTableCard from '@/components/utils/TimePeriodSelectTableCard.vue';
import PrintModal from '@/components/utils/PrintModal.vue';

import handleFetchErrors from "@/utils/handleFetchErrors";

import axios from 'axios';

import scrollToTop from '@/utils/scrollToTop';

const { addFlashMessage } = useFlashMessages();

const companiesList = ref(null);

const selectedCompany = ref({
    COMPANY_CODE: null
});
const selectedTimePeriod = ref({
    TIME_PERIOD: null,
    START_DATE: null,
    END_DATE: null,
});

onMounted(async() => {
    try {
        const companiesResponse = await axios.get(route('api.companies.index'));
        companiesList.value = companiesResponse.data.data;
    } catch(e) {
        console.log(e);
        handleFetchErrors(e, 'Error while fetching companies.');
    }
});

function openReport(name, includeCompanyCode = false, includeTimePeriod = false) {
    const routeName = `api.reports.accounting.${name}`;
    const params = {
        START_DATE: selectedTimePeriod.value.START_DATE,
        END_DATE: selectedTimePeriod.value.END_DATE,
    };

    if (includeTimePeriod)
        params.TIME_PERIOD = selectedTimePeriod.value.TIME_PERIOD;

    if (includeCompanyCode)
        params.COMPANY_CODE = selectedCompany.value.COMPANY_CODE;

    window.open(route(routeName, params), '_blank');
}

const isLoading = ref(false);
async function generateSummaryTransactions() {
    try {
        isLoading.value = true;
        const generateSummaryResponse = await axios.post(route('api.recon.generate-summary-transaction', {
            TIME_PERIOD: selectedTimePeriod.value.TIME_PERIOD,
            START_DATE: selectedTimePeriod.value.START_DATE,
            END_DATE: selectedTimePeriod.value.END_DATE,
            COMPANY_CODE: selectedCompany.value.COMPANY_CODE,
        }))

        addFlashMessage('SUCCESS', generateSummaryResponse.data.message);
        isLoading.value = false;
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, 'Error generating the summary transactions.');
        isLoading.value = false;
    }
}

const printReportSummaryTransactionPDFLink = computed(() => {
    return route('api.reports.accounting.display-summary-transactions', {
        START_DATE: selectedTimePeriod.value.START_DATE,
        END_DATE: selectedTimePeriod.value.END_DATE,
        TIME_PERIOD: selectedTimePeriod.value.TIME_PERIOD,
        COMPANY_CODE: selectedCompany.value.COMPANY_CODE,
    });
});

const printReportSummaryTransactionSpreadsheetLink = computed(() => {
    return route('api.reports.accounting.display-summary-transactions-spreadsheet', {
        START_DATE: selectedTimePeriod.value.START_DATE,
        END_DATE: selectedTimePeriod.value.END_DATE,
        TIME_PERIOD: selectedTimePeriod.value.TIME_PERIOD,
        COMPANY_CODE: selectedCompany.value.COMPANY_CODE,
    });
});

const printReportPurchaseVATPDFLink = computed(() => {
    return route('api.reports.accounting.financial-purchase', {
        START_DATE: selectedTimePeriod.value.START_DATE,
        END_DATE: selectedTimePeriod.value.END_DATE,
        COMPANY_CODE: selectedCompany.value.COMPANY_CODE,
    });
});

const printReportPurchaseVATSpreadsheetLink = computed(() => {
    return route('api.reports.accounting.financial-purchase-spreadsheet', {
        START_DATE: selectedTimePeriod.value.START_DATE,
        END_DATE: selectedTimePeriod.value.END_DATE,
        COMPANY_CODE: selectedCompany.value.COMPANY_CODE,
    });
});

const printReportGovernmentContributionsPDFLink = computed(() => {
    return route('api.reports.accounting.government-contributions', {
        START_DATE: selectedTimePeriod.value.START_DATE,
        END_DATE: selectedTimePeriod.value.END_DATE,
        COMPANY_CODE: selectedCompany.value.COMPANY_CODE,
    });
});

const printReportGovernmentContributionsSpreadsheetLink = computed(() => {
    return route('api.reports.accounting.government-contributions-spreadsheet', {
        START_DATE: selectedTimePeriod.value.START_DATE,
        END_DATE: selectedTimePeriod.value.END_DATE,
        COMPANY_CODE: selectedCompany.value.COMPANY_CODE,
    });
});

const printReportPagibigContributionsPDFLink = computed(() => {
    return route('api.reports.accounting.pagibig-contributions', {
        START_DATE: selectedTimePeriod.value.START_DATE,
        END_DATE: selectedTimePeriod.value.END_DATE,
        COMPANY_CODE: selectedCompany.value.COMPANY_CODE,
    });
});

const printReportPagibigContributionsSpreadsheetLink = computed(() => {
    return route('api.reports.accounting.pagibig-contributions-spreadsheet', {
        START_DATE: selectedTimePeriod.value.START_DATE,
        END_DATE: selectedTimePeriod.value.END_DATE,
        COMPANY_CODE: selectedCompany.value.COMPANY_CODE,
    });
});

const printReportPagibigLoansPDFLink = computed(() => {
    return route('api.reports.accounting.pagibig-loans', {
        START_DATE: selectedTimePeriod.value.START_DATE,
        END_DATE: selectedTimePeriod.value.END_DATE,
        COMPANY_CODE: selectedCompany.value.COMPANY_CODE,
    });
});

const printReportPagibigLoansSpreadsheetLink = computed(() => {
    return route('api.reports.accounting.pagibig-loans-spreadsheet', {
        START_DATE: selectedTimePeriod.value.START_DATE,
        END_DATE: selectedTimePeriod.value.END_DATE,
        COMPANY_CODE: selectedCompany.value.COMPANY_CODE,
    });
});

const printReportPagibigCalamityLoansPDFLink = computed(() => {
    return route('api.reports.accounting.pagibig-calamity-payments', {
        START_DATE: selectedTimePeriod.value.START_DATE,
        END_DATE: selectedTimePeriod.value.END_DATE,
        COMPANY_CODE: selectedCompany.value.COMPANY_CODE,
    });
});

const printReportPagibigCalamityLoansSpreadsheetLink = computed(() => {
    return route('api.reports.accounting.pagibig-calamity-payments-spreadsheet', {
        START_DATE: selectedTimePeriod.value.START_DATE,
        END_DATE: selectedTimePeriod.value.END_DATE,
        COMPANY_CODE: selectedCompany.value.COMPANY_CODE,
    });
});

const printReportSSSContributionsPDFLink = computed(() => {
    return route('api.reports.accounting.sss-contributions', {
        START_DATE: selectedTimePeriod.value.START_DATE,
        END_DATE: selectedTimePeriod.value.END_DATE,
        COMPANY_CODE: selectedCompany.value.COMPANY_CODE,
    });
});

const printReportSSSContributionsSpreadsheetLink = computed(() => {
    return route('api.reports.accounting.sss-contributions-spreadsheet', {
        START_DATE: selectedTimePeriod.value.START_DATE,
        END_DATE: selectedTimePeriod.value.END_DATE,
        COMPANY_CODE: selectedCompany.value.COMPANY_CODE,
    });
});

const printReportSSSLoansPDFLink = computed(() => {
    return route('api.reports.accounting.sss-loans', {
        START_DATE: selectedTimePeriod.value.START_DATE,
        END_DATE: selectedTimePeriod.value.END_DATE,
        COMPANY_CODE: selectedCompany.value.COMPANY_CODE,
    });
});

const printReportSSSLoansSpreadsheetLink = computed(() => {
    return route('api.reports.accounting.sss-loans-spreadsheet', {
        START_DATE: selectedTimePeriod.value.START_DATE,
        END_DATE: selectedTimePeriod.value.END_DATE,
        COMPANY_CODE: selectedCompany.value.COMPANY_CODE,
    });
});

const printReportSSSCalamityLoansPDFLink = computed(() => {
    return route('api.reports.accounting.sss-calamity-payments', {
        START_DATE: selectedTimePeriod.value.START_DATE,
        END_DATE: selectedTimePeriod.value.END_DATE,
        COMPANY_CODE: selectedCompany.value.COMPANY_CODE,
    });
});

const printReportSSSCalamityLoansSpreadsheetLink = computed(() => {
    return route('api.reports.accounting.sss-calamity-payments-spreadsheet', {
        START_DATE: selectedTimePeriod.value.START_DATE,
        END_DATE: selectedTimePeriod.value.END_DATE,
        COMPANY_CODE: selectedCompany.value.COMPANY_CODE,
    });
});

const printReportPhilhealthContributionsPDFLink = computed(() => {
    return route('api.reports.accounting.philhealth-contributions', {
        START_DATE: selectedTimePeriod.value.START_DATE,
        END_DATE: selectedTimePeriod.value.END_DATE,
        COMPANY_CODE: selectedCompany.value.COMPANY_CODE,
    });
});

const printReportPhilhealthContributionsSpreadsheetLink = computed(() => {
    return route('api.reports.accounting.philhealth-contributions-spreadsheet', {
        START_DATE: selectedTimePeriod.value.START_DATE,
        END_DATE: selectedTimePeriod.value.END_DATE,
        COMPANY_CODE: selectedCompany.value.COMPANY_CODE,
    });
});

const printReportInsuranceContributionsPDFLink = computed(() => {
    return route('api.reports.accounting.insurances', {
        START_DATE: selectedTimePeriod.value.START_DATE,
        END_DATE: selectedTimePeriod.value.END_DATE,
        COMPANY_CODE: selectedCompany.value.COMPANY_CODE,
    });
});

const printReportInsuranceContributionsSpreadsheetLink = computed(() => {
    return route('api.reports.accounting.insurances-spreadsheet', {
        START_DATE: selectedTimePeriod.value.START_DATE,
        END_DATE: selectedTimePeriod.value.END_DATE,
        COMPANY_CODE: selectedCompany.value.COMPANY_CODE,
    });
});

const printReportEmployeeSalesPDFLink = computed(() => {
    return route('api.reports.accounting.employee-sales', {
        START_DATE: selectedTimePeriod.value.START_DATE,
        END_DATE: selectedTimePeriod.value.END_DATE,
        COMPANY_CODE: selectedCompany.value.COMPANY_CODE,
    });
});

const printReportEmployeeSalesSpreadsheetLink = computed(() => {
    return route('api.reports.accounting.employee-sales-spreadsheet', {
        START_DATE: selectedTimePeriod.value.START_DATE,
        END_DATE: selectedTimePeriod.value.END_DATE,
        COMPANY_CODE: selectedCompany.value.COMPANY_CODE,
    });
});

const printReportGovernmentContributionsSalesPDFLink = computed(() => {
    return route('api.reports.accounting.government-contributions-sales', {
        START_DATE: selectedTimePeriod.value.START_DATE,
        END_DATE: selectedTimePeriod.value.END_DATE,
        COMPANY_CODE: selectedCompany.value.COMPANY_CODE,
    });
});

const printReportGovernmentContributionsSalesSpreadsheetLink = computed(() => {
    return route('api.reports.accounting.government-contributions-sales-spreadsheet', {
        START_DATE: selectedTimePeriod.value.START_DATE,
        END_DATE: selectedTimePeriod.value.END_DATE,
        COMPANY_CODE: selectedCompany.value.COMPANY_CODE,
    });
});

function visitStoreSummary() {
    const fullUrl = `store-summary/${selectedTimePeriod.value.TIME_PERIOD}/${selectedTimePeriod.value.START_DATE}/${selectedTimePeriod.value.END_DATE}`;
        window.location.href = fullUrl;
}
</script>
