import { ref } from "vue";

import constructFetchError from "./constructFetchError";

import axios from "axios";

export function useEmployeeRates() {
    const employeeRates = ref(null);

    const getEmployeeRates = async (empid) => {
        try {
            const getEmployeeRatesResponse = await axios.get(route('api.employees.rates.index', {
                employee: empid
            }));
            employeeRates.value = getEmployeeRatesResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching employee rates.');
        }
    };

    const postEmployeeRate = async (empid, employeeRate) => {
        try {
            await axios.post(route('api.employees.rates.store', {
                employee: empid
            }), employeeRate);
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while saving employee rate.');
        }
    };
    
    return { employeeRates, getEmployeeRates, postEmployeeRate };
}
