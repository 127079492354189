<template>
    <div v-if="isOpen"
        class="pop-up d-flex justify-content-center align-items-center">
        <div class="container bg-light px-0 py-3 rounded">
            <div class="d-flex justify-content-between align-items-center px-4">
                <h4 class="title mb-0 fw-bold">
                    <i class="icon ph-bold ph-calendar me-2"></i>Change Sold Date
                </h4>
                <button type="button"
                    class="btn-close"
                    @click="emit('close')">
                </button>
            </div>
            <hr />
            <div class="modal-container px-4">
                <div class="modal-padding-container">
                    <form @submit.prevent="updateSoldDateOfBarcodeSequence"
                        class="my-3">
                        <FormInput
                            type="text"
                            v-model="soldDate"
                            label="New Sold Date"
                            class="mb-2"
                            id-prefix="soldDate"
                            :errors="errors.SOLD_DT"
                        />
                        <div class="text-end">
                            <button type="submit"
                                class="btn btn-primary"
                                :disabled="isUpdating">
                                <span v-if="isUpdating"
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true">
                                </span>
                                <i class="icon ph-bold ph-arrow-clockwise me-2"></i>Update
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { useInventory } from "@/composables/data/inventory";
import FormInput from "@/components/utils/FormInput.vue";

const { updateSoldDate } = useInventory();

const props = defineProps({
    inventoryRecord: Object,
    isOpen: Boolean,
});

const emit = defineEmits(["update"]);

const soldDate = ref(null);

const errors = ref({});

const isUpdating = ref(false);

async function updateSoldDateOfBarcodeSequence() {
    errors.value = {};
    isUpdating.value = true;
    try {
        await updateSoldDate(
            props.inventoryRecord.STYLE,
            props.inventoryRecord.DATE,
            props.inventoryRecord.SERIAL,
            props.inventoryRecord.SEQ_NO,
            soldDate.value
        );
        emit("success", "Successfully updated sold date.");
    } catch (e) {
        errors.value = e.errors ?? {};
        if (e.status != 422) {
            emit("error", e.message);
        }
    }
    isUpdating.value = false;
}

watch(
    () => props.isOpen,
    () => {
        soldDate.value = null;
    }
);
</script>

<style scoped>
.container {
    max-width: 600px;
}
</style>
