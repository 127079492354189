import { defineStore } from "pinia";

export const useToastsStore = defineStore('toasts', {
    state: () => {
        return {
            messages: []
        };
    },
    actions: {
        add(type, header, message) {
            this.messages.push({
                type,
                header,
                message
            });
        }
    }
});
