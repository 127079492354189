<template>
    <Card :class="class">
        <template #header>
            I. INVENTORY
        </template>
        <DataTable :loading="isTableLoading" lazy :value="records" :total-records="totalRecords"
            paginator :rows="10" @page="e => { onPage(e); fetchRecords(); }"
            v-model:filters="filters" filter-display="row" @filter="tableOnFilter"
            sort-mode="multiple" @sort="e => { onSort(e); fetchRecords(); }"
            :pt="{ table: { class: 'table table-sm table-bordered table-hover recon-table' } }">
            <Column field="SEQ_NO" header="Seq" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="COLOR" header="Color" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="PREV_LOC" header="Prev. Loc." sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="LOCATION" header="Loc." sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="COMMENT_ID" header="Comment" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="EVENT_DT" header="Evnt" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="PRICE" header="Price" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="CREATED_BY" header="CRTD" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <template #empty>
                <div class="text-center">
                    No data.
                </div>
            </template>
        </DataTable>
    </Card>
</template>

<script setup>
import { ref, watch } from 'vue';

import Card from '@/components/utils/Card.vue';

import { useFlashMessages } from '@/composables/flashmessages';
import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useInventory } from '@/composables/data/inventory';
import { useInventoryNoBarcodes } from '@/composables/data/inventoryNoBarcodes';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

import scrollToTop from '@/utils/scrollToTop';

const props = defineProps({
    bagIdentifier: Object,
    location: String,
    refreshFlag: Boolean,
    class: String,
});

const { addFlashMessage } = useFlashMessages();

const { params, filters, onPage, onFilter, onSort } = useDataTableParams(
    ['SEQ_NO', 'COLOR', 'PREV_LOC', 'LOCATION', 'COMMENT_ID', 'EVENT_DT', 'PRICE', 'CREATED_BY']);

/**
 * This component will either fetch from inventory or inventory (no barcodes)
 * depending on the presence of a DATE field in the bagIdentifier.
 * These refs will serve as unified records holder for the table component above.
 */
const records = ref(null);
const totalRecords = ref(null);

const { inventoryRecords, inventoryTotalRecords, getInventoryRecords } = useInventory();
const { inventoryNoBarcodes, inventoryNoBarcodesTotalRecords, getInventoryNoBarcodes } = useInventoryNoBarcodes();

const isTableLoading = ref(false);

const fetchTimeout = ref(null);

async function fetchRecords() {
    records.value = null;
    inventoryRecords.value = null;

    if(!props.bagIdentifier)
        return;

    isTableLoading.value = true;
    try {
        if(props.bagIdentifier.DATE) {
            await getInventoryRecords({
                ...params.value,
                filters: {
                    ...params.value.filters,
                    STYLE: {
                        value: props.bagIdentifier.STYLE,
                        matchMode: 'equals',
                    },
                    DATE: {
                        value: props.bagIdentifier.DATE,
                        matchMode: 'equals',
                    },
                    SERIAL: {
                        value: props.bagIdentifier.SERIAL,
                        matchMode: 'equals',
                    },
                },
            });
            records.value = inventoryRecords.value;
            totalRecords.value = inventoryTotalRecords.value;
        }

        else {
            await getInventoryNoBarcodes({
                ...params.value,
                filters: {
                    ...params.value.filters,
                    STYLE: {
                        value: props.bagIdentifier.STYLE,
                        matchMode: 'equals',
                    },
                    ROWNUM: {
                        value: props.bagIdentifier.SEQ_NO,
                        matchMode: 'equals',
                    },
                    [props.bagIdentifier.NOBC_SOURCE]: {
                        value: props.location,
                        matchMode: 'equals',
                    },
                    COMMENT_ID: {
                        value: props.bagIdentifier.SERIAL,
                        matchMode: 'equals',
                    }
                },
            });
            records.value = inventoryNoBarcodes.value;
            totalRecords.value = inventoryNoBarcodesTotalRecords.value;
        }
    } catch(e) {
        addFlashMessage('ERROR', e.message);
        scrollToTop();
    }
    isTableLoading.value = false;
}

async function tableOnFilter(e) {
    onFilter(e);
    if(fetchTimeout.value)
        clearTimeout(fetchTimeout.value);
    fetchTimeout.value = setTimeout(fetchRecords, 300);
}

watch(() => [props.bagIdentifier], fetchRecords);

watch(() => props.refreshFlag, fetchRecords);
</script>
