<style scoped>
#glAccountsTableContainer,
#associatedVouchersTableContainer {
    height: 250px;
    overflow-y: auto;
}

#glAccountsTableContainer thead th,
#associatedVouchersTableContainer thead th {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: var(--bs-card-bg);
}
</style>

<template>
    <div class="row mb-3">
        <div class="col-md-8">
            <Card>
                <div class="row g-3 mb-3">
                    <div class="col-md-8">
                        <FormSelect label="Company"
                            v-model="selectedCompany" :options="companies"
                            :option-to-string="(company) => company.COMPANY_DESCRIPTION"
                            id-prefix="companyFilter" :is-horizontal="true" />
                    </div>
                    <div class="col-md-4">
                        <FormSelect label="List Type"
                            v-model="selectedListType" :options="listTypes"
                            id-prefix="listTypeFilter" :is-horizontal="true" />
                    </div>
                </div>
                <FormSelect label="Payroll Voucher"
                    v-model="selectedPayrollVoucher" :options="payrollVouchers"
                    :option-to-string="(voucher) => voucher.VOUCHER_ID + ' | ' +
                        voucher.VOUCHER_DATE + ' | ' + voucher.VOUCHER_AMT + ' | ' +
                        voucher.DESCRIPTION + ' | ' + voucher.PTR"
                    id-prefix="payrollVoucher" :is-horizontal="true"
                    class="mb-3" />
                <div class="row g-3 mb-3">
                    <div class="col-lg-6">
                        <FormInput type="date" label="Voucher Date"
                            v-model="selectedPayrollVoucher.VOUCHER_DATE" id-prefix="voucherDate"
                            :is-horizontal="true" :disabled="true" />
                    </div>
                    <div class="col-lg-6">
                        <FormInput type="text" label="Paytype"
                            v-model="voucherTimePeriod.PAYTYPE" id-prefix="paytype"
                            :is-horizontal="true" :disabled="true" />
                    </div>
                </div>
                <div class="row g-3 mb-3">
                    <div class="col-lg-6">
                        <FormInput type="number" label="Amount"
                            v-model="selectedPayrollVoucher.VOUCHER_AMT" id-prefix="amount"
                            :is-horizontal="true" :disabled="true" />
                    </div>
                    <div class="col-lg-6">
                        <FormInput type="text" label="Description"
                            v-model="selectedPayrollVoucher.DESCRIPTION" id-prefix="description"
                            :is-horizontal="true" :disabled="true" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <FormInput type="text" label="Time Period"
                            v-model="voucherTimePeriod.TIME_PERIOD" id-prefix="timePeriod"
                            :is-horizontal="true" :disabled="true" />
                    </div>
                    <div class="col-sm-3">
                        <FormInput type="text" label="Start Date" :hide-label="true"
                            v-model="voucherTimePeriod.START_DATE" id-prefix="startDate"
                            :disabled="true" />
                    </div>
                    <div class="col-sm-3">
                        <FormInput type="text" label="End Date" :hide-label="true"
                            v-model="voucherTimePeriod.END_DATE" id-prefix="endDate"
                            :disabled="true" />
                    </div>
                </div>
            </Card>
        </div>
    </div>
    <Card class="mb-3">
        <div class="row mb-3">
            <div class="col-md-3">
                <h2 class="h3">GL Account Name</h2>
            </div>
            <div class="col-md-3">
                <FormInput type="text" label="Total Debit"
                    v-model="glAccountTotals.TOTAL_DEBIT" id-prefix="glAcctTotalDebit"
                    :is-horizontal="true" :disabled="true" />
            </div>
            <div class="col-md-3">
                <FormInput type="text" label="Total Credit"
                    v-model="glAccountTotals.TOTAL_CREDIT" id-prefix="glAcctTotalCredit"
                    :is-horizontal="true" :disabled="true" />
            </div>
            <div class="col-md-3 text-md-end">
                <button type="button" class="btn btn-primary"
                    :disabled="selectedPayrollVoucher.VOUCHER_ID == ''"
                    data-bs-toggle="modal" data-bs-target="#printVoucherModal">
                    Print Voucher
                </button>
            </div>
        </div>
        <div class="table-responsive" id="glAccountsTableContainer">
            <table class="table table-bordered table-hover">
                <thead>
                    <tr>
                        <th>GL Seq. No.</th>
                        <th>Class</th>
                        <th>Acct. Name</th>
                        <th>Debit</th>
                        <th>Credit</th>
                        <th>Payee Name</th>
                        <th>CRTD</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="glAccounts == null">
                        <td colspan="7" class="text-center">Please select a Payroll Voucher.</td>
                    </tr>
                    <tr v-else-if="glAccounts.length == 0">
                        <td colspan="7" class="text-center">No GL Accounts.</td>
                    </tr>
                    <tr v-else v-for="voucher in glAccounts">
                        <td>{{ voucher.GL_SEQ_NO }}</td>
                        <td>{{ voucher.CLASS }}</td>
                        <td>{{ voucher.ACCTNAME }}</td>
                        <td>{{ voucher.DEBIT }}</td>
                        <td>{{ voucher.CREDIT }}</td>
                        <td>{{ voucher.PAYEE_NAME }}</td>
                        <td>{{ voucher.CREATED_BY }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </Card>
    <Card class="mb-3">
        <div class="row mb-3">
            <div class="col-md-3">
                <h2 class="h3">Associated Vouchers</h2>
            </div>
            <div class="col-md-3">
                <FormInput type="text" label="Total Debit"
                    v-model="associatedVoucherTotals.TOTAL_DEBIT" id-prefix="associatedVoucherTotalDebit"
                    :is-horizontal="true" :disabled="true" />
            </div>
            <div class="col-md-3">
                <FormInput type="text" label="Total Credit"
                    v-model="associatedVoucherTotals.TOTAL_CREDIT" id-prefix="associatedVoucherTotalCredit"
                    :is-horizontal="true" :disabled="true" />
            </div>
            <div class="col-md-3 text-md-end">
                <button type="button" class="btn btn-primary"
                    :disabled="selectedPayrollVoucher.VOUCHER_ID == ''"
                    data-bs-toggle="modal" data-bs-target="#printAssociatedVouchersModal">
                    Print Assoc Vouchers
                </button>
            </div>
        </div>
        <div class="table-responsive" id="associatedVouchersTableContainer">
            <table class="table table-bordered table-hover">
                <thead>
                    <tr>
                        <th>GL Seq. No.</th>
                        <th>Class</th>
                        <th>Acct. Name</th>
                        <th>Debit</th>
                        <th>Credit</th>
                        <th>Payee Name</th>
                        <th>Payee Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="associatedVouchers == null">
                        <td colspan="7" class="text-center">Please select a Payroll Voucher.</td>
                    </tr>
                    <tr v-else-if="associatedVouchers.length == 0">
                        <td colspan="7" class="text-center">No associated vouchers.</td>
                    </tr>
                    <tr v-else v-for="voucher in associatedVouchers">
                        <td>{{ voucher.GL_SEQ_NO }}</td>
                        <td>{{ voucher.CLASS }}</td>
                        <td>{{ voucher.ACCTNAME }}</td>
                        <td>{{ voucher.DEBIT }}</td>
                        <td>{{ voucher.CREDIT }}</td>
                        <td>{{ voucher.PAYEE_NAME }}</td>
                        <td>{{ voucher.PAYEE_DATE }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </Card>

    <PrintModal :pdf-link="printVoucherPDFLink"
        :spreadsheet-link="printVoucherSpreadsheetLink"
        title="Print Voucher" id="printVoucherModal" />

    <PrintModal :pdf-link="printAssociatedVouchersPDFLink"
        :spreadsheet-link="printAssociatedVouchersSpreadsheetLink"
        title="Print Associated Vouchers" id="printAssociatedVouchersModal" />
</template>

<script setup>
import { ref, computed, onMounted, watch } from "vue";

import Card from "../../utils/Card.vue";
import FormInput from "../../utils/FormInput.vue";
import FormSelect from "../../utils/FormSelect.vue";
import PrintModal from "../../utils/PrintModal.vue";

import axios from "axios";

const listTypes = ["ALL", "WEEKLY", "BIMONTHLY-ADMIN", "BIMONTHLY-SALES"];

const ptrAssociatedVouchersParamMapping = {
    "W": {
        PAYTYPE: "WEEKLY"
    },
    "A": {
        PAYTYPE: "BIMONTHLY",
        DEPT_NOT_LIKE: "S%"
    },
    "S": {
        PAYTYPE: "BIMONTHLY",
        DEPT_LIKE: "S%"
    }
};

const companies = ref(null);
const payrollVouchers = ref(null);
const selectedListType = ref(listTypes[0]);

const selectedCompany = ref(null);
const selectedPayrollVoucher = ref({
    VOUCHER_ID: "",
    VOUCHER_DATE: "",
    VOUCHER_AMT: "",
    DESCRIPTION: ""
});
const voucherTimePeriod = ref({
    TIME_PERIOD: "",
    START_DATE: "",
    END_DATE: "",
    PAYTYPE: ""
});
const glAccountTotals = ref({
    TOTAL_DEBIT: "",
    TOTAL_CREDIT: ""
});
const glAccounts = ref(null);
const associatedVoucherTotals = ref({
    TOTAL_DEBIT: "",
    TOTAL_CREDIT: ""
});
const associatedVouchers = ref(null);

const printVoucherPDFLink = computed(() => {
    if(!selectedPayrollVoucher.value)
        return;

    return route('print.finVoucherAllGeneric2', {
        VOUCHER_ID: selectedPayrollVoucher.value.VOUCHER_ID
    });
});

const printVoucherSpreadsheetLink = computed(() => {
    if(!selectedPayrollVoucher.value)
        return;

    return route('api.vouchers.voucher-spreadsheet', {
        voucher: selectedPayrollVoucher.value.VOUCHER_ID
    });
});

const printAssociatedVouchersPDFLink = computed(() => {
    if(!selectedPayrollVoucher.value)
        return;

    return route('print.associated-vouchers-pdf', {
        VOUCHER_ID: selectedPayrollVoucher.value.VOUCHER_ID
    });
});

const printAssociatedVouchersSpreadsheetLink = computed(() => {
    if(!selectedPayrollVoucher.value)
        return;

    return route('api.vouchers.associated-vouchers-spreadsheet', {
        voucher: selectedPayrollVoucher.value.VOUCHER_ID
    });
});

onMounted(async() => {
    try {
        const companiesResponse = await axios.get(route('api.companies.index'));
        companies.value = companiesResponse.data.data;
    } catch(e) {
        console.log(e);
    }
});

watch(selectedCompany, async () => {
    if(selectedCompany.value == null)
        return;
    fetchPayrollVouchers();
});

watch(selectedListType, fetchPayrollVouchers);

async function fetchPayrollVouchers() {
    try {
        const vouchersResponse = await axios.get(route('api.vouchers.index', {
            PTR_TYPE: selectedListType.value,
            VOUCHER_ID_LIKE: selectedCompany.value.COMPANY_CODE + '-%',
            multiSortMeta: [
                {
                    field: 'VOUCHER_DATE',
                    order: -1
                }
            ]
        }));
        payrollVouchers.value = vouchersResponse.data.data;
    } catch(e) {
        console.log(e);
    }
}

watch(selectedPayrollVoucher, async() => {
    if(selectedPayrollVoucher.value == null)
        return;

    try {
        const timePeriodResponse = await axios.get(route('api.time-periods.show', {
            time_period: Number(selectedPayrollVoucher.value.PTR.split("/")[2])
        }));
        voucherTimePeriod.value = timePeriodResponse.data.data;

        const glAccountTotalsResponse = await axios.get(route('api.finvoucherall.totals', {
            VOUCHER_ID: selectedPayrollVoucher.value.VOUCHER_ID,
            VOUCHER_ID_LIKE: selectedCompany.value.COMPANY_CODE + '-%'
        }));
        glAccountTotals.value = glAccountTotalsResponse.data.data;

        const glAccountsResponse = await axios.get(route('api.finvoucherall.entries', {
            VOUCHER_ID: selectedPayrollVoucher.value.VOUCHER_ID
        }));
        glAccounts.value = glAccountsResponse.data.data;

        const associatedVoucherParams = {
            ...ptrAssociatedVouchersParamMapping[selectedPayrollVoucher.value.PTR.split("/")[1]],
            IS_CC: true,
            VOUCHER_ID_LIKE: selectedCompany.value.COMPANY_CODE + "-%",
            PAYEE_DATE_START: voucherTimePeriod.value.START_DATE,
            PAYEE_DATE_END: voucherTimePeriod.value.END_DATE
        };
        const associatedVoucherTotalsResponse = await axios.get(
            route('api.finvoucherall.totals', associatedVoucherParams));
        associatedVoucherTotals.value = associatedVoucherTotalsResponse.data.data;
        const associatedVouchersResponse = await axios.get(
            route('api.finvoucherall.entries', associatedVoucherParams));
        associatedVouchers.value = associatedVouchersResponse.data.data;
    } catch(e) {
        console.log(e);
    }
});
</script>
