<template>
    <Card>
        <template #header>
            Inventory Records
        </template>
        <div class="row g-3 mb-3">
            <div class="col-md-3">
                <FormInput type="text" v-model="styleDisplay" disabled
                    label="Style" id-prefix="style" />
            </div>
            <div class="col-md-3">
                <FormInput type="text" v-model="dateDisplay" disabled
                    label="Date" id-prefix="date" />
            </div>
            <div class="col-md-3">
                <FormInput type="text" v-model="serialDisplay" disabled
                    label="Serial" id-prefix="serial" />
            </div>
        </div>
        <DataTable :loading="isLoading" lazy :value="inventoryRecords" :total-records="inventoryTotalRecords"
            paginator :rows="10" @page="e => { onPage(e); fetchInventory(); }" 
            filter-display="row" v-model:filters="filters" @filter="tableOnFilter"
            sort-mode="multiple" @sort="e => { onSort(e); fetchInventory(); }"
            selection-mode="single" v-model:selection="selectedInventoryRecord"
            :pt="{ table: { class: ' table table-bordered table-hover' } }">
            <Column field="SEQ_NO" header="Seq" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="COLOR" header="Color" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="PRICE" header="Price" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="PREV_LOC" header="Prev. Loc." sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="LOCATION" header="Location" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="SOLD_DT" header="Sold Date" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="EMPID" header="EMPID" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="FNAME" header="First Name" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="LNAME" header="Last Name" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="COMMENT_ID" header="Comment ID" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="COMMENT_RPT_DATE" header="Comment Rpt. Date" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="RECEIPT_NO" header="Receipt" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="SELL_TO_TYPE" header="Sell to Type" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="SELL_TO_ID" header="Sell to ID" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="DISCOUNT" header="Discount" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="SALE_TYPE" header="Sale Type" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="CREATED_BY" header="Created" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <template #footer>
                There are {{ inventoryTotalRecords ?? 0 }} records.
            </template>
            <template #empty>
                <div class="text-center">
                    No data.
                </div>
            </template>
        </DataTable>
    </Card>
</template>

<script setup>
import { computed, ref, watch } from 'vue';

import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useInventory } from '@/composables/data/inventory';

import Card from '@/components/utils/Card';
import FormInput from '@/components/utils/FormInput';

import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

const props = defineProps({
    style: String,
    date: String,
    serial: String,
    refreshFlag: null,
});

const emit = defineEmits(['error']);

const { params, filters, onPage, onFilter, onSort } = useDataTableParams(
    ['SEQ_NO', 'COMMENT_ID', 'COMMENT_RPT_DATE', 'PREV_LOC', 'LOCATION', 'PRICE',
    'SOLD_DT', 'COLOR', 'SALE_TYPE', 'RECEIPT_NO', 'CREATED_BY', 'EMPID', 'FNAME', 'LNAME',
    'SELL_TO_TYPE', 'SELL_TO_ID', 'DISCOUNT']);

const styleDisplay = computed(() => props.style);
const dateDisplay = computed(() => props.date);
const serialDisplay = computed(() => props.serial);

const { inventoryRecords, inventoryTotalRecords, getInventoryRecords } = useInventory();

const selectedInventoryRecord = defineModel();

const isLoading = ref(false);

const fetchTimeout = ref(null);

async function fetchInventory() {
    inventoryRecords.value = null;

    if(!props.style || !props.date || !props.serial)
        return;

    isLoading.value = true;
    try {
        await getInventoryRecords({
            ...params.value,
            filters: {
                ...params.value.filters,
                STYLE: {
                    value: props.style,
                    matchMode: 'equals',
                },
                DATE: {
                    value: props.date,
                    matchMode: 'equals',
                },
                SERIAL: {
                    value: props.serial,
                    matchMode: 'equals',
                },
            }
        });
    } catch(e) {
        emit('error', e.message);
    }
    isLoading.value = false;
}

function tableOnFilter(e) {
    onFilter(e);
    if(fetchTimeout.value)
        clearTimeout(fetchTimeout.value);
    fetchTimeout.value = setTimeout(fetchInventory, 300);
}

watch(() => [props.style, props.date, props.serial], fetchInventory);
watch(() => props.refreshFlag, fetchInventory);
</script>
