import constructFetchError from "./constructFetchError";

import axios from 'axios';

export function useReconInitializations() {
    const postReconInitialization = async (details) => {
        try {
            const postReconInitializationResponse = await axios.post(route('api.recon.initializations.store'), details);
            return postReconInitializationResponse;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while creating recon initialization.');
        }
    };
    
    return { postReconInitialization };
}
