<template>
    <div v-if="isOpen" class="pop-up d-flex justify-content-center align-items-center">
        <Card>
            <template #header>
                <div class="d-flex justify-content-between pt-2">
                    <h4 class="title">Export Sold Discrepancies</h4>
                    <button type="button" class="btn-close" @click="emit('close')"></button>
                </div>
            </template>
            <TimePerioSelectTableCard v-model="selectedTimePeriod"
                show-only-type="MONTHLY" />
            <template #footer>
                <div class="d-grid">
                    <button type="button" class="btn btn-primary"
                        :disabled="!selectedTimePeriod"
                        @click="exportDiscrepancies">
                        Export
                    </button>
                </div>
            </template>
        </Card>
    </div>
</template>

<script setup>
import { ref } from 'vue';

import Card from '@/components/utils/Card';
import TimePerioSelectTableCard from '@/components/utils/TimePeriodSelectTableCard';

const props = defineProps({
    location: String,
    isOpen: Boolean,
});

const emit = defineEmits(['close']);

const selectedTimePeriod = ref(null);

function exportDiscrepancies() {
    window.open(route('api.locations.sold-discrepancies-spreadsheet.show', {
        location: props.location,
        start_date: selectedTimePeriod.value.START_DATE,
        end_date: selectedTimePeriod.value.END_DATE,
    }));
}
</script>

<style scoped>
.pop-up {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: rgb(0, 0, 0, 0.5);
}

.pop-up .card {
    width: 60%;
}

@media (max-width: 768px) {
    .pop-up .card {
        width: 85%;
    }
}

@media (max-width: 992px) {
    .pop-up .card {
        width: 70%;
    }
}
</style>
