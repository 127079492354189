<template>
    <Card>
        <template #header>
            Sold from Other Store
        </template>
        <DataTable :loading="isLoading" :value="employeeSoldFromOtherStores"
            paginator :rows="10"
            v-model:filters="filters" filter-display="row" 
            :pt="{ table: { class: ' table table-bordered table-hover' } }">
            <Column field="LOCATION" header="Location" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="SOLD_DT_YEAR" header="Year" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="SOLD_DT_MONTH" header="Month" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <template #empty>
                <div class="text-center">
                    No data yet.
                </div>
            </template>
        </DataTable>
    </Card>
</template>

<script setup>
import { ref, watch } from 'vue';

import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useEmployeeSoldFromOtherStores } from '@/composables/data/employeeSoldFromOtherStores';

import Card from '@/components/utils/Card.vue';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

import moment from 'moment';

const props = defineProps({
    startTimePeriod: Number,
    endTimePeriod: Number,
    location: String,
    employeeId: Number,
});

const emit = defineEmits(['error']);

const { filters } = useDataTableParams(['LOCATION', 'SOLD_DT_YEAR', 'SOLD_DT_MONTH']);

const { employeeSoldFromOtherStores, getEmployeeSoldFromOtherStores } = useEmployeeSoldFromOtherStores();

const isLoading = ref(false);

watch(() => props.employeeId, async () => {
    employeeSoldFromOtherStores.value = null;

    if(!props.employeeId || !props.startTimePeriod || !props.endTimePeriod || !props.location)
        return;

    isLoading.value = true;
    try {
        await getEmployeeSoldFromOtherStores(props.employeeId, props.startTimePeriod, props.endTimePeriod, props.location);
        employeeSoldFromOtherStores.value = employeeSoldFromOtherStores.value.map(
            soldFromOtherStore => ({
                ...soldFromOtherStore,
                SOLD_DT_MONTH: moment().month(soldFromOtherStore.SOLD_DT_MONTH - 1).format("MMMM")
            })
        );
    } catch(e) {
        emit('error', e.message);
    }
    isLoading.value = false;
});
</script>
