import { ref } from "vue";

import constructFetchError from "./constructFetchError";

import axios from 'axios';

export function useReconFinSummaryLocationBases() {
    const reconFinSummaryLocationBase = ref(null);

    const glAccounts = ref(null);

    const getReconFinSummaryLocationBase = async (companyCode, timePeriod, location, brand) => {
        try {
            const getReconFinSummaryLocationBaseResponse =
                await axios.get(route('api.recon-fin-summary-location-base.show', {
                    COMPANY_CODE: companyCode,
                    TIME_PERIOD: timePeriod,
                    LOCATION: location,
                    BRAND: brand
                }));
            reconFinSummaryLocationBase.value = getReconFinSummaryLocationBaseResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching location summary.');
        }
    };

    const putReconFinSummaryLocationBase = async (companyCode, timePeriod, location, brand, reconFinSummartLocationBase) => {
        try {
            const putReconFinSummaryLocationBaseResponse =
                await axios.put(route('api.recon-fin-summary-location-base.update', {
                    COMPANY_CODE: companyCode,
                    TIME_PERIOD: timePeriod,
                    LOCATION: location,
                    BRAND: brand
                }), reconFinSummartLocationBase);
            return putReconFinSummaryLocationBaseResponse;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while updating location summary.');
        }
    };

    const getSalesGLAccounts = async (companyCode, timePeriod) => {
        try {
            const getAccountsResponse = await axios.get(route('api.recon-fin-summary-location-base.sales-accounts.index', {
                COMPANY_CODE: companyCode,
                TIME_PERIOD: timePeriod,
            }));
            glAccounts.value = getAccountsResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching Sales GL Accounts.');
        }
    };

    const getCollectionsGLAccounts = async (companyCode, timePeriod, locationFilter = null) => {
        try {
            const getAccountsResponse = await axios.get(route('api.recon-fin-summary-location-base.collections-accounts.index', {
                COMPANY_CODE: companyCode,
                TIME_PERIOD: timePeriod,
                filters: {
                    LOCATION: {
                        value: locationFilter,
                        matchMode: 'startsWith'
                    }
                }
            }));
            glAccounts.value = getAccountsResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching Collections GL Accounts.');
        }
    };

    return { reconFinSummaryLocationBase, getReconFinSummaryLocationBase, putReconFinSummaryLocationBase,
        glAccounts, getSalesGLAccounts, getCollectionsGLAccounts };
}
