<template>
    <div class="row g-3">
        <div class="col-md-8">
            <DetailTableCard :sample-id="sampleId" :sample-quantity="sampleQuantity"
                :refresh-flag="refreshSampleDetailsTableFlag"
                :selected-sample-detail="selectedSampleDetailFromTable"
                @sample-quantity-change="newQuantity => sampleQuantity = newQuantity"
                @select="selectedSampleDetail => selectedSampleDetailFromTable = selectedSampleDetail"/>
        </div>
        <div class="col-md-4">
            <CreateOrUpdateDetailCard :sample-id="sampleId" :sample-quantity="sampleQuantity"
                :selected-sample-detail="selectedSampleDetailFromTable"
                @create-or-update="_ => {  refreshSampleDetailsTableFlag++; }" />
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';

import DetailTableCard from './DetailTableCard.vue';
import CreateOrUpdateDetailCard from './CreateOrUpdateDetailCard.vue';

const sampleId = ref(null);
const sampleQuantity = ref(100);

const selectedSampleDetailFromTable = ref(null);

const refreshSampleDetailsTableFlag = ref(0);

onMounted(() => {
    sampleId.value = Number(new URLSearchParams(window.location.search).get('SAMPLE_ID'));
});
</script>
