<template>
    <Modal id="selectGLModal">
        <template #header>
            <h5 class="modal-title">Select GL Account</h5>
        </template>
        <FormSelect label="CLASS" :options="classesList"
            v-model="selectedClass"
            :option-to-string="(classObj) => classObj.CLASS"
            id-prefix="class" is-horizontal class="mb-3"
            :errors="errors.CLASS"
            @change="classOnChange" />
        <FormSelect label="ACCTNAME" :options="accountNamesList"
            v-model="selectedAccountName"
            :option-to-string="(accountNameObj) => accountNameObj.ACCTNAME"
            id-prefix="accountName" is-horizontal class="mb-3"
            :errors="errors.ACCTNAME"
            @change="accountNameOnChange" />
        <FormInput type="text" label="GL ID" v-model="selectedGLAccountName.GL_ID"
            id-prefix="glId" is-horizontal required disabled
            :errors="errors.GL_ID" />
        <template #footer>
            <button type="button" class="btn btn-outline-secondary" ref="closeButton"
                data-bs-dismiss="modal">
                Close
            </button>
            <button type="button" class="btn btn-primary"
                :disabled="selectedGLAccountName == null || selectedGLAccountName == ''"
                @click="selectGLButtonOnClick">
                Select
            </button>
        </template>
    </Modal>
</template>

<script setup>
import { ref, onMounted } from "vue";

import Modal from "../../../utils/Modal.vue";
import FormInput from "../../../utils/FormInput.vue";
import FormSelect from "../../../utils/FormSelect.vue";

import axios from "axios";

const emit = defineEmits(['select-gl']);

const classesList = ref([]);
const accountNamesList = ref([]);

const selectedClass = ref(null);
const selectedAccountName = ref(null);

const closeButton = ref(null);

const selectedGLAccountName = ref({
    CLASS: "",
    ACCTNAME: "",
    GL_ID: ""
});

const errors = ref({});

onMounted(async() => {
    try {
        const classesResponse = await axios.get(route('api.gl-accounts.classes'));
        classesList.value = classesResponse.data.data;
    } catch(e) {
        console.log(e);
        errors.value.CLASS = [
            'An error occurred while fetching classes. ' +
            'Contact your administrator.'
        ];
    }
});

async function classOnChange() {
    try {
        const accountNamesResponse = await axios.get(route('api.gl-accounts.account-names', {
            CLASS: selectedClass.value.CLASS
        }));
        accountNamesList.value = accountNamesResponse.data.data;
    } catch(e) {
        console.log(e);
        errors.value.CLASS = [
            'An error occurred while fetching account names. ' +
            'Contact your administrator.'
        ];
    }
}

async function accountNameOnChange() {
    if(selectedClass.value == null || selectedAccountName.value == null)
        return;

    try {
        const glAccountNamesResponse = await axios.get(route('api.gl-accounts.index', {
            CLASS: selectedClass.value.CLASS,
            ACCTNAME: selectedAccountName.value.ACCTNAME
        }));
        selectedGLAccountName.value = glAccountNamesResponse.data.data[0];
        if(!selectedGLAccountName.value)
            errors.value.GL_ID = [
                'No GL Account found with the specified CLASS/ACCTNAME.'
            ];
    } catch(e) {
        console.log(e);
        errors.value.GL_ID = [
            'An error occurred while fetching GL Account Name. ' +
            'Contact your administrator.'
        ];
    }
}

function selectGLButtonOnClick() {
    closeButton.value.click();
    emit('select-gl', selectedGLAccountName.value);
}
</script>
