import { ref } from "vue";

import constructFetchError from "./constructFetchError";

import axios from "axios";

export function useJobordersNew() {
    const jobordersNew = ref(null);
    const totalRecords = ref(null);

    const joborderNew = ref(null);

    const getJobordersNew = async (params) => {
        try {
            const jobordersNewResponse = await axios.get(route('api.joborder-new.index', params));
            jobordersNew.value = jobordersNewResponse.data.data;
            totalRecords.value = jobordersNewResponse.data.meta.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching joborders.');
        }
    };

    const getJoborderNew = async (jono) => {
        try {
            const joborderNewResponse = await axios.get(route('api.joborder-new.show', {
                joborder_new: jono
            }));
            joborderNew.value = joborderNewResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching joborder.');
        }
    };

    const getNextJONO = async (prefix, year) => {
        try {
            const nextJONOResponse = await axios.get(route('api.joborder-new.next-jono.show', { prefix, year }));
            return nextJONOResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching next JONO.');
        }
    };

    const postJoborderNew = async (joborderNew) => {
        try {
            const postJoborderNewResponse = await axios.post(route('api.joborder-new.store'), joborderNew);
            return postJoborderNewResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while creating joborder.');
        }
    };

    const putJoborderNew = async (jono, joborderNew) => {
        try {
            const putJoborderNewResponse = await axios.put(route('api.joborder-new.update', {
                joborder_new: jono
            }), joborderNew);
            return putJoborderNewResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while updating joborder.');
        }
    };

    return { jobordersNew, totalRecords, joborderNew, getJobordersNew, getJoborderNew,
        getNextJONO, postJoborderNew, putJoborderNew };
}
