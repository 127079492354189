<template>
    <div class="row justify-content-center">
        <div class="col-md-4">
            <Card>
                <template #header>
                    Select Yearly Time Period
                </template>
                <TimePeriodTable v-model="timePeriod"
                    :show-from-current-date="true"
                    :hide-properties="new Set(['PAYTYPE', 'STATUS'])"
                    paytype="YEARLY"
                    :sort="[{ field: 'TIME_PERIOD', order: -1 }]" />
            </Card>
        </div>
        <div class="col-md-4">
            <Card class="mb-3">
                <template #header>
                    Generate Yearly Reports
                </template>
                <FormSelect v-model="paytype" label="Paytype" id-prefix="paytype"
                    :options="['WEEKLY', 'BIMONTHLY - SALES/ROVING', 'BIMONTHLY - OFFICE']"
                    class="mb-3" />
                <FormSelect v-model="companyCode" label="Company" id-prefix="company"
                    :options="companies"
                    :option-value="company => company.COMPANY_CODE"
                    :option-to-string="company => company.COMPANY_DESCRIPTION" />
                <template #footer>
                    <div class="text-end">
                        <button type="button" class="btn btn-primary me-1"
                            :disabled="!timePeriod || !paytype || isGenerating"
                            @click="_ => { generateSpreadsheetReport('days-of-work'); }">
                            <span v-if="isGenerating" class="spinner-border spinner-border-sm"
                                role="status" aria-hidden="true"></span>
                            Days of Work Report
                        </button>
                        <button type="button" class="btn btn-primary me-1"
                            :disabled="!timePeriod || !paytype || isGenerating"
                            @click="_ => { generateSpreadsheetReport('service-incentives'); }">
                            <span v-if="isGenerating" class="spinner-border spinner-border-sm"
                                role="status" aria-hidden="true"></span>
                            Service Incentive Report
                        </button>
                    </div>
                </template>
            </Card>
            <TopAttendanceTableCard :time-period="timePeriod?.TIME_PERIOD"
                class="mb-3"
                @error="message => {
                    addFlashMessage('ERROR', message);
                    scrollToTop();
                }" />
            <GeneratedReportsStatusesTableCard :refresh-flag="reportGenerationsRefreshFlag" />
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';

import { useFlashMessages } from '@/composables/flashmessages';
import { useCompanies } from '@/composables/data/companies';
import { useAccountingYearlyReportGenerations } from '@/composables/data/accountingYearlyReportGenerations';

import Card from '@/components/utils/Card.vue';
import FormSelect from '@/components/utils/FormSelect.vue';
import TimePeriodTable from '@/components/utils/tables/TimePeriodTable.vue';

import TopAttendanceTableCard from './TopAttendanceTableCard.vue';
import GeneratedReportsStatusesTableCard from './GeneratedReportsStatusesTableCard.vue';

import scrollToTop from '@/utils/scrollToTop';

const { addFlashMessage } = useFlashMessages();

const { companies, getCompanies } = useCompanies();

const { postAccountingYearlyReportGeneration } = useAccountingYearlyReportGenerations();

const timePeriod = ref(null);
const paytype = ref(null);
const companyCode = ref(null);

const reportGenerationsRefreshFlag = ref(false);

const isGenerating = ref(false);

async function fetchCompanies() {
    try {
        await getCompanies();
    } catch(e) {
        addFlashMessage('ERROR', e.message);
        scrollToTop();
    }
}

onMounted(fetchCompanies);

async function generateSpreadsheetReport(reportType) {
    isGenerating.value = true;
    try {
        const response = await postAccountingYearlyReportGeneration(
            timePeriod.value.TIME_PERIOD, paytype.value, companyCode.value, reportType);
        addFlashMessage('SUCCESS', response.data.message);
        reportGenerationsRefreshFlag.value = !reportGenerationsRefreshFlag.value;
    } catch(e) {
        addFlashMessage('ERROR', e.message);
    }
    isGenerating.value = false;
    scrollToTop();
}
</script>
