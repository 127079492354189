<template>
    <Card>
        <div class="row p-5">
            <div class="col-12">
                <Card>
                    <div>
                        <div class="d-flex mb-5">
                            <FormInput label="Location:" is-horizontal input-class="form-control"
                                class="w-50" disabled v-model="location.LOCATION"/>
                            <button class="btn btn-primary w-25 ms-1"
                                data-bs-toggle="modal"
                                data-bs-target="#modalSelectLocation"
                                >Select Location</button>
                            <button class="btn btn-primary w-25 ms-1"
                                :disabled="!location.LOCATION"
                                @click="reset">Reset Location</button>
                        </div>
                        <div class="col-12 d-flex justify-content-center">
                            <button class="btn btn-primary w-25"
                                @click="ShowReport">Store Quantity</button>
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    </Card>
    <SelectLocationModal v-model="location"/>
</template>
<script setup>
import { ref } from "vue";
import Card from '@/components/utils/Card.vue';
import FormInput from '@/components/utils/FormInput.vue';
import SelectLocationModal from '@/components/reports/modal/SelectLocationModal.vue';

const location = ref({
    LOCATION: null
});

function reset() {
    location.value.LOCATION = null;
}

function ShowReport() {
    window.open(route(`api.reports.inventory.quantity-per-store`, {
        LOCATION: location.value?.LOCATION,
    }), '_blank');
}
</script>
