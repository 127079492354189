<template>
    <div class="row g-3">
        <div class="col-md-4">
            <Card class="mb-3">
                <template #header>
                    Select Time Period
                </template>
                <FormSelect v-model="paytype"
                    label="Paytype" id-prefix="paytype"
                    :options="['WEEKLY', 'BIMONTHLY']"
                    class="mb-3" />
                <TimePeriodTable show-from-current-date
                    :paytype="paytype"
                    :hide-properties="new Set(['PAYTYPE', 'STATUS'])"
                    :sort="[{ field: 'TIME_PERIOD', order: -1 }]"
                    v-model="timePeriod" />
            </Card>
            <Card>
                <template #header>
                    Reports
                </template>
                <div class="d-grid gap-3">
                    <button type="button" class="btn btn-primary"
                        :disabled="!timePeriod"
                        @click="generateReport('encoded-hours-spreadsheet')">
                        Encoded Hours Sheet
                    </button>
                    <button type="button" class="btn btn-primary"
                        :disabled="!timePeriod"
                        @click="generateReport('with-disbursements-without-emphours-spreadsheet')">
                        W/ Disbursements W/O Hours
                    </button>
                </div>
            </Card>
        </div>
        <div class="col-md-8">
            <Card>
                <template #header>
                    Payslips in Time Period
                </template>
                <PayslipsInTimePeriodTable :time-period="timePeriod"
                    v-model="selectedPayslip" />
                <template #footer>
                    <div class="text-end">
                        <button type="button" class="btn btn-primary"
                            :disabled="!selectedPayslip"
                            @click="_ => { isViewUnpaidLoansPopupOpen = true; }">
                            View Unpaid Loans
                        </button>
                    </div>
                </template>
            </Card>
        </div>
    </div>

    <ViewPayslipUnpaidLoansPopup :is-open="isViewUnpaidLoansPopupOpen"
        :payslip-id="selectedPayslip?.id"
        @close="_ => { isViewUnpaidLoansPopupOpen = false; }" />
</template>

<script setup>
import { ref } from 'vue';

import Card from '@/components/utils/Card.vue';
import FormSelect from '@/components/utils/FormSelect.vue';
import TimePeriodTable from '@/components/utils/tables/TimePeriodTable.vue';
import PayslipsInTimePeriodTable from '@/components/utils/tables/PayslipsInTimePeriodTable.vue';
import ViewPayslipUnpaidLoansPopup from './ViewPayslipUnpaidLoansPopup.vue';

const paytype = ref('WEEKLY');
const timePeriod = ref(null);

const selectedPayslip = ref(null);

const isViewUnpaidLoansPopupOpen = ref(false);

function generateReport(report) {
    window.open(route(`api.time-periods.${report}.show`, {
        time_period: timePeriod.value.TIME_PERIOD
    }));
}
</script>
