<template>
    <div class="row">
        <div class="col-md-3">
            <Card>
                <DataTable :value="commentDates" paginator :rows="10"
                    filter-display="row" v-model:filters="commentDateFilters"
                    v-model:selection="commentDate" selection-mode="single" data-key="date"
                    :pt="{ table: { class: 'table table-bordered table-hover' } }"
                    class="mb-3">
                    <Column field="date" header="Date" :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' } }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText type="text" v-model="filterModel.value" @keydown="filterCallback()"
                                class="form-control" placeholder="Search"
                                @keydown.enter.prevent="" />
                            <Button type="button" class="btn btn-primary" @click="filterCallback()">
                                Search
                            </Button>
                        </template>
                    </Column>
                    <template #empty>No dates.</template>
                </DataTable>
            </Card>
        </div>
        <div class="col-md-3">
            <Card>
                <DataTable :value="locations"
                    paginator :rows="10"
                    v-model:filters="filters" filterDisplay="row"
                    v-model:selection="location" selection-mode="single" data-key="LOCATION"
                    :row-class="data => [{ 'bg-primary text-white': data.LOCATION == location?.LOCATION }]"
                    :pt="{ table: { class: 'table table-bordered table-hover' } }" class="mb-3">
                    <Column field="LOCATION" header="Location" :pt="{
                        filterInput: { class: 'input-group input-group-sm' },
                        filterMenuButton: { class: 'd-none' },
                        headerFilterClearButton: { class: 'd-none' } }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                                class="form-control" placeholder="Search" />
                        </template>
                    </Column>
                </DataTable>
            </Card>
        </div>
        <div class="col-md-3">
            <Card class="mb-3">
                <template #header>
                    Brand Split
                </template>
                <div>
                    <FormSelect v-model="brandSplit" label="Brand Split" id-prefix="brandSplit"
                        :options="['KIMBEL + KIMCHI', 'KIMBEL + OTHER SPLIT',
                            'KIMBEL + OTHER COMBINED', 'KIMCHI ONLY',
                            'ALI OTRS ONLY']"
                        class="mb-3" />
                    <button :disabled="!commentDate || !location || !brandSplit" class="btn btn-primary w-100 m-1"
                        @click="_ => { generateBrandSplitReport('monthly-sales-brand-split-separate-report-pdf'); }">
                        Sales Report 1 (separate) (QS/QR/AMT/RET)
                    </button>
                    <button :disabled="!commentDate || !location || !brandSplit" class="btn btn-primary w-100 m-1"
                        @click="_ => { generateBrandSplitReport('monthly-sales-brand-split-combined-report-pdf'); }">
                        Sales Report 1 (combined) (QS/QR/AMT/RET)
                    </button>
                    <button :disabled="!commentDate || !location || !brandSplit" class="btn btn-primary w-100 m-1"
                        @click="_ => {
                            selectEmployeeReportType = 'SPLIT';
                            isSelectingPreparedBy = true;
                        }">
                        Sales Report 2 (receipt/cash/charge)
                    </button>
                </div>
            </Card>
            <Card>
                <template #header>
                    SM REPORTS
                </template>
                <div>
                    <button :disabled="!commentDate" class="btn btn-primary w-100 m-1" @click="ShowPDFReport('sm-detail')">
                        SM DETAIL REPORTS (periodic + liq)
                    </button>
                    <button :disabled="!commentDate" class="btn btn-primary w-100 m-1" @click="ShowPDFReport('sm-summary')">
                        SM SUMMARY REPORTS (periodic + liq + po)
                    </button>
                    <button :disabled="!commentDate" class="btn btn-primary w-100 m-1" @click="ShowPDFReport('sm-po')">
                        SM PO VOUCHER
                    </button>
                </div>
            </Card>
        </div>
        <div class="col-md-3">
            <Card class="mb-3">
                <template #header>
                    Landed Cost/Commission/Daily Sales
                </template>
                <div>
                    <button class="btn btn-primary w-100 m-1" disabled>
                        Landed Cost Report (disabled)
                    </button>
                    <button :disabled="!commentDate" class="btn btn-primary w-100 m-1"
                        @click="generateMonthlyCommissionReport">
                        Commission Report
                    </button>
                    <button class="btn btn-primary w-100 m-1" @click="updateDailySales">
                        Update Daily Sales
                    </button>
                </div>
            </Card>
            <Card class="mb-3">
                <template #header>
                    Do Not Split
                </template>
                <div>
                    <button :disabled="!commentDate || !location" class="btn btn-primary w-100 m-1"
                        @click="_ => {
                            preparedByEmployee = null;
                            notedByEmployee = null;
                            generateDoNotSplitReport('monthly-sales-separate-report-pdf');
                        }">
                        Sales Report 1 (separate) (QS/QR/AMT/RET)
                    </button>
                    <button :disabled="!commentDate || !location" class="btn btn-primary w-100 m-1"
                        @click="_ => {
                            preparedByEmployee = null;
                            notedByEmployee = null;
                            generateDoNotSplitReport('monthly-sales-combined-report-pdf');
                        }">
                        Sales Report 1 (combined) (QS/QR/AMT/RET)
                    </button>
                    <button :disabled="!commentDate || !location" class="btn btn-primary w-100 m-1"
                        @click="_ => {
                            selectEmployeeReportType = 'NO SPLIT';
                            isSelectingPreparedBy = true;
                        }">
                        Sales Report 2 (receipt/cash/charge)
                    </button>
                </div>
            </Card>
            <Card>
                <template #header>
                    Style Reports
                </template>
                <div>
                    <button :disabled="!commentDate" class="btn btn-primary w-100 m-1"
                        @click="_ => { generateStyleReport('deliveries'); }">
                        Deliveries
                    </button>
                    <button :disabled="!commentDate" class="btn btn-primary w-100 m-1"
                        @click="_ => { generateStyleReport('sales'); }">
                        Sales
                    </button>
                    <button :disabled="!commentDate" class="btn btn-primary w-100 m-1"
                        @click="_ => { generateStyleReport('pullouts'); }">
                        Pullouts
                    </button>
                </div>
            </Card>
        </div>
    </div>

    <SelectEmployee :is-open="isSelectingPreparedBy || isSelectingNotedBy"
        :on-select-employee="onEmployeeSelect"
        :on-close="_ => { isSelectingPreparedBy = false; isSelectingNotedBy = false; }"
        :title="isSelectingPreparedBy ? 'SELECT PREPARED BY' : 'SELECT NOTED BY'"
        :only-show="['ACCOUNTING', 'ADMIN', 'EXTRA']" />
</template>

<script setup>
import { onMounted, ref } from "vue";

import { useCommentDates } from "@/composables/data/commentDates";
import { useLocations } from "@/composables/data/locations";
import { useDataTableParams } from '@/composables/data/dataTableParams';

import Card from '@/components/utils/Card.vue';
import FormSelect from '@/components/utils/FormSelect.vue';
import SelectEmployee from '@/components/utils/SelectEmployee.vue';

import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';
import { FilterMatchMode } from 'primevue/api';

const commentDateFilters = ref({
    date: { value: null, matchMode: FilterMatchMode.CONTAINS },
});

const { commentDates, getCommentDates } = useCommentDates();
const commentDate = ref(null);

const { locations, getLocations } = useLocations();
const location = ref(null);

const { filters } = useDataTableParams(['LOCATION']);

const preparedByEmployee = ref(null);
const notedByEmployee = ref(null);

const isSelectingPreparedBy = ref(false);
const isSelectingNotedBy = ref(false);

const selectEmployeeReportType = ref(null);

const brandSplit = ref(null);

onMounted(() => {
    getLocations();
    getCommentDates();
});

function ShowPDFReport(reportType) {
    window.open(route(`api.reports.inventory.monthly-report-generator`, {
        REPORT_TYPE: reportType,
        MONTH: commentDate.value.date,
        LOCATION: location.value?.LOCATION,
    }), '_blank');
}

function generateDoNotSplitReport(reportType) {
    window.open(route(`api.inventory.${reportType}.show`, {
        MONTH: commentDate.value.date,
        LOCATION: location.value?.LOCATION,
        PREPARED_BY: preparedByEmployee.value?.EMPID,
        NOTED_BY: notedByEmployee.value?.EMPID,
    }));
}

function onEmployeeSelect(selectedEmployee) {
    if (isSelectingPreparedBy.value) {
        isSelectingPreparedBy.value = false;
        preparedByEmployee.value = selectedEmployee;
        setTimeout(() => { isSelectingNotedBy.value = true; }, 200);
    } else if (isSelectingNotedBy.value) {
        isSelectingNotedBy.value = false;
        notedByEmployee.value = selectedEmployee;

        if (selectEmployeeReportType.value == 'SPLIT')
            generateBrandSplitReport('monthly-sales-brand-split-details-report-pdf');
        else if (selectEmployeeReportType.value == 'NO SPLIT')
            generateDoNotSplitReport('monthly-sales-details-report-pdf');
    }
}

function generateBrandSplitReport(reportType) {
    window.open(route(`api.inventory.${reportType}.show`, {
        MONTH: commentDate.value.date,
        LOCATION: location.value?.LOCATION,
        BRAND_SPLIT: brandSplit.value,
        PREPARED_BY: preparedByEmployee.value?.EMPID,
        NOTED_BY: notedByEmployee.value?.EMPID,
    }));
}

function generateStyleReport(reportType) {
    window.open(route(`api.inventory.monthly-style-report-pdf.show`, {
        MONTH: commentDate.value.date,
        LOCATION: location.value?.LOCATION,
        REPORT_TYPE: reportType
    }));
}

function generateMonthlyCommissionReport() {
    window.open(route(`api.inventory.monthly-commission-report-pdf.show`, {
        MONTH: commentDate.value.date,
        LOCATION: location.value?.LOCATION,
    }));
}

function updateDailySales() {
    window.open(route(`report.inventory.monthly-reports.update-daily-sales`));
}
</script>
