<template>
    <Modal :id="id">
        <template #header>
            <h5 class="modal-title">{{ title }}</h5>
        </template>
        <FormSelect v-model="selectedOption" label="Format"
            :options="options" />
        <div class="p-3" v-if="selectedOption === 'PDF'">
            <FormInput type="number" required
                label="Top:" class="mb-3" is-horizontal
                input-class="w-75" v-model="margin.top"/>
            <FormInput type="number" required
                label="Left:" class="mb-3" is-horizontal
                input-class="w-75" v-model="margin.left"/>
            <FormInput type="number" required
                label="Right:" class="mb-3" is-horizontal
                input-class="w-75" v-model="margin.right"
                disabled />
            <FormInput type="number" required
                label="Bottom:" class="mb-3" is-horizontal
                input-class="w-75" v-model="margin.bottom"/>
        </div>
        <template #footer>
            <button type="button" class="btn btn-primary"
                @click="printButtonOnClick">
                Print
            </button>
        </template>
    </Modal>
</template>

<script setup>
import { ref } from "vue";

import Modal from "@/components/utils/Modal.vue";
import FormSelect from "@/components/utils/FormSelect.vue";
import FormInput from "@/components/utils/FormInput.vue";

const options = ['PDF', 'Spreadsheet'];

const props = defineProps({
    pdfProps: Object,
    pdfLink: String,
    spreadsheetLink: String,
    title: String,
    id: String
});

const margin = ref({
    top: 10,
    left: 25,
    right: 15,
    bottom: 20,
})

const selectedOption = ref(options[0]);

function printButtonOnClick() {
    if(selectedOption.value == options[0]) {
        window.open(route(props.pdfLink, {
            ...props.pdfProps,
            ...margin.value
        }), '_blank');
    } else if(selectedOption.value == options[1]) {
        window.open(props.spreadsheetLink, '_blank');
    }
}
</script>
