<template>
    <div class="row g-3 mb-3">
        <div class="col-md-4">
            <Card>
                <template #header>
                    Select Time Period
                </template>
                <FormSelect v-model="paytype"
                    :options="['BIMONTHLY', 'WEEKLY']"
                    label="Select Paytype"
                    id-prefix="paytype"
                    class="mb-3" />
                <TimePeriodTable
                    v-model="timePeriod"
                    show-from-current-date
                    :paytype="paytype ?? 'BIMONTHLY'"
                    :hide-properties="new Set(['STATUS'])"
                    :sort="[{ field: 'TIME_PERIOD', order: -1 }]" />
            </Card>
        </div>
    </div>
    <Card>
        <template #header>
            Employees Table
        </template>
        <h5>Filters</h5>
        <div class="row g-3 mb-3">
            <div class="col-md-4">
                <div class="d-flex flex-row">
                    <Checkbox v-model="showNonResigned"
                        binary
                        inputId="showNonResignedInput"
                        @change="fetchEmployees" />
                    <label class="ms-1" for="showNonResignedInput">
                        Not RESIGNED
                    </label>
                </div>
            </div>
            <div class="col-md-4">
                <div class="d-flex flex-row">
                    <Checkbox v-model="showEmptyTIN"
                        binary
                        inputId="showEmptyTINInput"
                        @change="fetchEmployees" />
                    <label class="ms-1" for="showEmptyTINInput">
                        Empty TIN
                    </label>
                </div>
            </div>
        </div>
        <h5>Rate Filters</h5>
        <div class="row g-3 mb-3">
            <div class="col-lg-3">
                <div class="d-flex flex-row">
                    <RadioButton v-model="showNoSSSContribution"
                        :value="true"
                        inputId="showNoSSSContributionTrue"
                        @change="fetchEmployees" />
                    <label class="ms-1" for="showNoSSSContributionTrue">
                        Show No SSS Contribution
                    </label>
                </div>
                <div class="d-flex flex-row mb-1">
                    <RadioButton v-model="showNoSSSContribution"
                        :value="false"
                        inputId="showNoSSSContributionFalse"
                        @change="fetchEmployees" />
                    <label class="ms-1" for="showNoSSSContributionFalse">
                        Show W/ SSS Contribution
                    </label>
                </div>
                <Button type="button" class="btn btn-sm btn-outline-secondary"
                    @click="_ => { showNoSSSContribution = null; fetchEmployees(); }">
                    Remove Filter
                </Button>
            </div>
            <div class="col-lg-3">
                <div class="d-flex flex-row">
                    <RadioButton v-model="showNoPhilhealthContribution"
                        :value="true"
                        inputId="showNoPhilhealthContributionTrue"
                        @change="fetchEmployees" />
                    <label class="ms-1" for="showNoPhilhealthContributionTrue">
                        Show No PH Contribution
                    </label>
                </div>
                <div class="d-flex flex-row mb-1">
                    <RadioButton v-model="showNoPhilhealthContribution"
                        :value="false"
                        inputId="showNoPhilhealthContributionFalse"
                        @change="fetchEmployees" />
                    <label class="ms-1" for="showNoPhilhealthContributionFalse">
                        Show W/ PH Contribution
                    </label>
                </div>
                <Button type="button" class="btn btn-sm btn-outline-secondary"
                    @click="_ => { showNoPhilhealthContribution = null; fetchEmployees(); }">
                    Remove Filter
                </Button>
            </div>
            <div class="col-lg-3">
                <div class="d-flex flex-row">
                    <RadioButton v-model="showNoPagibigContribution"
                        :value="true"
                        inputId="showNoPagibigContributionTrue"
                        @change="fetchEmployees" />
                    <label class="ms-1" for="showNoPagibigContributionTrue">
                        Show No PAGIBIG Contribution
                    </label>
                </div>
                <div class="d-flex flex-row mb-1">
                    <RadioButton v-model="showNoPagibigContribution"
                        :value="false"
                        inputId="showNoPagibigContributionFalse"
                        @change="fetchEmployees" />
                    <label class="ms-1" for="showNoPagibigContributionFalse">
                        Show W/ PAGIBIG Contribution
                    </label>
                </div>
                <Button type="button" class="btn btn-sm btn-outline-secondary"
                    @click="_ => { showNoPagibigContribution = null; fetchEmployees(); }">
                    Remove Filter
                </Button>
            </div>
            <div class="col-lg-3">
                <div class="d-flex flex-row">
                    <RadioButton v-model="showNoWTX"
                        :value="true"
                        inputId="showNoWTXTrue"
                        @change="fetchEmployees" />
                    <label class="ms-1" for="showNoWTXTrue">
                        Show No WTX
                    </label>
                </div>
                <div class="d-flex flex-row mb-1">
                    <RadioButton v-model="showNoWTX"
                        :value="false"
                        inputId="showNoWTXFalse"
                        @change="fetchEmployees" />
                    <label class="ms-1" for="showNoWTXFalse">
                        Show W/ WTX
                    </label>
                </div>
                <Button type="button" class="btn btn-sm btn-outline-secondary"
                    @click="_ => { showNoWTX = null; fetchEmployees(); }">
                    Remove Filter
                </Button>
            </div>
        </div>
        <DataTable
            :loading="loadingFlags.has('fetchEmployees')"
            lazy
            :value="employees"
            :total-records="totalRecords"
            paginator
            :rows="10"
            @page="e => { onPage(e); fetchEmployees(); }"
            filter-display="row"
            v-model:filters="filters"
            @filter="tableOnFilter"
            v-model:expanded-rows="expandedRows"
            :pt="{ table: { class: 'table table-bordered table-hover' }}">
            <Column expander style="width: 5rem" />
            <Column field="EMPID" header="ID" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="PAYTYPE" header="Paytype" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="FNAME" header="First Name" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="MNAME" header="Middle Name" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="LNAME" header="Last Name" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="GENDER" header="Gender" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="STATUS" header="Status" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="DEPT" header="Department" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="TITLE" header="Title" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="HIREDATE" header="Hiredate" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="LASTDATE" header="Last Date" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="SSS" header="SSS" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="PHILHEALTH" header="Philhealth" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="PAGIBIG" header="PAGIBIG" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="TIN" header="TIN" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <template #expansion="{ data }">
                <h5>
                    Rate Information for {{ data.FNAME }} {{ data.LNAME }}
                    <template v-if="!timePeriod">(Latest = '9999-12-31')</template>
                    <template v-else>(as of {{ timePeriod.END_DATE }})</template>
                </h5>
                <div class="row g-3">
                    <div class="col-md-4">
                        <h6>Main Information</h6>
                        <table class="table table-sm table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>Field</th>
                                    <th>Rate</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="text-end">
                                    <th>Department</th>
                                    <td>{{ data.employee_rate?.DEPT ?? 'N/A' }}</td>
                                </tr>
                                <tr class="text-end">
                                    <th>Company Code</th>
                                    <td>{{ data.employee_rate?.COMPANY_CODE ?? 'N/A' }}</td>
                                </tr>
                                <tr class="text-end">
                                    <th>Days of Work</th>
                                    <td>{{ data.employee_rate?.DAYSOFWORK ?? 'N/A' }}</td>
                                </tr>
                                <tr class="text-end">
                                    <th>Rate</th>
                                    <td>{{ data.employee_rate?.RATE ?? 'N/A' }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-md-4">
                        <h6>Deductions</h6>
                        <table class="table table-sm table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>Field</th>
                                    <th>Rate</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="text-end">
                                    <th>Philhealth Contribution</th>
                                    <td>{{ data.employee_rate?.PH_CONTRIBUTION ?? 'N/A' }}</td>
                                </tr>
                                <tr class="text-end">
                                    <th>PAGIBIG Contribution</th>
                                    <td>{{ data.employee_rate?.PAGIBIG_CONTRIBUTION ?? 'N/A' }}</td>
                                </tr>
                                <tr class="text-end">
                                    <th>SSS Contribution</th>
                                    <td>{{ data.employee_rate?.SSS_CONTRIBUTION ?? 'N/A' }}</td>
                                </tr>
                                <tr class="text-end">
                                    <th>WTX</th>
                                    <td>{{ data.employee_rate?.WTX ?? 'N/A' }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </template>
            <template #empty>
                <div class="text-center">
                    No data
                </div>
            </template>
            <template #footer>
                {{ totalRecords ?? 0 }} total employees
            </template>
        </DataTable>
        <template #footer>
            <div class="text-end">
                <Button class="btn btn-primary"
                    @click="exportSpreadsheet">
                    Export to Spreadsheet
                </Button>
            </div>
        </template>
    </Card>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue';

import { useLoadingFlagsStore } from '@/stores/loadingFlags';
import { useFlashMessages } from '@/composables/flashmessages';
import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useEmployees } from '@/composables/data/employees';

import Card from '@/components/utils/Card.vue';

import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';
import RadioButton from 'primevue/radiobutton';

import FormSelect from '@/components/utils/FormSelect.vue';
import TimePeriodTable from '@/components/utils/tables/TimePeriodTable.vue';

import moment from 'moment';

import scrollToTop from '@/utils/scrollToTop';

const { addFlashMessage } = useFlashMessages();

const paytype = ref(null);
const timePeriod = ref(null);

const { params, filters, onPage, onFilter } = useDataTableParams([
    'EMPID',
    'PAYTYPE',
    'FNAME',
    'MNAME',
    'LNAME',
    'GENDER',
    'STATUS',
    'DEPT',
    'TITLE',
    'HIREDATE',
    'LASTDATE',
    'SSS',
    'PHILHEALTH',
    'PAGIBIG',
    'TIN',
]);

const { employees, totalRecords, getEmployees } = useEmployees();

const expandedRows = ref(null);

const loadingFlags = useLoadingFlagsStore();

const fetchTimeout = ref(false);

const showNonResigned = ref(false);
const showEmptyTIN = ref(false);
const showNoSSSContribution = ref(null);
const showNoPhilhealthContribution = ref(null);
const showNoPagibigContribution = ref(null);
const showNoWTX = ref(null);

const hardFilters = computed(() => ({
    ...(showNonResigned.value ? {
        DEPT: {
            value: 'RESIGNED',
            matchMode: 'notEquals'
        }
    } : {}),
    ...(showEmptyTIN.value ?  {
        TIN: {
            value: '--placeholder--',
            matchMode: 'isEmpty'
        }
    } : {}),
    ...(timePeriod.value != null ? {
        PAYTYPE: {
            value: timePeriod.value.PAYTYPE,
            matchMode: 'equals',
        },
        'employeeRates.START_DATE': {
            value: timePeriod.value.END_DATE,
            matchMode: 'lte'
        },
        'employeeRates.END_DATE': {
            value: timePeriod.value.END_DATE,
            matchMode: 'gte'
        },
    } : {}),
    ...(showNoSSSContribution.value != null ||
        showNoPhilhealthContribution.value != null ||
        showNoPagibigContribution.value != null ||
        showNoWTX.value != null ?
            timePeriod.value == null ? {
                'employeeRates.END_DATE': {
                    value: '9999-12-31',
                    matchMode: 'equals'
                }
            } : {} : {}),
    ...(showNoSSSContribution.value != null ? {
        'employeeRates.SSS_CONTRIBUTION': {
            value: 0,
            matchMode: showNoSSSContribution.value ? 'equals' : 'notEquals'
        }
    } : {}),
    ...(showNoPhilhealthContribution.value != null ? {
        'employeeRates.PH_CONTRIBUTION': {
            value: 0,
            matchMode: showNoPhilhealthContribution.value ? 'equals' : 'notEquals'
        }
    } : {}),
    ...(showNoPagibigContribution.value != null ? {
        'employeeRates.PAGIBIG_CONTRIBUTION': {
            value: 0,
            matchMode: showNoPagibigContribution.value ? 'equals' : 'notEquals'
        }
    } : {}),
    ...(showNoWTX.value != null ? {
        'employeeRates.WTX': {
            value: 0,
            matchMode: showNoWTX.value ? 'equals' : 'notEquals'
        }
    } : {}),
}));

async function fetchEmployees() {
    employees.value = null;

    loadingFlags.add('fetchEmployees');
    try {
        await getEmployees({
            with: 'employeeRates',
            ...params.value,
            hardFilters: hardFilters.value
        });

        employees.value = employees.value.map(employee => {
            return {
                ...employee,
                employee_rate: timePeriod.value != null ?
                    (employee.employee_rates.filter(rate => {
                        const rateStartDate = moment(rate.START_DATE);
                        const rateEndDate = moment(rate.END_DATE);
                        const timePeriodEndDate = moment(timePeriod.value.END_DATE);

                        return timePeriodEndDate.isSameOrAfter(rateStartDate) &&
                            timePeriodEndDate.isSameOrBefore(rateEndDate);
                    })[0] ?? null) :
                    (employee.employee_rates.filter(rate => rate.END_DATE == '9999-12-31')[0] ?? null)
            }
        });
    } catch(e) {
        addFlashMessage('ERROR', e.message);
        scrollToTop();
    }
    loadingFlags.delete('fetchEmployees');
}

function tableOnFilter(e) {
    onFilter(e);
    if(fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(fetchEmployees, 300);
}

onMounted(fetchEmployees);

watch(timePeriod, fetchEmployees);

function exportSpreadsheet() {
    window.open(route('api.employees.general-employee-spreadsheet.show', {
        filters: filters.value,
        hardFilters: hardFilters.value,
    }));
}
</script>
