<template>

    <div class="row g-3 my-3">
        <div class="col-12">
            <form @submit.prevent="saveNewBarcodeInput">
                <fieldset>
                    <legend>
                        <i class="icon ph-bold ph-keyboard me-2"></i>Input Styles
                    </legend>
                    <div class="row input-style-container">
                        <div class="col mb-md-0 mb-2">
                            <FormInput
                                type="text"
                                label="Style"
                                v-model="newBarcodeInput.STYLE"
                                id-prefix="style"
                                class="mb-3"
                                input-class="form-control-sm"
                                :errors="errors.STYLE"
                                @keydown-enter="focusNextInput('colorFormInput')"
                            />
                        </div>
                        <div class="col mb-md-0 mb-2">
                            <FormInput
                                type="text"
                                label="Color"
                                v-model="newBarcodeInput.COLOR"
                                id-prefix="color"
                                class="mb-3"
                                input-class="form-control-sm"
                                :errors="errors.COLOR"
                                @keydown-enter="focusNextInput('dateFormInput')"
                            />
                        </div>
                        <div class="col mb-md-0 mb-2">
                            <FormInput
                                type="year"
                                label="Date"
                                v-model="newBarcodeInput.DATE"
                                id-prefix="date"
                                class="mb-3"
                                input-class="form-control-sm"
                                :errors="errors.DATE"
                                @keydown-enter="focusNextInput('countFormInput')"
                            />
                        </div>
                        <div class="col mb-md-0 mb-2">
                            <FormInput
                                type="number"
                                label="Count"
                                v-model="newBarcodeInput.COUNT"
                                id-prefix="count"
                                class="mb-3"
                                input-class="form-control-sm"
                                :errors="errors.COUNT"
                                @keydown-enter="focusNextInput('priceFormInput')"
                            />
                        </div>
                        <div class="col mb-md-0 mb-2">
                            <FormInput
                                type="number"
                                step=".02"
                                label="Price"
                                v-model="newBarcodeInput.PRICE"
                                id-prefix="price"
                                class="mb-3"
                                input-class="form-control-sm"
                                :errors="errors.PRICE"
                                @keydown-enter="focusNextInput('enterToTableButton')"
                            />
                        </div>
                    </div>
                    <hr class="my-4" />
                    <div class="text-end">
                        <button type="submit"
                            class="btn btn-sm btn-primary"
                            id="enterToTableButton">
                            <i class="icon ph-bold ph-table me-2"></i>Enter to Table
                        </button>
                    </div>
                </fieldset>
            </form>
        </div>
    </div>
    <div class="row g-3">
        <div class="col-xl-6">
            <fieldset class="h-100 d-flex flex-column justify-content-between">
                <legend>
                    <i class="icon ph-bold ph-barcode me-2"></i>Barcodes to Generate
                </legend>
                <DataTable :value="barcodesToGenerate"
                    paginator
                    :rows="10"
                    filter-display="row"
                    v-model:filters="filters.barcodesToGenerate"
                    :pt="{ table: { class: 'table table-bordered table-hover' } }"
                    editMode="cell"
                    @cell-edit-complete="onCellEditComplete">
                    <Column field="STYLE" header="Style" :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search by Style"
                            />
                        </template>
                    </Column>
                    <Column
                        field="COLOR"
                        header="Color"
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search by Color"
                            />
                        </template>
                    </Column>
                    <Column
                        field="DATE"
                        header="Date"
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search by Date"
                            />
                        </template>
                    </Column>
                    <Column
                        field="COUNT"
                        header="Count"
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search by Count"
                            />
                        </template>
                        <template #editor="{ data, field }">
                            <InputNumber
                                v-model="data[field]"
                                class="form-control-sm"
                                autofocus
                            />
                        </template>
                    </Column>
                    <Column
                        field="PRICE"
                        header="Price"
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search by Price"
                            />
                        </template>
                    </Column>
                    <Column field="id" header="Delete"
                        body-class="d-flex justify-content-center">
                        <template #body="{ data, field }">
                            <a
                                class="btn btn-outline-danger px-1 py-0"
                                href="#"
                                role="button"
                                @click="deleteRow(data[field])"
                                >
                                <i class="icon ph-bold ph-trash"></i>
                            </a>
                        </template>
                    </Column>
                    <template #empty>No inputs.</template>
                </DataTable>
                <div>
                    <hr class="my-4">
                    <div class="text-end">
                        <button type="button"
                            class="btn btn-sm btn-outline-danger me-2 mb-1"
                            @click="clearBarcodesToGenerate">
                            <i class="icon ph-bold ph-x me-2"></i>Clear
                        </button>
                        <button type="button"
                            class="btn btn-sm btn-primary me-2 mb-1"
                            @click="generateBarcodes">
                            <i class="icon ph-bold ph-barcode me-2"></i>Generate Barcodes
                        </button>
                    </div>
                </div>
            </fieldset>
        </div>
        <div class="col-xl-6">
            <fieldset class="h-100 d-flex flex-column justify-content-between">
                <legend>
                    <i class="icon ph-bold ph-barcode me-2"></i>Generated Barcodes
                </legend>
                <DataTable :value="generatedBarcodes"
                    paginator
                    :rows="10"
                    filter-display="row"
                    v-model:filters="filters.generatedBarcodes"
                    :pt="{table: { class: 'table table-bordered table-hover' }}">
                    <Column
                        field="BARCODE"
                        header="Barcode"
                        :pt="{
                            bodyCell: { class: 'barcode' },
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search by Barcode"
                            />
                        </template>
                    </Column>
                    <Column
                        field="COLOR"
                        header="Color"
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search by Color"
                            />
                        </template>
                    </Column>
                    <Column
                        field="PRICE"
                        header="Price"
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search by Price"
                            />
                        </template>
                    </Column>
                    <template #empty>No barcodes.</template>
                </DataTable>
                <div>
                    <hr class="my-4" />
                    <div class="text-end">
                        <button type="button"
                            class="btn btn-sm btn-outline-danger me-2 mb-1"
                            @click="clearGeneratedBarcodes">
                            <i class="icon ph-bold ph-x me-2"></i>Clear
                        </button>
                    </div>
                </div>
            </fieldset>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import FormInput from '../../../utils/FormInput.vue';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import { FilterMatchMode } from 'primevue/api';

import focusNextInput from '../../../../utils/focusnext';
import handleFetchErrors from "../../../../utils/handleFetchErrors";
import scrollToTop from "../../../../utils/scrollToTop";
import { useFlashMessages } from "../../../../composables/flashmessages";

const { addFlashMessage } = useFlashMessages();

const filters = ref({
    barcodesToGenerate: {
        STYLE: { value: null, matchMode: FilterMatchMode.CONTAINS },
        COLOR: { value: null, matchMode: FilterMatchMode.CONTAINS },
        DATE: { value: null, matchMode: FilterMatchMode.CONTAINS },
        COUNT: { value: null, matchMode: FilterMatchMode.CONTAINS },
        PRICE: { value: null, matchMode: FilterMatchMode.CONTAINS },
    },
    generatedBarcodes: {
        BARCODE: { value: null, matchMode: FilterMatchMode.CONTAINS },
        COLOR: { value: null, matchMode: FilterMatchMode.CONTAINS },
        PRICE: { value: null, matchMode: FilterMatchMode.CONTAINS },
    },
});

const barcodesToGenerate = ref();
const generatedBarcodes = ref();

const errors = ref({});

const newBarcodeInput = ref({});

async function fetchBarcodesToGenerate() {
    try {
        const newBarcodeInputsResponse = await axios.get(
            route("api.new-barcode-inputs.index")
        );
        barcodesToGenerate.value = newBarcodeInputsResponse.data.data;
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, "Error while fetching barcodes to generate.");
        scrollToTop();
    }
}

async function fetchGeneratedBarcodes() {
    try {
        const newBarcodeOutputsResponse = await axios.get(
            route("api.new-barcode-outputs.index")
        );
        generatedBarcodes.value = newBarcodeOutputsResponse.data.data;
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, "Error while fetching generated barcodes.");
        scrollToTop();
    }
}

function resetInputPrice() {
    newBarcodeInput.value.PRICE = 0;
}

onMounted(() => {
    fetchBarcodesToGenerate();
    fetchGeneratedBarcodes();
    resetInputPrice();
});

async function saveNewBarcodeInput() {
    errors.value = {};
    try {
        await axios.post(
            route("api.new-barcode-inputs.store"),
            newBarcodeInput.value
        );
        addFlashMessage(
            "SUCCESS",
            "Successfully saved style for barcode generation."
        );
        newBarcodeInput.value = {};
        fetchBarcodesToGenerate();
        resetInputPrice();
    } catch(e) {
        console.log(e);
        errors.value = handleFetchErrors(
            e,
            "Error while saving new barcode input."
        );
    }
    scrollToTop();
}

async function onCellEditComplete(event) {
    const { data, newValue, field } = event;
    data[field] = newValue;
    errors.value = {};
    try {
        await axios.put(route('api.new-barcode-inputs.update', data['id']), data);
        addFlashMessage('SUCCESS', 'Successfully updated style for barcode generation.');
        newBarcodeInput.value = {};
        fetchBarcodesToGenerate();
    } catch(e) {
        fetchBarcodesToGenerate();
        console.log(e);
        handleFetchErrors(e, 'Error while updating barcode input.');
    }
    scrollToTop();
}

async function generateBarcodes() {
    try {
        const newBarcodeOutputsResponse = await axios.post(
            route("api.new-barcode-inputs.movements.store")
        );
        generatedBarcodes.value = newBarcodeOutputsResponse.data.data;
        addFlashMessage("SUCCESS", "Successfully generated barcodes.");
    } catch(e) {
        console.log(e);
        handleFetchErrors(e, 'Error while generating barcodes.');
    }
    scrollToTop();
}

async function deleteRow(id) {
    if(window.prompt('Type "DELETE" to delete row.') != 'DELETE')
        return;

    try {
        await axios.delete(route('api.new-barcode-inputs.destroy', id));
        addFlashMessage('SUCCESS', 'Successfully deleted barcode to generate.');
        fetchBarcodesToGenerate();
    } catch(e) {
        console.log(e);
        handleFetchErrors(e, 'Error while deleting barcode to generate.');
    }
    scrollToTop();
}

async function clearBarcodesToGenerate() {
    if(window.prompt('Type "CLEAR" to clear table.') != 'CLEAR')
        return;

    try {
        await axios.delete(route('api.new-barcode-inputs.destroy-all'));
        addFlashMessage('SUCCESS', 'Successfully cleared barcodes to generate.');
        fetchBarcodesToGenerate();
    } catch(e) {
        console.log(e);
        handleFetchErrors(e, 'Error while deleting barcodes to generate.');
    }
    scrollToTop();
}

async function clearGeneratedBarcodes() {
    if(window.prompt('Type "CLEAR" to clear table.') != 'CLEAR')
        return;

    try {
        await axios.delete(route('api.new-barcode-outputs.destroy-all'));
        addFlashMessage('SUCCESS', 'Successfully cleared generated barcodes.');
        fetchGeneratedBarcodes();
    } catch(e) {
        console.log(e);
        handleFetchErrors(e, 'Error while deleting generated barcodes.');
    }
    scrollToTop();
}
</script>
