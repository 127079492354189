import { ref } from 'vue';

import axios from 'axios';

import constructFetchError from "./constructFetchError";

export function useSalesLeaves() {
    const postSalesLeavesMultiple = async (employeeId, timePeriod, salesLeaves) => {
        try {
            const postSalesLeavesMultipleResponse = await axios.post(route('api.employees.sales-leaves.flock.store', {
                employee: employeeId,
            }), {
                TIME_PERIOD: timePeriod,
                ENTRIES: salesLeaves
            });
            return postSalesLeavesMultipleResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while saving sales leaves.');
        }
    };

    return { postSalesLeavesMultiple };
}
