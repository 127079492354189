<template>
    <form @submit.prevent="insertJoborderNew">
        <Card>
            <template #header>
                JONO Form
            </template>
            <div class="row g-3 mb-3">
                <div class="col-md-6">
                    <FormInput type="text" v-model="joborder.JONO"
                        label="JONO" id-prefix="jono"
                        :errors="errors.JONO"
                        is-horizontal disabled />
                </div>
                <div class="col-md-6">
                    <button type="button" class="btn btn-primary"
                        @click="newSP">
                        New SP
                    </button>
                </div>
            </div>
            <FormInput type="text" v-model="joborder.DESCRIPTION"
                label="DESCRIPTION" id-prefix="description"
                :errors="errors.DESCRIPTION"
                is-horizontal class="mb-3" />
            <FormInput type="date" v-model="joborder.DATE_DUE"
                label="DATE_DUE" id-prefix="dateDue"
                :errors="errors.DATE_DUE"
                is-horizontal class="mb-3" />
            <FormInput type="text" v-model="joborder.PO_NUM"
                label="PO_NUM" id-prefix="poNum"
                :errors="errors.PO_NUM"
                is-horizontal class="mb-3" />
            <template #footer>
                <div class="text-end">
                    <button type="submit" class="btn btn-primary me-2"
                        :disabled="!account.ACCOUNT_ID || isUpdating">
                        Insert
                    </button>
                    <button type="button" class="btn btn-primary"
                        :disabled="!account.ACCOUNT_ID || !isUpdating"
                        @click="updateJoborderNew">
                        Update
                    </button>
                </div>
            </template>
        </Card>
    </form>
</template>

<script setup>
import { ref, watch } from 'vue';

import { useFlashMessages } from '@/composables/flashmessages';

import { useJobordersNew } from '@/composables/data/jobordersNew';

import Card from '@/components/utils/Card.vue';
import FormInput from '@/components/utils/FormInput.vue';

import scrollToTop from '@/utils/scrollToTop';

import moment from 'moment';

const props = defineProps({
    selectedJoborderFromTable: Object,
    account: Object,
});

const emit = defineEmits(['create-or-update']);

const { addFlashMessage } = useFlashMessages();

const { getNextJONO, postJoborderNew, putJoborderNew } = useJobordersNew();

const errors = ref({});

const joborder = ref({
    JONO: null,
    ACCOUNT_ID: null,
    DESCRIPTION: null,
    DATE_DUE: null,
    PO_NUM: null,
});

const isUpdating = ref(false);

async function newSP() {
    try {
        const nextJONO = await getNextJONO('SP', moment().format('YYYY'));
        joborder.value = {
            JONO: nextJONO,
            ACCOUNT_ID: props.account.ACCOUNT_ID,
            DESCRIPTION: null,
            DATE_DUE: null,
            PO_NUM: null,
        };
        isUpdating.value = false;
    } catch(e) {
        addFlashMessage('ERROR', e.message);
        scrollToTop();
    }
}

async function insertJoborderNew() {
    errors.value = {};
    try {
        joborder.value = await postJoborderNew(joborder.value);
        addFlashMessage('SUCCESS', 'Successfully created joborder.');
        emit('create-or-update', joborder.value);
        isUpdating.value = true;
    } catch(e) {
        addFlashMessage('ERROR', e.message);
        errors.value = e.errors;
    }
    scrollToTop();
}

async function updateJoborderNew() {
    errors.value = {};
    try {
        joborder.value = await putJoborderNew(joborder.value.JONO, joborder.value);
        addFlashMessage('SUCCESS', 'Successfully updated joborder.');
        emit('create-or-update', joborder.value);
    } catch(e) {
        addFlashMessage('ERROR', e.message);
        errors.value = e.errors;
    }
    scrollToTop();
}

watch(() => props.selectedJoborderFromTable, () => {
    joborder.value = Object.assign({}, props.selectedJoborderFromTable);
    isUpdating.value = joborder.value.JONO != null;
});
</script>
