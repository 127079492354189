<template>
    <div
        class="modal fade"
        id="modalConfirmation"
        tabindex="-1"
        aria-labelledby="modalConfirmationLabel"
        aria-hidden="true"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="modalConfirmationLabel">
                        Confirmation Dialog
                    </h1>
                    <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        ref="closeButtonRef"
                    ></button>
                </div>
                <div class="modal-body d-flex flex-column">
                    <Card>
                        <FormInput type="text" :label="description"
                            input-class="form-control-sm" v-model="confirmationText"
                            :errors="errors.CONFIRMATION_TEXT"/>
                    </Card>
                </div>
                <div class="modal-footer">
                    <button
                        class="btn btn-primary"
                        :disabled="confirmationText === ''"
                        @click="confirm"
                    >
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";

import Card from "./Card.vue";
import FormInput from "./FormInput.vue";

const props = defineProps({
    description: {
        type: String,
        required: true,
    },
    text: {
        type: String,
        required: true
    }
});

const emit = defineEmits(['confirmed', 'error']);

const confirmationText = ref('');
const errors = ref({});
const closeButtonRef = ref(null);

function confirm() {
    if (confirmationText.value === props.text) {
        emit("confirmed", true);
        closeButtonRef.value.click();
        clearData();
    } else {
        emit("confirmed", false);
        errors.value.CONFIRMATION_TEXT = [`'${props.text}' not specified. The request was declined.`]
    }
}

function clearData() {
    confirmationText.value = '';
    errors.value = {};
}
</script>
