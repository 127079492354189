<template>
    <DataTable :loading="isLoading" :value="styleSkuPrices"
        paginator :rows="10"
        filter-display="row" v-model:filters="filters"
        sort-mode="multiple"
        :pt="{ table: { class: 'table table-sm table-bordered table-hover' } }">
        <Column field="SKU_PRICE" header="SKU Price" sortable :pt="{
            filterInput: { class: 'input-group input-group-sm' },
            filterMenuButton: { class: 'd-none' },
            headerFilterClearButton: { class: 'd-none' } }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text" v-model="filterModel.value"
                    @input="filterCallback" class="form-control"
                    placeholder="Search" />
            </template>
        </Column>
        <Column field="START_DATE" header="Start Date" sortable :pt="{
            filterInput: { class: 'input-group input-group-sm' },
            filterMenuButton: { class: 'd-none' },
            headerFilterClearButton: { class: 'd-none' } }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text" v-model="filterModel.value"
                    @input="filterCallback" class="form-control"
                    placeholder="Search" />
            </template>
        </Column>
        <Column field="END_DATE" header="End Date" sortable :pt="{
            filterInput: { class: 'input-group input-group-sm' },
            filterMenuButton: { class: 'd-none' },
            headerFilterClearButton: { class: 'd-none' } }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text" v-model="filterModel.value"
                    @input="filterCallback" class="form-control"
                    placeholder="Search" />
            </template>
        </Column>
        <template #empty>
            <div class="text-center">
                No data.
            </div>
        </template>
    </DataTable>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';

import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useStyleSkuPrices } from '@/composables/data/styleSkuPrices';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

const props = defineProps({
    style: String,
    date: String,
});

const emit = defineEmits(['error']);

const isLoading = ref(false);

const { filters } = useDataTableParams([
    'SKU_PRICE', 'START_DATE', 'END_DATE'
]);

const { styleSkuPrices, getStyleSkuPrices } = useStyleSkuPrices();

const fetchTimeout = ref(null);

function fetchStyleSkuPrices() {
    styleSkuPrices.value = null;

    if(!props.style)
        return;

    if(fetchTimeout.value)
        clearTimeout(fetchTimeout.value);

    fetchTimeout.value = setTimeout(async () => {
        isLoading.value = true;
        try {
            await getStyleSkuPrices(props.style, {
                date: props.date
            });
        } catch(e) {
            emit('error', e.message);
        }
        isLoading.value = false;
    }, 300);
}

watch(() => [props.style, props.date], fetchStyleSkuPrices);
onMounted(fetchStyleSkuPrices);
</script>
