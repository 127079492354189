

<style scoped>
#employeeTableContainer {
    height: 39rem;
    overflow-y: auto;
}

#employeeRateTableContainer {
    height: 300px;
    overflow-y: auto;
}

thead {
    top: 0;
    position: sticky;
    background-color: var(--bs-card-bg);
}

tbody {
    cursor: pointer;
}
</style>

<template>
    <div class="row mb-3">
        <div class="col-md-8">
            <Card class="mb-3">
                <template #header>
                    Select Employee
                </template>
                <div class="row g-3">
                    <div class="col-md-3">
                        <FormInput type="text" v-model="selectedEmployeeId"
                            label="Employee ID" id-prefix="empid"
                            disabled />
                    </div>
                    <div class="col-md-3">
                        <FormInput type="text" v-model="selectedEmployeeDepartment"
                            label="Department" id-prefix="department"
                            disabled />
                    </div>
                    <div class="col-md-3">
                        <FormInput type="text" v-model="selectedEmployeeFirstName"
                            label="First Name" id-prefix="firstName"
                            disabled />
                    </div>
                    <div class="col-md-3">
                        <FormInput type="text" v-model="selectedEmployeeLastName"
                            label="Last Name" id-prefix="lastName"
                            disabled />
                    </div>
                </div>
                <template #footer>
                    <div class="text-end">
                        <button type="button" class="btn btn-primary"
                            @click="_ => { isSelectEmployeePopupOpen = true; }">
                            Select
                        </button>
                    </div>
                </template>
            </Card>
            <Card>
                <template #header>
                    Employee Rates History
                </template>
                <DataTable
                    :loading="isLoading"
                    :value="employeeRates"
                    paginator
                    :rows="10"
                    sort-field="START_DATE"
                    :sort-order="-1"
                    :pt="{ table: { class: 'table table-bordered table-hover' }}">
                    <Column field="START_DATE" header="Start Date" sortable />
                    <Column field="END_DATE" header="End Date" />
                    <Column field="DEPT" header="Department" />
                    <Column field="COMPANY_CODE" header="Company Code" />
                    <Column field="DAYSOFWORK" header="Days of Work" />
                    <Column field="RATE" header="Rate" />
                    <Column field="COLA" header="COLA" />
                    <Column field="PH_CONTRIBUTION" header="PH" />
                    <Column field="SSS_CONTRIBUTION" header="SSS" />
                    <Column field="SSS_CONTRIBUTION_EMP" header="SSS EMP" />
                    <Column field="PAGIBIG_CONTRIBUTION" header="PAGIBIG" />
                    <Column field="PAGIBIG_CONTRIBUTION_EMP" header="PAGIBIG EMP" />
                    <Column field="WTX" header="WTX" />
                    <template #empty>
                        <div class="text-center">
                            No data yet.
                        </div>
                    </template>
                </DataTable>
            </Card>
        </div>
        <div class="col-md-4">
            <Card>
                <template #header>
                    Update Rate
                </template>
                <FormInput label="RATE" type="number" step=".01" v-model="employeeRate.RATE"
                    :is-horizontal="true" id-prefix="rate" class="mb-3" input-class="form-control-sm"
                    :errors="errors.RATE"
                    @keyup="recalculateMonthlyRate" @change="recalculateMonthlyRate"
                    @keydown-enter="focusNextInput('daysOfWorkFormInput')" />
                <FormInput label="DAYS OF WORK" type="number" v-model="employeeRate.DAYSOFWORK"
                    :is-horizontal="true" id-prefix="daysOfWork" class="mb-3" input-class="form-control-sm"
                    :errors="errors.DAYSOFWORK"
                    @keyup="recalculateMonthlyRate" @change="recalculateMonthlyRate"
                    @keydown-enter="focusNextInput('companyCodeFormInput')" />
                <FormInput label="MONTHLY RATE" type="number" step=".01" v-model="monthlyRate" disabled
                    :is-horizontal="true" id-prefix="monthlyRate" class="mb-3" input-class="form-control-sm" />
                <FormSelect label="COMPANY CODE" v-model="employeeRate.COMPANY_CODE"
                    :options="companies" :option-to-string="(company) => company.COMPANY_DESCRIPTION"
                    :option-value="(company) => company.COMPANY_CODE"
                    id-prefix="companyCode" :is-horizontal="true" class="mb-3" select-class="form-select-sm"
                    :errors="errors.COMPANY_CODE"
                    @keydown-enter="focusNextInput('departmentFormInput')" />
                <FormSelect label="DEPARTMENT" v-model="employeeRate.DEPT"
                    :options="departments" :option-to-string="(department) => department.DEPT"
                    :option-value="(department) => department.DEPT"
                    id-prefix="department" :is-horizontal="true" class="mb-3" select-class="form-select-sm"
                    :errors="errors.DEPT"
                    @keydown-enter="focusNextInput('colaFormInput')" />
                <FormInput label="COLA" type="number" step=".01" v-model="employeeRate.COLA"
                    :is-horizontal="true" id-prefix="cola" class="mb-3" input-class="form-control-sm"
                    :errors="errors.COLA"
                    @keydown-enter="focusNextInput('phContFormInput')" />
                <FormInput label="PH CONT" type="number" step=".01" v-model="employeeRate.PH_CONTRIBUTION"
                    :is-horizontal="true" id-prefix="phCont" class="mb-3" input-class="form-control-sm"
                    :errors="errors.PH_CONTRIBUTION"
                    @keydown-enter="focusNextInput('sssContFormInput')" />
                <FormInput label="SSS CONT" type="number" step=".01" v-model="employeeRate.SSS_CONTRIBUTION"
                    :is-horizontal="true" id-prefix="sssCont" class="mb-3" input-class="form-control-sm"
                    :errors="errors.SSS_CONTRIBUTION"
                    @keydown-enter="focusNextInput('sssEmpContFormInput')" />
                <FormInput label="SSS EMP CONT" type="number" step=".01" v-model="employeeRate.SSS_CONTRIBUTION_EMP"
                    :is-horizontal="true" id-prefix="sssEmpCont" class="mb-3" input-class="form-control-sm"
                    :errors="errors.SSS_CONTRIBUTION_EMP"
                    @keydown-enter="focusNextInput('pagibigFormInput')" />
                <FormInput label="PAGIBIG" type="number" step=".01" v-model="employeeRate.PAGIBIG_CONTRIBUTION"
                    :is-horizontal="true" id-prefix="pagibig" class="mb-3" input-class="form-control-sm"
                    :errors="errors.PAGIBIG_CONTRIBUTION"
                    @keydown-enter="focusNextInput('pagibigEmpFormInput')" />
                <FormInput label="PAGIBIG EMP" type="number" step=".01" v-model="employeeRate.PAGIBIG_CONTRIBUTION_EMP"
                    :is-horizontal="true" id-prefix="pagibigEmp" class="mb-3" input-class="form-control-sm"
                    :errors="errors.PAGIBIG_CONTRIBUTION_EMP"
                    @keydown-enter="focusNextInput('wtxFormInput')" />
                <FormInput label="WTX" type="number" step=".01" v-model="employeeRate.WTX"
                    :is-horizontal="true" id-prefix="wtx" class="mb-3" input-class="form-control-sm"
                    :errors="errors.WTX"
                    @keydown-enter="focusNextInput('effDateFormInput')" />
                <FormInput label="Eff. Date" type="date" v-model="employeeRate.START_DATE"
                    :is-horizontal="true" id-prefix="effDate" class="mb-3" input-class="form-control-sm"
                    :errors="errors.START_DATE"
                    @keydown-enter="focusNextInput('updateRateButton')" />
                <template #footer>
                    <div class="text-end">
                        <button type="click" class="btn btn-primary" id="updateRateButton"
                            :disabled="isUpdating"
                            @click="updateRate">
                            <div v-show="isUpdating"
                                class="spinner-border spinner-border-sm text-light"
                                role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            Update Rate
                        </button>
                    </div>
                </template>
            </Card>
        </div>
    </div>

    <SelectEmployee :is-open="isSelectEmployeePopupOpen"
        :on-close="_ => { isSelectEmployeePopupOpen = false; }"
        :on-select-employee="employee => {
            selectedEmployee = employee
        }" />
</template>

<script setup>
import { ref, onMounted, computed, watch } from "vue";

import axios from "axios";

import { useFlashMessages } from "@/composables/flashmessages";
import { useDepartments } from "@/composables/catalog/departments.js";
import { useCompanies } from "@/composables/data/companies";
import { useEmployeeRates } from "@/composables/data/employeeRates";

import Card from "@/components/utils/Card.vue";
import FormInput from "@/components/utils/FormInput.vue";
import FormSelect from "@/components/utils/FormSelect.vue";
import SelectEmployee from "@/components/utils/SelectEmployee.vue";

import Column from "primevue/column";
import DataTable from "primevue/datatable";

import focusNextInput from "@/utils/focusnext.js";
import handleFetchErrors from "@/utils/handleFetchErrors";
import scrollToTop from "@/utils/scrollToTop";

const { addFlashMessage } = useFlashMessages();

const isSelectEmployeePopupOpen = ref(false);

const errors = ref({});

const { companies, getCompanies } = useCompanies();
const { departments } = useDepartments();

const selectedEmployee = ref(null);

const selectedEmployeeId = computed(() => selectedEmployee.value?.EMPID);
const selectedEmployeeDepartment = computed(() => selectedEmployee.value?.DEPT);
const selectedEmployeeFirstName = computed(() => selectedEmployee.value?.FNAME);
const selectedEmployeeLastName = computed(() => selectedEmployee.value?.LNAME);

const monthlyRate = ref();
const maxEffDate = ref();

const { employeeRates, getEmployeeRates, postEmployeeRate } = useEmployeeRates();
const employeeRate = ref({});

const monthlyRateUpdateTimeout = ref(null);

const isLoading = ref(false);
const isUpdating = ref(false);

async function fetchCompanies() {
    try {
        await getCompanies();
    } catch(e) {
        addFlashMessage('ERROR', e.message);
        scrollToTop();
    }
}

async function fetchSSSMaxEffectivityDate() {
    try {
        const response = await axios.get(route('api.govt-sss-contributions.latest'));
        maxEffDate.value = response.data.data.EFF_DATE;
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, 'Error while fetching latest SSS bracket date.');
        scrollToTop();
    }
}

onMounted(async () => {
    fetchSSSMaxEffectivityDate();
    fetchCompanies();
});

async function fetchRates() {
    isLoading.value = true;
    try {
        await getEmployeeRates(selectedEmployee.value.EMPID);

        // Fetch latest rate, populate the form
        const latestEmployeeRate = employeeRates.value.filter(employeeRate => employeeRate.END_DATE == '9999-12-31')[0];
        if(latestEmployeeRate) {
            employeeRate.value = Object.assign({}, latestEmployeeRate);
            monthlyRate.value = employeeRate.value.RATE * employeeRate.value.DAYSOFWORK;
        } else {
            employeeRate.value = {
                DEPT: employee.value.DEPT,
                START_DATE: new Date().toISOString().split('T')[0]
            };
            monthlyRate.value = 0;
        }
    } catch (e) {
        addFlashMessage('ERROR', e.message);
        scrollToTop();
    }
    isLoading.value = false;
}

watch(selectedEmployee, fetchRates);

const updateRate = async () => {
    isUpdating.value = true;
    errors.value = {};

    try {
        await postEmployeeRate(selectedEmployee.value.EMPID, employeeRate.value);
        addFlashMessage('SUCCESS', 'Successfully saved employee rate.');
        fetchRates();
    } catch (e) {
        addFlashMessage('ERROR', e.message);
    }
    scrollToTop();
    isUpdating.value = false;
};

async function recalculateMonthlyRate() {
    if(!employeeRate.value.RATE || !employeeRate.value.DAYSOFWORK) {
        monthlyRate.value = 0;
        employeeRate.value.PH_CONTRIBUTION = 0;
        employeeRate.value.SSS_CONTRIBUTION = 0;
        employeeRate.value.SSS_CONTRIBUTION_EMP = 0;
        return;
    }

    monthlyRate.value = employeeRate.value.RATE * employeeRate.value.DAYSOFWORK;

    if(monthlyRateUpdateTimeout.value)
        clearTimeout(monthlyRateUpdateTimeout.value);

    monthlyRateUpdateTimeout.value = setTimeout(async () => {
        // Calculate Philhealth contribution
        if(monthlyRate.value <= 10000)
            employeeRate.value.PH_CONTRIBUTION = 500 / 2;
        else
            employeeRate.value.PH_CONTRIBUTION = (monthlyRate.value * 0.05) / 2;

        // Calculate SSS contribution
        try {
            const response = await axios.get(route('api.govt-sss-contributions.latest'),
                { params: { START_LT: monthlyRate.value, } });
            employeeRate.value.SSS_CONTRIBUTION = response.data.data.SSS_CONTRIBUTION;
            employeeRate.value.SSS_CONTRIBUTION_EMP = response.data.data.SSS_CONTRIBUTION_EMP;
        } catch (e) {
            console.log(e);
            handleFetchErrors(e, 'Error while determining SSS bracket.');
            scrollToTop();
        }

        // Calculate PAGIBIG Contribution PURPOSELY COMMENTED OUT FOR FUTURE PROSPECTS (requested to be temp removed)
        // let pagibigContributionPercentage;
        // if(monthlyRate.value <= 1500) pagibigContributionPercentage = 0.01;
        // else pagibigContributionPercentage = 0.02;
        // employeeRate.value.PAGIBIG_CONTRIBUTION_EMP = Math.min(200, monthlyRate.value * 0.02); // Employer
        // employeeRate.value.PAGIBIG_CONTRIBUTION = Math.min(200, monthlyRate.value * pagibigContributionPercentage); // Employee
    }, 200);
}
</script>
