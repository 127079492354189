import { ref } from "vue";

import constructFetchError from "./constructFetchError";

import axios from 'axios';

export function useReconParms() {
    const reconParms = ref(null);
    const reconParm = ref(null);

    const getReconParms = async (params) => {
        try {
            const getReconParmsResponse = await axios.get(route('api.recon-parms.index', params));
            reconParms.value = getReconParmsResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching recon parms.');
        }
    };

    const getReconParmsWithinTimePeriod = async (TIME_PERIOD) => {
        try {
            const getReconParmsResponse = await axios.get(route('api.recon-parms.within-time-period.show', {
                time_period: TIME_PERIOD
            }));
            reconParms.value = getReconParmsResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching recon parms.');
        }
    };

    const getReconParm = async (reconParmId, params = null) => {
        try {
            const getReconParmResponse = await axios.get(route('api.recon-parms.show', {
                recon_parm: reconParmId,
                ...params
            }));
            reconParm.value = getReconParmResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching recon parms.');
        }
    };
    
    return { reconParms, reconParm, getReconParms, getReconParmsWithinTimePeriod, getReconParm };
}
