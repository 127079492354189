import { ref } from "vue";

import constructFetchError from "./constructFetchError";

import axios from 'axios';

export function useEmployees() {
    const employees = ref(null);
    const totalRecords = ref(null);
    const employee = ref(null);

    const getEmployees = async (params) => {
        try {
            const getEmployeesResponse = await axios.get(route('api.employees.index', params));
            totalRecords.value = getEmployeesResponse.data.meta.total;
            employees.value = getEmployeesResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching employees.');
        }
    };

    const getEmployee = async (empid) => {
        try {
            const getEmployeeResponse = await axios.get(route('api.employees.show', {
                employee: empid
            }));
            employee.value = getEmployeeResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching employee.');
        }
    };

    const getEmployeesEligibleForCommission = async (startTimePeriod, endTimePeriod, location) => {
        try {
            const getEmployeesEligibleForCommissionResponse = await axios.get(route('api.employees.eligible-for-commission.index', {
                START_TIME_PERIOD: startTimePeriod,
                END_TIME_PERIOD: endTimePeriod,
                LOCATION: location
            }));
            employees.value = getEmployeesEligibleForCommissionResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching employees.');
        }
    };

    const getEmployeesWithHoursWithoutPayslipOnTimePeriod = async (timePeriod, department) => {
        try {
            const getEmployeesResponse = await axios.get(route('api.time-periods.employees-with-hours-without-payslip.index', {
                TIME_PERIOD: timePeriod,
                DEPT: department,
            }));
            employees.value = getEmployeesResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching employees.');
        }
    };
    
    return { employees, totalRecords, getEmployees, getEmployeesEligibleForCommission,
        employee, getEmployee, getEmployeesWithHoursWithoutPayslipOnTimePeriod };
}
