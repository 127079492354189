import { ref } from "vue";

import constructFetchError from "./constructFetchError";

import axios from 'axios';

export function useInventoryCheckDescriptions() {
    const inventoryCheckDescriptions = ref(null);
    const totalRecords = ref(null);

    const getInventoryCheckDescriptions = async (params) => {
        try {
            const getInventoryCheckDescriptionsResponse = await axios.get(route('api.inventory-check-descriptions.index', params));
            inventoryCheckDescriptions.value = getInventoryCheckDescriptionsResponse.data.data;
            totalRecords.value = getInventoryCheckDescriptionsResponse.data.meta.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching inventory check descriptions.');
        }
    };

    const postInventoryCheckDescription = async (inventoryCheckDescription) => {
        try {
            const postInventoryCheckDescriptionResponse = await axios.post(route('api.inventory-check-descriptions.store'),
                inventoryCheckDescription);
            return postInventoryCheckDescriptionResponse;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while saving inventory check description.');
        }
    };
    
    return { inventoryCheckDescriptions, totalRecords,
        getInventoryCheckDescriptions, postInventoryCheckDescription };
}
