<template>
    <div class="d-flex justify-content-center">
        <Card class="w-50">
            <div class="row p-5">
                <FormInput label="VOUCHER ID:" is-horizontal
                    class="col-md-10 mb-2" v-model="voucher.VOUCHER_ID"
                    id-prefix="voucherId" input-class="form-control"
                    :errors="errors.VOUCHER_ID" disabled/>
                <FormInput label="VOUCHER DATE:" is-horizontal type="date"
                    class="col-md-10 mb-2" v-model="voucher.VOUCHER_DATE"
                    id-prefix="voucherDate" input-class="form-control"
                    :errors="errors.VOUCHER_DATE"/>
                <FormInput label="VOUCHER AMOUNT:" is-horizontal
                    class="col-md-10 mb-2" v-model="voucher.VOUCHER_AMT"
                    id-prefix="voucherAmt" input-class="form-control"
                    :errors="errors.VOUCHER_AMT"/>
                <FormInput label="DESCRIPTION:" is-horizontal
                    class="col-md-10 mb-2" v-model="voucher.DESCRIPTION"
                    id-prefix="description" input-class="form-control"
                    :errors="errors.DESCRIPTION"/>
                <FormTextarea label="MEMO:" id-prefix="memo"
                    v-model="voucher.MEMO" input-class="form-control"
                    is-horizontal class="col-md-10 mb-2"
                    :errors="errors.MEMO"/>
                <FormSelect label="PAYEE TYPE:" is-horizontal
                    class="col-md-10 mb-2" v-model="voucher.PAYEE_TYPE"
                    :options="payeeTypes"
                    :option-to-string="payeeType => payeeType.name"
                    :option-value="payeeType => payeeType.value"
                    :errors="errors.PAYEE_TYPE"/>
                <button class="btn btn-primary col-md-2 mb-2"
                    @click="searchPayee">Search Payee</button>
                <FormInput label="PAYEE ID:" is-horizontal
                    class="col-md-10 mb-2" v-model="voucher.PAYEE_ID"
                    id-prefix="payeeId" input-class="form-control"
                    :errors="errors.PAYEE_ID" disabled/>
                <FormInput label="PAYEE NAME:" is-horizontal
                    class="col-md-10 mb-2" v-model="voucher.PAYEE_NAME"
                    id-prefix="payeeName" input-class="form-control"
                    :errors="errors.PAYEE_NAME" disabled/>
                <FormInput label="APPROVED BY:" is-horizontal
                    class="col-md-10 mb-2" v-model="voucher.APPROVED_BY"
                    id-prefix="approvedBy" input-class="form-control"
                    :errors="errors.APPROVED_BY" disabled/>
                <button class="btn btn-primary col-md-2 mb-2"
                    :disabled="!voucher.APPROVED_BY"
                    @click="deleteApproval">Delete</button>
                <div class="col-md-12 d-flex justify-content-center mt-5">
                    <button class="btn btn-primary w-25"
                    @click="handleSubmit">UPDATE</button>
                </div>
            </div>
        </Card>
    </div>
    <SelectAccount
        :is-open="isSelectingAccount"
        :on-close="_ => isSelectingAccount = false"
        :onSelectAccount="handleSelectAccount"
    />
    <SelectEmployee
        :is-open="isSelectingEmployee"
        :on-close="_ => isSelectingEmployee = false"
        :onSelectEmployee="handleSelectEmployee"
    />
</template>

<script setup>
import { onMounted, ref } from 'vue';

import Card from '@/components/utils/Card.vue';
import FormInput from '@/components/utils/FormInput.vue';
import FormSelect from '@/components/utils/FormSelect.vue';
import SelectAccount from '@/components/utils/SelectAccount.vue';
import SelectEmployee from '@/components/utils/SelectEmployee.vue';
import FormTextarea from '@/components/utils/FormTextarea.vue';

import { useFlashMessages } from "@/composables/flashmessages";
import handleFetchErrors from "@/utils/handleFetchErrors";

const { addFlashMessage } = useFlashMessages();

const voucher = ref({
    VOUCHER_ID: null,
    VOUCHER_DATE: null,
    VOUCHER_AMT: null,
    DESCRIPTION: null,
    MEMO: null,
    PAYEE_TYPE: null,
    PAYEE_ID: null,
    APPROVED_BY: null,
    PAYEE_NAME: null,
});

const errors = ref({});

const isSelectingAccount = ref(false);
const isSelectingEmployee = ref(false);

const payeeTypes = [
    { name: 'External', value: 'E'},
    { name: 'Internal', value: 'I'}
]

onMounted(async () => {
    const voucher_id = new URLSearchParams(window.location.search).get('VOUCHER_ID');
    try {
        const response = await axios.get(route('api.vouchers.show', { id: voucher_id }));
        voucher.value = {
            VOUCHER_ID: response.data.VOUCHER_ID,
            VOUCHER_DATE: response.data.VOUCHER_DATE,
            VOUCHER_AMT: response.data.VOUCHER_AMT,
            DESCRIPTION: response.data.DESCRIPTION,
            MEMO: response.data.MEMO,
            PAYEE_TYPE: response.data.PAYEE_TYPE,
            PAYEE_ID: response.data.PAYEE_ID,
            APPROVED_BY: response.data.APPROVED_BY ?? null,
            PAYEE_NAME: response.data.PAYEE_NAME,
        }
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, 'Error while fetching voucher.');
    }
});

function searchPayee() {
    switch (voucher.value.PAYEE_TYPE) {
        case 'E':
            isSelectingAccount.value = true;
            break;
        case 'I':
            isSelectingEmployee.value = true;
            break;
        default:
            break;
    }
}

function handleSelectAccount(selectedAccount) {
    voucher.value.PAYEE_ID = selectedAccount.ACCOUNT_ID;
    voucher.value.PAYEE_NAME = selectedAccount.ACCOUNT_NAME;
}

function handleSelectEmployee(selectedEmployee) {
    voucher.value.PAYEE_ID = selectedEmployee.EMPID;
    voucher.value.PAYEE_NAME = `${selectedEmployee.FNAME} ${selectedEmployee.LNAME}`;
}

async function handleSubmit() {
    try {
        if (voucher.value.APPROVED_BY) {
            addFlashMessage('ERROR','Cannot update when approved');
            return;
        }

        const response = await axios.patch(route('api.vouchers.update', {
            id: voucher.value.VOUCHER_ID
        }), {
            MEMO: voucher.value.MEMO,
            PAYEE_ID: voucher.value.PAYEE_ID,
            PAYEE_TYPE: voucher.value.PAYEE_TYPE,
            VOUCHER_AMT: voucher.value.VOUCHER_AMT,
            DESCRIPTION: voucher.value.DESCRIPTION,
            VOUCHER_DATE: voucher.value.VOUCHER_DATE,
        });
        addFlashMessage('SUCCESS', response.data.message);
    } catch (e) {
        console.log(e);
        if(e.response && e.response.status == 422) {
            errors.value = e.response.data.errors;
        } else {
            addFlashMessage('ERROR', `An error occurred while saving voucher. Please contact your administrator.`);
        }
    }
}

async function deleteApproval() {
    try {
        const response = await axios.delete(route('api.voucher-accounting-approval.destroy',{
            id: voucher.value.VOUCHER_ID
        }));
        if (response.data.message === 'Success') {
            voucher.value.APPROVED_BY = null;
            addFlashMessage('SUCCESS', 'Accounting approval deleted.');
            return;
        }
        addFlashMessage('ERROR', 'Failed to delete approval.');
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, 'Error while deleting approval.');
        scrollToTop();
    }
}
</script>
