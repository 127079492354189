<template>
    <Card>
        <template #header>
            Payslips
        </template>
        <DataTable :loading="isLoading" lazy
            :value="payslips" :total-records="totalRecords"
            paginator :rows="10" @page="e => { onPage(e); fetchPayslips(); }"
            sort-mode="multiple" @sort="e => { onSort(e); fetchPayslips(); }"
            v-model:filters="filters" filter-display="row" @filter="tableOnFilter"
            selection-mode="single" v-model:selection="selectedEmployeePayslip"
            :pt="{ table: { class: 'table table-bordered table-hover' } }">
            <Column field="employee" header="Employee ID" sortable
                filter-field="relation_employee_EMPID"
                :pt="{ filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
                <template #body="slotProps">
                    {{ slotProps.data.employee?.EMPID }}
                </template>
            </Column>
            <Column field="employee" header="First Name" sortable
                filter-field="relation_employee_FNAME"
                :pt="{ filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
                <template #body="slotProps">
                    {{ slotProps.data.employee?.FNAME }}
                </template>
            </Column>
            <Column field="employee" header="Last Name" sortable
                filter-field="relation_employee_LNAME"
                :pt="{ filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
                <template #body="slotProps">
                    {{ slotProps.data.employee?.LNAME }}
                </template>
            </Column>
            <Column field="employee" header="Department" sortable
                filter-field="relation_employee_DEPT"
                :pt="{ filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
                <template #body="slotProps">
                    {{ slotProps.data.employee?.DEPT }}
                </template>
            </Column>
            <Column field="HOURS" header="Hours" sortable
                :pt="{ filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="DOW" header="DOW" sortable
                :pt="{ filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="GROSS_PAY" header="Gross" sortable
                :pt="{ filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="TOTAL_DEDUCT" header="Deduct" sortable
                :pt="{ filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="FINAL" header="Final" sortable
                :pt="{ filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <template #empty>
                <div class="text-center">
                    No payslips.
                </div>
            </template>
        </DataTable>
        <template #footer>
            <div class="text-end">
                <button type="button" class="btn btn-primary me-1"
                    @click="generatePayslipsPDF">
                    Print All Payslips
                </button>
                <button type="button" class="btn btn-primary"
                    :disabled="!selectedEmployeePayslip"
                    @click="generatePayslipOfEmployeePDF">
                    Print Selected Payslip
                </button>
            </div>
        </template>
    </Card>
</template>

<script setup>
import { ref, watch } from 'vue';

import { useDataTableParams } from '@/composables/data/dataTableParams';
import { usePayslips } from '@/composables/data/payslips';

import Card from '@/components/utils/Card';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

const props = defineProps({
    timePeriod: Number,
});

const emit = defineEmits(['error']);

const { params, filters, onPage, onFilter, onSort } = useDataTableParams(
    ['HOURS', 'DOW', 'GROSS_PAY', 'TOTAL_DEDUCT', 'FINAL'],
    null,
    {
        relation_employee_EMPID: { value: null, matchMode: 'contains' },
        relation_employee_DEPT: { value: null, matchMode: 'contains' },
        relation_employee_FNAME: { value: null, matchMode: 'contains' },
        relation_employee_LNAME: { value: null, matchMode: 'contains' }
    });

const { payslips, totalRecords, getPayslips } = usePayslips();

const isLoading = ref(false);

const fetchTimeout = ref(null);

const selectedEmployeePayslip = ref(null);

async function fetchPayslips() {
    payslips.value = null;

    if(!props.timePeriod)
        return;

    isLoading.value = true;
    try {
        await getPayslips({
            ...params.value,
            filters: {
                ...params.value.filters,
                TIME_PERIOD: {
                    value: props.timePeriod,
                    matchMode: 'equals'
                }
            }
        });
    } catch(e) {
        emit('error', e.message);
    }
    isLoading.value = false;
}

function tableOnFilter(e) {
    onFilter(e);
    if(fetchTimeout.value)
        clearTimeout(fetchTimeout.value);
    fetchTimeout.value = setTimeout(fetchPayslips, 300);
}

watch(() => props.timePeriod, fetchPayslips);

function generatePayslipsPDF() {
    window.open(route('api.time-periods.payslips.pdf.show', {
        time_period: props.timePeriod
    }));
}

function generatePayslipOfEmployeePDF() {
    window.open(route('api.time-periods.payslips.pdf.show', {
        time_period: props.timePeriod,
        empid: selectedEmployeePayslip.value.employee.EMPID,
    }));
}
</script>
