<template>
    <form @submit.prevent="addStyle">
        <Card :class="class">
            <template #header>
                Enter Style Details
            </template>
            <FormInput type="text" v-model="styleDetails.style" required
                label="Style" class="mb-3" is-horizontal
                :errors="errors.style" />
            <FormInput type="text" v-model="styleDetails.color" required
                label="Color" class="mb-3" is-horizontal
                :errors="errors.color" />
            <FormInput type="number" v-model="styleDetails.quantity" required
                label="Quantity" class="mb-3" is-horizontal min="1"
                :errors="errors.quantity" />
            <FormInput type="text" v-model="styleDetails.box" required
                label="Box" class="mb-3" is-horizontal min="1"
                :errors="errors.box" />
            <template #footer>
                <div class="text-end">
                    <button type="reset" class="btn btn-outline-secondary me-2"
                        @click="reset">Reset</button>
                    <button type="submit" class="btn btn-primary">Add</button>
                </div>
            </template>
        </Card>
    </form>
</template>

<script setup>
import { ref } from 'vue';

import { useStyleSMSkus } from '@/composables/data/styleSMSkus';

import Card from '@/components/utils/Card.vue';
import FormInput from '@/components/utils/FormInput.vue';

const props = defineProps({
    class: String
});

const emit = defineEmits(['add']);

const errors = ref({});

const { styleSMSkus, getStyleSMSkus } = useStyleSMSkus();

const styleDetails = ref({
    style: null,
    color: null,
    quantity: null,
    box: null,
});

function reset() {
    styleDetails.value = {
        style: null,
        color: null,
        quantity: null,
        box: null
    };
}

async function addStyle() {
    errors.value = {};

    try {
        await getStyleSMSkus(styleDetails.value.style, {
            COLOR: styleDetails.value.color
        });
        if(styleSMSkus.value.length == 0) {
            errors.value.color = ['Color does not exist for given Style.'];
            return;
        }
        emit('add', {
            ...styleDetails.value,
            sku: styleSMSkus.value[0].SM_SKU
        });
        styleDetails.value = {
            style: null,
            color: null,
            quantity: null,
            box: null
        };
    } catch(e) {
        console.log(e);
        if(e.status == 404)
            errors.value.style = ['Style does not exist.'];
        else
            addFlashMessage('ERROR', e.message);
    }
}
</script>
