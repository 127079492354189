<template>

<div class="row g-3">
        <div class="col-md-4">
            <Card>
                <template #header>
                    Select Time Period
                </template>
                <TimePeriodTable show-from-current-date
                    paytype="MONTHLY"
                    :hide-properties="new Set(['PAYTYPE', 'STATUS'])"
                    :sort="[{ field: 'TIME_PERIOD', order: -1 }]"
                    v-model="timePeriod" />
            </Card>
        </div>
        <div class="col-md-4">
            <Card>
                <template #header>
                    Reports
                </template>
                <div class="d-grid gap-3">
                    <button type="button" class="btn btn-primary"
                        :disabled="!timePeriod"
                        @click="generateReport('absent-hours-on-month-spreadsheet')">
                        Absent Hours On Month
                    </button>
                </div>
            </Card>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';

import Card from '@/components/utils/Card.vue';
import TimePeriodTable from '@/components/utils/tables/TimePeriodTable.vue';

const timePeriod = ref(null);

function generateReport(report) {
    window.open(route(`api.time-periods.${report}.show`, {
        time_period: timePeriod.value.TIME_PERIOD
    }));
}
</script>
