<style scoped>
#employeeHoursSectionCard {
    height: 350px;
    overflow-y: auto;
}

.weekend-input {
    background-color: #c880c8;
}
</style>

<template>
    <Card :class="class" id="employeeHoursSectionCard">
        <div class="row g-3 mb-3">
            <div class="col-md-6">
                <h2>Enter Employee Hours</h2>
            </div>
            <div class="col-md-6 text-end">
                <div class="d-flex justify-content-end">
                    <button type="button" class="btn btn-primary me-2 mb-2" :disabled="employee === null"
                        @click="clearEmployeeHoursButtonOnClick">
                        Clear
                    </button>
                    <button type="button" class="btn btn-primary me-4 mb-2" :disabled="employee === null"
                        @click="copyPreviousJONOS">
                        Copy Previous JONOS
                    </button>
                    <button type="button" class="btn btn-primary me-2 mb-2" :disabled="employee === null"
                        @click="decreaseNumberOfJobsButtonOnClick">
                        -
                    </button>
                    <h3 class="me-2 mb-2">{{ employeeJobs.length }}</h3>
                    <button type="button" class="btn btn-primary me-2 mb-2" :disabled="employee === null"
                        @click="increaseNumberOfJobsButtonOnClick">
                        +
                    </button>
                </div>
            </div>
        </div>
        <div v-if="loadingFlagStore.has('fetchCurrentHours') ||
            loadingFlagStore.has('copyPreviousJONOS')" class="text-center">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div v-else-if="employeeJobs && employeeJobs.length > 0" class="table-responsive">
            <table>
                <thead>
                    <tr v-if="employeeJobs.length > 0">
                        <th class="text-center">JONO</th>
                        <th class="text-center">STYLE</th>
                        <th class="text-center">RATE</th>
                        <th class="text-center" v-for="dateInTimePeriod in datesInTimePeriod">
                            {{ moment(dateInTimePeriod).format("ddd") }}<br />
                            {{ dateInTimePeriod.getMonth() + 1 }}/{{ dateInTimePeriod.getDate() }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="employeeJob, jobIndex in employeeJobs">
                        <td>
                            <input type="text" :value="employeeJob.JONO"
                                :id="'jono' + jobIndex + 'FormInput'"
                                @keydown.prevent="e => { e.target.value = employeeJob.JONO; }"
                                @change="e => { e.target.value = employeeJob.JONO; }"
                                class="form-control form-control-sm"
                                @keydown.enter="_ => { focusNextInput('style' + jobIndex + 'FormInput'); }"
                                @keydown.space="_ => {
                                    jonoStyleRatePopupSettings.jobIndex = jobIndex;
                                    jonoStyleRatePopupSettings.field = 'JONO';
                                    jonoStyleRatePopupSettings.fieldToUse = 'JONO';
                                    jonoStyleRatePopupSettings.fields = ['JONO'];
                                    jonoStyleRatePopupSettings.options = jonoChoices;
                                    jonoStyleRatePopupSettings.preselectedValue = employeeJob.JONO;
                                    isJonoStyleRateSelectionPopupOpen = true;
                                }" />
                        </td>
                        <td>
                            <FormSelect v-model="employeeJob.STYLE" :id-prefix="'style' + jobIndex"
                                :options="styleChoices[jobIndex]"
                                :option-value="styleChoice => styleChoice.STYLE"
                                :option-to-string="styleChoice => styleChoice.STYLE"
                                @change="styleSelectOnChange(jobIndex)"
                                @keydown-enter="_ => { focusNextInput('rate' + jobIndex + 'FormInput'); }"
                                hide-label select-class="form-select-sm" />
                        </td>
                        <td>
                            <FormSelect v-model="employeeJob.RATE" :id-prefix="'rate' + jobIndex"
                                :options="rateChoices[jobIndex]"
                                :option-value="rateChoice => rateChoice.PAYRATE"
                                :option-to-string="rateChoice => rateChoice.PAYRATE"
                                hide-label select-class="form-select-sm"
                                @keydown-enter="focusNextInput('jobHours' + jobIndex + 0)" />
                        </td>
                        <td v-for="timePeriodDate, dayIndex in datesInTimePeriod">
                            <input type="number" class="form-control form-control-sm"
                                :id="'jobHours' + jobIndex + dayIndex"
                                :class="{ 'weekend-input': [0, 6].includes(moment(timePeriodDate).day()) }"
                                v-model="employeeJob.HOURS_PER_DAY[timePeriodDate].hours"
                                @change="$emit('employee-jobs-change', employeeJobs)"
                                @keydown.enter.prevent="e => {
                                    if(dayIndex == datesInTimePeriod.length - 1) {
                                        if(jobIndex == employeeJobs.length - 1)
                                            focusNextInput('updateHoursButton');
                                        else
                                            focusNextInput('jono' + (jobIndex + 1) + 'FormInput');
                                    }

                                    else
                                        focusNextInput('jobHours' + jobIndex + (dayIndex + 1));
                                }">
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-else class="d-flex justify-content-center align-items-center h-50">
            <p>No employee hours saved yet.</p>
        </div>
    </Card>
    <JonoStyleRateSelectionPopup :is-open="isJonoStyleRateSelectionPopupOpen"
        :options="jonoStyleRatePopupSettings.options"
        :field-to-use="jonoStyleRatePopupSettings.fieldToUse"
        :fields="jonoStyleRatePopupSettings.fields"
        :preselected-value="jonoStyleRatePopupSettings.preselectedValue"
        @select="onJonoStyleRateSelect"
        @close="_ => { isJonoStyleRateSelectionPopupOpen = false; }" />
</template>

<script setup>
import { inject, ref, watch } from "vue";

import Card from '@/components/utils/Card.vue';
import FormSelect from '@/components/utils/FormSelect.vue';

import JonoStyleRateSelectionPopup from './JonoStyleRateSelectionPopup';

import focusNextInput from '@/utils/focusnext';

import moment from "moment";

const props = defineProps({
    employee: Object,
    timePeriod: Object,
    employeeJobs: Array,
    datesInTimePeriod: Array,
    availableLeavesDays: Number,
    class: String
});

const emit = defineEmits(['employee-jobs-change', 'copy-click', 'error']);
const loadingFlagStore = inject('loadingFlagStore');

const employeeJobs = ref([]);

const jonoChoices = ref([]);
const styleChoices = ref([]);
const rateChoices = ref([]);

const isJonoStyleRateSelectionPopupOpen = ref(false);
const jonoStyleRatePopupSettings = ref({
    jobIndex: null,
    preselectedValue: null,
    field: null,
    fieldToUse: null,
    options: null,
    fields: null,
});

watch(() => props.datesInTimePeriod, () => {
    employeeJobs.value = [];
    emit('employee-jobs-change', employeeJobs.value);
});

watch(() => props.employee, async (_, __) => {
    if(props.employee === null || props.timePeriod === null)
        return;

    // Fetch JONOs
    loadingFlagStore.value.add('fetchJonos');
    try {
        const jonosResponse = await axios.get(route('api.joborders.jonos'), {
            params: {
                'AVAILABLE_IN': props.timePeriod.END_DATE
            }
        });
        jonoChoices.value = jonosResponse.data.data;
    } catch(e) {
        console.log(e);
        emit('error', 'Unable to fetch JONOs. Please contact your administrator.');
    }
    loadingFlagStore.value.delete('fetchJonos');

    loadingFlagStore.value.add('fetchCurrentHours');
    try {
        const employeeHoursInTimePeriodResponse = await axios.get(
            route('api.employees.hours.time-period.index',
                [props.employee.EMPID, props.timePeriod.TIME_PERIOD]));
        const selectedEmployeeJobs = [];
        employeeHoursInTimePeriodResponse.data.data.forEach(employeeHoursOnJob => {
            let matchingJobFilterResult = selectedEmployeeJobs.filter(job =>
                job.JONO === employeeHoursOnJob.JONO &&
                job.STYLE === employeeHoursOnJob.STYLE &&
                job.RATE === employeeHoursOnJob.RATE);

            let matchingJob = null;
            if (matchingJobFilterResult.length === 0) {
                selectedEmployeeJobs.push({
                    JONO: employeeHoursOnJob.JONO,
                    STYLE: employeeHoursOnJob.STYLE,
                    RATE: employeeHoursOnJob.RATE,
                    HOURS_PER_DAY: {}
                });
                props.datesInTimePeriod.forEach((date) => {
                    selectedEmployeeJobs.at(-1).HOURS_PER_DAY[date] = { hours: "" };
                });
                matchingJob = selectedEmployeeJobs.at(-1);
            } else {
                matchingJob = matchingJobFilterResult[0];
            }

            let employeeHoursDate = new Date(employeeHoursOnJob.DATE);
            matchingJob.HOURS_PER_DAY[employeeHoursDate].id = employeeHoursOnJob.id;
            matchingJob.HOURS_PER_DAY[employeeHoursDate].hours = employeeHoursOnJob.HOURS;
        });

        // Fetch choices for styles, rates
        for (let i = 0; i < selectedEmployeeJobs.length; i++) {
            const selectedEmployeeJob = selectedEmployeeJobs[i];
            await fetchStyleChoices(i, selectedEmployeeJob.JONO);
            await fetchRateChoices(i, selectedEmployeeJob.JONO, selectedEmployeeJob.RATE);
        }

        employeeJobs.value = selectedEmployeeJobs;
        emit('employee-jobs-change', employeeJobs.value);
    } catch (e) {
        console.log(e);
        emit('error', 'Unable to fetch employee jobs. Please contact your administrator.');
    }

    loadingFlagStore.value.delete('fetchCurrentHours');
});

watch(() => props.employeeJobs, async () => {
    if(props.timePeriod == null || props.employeeJobs == null)
        return;

    loadingFlagStore.value.add('fetchJonoChoices');
    // Fetch JONOs
    try {
        const jonosResponse = await axios.get(route('api.joborders.jonos'), {
            params: {
                'AVAILABLE_IN': props.timePeriod.END_DATE
            }
        });
        jonoChoices.value = jonosResponse.data.data;
    } catch(e) {
        console.log(e);
        emit('error', 'Unable to fetch JONOs. Please contact your administrator.');
    }

    // Fetch choices for styles, rates
    for (let i = 0; i < props.employeeJobs.length; i++) {
        const selectedEmployeeJob = props.employeeJobs[i];
        await fetchStyleChoices(i, selectedEmployeeJob.JONO);
        if (styleChoices.value[i].filter(styleChoice => styleChoice.STYLE == selectedEmployeeJob.STYLE).length == 0)
            styleChoices.value[i].push({STYLE: selectedEmployeeJob.STYLE});
        await fetchRateChoices(i, selectedEmployeeJob.JONO, selectedEmployeeJob.RATE);
    }

    employeeJobs.value = props.employeeJobs;
    loadingFlagStore.value.delete('fetchJonoChoices');
});

async function copyPreviousJONOS() {
    loadingFlagStore.value.add('copyPreviousJONOS');
    try {
        const previousTimePeriodHoursResponse = await axios.get(
            route('api.employees.hours.time-period.index',
                [props.employee.EMPID, props.timePeriod.TIME_PERIOD - 1]));
        const employeePreviousJobs = previousTimePeriodHoursResponse.data.data.reduce((jobs, employeeHoursOnJob) => {
            let newJobs = [...jobs];
            if(newJobs.filter((hoursOnJob) => hoursOnJob.JONO === employeeHoursOnJob.JONO &&
                hoursOnJob.STYLE === employeeHoursOnJob.STYLE &&
                hoursOnJob.RATE === employeeHoursOnJob.RATE).length === 0) {
                newJobs.push({
                    JONO: employeeHoursOnJob.JONO,
                    STYLE: employeeHoursOnJob.STYLE,
                    RATE: employeeHoursOnJob.RATE,
                    HOURS_PER_DAY: props.datesInTimePeriod.reduce((hoursPerDay, date) => (
                        {...hoursPerDay, [date]: { hours: "" }}
                    ), {})
                });
            }
            return newJobs;
        }, []);
        emit('employee-jobs-change', employeePreviousJobs);
    } catch(e) {
        console.log(e);
        emit('error', 'Unable to fetch previous JONOs. Please contact your administrator.');
    }
    loadingFlagStore.value.delete('copyPreviousJONOS');
}

function decreaseNumberOfJobsButtonOnClick() {
    if (employeeJobs.value.length <= 1)
        return;

    employeeJobs.value.pop();
}

function increaseNumberOfJobsButtonOnClick() {
    employeeJobs.value.push({
        JONO: "",
        STYLE: "",
        RATE: "",
        HOURS_PER_DAY: {}
    });
    props.datesInTimePeriod.forEach((date) => {
        employeeJobs.value.at(-1).HOURS_PER_DAY[date] = { hours: "" };
    });
}

async function fetchStyleChoices(index, jono) {
    loadingFlagStore.value.add('fetchStyleChoices');
    try {
        const stylesResponse = await axios.get(route('api.joborders.styles'), {
            params: {
                JONO: jono,
                AVAILABLE_IN: props.timePeriod.END_DATE
            }
        });
        styleChoices.value[index] = stylesResponse.data.data;
    } catch (e) {
        console.log(e);
        emit('error', 'Unable to fetch styles. Please contact your administrator.');
    }
    loadingFlagStore.value.delete('fetchStyleChoices');
}

async function fetchRateChoices(index, jono, style) {
    loadingFlagStore.value.add('fetchRateChoices');
    try {
        /**
         * This is currently some hardcoded scenarios from the MS Access Form.
         * TODO: Handle this by modeling the db relationships properly.
         */
        const params = { ACTIVE: 'Y' };
        if (jono === 'HOLIDAY' && style === 'HOLIDAY')
            params.PAYGROUP_LIKE = 'HOL';
        else if (jono === 'ABSENT' && style === 'EXCUSED')
            params.PAYGROUP_LIKE = 'ABSENT';
        else if (jono === 'LEAVE')
            params.PAYGROUP_LIKE = 'REGULAR';
        else if (jono === 'SALES')
            params.PAYGROUP_NOT_IN = 'ABSENT,HOLIDAY';
        else if (jono !== 'ABSENT' && style !== 'EXCUSED')
            params.PAYGROUP_NOT_IN = 'ABSENT';

        const ratesResponse = await axios.get(route('api.payrates.index'), { params });
        rateChoices.value[index] = ratesResponse.data.data;
    } catch (e) {
        console.log(e);
        emit('error', 'Unable to fetch rates. Please contact your administrator.');
    }
    loadingFlagStore.value.delete('fetchRateChoices');
}

async function jonoSelectOnChange(index) {
    // If LEAVE JONO, don't allow if there are no available leaves.
    if(employeeJobs.value[index].JONO == 'LEAVE' && props.availableLeavesDays <= 0) {
        alert('There are no available leaves for this employee.');
        employeeJobs.value[index].JONO = "";
        return;
    }

    await fetchStyleChoices(index, employeeJobs.value[index].JONO);
    rateChoices.value[index] = [];
    employeeJobs.value[index].STYLE = "";
    employeeJobs.value[index].RATE = "";
}

async function styleSelectOnChange(index) {
    await fetchRateChoices(index, employeeJobs.value[index].JONO, employeeJobs.value[index].STYLE);
    employeeJobs.value[index].RATE = "";
}

function onJonoStyleRateSelect(option) {
    isJonoStyleRateSelectionPopupOpen.value = false;
    employeeJobs.value[jonoStyleRatePopupSettings.value.jobIndex][jonoStyleRatePopupSettings.value.field] = option;
    if(jonoStyleRatePopupSettings.value.field == 'JONO') {
        jonoSelectOnChange(jonoStyleRatePopupSettings.value.jobIndex);
        setTimeout(_ => { focusNextInput('jonoSelect' + jonoStyleRatePopupSettings.value.jobIndex); }, 0);
    }
    else if(jonoStyleRatePopupSettings.value.field == 'STYLE') {
        styleSelectOnChange(jonoStyleRatePopupSettings.value.jobIndex);
        setTimeout(_ => { focusNextInput('styleSelect' + jonoStyleRatePopupSettings.value.jobIndex); }, 0);
    } else {
        setTimeout(_ => { focusNextInput('rateSelect' + jonoStyleRatePopupSettings.value.jobIndex); }, 0);
    }
}

function clearEmployeeHoursButtonOnClick() {
    employeeJobs.value = [];
}
</script>
