<template>
    <Card>
        <template #header>
            Select a Location
        </template>
        <DataTable :value="locations"
            paginator :rows="10"
            v-model:filters="filters" filterDisplay="row"
            v-model:selection="selectedLocation" selection-mode="single"
            :pt="{ table: { class: 'table table-bordered table-hover' } }">
            <Column field="LOCATION" header="Location" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
        </DataTable>
    </Card>
</template>

<script setup>
import { onMounted } from 'vue';

import { useFlashMessages } from '@/composables/flashmessages';

import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useLocations } from '@/composables/data/locations';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

import Card from '@/components/utils/Card.vue';

const { addFlashMessage } = useFlashMessages();

const { filters } = useDataTableParams(['LOCATION']);

const { locations, getLocations } = useLocations();

const selectedLocation = defineModel();

onMounted(async() => {
    try {
        await getLocations();
    } catch(e) {
        addFlashMessage('ERROR', e.message);
    }
});
</script>
