import { ref } from "vue";

import axios from 'axios';

import constructFetchError from "./constructFetchError";

export function useTimePeriods() {
    const timePeriods = ref(null);
    const totalRecords = ref(null);

    const timePeriod = ref(null);

    const getTimePeriods = async (params) => {
        try {
            const getTimePeriodsResponse = await axios.get(route('api.time-periods.index', params));
            timePeriods.value = getTimePeriodsResponse.data.data;
            totalRecords.value = getTimePeriodsResponse.data.meta?.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching time periods.');
        }
    };

    const getTimePeriod = async (timePeriodId) => {
        try {
            const getTimePeriodResponse = await axios.get(route('api.time-periods.show', {
                time_period: timePeriodId
            }));
            timePeriod.value = getTimePeriodResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching time period.');
        }
    };

    const getTimePeriodsLatest = async (params) => {
        try {
            const getTimePeriodsResponse = await axios.get(route('api.time-periods.index', {
                ...params,
                latest: true,
            }));
            timePeriods.value = getTimePeriodsResponse.data.data;
            totalRecords.value = getTimePeriodsResponse.data.meta?.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching time periods.');
        }
    };

    return { timePeriods, totalRecords, getTimePeriods,
        timePeriod, getTimePeriod, getTimePeriodsLatest };
}
