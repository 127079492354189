<template>
    <Card :class="class">
        <div class="row g-3 mb-3">
            <div class="col-sm-6">
                <button type="button" class="btn btn-primary w-100" disabled>
                    Populate Absent
                </button>
            </div>
            <div class="col-sm-6">
                <button type="button" class="btn btn-primary w-100"
                    :disabled="employee === null || (employee.PAYTYPE != 'WEEKLY' &&
                        !(employee.PAYTYPE == 'BIMONTHLY' &&
                            ['ADMIN', 'ADMIN - S', 'MAINTENANCE'].includes(employee.DEPT)))"
                    data-bs-toggle="modal"
                    :data-bs-target="'#' + showHoursModalId">
                    Show Hours
                </button>
            </div>
            <div class="col-sm-6">
                <button type="button" class="btn btn-primary w-100" disabled>
                    Work Hours
                </button>
            </div>
            <div class="col-sm-6">
                <button type="button" class="btn btn-primary w-100"
                    :disabled="employee === null"
                    data-bs-toggle="modal"
                    :data-bs-target="'#' + enterEmployeeHoursDetailsModalId"
                    @click="_ => { resetCoffeeBreakFlag = !resetCoffeeBreakFlag; }">
                    Enter Hours
                </button>
            </div>
        </div>
        <button type="button" class="btn btn-success w-100" id="updateHoursButton"
            :disabled="props.employee === null || props.isUpdateDisabled"
            @click="updateHoursButtonOnClick">
            Update
        </button>
    </Card>
</template>

<script setup>
import Card from '../../../utils/Card.vue';

const props = defineProps({
    employee: Object,
    class: String,
    enterEmployeeHoursDetailsModalId: String,
    showHoursModalId: String,
    isUpdateDisabled: Boolean
});

const resetCoffeeBreakFlag = defineModel('resetCoffeeBreakFlag');

const emit = defineEmits(['update-button-click'])

function updateHoursButtonOnClick() {
    emit('update-button-click');
}
</script>
