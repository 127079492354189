<template>
    <div v-if="isOpen" class="pop-up d-flex justify-content-center align-items-center">
        <Card>
            <template #header>
                <div class="d-flex justify-content-between pt-2">
                    <h4 class="title">Select Style</h4>
                    <button type="button" class="btn-close" @click="emit('close')"></button>
                </div>
            </template>
            <StyleTable v-model="style" :preset-filters="presetFilters" />
            <template #footer>
                <div class="d-grid">
                    <button type="button" class="btn btn-primary"
                        :disabled="!style"
                        @click="_ => { emit('select', style); }">
                        Select Style
                    </button>
                </div>
            </template>
        </Card>
    </div>
</template>

<script setup>
import Card from "@/components/utils/Card.vue";

import StyleTable from "./StyleTable.vue";

const props = defineProps({
    presetFilters: Object,
    isOpen: Boolean
});

const emit = defineEmits(['select', 'error']);

const style = defineModel();
</script>

<style scoped>
.pop-up {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: rgb(0, 0, 0, 0.5);
}

.pop-up .card {
    width: 100%;
}

@media (min-width: 768px) {
    .pop-up .card {
        width: 85%;
    }
}

@media (min-width: 992px) {
    .pop-up .card {
        width: 70%;
    }
}
</style>
