<template>
    
    <Card>
        <template #header>
            Report Generations
        </template>
        <DataTable :loading="isLoading" lazy :value="accountingYearlyReportGenerations" :total-records="totalRecords"
            paginator :rows="10" @page="e => { onPage(e); fetchAccountingYearlyReportGenerations(); }"
            filter-display="row" v-model:filters="filters" @filter="tableOnFilter"
            sort-mode="multiple" @sort="e => { onSort(e); fetchAccountingYearlyReportGenerations(); }"
            v-model:selection="selectedGeneration" selection-mode="single"
            :pt="{ table: { class: 'table table-bordered table-hover' } }">
            <Column field="time_period" header="Time Period" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value"
                        @input="filterCallback"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="report_type" header="Report Type" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value"
                        @input="filterCallback"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="company_code" header="Company" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value"
                        @input="filterCallback"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="paytype" header="Paytype" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value"
                        @input="filterCallback"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="created_at" header="Created" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value"
                        @input="filterCallback"
                        class="form-control" placeholder="Search" />
                </template>
                <template #body="{ data }">
                    {{ moment(data.created_at).format('YYYY-MM-DD HH:mm') }}
                </template>
            </Column>
            <Column field="status" header="Status" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value"
                        @input="filterCallback"
                        class="form-control" placeholder="Search" />
                </template>
                <template #body="{ data }">
                    <span v-if="data.status == 'PENDING'" class="badge bg-secondary">
                        PENDING
                    </span>
                    <span v-else-if="data.status == 'RUNNING'" class="badge bg-primary">
                        RUNNING
                    </span>
                    <span v-else-if="data.status == 'DONE'" class="badge bg-success">
                        DONE
                    </span>
                    <span v-else-if="data.status == 'FAILED'" class="badge bg-danger">
                        FAILED
                    </span>
                </template>
            </Column>
            <template #empty>
                <div class="text-center">
                    No data.
                </div>
            </template>
        </DataTable>
        <template #footer>
            <div class="text-end">
                <button type="button" class="btn btn-primary me-1"
                    @click="fetchAccountingYearlyReportGenerations">
                    Refresh
                </button>
                <button type="button" class="btn btn-primary"
                    :disabled="selectedGeneration?.status != 'DONE'"
                    @click="downloadReport">
                    Download
                </button>
            </div>
        </template>
    </Card>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';

import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useAccountingYearlyReportGenerations } from '@/composables/data/accountingYearlyReportGenerations';

import Card from '@/components/utils/Card.vue';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

import moment from 'moment';

const props = defineProps({
    refreshFlag: null
});

const emit = defineEmits(['error']);

const { params, filters, onPage, onFilter, onSort } = useDataTableParams([
    'time_period', 'report_type', 'company_code', 'paytype', 'created_at', 'status'
]);

const { accountingYearlyReportGenerations, totalRecords, getAccountingYearlyReportGenerations } =
    useAccountingYearlyReportGenerations();

const selectedGeneration = ref(null);

const isLoading = ref(false);

const fetchTimeout = ref(null);

async function fetchAccountingYearlyReportGenerations() {
    accountingYearlyReportGenerations.value = null;

    isLoading.value = true;
    try {
        await getAccountingYearlyReportGenerations(params.value);
    } catch(e) {
        emit('error', e.message);
    }
    isLoading.value = false;
}

function tableOnFilter(e) {
    onFilter(e);
    if(fetchTimeout.value)
        clearTimeout(fetchTimeout.value);
    fetchTimeout.value = setTimeout(fetchAccountingYearlyReportGenerations, 300);
}

onMounted(fetchAccountingYearlyReportGenerations);
watch(() => props.refreshFlag, fetchAccountingYearlyReportGenerations);

function downloadReport() {
    window.open(route('api.accounting-yearly-report-generations.report.show', {
        report_generation: selectedGeneration.value.id
    }));
}
</script>
