<template>
    <div class="row g-3 mb-3">
        <div class="col-md-4">
            <Card>
                <template #header>
                    Select Time Period
                </template>
                <TimePeriodTable v-model="selectedTimePeriod"
                    show-from-current-date
                    paytype="MONTHLY"
                    :hide-properties="new Set(['PAYTYPE', 'STATUS'])"
                    :sort="[{ field: 'TIME_PERIOD', order: -1 }]" />
            </Card>
        </div>
        <div class="col-md-8">
            <form @submit.prevent="uploadRemittanceAdvice" class="mb-3">
                <Card>
                    <template #header>
                        Upload Remittance Advice Files
                    </template>
                    <div class="mb-3">
                        <label for="siFile">SI File</label>
                        <input type="file" name="siFile" id="siFile" ref="siFileInput" class="form-control"
                            @change="e => { siFile = e.target.files[0]; }" />
                    </div>
                    <div class="mb-3">
                        <label for="dmFile">DM File</label>
                        <input type="file" name="dmFile" id="dmFile" ref="dmFileInput" class="form-control"
                            @change="e => { dmFile = e.target.files[0]; }" />
                    </div>
                    <div class="mb-3">
                        <label for="mainFile">Main File</label>
                        <input type="file" name="mainFile" id="mainFile" ref="mainFileInput" class="form-control"
                            @change="e => { mainFile = e.target.files[0]; }" />
                    </div>
                    <template #footer>
                        <div class="text-end">
                            <button type="submit" class="btn btn-primary"
                                :disabled="!selectedTimePeriod || isUploading">
                                <span v-if="isUploading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Upload
                            </button>
                        </div>
                    </template>
                </Card>
            </form>
            <Card>
                <template #header>
                    Upload Messages
                </template>
                <DataTable :value="uploadMessages"
                    paginator :rows="10"
                    :pt="{ table: { class: 'table table-bordered table-hover' }}">
                    <Column field="type" header="Type">
                        <template #body="{ data }">
                            <span v-if="data.type == 'ERROR'" class="badge bg-danger">
                                Error
                            </span>
                            <span v-else-if="data.type == 'WARNING'" class="badge bg-warning">
                                Warning
                            </span>
                        </template>
                    </Column>
                    <Column field="message" header="Message" />
                    <Column field="file_type" header="File Type" />
                    <template #empty>
                        <div class="text-center">
                            No messages.
                        </div>
                    </template>
                </DataTable>
            </Card>
        </div>
    </div>
    <VouchersTableCard :time-period="selectedTimePeriod?.TIME_PERIOD"
        @populate-success="message => {
            addFlashMessage('SUCCESS', message);
            scrollToTop();
        }"
        @error="message => {
            addFlashMessage('ERROR', message);
            scrollToTop();
        }"
        class="mb-3" />
    <RemittanceAdviceUploadedFileStatusesTableCard :refresh-flag="uploadedFilesTableRefreshFlag" />
</template>

<script setup>
import { ref } from 'vue';

import { useFlashMessages } from '@/composables/flashmessages';
import { useRemittanceAdvice } from '@/composables/data/remittanceAdvice';

import Card from '@/components/utils/Card.vue';
import TimePeriodTable from '@/components/utils/tables/TimePeriodTable.vue';

import VouchersTableCard from './VouchersTableCard.vue';
import RemittanceAdviceUploadedFileStatusesTableCard from './RemittanceAdviceUploadedFileStatusesTableCard.vue';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';

import scrollToTop from '@/utils/scrollToTop';

const { addFlashMessage } = useFlashMessages();

const selectedTimePeriod = ref(null);

const siFileInput = ref();
const dmFileInput = ref();
const mainFileInput = ref();

const siFile = ref(null);
const dmFile = ref(null);
const mainFile = ref(null);

const { postRemittanceAdvice } = useRemittanceAdvice();

const isUploading = ref(false);

const uploadMessages = ref(null);

const uploadedFilesTableRefreshFlag = ref(false);

async function uploadRemittanceAdvice() {
    uploadMessages.value = null;
    isUploading.value = true;
    try {
        const formData = new FormData();
        formData.append("file_si", siFile.value);
        formData.append("file_dm", dmFile.value);
        formData.append("file_main", mainFile.value);
        const response = await postRemittanceAdvice(selectedTimePeriod.value.TIME_PERIOD, formData);
        addFlashMessage('SUCCESS', response.data.message);
        if (response.data.locations_warnings?.length > 0)
            uploadMessages.value = response.data.locations_warnings.map(location_warning => ({
                type: 'WARNING',
                file_type: 'N/A',
                message: `Discrepancy on location ${location_warning.LOCATION} - ` +
                    `uploaded remittance (${location_warning.FROM_REMITTANCE.toFixed(2)}) ` +
                    `vs. from sales summary (${location_warning.FROM_SALES_ACTUAL_SUMMARY.toFixed(2)})`
            }));
        if (response.data.other_warnings?.length > 0)
            uploadMessages.value = response.data.other_warnings.map(warning => ({
                type: 'WARNING',
                ...warning
            }));
    } catch(e) {
        addFlashMessage('ERROR', e.message);
        if (e.status == 422) {
            if(Array.isArray(e.errors)) {
                uploadMessages.value = e.errors.map(error => ({
                    type: 'ERROR',
                    ...error
                }));
            } else {
                uploadMessages.value = [];
                if(e.errors.file_si)
                    uploadMessages.value = [...uploadMessages.value,
                        ...e.errors.file_si.map(error => ({ type: 'ERROR', message: error, file_type: 'SI File'}))];
                if(e.errors.file_dm)
                    uploadMessages.value = [...uploadMessages.value,
                        ...e.errors.file_dm.map(error => ({ type: 'ERROR', message: error, file_type: 'DM File'}))];
                if(e.errors.file_main)
                    uploadMessages.value = [...uploadMessages.value,
                        ...e.errors.file_main.map(error => ({ type: 'ERROR', message: error, file_type: 'Main File'}))];
            }
        }
    }
    scrollToTop();
    isUploading.value = false;
    uploadedFilesTableRefreshFlag.value = !uploadedFilesTableRefreshFlag.value;
}
</script>
