<template>
    <form @submit.prevent>
        <Modal id="sampleCostingFormModal" class="modal-xl">
            <template #header>
                <h4 class="modal-title">Sample Costing / Insert Approval</h4>
            </template>
            <div class="row">
                <div class="col-md-6 border-end">
                    <h5 class="mb-3">Sample Costing</h5>
                    <form @submit.prevent="updateSampleCost">
                        <div class="row justify-content-end mb-3">
                            <div class="col-md-6">
                                <FormInput type="number" v-model="sampleQuantity"
                                    label="Quantity" id-prefix="sampleQuantity" />
                            </div>
                        </div>
                        <div class="row g-3 mb-3">
                            <div class="col-md-6">
                                <FormInput type="number" step=".01" v-model="actualCost"
                                    label="Actual Cost" id-prefix="actualCost"
                                    disabled />
                            </div>
                            <div class="col-md-6">
                                <FormInput type="number" step=".01" v-model="quantityActualCost"
                                    label="Qty. Actual Cost" id-prefix="quantityActualCost"
                                    disabled />
                            </div>
                        </div>
                        <div class="row g-3 mb-3">
                            <div class="col-md-6">
                                <FormInput type="number" step=".01" v-model="sampleCost.COST_MATERIAL"
                                    label="Material Cost" id-prefix="materialCost"
                                    :errors="sampleCostErrors.COST_MATERIAL" />
                            </div>
                            <div class="col-md-6">
                                <FormInput type="number" step=".01" v-model="quantityMaterialCost"
                                    label="Qty. Material Cost" id-prefix="quantityMaterialCost"
                                    disabled />
                            </div>
                        </div>
                        <div class="row g-3 mb-3">
                            <div class="col-md-6">
                                <FormInput type="number" step=".01" v-model="sampleCost.COST_CUTTING"
                                    label="Cutting Cost" id-prefix="cuttingCost"
                                    :errors="sampleCostErrors.COST_CUTTING" />
                            </div>
                            <div class="col-md-6">
                                <FormInput type="number" step=".01" v-model="quantityCuttingCost"
                                    label="Qty. Cutting Cost" id-prefix="quantityCuttingCost"
                                    disabled />
                            </div>
                        </div>
                        <div class="row g-3 mb-3">
                            <div class="col-md-6">
                                <FormInput type="number" step=".01" v-model="sampleCost.COST_ASSEMBLY"
                                    label="Assembly Cost" id-prefix="assemblyCost"
                                    :errors="sampleCostErrors.COST_ASSEMBLY" />
                            </div>
                            <div class="col-md-6">
                                <FormInput type="number" step=".01" v-model="quantityAssemblyCost"
                                    label="Qty. Assembly Cost" id-prefix="quantityAssemblyCost"
                                    disabled />
                            </div>
                        </div>
                        <div class="row g-3 mb-5">
                            <div class="col-md-6">
                                <FormInput type="number" step=".01" v-model="sampleCost.COST_OTHER"
                                    label="Other Cost" id-prefix="otherCost"
                                    :errors="sampleCostErrors.COST_OTHER" />
                            </div>
                            <div class="col-md-6">
                                <FormInput type="number" step=".01" v-model="quantityOtherCost"
                                    label="Qty. Other Cost" id-prefix="quantityOtherCost"
                                    disabled />
                            </div>
                        </div>
                        <div class="row g-3 mb-3">
                            <div class="col-md-6">
                                <FormInput type="number" step=".01" v-model="subtotal"
                                    label="Subtotal" id-prefix="subtotal"
                                    disabled />
                            </div>
                            <div class="col-md-6">
                                <FormInput type="number" step=".01" v-model="quantitySubtotal"
                                    label="Qty. Subtotal" id-prefix="quantitySubtotal"
                                    disabled />
                            </div>
                        </div>
                        <div class="row g-3 mb-3">
                            <div class="col-md-6">
                                <FormInput type="number" step=".01" v-model="sampleCost.MSRP"
                                    label="MSRP" id-prefix="msrp"
                                    :errors="sampleCostErrors.MSRP" />
                            </div>
                            <div class="col-md-6">
                                <FormInput type="number" step=".01" v-model="quantityMSRP"
                                    label="Qty. MSRP" id-prefix="quantityMSRP"
                                    disabled />
                            </div>
                        </div>
                        <div class="row justify-content-end mb-3">
                            <div class="col-md-6">
                                <FormInput type="number" step=".01" v-model="sampleCost.FACTOR"
                                    label="Factor" id-prefix="factor"
                                    :errors="sampleCostErrors.FACTOR" />
                            </div>
                        </div>
                        <div class="row g-3 mb-3">
                            <div class="col-md-6">
                                <FormInput type="number" step=".01" v-model="factorTotal"
                                    label="Factor Total" id-prefix="factorTotal"
                                    disabled />
                            </div>
                            <div class="col-md-6">
                                <FormInput type="number" step=".01" v-model="quantityFactorTotal"
                                    label="Qty. Factor Total" id-prefix="quantityFactorTotal"
                                    disabled />
                            </div>
                        </div>
                        <FlashMessageAlerts :messages="sampleCostFlashMessages" :dismissible="true"
                            class="mb-3"/>
                        <div class="text-end">
                            <button type="submit" class="btn btn-primary">
                                Save
                            </button>
                        </div>
                    </form>
                </div>
                <div class="col-md-6">
                    <h5 class="mb-3">Insert Approval</h5>
                    <form @submit.prevent="saveSampleApproval">
                        <FormInput type="text" v-model="sampleId"
                            label="Sample ID" id-prefix="sampleId"
                            class="mb-3"
                            disabled />
                        <FormInput type="text" v-model="sampleApproval.CLIENT"
                            label="Client" id-prefix="client"
                            class="mb-3"
                            :errors="insertApprovalErrors.CLIENT" />
                        <FormInput type="number" v-model="sampleApproval.QTY"
                            label="Quantity" id-prefix="quantity"
                            class="mb-3"
                            :errors="insertApprovalErrors.QTY" />
                        <FormInput type="number" step=".01" v-model="sampleApproval.UNIT_PRICE"
                            label="Unit Price" id-prefix="unitPrice"
                            class="mb-3"
                            :errors="insertApprovalErrors.UNIT_PRICE" />
                        <FlashMessageAlerts :messages="insertApprovalFlashMessages" :dismissible="true"
                            class="mb-3"/>
                        <div class="text-end">
                            <button type="submit" class="btn btn-primary">
                                Save
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    </form>
</template>

<script setup>
import { computed, ref, watch } from 'vue';

// Local flash messages inside modal
import FlashMessageAlerts from '@/components/utils/FlashMessageAlerts.vue';

import { useSampleApprovals } from '@/composables/data/sampleApprovals';
import { useSampleCost } from '@/composables/data/sampleCost';
import { useSampleDetails } from '@/composables/data/sampleDetails';

import FormInput from '@/components/utils/FormInput';
import Modal from '@/components/utils/Modal';

const props = defineProps({
    sampleId: Number
});

const sampleCostFlashMessages = ref([]);
const insertApprovalFlashMessages = ref([]);

const sampleId = ref(null);
const sampleQuantity = ref(100);

const { sampleCost, getSampleCost, putSampleCost } = useSampleCost();
const { sampleDetails, getSampleDetails } = useSampleDetails();

const { postSampleApproval } = useSampleApprovals();

const sampleCostErrors = ref({});

const sampleApproval = ref({
    CLIENT: null,
    QUANTITY: null,
    UNIT_PRICE: null,
});

const insertApprovalErrors = ref({});

const actualCost = computed(() =>
    (sampleDetails.value?.filter(sampleDetail => sampleDetail.material != null)
        .reduce((totalCost, sampleDetail) =>
            totalCost + (sampleDetail.QTY * sampleDetail.material.PRICE), 0) ?? 0)
);
const quantityActualCost = computed(() =>
    (actualCost.value * (sampleQuantity.value ?? 0)));
const quantityMaterialCost = computed(() =>
    ((sampleCost.value.COST_MATERIAL ?? 0) * (sampleQuantity.value ?? 0)));
const quantityCuttingCost = computed(() =>
    ((sampleCost.value.COST_CUTTING ?? 0) * (sampleQuantity.value ?? 0)));
const quantityAssemblyCost = computed(() =>
    ((sampleCost.value.COST_ASSEMBLY ?? 0) * (sampleQuantity.value ?? 0)));
const quantityOtherCost = computed(() =>
    ((sampleCost.value.COST_OTHER ?? 0) * (sampleQuantity.value ?? 0)));
const subtotal = computed(() =>
    ((sampleCost.value.COST_MATERIAL ?? 0) + (sampleCost.value.COST_CUTTING ?? 0) +
        (sampleCost.value.COST_ASSEMBLY ?? 0) + (sampleCost.value.COST_OTHER ?? 0)));
const quantitySubtotal = computed(() =>
    (subtotal.value * (sampleQuantity.value ?? 0)));
const quantityMSRP = computed(() =>
    ((sampleCost.value.MSRP ?? 0) * (sampleQuantity.value ?? 0)));
const factorTotal = computed(() =>
    (subtotal.value * (sampleCost.value.FACTOR ?? 0)));
const quantityFactorTotal = computed(() =>
    (factorTotal.value *  (sampleQuantity.value ?? 0)));

watch(() => props.sampleId, async() => {
    sampleId.value = props.sampleId;
    sampleDetails.value = null;

    if(!props.sampleId)
        return;

    try {
        await getSampleDetails(props.sampleId, {
            include_relations: 'material'
        });
    } catch(e) {
        addFlashMessage('ERROR', e.message);
    }

    try {
        await getSampleCost(props.sampleId);
    } catch(e) {
        if(e.status == 404) {
            sampleCost.value = {
                COST_MATERIAL: null,
                COST_CUTTING: null,
                COST_ASSEMBLY: null,
                COST_OTHER: null,
                MSRP: null,
                FACTOR: null,
            };
            return;
        }

        addFlashMessage('ERROR', e.message);
    }
});

async function updateSampleCost() {
    sampleCostErrors.value = {};
    try {
        await putSampleCost(props.sampleId, sampleCost.value);
        sampleCostFlashMessages.value.push({ type: 'SUCCESS', content: 'Successfully saved costs.' });
    } catch(e) {
        sampleCostFlashMessages.value.push({ type: 'ERROR', content: e.message });
        sampleCostErrors.value = e.errors ?? {};
    }
}

async function saveSampleApproval() {
    insertApprovalErrors.value = {};
    try {
        await postSampleApproval(props.sampleId, sampleApproval.value);
        insertApprovalFlashMessages.value.push({ type: 'SUCCESS', content: 'Successfully saved sample approval request.' });
        sampleApproval.value = {
            CLIENT: null,
            QUANTITY: null,
            UNIT_PRICE: null,
        };
    } catch(e) {
        insertApprovalFlashMessages.value.push({ type: 'ERROR', content: e.message });
        insertApprovalErrors.value = e.errors ?? {};
    }
}
</script>
