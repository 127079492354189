<template>
    <div>
        <div v-if="isLoading" class="position-fixed bottom-0 end-0 top-0 start-0 d-flex justify-content-center align-items-center p-3" style="z-index: 11; background: rgb(0, 0, 0, 0.5)">
            <div class="spinner-border text-primary" role="status"></div>
        </div>
        <div v-if="isToastSuccess" class="position-fixed bottom-0 end-0 top-0 start-0 d-flex justify-content-center align-items-center p-3" style="z-index: 11; background: rgb(0, 0, 0, 0.5)">
            <div class="toast show bg-success" role="alert" aria-live="assertive" aria-atomic="true" style="width: 40%;">
                <div class="toast-header">
                    <h6 class="me-auto text-black m-0">Success!</h6>
                    <button @click="hideToast()" type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
                    <div class="toast-body text-white p-4 fs-1.4">
                    {{ toastMessage }}
                    </div>
            </div>
        </div>
        <div v-if="isToastError" class="position-fixed bottom-0 end-0 top-0 start-0 d-flex justify-content-center align-items-center p-3" style="z-index: 11; background: rgb(0, 0, 0, 0.5)">
            <div class="toast show bg-danger" role="alert" aria-live="assertive" aria-atomic="true" style="width: 40%;">
                <div class="toast-header">
                    <h6 class="me-auto text-black m-0">Error!</h6>
                    <button @click="hideToast()" type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
                    <div class="toast-body text-white p-4 fs-1.4">
                    {{ toastMessage }}
                    </div>
            </div>
        </div>
        <div
            class="card p-4 glbalances-main-card"
        >
            <div class="d-flex flex-column align-items-center">
                <div class="d-flex flex-row w-100 mb-2">
                    <section class="w-50 me-2">
                        <div class="d-flex mb-1">
                            <label class="form-label w-25 my-auto">COMPANY:</label>
                            <select
                                class="form-select w-20"
                                v-model="companyCode"
                            >
                                <option v-for="company in companies" :value="company.COMPANY_CODE">{{ company.COMPANY_DESCRIPTION }}</option>
                            </select>
                        </div>
                        <div class="d-flex mb-1">
                            <select
                                class="form-select w-25"
                                v-model="className"
                                @change="getAcctName()"
                            >
                                <option v-for="item in classData" :value="item">{{ item }}</option>
                            </select>
                            <select
                                class="form-select w-50 ms-1"
                                v-model="acctName"
                                @change="acctNameAfterUpdate()"
                            >
                                <option v-for="item in acctNameData" :value="item.ACCTNAME">{{ item.ACCTNAME }}</option>
                            </select>
                            <input
                                type="text"
                                class="form-control w-25 ms-1"
                                v-model="glId"
                                disabled
                            >
                        </div>
                        <div class="d-flex mb-1">
                            <div class="w-75">
                                <FormInput type="date" label="START DATE:"
                                    v-model="startDate"
                                    is-horizontal id-prefix="startDate" class="w-100 mb-1"
                                    input-class="form-control w-100"
                                    :errors="errors.START_DATE"/>
                                <FormInput type="date" label="END DATE:"
                                    v-model="endDate"
                                    is-horizontal id-prefix="endDate" class="w-100"
                                    input-class="form-control w-100"
                                    :errors="errors.END_DATE"/>
                            </div>
                            <div class="d-flex w-25 ms-1">
                                <button
                                    class="btn btn-primary w-75 my-auto"
                                    @click="getGLBalances()"
                                >
                                SEARCH
                                </button>
                            </div>
                        </div>
                        <FormInput type="text" label="CUR BAL:"
                            v-model="glEBal" disabled
                            is-horizontal id-prefix="glEBal" class="w-75 mb-1"
                            input-class="form-control w-100"/>
                        <div class="d-flex mb-1">
                            <label class="form-label my-auto" style="width: 20%">DEBIT/CREDIT</label>
                            <select
                                class="form-select w-50 ms-1"
                                v-model="debitCredit"
                                @change="debitCreditAfterUpdate()"
                            >
                                <option value="DEBIT+CREDIT">DEBIT+CREDIT</option>
                                <option value="DEBIT only">DEBIT only</option>
                                <option value="CREDIT only">CREDIT only</option>
                            </select>
                        </div>
                    </section>
                    <section class="w-50">
                        <div class="d-flex flex-row w-100 h-50">
                            <div class="d-flex flex-column mb-1 w-50">
                                <button
                                    class="btn btn-primary w-100 mb-1"
                                    data-bs-toggle="modal"
                                    data-bs-target="#printSummaryClearedModal"
                                >
                                    REPORT<br>(summary) -cleared
                                </button>
                                <button
                                    class="btn btn-primary w-100"
                                    data-bs-toggle="modal"
                                    data-bs-target="#printSummaryAllModal"
                                >
                                    REPORT<br>(summary) -all
                                </button>
                            </div>
                            <div class="d-flex flex-column w-50">
                                <button
                                    class="btn btn-primary w-100 ms-1 mb-1"
                                    data-bs-toggle="modal"
                                    data-bs-target="#printReportPayeeModal"
                                >
                                    REPORT<br>(by PAYEE) -both
                                </button>
                                <button
                                    class="btn btn-primary w-100 ms-1"
                                    data-bs-toggle="modal"
                                    data-bs-target="#printReportJonoModal"
                                >
                                    REPORT<br>(by JONO) -both
                                </button>
                            </div>
                        </div>
                        <div class="d-flex align-items-end h-25">
                            <p class="text-bold">{{ payName }}</p>
                        </div>
                        <div class="d-flex h-25 align-items-center">
                            <div class="dropdownContainer w-50 mb-1 ms-1" ref="dropdownContainer" style="position: relative;">
                                <button @click="isListVisible = !isListVisible" class="btn btn-primary w-75 d-flex justify-content-between" style="background-color: #F9FAFB; border: 1px solid #CED4DA; color: black">
                                    <span>{{ payeeTypeText }}</span>
                                    <span><i class="fa-solid fa-angle-down"></i></span>
                                </button>
                                <ul v-if="isListVisible" class="list-group w-75" style="position: absolute; top: 100%; left: 0; z-index: 1;">
                                    <li @click="searchPayee('I')" class="dropdown list-group-item list-group-item-action">INTERNAL</li>
                                    <li @click="searchPayee('E')" class="dropdown list-group-item list-group-item-action">EXTERNAL</li>
                                </ul>
                            </div>
                            <button
                                class="btn btn-primary w-50 ms-1"
                                @click="reportByPayeeJono()"
                            >
                                REPORT<br>(by PAYEE and JONO) -both
                            </button>
                            <button
                                id="btnInternal"
                                data-bs-toggle="modal"
                                data-bs-target="#printReportPayeeJonoInternalModal"
                                hidden
                            ></button>
                            <button
                                id="btnExternal"
                                data-bs-toggle="modal"
                                data-bs-target="#printReportPayeeJonoExternalModal"
                                hidden
                            ></button>
                        </div>
                    </section>
                </div>
                <div class="w-100">
                    <section class="w-100">
                        <div class="gl-table">
                            <table
                                class="table table-sm table-bordered table-striped table-hover"
                            >
                                <thead>
                                    <tr>
                                        <th scope="col">VOUCHER_ID</th>
                                        <th scope="col">DESCRIPTION</th>
                                        <th scope="col">VOUCHER_DATE</th>
                                        <th scope="col">AMT</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="balance in glBalances"
                                        :class="{ 'selected-row': balance.VOUCHER_ID === selectedVoucherId && balance.NAME === selectedDescription && balance.VOUCHER_DATE === selectedDate && balance.GL_AMT === selectedAmt }"
                                        @click="selectVoucher(balance.VOUCHER_ID, balance.NAME, balance.VOUCHER_DATE, balance.GL_AMT)"
                                        @dblclick="navigateToRoute('update-voucher', balance.VOUCHER_ID)"
                                    >
                                        <td>{{ balance.VOUCHER_ID }}</td>
                                        <td>({{ balance.NAME }}) {{ balance.DESCRIPTION }}</td>
                                        <td>{{ balance.VOUCHER_DATE }}</td>
                                        <td>{{ balance.GL_AMT }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <SelectAccount
            :isOpen="isSelectAccountOpen"
            :onClose="() => closeSelectAccount()"
            :onSelectAccount="handleSelectAccount"
        />
        <SelectEmployee
            :isOpen="isSelectEmployeeOpen"
            :onClose="() => closeSelectEmployee()"
            :onSelectEmployee="handleSelectEmployee"
        />
        <PrintModal
            :pdf-link="printSummaryClearedPDFLink"
            :spreadsheet-link="printSummaryClearedSpreadsheetLink"
            title="Print Summary Cleared" id="printSummaryClearedModal"
        />
        <PrintModal
            :pdf-link="printSummaryAllPDFLink"
            :spreadsheet-link="printSummaryAllSpreadsheetLink"
            title="Print Summary All" id="printSummaryAllModal"
        />
        <PrintModal
            :pdf-link="printReportPayeePDFLink"
            :spreadsheet-link="printReportPayeeSpreadsheetLink"
            title="Print Report by Payee" id="printReportPayeeModal"
        />
        <PrintModal
            :pdf-link="printReportJonoPDFLink"
            :spreadsheet-link="printReportJonoSpreadsheetLink"
            title="Print Report by Jono" id="printReportJonoModal"
        />
        <PrintModal
            :pdf-link="printReportPayeeJonoInternalPDFLink"
            :spreadsheet-link="printReportPayeeJonoInternalSpreadsheetLink"
            title="Print Report by Payee and Jono (Internal)" id="printReportPayeeJonoInternalModal"
        />
        <PrintModal
            :pdf-link="printReportPayeeJonoExternalPDFLink"
            :spreadsheet-link="printReportPayeeJonoExternalSpreadsheetLink"
            title="Print Report by Payee and Jono (External)" id="printReportPayeeJonoExternalModal"
        />
    </div>
</template>

<script setup>
    import { ref, onMounted, computed, onBeforeUnmount } from 'vue';

    import SelectAccount from '../../utils/SelectAccount.vue';
    import SelectEmployee from '../../utils/SelectEmployee.vue';
    import PrintModal from '../../utils/PrintModal.vue';
    import FormInput from '@/components/utils/FormInput.vue';

    const className = ref('ASSET');
    const acctName = ref('COH');
    const glId = ref('9');
    const companyCode = ref('K');
    const startDate = ref('');
    const endDate= ref('');
    const glEBal = ref('');
    const debitCredit = ref('DEBIT+CREDIT');
    const debitCreditClause = ref('');
    const payeeType = ref('');
    const payeeTypeText = ref('');
    const isListVisible = ref(false);
    const payName = ref('');
    const payId = ref('');
    const classData = ref([]);
    const acctNameData = ref([]);
    const companies = ref([]);
    const glBalances = ref([]);
    const selectedVoucherId = ref('');
    const selectedDate = ref('');
    const selectedDescription = ref('');
    const selectedAmt = ref(0);
    const isLoading = ref(false);
    const isToastSuccess = ref(false);
    const isToastError = ref(false);
    const toastMessage = ref('');
    const isSelectAccountOpen = ref(false);
    const isSelectEmployeeOpen = ref(false);

    const errors = ref({});

    const closeList = (event) => {
      const dropdownContainer = document.querySelector('.dropdownContainer'); // Change this selector accordingly
      if (dropdownContainer && !dropdownContainer.contains(event.target)) {
        isListVisible.value = false;
      }
    };

    onMounted(() => {
      document.addEventListener('click', closeList);
    });

    onBeforeUnmount(() => {
      document.removeEventListener('click', closeList);
    });


    onMounted(() => {
        getClass();
        getAcctName();
        getCompany();
        setInitialDates();
        getGLBalances();
        getGLBalanceAmount();
    })

    function setInitialDates() {
        // Initial values for startDate and endDate
        const today = new Date();
        endDate.value = formatDate(today);
        startDate.value = formatDate(getDateDaysAgo(today, 10));
    }

    async function getCompany() {
        try {
            const response = await axios.get(route('api.companies.index'));
            companies.value = response.data.data;
        } catch (error) {
            isToastError.value = true;
            toastMessage.value = error.response.data.message;
        }
    }

    async function getClass() {
        try {
            const response = await axios.post(route('api.gl.filter'), {
                class: true
            });
            classData.value = response.data[0];
        } catch (error) {
            isToastError.value = true;
            toastMessage.value = error.response.data.message;
        }
    }

    async function getAcctName() {
        try {
            const response = await axios.post(route('api.gl.filter'), {
                search: className.value !== "" ? className.value : 'empty',
            });
            acctNameData.value = response.data[0];
        } catch (error) {
            isToastError.value = true;
            toastMessage.value = error.response.data.message;
        }
    }

    async function getGLBalances() {
        isLoading.value = true;
        try {
            const response = await axios.post(route('api.voucher.balance'), {
                START_DATE: startDate.value,
                END_DATE: endDate.value,
                COMPANY_CODE: companyCode.value,
                GL_ID: glId.value,
            });
            glBalances.value = response.data;
            isLoading.value = false;
        } catch (e) {
            errors.value = e.response.data.errors;
            isLoading.value = false;
        }
    }

    async function getGLBalanceAmount()
    {
        try {
            const response = await axios.post(route('api.vouchergl.balance'), {
                COMPANY_CODE: companyCode.value,
                GL_ID: glId.value,
            });
            glEBal.value = parseFloat(response.data).toFixed(2);
        } catch (error) {
            isToastError.value = true;
            toastMessage.value = error.response.data.message;
        }
    }

    function acctNameAfterUpdate(){
        getGlId();
        getGLBalances();
        getGLBalanceAmount();
    }

    function getGlId() {
        const foundGLAccount = acctNameData.value.find(gl => gl.ACCTNAME === acctName.value);
        glId.value = foundGLAccount.GL_ID;
    }

    function formatDate(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
    }

    function getDateDaysAgo(date, days) {
        const result = new Date(date);
        result.setDate(date.getDate() - days);
        return result;
    }

    function hideToast() {
        isToastError.value = false;
        isToastSuccess.value = false;
    }

    //functions for select External
    function openSelectAccount() {
        isSelectAccountOpen.value = true;
    }

    function closeSelectAccount() {
        isSelectAccountOpen.value = false;
    }

    function handleSelectAccount(selectedAccount) {
        payeeType.value = 'E';
        payeeTypeText.value = 'EXTERNAL';
        payId.value = selectedAccount.ACCOUNT_ID;
        payName.value = selectedAccount.ACCOUNT_NAME;
    }

    //functions for select Internal
    function openSelectEmployee() {
        isSelectEmployeeOpen.value = true;
    }

    function closeSelectEmployee() {
        isSelectEmployeeOpen.value = false;
    }

    function handleSelectEmployee(selectedEmployee) {
        payeeType.value = 'I';
        payeeTypeText.value = 'INTERNAL';
        payId.value = selectedEmployee.EMPID;
        payName.value = selectedEmployee.FNAME + " " + selectedEmployee.LNAME
    };

    function searchPayee(type) {
        isListVisible.value = false;
        switch (type) {
            case 'E':
                openSelectAccount();
                break;
            case 'I':
                openSelectEmployee();
                break;
            case '':
                isToastError.value = true;
                toastMessage.value = 'Please select a payee type!'
            default:
                break;
        }
    }

    function debitCreditAfterUpdate() {
        switch (debitCredit.value) {
            case 'DEBIT+CREDIT':
                debitCreditClause.value = "";
                break;
            case 'DEBIT only':
                debitCreditClause.value = " GL_AMT > 0";
                break;
            case 'CREDIT only':
                debitCreditClause.value = " GL_AMT < 0";
                break;
            default:
                break;
        }
    }

    const printSummaryAllPDFLink = computed(() => {
        return route('print.finVoucherAll', {
            START_DATE: startDate.value,
            END_DATE: endDate.value,
            COMPANY_CODE: companyCode.value,
            GL_ID: glId.value,
            DEBIT_CREDIT_CLAUSE: debitCreditClause.value
        });
    });

    const printSummaryAllSpreadsheetLink = computed(() => {
        return route('api.vouchers.summary-spreadsheet', {
            START_DATE: startDate.value,
            END_DATE: endDate.value,
            COMPANY_CODE: companyCode.value,
            GL_ID: glId.value,
            DEBIT_CREDIT_CLAUSE: debitCreditClause.value
        });
    });

    const printSummaryClearedPDFLink = computed(() => {
        return route('print.finVoucherAll', {
            START_DATE: startDate.value,
            END_DATE: endDate.value,
            COMPANY_CODE: companyCode.value,
            GL_ID: glId.value,
            DEBIT_CREDIT_CLAUSE: debitCreditClause.value,
            CLEARED: true,
        });
    });

    const printSummaryClearedSpreadsheetLink = computed(() => {
        return route('api.vouchers.summary-spreadsheet', {
            START_DATE: startDate.value,
            END_DATE: endDate.value,
            COMPANY_CODE: companyCode.value,
            GL_ID: glId.value,
            DEBIT_CREDIT_CLAUSE: debitCreditClause.value,
            CLEARED: true,
        });
    });

    const printReportPayeePDFLink = computed(() => {
        return route('print.finVoucherAll2', {
            START_DATE: startDate.value,
            END_DATE: endDate.value,
            COMPANY_CODE: companyCode.value,
            GL_ID: glId.value,
            DEBIT_CREDIT_CLAUSE: debitCreditClause.value,
        });
    });

    const printReportPayeeSpreadsheetLink = computed(() => {
        return route('api.vouchers.payee-spreadsheet', {
            START_DATE: startDate.value,
            END_DATE: endDate.value,
            COMPANY_CODE: companyCode.value,
            GL_ID: glId.value,
            DEBIT_CREDIT_CLAUSE: debitCreditClause.value,
        });
    });

    const printReportJonoPDFLink = computed(() => {
        return route('print.finVoucherAll3', {
            START_DATE: startDate.value,
            END_DATE: endDate.value,
            COMPANY_CODE: companyCode.value,
            GL_ID: glId.value,
            DEBIT_CREDIT_CLAUSE: debitCreditClause.value,
            JONO: true,
        });
    });

    const printReportJonoSpreadsheetLink = computed(() => {
        return route('api.vouchers.jono-spreadsheet', {
            START_DATE: startDate.value,
            END_DATE: endDate.value,
            COMPANY_CODE: companyCode.value,
            GL_ID: glId.value,
            DEBIT_CREDIT_CLAUSE: debitCreditClause.value,
            JONO: true,
        });
    });

    function reportByPayeeJono() {
        if (payeeType.value === 'E') {
            if (payId.value === '') {
                isToastError.value = true;
                toastMessage.value = "Please select an external payee!";
            } else {
                const btnExternal = document.getElementById('btnExternal');
                if (btnExternal) {
                    btnExternal.click();
                }
            }
        } else {
            const btnInternal = document.getElementById('btnInternal');
            if (btnInternal) {
                btnInternal.click();
            }
        }
    }

    const printReportPayeeJonoExternalPDFLink = computed(() => {
        return route('print.finVoucherAll4', {
            START_DATE: startDate.value,
            END_DATE: endDate.value,
            COMPANY_CODE: companyCode.value,
            GL_ID: glId.value,
            DEBIT_CREDIT_CLAUSE: debitCreditClause.value,
            PAYEE_ID: payId.value,
        });
    });

    const printReportPayeeJonoExternalSpreadsheetLink = computed(() => {
        return route('api.vouchers.external-spreadsheet', {
            START_DATE: startDate.value,
            END_DATE: endDate.value,
            COMPANY_CODE: companyCode.value,
            GL_ID: glId.value,
            DEBIT_CREDIT_CLAUSE: debitCreditClause.value,
            PAYEE_ID: payId.value,
        });
    });

    const printReportPayeeJonoInternalPDFLink = computed(() => {
        return route('print.finVoucherAll3', {
            START_DATE: startDate.value,
            END_DATE: endDate.value,
            COMPANY_CODE: companyCode.value,
            GL_ID: glId.value,
            DEBIT_CREDIT_CLAUSE: debitCreditClause.value,
            PAYEE_ID: payId.value,
            JONO: true,
        });
    });

    const printReportPayeeJonoInternalSpreadsheetLink = computed(() => {
        return route('api.vouchers.jono-spreadsheet', {
            START_DATE: startDate.value,
            END_DATE: endDate.value,
            COMPANY_CODE: companyCode.value,
            GL_ID: glId.value,
            DEBIT_CREDIT_CLAUSE: debitCreditClause.value,
            PAYEE_ID: payId.value,
            JONO: true,
        });
    });

    function selectVoucher(id, description, date, amt) {
        selectedVoucherId.value = id;
        selectedDescription.value = description;
        selectedDate.value = date;
        selectedAmt.value = amt;
    }

    function navigateToRoute(routeName, voucherId) {
        const fullUrl = `${routeName}/${voucherId}`;
        window.location.href = fullUrl;
    }
</script>

<style scoped>
section {
    padding: 1rem;
    background-color: #f9fafb;
    border-radius: 0.5rem;
    border: 2px solid #d1d5db;
}

.glbalances-main-card {
    display: flex;
    column-gap: 0.5rem;
    width: 100%;
    flex-wrap: wrap;
}

.gl-table {
    width: 100%;
    height: 20rem;
    overflow-y: auto;
    background-color: #FFF;
}

.selected-row {
    background-color: lightslategrey;
}

thead {
    top: 0;
    margin: 0;
    position: sticky;
    background-color: white;
    backdrop-filter: blur(100px);
}

th {
    backdrop-filter: blur(100px);
}

td {
    max-width: 30vw;
}

td:hover {
    cursor: pointer;
}

.dropdown {
    cursor: pointer;
    padding: 3px;
}

.dropdown:hover {
    background-color: #0d6efd;
    color: white;
}
</style>
