<template>
    <div class="row g-3">
        <div class="col-md-4">
            <TimePeriodSelectTableCard show-only-type="YEARLY"
                v-model="selectedTimePeriod" />
        </div>
        <div class="col-md-8">
            <Card class="mb-3">
                <template #header>
                    Topseller Styles Report (by SM Price)
                </template>
                <FormInput type="text" v-model="topsellerStylesStyleFilter"
                    label="Style (filter)" id-prefix="topsellerStylesStyleFilter"
                    class="mb-1" />
                <div class="text-end">
                    <button type="button" class="btn btn-sm btn-primary"
                        @click="_ => { isSelectStylePopupOpen = true; reportToSetStyle = 'STYLES'; }">
                        Select
                    </button>
                </div>
                <FormInput type="number" step=".01" v-model="topsellerStylesPriceFilter"
                    label="SM Price (filter)" id-prefix="topsellerStylesPriceFilter"
                    class="mb-1" />
                <template #footer>
                    <div class="text-end">
                        <button type="button" class="btn btn-primary"
                            :disabled="!selectedTimePeriod"
                            @click="generateTopsellerStylesReport">
                            Generate
                        </button>
                    </div>
                </template>
            </Card>
            <Card>
                <template #header>
                    Topseller Locations Report
                </template>
                <FormInput type="text" v-model="topsellerLocationsStyleFilter"
                    label="Style (filter)" id-prefix="topsellerLocationsStyleFilter"
                    class="mb-1" />
                <div class="text-end">
                    <button type="button" class="btn btn-sm btn-primary"
                        @click="_ => { isSelectStylePopupOpen = true; reportToSetStyle = 'LOCATIONS'; }">
                        Select
                    </button>
                </div>
                <template #footer>
                    <div class="text-end">
                        <button type="button" class="btn btn-primary"
                            :disabled="!selectedTimePeriod"
                            @click="generateTopsellerLocationsReport">
                            Generate
                        </button>
                    </div>
                </template>
            </Card>
        </div>
    </div>
    <SelectStyle :is-open="isSelectStylePopupOpen"
        @close="_ => { isSelectStylePopupOpen = false; }"
        @select="selectedStyle => {
            if (reportToSetStyle == 'STYLES')
                topsellerStylesStyleFilter = selectedStyle.STYLE;
            else if (reportToSetStyle == 'LOCATIONS')
                topsellerLocationsStyleFilter = selectedStyle.STYLE;
            isSelectStylePopupOpen = false;
        }" />
</template>

<script setup>
import { ref } from 'vue';

import Card from '@/components/utils/Card';
import FormInput from '@/components/utils/FormInput';
import TimePeriodSelectTableCard from '@/components/utils/TimePeriodSelectTableCard';

import SelectStyle from '@/components/utils/style/SelectStyle.vue';

const topsellerStylesStyleFilter = ref(null);
const topsellerStylesPriceFilter = ref(null);

const topsellerLocationsStyleFilter = ref(null);

const isSelectStylePopupOpen = ref(false);

const selectedTimePeriod = ref(null);

const reportToSetStyle = ref(null);

function generateTopsellerStylesReport() {
    window.open(route('api.time-periods.style-bestsellers-by-sold-date-sm-price-spreadsheet.show', {
        time_period: selectedTimePeriod.value.TIME_PERIOD,
        style: topsellerStylesStyleFilter.value,
        price: topsellerStylesPriceFilter.value,
    }));
}

function generateTopsellerLocationsReport() {
    window.open(route('api.time-periods.location-bestsellers-spreadsheet.show', {
        time_period: selectedTimePeriod.value.TIME_PERIOD,
        style: topsellerLocationsStyleFilter.value,
    }));
}
</script>
