<template>
    <Card :class="class">
        <template #header>
            Pullouts for this Month
        </template>
        <DataTable :loading="isLoading" lazy :value="comments" :total-records="totalRecords"
            paginator :rows="10" @page="e => { onPage(e); fetchComments(); }"
            filter-display="row" v-model:filters="filters" @filter="tableOnFilter"
            v-model:selection="comment" selection-mode="single"
            :pt="{ table: { class: tableClass } }"
            :class="class">
            <Column field="COMMENT_ID" header="ID"
                :pt="{ filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                    <Button type="button" class="btn btn-primary" @click="filterCallback()">
                        Search
                    </Button>
                </template>
            </Column>
            <Column field="COMMENT_TEXT" header="Description"
                :pt="{ filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                    <Button type="button" class="btn btn-primary" @click="filterCallback()">
                        Search
                    </Button>
                </template>
            </Column>
            <Column field="COMMENT_RPT_DATE" header="Date"
                :pt="{ filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                    <Button type="button" class="btn btn-primary" @click="filterCallback()">
                        Search
                    </Button>
                </template>
            </Column>
            <Column field="COUNT" header="Count"
                :pt="{ filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                    <Button type="button" class="btn btn-primary" @click="filterCallback()">
                        Search
                    </Button>
                </template>
            </Column>
            <Column field="RR_COUNT" header="Count"
                :pt="{ filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                    <Button type="button" class="btn btn-primary" @click="filterCallback()">
                        Search
                    </Button>
                </template>
            </Column>
            <template #empty>No data.</template>
        </DataTable>
        <template #footer>
            <div class="text-end">
                <button type="button" class="btn btn-sm btn-primary"
                    :disabled="!comment"
                    @click="openCommentReport">
                    Open Report
                </button>
            </div>
        </template>
    </Card>
</template>

<script setup>
import { computed, ref, watch } from 'vue';

import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useComments } from '@/composables/data/comments';

import Card from '@/components/utils/Card.vue';

import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

const props = defineProps({
    timePeriod: Number,
    location: String,
    tableClass: String,
    class: String,
});

const emit = defineEmits(['error']);

const tableClass = computed(() => {
    let tableClass = 'table table-bordered table-hover';
    if(props.tableClass)
        tableClass += ' ' + props.tableClass;
    return tableClass;
});

const comment = ref(null);

const { params, filters, onPage, onFilter, onSort } = useDataTableParams(
    ['COMMENT_ID', 'COMMENT_TEXT', 'COMMENT_RPT_DATE', 'COUNT', 'RR_COUNT']);

const { comments, totalRecords, getCommentsWithPulloutCounts } = useComments();

const fetchTimeout = ref(null);

const isLoading = ref(false);

async function fetchCommentsWithPulloutCounts() {
    comments.value = null;

    if(!props.timePeriod || !props.location)
        return;

    isLoading.value = true;
    try {
        await getCommentsWithPulloutCounts(props.timePeriod, props.location, params.value);
    } catch(e) {
        emit('error', e.message);
    }
    isLoading.value = false;
}

function tableOnFilter(e) {
    onFilter(e);
    if(fetchTimeout.value)
        clearTimeout(fetchTimeout.value);
    fetchTimeout.value = setTimeout(fetchCommentsWithPulloutCounts, 300);
}

watch(() => [props.timePeriod, props.location], fetchCommentsWithPulloutCounts);

function openCommentReport() {
    window.open(route(`api.reports.inventory.comment-report-generator`, {
        REPORT_TYPE: 'comment-detail-style',
        COMMENT_ID: comment.value.COMMENT_ID,
        RECON_LOCATION: props.location,
    }), '_blank');
}
</script>
