<template>
    <Card :class="class">
        <h2>Selected Employee</h2>
        <div class="row g-3 mb-3">
            <div class="col-md-6">
                <input type="text" :value="employeeFullName" class="form-control" disabled>
            </div>
            <div class="col-md-6">
                <input type="text" :value="employee?.PAYTYPE ?? ''" class="form-control" disabled>
            </div>
        </div>
    </Card>
</template>

<script setup>
import { computed } from 'vue';

import Card from '../../../utils/Card.vue';

const props = defineProps({
    employee: Object,
    class: String
});

const emit = defineEmits(['click-update-button']);

const employeeFullName = computed(() =>
    props.employee !== null ?
        `${props.employee.FNAME} ${props.employee.LNAME}` :
        ''
);
</script>
