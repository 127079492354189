<template>
    <div v-if="isOpen" class="pop-up d-flex justify-content-center align-items-center">
        <Card>
            <template #header>
                <div class="d-flex justify-content-between pt-2">
                    <h4 class="title">Select Option</h4>
                    <button type="button" class="btn-close" @click="emit('close')"></button>
                </div>
            </template>
            <DataTable :value="options"
                paginator :rows="10"
                v-model:filters="filters" filter-display="row"
                v-model:selection="selectedOption" selection-mode="single"
                :pt="{ table: { class: 'table table-bordered table-hover jono-style-rate-table' } }">
                <Column v-for="field in fields" :field="field" :header="field" sortable :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                            :id="field + 'SearchInput'"
                            class="form-control" placeholder="Search" />
                    </template>
                </Column>
                <template #empty>
                    <div class="text-center">
                        No options.
                    </div>
                </template>
            </DataTable>
            <template #footer>
                <div class="d-grid">
                    <button type="button" class="btn btn-primary"
                        :disabled="!selectedOption"
                        @click="_ => { emit('select', selectedOption[fieldToUse]); }">
                        Select
                    </button>
                </div>
            </template>
        </Card>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';

import Card from '@/components/utils/Card';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

import focusnext from '@/utils/focusnext';

const props = defineProps({
    options: Array,
    fieldToUse: String,
    fields: Array,
    isOpen: Boolean,
    preselectedValue: null,
});

const emit = defineEmits(['select', 'close']);

const filters = ref({});

const selectedOption = ref(null);

watch(() => props.fields, _ => {
    filters.value = {
        ...props.fields.reduce((allFilters, field) => ({
            ...allFilters,
            [field]: {
                value: null,
                matchMode: 'contains'
            }
        }), {})
    };
});

watch(() => props.preselectedValue, _ => {
    filters.value[props.fieldToUse].value = props.preselectedValue;
});

watch(() => props.isOpen, _ => {
    setTimeout(_ => { focusnext(props.fieldToUse + 'SearchInput'); }, 0);
    selectedOption.value = null;
});
</script>

<style scoped>
.pop-up {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: rgb(0, 0, 0, 0.5);
}

.pop-up .card {
    width: 100%;
}

@media (min-width: 768px) {
    .pop-up .card {
        width: 85%;
    }
}

@media (min-width: 992px) {
    .pop-up .card {
        width: 70%;
    }
}
</style>
