<template>
    <div v-if="messages?.length > 0" class="vstack" :class="class">
        <div v-for="message in messages" role="alert" class="alert fade show" :class="{
            'alert-dismissible': dismissible,
            'alert-primary': message.type === 'PRIMARY',
            'alert-success': message.type === 'SUCCESS',
            'alert-danger': message.type === 'ERROR',
            'alert-warning': message.type === 'WARNING'
        }">
            <span v-html="message.content"></span>
            <button v-if="dismissible" type="button" class="btn-close" aria-label="Close"
                @click="resolveAlert(message)">
            </button>
            <template v-else-if="message.onYes != null || message.onNo != null">
                <a href="javascript:void(0)"
                    @click="() => {message.onYes?.(); resolveAlert(message);}">Yes</a>
                <a href="javascript:void(0)"
                    @click="() => {message.onNo?.(); resolveAlert(message);}" class="ms-2">No</a>
            </template>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    'messages': Array,
    'class': String,
    'dismissible': {
        type: Boolean,
        default: false
    }
});

function resolveAlert(message) {
    props.messages.splice(props.messages.indexOf(message), 1);
}
</script>
