<template>
    <Card :class="class">
        <template #header>
            Style
        </template>
        <form>
            <FormInput label="Style" type="text" v-model="style.STYLE"
                is-horizontal id-prefix="selectedStyle" class="mb-3"
                input-class="form-control-sm" :disabled="props.style?.STYLE != null"
                :errors="errors.STYLE"
                @keydown-enter="focusNextInput('styleTypeFormInput')" />
            <FormSelect label="Style Type" v-model="style.STYLE_TYPE"
                :options="styleTypes"
                :option-value="styleType => styleType.STYLE_TYPE"
                :option-to-string="styleType => styleType.STYLE_TYPE"
                is-horizontal id-prefix="styleType" class="mb-3"
                select-class="form-select-sm" :errors="errors.STYLE_TYPE"
                @keydown-enter="focusNextInput('categoryFormInput')" />
            <FormSelect label="Category" v-model="style.CATEGORY" :options="styleCategories"
                is-horizontal id-prefix="category" class="mb-3"
                select-class="form-select-sm" :errors="errors.CATEGORY"
                @keydown-enter="focusNextInput('supplierFormInput')" />
            <FormSelect label="Supplier" v-model="style.SUPPLIER_ID" :options="suppliers"
                :option-to-string="(supplier) => supplier.ACCOUNT_NAME"
                :option-value="(supplier) => supplier.ACCOUNT_ID"
                is-horizontal id-prefix="supplier" class="mb-3"
                select-class="form-select-sm" :errors="errors.SUPPLIER_ID"
                @keydown-enter="focusNextInput('insertStyleButton')" />
            <FormInput label="Created By" type="text" v-model="style.CREATED_BY"
                is-horizontal id-prefix="createdBy" class="mb-3"
                input-class="form-control-sm" disabled />
            <FormInput label="Created On" type="text" v-model="style.CREATED_WHEN"
                is-horizontal id-prefix="createdOn" class="mb-3"
                input-class="form-control-sm" disabled />
            <button type="button" class="btn btn-primary me-2" id="insertStyleButton"
                :disabled="props.style?.STYLE != null"
                @click="insertButtonOnClick">Insert</button>
            <button type="button" class="btn btn-primary me-2" id="updateStyleButton"
                :disabled="props.style?.STYLE == null"
                @click="updateButtonOnClick">Update</button>
            <button type="reset" class="btn btn-outline-secondary"
                @click="emit('clear')">
                Clear
            </button>
        </form>
    </Card>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';

import Card from '../../../utils/Card.vue';
import FormSelect from '../../../utils/FormSelect.vue';
import FormInput from '../../../utils/FormInput.vue';

import focusNextInput from '../../../../utils/focusnext';
import handleFetchErrors from "../../../../utils/handleFetchErrors";
import scrollToTop from "../../../../utils/scrollToTop";

import { useFlashMessages } from "../../../../composables/flashmessages";

const { addFlashMessage } = useFlashMessages();

import axios from 'axios';

const props = defineProps({
    style: Object,
    styleTypes: Array,
    class: String
});

const emit = defineEmits(['clear']);

const errors = ref({});

const styleCategories = ref();
const suppliers = ref();

const style = ref({});

watch(() => props.style, () => {
    errors.value = {};
    style.value = Object.assign({}, props.style);
});

async function fetchStyles() {
    try {
        const styleCategoriesResponse = await axios.get(route('api.styles.categories.index'));
        styleCategories.value = styleCategoriesResponse.data.data;
    } catch(e) {
        console.log(e);
        handleFetchErrors(e, 'Error while fetching categories.');
        scrollToTop();
        emit('error', 'An error occurred while fetching categories. ' +
            'Please contact your administrator.');
    }
}

async function fetchSuppliers() {
    try {
        const suppliersResponse = await axios.get(route('api.accounts.index', {
            accountType: 'STYLE-SUPP'
        }));
        suppliers.value = suppliersResponse.data.data;
    } catch(e) {
        console.log(e);
        handleFetchErrors(e, 'Error while fetching suppliers.');
        scrollToTop();
    }
}

onMounted(async () => {
    fetchStyles();
    fetchSuppliers();
});

async function insertButtonOnClick() {
    errors.value = {};
    try {
        const saveStyleResponse = await axios.post(route('api.styles.store'), style.value);
        style.value = saveStyleResponse.data.data;
        addFlashMessage('SUCCESS', 'Successfully created style ' +
            style.value.STYLE + '.', style.value);
    } catch(e) {
        console.log(e);
        errors.value = handleFetchErrors(e, 'Error while saving style.');
    }
    scrollToTop();
}

async function updateButtonOnClick() {
    errors.value = {};
    try {
        const updateStyleResponse = await axios.put(route('api.styles.update', {
            style: style.value.STYLE
        }), style.value);
        style.value = updateStyleResponse.data.data;
        addFlashMessage('SUCCESS', 'Successfully saved style ' +
            style.value.STYLE + '.', style.value);
    } catch(e) {
        console.log(e);
        errors.value = handleFetchErrors(e, 'Error while updating style.');
    }
    scrollToTop();
}
</script>
