export function useSaleTypes() {
    // Done as a composable type for the future prospect of transforming this as a fetch
    const saleTypes = [
        { value: 1, verbose: 'CASH' },
        { value: 2, verbose: 'CREDIT CARD' },
        { value: 3, verbose: 'ANY 2' },
        { value: 4, verbose: 'BUY 1 TAKE 1 SPLIT' },
        { value: 5, verbose: 'DISCOUNT' },
        { value: 6, verbose: 'BUY 1 TAKE 1 HIGHER' },
        { value: 7, verbose: 'EXCESS BARCODE' },
    ];
    
    return { saleTypes };
}
