import { ref } from "vue";

import axios from 'axios';

import constructFetchError from "./constructFetchError";

export function useStyleSMSkus() {
    const styleSMSkus = ref(null);

    const getStyleSMSkus = async (style, params) => {
        try {
            const styleSMSkusResponse = await axios.get(route('api.styles.sm-skus.index', {
                style: style,
                ...params
            }));
            styleSMSkus.value = styleSMSkusResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching SM SKUs.');
        }
    };
    
    return { styleSMSkus, getStyleSMSkus };
}
