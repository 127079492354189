<template>
    <Card>
        <template #header>
            Inventory Check Description
        </template>
        <DataTable :loading="isLoading" lazy :value="inventoryCheckDescriptions" :total-records="totalRecords"
            paginator :rows="10" @page="e => { onPage(e); fetchInventoryCheckDescriptions(); }"
            filter-display="row" v-model:filters="filters" @filter="tableOnFilter"
            sort-mode="multiple" @sort="e => { onSort(e); fetchInventoryCheckDescriptions(); }"
            v-model:selection="selectedInventoryCheckDescription" selection-mode="single"
            :pt="{ table: { class: 'table table-bordered table-hover' } }">
            <Column field="id" header="ID" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value"
                        @input="_ => {
                            filterModel.matchMode = 'contains';
                            filterCallback();
                        }"
                        class="form-control" placeholder="Search" />
                    <Button type="button" class="btn btn-primary"
                        @click="_ => {
                            filterModel.matchMode = 'contains';
                            filterCallback();
                        }">
                        Search
                    </Button>
                </template>
            </Column>
            <Column field="LOCATION" header="Location" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value"
                        @input="_ => {
                            filterModel.matchMode = 'contains';
                            filterCallback();
                        }"
                        class="form-control" placeholder="Search" />
                    <Button type="button" class="btn btn-primary"
                        @click="_ => {
                            filterModel.matchMode = 'contains';
                            filterCallback();
                        }">
                        Search
                    </Button>
                </template>
            </Column>
            <Column field="INVENTORY_DONE" header="Inventory Done" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                    <Button type="button" class="btn btn-primary" @click="filterCallback()">
                        Search
                    </Button>
                </template>
            </Column>
            <Column field="DESCRIPTION" header="Description" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
                bodyCell: { class: 'barcode' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                    <Button type="button" class="btn btn-primary" @click="filterCallback()">
                        Search
                    </Button>
                </template>
            </Column>
            <template #empty>
                <div class="text-center">
                    No data.
                </div>
            </template>
        </DataTable>
        <template v-if="$slots.footer" #footer>
            <slot name="footer"></slot>
        </template>
    </Card>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';

import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useInventoryCheckDescriptions } from '@/composables/data/inventoryCheckDescriptions';

import Card from '@/components/utils/Card.vue';

import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

const props = defineProps({
    locationFilter: String,
    idFilter: Number,
});

const emit = defineEmits(['error']);

const { params, filters, onPage, onFilter, onSort } = useDataTableParams(
    ['id', 'LOCATION', 'INVENTORY_DONE', 'DESCRIPTION']
);

const { inventoryCheckDescriptions, totalRecords, getInventoryCheckDescriptions } = useInventoryCheckDescriptions();

const isLoading = ref(false);

const fetchTimeout = ref(null);

const selectedInventoryCheckDescription = defineModel();

async function fetchInventoryCheckDescriptions() {
    inventoryCheckDescriptions.value = null;
    isLoading.value = true;
    try {
        await getInventoryCheckDescriptions(params.value);
    } catch(e) {
        emit('error', e.message);
    }
    isLoading.value = false;
}

function tableOnFilter(e) {
    onFilter(e);
    if(fetchTimeout.value)
        clearTimeout(fetchTimeout.value);
    fetchTimeout.value = setTimeout(fetchInventoryCheckDescriptions, 300);
}

onMounted(fetchInventoryCheckDescriptions);

watch(() => props.locationFilter, () => {
    filters.value.LOCATION.value = props.locationFilter;
    filters.value.LOCATION.matchMode = 'equals';
    onFilter();
    fetchInventoryCheckDescriptions();
});

watch(() => props.idFilter, () => {
    filters.value.id.value = props.idFilter;
    filters.value.id.matchMode = 'equals';
    onFilter();
    fetchInventoryCheckDescriptions();
});
</script>
