<template>
    <div class="row g-3">
        <div class="col-md-6">
            <AddStyleDetailsCard @add="addSkuToPullout" class="mb-3" />
            <SKUsToPulloutCard v-model="skusToPullout" />
        </div>
        <div class="col-md-6">
            <ExportStylesToPulloutCard @export="exportPulloutLetter"
                :disable-export="skusToPullout.length == 0" />
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';

import { useFlashMessages } from '@/composables/flashmessages';

import AddStyleDetailsCard from './AddStyleDetailsCard.vue';
import SKUsToPulloutCard from './SKUsToPulloutCard.vue';
import ExportStylesToPulloutCard from './ExportStylesToPulloutCard.vue';

const { addFlashMessage } = useFlashMessages();

const skusToPullout = ref([]);

function addSkuToPullout(skuToPullout) {
    if(skusToPullout.value
        .filter(addedSkuToPullout =>
            addedSkuToPullout.sku == skuToPullout.sku &&
            addedSkuToPullout.box == skuToPullout.box).length > 0) {
        addFlashMessage('ERROR', 'Style-Color is already added for same box.');
        return;
    }
    skusToPullout.value.push(skuToPullout);
}

function exportPulloutLetter(details) {
    window.open(route('api.sm-pullout-letter.show', {
        ...details,
        skus: skusToPullout.value.map(skuToPullout => ({
            sku: skuToPullout.sku,
            quantity: skuToPullout.quantity,
            box: skuToPullout.box
        }))
    }), '_blank');
}
</script>
