import { ref } from "vue";

const messages = ref([]);

export function useFlashMessages() {
    function addFlashMessage(type, content, dismissible = true, onYes = null, onNo = null) {
        messages.value.push({ type, content, dismissible, onYes, onNo });
    }

    function removeFlashMessage(index) {
        messages.value.splice(index, 1);
    }

    function clearFlashMessages() {
        messages.value = [];
    }

    return { messages, addFlashMessage, removeFlashMessage, clearFlashMessages };
};
