<template>
    <div class="row g-3 mb-3">
        <div class="col-md-8">
            <Card>
                <template #header>
                    New Location for Items
                </template>
                <div class="row">
                    <div class="col-8">
                        <div class="row g-3">
                            <div class="col-8">
                                <DataTable :value="commentDates" paginator :rows="5"
                                    filter-display="row" v-model:filters="commentDateFilters"
                                    v-model:selection="commentDate" selection-mode="single" data-key="date"
                                    :pt="{ table: { class: 'table table-bordered table-hover' } }"
                                    class="mb-3">
                                    <Column field="date" header="Date" :pt="{
                                            filterInput: { class: 'input-group input-group-sm' },
                                            filterMenuButton: { class: 'd-none' },
                                            headerFilterClearButton: { class: 'd-none' } }">
                                        <template #filter="{ filterModel, filterCallback }">
                                            <InputText type="text" v-model="filterModel.value" @keydown="filterCallback()"
                                                class="form-control" placeholder="Search"
                                                @keydown.enter.prevent="" />
                                            <Button type="button" class="btn btn-primary" @click="filterCallback()">
                                                Search
                                            </Button>
                                        </template>
                                    </Column>
                                    <template #empty>No dates.</template>
                                </DataTable>
                            </div>
                            <div class="col-4">
                                <FormInput type="text" v-model="previousLocationDisplay" disabled
                                    label="Prev. Loc." id-prefix="previousLocation" class="mb-1" />
                                <div class="text-end">
                                    <button type="button" class="btn btn-sm btn-primary"
                                        @click="_ => { isSelectingPreviousLocation = true; }">
                                        Select
                                    </button>
                                </div>
                                <FormSelect label="Comment Type" v-model="commentType"
                                    :options="commentTypes"
                                    id-prefix="commentType" class="mb-3"
                                    @keydown-enter="focusNextInput('commentFormInput')" />
                                <FormSelect label="Comment" v-model="comment"
                                    :options="comments"
                                    :option-to-string="comment => [comment.COMMENT_ID, comment.COMMENT_RPT_DATE,
                                        comment.COMMENT_TEXT, comment.LOCATION, comment.DESCRIPTION].join(' | ')"
                                    id-prefix="comment" class="mb-3"
                                    @keydown-enter="focusNextInput('barcodeFormInput')" />
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <FormInput type="text" label="New Location (automatically filled)"
                            v-model="commentLocation" disabled
                            id-prefix="newLocation" class="mb-3" />
                        <!-- For selling bags to employees themselves, bag must be from main warehouse -->
                        <FormInput type="text" label="Sale to Employee (for MAIN_1)"
                            v-model="sellToId" :disabled="previousLocation?.LOCATION != 'MAIN_1' || commentType != 'Sales'"
                            id-prefix="sellToId" class="mb-3" />
                    </div>
                </div>
                <template #footer>
                    <div class="text-end">
                        <button type="button" class="btn btn-outline-secondary"
                            @click="reset">
                            Reset
                        </button>
                    </div>
                </template>
            </Card>
        </div>
        <div class="col-md-4">
            <form @submit.prevent="addBarcode" class="mb-3">
                <Card>
                    <template #header>
                        Barcode to Move (specify comment first on the left)
                    </template>
                    <FormInput type="text" label="Item" v-model="barcode"
                        id-prefix="barcode" is-horizontal
                        :disabled="isBarcodeLoading"
                        :errors="barcodeErrors.barcode"
                        @keydown-enter="addBarcode" />
                    <template #footer>
                        <button type="submit" class="btn btn-primary"
                            :disabled="!comment || isBarcodeLoading" id="addBarcodeButton">
                            <span v-if="isBarcodeLoading" class="spinner-border spinner-border-sm"
                                role="status" aria-hidden="true"></span>
                            Add
                    </button>
                    </template>
                </Card>
            </form>
            <form @submit.prevent="addNoBarcode" class="mb-3">
                <Card>
                    <template #header>
                        Item to Move (NO BARCODE) (for Sales and Returns only)
                    </template>
                    <FormInput type="text" label="Style" v-model="noBarcodeStyle"
                        id-prefix="itemNoBarcode" is-horizontal
                        :errors="noBarcodeStyleErrors"
                        @keydown-enter="addNoBarcode" />
                    <template #footer>
                        <button type="submit" class="btn btn-primary" id="addNoBarcodeButton"
                            :disabled="!comment || (commentType != 'Sales' && commentType != 'Return') || isNoBarcodeLoading">
                            <span v-if="isNoBarcodeLoading" class="spinner-border spinner-border-sm"
                                role="status" aria-hidden="true"></span>
                            Add
                        </button>
                    </template>
                </Card>
            </form>
            <StyleImageCard :style="currentItemToMove?.style" />
        </div>
    </div>
    <Card class="mb-3">
        <template #header>
            Items to be moved
        </template>
        <div class="row g-3 mb-3">
            <div class="col-md-2">
                <FormInput label="Total Count" v-model="totalBarcodesCount"
                    is-horizontal disabled
                    id-prefix="count" />
            </div>
            <div class="col-md-2">
                <FormInput label="Total Price" v-model="totalBarcodesPrice"
                    is-horizontal disabled
                    id-prefix="totalprice" />
            </div>
            <div class="col-md-8">
                <div class="text-end">
                    <button type="button" class="btn btn-outline-danger me-2"
                        :disabled="!selectedBarcodeToMove"
                        @click="deleteSelectedFromBarcodesToMove">
                        Delete from List
                    </button>
                    <button type="button" class="btn btn-primary"
                        :disabled="itemsToMove.length == 0"
                        @click="moveItems">
                        Move Items
                    </button>
                </div>
            </div>
        </div>
        <DataTable :value="itemsToMove"
            v-model:selection="selectedBarcodeToMove" selection-mode="single"
            :pt="{ table: { class: 'table table-bordered table-hover' } }"
            class="mb-3">
            <Column field="barcode" header="Barcode"
                :pt="{ bodyCell: { class: 'barcode' } }" />
            <Column field="style" header="Style" />
            <Column field="date" header="Date Arrived" />
            <Column field="serial" header="Serial" />
            <Column field="currentLocation" header="Current Location" />
            <Column field="saleType" header="Sale Type" />
            <Column field="receipt" header="Receipt" />
            <Column field="color" header="Color" />
            <Column field="price" header="Price" />
            <Column field="soldDate" header="Sold Date" />
            <Column field="empid" header="Employee ID" />
            <Column field="employee_name" header="Employee Name" />
            <Column field="discount" header="Discount" />
            <template #empty>No items.</template>
        </DataTable>
    </Card>
    <div class="row g-3">
        <div class="col-md-6" v-if="commentType == 'Sales'">
            <SalesReportCard :previous-location="previousLocation"
                :comment="comment" />
        </div>
        <div class="col-md-6" v-if="commentType == 'Sales' || commentType == 'Return'">
            <NoBarcodesCard :previous-location="previousLocation"
                :comment="comment" />
        </div>
    </div>

    <!-- Hidden buttons, for programmatic triggering of modals -->
    <button class="d-none" data-bs-toggle="modal" data-bs-target="#findCommentOnReturnModal"
        ref="openFindCommentOnReturnModalButton"></button>

    <SelectLocationPopup :is-open="isSelectingPreviousLocation"
        @select="location => {
            previousLocation = location;
            isSelectingPreviousLocation = false;
        }"
        @close="_ => { isSelectingPreviousLocation = false; }"
        @error="message => {}" />
    
    <!-- For selling to employees-->
    <SelectEmployee :is-open="isSelectingEmployeeToSellTo"
        :on-close="_ => { isSelectingEmployeeToSellTo = false; }"
        :on-select-employee="onEmployeeToSellToSelect"/>

    <!-- Helper modal for finding a comment when returning a bag -->
    <FindCommentOnReturnToLocationModal :comment-date="commentDate?.date"
        @found="foundComment => { comment = foundComment; }" />
    
    <!-- For final details of item movement -->
    <OtherSelectUnifiedPopup :is-open="isSpecifyingDetails"
        :barcode-to-move="currentItemToMove"
        :comment-type="commentType"
        :comment="comment"
        :employee-location-filter="employeeLocationFilter"
        :previous-location="previousLocation"
        :items-to-move="itemsToMove"
        :movement-type="movementType"
        v-model:global-sold-date="globalSoldDate"
        v-model:global-employee="globalEmployee"
        v-model:validation-sold-date="validationSoldDate"
        @done="details => onOtherSelectUnifiedDone(details)"
        @done-b1t1="onOtherSelectUnifiedDoneB1T1"
        @close="_ => {
            isSpecifyingDetails = false;
            movementType = null;
        }" />
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue";

import { useBarcodesInventory } from '@/composables/data/barcodesInventory';
import { useComments } from "@/composables/data/comments";
import { useCommentDates } from "@/composables/data/commentDates";
import { useCommentTypes } from "@/composables/data/commentTypes";
import { useStyles } from '@/composables/data/styles';
import { useStylePrices } from '@/composables/data/stylePrices';
import { useLegacyBarcodes } from "@/composables/data/legacyBarcodes";

import Card from "@/components/utils/Card.vue";
import FormInput from "@/components/utils/FormInput.vue";
import FormSelect from "@/components/utils/FormSelect.vue";

import StyleImageCard from "@/components/utils/StyleImageCard.vue";

import SelectEmployee from "@/components/utils/SelectEmployee.vue";

import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';
import { FilterMatchMode } from 'primevue/api';

import FindCommentOnReturnToLocationModal from "./FindCommentOnReturnToLocationModal.vue";
import OtherSelectUnifiedPopup from "./other-select-unified-popup/";
import NoBarcodesCard from "./NoBarcodesCard.vue";
import SalesReportCard from "./SalesReportCard.vue";

import axios from "axios";

import focusNextInput from "@/utils/focusnext";
import handleFetchErrors from "@/utils/handleFetchErrors";
import scrollToTop from "@/utils/scrollToTop";

import { useFlashMessages } from "@/composables/flashmessages";
import SelectLocationPopup from "@/components/utils/popups/SelectLocationPopup.vue";

const { addFlashMessage, clearFlashMessages } = useFlashMessages();

const { getNewBarcode } = useLegacyBarcodes();

const isSpecifyingDetails = ref(false);

// Button refs for programmatic triggering of modals
const openFindCommentOnReturnModalButton = ref(null);

const commentDateFilters = ref({
    date: { value: null, matchMode: FilterMatchMode.CONTAINS },
});

const { commentDates, getCommentDates } = useCommentDates();
const commentDate = ref(null);

const { commentTypes } = useCommentTypes();
const commentType = ref(null);

const previousLocation = ref(null);
const previousLocationDisplay = computed(() => previousLocation.value?.LOCATION);
const isSelectingPreviousLocation = ref(false);

const { comments, getComments } = useComments();
const comment = ref(null);

const { getBarcodeLatestInformation } = useBarcodesInventory();

const { getStyle } = useStyles();

const { getStylePrices } = useStylePrices();

const commentLocation = computed(() => comment.value?.LOCATION);

const barcode = ref(null);
const barcodeErrors = ref({});

const itemsToMove = ref([]);
const selectedBarcodeToMove = ref(null); // Used when deleting
const currentItemToMove = ref(null);

const noBarcodeStyleErrors = ref(null);
const noBarcodeStyle = ref(null); // For adding no barcode items

const sellToId = ref(0); // For selling bags to employees themselves
const isSelectingEmployeeToSellTo = ref(false);

const movementType = ref(null); // BARCODE or NOBC, for disabling certain things in Unified Barcodes Form

const globalSoldDate = ref(null); // For retaining sold date, continuous adding
const globalEmployee = ref(null); // For retaining employee, continuous adding

const validationSoldDate = ref(null);

const style = ref(null);

const totalBarcodesCount = computed(() => itemsToMove.value.length);
const totalBarcodesPrice = computed(() =>
    itemsToMove.value.reduce(
        (total, barcodeEntry) => total + (barcodeEntry.discount || barcodeEntry.price), 0)
            .toFixed(2)
);

const employeeLocationFilter = ref(null);

const isBarcodeLoading = ref(false);
const isNoBarcodeLoading = ref(false);

onMounted(() => {
    getCommentDates();
});

watch([commentDate, commentType], () => {
    comments.value = null;
    comment.value = null;
    if(!commentDate.value || !commentType.value) return;
    getComments({ 
        MONTH: commentDate.value.date,
        COMMENT_TYPE: commentType.value,
        include_relations: 'location',
    });
});

watch([previousLocation, comment], () => {
    if(!previousLocation.value || !comment.value)
        return;
    
    else if(previousLocation.value.LOCATION == comment.value.LOCATION) {
        addFlashMessage('ERROR', 'Cannot specify that comment, locations will be same.');
        setTimeout(() => {
            comment.value = null;
        }, 0);
    }
});

watch([previousLocation, commentType], () => {
    sellToId.value = 0;

    if(!previousLocation.value || !commentType.value)
        return;

    else if(previousLocation.value.LOCATION == 'SOLD' && commentType.value == 'Return')
        openFindCommentOnReturnModalButton.value.click();

    else if(previousLocation.value.LOCATION == 'MAIN_1' && commentType.value == 'Sales')
        isSelectingEmployeeToSellTo.value = true; // This modal is done differently so opening it is via a flag
});

watch(comment, () => {
    style.value = null;
    employeeLocationFilter.value = null;

    itemsToMove.value = [];
    selectedBarcodeToMove.value = null;
    currentItemToMove.value = null;

    globalSoldDate.value = null;
    globalEmployee.value = null;

    validationSoldDate.value = null;
});

async function addBarcode() {
    barcodeErrors.value = {};
    currentItemToMove.value = null;
    style.value = null;
    movementType.value = null;
    isBarcodeLoading.value = true;
    clearFlashMessages();

    try {
        if(!barcode.value)
            throw new Error('Please specify barcode.');

        if(!comment.value)
            throw new Error('Please specify comment details first.');

        barcode.value = barcode.value.toUpperCase();

        if(itemsToMove.value.filter(barcodeEntry => barcodeEntry.barcode == barcode.value).length > 0)
            throw new Error('Barcode is already inputted for movement.');

    } catch(e) {
        console.log(e);
        addFlashMessage('ERROR', e.message);
        isBarcodeLoading.value = false;
        return;
    }

    try {
        // Check if barcode is legacy, then replace
        try {
            const newBarcode = await getNewBarcode(barcode.value);
            alert(`Replacing Legacy Barcode ${barcode.value} with ${newBarcode}.`);
            barcode.value = newBarcode;
        } catch(e) {
            if (e.status != 404)
                throw e;
        }

        let lastInventoryRecord = null;
        try {
            lastInventoryRecord = await getBarcodeLatestInformation(barcode.value, comment.value.COMMENT_RPT_DATE);
        } catch(e) {
            if(e.status == 422 || e.status == 400)
                throw e;
        }
        if(!lastInventoryRecord)
            throw new Error('Barcode does not exist.');

        // For sales, price is dependent on sold date (internally in popup). Ignore this validation for sales.
        if(commentType.value != 'Sales' && (!lastInventoryRecord.SM_PRICE && !lastInventoryRecord.LM_SALE_PRICE))
            throw new Error('Cannot move item. Item has no price available for this date.');

        // Check if input previous location matches latest location of the bag
        if(lastInventoryRecord.LOCATION != previousLocation.value.LOCATION)
            throw new Error(`Barcode current location (${lastInventoryRecord.LOCATION}) ` +
                `does not match inputted previous location (${previousLocation.value.LOCATION}).`);

        let price = 0;
        let empid = null;
        let soldDate = null;
        let receipt = null;
        if(lastInventoryRecord.COMMENT_TYPE == 'S') {
            employeeLocationFilter.value = lastInventoryRecord.PREV_LOC; // For the modal, fetch all employees in the mall it was sold from
            price = -lastInventoryRecord.PRICE;
            empid = lastInventoryRecord.EMPID;
            soldDate = lastInventoryRecord.SOLD_DT;
            receipt = lastInventoryRecord.RECEIPT_NO;
        } else {
            employeeLocationFilter.value = previousLocation.value.LOCATION; // For the modal, fetch all employees with the current prev_loc
            let priceType;

            if(comment.value.COMMENT_TYPE == 'S') { // This is a SALE, price dependent on where it came from
                priceType = lastInventoryRecord.PRICE_TYPE;
            } else { // Price dependent on where its going
                priceType = comment.value.location.PRICE_TYPE;
            }

            if(commentType.value == 'Sales') price = null; // If SALE, price is handled internally (by sold date). Set to null
            else if(priceType == 'S') price = lastInventoryRecord.SM_PRICE; // I
            else price = lastInventoryRecord.LM_SALE_PRICE;
        }

        currentItemToMove.value = {
            barcode: barcode.value,
            style: lastInventoryRecord.STYLE,
            date: lastInventoryRecord.DATE,
            serial: lastInventoryRecord.SERIAL,
            currentLocation: lastInventoryRecord.LOCATION,
            saleType: lastInventoryRecord.SALE_TYPE,
            receipt: receipt,
            color: lastInventoryRecord.COLOR,
            price: price,
            soldDate: soldDate ?? globalSoldDate.value,
            empid: empid ?? globalEmployee.value?.EMPID ?? 0,
            discount: 0
        };
        movementType.value = "BARCODE";
        isSpecifyingDetails.value = true;
    } catch(e) {
        console.log(e);
        if(e.response)
            barcodeErrors.value = handleFetchErrors(e, 'Error while retrieving barcode data.') ?? {};
        else
            addFlashMessage('ERROR', e.message);
    }
    isBarcodeLoading.value = false;
}

async function addNoBarcode() {
    noBarcodeStyleErrors.value = [];
    movementType.value = null;
    isNoBarcodeLoading.value = true;
    clearFlashMessages();

    try {
        if(!noBarcodeStyle.value)
            throw new Error('Please specify STYLE.');
        
        if(!comment.value)
            throw new Error('Please specify comment details first.');

        try {
            style.value = await getStyle(noBarcodeStyle.value, {
                include_relations: 'stylePrices'
            });
        } catch(e) {
            if(e.status == 404) {
                noBarcodeStyleErrors.value = ['Style does not exist.'];
                throw new Error('Style does not exist.');
            }
        }

        // Fetch price
        const latestPrice = (await getStylePrices(style.value.STYLE, {
            START_DATE_LTE: comment.value.COMMENT_RPT_DATE,
            END_DATE_GTE: comment.value.COMMENT_RPT_DATE
        }))[0];
        
        if(commentType.value != 'Sales' && !latestPrice) {
            const message = 'No price for style. Please add price in Style Price Form.';
            noBarcodeStyleErrors.value = [message];
            throw new Error(message);
        }
        
        // For filtering which employees to show in the modal
        if(commentType.value == 'Return')
            employeeLocationFilter.value = comment.value.location.LOCATION;
        else
            employeeLocationFilter.value = previousLocation.value.LOCATION;
        
        // Determining which price to use (SM_PRICE or LM_SALE_PRICE)
        let price = null;
        if(commentType.value == 'Sales') price = null; // If SALE, price is handled internally (by sold date). Set to null
        else if(comment.value.location.PRICE_TYPE == 'S') price = latestPrice.SM_PRICE;
        else price = latestPrice.LM_SALE_PRICE;

        currentItemToMove.value = {
            style: noBarcodeStyle.value,
            saleType: 1,
            receipt: null,
            color: null,
            currentLocation: previousLocation.value.LOCATION,
            price: commentType.value == 'Sales' ? price : -price,
            soldDate: globalSoldDate.value,
            empid: globalEmployee.value?.EMPID ?? 0,
            discount: 0
        };
        movementType.value = 'NOBC';
        isSpecifyingDetails.value = true;
    } catch(e) {
        console.log(e);
        addFlashMessage('ERROR', e.message);
    }

    isNoBarcodeLoading.value = false;
}

function onEmployeeToSellToSelect(employee) {
    sellToId.value = employee.EMPID;
}

function reset() {
    clearFlashMessages();

    commentDate.value = null;
    previousLocation.value = null;
    commentType.value = null;
    comment.value = null;
    sellToId.value = null;
    movementType.value = null;

    style.value = null;
    employeeLocationFilter.value = null;

    itemsToMove.value = [];
    selectedBarcodeToMove.value = null;
    currentItemToMove.value = null;

    barcode.value = null;
    noBarcodeStyle.value = null;

    globalSoldDate.value = null;
    globalEmployee.value = null;

    validationSoldDate.value = null;
}

function onOtherSelectUnifiedDone(details) {
    isSpecifyingDetails.value = false;
    itemsToMove.value.push(details);
    barcode.value = null;
    noBarcodeStyle.value = null;
    currentItemToMove.value = null;

    if(movementType.value == 'BARCODE')
        focusNextInput('barcodeFormInput');
    else if(movementType.value == 'NOBC')
        focusNextInput('itemNoBarcodeFormInput');

    movementType.value = null;
}

function onOtherSelectUnifiedDoneB1T1(details, barcodePartnerDetails) {
    isSpecifyingDetails.value = false;
    itemsToMove.value.push(details);
    itemsToMove.value.push(barcodePartnerDetails);
    barcode.value = null;
    noBarcodeStyle.value = null;
    currentItemToMove.value = null;

    if(movementType.value == 'BARCODE')
        focusNextInput('barcodeFormInput');
    else if(movementType.value == 'NOBC')
        focusNextInput('itemNoBarcodeFormInput');

    movementType.value = null;
}

function deleteSelectedFromBarcodesToMove() {
    itemsToMove.value.splice(itemsToMove.value.indexOf(selectedBarcodeToMove.value), 1);
    selectedBarcodeToMove.value = null;
}

async function moveItems() {
    try {
        const withBarcodeItems = itemsToMove.value.filter(itemToMove => itemToMove.barcode != null);
        const withoutBarcodeItems = itemsToMove.value.filter(itemToMove => itemToMove.barcode == null);

        await axios.post(route('api.inventory.movements.store'), {
            COMMENT_ID: comment.value.COMMENT_ID,
            barcodes: withBarcodeItems.map(barcodeEntry => ({
                STYLE: barcodeEntry.style,
                DATE: barcodeEntry.date,
                SERIAL: barcodeEntry.serial,
                PREV_LOC: barcodeEntry.currentLocation,
                PRICE: barcodeEntry.price,
                SOLD_DT: barcodeEntry.soldDate,
                COLOR: barcodeEntry.color,
                SALE_TYPE: barcodeEntry.saleType,
                RECEIPT_NO: barcodeEntry.receipt,
                EMPID: barcodeEntry.empid,
                SELL_TO_TYPE: 'I',
                SELL_TO_ID: sellToId.value,
                DISCOUNT: barcodeEntry.discount
            })),
            items: withoutBarcodeItems.map(itemEntry => ({
                STYLE: itemEntry.style,
                PREV_LOC: itemEntry.currentLocation,
                PRICE: itemEntry.price,
                SOLD_DT: itemEntry.soldDate,
                COLOR: itemEntry.color,
                SALE_TYPE: itemEntry.saleType,
                RECEIPT_NO: itemEntry.receipt,
                EMPID: itemEntry.empid,
                DISCOUNT: itemEntry.discount
            }))
        });
        addFlashMessage('SUCCESS', 'Successfully moved barcodes/nobc.');
        itemsToMove.value = [];
        validationSoldDate.value = null;
    } catch(e) {
        console.log(e);
        handleFetchErrors(e, 'Error while moving barcodes/nobc.');
    }

    scrollToTop();
}
</script>
