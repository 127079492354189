<template>
    <DataTable lazy :loading="isLoading" :value="timePeriods" :total-records="totalRecords"
        paginator :rows="10" @page="e => { onPage(e); fetchTimePeriods(); }"
        filter-display="row" v-model:filters="filters" @filter="tableOnFilter"
        v-model:selection="selectedTimePeriod" selection-mode="single"
        sort-mode="multiple" @sort="e => { onSort(e); fetchTimePeriods(); }"
        :pt="{ table: { class: ' table table-bordered table-hover' } }">
        <Column v-if="!hideProperties?.has('TIME_PERIOD')" field="TIME_PERIOD" header="Time Period" :sortable="!sort" :pt="{
            filterInput: { class: 'input-group input-group-sm' },
            filterMenuButton: { class: 'd-none' },
            headerFilterClearButton: { class: 'd-none' } }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                    class="form-control" placeholder="Search" />
            </template>
        </Column>
        <Column v-if="!hideProperties?.has('PAYTYPE')" field="PAYTYPE" header="Paytype" :sortable="!sort" :pt="{
            filterInput: { class: 'input-group input-group-sm' },
            filterMenuButton: { class: 'd-none' },
            headerFilterClearButton: { class: 'd-none' } }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                    class="form-control" placeholder="Search" />
            </template>
        </Column>
        <Column v-if="!hideProperties?.has('START_DATE')" field="START_DATE" header="Start Date" :sortable="!sort" :pt="{
            filterInput: { class: 'input-group input-group-sm' },
            filterMenuButton: { class: 'd-none' },
            headerFilterClearButton: { class: 'd-none' } }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                    class="form-control" placeholder="Search" />
            </template>
        </Column>
        <Column v-if="!hideProperties?.has('END_DATE')" field="END_DATE" header="End Date" :sortable="!sort" :pt="{
            filterInput: { class: 'input-group input-group-sm' },
            filterMenuButton: { class: 'd-none' },
            headerFilterClearButton: { class: 'd-none' } }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                    class="form-control" placeholder="Search" />
            </template>
        </Column>
        <Column v-if="!hideProperties?.has('STATUS')" field="STATUS" header="Status" :sortable="!sort" :pt="{
            filterInput: { class: 'input-group input-group-sm' },
            filterMenuButton: { class: 'd-none' },
            headerFilterClearButton: { class: 'd-none' } }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                    class="form-control" placeholder="Search" />
            </template>
        </Column>
        <template #loading>
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
            Loading time periods...
        </template>
        <template #empty>
            <div class="text-center">
                No time periods...
            </div>
        </template>
    </DataTable>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';

import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useTimePeriods } from '@/composables/data/timePeriods';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

import moment from 'moment';

const props = defineProps({
    showFromCurrentDate: Boolean,
    paytype: String,
    hideProperties: Set,
    filters: Object,
    sort: Array,
});

const emit = defineEmits(['error']);

const { params, filters, onPage, onFilter, onSort } = useDataTableParams(
    ['TIME_PERIOD', 'PAYTYPE', 'START_DATE', 'END_DATE', 'STATUS']);

const { timePeriods, totalRecords, getTimePeriods } = useTimePeriods();

const selectedTimePeriod = defineModel();

const filterFetchTimeout = ref(null);

const isLoading = ref(false);

async function fetchTimePeriods() {
    timePeriods.value = null;
    isLoading.value = true;
    try {
        if(props.paytype) {
            params.value.filters.PAYTYPE = {
                value: props.paytype,
                matchMode: 'equals',
            };
        }

        await getTimePeriods({
            ...params.value,
            filters: {
                ...params.value.filters,
                ...props.filters
            },
            multiSortMeta: {
                ...params.value.multiSortMeta,
                ...props.sort
            },
            START_DATE_LTE: props.showFromCurrentDate ? moment().format('YYYY-MM-DD') : null,
        });
    } catch(e) {
        emit('error', e.message);
    }
    isLoading.value = false;
}

async function tableOnFilter(e) {
    onFilter(e);
    if(filterFetchTimeout.value)
        clearTimeout(filterFetchTimeout.value);
    filterFetchTimeout.value = setTimeout(fetchTimePeriods, 300);
}

onMounted(fetchTimePeriods);

watch(() => props.paytype, fetchTimePeriods);
</script>
