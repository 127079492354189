import { ref } from "vue";

import constructFetchError from "./constructFetchError";

import axios from 'axios';

export function useOrderSuppliers() {
    const orderSuppliers = ref(null);
    const totalRecords = ref(null);

    const getOrderSuppliers = async (params) => {
        try {
            const getOrderSuppliersResponse = await axios.get(route('api.order-suppliers.index', params));
            orderSuppliers.value = getOrderSuppliersResponse.data.data;
            totalRecords.value = getOrderSuppliersResponse.data.meta.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching order suppliers.');
        }
    };

    const postOrderSupplier = async (orderSupplier) => {
        try {
            const postOrderSupplierResponse = await axios.post(route('api.order-suppliers.store'), orderSupplier);
            return postOrderSupplierResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while saving order supplier.');
        }
    };

    const putOrderSupplier = async (orderSupplierId, orderSupplier) => {
        try {
            const putOrderSupplierResponse = await axios.put(route('api.order-suppliers.update', {
                order_supplier: orderSupplierId
            }), orderSupplier);
            return putOrderSupplierResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while updating order supplier.');
        }
    };

    return {
        orderSuppliers,
        totalRecords,
        getOrderSuppliers,
        postOrderSupplier,
        putOrderSupplier
    };
}
