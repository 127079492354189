import { ref } from "vue";

import constructFetchError from "./constructFetchError";

import axios from "axios";

export function useEmployeeNidekaHoursOnDays() {
    const employeeNidekaHoursOnDays = ref(null);
    const totalRecords = ref(null);

    const getEmployeeNidekaHoursOnDays = async (empid, params) => {
        try {
            const getEmployeeNidekaHoursOnDaysResponse = await axios.get(route('api.employees.employee-nideka-hours-on-days.index', {
                employee: empid,
                ...params
            }));
            employeeNidekaHoursOnDays.value = getEmployeeNidekaHoursOnDaysResponse.data.data;
            totalRecords.value = getEmployeeNidekaHoursOnDaysResponse.data.meta.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching employee hours.');
        }
    };
    
    return {
        employeeNidekaHoursOnDays,
        totalRecords,
        getEmployeeNidekaHoursOnDays,
    };
}
