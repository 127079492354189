import { ref } from "vue";

import axios from 'axios';

import constructFetchError from "./constructFetchError";

export function useVouchers() {
    const vouchers = ref(null);
    const voucher = ref(null);

    const getFinalVouchers = async (params) => {
        try {
            const getVouchersResponse = await axios.get(route('api.finvoucherall.index', params));
            vouchers.value = getVouchersResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching vouchers.');
        }
    };

    const getVoucher = async (voucherId) => {
        try {
            const getVoucherResponse = await axios.get(route('api.vouchers.show', {
                voucher: voucherId
            }));
            voucher.value = getVoucherResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching voucher.');
        }
    };

    const postGovtVoucherImage = async (govtVoucherId, formData) => {
        try {
            await axios.post(route('api.voucher.govt.image.store', {
                govt_voucher_id: govtVoucherId
            }), formData);
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while saving govt. voucher image.');
        }
    };

    return { vouchers, voucher, getFinalVouchers, getVoucher, postGovtVoucherImage };
}
