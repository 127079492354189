<template>
    <div class="card" :class="class">
        <div v-if="slots.header" class="card-header">
            <slot name="header"></slot>
        </div>
        <div class="card-body">
            <h5 v-if="props.title" class="card-title">{{ title }}</h5>
            <slot></slot>
        </div>
        <div v-if="slots.footer" class="card-footer">
            <slot name="footer"></slot>
        </div>
    </div>
</template>

<script setup>
import { useSlots } from 'vue';

const props = defineProps({
    title: String,
    class: String,
});

const slots = useSlots();
</script>
