import { ref } from "vue";

import constructFetchError from "./constructFetchError";

export function useComments() {
    const comments = ref(null);
    const totalRecords = ref(null);

    const getComments = async (params) => {
        try {
            const commentsResponse = await axios.get(route('api.comments.index', { ...params }));
            comments.value = commentsResponse.data.data;
            totalRecords.value = commentsResponse.data.meta?.total ?? comments.value.length;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching comments.');
        }
    };

    const getLostCommentsWithTotals = async (startDate, endDate, location, params) => {
        try {
            const commentsResponse = await axios.get(route('api.comments.lost-comments.with-totals.index', {
                START_DATE: startDate,
                END_DATE: endDate,
                LOCATION: location,
                ...params
            }));
            comments.value = commentsResponse.data.data;
            totalRecords.value = commentsResponse.data.meta?.total ?? comments.value.length;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching LOST comments.');
        }
    };

    const getCommentsWithPulloutCounts = async (timePeriod, location, params) => {
        try {
            const commentsResponse = await axios.get(route('api.comments.with-pullout-counts.index', {
                ...params,
                TIME_PERIOD: timePeriod,
                LOCATION: location,
            }));
            comments.value = commentsResponse.data.data;
            totalRecords.value = commentsResponse.data.meta?.total ?? comments.value.length;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching comments.');
        }
    };

    const getDeliveryPulloutReports = async (startDate, endDate, location, params, reportType) => {
        try {
            const commentsResponse = await axios.get(route('api.comments.delivery-pullout-reports', {
                ...params,
                START_DATE: startDate,
                END_DATE: endDate,
                LOCATION: location,
                REPORT_TYPE: reportType
            }));

            comments.value = commentsResponse.data.data;
            totalRecords.value = commentsResponse.data.meta?.total ?? comments.value.length;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching comments.');
        }
    }

    return {
        comments,
        totalRecords,
        getComments,
        getLostCommentsWithTotals,
        getCommentsWithPulloutCounts,
        getDeliveryPulloutReports,
    };
}
