<template>
    <Card>
        <template #header>
            Same Style
        </template>
        <DataTable lazy :loading="isLoading" :value="landedCosts" :total-records="landedCostsTotalRecords"
            paginator :rows="10" @page="e => { onPage(e); fetchLandedCosts(); }"
            filter-display="row" v-model:filters="filters" @filter="tableOnFilter"
            sort-mode="multiple" @sort="e => { onSort(e); fetchLandedCosts(); }"
            :pt="{ table: { class: 'table table-bordered table-hover' } }">
            <Column field="STYLE" header="Style" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                    <Button type="button" class="btn btn-primary" @click="filterCallback()">
                        Search
                    </Button>
                </template>
            </Column>
            <Column field="DATE" header="Date" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                    <Button type="button" class="btn btn-primary" @click="filterCallback()">
                        Search
                    </Button>
                </template>
            </Column>
            <Column field="COUNT" header="Count" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                    <Button type="button" class="btn btn-primary" @click="filterCallback()">
                        Search
                    </Button>
                </template>
            </Column>
            <Column field="LANDED_COST" header="Landed Cost" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                    <Button type="button" class="btn btn-primary" @click="filterCallback()">
                        Search
                    </Button>
                </template>
            </Column>
            <template #empty>
                <div class="text-center">
                    No data.
                </div>
            </template>
        </DataTable>
        <template #footer>
            <div class="text-end">
                <button type="button" class="btn btn-primary me-1"
                    :disabled="isLoading"
                    @click="fetchLandedCosts">
                    <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Refresh
                </button>
            </div>
        </template>
    </Card>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';

import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useLandedCosts } from '@/composables/data/landedCosts';

import Card from '@/components/utils/Card';

import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

const props = defineProps({
    style: String,
});

const emit = defineEmits(['error']);

const { params, filters, onPage, onFilter, onSort } = useDataTableParams(
    ['STYLE', 'DATE', 'COUNT', 'LANDED_COST']);

const { landedCosts, landedCostsTotalRecords, getLandedCostsByStyle } = useLandedCosts();

const isLoading = ref(false);

const fetchTimeout = ref(null);

async function fetchLandedCosts() {
    landedCosts.value = null;

    if(!props.style)
        return;

    isLoading.value = true;
    try {
        await getLandedCostsByStyle(props.style, params.value);
    } catch(e) {
        emit('error', e.message);
    }
    isLoading.value = false;
}

async function tableOnFilter(e) {
    onFilter(e);
    if(fetchTimeout.value)
        clearTimeout(fetchTimeout.value);
    fetchTimeout.value = setTimeout(fetchLandedCosts, 300);
}

onMounted(fetchLandedCosts);

watch(() => props.style, fetchLandedCosts);
</script>
