<style scoped>
.sticky-header-footer {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #FFF;
}

#comment-container {
    width: 100%;
    height: 15rem;
}

#month-container {
    width: 100%;
    height: 10.5rem;
    overflow-y: auto;
    background-color: #FFF;
}

#smsku-container,
#comment-split-container{
    width: 100%;
    height: 18rem;
    overflow-y: auto;
    background-color: #FFF;
}
</style>

<template>
    <Card>
        <div class="row g-3 mb-3">
            <div class="col-md-2">
                <div class="mb-3">
                    <Card>
                        <template #header>
                            COMMENT
                        </template>
                        <div id="comment-container">
                            <button class="btn btn-primary w-100 mb-1"
                                @click="ShowPDFReport('comment-detail-stocklot')"
                                :disabled="!selectedComment"
                                >Comment Detail<br>(by STOCKLOT)</button>
                            <button class="btn btn-primary w-100 mb-1"
                                @click="ShowPDFReport('comment-detail-style')"
                                :disabled="!selectedComment"
                                >Comment Detail<br>(by STYLE)</button>
                            <button class="btn btn-primary w-100 mb-1"
                                @click="_ => { isGeneratingCommentSummary = true; }"
                                :disabled="!selectedComment"
                                >Comment Summary<br>(by STYLE)</button>
                        </div>
                    </Card>
                </div>
                <div>
                    <Card>
                        <template #header>
                            COMMENT - SPLIT BY BRAND
                        </template>
                        <div id="comment-split-container"
                            class="d-flex flex-column align-items-center">
                            <FormSelect class="w-100 mb-3" :options="splitTypes"
                            :option-to-string="(splitType) => splitType.label"
                            :option-value="(splitType) => splitType.value"
                            v-model="splitType"
                            select-class="form-select-sm w-100"/>
                            <button class="btn btn-primary w-100 mb-1"
                                @click="ShowPDFReport('comment-detail-split')"
                                :disabled="!selectedComment"
                                >Comment Detail<br>(by STYLE)
                            </button>
                            <button class="btn btn-primary w-100 mb-1"
                                @click="ShowPDFReport('comment-summary-split')"
                                :disabled="!selectedComment"
                                >Comment Summary<br>(by STYLE)
                            </button>
                        </div>
                    </Card>
                </div>
            </div>
            <div class="col-md-10">
                <Card>
                    <DataTable lazy paginator class="sticky-header-footer"
                        :rows="10" :value="commentList" :total-records="totalRecords"
                        filter-display="row" v-model:filters="filters"
                        v-model:selection="selectedComment" selection-mode="single" data-key="COMMENT_ID"
                        @page="e => onPage(e)" @filter="e => onFilter(e)"
                        :row-class="commentRowClass" :pt="{ table: { class: ' table table-bordered table-hover' } }">
                        <Column field="COMMENT_ID" header="ID" :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' } }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText type="text" v-model="filterModel.value" @keydown="filterCallback()"
                                    class="form-control" placeholder="Search" />
                                <Button type="button" class="btn btn-primary" @click="filterCallback()">
                                    Search
                                </Button>
                            </template>
                        </Column>
                        <Column field="COMMENT_TEXT" header="Text" :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' } }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText type="text" v-model="filterModel.value" @keydown="filterCallback()"
                                    class="form-control" placeholder="Search" />
                                <Button type="button" class="btn btn-primary" @click="filterCallback()">
                                    Search
                                </Button>
                            </template>
                        </Column>
                        <Column field="COMMENT_RPT_DATE" header="Report Date" :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' } }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText type="text" v-model="filterModel.value" @keydown="filterCallback()"
                                    class="form-control" placeholder="Search" />
                                <Button type="button" class="btn btn-primary" @click="filterCallback()">
                                    Search
                                </Button>
                            </template>
                        </Column>
                        <Column field="COMMENT_TIMESTAMP" header="Timestamp" :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' } }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText type="text" v-model="filterModel.value" @keydown="filterCallback()"
                                    class="form-control" placeholder="Search" />
                                <Button type="button" class="btn btn-primary" @click="filterCallback()">
                                    Search
                                </Button>
                            </template>
                        </Column>
                        <Column field="LOCATION" header="Location" :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' } }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText type="text" v-model="filterModel.value" @keydown="filterCallback()"
                                    class="form-control" placeholder="Search" />
                                <Button type="button" class="btn btn-primary" @click="filterCallback()">
                                    Search
                                </Button>
                            </template>
                        </Column>
                        <Column field="CREATED_BY" header="Created By" :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' } }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText type="text" v-model="filterModel.value" @keydown="filterCallback()"
                                    class="form-control" placeholder="Search" />
                                <Button type="button" class="btn btn-primary" @click="filterCallback()">
                                    Search
                                </Button>
                            </template>
                        </Column>
                    </DataTable>
                </Card>
                <div class="d-flex mt-3">
                    <Card class="w-50">
                        <template #header>
                            Spreadsheet Reports
                        </template>
                        <div id="smsku-container" class="py-1">
                            <div class="d-flex w-100 mb-1 pe-4">
                                <label class="form-label w-25">COMMENT ID:</label>
                                <input class="form-control form-control-sm w-75" disabled
                                    :value="selectedComment ? selectedComment.COMMENT_ID : ''"/>
                            </div>
                            <div class="d-flex w-100 mb-1 pe-4">
                                <label class="form-label w-25">EXP DEL DATE:</label>
                                <input class="form-control form-control-sm w-75" type="date" v-model="expDelDate"/>
                            </div>
                            <div class="d-flex mb-1">
                                <button class="btn btn-primary w-50"
                                    :disabled="!selectedComment"
                                    @click="ShowPDFReport('sm-sku-dr-spreadsheet')">SM SKU DR
                                </button>
                                <button class="btn btn-primary w-50 ms-1"
                                    :disabled="!selectedComment"
                                    @click="ShowPDFReport('landmark-sku-dr-spreadsheet')">
                                    Landmark SKU DR
                                </button>
                            </div>
                            <div class="d-flex mb-1">
                                <button class="btn btn-primary w-50"
                                    :disabled="!selectedComment"
                                    @click="ShowPDFReport('rds-sku-dr-spreadsheet')">
                                    Summary Excel
                                </button>
                            </div>
                        </div>
                    </Card>
                    <Card class="w-50 ms-3">
                        <div id="month-container">
                            <table class="table table-bordered table-hover">
                                <tbody>
                                    <tr
                                        v-for="month in months" :key="month"
                                        :class="{ 'bg-primary text-white': month === selectedMonth }"
                                        @click="selectMonth(month)"
                                    >
                                        <td class="cursor-pointer">{{ month }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <FormSelect class="w-100 my-3"
                            label="COMMENT TYPE:" v-model="commentType"
                            :options="commentTypes" id-prefix="CommentType"
                            select-class="form-select-sm w-100"/>
                    </Card>
                </div>
            </div>
        </div>
    </Card>
    <CommentSummaryMarginPopup :is-open="isGeneratingCommentSummary"
        :comment-id="selectedComment?.COMMENT_ID"
        @close="_ => { isGeneratingCommentSummary = false; }"/>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';

import Card from '@/components/utils/Card.vue';
import FormSelect from '@/components/utils/FormSelect.vue';

import { useFlashMessages } from "@/composables/flashmessages";
import scrollToTop from '@/utils/scrollToTop';

import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';
import { FilterMatchMode } from 'primevue/api';
import CommentSummaryMarginPopup from './CommentSummaryMarginPopup.vue';

import axios from 'axios';

const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const currentMonth = currentDate.getMonth() + 1;

const years = ref([]);
const months = ref([]);
const selectedComment = ref(null);
const selectedMonth = ref('');
const commentType = ref('Delivery');
const commentList = ref([]);
const splitType = ref(" KIMTYPE in ('KIMBEL', 'OTHER')");
const drNumber = ref(null);
const expDelDate = ref(null);
const totalRecords = ref();
const params = ref({});
const filters = ref({
    COMMENT_ID: { value: null, matchMode: FilterMatchMode.CONTAINS },
    COMMENT_TEXT: { value: null, matchMode: FilterMatchMode.CONTAINS },
    COMMENT_RPT_DATE: { value: null, matchMode: FilterMatchMode.CONTAINS },
    COMMENT_TIMESTAMP: { value: null, matchMode: FilterMatchMode.CONTAINS },
    LOCATION: { value: null, matchMode: FilterMatchMode.CONTAINS },
    CREATED_BY: { value: null, matchMode: FilterMatchMode.CONTAINS },
});

const { addFlashMessage } = useFlashMessages();

const isGeneratingCommentSummary = ref(false);

const commentTypes = [
    'Initial',
    'Delivery',
    'Pullout',
    'Sales',
    'Reconciliation',
    'New Stock',
    'Return',
    'Replacement'
]

const splitTypes = [
    {
        label: "KIMBEL+KIMCHI",
        value: " "
    },
    {
        label: "KIMBEL ONLY",
        value: " KIMTYPE in ('KIMBEL', 'OTHER')"
    },
    {
        label: "KIMCHI ONLY",
        value: " KIMTYPE in ('KIMCHI')"
    }
]

const smLocation = 'SM';

watch(params, getCommentList, { deep: true });

watch(selectedMonth, () => {
    getCommentList();
})

watch(commentType, () => {
    getCommentList();
})

onMounted(() => {
    generateYearsAndMonths();

    const formattedCurrentMonth = currentMonth < 10 ? `0${currentMonth}` : `${currentMonth}`;
    selectedMonth.value = `${currentYear}/${formattedCurrentMonth}`;
})

onMounted(async () => {
    params.value = {
        first: 0,
        page: 0,
        rows: 10,
        sortField: null,
        sortOrder: null,
        filters: filters.value,

    };
});

const commentRowClass = (data) => {
    return [{'bg-primary text-white': data.COMMENT_ID == selectedComment.value?.COMMENT_ID}];
}

function generateYearsAndMonths() {
    const startYear = 2000;

    for (let year = currentYear; year >= startYear; year--) {
        years.value.push(year);
    }
    for (let year = currentYear; year >= startYear; year--) {
        const endMonth = year === currentYear ? currentMonth : 12;
        for (let month = 12; month >= 1; month--) {
            if (year === currentYear && month > endMonth) {
                continue;
            }
            const formattedMonth = month < 10 ? `0${month}` : `${month}`;
            const yearMonth = `${year}/${formattedMonth}`;
            months.value.push(yearMonth);
        }
    }
}

async function getCommentList() {
    try {
        const response = await axios.get(route('api.comments.index', {
            ...params.value,
            MONTH: selectedMonth.value,
            COMMENT_TYPE: commentType.value,
            COMMENT_STATUS: 'O'
        }))
        commentList.value = response.data.data;
        totalRecords.value = response.data.meta.total;
    } catch (e) {
        console.log(e)
        scrollToTop();
        addFlashMessage('ERROR', `An error occurred while fetching the comment list.
            Please contact your administrator.`);
    }
}

const onPage = (event) => {
    params.value = event;
}

const onFilter = _ => {
    params.value.filters = filters.value;
    params.value.page = 0;
    params.value.first = 0;
}

function selectMonth(month) {
    selectedMonth.value = month;
    selectedComment.value = null;
}

function ShowPDFReport(reportType) {
    if (selectedComment.value?.LOCATION.substr(0, 2) !== smLocation && reportType == 'sm-sku') {
        scrollToTop();
        addFlashMessage('ERROR', 'The selected comment is not located in SM.');
        return;
    }

    window.open(route(`api.reports.inventory.comment-report-generator`, {
        REPORT_TYPE: reportType,
        COMMENT_ID: selectedComment.value?.COMMENT_ID,
        SPLIT_TYPE: splitType.value,
    }), '_blank');
}

function DownloadSpreadsheetReport() {
    if (selectedComment.value?.LOCATION.substr(0, 2) !== smLocation) {
        scrollToTop();
        addFlashMessage('ERROR', 'The selected comment is not located in SM.');
        return;
    }

    window.open(route(`api.reports.inventory.comment-report-generator`, {
        REPORT_TYPE: 'comment-sm-spreadsheet',
        COMMENT_ID: selectedComment.value?.COMMENT_ID,
        DR_NO: drNumber.value,
        EXP_DEL_DATE: expDelDate.value,
    }), '_blank');
}
</script>
