<template>
    <Card :class="class">
        <template #header>
            Duplicate Inventory Check Barcodes (Clean Up)
        </template>
        <DataTable :loading="isTableLoading" lazy :value="duplicateInventoryCheckCounts" :total-records="duplicateInventoryCheckCountsTotalRecords"
            paginator :rows="10" @page="e => { onPage(e); fetchDuplicates(); }"
            v-model:filters="filters" filter-display="row"
            @filter="duplicateInventoryCountsTableOnFilter"
            :pt="{ table: { class: 'table table-bordered table-hover' } }">
            <Column field="location" header="Location" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="inventory_done" header="Inventory Done" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="raw" header="Raw" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="total_count" header="Count" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <template #empty>
                <div class="text-center">
                    No recon locations.
                </div>
            </template>
        </DataTable>
        <template #footer>
            <div class="text-end">
                <button type="button" class="btn btn-outline-danger"
                    :disabled="isDuplicatesDeleting"
                    @click="destroyDuplicateInventoryChecks">
                    <span v-if="isDuplicatesDeleting" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Delete All Duplicate Barcodes
                </button>
            </div>
        </template>
    </Card>
</template>

<script setup>
import { onMounted, ref } from 'vue';

import Card from '@/components/utils/Card.vue';

import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useInventoryCheckCounts } from '@/composables/data/inventoryCheckCounts';
import { useInventoryChecks } from '@/composables/data/inventoryChecks';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

const props = defineProps({
    class: String
});

const emit = defineEmits(['delete-duplicate-success', 'error']);

const { params, filters, onPage, onFilter } = useDataTableParams(['location', 'inventory_done', 'raw', 'total_count']);

const { duplicateInventoryCheckCounts, duplicateInventoryCheckCountsTotalRecords,
    getDuplicateInventoryCheckCounts } = useInventoryCheckCounts();

const { deleteDuplicateInventoryChecks } = useInventoryChecks();

const isTableLoading = ref(false);
const isDuplicatesDeleting = ref(false);

const fetchDuplicatesTimeout = ref(null);

async function fetchDuplicates() {
    duplicateInventoryCheckCounts.value = null;

    isTableLoading.value = true;
    try {
        await getDuplicateInventoryCheckCounts(params.value);
    } catch(e) {
        emit('error', e.message);
    }
    isTableLoading.value = false;
}

function duplicateInventoryCountsTableOnFilter(e) {
    onFilter(e);
    if(fetchDuplicatesTimeout.value)
        clearTimeout(fetchDuplicatesTimeout.value);
    fetchDuplicatesTimeout.value = setTimeout(fetchDuplicates, 300);
}

async function destroyDuplicateInventoryChecks() {
    isDuplicatesDeleting.value = true;
    try {
        const response = await deleteDuplicateInventoryChecks();
        emit('delete-duplicate-success', response.data.message);
        fetchDuplicates();
    } catch(e) {
        emit('error', e.message);
    }
    isDuplicatesDeleting.value = false;
}

onMounted(fetchDuplicates);
</script>
