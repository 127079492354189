import { ref } from "vue";

import axios from 'axios';

import constructFetchError from "./constructFetchError";

export function useUploadedSalesReportFileCollections() {
    const uploadedSalesReportFileCollections = ref(null);
    const totalRecords = ref(null);

    const getUploadedSalesPeriodicReportFileCollections = async (params) => {
        try {
            const getUploadedSalesReportFileCollectionsResponse =
                await axios.get(route('api.uploaded-sales-report-periodic-file-collections.index', params));
                uploadedSalesReportFileCollections.value = getUploadedSalesReportFileCollectionsResponse.data.data;
                totalRecords.value = getUploadedSalesReportFileCollectionsResponse.data.meta?.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching uploaded sales report files.');
        }
    };

    const getUploadedSalesLiquidationReportFileCollections = async (params) => {
        try {
            const getUploadedSalesReportFileCollectionsResponse =
                await axios.get(route('api.uploaded-sales-report-liquidation-file-collections.index', params));
                uploadedSalesReportFileCollections.value = getUploadedSalesReportFileCollectionsResponse.data.data;
                totalRecords.value = getUploadedSalesReportFileCollectionsResponse.data.meta?.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching uploaded sales report files.');
        }
    };

    return { uploadedSalesReportFileCollections, totalRecords,
        getUploadedSalesPeriodicReportFileCollections,
        getUploadedSalesLiquidationReportFileCollections};
}
