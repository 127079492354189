import axios from 'axios';

import constructFetchError from "./constructFetchError";

export function useSMGenericSkus() {
    const putSMGenericSku = async (style, smGenericSku) => {
        try {
            await axios.put(route('api.styles.sm-generic-sku.update', {
                style: style
            }), smGenericSku);
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while saving SM Generic SKU.');
        }
    };
    
    return { putSMGenericSku };
}
