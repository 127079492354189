<template>
    <div class="row g-3">
        <div class="col-md-4">
            <LocationSelectTableCard v-model="location" />
        </div>
        <div class="col-md-8">
            <LocationKeysTableCard :location="location?.LOCATION"
                :refresh-flag="locationKeyTableRefreshFlag"
                @delete-success="message => {
                    addFlashMessage('SUCCESS', message);
                    scrollToTop();
                }"
                @error="message => {
                    addFlashMessage('ERROR', message);
                    scrollToTop();
                }"
                class="mb-3" />
            <InsertLocationKeyFormCard :location="location?.LOCATION"
                @success="message => {
                    locationKeyTableRefreshFlag = !locationKeyTableRefreshFlag;
                    addFlashMessage('SUCCESS', message);
                    scrollToTop();
                }"
                @error="message => {
                    addFlashMessage('ERROR', message);
                    scrollToTop();
                }" />
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';

import { useFlashMessages } from '@/composables/flashmessages';

import LocationSelectTableCard from '@/components/utils/LocationSelectTableCard.vue';
import LocationKeysTableCard from './LocationKeysTableCard.vue';
import InsertLocationKeyFormCard from './InsertLocationKeyFormCard.vue';

import scrollToTop from '@/utils/scrollToTop';

const { addFlashMessage } = useFlashMessages();

const location = ref(null);

const locationKeyTableRefreshFlag = ref(false);
</script>
