import { ref } from "vue";

import constructFetchError from "./constructFetchError";

import axios from 'axios';

export function useInventory() {
    const inventoryRecords = ref(null);
    const inventoryTotalRecords = ref(null);

    const missingBarcodes = ref(null);

    const getInventoryRecords = async (params) => {
        try {
            const getInventoryRecordsResponse = await axios.get(route('api.inventory.index', params));
            inventoryRecords.value = getInventoryRecordsResponse.data.data;
            inventoryTotalRecords.value = getInventoryRecordsResponse.data.meta?.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching inventory records.');
        }
    };

    const getInventoryReportRecords = async (params) => {
        try {
            const getInventoryReportRecordsResponse = await axios.get(route('api.inventory.report.index', params));
            inventoryRecords.value = getInventoryReportRecordsResponse.data.data;
            inventoryTotalRecords.value = getInventoryReportRecordsResponse.data.meta.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching inventory records.');
        }
    };

    const getInventorySales = async (reportparams, params) => {
        try {
            const getInventoryRecordsResponse = await axios.get(route('api.inventory.sales.index', {
                ...reportparams,
                ...params
            }));
            inventoryRecords.value = getInventoryRecordsResponse.data.data;
            inventoryTotalRecords.value = getInventoryRecordsResponse.data.meta.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching inventory records.');
        }
    };

    const getSoldBarcodesInLocationByStyle = async (location, style, params) => {
        try {
            const getSoldBarcodesInLocationByStyleResponse = await axios.get(route('api.inventory.sold-by-location-and-style.index', {
                location: location,
                STYLE: style,
                ...params
            }));
            inventoryRecords.value = getSoldBarcodesInLocationByStyleResponse.data.data;
            inventoryTotalRecords.value = getSoldBarcodesInLocationByStyleResponse.data.meta.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching sold records.');
        }
    };

    const getMissingBarcodesInLocation = async (location, timePeriod, inventoryDone, params) => {
        try {
            const getMissingBarcodesInLocationResponse = await axios.get(route('api.locations.missing-barcodes.index', {
                location: location,
                TIME_PERIOD: timePeriod,
                INVENTORY_DONE: inventoryDone,
                ...params
            }));
            missingBarcodes.value = getMissingBarcodesInLocationResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching missing barcodes.');
        }
    };

    const getMonthlyDiscrepancies = async (location, startDate, endDate) => {
        try {
            const getInventoryRecordsResponse = await axios.get(route('api.inventory.monthly-discrepancy.index', {
                LOCATION: location,
                START_DATE: startDate,
                END_DATE: endDate,
            }))
            inventoryRecords.value = getInventoryRecordsResponse.data.data;
            console.log(getInventoryRecordsResponse.data);
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching inventory records.');
        }
    };

    const updateColor = async (style, date, serial, color) => {
        try {
            const updateColorResponse = await axios.put(route('api.inventory.color.update', {
                STYLE: style,
                DATE: date,
                SERIAL: serial,
            }), { COLOR: color });
            return updateColorResponse;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while updating color of inventory record.');
        }
    };

    const updateSoldDate = async (style, date, serial, seqNo, soldDate) => {
        try {
            const updateColorResponse = await axios.put(route('api.inventory.sold-date.update', {
                STYLE: style,
                DATE: date,
                SERIAL: serial,
                SEQ_NO: seqNo,
            }), { SOLD_DT: soldDate });
            return updateColorResponse;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while updating sold date of inventory record.');
        }
    };

    const updateSaleType = async (style, date, serial, seqNo, saleType) => {
        try {
            const updateSaleTypeResponse = await axios.put(route('api.inventory.sale-type.update', {
                STYLE: style,
                DATE: date,
                SERIAL: serial,
                SEQ_NO: seqNo,
            }), { SALE_TYPE: saleType });
            return updateSaleTypeResponse;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while updating sale type of inventory record.');
        }
    };

    const updatePrice = async (style, date, serial, seqNo, price) => {
        try {
            const updatePriceResponse = await axios.put(route('api.inventory.price.update', {
                STYLE: style,
                DATE: date,
                SERIAL: serial,
                SEQ_NO: seqNo,
            }), { PRICE: price });
            return updatePriceResponse;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while updating price of inventory record.');
        }
    };

    const updateSeller = async (style, date, serial, seqNo, sellerId) => {
        try {
            const updateSellerResponse = await axios.put(route('api.inventory.seller.update', {
                STYLE: style,
                DATE: date,
                SERIAL: serial,
                SEQ_NO: seqNo,
            }), { EMPID: sellerId });
            return updateSellerResponse;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while updating seller of inventory record.');
        }
    };

    const deleteAsSingle = async (style, date, serial, description) => {
        try {
            const deleteAsSingleResponse = await axios.post(route('api.inventory.last-record.deletions.store', {
                STYLE: style,
                DATE: date,
                SERIAL: serial,
            }), { DESCRIPTION: description });
            return deleteAsSingleResponse;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while deleting inventory record.');
        }
    };

    const deleteCurrentRecord = async (style, date, serial, description) => {
        try {
            const deleteCurrentRecordResponse = await axios.post(route('api.inventory.current-record.deletions.store', {
                STYLE: style,
                DATE: date,
                SERIAL: serial,
            }), { DESCRIPTION: description });
            return deleteCurrentRecordResponse;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while deleting inventory record.');
        }
    };

    const deleteCurrentSaleOrReturn = async (style, date, serial) => {
        try {
            const deleteCurrentSaleOrReturnResponse = await axios.post(route('api.inventory.current-sale-or-return.deletions.store', {
                STYLE: style,
                DATE: date,
                SERIAL: serial,
            }));
            return deleteCurrentSaleOrReturnResponse;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while deleting inventory record.');
        }
    };

    return { inventoryRecords, inventoryTotalRecords,
        getInventoryRecords, getSoldBarcodesInLocationByStyle,
        getInventoryReportRecords,
        updateColor, updateSoldDate, updateSaleType,
        updatePrice, updateSeller, deleteAsSingle, deleteCurrentRecord,
        deleteCurrentSaleOrReturn, getInventorySales,
        missingBarcodes, getMissingBarcodesInLocation,
        getMonthlyDiscrepancies };
}
