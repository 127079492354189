import { ref } from "vue";

import axios from 'axios';

import constructFetchError from "./constructFetchError";

export function useVoucherAccountingApprovals() {
    const voucherAccountingApproval = ref(null);

    const getVoucherAccountingApproval = async (voucherId) => {
        try {
            const getVoucherAccountingApprovalResponse = await axios.get(route('api.vouchers.accounting-approval.show', {
                voucher: voucherId
            }));
            voucherAccountingApproval.value = getVoucherAccountingApprovalResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching voucher accounting approval.');
        }
    };

    return { voucherAccountingApproval, getVoucherAccountingApproval };
}
