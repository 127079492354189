<template>
    <div v-if="isOpen" class="pop-up d-flex justify-content-center align-items-center" id="otherSelectUnifiedPopup" tabindex="-1"
        @keydown.enter="onPopupEnter">
        <Card>
            <template #header>
                <h5 class="modal-title">Details</h5>
            </template>
            <FormInput type="date" label="Sold Date" v-model="details.soldDate"
                :required="['Sales', 'Return'].includes(commentType)"
                is-horizontal :disabled="!['Sales', 'Return'].includes(commentType)"
                id-prefix="soldDate" class="mb-3"
                :errors="errors.soldDate" />
            <SelectEmployeeAvailableInLocationOnDateTable v-model="employee"
                :location="employeeLocationFilter"
                :date="details.soldDate"
                class="mb-3" />
            <FormInput type="number" step=".01" label="Price" v-model="details.price"
                @change="_ => { discountPriceBasis = details.price; }"
                :disabled="commentType != 'Sales'"
                is-horizontal id-prefix="price" class="mb-3"
                :errors="errors.price" />
            <FormInput label="Discount" step=".01" v-model="details.discount" disabled
                is-horizontal id-prefix="discount" class="mb-3" />
            <div v-if="details.saleType == 3" class="text-end mb-3">
                <button type="button" class="btn btn-primary"
                    :disabled="!previousLocation.LOCATION.startsWith('SM_')"
                    @click="_ => { details.discount = anyTwoPriceBasis - details.price; details.price = anyTwoPriceBasis; }">
                    Recalculate Discount (for SM)
                </button>
            </div>
            <div v-else-if="details.saleType == 5" class="text-end mb-3">
                <button v-for="discountPercentage in [0.1, 0.2, 0.3, 0.4, 0.5]" class="btn btn-primary me-1"
                    @click="_ => { details.price = parseFloat((discountPriceBasis - discountPriceBasis * discountPercentage).toFixed(2)); }">
                    {{ discountPercentage * 100 }}%
                </button>
            </div>
            <FormSelect label="Sale Type" v-model="details.saleType"
                :options="saleTypes"
                :option-value="saleType => saleType.value"
                :option-to-string="saleType => [saleType.value, saleType.verbose].join(' | ')"
                is-horizontal id-prefix="saleType" class="mb-3" />
            <FormSelect v-if="[4, 6].includes(details.saleType)"
                label="Partner Type" v-model="partnerDetails.barcodeType"
                :options="['BARCODE', 'NOBC']"
                is-horizontal id-prefix="barcodeType" class="mb-3" />
            <div v-if="partnerDetails.barcodeType != null" class="row g-3 mb-3">
                <div class="col-8">
                    <FormInput v-if="partnerDetails.barcodeType == 'BARCODE'"
                        label="Partner Barcode" v-model="partnerDetails.barcode"
                        is-horizontal id-prefix="partnerBarcode"
                        :errors="partnerErrors.barcode" />
                    <FormInput v-if="partnerDetails.barcodeType == 'NOBC'"
                        label="Partner Style" v-model="partnerDetails.style"
                        is-horizontal id-prefix="partnerStyle"
                        :errors="partnerErrors.style" />
                </div>
                <div class="col-4">
                    <button type="button" class="btn btn-sm btn-primary"
                        @click="validatePartner">
                        Check Partner
                    </button>
                </div>
            </div>
            <FormSelect v-if="partnerDetails.barcodeType != null"
                :disabled="partnerDetails.barcodeType == 'BARCODE'"
                label="Partner Color" v-model="partnerDetails.color"
                :options="colors" required
                :errors="partnerErrors.color"
                is-horizontal id-prefix="partnerColor" class="mb-3" />
            <FormInput type="number" step=".01" v-if="partnerDetails.barcodeType != null"
                label="Partner Price" v-model="partnerDetails.price"
                :errors="partnerErrors.price"
                is-horizontal id-prefix="partnerPrice" class="mb-3" />
            <FormInput label="Receipt #" v-model="details.receipt"
                is-horizontal id-prefix="receipt" class="mb-3"
                :errors="errors.receipt" />
            <FormSelect label="Color" v-model="details.color" :disabled="movementType == 'BARCODE'"
                :options="colors" required
                is-horizontal id-prefix="color" class="mb-3"
                :errors="errors.color" />
            <StyleSkuPricesTable :style="details.style"
                :date="details.soldDate" />
            <template #footer>
                <div class="text-end">
                    <button type="button" class="btn btn-outline-secondary me-2"
                        @click="_ => { emit('close'); }">Close</button>
                    <button type="button" class="btn btn-primary"
                        id="otherSelectUnifiedDoneButton"
                        :disabled="isFinishing"
                        @click="finishSpecifyingDetails">
                        <span v-if="isFinishing" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Done
                    </button>
                </div>
            </template>
        </Card>
    </div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";

import { useColors } from "@/composables/data/colors";
import { useBarcodesInventory } from '@/composables/data/barcodesInventory';
import { useEmployees } from '@/composables/data/employees';
import { useStyles } from '@/composables/data/styles';
import { useStylePrices } from '@/composables/data/stylePrices';
import { useSaleTypes } from "@/composables/data/saleTypes";

import Card from "@/components/utils/Card.vue";
import FormInput from "@/components/utils/FormInput.vue";
import FormSelect from "@/components/utils/FormSelect.vue";

import SelectEmployeeAvailableInLocationOnDateTable from './SelectEmployeeAvailableInLocationOnDateTable.vue';
import StyleSkuPricesTable from "./StyleSkuPricesTable.vue";

import moment from "moment";

import focusnext from "@/utils/focusnext";

const { saleTypes } = useSaleTypes();

const { colors, getActiveColors } = useColors();

const { getBarcodeLatestInformation } = useBarcodesInventory();

const { employee: fetchedEmployee, getEmployee } = useEmployees();

const { getStyle } = useStyles();

const { getStylePrices } = useStylePrices();

const props = defineProps({
    barcodeToMove: Object,
    employeeLocationFilter: String,
    commentType: String,
    comment: Object,
    previousLocation: Object,
    itemsToMove: Array,
    movementType: String,
    isOpen: Boolean,
});

const emit = defineEmits(['done', 'done-b1t1', 'error', 'close']);

async function fetchColors() {
    try {
        await getActiveColors();
        colors.value = colors.value.map(color => color.COLOR);
    } catch(e) {
        emit('error', e.message);
    }
}

onMounted(fetchColors);

const errors = ref({});
const partnerErrors = ref({});

const defaultDetails = {
    soldDate: null,
    empid: null,
    price: 0.00,
    discount: 0.00,
    saleType: 1,
    receipt: null,
    color: null
};

const details = ref(Object.assign({}, defaultDetails));
const partnerDetails = ref({
    barcodeType: null,
    barcode: null,
    style: null,
    color: null,
    price: null,
});
const isPartnerValid = ref(false);

const employee = ref(null);

const DEFAULT_EMPLOYEE_ID = 0;
const DEFAULT_EMPLOYEE_NAME = 'UNKNOWN UNKNOWN';

// For preselected options
const globalSoldDate = defineModel('globalSoldDate');
const globalEmployee = defineModel('globalEmployee');

// For sold date validation
const validationSoldDate = defineModel('validationSoldDate');

// For applying any two recalculate button
const anyTwoPriceBasis = ref(null);
// For applying discount buttons (10% to 50% buttons)
const discountPriceBasis = ref(null);

const isFinishing = ref(false);

watch(() => props.isOpen, () => {
    if(props.isOpen)
        setTimeout(_ => {
            if (['Sales', 'Return'].includes(props.commentType))
                focusnext('soldDateFormInput');
            else
                focusnext('otherSelectUnifiedPopup');
        }, 0);
});

watch(() => props.barcodeToMove, async () => {
    details.value = Object.assign({}, defaultDetails);
    if(!props.barcodeToMove) return;
    details.value = {
        ...details.value,
        ...props.barcodeToMove
    };
    partnerDetails.value = {
        barcodeType: null,
        barcode: null,
        style: null,
        color: null,
        price: null,
    };
    anyTwoPriceBasis.value = details.value.price;
    discountPriceBasis.value = details.value.price;
    await getEmployee(props.barcodeToMove.empid);
    employee.value = fetchedEmployee.value;
});

watch(employee, () => {
    details.value.empid = employee.value?.EMPID;
    details.value.employee_name = employee.value ?
        `${employee.value.FNAME} ${employee.value.LNAME}` : null; // For display only
    globalEmployee.value = employee.value;
});

watch(() => details.value.soldDate, async () => {
    if(props.comment.LOCATION != 'SOLD')
        return;

    details.value.price = null;

    if(!details.value.soldDate) return;

    const latestPrice = (await getStylePrices(details.value.style, {
        START_DATE_LTE: details.value.soldDate,
        END_DATE_GTE: details.value.soldDate,
    }))[0];

    if(!latestPrice) {
        errors.value.b1t1BarcodePartner = [`Barcode has no price on that date.`];
        return;
    }

    if(props.previousLocation.PRICE_TYPE == 'S')
        details.value.price = latestPrice.SM_PRICE;
    else
        details.value.price = latestPrice.LM_SALE_PRICE;
    
    anyTwoPriceBasis.value = details.value.price;
    discountPriceBasis.value = details.value.price;
});

watch(() => details.value.saleType, () => {
    details.value.discount = 0;
    partnerDetails.value.barcodeType = null;
});

watch(() => partnerDetails.value.barcodeType, () => {
    partnerDetails.value = {
        barcodeType: partnerDetails.value.barcodeType,
        barcode: null,
        style: null,
        color: null,
        price: null,
    };
});

watch(partnerDetails, _ => { isPartnerValid.value = false; });

async function validatePartner() {
    partnerErrors.value = {};
    isPartnerValid.value = false;

    if(![4, 6].includes(details.value.saleType))
        return;

    if(partnerDetails.value.barcodeType == 'BARCODE') {
        if(!partnerDetails.value.barcode) {
            partnerErrors.value.barcode = ['Please specify the barcode.'];
            return;
        }

        partnerDetails.value.barcode = partnerDetails.value.barcode.toUpperCase();

        // Must not be same as the first barcode
        if(partnerDetails.value.barcode == details.value.barcode) {
            partnerErrors.value.barcode = ['Barcode already scanned. Please scan the partner.'];
            return;
        }

        // Must not be already being moved
        if(props.itemsToMove.map(itemToMove => itemToMove.barcode).includes(partnerDetails.value.barcode)) {
            partnerErrors.value.barcode = ['Barcode already inputted for movement. Please specify another.'];
            return;
        }

        try {
            const lastInventoryRecord = (await getBarcodeLatestInformation(partnerDetails.value.barcode));
            if(!lastInventoryRecord) {
                partnerErrors.value.barcode = ['Partner barcode does not exist.'];
                return;
            } else if(lastInventoryRecord.LOCATION != props.previousLocation.LOCATION) {
                partnerErrors.value.barcode = [`Barcode previous location ${lastInventoryRecord.LOCATION} does not match
                    previous location ${props.previousLocation.LOCATION}.`];
                return;
            }

            partnerDetails.value.style = lastInventoryRecord.STYLE;
            partnerDetails.value.date = lastInventoryRecord.DATE;
            partnerDetails.value.serial = lastInventoryRecord.SERIAL;
            partnerDetails.value.color = lastInventoryRecord.COLOR;
        } catch(e) {
            if(e.status == 422)
                partnerErrors.value.barcode = e.errors.barcode;
            else if(e.status == 400)
                partnerErrors.value.barcode = [e.message];
            return;
        }
    } else if(partnerDetails.value.barcodeType == 'NOBC') {
        try {
            await getStyle(partnerDetails.value.style);
        } catch(e) {
            if(e.status == 404)
                partnerErrors.value.style = ['No STYLE found.'];
            else
                emit('error', e.message);
            return;
        }
    }

    try {
        if(!details.value.soldDate) {
            errors.value.soldDate = ['Sold date is required before checking partner.'];
            return;
        }

        const latestPrice = (await getStylePrices(partnerDetails.value.style, {
            START_DATE_LTE: details.value.soldDate,
            END_DATE_GTE: details.value.soldDate,
        }))[0];

        if(!latestPrice) {
            errors.value.b1t1BarcodePartner = [`Barcode has no price.`];
            return;
        }

        if(props.previousLocation.PRICE_TYPE == 'S')
            partnerDetails.value.price = latestPrice.SM_PRICE;
        else
            partnerDetails.value.price = latestPrice.LM_SALE_PRICE;
    } catch(e) {
        emit('error', e.message);
    }

    isPartnerValid.value = true;
    window.alert("Successfully validated b1t1 partner. Prices have been set. You may proceed.");
}

async function finishSpecifyingDetails() {
    isFinishing.value = true;

    errors.value = {};
    partnerErrors.value = {};

    // Sales validation
    if(props.comment.LOCATION == 'SOLD') {
        // Receipt no
        if((details.value.receipt?.length || 0) != (props.previousLocation.RECEIPT_LEN || 0)) {
            errors.value.receipt = [`Receipt must be length ${props.previousLocation.RECEIPT_LEN || 0}.`];
            isFinishing.value = false;
            return;
        }

        // Price - Required
        if(!details.value.price) {
            errors.value.price = ['Price is required.'];
            isFinishing.value = false;
            return;
        }

        // Sold Date - Required
        if(!details.value.soldDate) {
            errors.value.soldDate = ['Sold date is required.'];
            isFinishing.value = false;
            return;
        }

        else {
            const soldDateObj = moment(details.value.soldDate);
            const soldCommentDateObj = moment(props.comment.COMMENT_RPT_DATE);

            // Same month as comment date
            if(soldDateObj.month() != soldCommentDateObj.month() || soldDateObj.year() != soldCommentDateObj.year()) {
                errors.value.soldDate = [`Sold date must be within month of ${soldCommentDateObj.format('YYYY-MM')}.`];
                isFinishing.value = false;
                return;
            }

            // Check that sold date must be same for same batches
            else if(validationSoldDate.value) {
                const validationSoldDateObj = moment(validationSoldDate.value);

                if(!validationSoldDateObj.isSame(soldDateObj, 'day')) {
                    errors.value.soldDate = [`Sold date must be same as previous entry ${validationSoldDateObj.format('YYYY-MM-DD')}.`];
                    isFinishing.value = false;
                    return;
                }
            }
        }

        if(props.movementType == 'BARCODE') {
            try {
                const lastInventoryRecord = (await getBarcodeLatestInformation(props.barcodeToMove.barcode));
                if(moment(lastInventoryRecord.COMMENT_RPT_DATE).isAfter(details.value.soldDate)) {
                    errors.value.soldDate = [`Sold date must be after previous comment date ${lastInventoryRecord.COMMENT_RPT_DATE}.`];
                    isFinishing.value = false;
                    return;
                }
            } catch(e) {
                emit('error', e.message);
                return;
            }
        }
    }

    // Return validation
    if(props.comment.COMMENT_TEXT.toUpperCase().includes("RETURN")) {
        // Sold Date
        if(!details.value.soldDate) {
            errors.value.soldDate = ['Sold date is required.'];
            isFinishing.value = false;
            return;
        }

        if(props.movementType == 'BARCODE') {
            try {
                const lastInventoryRecord = (await getBarcodeLatestInformation(props.barcodeToMove.barcode));
                if(moment(lastInventoryRecord.SOLD_DT).isAfter(details.value.soldDate)) {
                    errors.value.soldDate = [`Sold date must be after previous sold date ${lastInventoryRecord.SOLD_DT}.`];
                    isFinishing.value = false;
                    return;
                }
            } catch(e) {
                emit('error', e.message);
                return;
            }
        }
    }

    // Color validation - required
    if(!details.value.color) {
        errors.value.color = ['Color is required.'];
        isFinishing.value = false;
        return;
    }

    // Buy 1 Take 1 validation (4 - split, 6 - higher)
    if([4, 6].includes(details.value.saleType)) {
        if(!isPartnerValid.value) {
            window.alert("Partner is not yet validated. Check details and click on" +
                " 'Check Partner' before proceeding.");
            isFinishing.value = false;
            return;
        }

        if(partnerDetails.value.price == null) {
            partnerErrors.value.price = [`Price is required.`];
            isFinishing.value = false;
            return;
        }

        if(partnerDetails.value.color == null) {
            partnerErrors.value.color = [`Color is required.`];
            isFinishing.value = false;
            return;
        }

        if(details.value.saleType == 4 && details.value.price != partnerDetails.value.price) {
            partnerErrors.value.price = [`Price must be same as partner ${details.value.price}.`];
            isFinishing.value = false;
            return;
        }
        
        let finalPriceToUse = details.value.price;
        if(details.value.saleType == 6)
            finalPriceToUse = Math.max(details.value.price, partnerDetails.value.price);

        /**
         * Special cases for price in buy 1 take 1:
         * - if SM, set the price to the bag with higher price
         * - if non-SM, set the price to same as discount
         */
    
        const firstBarcodeDiscount = Math.floor(finalPriceToUse / 2 * 100) / 100;
        const secondBarcodeDiscount = Math.ceil(finalPriceToUse / 2 * 100) / 100;

        let firstBarcodePrice = null;
        let secondBarcodePrice = null;
        if (props.previousLocation.LOCATION.startsWith('SM_')) {
            firstBarcodePrice = finalPriceToUse;
            secondBarcodePrice = finalPriceToUse;
        } else {
            firstBarcodePrice = firstBarcodeDiscount;
            secondBarcodePrice = secondBarcodeDiscount;
        }

        emit('done-b1t1', {
            ...details.value,
            price: firstBarcodePrice,
            discount: firstBarcodeDiscount,
            empid: details.value.empid ?? DEFAULT_EMPLOYEE_ID,
            employee_name: details.value.employee_name ?? DEFAULT_EMPLOYEE_NAME,
        }, {
            ...partnerDetails.value,
            price: secondBarcodePrice,
            currentLocation: props.previousLocation.LOCATION,
            soldDate: details.value.soldDate,
            saleType: details.value.saleType,
            receipt: details.value.receipt,
            discount: secondBarcodeDiscount,
            empid: details.value.empid ?? DEFAULT_EMPLOYEE_ID,
            employee_name: details.value.employee_name ?? DEFAULT_EMPLOYEE_NAME,
        });
    } else {
        emit('done', {
            ...details.value,
            empid: details.value.empid ?? DEFAULT_EMPLOYEE_ID,
            employee_name: details.value.employee_name ?? DEFAULT_EMPLOYEE_NAME,
        });
    }

    globalSoldDate.value = details.value.soldDate;
    validationSoldDate.value = details.value.soldDate; // Next sold dates must be same for the same batch
    
    isFinishing.value = false;
}

function onPopupEnter() {
    focusnext('otherSelectUnifiedDoneButton');
}
</script>

<style scoped>
.pop-up {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: rgb(0, 0, 0, 0.5);
}

.pop-up .card {
    width: 40%;
}

@media (max-width: 1199.98px) {
    .pop-up .card {
        width: 60%;
    }
}

@media (max-width: 991.98px) {
    .pop-up .card {
        width: 75%;
    }
}

@media (max-width: 767.98px) {
    .pop-up .card {
        width: 100%;
    }
}
</style>
