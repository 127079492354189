<template>
    <fieldset>
        <legend>
            <i class="icon ph-bold ph-chart-bar me-2"></i>Daily Sales Report
        </legend>
        <div class="row mb-4">
            <div class="col-xl-7">
                <div class="row mb-3">
                    <div class="col-md-6">
                        <FormInput
                            label="Start Date:"
                            v-model="reportParams.START_DATE"
                            type="date"
                            class="mb-md-1 mb-2"
                            input-class="form-control-sm"
                            id-prefix="startDate"
                            is-horizontal
                            label-class="col-xl-4 col-md-5 label-sm align-items-center"
                            input-container-class="col-xl-8 col-md-7 col-12"
                            :errors="errors.START_DATE"
                        />
                    </div>
                    <div class="col-md-6">
                        <FormInput
                            label="End Date:"
                            v-model="reportParams.END_DATE"
                            type="date"
                            class="mb-md-1 mb-2"
                            input-class="form-control-sm"
                            is-horizontal
                            label-class="col-xl-4 col-md-5 label-sm align-items-center"
                            input-container-class="col-xl-8 col-md-7 col-12"
                            id-prefix="endDate"
                            :errors="errors.END_DATE"
                        />
                    </div>
                </div>
            </div>
            <div class="col-xl-7 col-md-6 sales-report-column">
                <div class="row justify-content-end">
                    <div class="col select-reset-main-container">
                        <FormInput
                            label="Prev. Location:"
                            class="mb-md-1 mb-2"
                            is-horizontal
                            disabled
                            input-class="form-control-sm"
                            label-class="col-xl-4 col-lg-5 label-sm align-items-center"
                            input-container-class="col-xl-8 col-lg-7 col-12"
                            v-model="reportParams.PREV_LOC"
                        />
                    </div>
                    <div class="col-auto text-end mt-auto mb-lg-0 mb-1">
                        <button class="btn btn-primary btn-sm mb-1 me-2 px-2"
                            @click="(_) => { isSelectingLocation = true; }">
                            <i class="icon ph-bold ph-magnifying-glass me-2"></i>Select
                        </button>
                        <button class="btn btn-primary btn-sm mb-1 px-2"
                            @click="(_) => { reportParams.PREV_LOC = null; }">
                            <i class="icon ph-bold ph-arrow-counter-clockwise me-2"></i>Reset
                        </button>
                    </div>
                </div>
                <div class="row justify-content-end">
                    <div class="col select-reset-main-container">
                        <FormInput
                            label="Style:"
                            class="mb-md-1 mb-2"
                            is-horizontal
                            input-class="form-control-sm"
                            label-class="col-xl-4 col-lg-5 label-sm align-items-center"
                            input-container-class="col-xl-8 col-lg-7 col-12"
                            disabled
                            v-model="reportParams.STYLE"
                        />
                    </div>
                    <div class="col-auto text-end mt-auto mb-lg-0 mb-1">
                        <button
                            class="btn btn-primary btn-sm mb-1 me-2 px-2"
                            @click="(_) => { isSelectingStyle = true; }">
                            <i class="icon ph-bold ph-magnifying-glass me-2"></i>Select
                        </button>
                        <button class="btn btn-primary btn-sm mb-1 px-2"
                            @click="(_) => { reportParams.STYLE = null; }">
                            <i class="icon ph-bold ph-arrow-counter-clockwise me-2"></i>Reset
                        </button>
                    </div>
                </div>
                <div class="row justify-content-end">
                    <div class="col select-reset-main-container">
                        <FormInput
                            label="Color:"
                            class="mb-md-1 mb-2"
                            is-horizontal
                            input-class="form-control-sm"
                            label-class="col-xl-4 col-lg-5 label-sm align-items-center"
                            input-container-class="col-xl-8 col-lg-7 col-12"
                            disabled
                            v-model="reportParams.COLOR"
                        />
                    </div>
                    <div class="col-auto text-end mt-auto mb-lg-0 mb-1">
                        <button class="btn btn-primary btn-sm mb-1 me-2 px-2"
                            @click="(_) => { isSelectingColor = true; }">
                            <i class="icon ph-bold ph-magnifying-glass me-2"></i>Select
                        </button>
                        <button class="btn btn-primary btn-sm mb-1 px-2"
                            @click="(_) => { reportParams.COLOR = null; }">
                            <i class="icon ph-bold ph-arrow-counter-clockwise me-2"></i>Reset
                        </button>
                    </div>
                </div>
                <div class="row justify-content-end">
                    <div class="col select-reset-main-container">
                        <FormInput
                            label="Employee ID:"
                            class="mb-md-1 mb-2"
                            is-horizontal
                            input-class="form-control-sm"
                            label-class="col-xl-4 col-lg-5 label-sm align-items-center"
                            input-container-class="col-xl-8 col-lg-7 col-12"
                            disabled
                            v-model="reportParams.EMPID"
                        />
                    </div>
                    <div class="col-auto text-end mt-auto mb-lg-0 mb-1">
                        <button class="btn btn-primary btn-sm mb-1 me-2 px-2"
                            @click="(_) => { isSelectingEmployee = true; }">
                            <i class="icon ph-bold ph-magnifying-glass me-2"></i>Select
                        </button>
                        <button class="btn btn-primary btn-sm mb-1 px-2"
                            @click="(_) => { reportParams.EMPID = null; }">
                            <i class="icon ph-bold ph-arrow-counter-clockwise me-2"></i>Reset
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-xl-5 col-md-6">
                <FormInput
                    label="Price:"
                    class="mb-md-1 mb-2"
                    is-horizontal
                    input-class="form-control-sm"
                    label-class="col-xl-3 col-md-5 label-sm align-items-center"
                    input-container-class="col-xl-9 col-md-7 col-12"
                    v-model="reportParams.PRICE"
                />

                <FormInput
                    label="Receipt No:"
                    class="mb-md-1 mb-2"
                    is-horizontal
                    input-class="form-control-sm"
                    label-class="col-xl-3 col-md-5 label-sm align-items-center"
                    input-container-class="col-xl-9 col-md-7 col-12"
                    v-model="reportParams.RECEIPT_NO"
                />

                <FormInput
                    label="Sale type:"
                    class="mb-md-1 mb-2"
                    is-horizontal
                    input-class="form-control-sm"
                    label-class="col-xl-3 col-md-5 label-sm align-items-center"
                    input-container-class="col-xl-9 col-md-7 col-12"
                    v-model="reportParams.SALE_TYPE"
                />
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <button class="btn btn-primary mb-2 px-2" @click="fetchSalesReport">
                    <i class="icon ph-bold ph-book-open me-2"></i>Display Sales Report
                </button>
            </div>
            <div class="col-12">
                <DataTable
                    :loading="isLoading"
                    lazy
                    :value="inventoryRecords"
                    :total-records="inventoryTotalRecords"
                    paginator
                    :rows="10"
                    @page="(e) => { onPage(e); fetchSalesReport(); }"
                    sort-mode="multiple"
                    @sort="(e) => { onSort(e); fetchSalesReport(); }"
                    :pt="{ table: { class: 'table table-bordered table-hover' } }">
                    <Column field="STYLE" header="STYLE" sortable />
                    <Column field="PREV_LOC" header="PREV_LOC" sortable />
                    <Column field="SOLD_DT" header="SOLD_DT" sortable />
                    <Column field="BARCODE" header="BARCODE" sortable :pt="{ bodyCell: { class: 'barcode' } }"/>
                    <Column field="COLOR" header="COLOR" sortable />
                    <Column field="PRICE" header="PRICE" sortable />
                    <Column field="EMPID" header="EMPID" sortable />
                    <Column field="RECEIPT_NO" header="RECEIPT_NO" sortable />
                    <Column field="SALE_TYPE" header="SALE_TYPE" sortable />
                    <Column field="DISCOUNT" header="DISCOUNT" sortable />
                    <Column field="SELL_TO_ID" header="SELL_TO_ID" sortable />
                    <Column field="CREATED_BY" header="CREATED_BY" sortable />
                    <Column field="SEQ_NO" header="SEQ_NO" sortable />
                    <Column field="COMMENT_ID" header="COMMENT_ID" sortable />
                    <Column field="SELL_TO_TYPE" header="SELL_TO_TYPE" sortable />
                    <template #loading>
                        <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        Loading report...
                    </template>
                    <template #empty>
                        <div class="text-center py-2">
                            <i class="icon ph-bold ph-database me-2"></i>No data.
                        </div>
                    </template>
                </DataTable>
            </div>
        </div>
    </fieldset>

    <SelectLocation
        :is-open="isSelectingLocation"
        @select="handleSelectLocation"
        @close="(_) => { isSelectingLocation = false; }"
    />
    <SelectStyle
        :is-open="isSelectingStyle"
        @select="handleSelectStyle"
        @close="(_) => { isSelectingStyle = false; }"
    />
    <SelectColor
        :is-open="isSelectingColor"
        @select="handleSelectColor"
        @close="(_) => { isSelectingColor = false; }"
    />
    <SelectEmployee
        :is-open="isSelectingEmployee"
        :onClose="(_) => { isSelectingEmployee = false; }"
        :onSelectEmployee="handleSelectEmployee"
    />
</template>

<script setup>
import { ref } from "vue";
import FormInput from "@/components/utils/FormInput.vue";
import SelectLocation from "@/components/utils/popups/SelectLocationPopup.vue";
import SelectColor from "@/components/utils/SelectColor";
import SelectStyle from "@/components/utils/style/SelectStyle";
import SelectEmployee from "@/components/utils/SelectEmployee.vue";
import handleFetchErrors from "@/utils/handleFetchErrors";
import scrollToTop from "@/utils/scrollToTop";
import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useInventory } from "@/composables/data/inventory";
import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useToastsStore } from "@/stores/toasts";
import Column from "primevue/column";
import DataTable from "primevue/datatable";

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const errors = ref({});
const isSelectingLocation = ref(false);
const isSelectingStyle = ref(false);
const isSelectingColor = ref(false);
const isSelectingEmployee = ref(false);

const { params, onPage, onSort } = useDataTableParams([
    "PREV_LOC",
    "STYLE",
    "PRICE",
    "EMPID",
    "RECEIPT_NO",
    "SALE_TYPE",
    "COLOR",
]);

const { inventoryRecords, inventoryTotalRecords, getInventorySales } =
    useInventory();

const reportParams = ref({
    START_DATE: null,
    END_DATE: null,
    PREV_LOC: null,
    STYLE: null,
    COLOR: null,
    EMPID: null,
    PRICE: null,
    RECEIPT_NO: null,
    SALE_TYPE: null,
});

function handleSelectLocation(selectedLocation) {
    reportParams.value.PREV_LOC = selectedLocation.LOCATION;
    isSelectingLocation.value = false;
}

function handleSelectColor(selectedColor) {
    reportParams.value.COLOR = selectedColor.COLOR;
    isSelectingColor.value = false;
}

function handleSelectStyle(selectedStyle) {
    reportParams.value.STYLE = selectedStyle.STYLE;
    isSelectingStyle.value = false;
}

function handleSelectEmployee(selectedEmployee) {
    reportParams.value.EMPID = selectedEmployee.EMPID;
    isSelectingEmployee.value = false;
}

async function fetchSalesReport() {
    errors.value = {};
    inventoryRecords.value = null;

    loadingFlags.add('fetchSalesReport');
    try {
        await getInventorySales(reportParams.value, params.value);
    } catch (e) {
        errors.value = e.errors ?? {};
        toasts.add('ERROR', 'Error', e.message);
    }
    loadingFlags.delete('fetchSalesReport');
}
</script>
