<template>
    <form @submit.prevent="createDetail">
        <Card>
            <template #header>
                Create or Update Detail
            </template>
            <div class="row g-3 mb-1">
                <div class="col-6">
                    <FormInput type="text" v-model="sampleDetail.MATERIAL"
                        label="Material" id-prefix="material" disabled
                        :errors="errors.MATERIAL"
                        @keydown-enter="focusnext('colorFormInput')" />
                </div>
                <div class="col-6">
                    <FormInput type="text" v-model="sampleDetail.COLOR"
                        label="Color" id-prefix="color" disabled
                        :errors="errors.COLOR"
                        @keydown-enter="focusnext('selectMaterialAndColorButton')" />
                </div>
            </div>
            <div class="text-end mb-3">
                <button type="button" class="btn btn-primary"
                    id="selectMaterialAndColorButton"
                    @click="_ => { isSelectingMaterial = true; }"
                    @keydown.enter.prevent="focusnext('detailTotalQuantityFormInput')">
                    Select
                </button>
            </div>
            <FormInput type="text" v-model="material.UNIT"
                label="Unit of Measure" id-prefix="unitOfMeasure" class="mb-3"
                disabled />
            <FormInput type="number" step=".01" v-model="sampleDetail.QTY"
                label="Quantity" id-prefix="quantity" class="mb-3"
                disabled
                :errors="errors.QTY" />
            <FormInput type="number" step=".01" v-model="totalQuantity"
                label="Total Quantity" id-prefix="detailTotalQuantity" class="mb-3"
                @keyup="totalQuantityOnChange" @change="totalQuantityOnChange"
                @keydown-enter="_ => {
                    if(!sampleDetail.id) focusnext('insertSampleDetailButton');
                    else focusnext('updateSampleDetailButton');
                }" />
            <FormInput type="number" step=".01" v-model="material.PRICE"
                label="Original Unit Price" id-prefix="originalUnitPrice" class="mb-3"
                disabled />
            <FormInput type="number" step=".01" v-model="totalPrice"
                label="Total Price" id-prefix="totalPrice"
                disabled />
            <template #footer>
                <button type="button" class="btn btn-outline-secondary me-2"
                    @click="reset">
                    Reset
                </button>
                <button type="submit" class="btn btn-primary me-2"
                    id="insertSampleDetailButton"
                    :disabled="sampleDetail.id">
                    Insert
                </button>
                <button type="button" class="btn btn-primary me-2"
                    id="updateSampleDetailButton"
                    :disabled="!sampleDetail.id"
                    @click="updateDetail">
                    Update
                </button>
            </template>
        </Card>
    </form>

    <MaterialSelectDialog :is-open="isSelectingMaterial"
        @select="selectedMaterial => {
            material = selectedMaterial;
            sampleDetail.MATERIAL = selectedMaterial.MATERIAL;
            sampleDetail.COLOR = selectedMaterial.COLOR;
            isSelectingMaterial = false;
        }"
        @close="_ => { isSelectingMaterial = false; }" />
</template>

<script setup>
import { computed, ref, watch } from 'vue';

import { useFlashMessages } from '@/composables/flashmessages';
import { useSampleDetails } from '@/composables/data/sampleDetails';

import MaterialSelectDialog from './MaterialSelectDialog.vue';

import Card from '@/components/utils/Card.vue';
import FormInput from '@/components/utils/FormInput.vue';

import focusnext from '@/utils/focusnext';
import scrollToTop from '@/utils/scrollToTop';

const props = defineProps({
    sampleId: Number,
    sampleQuantity: Number,
    selectedSampleDetail: Object,
});

const emit = defineEmits('create-or-update');

const { addFlashMessage } = useFlashMessages();

const { postSampleDetail, putSampleDetail } = useSampleDetails();

const errors = ref({});

const sampleDetail = ref({
    id: null,
    MATERIAL: null,
    COLOR: null,
    QTY: null,
});

const material = ref({
    id: null,
    MATERIAL: null,
    COLOR: null,
    UNIT: null,
    PRICE: null,
});

const totalQuantity = ref(null);
const totalPrice = computed(() => (totalQuantity.value ?? 0) * (material.value.PRICE ?? 0));

const isSelectingMaterial = ref(false);

watch(() => props.sampleQuantity, () => {
    totalQuantity.value = (sampleDetail.value.QTY ?? 0) * (props.sampleQuantity ?? 0);
});

function totalQuantityOnChange() {
    sampleDetail.value.QTY = (totalQuantity.value ?? 0) / (props.sampleQuantity ?? 0);
}

function reset() {
    sampleDetail.value = {
        id: null,
        MATERIAL: null,
        COLOR: null,
        QTY: null,
    };
    material.value = {
        id: null,
        MATERIAL: null,
        COLOR: null,
        UNIT: null,
        PRICE: null,
    };
    totalQuantity.value = null;
    errors.value = {};
}

async function createDetail() {
    errors.value = {};
    try {
        sampleDetail.value = await postSampleDetail(props.sampleId, sampleDetail.value);
        addFlashMessage('SUCCESS', 'Successfully saved sample detail.');
        emit('create-or-update');
    } catch(e) {
        addFlashMessage('ERROR', e.message);
        errors.value = e.errors;
    }
    scrollToTop();
}

watch(() => props.selectedSampleDetail, () => {
    material.value = props.selectedSampleDetail?.material;
    sampleDetail.value = props.selectedSampleDetail;
    totalQuantity.value = (sampleDetail.value.QTY ?? 0) * (props.sampleQuantity ?? 0);
});

async function updateDetail() {
    errors.value = {};
    try {
        sampleDetail.value = await putSampleDetail(props.sampleId, sampleDetail.value.id, sampleDetail.value);
        addFlashMessage('SUCCESS', 'Successfully updated sample detail.');
        emit('create-or-update');
    } catch(e) {
        addFlashMessage('ERROR', e.message);
        errors.value = e.errors;
    }
    scrollToTop();
}
</script>
