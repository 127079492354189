<template>
    <Card>
        <template #header>
            Sales Report
        </template>
        <DataTable :value="perDaySales" paginator :rows="10"
            :pt="{ table: { class: 'table table-bordered table-hover' } }">
            <Column field="date" header="Day" />
            <Column field="count" header="Qty." />
            <Column field="price" header="Price" />
            <template #empty>
                <div class="text-center">No sales report.</div>
            </template>
        </DataTable>
        <template #footer>
            <div class="text-end">
                <button type="button" class="btn btn-primary"
                    @click="fetchPerDaySales">
                    Refresh
                </button>
            </div>
        </template>
    </Card>
</template>

<script setup>
import { ref, watch } from 'vue';

import Card from '@/components/utils/Card.vue';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';

import handleFetchErrors from '@/utils/handleFetchErrors';

const props = defineProps({
    previousLocation: Object,
    comment: Object
});

const perDaySales = ref(null);

async function fetchPerDaySales() {
    perDaySales.value = null;
    if (!props.previousLocation || !props.comment)
        return;

    try {
        const perDaySalesResponse = await axios.get(route('api.inventory.daily-sales', {
            COMMENT_ID: props.comment.COMMENT_ID,
            PREV_LOC: props.previousLocation.LOCATION
        }));
        perDaySales.value = perDaySalesResponse.data.data.map(daySales => ({
            ...daySales,
            price: daySales.price.toFixed(2)
        }));
    } catch(e) {
        console.log(e);
        handleFetchErrors(e, 'Error while fetching daily sales.');
    }
}

watch(() => [props.previousLocation, props.comment], () => {
    perDaySales.value = null;
});
</script>
