<template>
    <div class="row g-3">
        <div class="col-md-6">
            <Card>
                <template #header>
                    Select a Location
                </template>
                <LocationTable v-model="location"
                    @error="message => {
                        addFlashMessage('ERROR', message);
                        scrollToTop();
                    }" />
            </Card>
        </div>
        <div class="col-md-6">
            <LocationChargesTableCard :location="location?.LOCATION"
                v-model="selectedLocationCharge"
                :refresh-flag="locationChargesTableRefreshFlag"
                class="mb-3" />
            <ModifyLocationChargeFormCard :location="location?.LOCATION"
                :location-charge="selectedLocationCharge"
                @success="message => {
                    locationChargesTableRefreshFlag = !locationChargesTableRefreshFlag;
                    addFlashMessage('SUCCESS', message);
                    scrollToTop();
                }"
                @warning="message => {
                    addFlashMessage('WARNING', message);
                    scrollToTop();
                }"
                @error="message => {
                    addFlashMessage('ERROR', message);
                    scrollToTop();
                }"
                @reset="_ => {
                    selectedLocationCharge = null;
                }" />
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';

import { useFlashMessages } from '@/composables/flashmessages';

import Card from '@/components/utils/Card';
import LocationTable from '@/components/utils/tables/LocationTable';

import LocationChargesTableCard from './LocationChargesTableCard.vue';
import ModifyLocationChargeFormCard from './ModifyLocationChargeFormCard.vue';

import scrollToTop from '@/utils/scrollToTop';

const { addFlashMessage } = useFlashMessages();

const location = ref(null);
const selectedLocationCharge = ref(null);

const locationChargesTableRefreshFlag = ref(false);
</script>
