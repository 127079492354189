<template>
    <Card>
        <template #header>
            Resolved Barcodes
        </template>
        <DataTable
            lazy
            :value="reconMainResolvedItems"
            :total-records="totalRecords"
            paginator
            :rows="10"
            @page="e => { onPage(e); fetchReconMainResolvedItems(); }"
            filter-display="row"
            v-model:filters="filters"
            @filter="tableOnFilter"
            sort-mode="multiple"
            @sort="e => { onSort(e); fetchReconMainResolvedItems(); }"
            selection-mode="single"
            v-model:selection="selectedReconItemResolved"
            :pt="{ table: { class: 'table table-bordered table-hover' }}">
            <Column field="RECON_DATE" header="Recon Date" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="STYLE" header="Style" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="DATE" header="Date" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="SERIAL" header="Serial" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="COLOR" header="Color" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <template #footer>
                {{ totalRecords ?? 0 }} total barcodes.
            </template>
            <template #empty>
                <div class="text-center">
                    No data.
                </div>
            </template>
        </DataTable>
        <template v-if="$slots.footer" #footer>
            <slot name="footer"></slot>
        </template>
    </Card>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';

import { useLoadingFlagsStore } from '@/stores/loadingFlags';
import { useToastsStore } from '@/stores/toasts';
import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useReconMainResolvedItems } from '@/composables/data/reconMainResolvedItems';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

import Card from '@/components/utils/Card.vue';

const props = defineProps({
    refreshFlag: Boolean,
});

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const { params, filters, onPage, onFilter, onSort } = useDataTableParams([
    'RECON_DATE',
    'STYLE',
    'DATE',
    'SERIAL',
    'COLOR',
]);

const {
    reconMainResolvedItems,
    totalRecords,
    getReconMainResolvedItems,
} = useReconMainResolvedItems();

const fetchTimeout = ref(null);

const selectedReconItemResolved = defineModel();

async function fetchReconMainResolvedItems() {
    reconMainResolvedItems.value = null;

    loadingFlags.add("fetchReconMainResolvedItems");
    try {
        await getReconMainResolvedItems(params.value);
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("fetchReconMainResolvedItems");
}

function tableOnFilter(e) {
    onFilter(e);
    if (fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(fetchReconMainResolvedItems, 300);
}

onMounted(fetchReconMainResolvedItems);

watch(() => props.refreshFlag, fetchReconMainResolvedItems);
</script>
