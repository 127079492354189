<style scoped>
/* general styling */
.table-container {
    overflow: auto;
}

section h5 {
    margin-bottom: 1rem;
    font-weight: bold;
}

input,
input:focus,
select,
select:focus {
    background-color: white;
}

.loan-id-label {
    font-size: 0.8em;
    text-align: right;
}

.label,
.label {
    font-size: small;
}

table {
    position: relative;
    margin-bottom: 0 !important;
}

thead {
    top: 0;
    position: sticky;
    background-color: white;
}

table td {
    cursor: pointer;
}

.employee-filter div section {
    height: 300px;
    display: flex;
    flex-direction: column;
    overflow: auto;
}
.employee-hours-and-leaves div section {
    height: 275px;
    display: flex;
    flex-direction: column;
    overflow: auto;
}
</style>

<template>
    <div class="row g-3 mb-3">
        <div class="col-md-8">
            <div class="row g-3 mb-3 employee-filter">
                <div class="col-md-6">
                    <Card>
                        <template #header>
                            Open Time Periods
                        </template>
                        <TimePeriodTable v-model="selectedTimePeriod"
                            :refresh-flag="timePeriodsTableRefreshFlag"
                            :filters="{
                                STATUS: {
                                    value: 'O',
                                    matchMode: 'equals',
                                },
                                PAYTYPE: {
                                    value: ['WEEKLY', 'BIMONTHLY'],
                                    matchMode: 'in',
                                }
                            }"
                            @error="message => {
                                addFlashMessage('ERROR', message);
                                scrollToTop();
                            }" />
                    </Card>
                </div>
                <div class="col-md-6">
                    <EmployeeSelectionTableCard v-model="selectedEmployee"
                        :time-period="selectedTimePeriod?.TIME_PERIOD"
                        :refresh-flag="employeesTableRefreshFlag"
                        @error="message => {
                            addFlashMessage('ERROR', message);
                            scrollToTop();
                        }" />
                </div>
            </div>
            <div class="row g-3 employee-hours-and-leaves">
                <div class="col-md-8">
                    <Card class="h-100">
                        <template #header>
                            Employee Hours
                        </template>
                        <div class="table-container">
                            <table class="table table-bordered table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th>Jono</th>
                                        <th>Style</th>
                                        <th>Rate</th>
                                        <th>Hours</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="hoursOnJoborder in allHoursPerJoborder">
                                        <td>{{ hoursOnJoborder.JONO }}</td>
                                        <td>{{ hoursOnJoborder.STYLE }}</td>
                                        <td>{{ hoursOnJoborder.RATE }}</td>
                                        <td>{{ hoursOnJoborder.HOURS.toFixed(2) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Card>
                </div>
                <div class="col-md-4">
                    <Card>
                        <template #header>
                            Leaves Taken This Year
                        </template>
                        <div class="row mb-2">
                            <label for="incentiveSalesHoursDisplay" class="col-sm-5 col-form-label">
                                Incentive (Sales)
                            </label>
                            <div class="col-sm-7">
                                <input id="incentiveSalesHoursDisplay" type="text"
                                    class="col form-control form-control-sm" :value="incentiveSalesHours"
                                    disabled>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <label for="incentiveRegularHoursDisplay" class="col-sm-5 col-form-label">
                                Incentive (Reg)
                            </label>
                            <div class="col-sm-7">
                                <input id="incentiveRegularHoursDisplay" type="text"
                                    class="col form-control form-control-sm" :value="incentiveRegularHours"
                                    disabled>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <label for="absentHoursDisplay" class="col-sm-5 col-form-label">Absent</label>
                            <div class="col-sm-7">
                                <input id="absentHoursDisplay" type="text"
                                    class="col form-control form-control-sm" :value="absentHours" disabled>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <label for="suspendedHoursDisplay" class="col-sm-5 col-form-label">Suspended</label>
                            <div class="col-sm-7">
                                <input id="suspendedHoursDisplay" type="text"
                                    class="col form-control form-control-sm" :value="suspendedHours" disabled>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <Card>
                <template #header>
                    Employee Share Deficit
                </template>
                <div class="row mb-2">
                    <label for="paytypeDisplay" class="col-sm-5 col-form-label">Pay Type</label>
                    <div class="col-sm-7">
                        <input id="paytypeDisplay" type="text" class="col form-control form-control-sm"
                            :value="selectedTimePeriod?.PAYTYPE" disabled>
                    </div>
                </div>
                <div class="row mb-2">
                    <label for="startDateDisplay" class="col-sm-5 col-form-label">Start Date</label>
                    <div class="col-sm-7">
                        <input id="startDateDisplay" type="date" class="col form-control form-control-sm"
                            :value="selectedTimePeriod?.START_DATE" disabled>
                    </div>
                </div>
                <div class="row mb-2">
                    <label for="endDateDisplay" class="col-sm-5 col-form-label">End Date</label>
                    <div class="col-sm-7">
                        <input id="endDateDisplay" type="date" class="col form-control form-control-sm"
                            :value="selectedTimePeriod?.END_DATE" disabled>
                    </div>
                </div>
                <div class="row mb-2">
                    <label for="daysAbsentDisplay" class="col-sm-5 col-form-label">Days Absent</label>
                    <div class="col-sm-7">
                        <input id="daysAbsentDisplay" type="text" class="col form-control form-control-sm"
                            :value="payslip?.ABSENT_DAYS" disabled>
                    </div>
                </div>
                <div class="row mb-2">
                    <label for="actSSSEmpDisplay" class="col-sm-5 col-form-label">ACT SSS EMP</label>
                    <div class="col-sm-7">
                        <input id="actSSSEmpDisplay" type="text" class="col form-control form-control-sm"
                            :value="payslip?.RATE_OBJ.SSS_CONTRIBUTION_EMP.toFixed(2)" disabled>
                    </div>
                </div>
                <div class="row mb-2">
                    <label for="actPHEmpDisplay" class="col-sm-5 col-form-label">ACT PH EMP</label>
                    <div class="col-sm-7">
                        <input id="actPHEmpDisplay" type="text" class="col form-control form-control-sm"
                            :value="payslip?.RATE_OBJ.PH_CONTRIBUTION.toFixed(2)" disabled>
                    </div>
                </div>
                <div class="row mb-2">
                    <label for="actPIEmpDisplay" class="col-sm-5 col-form-label">ACT PI EMP</label>
                    <div class="col-sm-7">
                        <input id="actPIEmpDisplay" type="text" class="col form-control form-control-sm"
                            :value="payslip?.RATE_OBJ.PAGIBIG_CONTRIBUTION_EMP.toFixed(2)" disabled>
                    </div>
                </div>
                <div class="row mb-2">
                    <label for="calculatedSalaryDisplay" class="col-sm-5 col-form-label">CALC SALARY</label>
                    <div class="col-sm-7">
                        <input id="calculatedSalaryDisplay" type="text" class="col form-control form-control-sm"
                            :value="payslip?.CALCULATED_SALARY.toFixed(2)" disabled>
                    </div>
                </div>
                <div class="row mb-2">
                    <label for="calculatedSSSEmpDisplay" class="col-sm-5 col-form-label">CALC SSS EMP</label>
                    <div class="col-sm-7">
                        <input id="calculatedSSSEmpDisplay" type="text" class="col form-control form-control-sm"
                            :value="payslip?.CALCULATED_SSS_EMP.toFixed(2)" disabled>
                    </div>
                </div>
                <div class="row mb-2">
                    <label for="calculatedPHEmpDisplay" class="col-sm-5 col-form-label">CALC PH EMP</label>
                    <div class="col-sm-7">
                        <input id="calculatedPHEmpDisplay" type="text" class="col form-control form-control-sm"
                            :value="payslip?.CALCULATED_PH_EMP.toFixed(2)" disabled>
                    </div>
                </div>
                <div class="row mb-2">
                    <label for="calculatedPIEmpDisplay" class="col-sm-5 col-form-label">CALC PI EMP</label>
                    <div class="col-sm-7">
                        <input id="calculatedPIEmpDisplay" type="text" class="col form-control form-control-sm"
                            :value="payslip?.CALCULATED_PI_EMP.toFixed(2)" disabled>
                    </div>
                </div>
            </Card>
        </div>
    </div>
    <OpenLoansTableCard :employee="selectedEmployee"
        :refresh-flag="loansRefreshFlag"
        class="mb-3"/>
    <div class="row g-3">
        <div class="col-md-5">
            <Card class="mb-3">
                <template #header>
                    Add
                </template>
                <div class="row mb-2">
                    <label for="honorariumDisplay" class="col-sm-5 col-form-label">Honorarium</label>
                    <div class="col-sm-7">
                        <input ids="honorariumDisplay" type="text" class="col form-control form-control-sm"
                            :title="`Actual is ${payslip?.HONORARIUM ?? 0}`"
                            :value="payslip?.HONORARIUM.toFixed(2)" disabled>
                    </div>
                </div>
                <div class="row mb-2">
                    <label for="incentiveRegularAddDisplay" class="col-sm-5 col-form-label">Incentive (Reg)</label>
                    <div class="col-sm-7">
                        <input id="incentiveRegularAddDisplay" type="text" class="col form-control form-control-sm"
                            :title="`Actual is ${payslip?.INCENTIVE_REG ?? 0}`"
                            :value="payslip?.INCENTIVE_REG.toFixed(2)" disabled>
                    </div>
                </div>
                <div class="row mb-2">
                    <label for="incentiveSalesAddDisplay" class="col-sm-5 col-form-label">Incentive (Sales)</label>
                    <div class="col-sm-7">
                        <input id="incentiveSalesAddDisplay" type="text" class="col form-control form-control-sm"
                            :title="`Actual is ${payslip?.INCENTIVE_SALES ?? 0}`"
                            :value="payslip?.INCENTIVE_SALES.toFixed(2)" disabled>
                    </div>
                </div>
                <div class="row mb-2">
                    <label for="regularSalaryDisplay" class="col-sm-5 col-form-label">Regular Salary</label>
                    <div class="col-sm-7">
                        <input id="regularSalaryDisplay" type="text" class="col form-control form-control-sm"
                            :title="`Actual is ${payslip?.REGULAR_SALARY ?? 0}`"
                            :value="payslip?.REGULAR_SALARY.toFixed(2)" disabled>
                    </div>
                </div>
                <div class="row mb-2">
                    <label for="COLADisplay" class="col-sm-5 col-form-label">COLA</label>
                    <div class="col-sm-7">
                        <input id="COLADisplay" type="text" class="col form-control form-control-sm"
                            :title="`Actual is ${payslip?.COLA ?? 0}`"
                            :value="payslip?.COLA.toFixed(2)" disabled>
                    </div>
                </div>
                <div class="row mb-2">
                    <label for="overtimeSalaryDisplay" class="col-sm-5 col-form-label">Overtime Salary</label>
                    <div class="col-sm-7">
                        <input id="overtimeSalaryDisplay" type="text" class="col form-control form-control-sm"
                            :title="`Actual is ${payslip?.OVERTIME_SALARY ?? 0}`"
                            :value="payslip?.OVERTIME_SALARY.toFixed(2)" disabled>
                    </div>
                </div>
                <div class="row mb-2">
                    <label for="extraSalaryDisplay" class="col-sm-5 col-form-label">Extra Salary</label>
                    <div class="col-sm-7">
                        <input id="extraSalaryDisplay" type="text" class="col form-control form-control-sm"
                            :title="`Actual is ${payslip?.EXTRA_SALARY ?? 0}`"
                            :value="payslip?.EXTRA_SALARY.toFixed(2)" disabled>
                    </div>
                </div>
                <div class="row mb-2">
                    <label for="disbursementsDisplay" class="col-sm-5 col-form-label">Disbursements</label>
                    <div class="col-sm-7">
                        <input id="disbursementsDisplay" type="text" class="col form-control form-control-sm"
                            :title="`Actual is ${payslip?.DISBURSEMENT ?? 0}`"
                            :value="payslip?.DISBURSEMENT.toFixed(2)" disabled>
                    </div>
                </div>
            </Card>
            <Card>
                <template #header>
                    Final Checkout Amount
                </template>
                <div class="row mb-2">
                    <label for="CAOffsetDisplay" class="col-sm-5 col-form-label">CA Offset</label>
                    <div class="col-sm-7">
                        <input id="CAOffsetDisplay" type="text" class="col form-control form-control-sm"
                            :title="`Actual is ${caOffset ?? 0}`"
                            :value="caOffset?.toFixed(2)" disabled>
                    </div>
                </div>
                <div class="row mb-2">
                    <label for="totalAddDisplay" class="col-sm-5 col-form-label">Gross Pay</label>
                    <div class="col-sm-7">
                        <input id="totalAddDisplay" type="text" class="col form-control form-control-sm"
                            :title="`Actual is ${grossPay ?? 0}`"
                            :value="grossPay?.toFixed(2)" disabled>
                    </div>
                </div>
                <div class="row mb-2">
                    <label for="missedContributionsDisplay" class="col-sm-5 col-form-label">Missed Contributions</label>
                    <div class="col-sm-7">
                        <input id="missedContributionsDisplay" type="text" class="col form-control form-control-sm"
                            :title="`Actual is ${payslip?.MISSED_CONTRIB ?? 0}`"
                            :value="payslip?.MISSED_CONTRIB.toFixed(2)" disabled>
                    </div>
                </div>
                <div class="row mb-2">
                    <label for="totalDeductDisplay" class="col-sm-5 col-form-label">Total Deduct</label>
                    <div class="col-sm-7">
                        <input id="totalDeductDisplay" type="text" class="col form-control form-control-sm"
                            :title="`Actual is ${totalDeduct ?? 0}`"
                            :value="totalDeduct?.toFixed(2)" disabled>
                    </div>
                </div>
                <div class="row mb-2">
                    <label for="finalDisplay" class="col-sm-5 col-form-label"><strong>FINAL</strong></label>
                    <div class="col-sm-7">
                        <input id="finalDisplay" type="text" class="col form-control form-control-sm"
                            :title="`Actual is ${finalCheckoutAmount ?? 0}`"
                            :value="finalCheckoutAmount?.toFixed(2)" disabled>
                    </div>
                </div>
            </Card>
        </div>
        <div class="col-md-7">
            <Card class="sub mb-3">
                <template #header>
                    Subtract
                </template>
                <div class="subtract-section-multiple-input-container">
                    <div class="mb-3">
                        <div class="row">
                            <div class="col-2">
                                C/A
                            </div>
                            <div class="col row" v-for="payment, index in deductionInputs.cashAdvanceLoanPayments">
                                <div class="col-3">
                                    <label :for="'caLoanPayment' + index" class="form-label loan-id-label">
                                        {{ payment.LOANID }}
                                    </label>
                                </div>
                                <div class="col-9">
                                    <input type="number" step=".01" v-model="payment.PAYMENT"
                                        :id="'caLoanPayment' + index" class="form-control form-control-sm"
                                        :title="`Actual is ${actualDeductions.cashAdvanceLoanPayments[index].PAYMENT ?? 0}`"
                                        @keyup="_ => { actualDeductions.cashAdvanceLoanPayments[index].PAYMENT = payment.PAYMENT; }"
                                        @change="_ => { actualDeductions.cashAdvanceLoanPayments[index].PAYMENT = payment.PAYMENT; }"
                                        @keydown.enter.prevent="e => {
                                            if(index == deductionInputs.cashAdvanceLoanPayments.length - 1)
                                                focusNextInput('itemLoanPayment0');
                                            else
                                                focusNextInput('caLoanPayment' + (index + 1));
                                        }"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <div class="row">
                            <div class="col-2">
                                Items
                            </div>
                            <div class="col row" v-for="payment, index in deductionInputs.itemLoanPayments">
                                <div class="col-3">
                                    <label :for="'itemLoanPayment' + index" class="form-label loan-id-label">
                                        {{ payment.LOANID }}
                                    </label>
                                </div>
                                <div class="col-9">
                                    <input type="number" step=".01" v-model="payment.PAYMENT"
                                        :id="'itemLoanPayment' + index" class="form-control form-control-sm"
                                        :title="`Actual is ${actualDeductions.itemLoanPayments[index].PAYMENT ?? 0}`"
                                        @keyup="_ => { actualDeductions.itemLoanPayments[index].PAYMENT = payment.PAYMENT; }"
                                        @change="_ => { actualDeductions.itemLoanPayments[index].PAYMENT = payment.PAYMENT; }"
                                        @keydown.enter.prevent="e => {
                                            if(index == deductionInputs.itemLoanPayments.length - 1)
                                                focusNextInput('missingLoanPayment0');
                                            else
                                                focusNextInput('itemLoanPayment' + (index + 1));
                                        }"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <div class="row">
                            <div class="col-2">
                                Missing
                            </div>
                            <div class="col row" v-for="payment, index in deductionInputs.missingLoanPayments">
                                <div class="col-3">
                                    <label :for="'missingLoanPayment' + index" class="form-label loan-id-label">
                                        {{ payment.LOANID }}
                                    </label>
                                </div>
                                <div class="col-9">
                                    <input type="number" step=".01" v-model="payment.PAYMENT"
                                        :id="'missingLoanPayment' + index" class="form-control form-control-sm"
                                        :title="`Actual is ${actualDeductions.missingLoanPayments[index].PAYMENT ?? 0}`"
                                        @keyup="_ => { actualDeductions.missingLoanPayments[index].PAYMENT = payment.PAYMENT; }"
                                        @change="_ => { actualDeductions.missingLoanPayments[index].PAYMENT = payment.PAYMENT; }"
                                        @keydown.enter.prevent="e => {
                                            if(index == deductionInputs.missingLoanPayments.length - 1)
                                                focusNextInput('kimbelLoanPayment0');
                                            else
                                                focusNextInput('missingLoanPayment' + (index + 1));
                                        }"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <div class="row">
                            <div class="col-2">
                                Kimbel
                            </div>
                            <div class="col row" v-for="payment, index in deductionInputs.kimbelLoanPayments">
                                <div class="col-3">
                                    <label :for="'kimbelLoanPayment' + index" class="form-label loan-id-label">
                                        {{ payment.LOANID }}
                                    </label>
                                </div>
                                <div class="col-9">
                                    <input type="number" step=".01" v-model="payment.PAYMENT"
                                        :id="'kimbelLoanPayment' + index" class="form-control form-control-sm"
                                        :title="`Actual is ${actualDeductions.kimbelLoanPayments[index].PAYMENT ?? 0}`"
                                        @keyup="_ => { actualDeductions.kimbelLoanPayments[index].PAYMENT = payment.PAYMENT; }"
                                        @change="_ => { actualDeductions.kimbelLoanPayments[index].PAYMENT = payment.PAYMENT; }"
                                        @keydown.enter.prevent="e => {
                                            if(index == deductionInputs.kimbelLoanPayments.length - 1)
                                                focusNextInput('eloadInput');
                                            else
                                                focusNextInput('kimbelLoanPayment' + (index + 1));
                                        }"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col row">
                        <label for="eloadInput" class="col-sm-6 col-form-label">E-LOAD</label>
                        <div class="col-sm-2 d-flex align-items-center loan-id-label">
                            {{ includeDeductions ? payslip?.ELOAD_LOAN_OBJ?.LOANID : "" }}
                        </div>
                        <div class="col-sm-4">
                            <input id="eloadInput" type="number" step=".01"
                                class="col form-control form-control-sm"
                                v-model="deductionInputs.eloadPayment" :disabled="!includeDeductions"
                                :title="`Actual is ${actualDeductions.eloadPayment ?? 0}`"
                                @keyup="_ => { actualDeductions.eloadPayment = deductionInputs.eloadPayment; }"
                                @change="_ => { actualDeductions.eloadPayment = deductionInputs.eloadPayment; }"
                                @keydown.enter.prevent="focusNextInput('SSSContributionInput')"/>
                        </div>
                    </div>
                    <div class="col row">
                        <!-- empty. Used for spacing -->
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col row">
                        <label for="SSSContributionInput" class="col-sm-8 col-form-label">SSS Contribution</label>
                        <div class="col-sm-4">
                            <input id="SSSContributionInput" type="number" step=".01"
                                class="col form-control form-control-sm"
                                v-model="deductionInputs.SSSContribution" :disabled="!includeDeductions"
                                :title="`Actual is ${actualDeductions.SSSContribution ?? 0}`"
                                @keyup="_ => { actualDeductions.SSSContribution = deductionInputs.SSSContribution; }"
                                @change="_ => { actualDeductions.SSSContribution = deductionInputs.SSSContribution; }"
                                @keydown.enter.prevent="focusNextInput('SSSLoanInput')"/>
                        </div>
                    </div>
                    <div class="col row">
                        <label for="SSSLoanInput" class="col-sm-6 col-form-label">SSS Loan</label>
                        <div class="col-sm-2 d-flex align-items-center loan-id-label">
                            {{ includeDeductions ? payslip?.SSS_LOAN_OBJ?.LOANID : "" }}
                        </div>
                        <div class="col-sm-4">
                            <input id="SSSLoanInput" type="number" step=".01"
                                class="col form-control form-control-sm"
                                v-model="deductionInputs.SSSLoan" :disabled="!includeDeductions"
                                :title="`Actual is ${actualDeductions.SSSLoan ?? 0}`"
                                @keyup="_ => { actualDeductions.SSSLoan = deductionInputs.SSSLoan; }"
                                @change="_ => { actualDeductions.SSSLoan = deductionInputs.SSSLoan; }"
                                @keydown.enter.prevent="focusNextInput('SSSCalamityInput')"/>
                        </div>
                    </div>
                </div>
                <div class="row mb-2 pe-0">
                    <div class="col row">
                        <label for="SSSCalamityInput" class="col-sm-6 col-form-label">SSS Calamity</label>
                        <div class="col-sm-2 d-flex align-items-center loan-id-label">
                            {{ includeDeductions ? payslip?.SSS_CALAMITY_LOAN_OBJ?.LOANID : "" }}
                        </div>
                        <div class="col-sm-4">
                            <input id="SSSCalamityInput" type="number" step=".01"
                                class="col form-control form-control-sm"
                                v-model="deductionInputs.SSSCalamity" :disabled="!includeDeductions"
                                :title="`Actual is ${actualDeductions.SSSCalamity ?? 0}`"
                                @keyup="_ => { actualDeductions.SSSCalamity = deductionInputs.SSSCalamity; }"
                                @change="_ => { actualDeductions.SSSCalamity = deductionInputs.SSSCalamity; }"
                                @keydown.enter.prevent="focusNextInput('SSSEmpContInput')"/>
                        </div>
                    </div>
                    <div class="col row">
                        <label for="SSSEmpContInput" class="col-sm-8 col-form-label">SSS EMP CONT</label>
                        <div class="col-sm-4">
                            <input id="SSSEmpContInput" type="number" step=".01"
                                class="col form-control form-control-sm"
                                v-model="deductionInputs.SSSEmpCont" :disabled="!includeDeductions"
                                :title="`Actual is ${actualDeductions.SSSEmpCont ?? 0}`"
                                @keyup="_ => { actualDeductions.SSSEmpCont = deductionInputs.SSSEmpCont; }"
                                @change="_ => { actualDeductions.SSSEmpCont = deductionInputs.SSSEmpCont; }"
                                @keydown.enter.prevent="focusNextInput('pagibigLoanInput')"/>
                        </div>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col row">
                        <label for="pagibigLoanInput" class="col-sm-6 col-form-label">PAGIBIG Loan</label>
                        <div class="col-sm-2 d-flex align-items-center loan-id-label">
                            {{ includeDeductions ? payslip?.PAGIBIG_LOAN_OBJ?.LOANID : "" }}
                        </div>
                        <div class="col-sm-4">
                            <input id="pagibigLoanInput" type="number" step=".01"
                                class="col form-control form-control-sm"
                                v-model="deductionInputs.pagibigLoan" :disabled="!includeDeductions"
                                :title="`Actual is ${actualDeductions.pagibigLoan ?? 0}`"
                                @keyup="_ => { actualDeductions.pagibigLoan = deductionInputs.pagibigLoan; }"
                                @change="_ => { actualDeductions.pagibigLoan = deductionInputs.pagibigLoan; }"
                                @keydown.enter.prevent="focusNextInput('pagibigCalamityInput')"/>
                        </div>
                    </div>
                    <div class="col row">
                        <label for="pagibigCalamityInput" class="col-sm-6 col-form-label">PAGIBIG Calamity
                            Loan</label>
                        <div class="col-sm-2 d-flex align-items-center loan-id-label">
                            {{ includeDeductions ? payslip?.PAGIBIG_CALAMITY_LOAN_OBJ?.LOANID : "" }}
                        </div>
                        <div class="col-sm-4">
                            <input id="pagibigCalamityInput" type="number" step=".01"
                                class="col form-control form-control-sm"
                                v-model="deductionInputs.pagibigCalamityLoan" :disabled="!includeDeductions"
                                :title="`Actual is ${actualDeductions.pagibigCalamityLoan ?? 0}`"
                                @keyup="_ => { actualDeductions.pagibigCalamityLoan = deductionInputs.pagibigCalamityLoan; }"
                                @change="_ => { actualDeductions.pagibigCalamityLoan = deductionInputs.pagibigCalamityLoan; }"
                                @keydown.enter.prevent="focusNextInput('pagibigContributionInput')"/>
                        </div>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col row">
                        <label for="pagibigContributionInput" class="col-sm-8 col-form-label">PAGIBIG
                            Contribution</label>
                        <div class="col-sm-4">
                            <input id="pagibigContributionInput" type="number" step=".01"
                                class="col form-control form-control-sm"
                                v-model="deductionInputs.pagibigContribution" :disabled="!includeDeductions"
                                :title="`Actual is ${actualDeductions.pagibigContribution ?? 0}`"
                                @keyup="_ => { actualDeductions.pagibigContribution = deductionInputs.pagibigContribution; }"
                                @change="_ => { actualDeductions.pagibigContribution = deductionInputs.pagibigContribution; }"
                                @keydown.enter.prevent="focusNextInput('PIEmpContInput')"/>
                        </div>
                    </div>
                    <div class="col row">
                        <label for="PIEmpContInput" class="col-sm-8 col-form-label">PI EMP CONT</label>
                        <div class="col-sm-4">
                            <input id="PIEmpContInput" type="number" step=".01"
                                class="col form-control form-control-sm"
                                v-model="deductionInputs.PIEmpCont" :disabled="!includeDeductions"
                                :title="`Actual is ${actualDeductions.PIEmpCont ?? 0}`"
                                @keyup="_ => { actualDeductions.PIEmpCont = deductionInputs.PIEmpCont; }"
                                @change="_ => { actualDeductions.PIEmpCont = deductionInputs.PIEmpCont; }"
                                @keydown.enter.prevent="focusNextInput('insuranceInput')"/>
                        </div>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col row">
                        <label for="insuranceInput" class="col-sm-8 col-form-label">Insurance</label>
                        <div class="col-sm-4">
                            <input id="insuranceInput" type="number" step=".01"
                                class="col form-control form-control-sm"
                                v-model="deductionInputs.insurance" :disabled="!includeDeductions"
                                :title="`Actual is ${actualDeductions.insurance ?? 0}`"
                                @keyup="_ => { actualDeductions.insurance = deductionInputs.insurance; }"
                                @change="_ => { actualDeductions.insurance = deductionInputs.insurance; }"
                                @keydown.enter.prevent="focusNextInput('PHContributionInput')"/>
                        </div>
                    </div>
                    <div class="col row">
                        <label for="PHContributionInput" class="col-sm-8 col-form-label">PH Contribution</label>
                        <div class="col-sm-4">
                            <input id="PHContributionInput" type="number" step=".01"
                                class="col form-control form-control-sm"
                                v-model="deductionInputs.PHContribution" :disabled="!includeDeductions"
                                :title="`Actual is ${actualDeductions.PHContribution ?? 0}`"
                                @keyup="_ => { actualDeductions.PHContribution = deductionInputs.PHContribution; }"
                                @change="_ => { actualDeductions.PHContribution = deductionInputs.PHContribution; }"
                                @keydown.enter.prevent="focusNextInput('WTXInput')"/>
                        </div>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col row">
                        <label for="WTXInput" class="col-sm-8 col-form-label">WTX</label>
                        <div class="col-sm-4">
                            <input id="WTXInput" type="number" step=".01" class="col form-control form-control-sm"
                                v-model="deductionInputs.WTX" :disabled="!includeDeductions"
                                :title="`Actual is ${actualDeductions.WTX ?? 0}`"
                                @keyup="_ => { actualDeductions.WTX = deductionInputs.WTX; }"
                                @change="_ => { actualDeductions.WTX = deductionInputs.WTX; }"
                                @keydown.enter.prevent="focusNextInput('includeDeductionsInput')"/>
                        </div>
                    </div>
                    <div class="col row">
                        <span class="form-check d-flex justify-content-end align-items-center">
                            <label for="includeDeductionsInput" class="form-check-label me-2">Include Deductions</label>
                            <input type="checkbox" v-model="includeDeductions" id="includeDeductionsInput"
                                aria-label="Checkbox for including deductions" checked
                                @change="initializeSubtractSection"
                                @keydown.enter.prevent="focusNextInput('createPayslipButton')"/>
                        </span>
                    </div>
                </div>
            </Card>
            <Card>
                <template #header>
                    Rate
                </template>
                <div class="d-flex align-items-center">
                    <div class="flex-grow-1 me-3">
                        <div class="row mb-2">
                            <label for="dailyRateDisplay" class="col-sm-5 col-form-label">Daily Rate</label>
                            <div class="col-sm-7">
                                <input type="text" class="col form-control form-control-sm"
                                    id="dailyRateDisplay" :value="payslip?.RATE" disabled>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <label for="hourlyRateDisplay" class="col-sm-5 col-form-label">Hourly Rate</label>
                            <div class="col-sm-7">
                                <input type="text" class="col form-control form-control-sm"
                                    id="hourlyRateDisplay" :value="payslip?.HOURLY_RATE" disabled>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <label for="hourlyCOLADisplay" class="col-sm-5 col-form-label">Hourly COLA</label>
                            <div class="col-sm-7">
                                <input type="text" class="col form-control form-control-sm"
                                    id="hourlyCOLADisplay" :value="payslip?.HOURLY_COLA_RATE" disabled>
                            </div>
                        </div>
                    </div>
                    <div class="h-100">
                        <button type="submit" class="btn btn-primary h-100"
                            :disabled="loadingFlagStore.has('submitPayslip') || isDisabled"
                            id="createPayslipButton"
                            @click="submitForm">
                            <span v-if="loadingFlagStore.has('submitPayslip')" class="spinner-border spinner-border-sm"
                                role="status" aria-hidden="true"></span>
                            Create Payslip
                        </button>
                    </div>
                </div>
            </Card>
        </div>
    </div>
</template>

<script setup>
import { computed, inject, ref, watch } from "vue";

import axios from "axios";

import Card from "@/components/utils/Card.vue";
import TimePeriodTable from "@/components/utils/tables/TimePeriodTable";

import EmployeeSelectionTableCard from "./EmployeeSelectionTableCard";
import OpenLoansTableCard from "./OpenLoansTableCard";

import focusNextInput from "@/utils/focusnext";
import handleFetchErrors from "@/utils/handleFetchErrors";
import scrollToTop from "@/utils/scrollToTop";

import { useFlashMessages } from "@/composables/flashmessages";

const { addFlashMessage } = useFlashMessages();

const timePeriodsTableRefreshFlag = ref(false);
const employeesTableRefreshFlag = ref(false);

const selectedTimePeriod = ref(null);
const selectedEmployee = ref(null);
const payslip = ref(null);

const loansRefreshFlag = ref(false);

const isDisabled = ref(true);
const loadingFlagStore = inject('loadingFlagStore');

const deductionInputs = ref({
    cashAdvanceLoanPayments: null,
    itemLoanPayments: null,
    missingLoanPayments: null,
    kimbelLoanPayments: null,
    eloadPayment: 0,
    SSSContribution: 0,
    SSSLoan: 0,
    SSSCalamity: 0,
    SSSEmpCont: 0,
    pagibigLoan: 0,
    pagibigCalamityLoan: 0,
    pagibigContribution: 0,
    PIEmpCont: 0,
    insurance: 0,
    PHContribution: 0,
    WTX: 0,
});

const actualDeductions = ref({
    cashAdvanceLoanPayments: null,
    itemLoanPayments: null,
    missingLoanPayments: null,
    kimbelLoanPayments: null,
    eloadPayment: 0,
    SSSContribution: 0,
    SSSLoan: 0,
    SSSCalamity: 0,
    SSSEmpCont: 0,
    pagibigLoan: 0,
    pagibigCalamityLoan: 0,
    pagibigContribution: 0,
    PIEmpCont: 0,
    insurance: 0,
    PHContribution: 0,
    WTX: 0,
});

const includeDeductions = ref(true);

const incentiveSalesHours = computed(() => {
    if (!payslip.value)
        return null;

    return payslip.value.HOURS_LIST
        .filter(hoursOnDate => hoursOnDate.STYLE === "INCENTIVE (SALES)")
        .reduce((totalHours, hoursOnDate) => totalHours + hoursOnDate.HOURS, 0);
});

const incentiveRegularHours = computed(() => {
    if (!payslip.value)
        return null;

    return payslip.value.HOURS_LIST
        .filter(hoursOnDate => hoursOnDate.STYLE === "INCENTIVE (REG)")
        .reduce((totalHours, hoursOnDate) => totalHours + hoursOnDate.HOURS, 0);
});

const absentHours = computed(() => {
    if (!payslip.value)
        return null;

    return payslip.value.HOURS_LIST
        .filter(hoursOnDate => hoursOnDate.STYLE === "ABSENT")
        .reduce((totalhours, hoursOnDate) => totalHours + hoursOnDate.HOURS, 0);
});

const suspendedHours = computed(() => {
    if (!payslip.value)
        return null;

    return payslip.value.HOURS_LIST
        .filter(hoursOnDate => hoursOnDate.STYLE === "SUSPENDED")
        .reduce((totalHours, hoursOnDate) => totalHours + hoursOnDate.HOURS, 0);
});

const allHoursPerJoborder = computed(() => {
    if(!payslip.value)
        return null;

    return payslip.value.HOURS_LIST.reduce((allHoursPerJoborder, hoursOnDate) => {
        const newAllHoursPerJoborder = [ ...allHoursPerJoborder ];

        const joborderHours = newAllHoursPerJoborder.filter((hoursOnJoborder) => {
            return hoursOnJoborder.JONO === hoursOnDate.JONO &&
                hoursOnJoborder.STYLE === hoursOnDate.STYLE &&
                hoursOnJoborder.RATE === hoursOnDate.RATE;
        })[0];

        if(joborderHours != undefined) {
            joborderHours.HOURS += hoursOnDate.HOURS;
        } else {
            newAllHoursPerJoborder.push({
                JONO: hoursOnDate.JONO,
                STYLE: hoursOnDate.STYLE,
                RATE: hoursOnDate.RATE,
                HOURS: hoursOnDate.HOURS
            });
        }

        return newAllHoursPerJoborder;
    }, []);
});

const grossPay = computed(() =>
    payslip.value ? payslip.value.GROSS_PAY + (caOffset.value > 0 ? caOffset.value : 0) : 0
);

const totalDeduct = computed(() => {
    const paymentTotalReducer = (total, payment) => total + (payment.PAYMENT || 0);

    return (actualDeductions.value.cashAdvanceLoanPayments?.reduce(paymentTotalReducer, 0) || 0) +
        (actualDeductions.value.itemLoanPayments?.reduce(paymentTotalReducer, 0) || 0) +
        (actualDeductions.value.missingLoanPayments?.reduce(paymentTotalReducer, 0) || 0) +
        (actualDeductions.value.kimbelLoanPayments?.reduce(paymentTotalReducer, 0) || 0) +
        actualDeductions.value.eloadPayment +
        actualDeductions.value.SSSContribution +
        actualDeductions.value.SSSLoan +
        actualDeductions.value.SSSCalamity +
        actualDeductions.value.pagibigLoan +
        actualDeductions.value.pagibigCalamityLoan +
        actualDeductions.value.pagibigContribution +
        actualDeductions.value.PHContribution +
        actualDeductions.value.insurance +
        actualDeductions.value.WTX +
        (payslip.value?.MISSED_CONTRIB ?? 0);
});

const finalAmount = computed(() =>
    payslip.value ? payslip.value.GROSS_PAY - totalDeduct.value : 0
);

const finalCheckoutAmount = computed(() =>
    finalAmount.value >= 0 ? finalAmount.value : 0
);

const caOffset = computed(() =>
    finalAmount.value < 0 ?
        -finalAmount.value : 0
);

async function initializePayslipForSelectedEmployee() {
    isDisabled.value = true;

    payslip.value = null;
    actualDeductions.value = {
        cashAdvanceLoanPayments: null,
        itemLoanPayments: null,
        missingLoanPayments: null,
        kimbelLoanPayments: null,
        eloadPayment: 0,
        SSSContribution: 0,
        SSSLoan: 0,
        SSSCalamity: 0,
        SSSEmpCont: 0,
        pagibigLoan: 0,
        pagibigCalamityLoan: 0,
        pagibigContribution: 0,
        PIEmpCont: 0,
        insurance: 0,
        PHContribution: 0,
        WTX: 0,
    };
    deductionInputs.value = Object.assign({}, actualDeductions.value);

    if (!selectedEmployee.value) {
        return;
    }

    loadingFlagStore.value.add('initializePayslipForSelectedEmployee');
    try {
        const initialPayslipResponse = await axios.get(route('api.payslips.initial'), {
            params: {
                TIME_PERIOD: selectedTimePeriod.value.TIME_PERIOD,
                EMPID: selectedEmployee.value.EMPID
            }
        });
        payslip.value = initialPayslipResponse.data.data;
        initializeSubtractSection();
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, 'Error while making initial payslip.');
        scrollToTop();
    }
    loadingFlagStore.value.delete('initializePayslipForSelectedEmployee');
    isDisabled.value = false;
}

watch(selectedTimePeriod, () => { selectedEmployee.value = null; });
watch(selectedEmployee, initializePayslipForSelectedEmployee);

function initializeSubtractSection() {
    if(includeDeductions.value) {
        const loanPaymentMapper = loan => ({ LOANID: loan.LOANID, PAYMENT: +(loan.PAYMENT) });

        actualDeductions.value.cashAdvanceLoanPayments = [
            ...payslip.value.CA_LOANS_LIST.map(loanPaymentMapper),
            ...new Array(5 - payslip.value.CA_LOANS_LIST.length).fill({ LOANID: null, PAYMENT: null })
        ];
        actualDeductions.value.itemLoanPayments = [
            ...payslip.value.ITEM_LOANS_LIST.map(loanPaymentMapper),
            ...new Array(5 - payslip.value.ITEM_LOANS_LIST.length).fill({ LOANID: null, PAYMENT: null })
        ];
        actualDeductions.value.missingLoanPayments = [
            ...payslip.value.MISSING_LOANS_LIST.map(loanPaymentMapper),
            ...new Array(5 - payslip.value.MISSING_LOANS_LIST.length).fill({ LOANID: null, PAYMENT: null })
        ];
        actualDeductions.value.kimbelLoanPayments = [
            ...payslip.value.KIMBEL_LOANS_LIST.map(loanPaymentMapper),
            ...new Array(5 - payslip.value.KIMBEL_LOANS_LIST.length).fill({ LOANID: null, PAYMENT: null })
        ];
        actualDeductions.value.eloadPayment = +payslip.value.ELOAD;
        actualDeductions.value.SSSContribution = +payslip.value.SSS_CONTRIBUTION;
        actualDeductions.value.SSSLoan = +payslip.value.SSS_LOAN;
        actualDeductions.value.SSSCalamity = +payslip.value.SSS_CALAMITY;
        actualDeductions.value.SSSEmpCont = +payslip.value.SSS_CONTRIBUTION_EMP;
        actualDeductions.value.pagibigLoan = +payslip.value.PAGIBIG_LOAN;
        actualDeductions.value.pagibigCalamityLoan = +payslip.value.PAGIBIG_CALAMITY_LOAN;
        actualDeductions.value.pagibigContribution = +payslip.value.PAGIBIG_CONTRIBUTION;
        actualDeductions.value.PIEmpCont = +payslip.value.PAGIBIG_CONTRIBUTION_EMP;
        actualDeductions.value.PHContribution = +payslip.value.PH_CONTRIBUTION;
        actualDeductions.value.insurance = +payslip.value.INSURANCE;
        actualDeductions.value.WTX = +payslip.value.WTX;
        actualDeductions.value.missedContributions = +payslip.value.MISSED_CONTRIB;

        // For deduction inputs, initially display two decimal places.
        const loanRoundMapper = loan => ({...loan, PAYMENT: loan.PAYMENT != null ? parseFloat(loan.PAYMENT.toFixed(2)) : null});
        deductionInputs.value = JSON.parse(JSON.stringify(actualDeductions.value));
        deductionInputs.value.cashAdvanceLoanPayments = deductionInputs.value.cashAdvanceLoanPayments.map(loanRoundMapper);
        deductionInputs.value.itemLoanPayments = deductionInputs.value.itemLoanPayments.map(loanRoundMapper);
        deductionInputs.value.missingLoanPayments = deductionInputs.value.missingLoanPayments.map(loanRoundMapper);
        deductionInputs.value.kimbelLoanPayments = deductionInputs.value.kimbelLoanPayments.map(loanRoundMapper);
        deductionInputs.value.SSSContribution = parseFloat(deductionInputs.value.SSSContribution.toFixed(2));
        deductionInputs.value.SSSLoan = parseFloat(deductionInputs.value.SSSLoan.toFixed(2));
        deductionInputs.value.SSSCalamity = parseFloat(deductionInputs.value.SSSCalamity.toFixed(2));
        deductionInputs.value.SSSEmpCont = parseFloat(deductionInputs.value.SSSEmpCont.toFixed(2));
        deductionInputs.value.pagibigLoan = parseFloat(deductionInputs.value.pagibigLoan.toFixed(2));
        deductionInputs.value.pagibigCalamityLoan = parseFloat(deductionInputs.value.pagibigCalamityLoan.toFixed(2));
        deductionInputs.value.pagibigContribution = parseFloat(deductionInputs.value.pagibigContribution.toFixed(2));
        deductionInputs.value.PIEmpCont = parseFloat(deductionInputs.value.PIEmpCont.toFixed(2));
        deductionInputs.value.PHContribution = parseFloat(deductionInputs.value.PHContribution.toFixed(2));
        deductionInputs.value.insurance = parseFloat(deductionInputs.value.insurance.toFixed(2));
        deductionInputs.value.WTX = parseFloat(deductionInputs.value.WTX.toFixed(2));
    } else {
        const loanPaymentMapper = loan => ({ LOANID: loan.LOANID, PAYMENT: +(loan.PAYMENT) });
        
        actualDeductions.value = {
            cashAdvanceLoanPayments: [
                ...payslip.value.CA_LOANS_LIST.map(loanPaymentMapper),
                ...new Array(5 - payslip.value.CA_LOANS_LIST.length).fill({ LOANID: null, PAYMENT: null })
            ],
            itemLoanPayments: [
                ...payslip.value.ITEM_LOANS_LIST.map(loanPaymentMapper),
                ...new Array(5 - payslip.value.ITEM_LOANS_LIST.length).fill({ LOANID: null, PAYMENT: null })
            ],
            missingLoanPayments: null,
            kimbelLoanPayments: null,
            eloadPayment: 0,
            SSSContribution: 0,
            SSSLoan: 0,
            SSSCalamity: 0,
            SSSEmpCont: 0,
            pagibigLoan: 0,
            pagibigCalamityLoan: 0,
            pagibigContribution: 0,
            PIEmpCont: 0,
            insurance: 0,
            PHContribution: 0,
            missedContributions: 0,
            WTX: 0,
        };
        
        deductionInputs.value = Object.assign({}, actualDeductions.value);
    }
}

const submitForm = async () => {
    let payslip = {
        EMPID: selectedEmployee.value.EMPID,
        PAYTYPE: selectedTimePeriod.value.PAYTYPE,
        TIME_PERIOD: selectedTimePeriod.value.TIME_PERIOD,
        CA_LOAN_PAYMENTS: actualDeductions.value.cashAdvanceLoanPayments?.filter(payment => payment.LOANID != null),
        ITEM_LOAN_PAYMENTS: actualDeductions.value.itemLoanPayments?.filter(payment => payment.LOANID != null),
        MISSING_LOAN_PAYMENTS: actualDeductions.value.missingLoanPayments?.filter(payment => payment.LOANID != null),
        KIMBEL_LOAN_PAYMENTS: actualDeductions.value.kimbelLoanPayments?.filter(payment => payment.LOANID != null),
        ELOAD_PAYMENT: actualDeductions.value.eloadPayment,
        SSS_CONTRIBUTION: actualDeductions.value.SSSContribution,
        SSS_LOAN_PAYMENT: actualDeductions.value.SSSLoan,
        SSS_CALAMITY_PAYMENT: actualDeductions.value.SSSCalamity,
        SSS_EMP_CONT: actualDeductions.value.SSSEmpCont,
        PAGIBIG_LOAN_PAYMENT: actualDeductions.value.pagibigLoan,
        PAGIBIG_CALAMITY_LOAN_PAYMENT: actualDeductions.value.pagibigCalamityLoan,
        PAGIBIG_CONTRIBUTION: actualDeductions.value.pagibigContribution,
        PI_EMP_CONT: actualDeductions.value.PIEmpCont,
        INSURANCE: actualDeductions.value.insurance,
        PH_CONTRIBUTION: actualDeductions.value.PHContribution,
        WTX: actualDeductions.value.WTX,
    };

    // Prompt to create CA Loan, but it will be created on backend
    if (caOffset.value > 0 && !confirm("Create Cash Advance Loan for Employee?")) {
        return;
    }

    loadingFlagStore.value.add('submitPayslip');
    try {
        await axios.post(route('api.payslips.store'), payslip);
        addFlashMessage('SUCCESS', `Successfully created payslip, payments, and costs
            for employee ${selectedEmployee.value.FNAME} ${selectedEmployee.value.LNAME}.`);

        employeesTableRefreshFlag.value = !employeesTableRefreshFlag.value;
        loansRefreshFlag.value = !loansRefreshFlag.value;
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, 'Error while creating payslip.');
    }
    loadingFlagStore.value.delete('submitPayslip');
    isDisabled.value = true;
    scrollToTop();
};
</script>
