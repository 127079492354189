<template>
    <div class="row g-3">
        <div class="col-md-3">
            <LocationSelectTableCard v-model="selectedLocation" />
        </div>
        <div class="col-md-9">
            <InventoryCheckDetailsCard :location="selectedLocation?.LOCATION" />
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';

import LocationSelectTableCard from '@/components/utils/LocationSelectTableCard.vue';
import InventoryCheckDetailsCard from './InventoryCheckDetailsCard.vue';

const selectedLocation = ref(null);
</script>
