<template>
    <Card>
        <DataTable lazy paginator :rows="10" :value="mainInfoTable" :total-records="mainTotalRecords"
            filter-display="row" v-model:filters="filters"
            v-model:selection="selectedMainInfo" selection-mode="single" data-key="EMPID"
            @page="e => { onPage(e); getMainInfoTable(); }"
            @filter="e => { onFilter(e); getMainInfoTable(); }"
            :row-class="data => [{'bg-primary text-white': data.EMPID == selectedMainInfo?.EMPID}]"
            :pt="{ table: { class: 'table table-bordered table-hover' } }">
            <Column field="EMPID" header="Employee ID" class="small"/>
            <Column field="EMPNAME" header="Employee Name" class="small"/>
            <Column field="SOLD_AMT" header="Sold AMT" class="small"/>
            <Column field="SOLD_DAYS" header="Sold Days" class="small"/>
            <Column field="COMMISSION_PCT" header="Commission PCT" class="small"/>
            <Column field="COMMISSION_AMT" header="Commission AMT" class="small"/>
            <Column field="MISSING_PCT" header="Missing PCT" class="small"/>
            <Column field="MISSING_AMT" header="Missing AMT" class="small"/>
            <template #empty>Empty summary.</template>
        </DataTable>
    </Card>
</template>

<script setup>
import axios from 'axios';
import { ref, watch } from 'vue';

import Card from '@/components/utils/Card.vue';
import { useDataTableParams } from '@/composables/data/dataTableParams';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';

const { params, filters, onPage, onFilter } = useDataTableParams(
    ['EMPID', 'SOLD_AMT', 'SOLD_DAYS']
);

const props = defineProps({
  location: {
    type: Object,
    required: true
  },
  timePeriod: {
    type: Object,
    required: true
  }
});

watch(() => props.location, () => {
    getMainInfoTable();
})

watch(() => props.timePeriod, () => {
    getMainInfoTable();
})

const selectedMainInfo = ref(null);
const mainTotalRecords = ref(null);
const mainInfoTable = ref(null);

async function getMainInfoTable() {
    try {
        const response = await axios.get(route('api.recon-store-summary-dist.index', {
            ...params.value,
            TIME_PERIOD: props.timePeriod.TIME_PERIOD,
            LOCATION: props.location.LOCATION,
        }))

        mainInfoTable.value = response.data.data;
        mainTotalRecords.value = response.data.meta.total;
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, 'Error while fetching main information table');
    }
}

defineExpose({ getMainInfoTable });
</script>
