<template>
    <Card :class="class">
        <template #header>
            Vouchers
        </template>
        <div class="row mb-3">
            <div class="col-md-4">
                <FormSelect v-model="companyCode"
                    :options="companies"
                    :option-value="company => company.COMPANY_CODE"
                    :option-to-string="company => [company.COMPANY_CODE, company.COMPANY_DESCRIPTION].join(' | ')"
                    label="Company Code" id-prefix="companyCode" />
            </div>
            <div class="col-md-4">
                <FormInput type="text" v-model="locationFilter"
                    label="Location Filter" id-prefix="locationFilter" />
            </div>
        </div>
        <DataTable :loading="isSalesLoading || isCollectionsLoading" :value="glAccounts"
            paginator :rows="10"
            :pt="{ table: { class: 'table table-bordered table-hover' }}"
            class="mb-3">
            <Column field="GL_ID" header="GL ID" />
            <Column field="CLASS" header="Class" />
            <Column field="ACCTNAME" header="Acct. Name" />
            <Column field="LOCATION" header="Location" />
            <Column field="DEBIT" header="Debit">
                <template #body="{ data }">
                    {{ data.DEBIT?.toFixed(2) ?? 0 }}
                </template>
            </Column>
            <Column field="CREDIT" header="Credit">
                <template #body="{ data }">
                    {{ data.CREDIT?.toFixed(2) ?? 0 }}
                </template>
            </Column>
            <template #empty>
                <div class="text-center">
                    No data yet.
                </div>
            </template>
        </DataTable>
        <div class="row justify-content-end">
            <div class="col-md-4">
                <FormInput type="text" v-model="voucherId"
                    label="Voucher" id-prefix="voucherId"
                    is-horizontal />
            </div>
            <div class="col-md-2">
                <button type="button" class="btn btn-primary"
                    :disabled="!voucherType"
                    @click="populate">
                    Populate
                </button>
            </div>
        </div>
        <template #footer>
            <div class="text-end">
                <button type="button" class="btn btn-primary me-1"
                    :disabled="isSalesLoading"
                    @click="fetchSalesGLAccounts">
                    <span v-if="isSalesLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Calculate
                </button>
                <button type="button" class="btn btn-primary me-1"
                    :disabled="isCollectionsLoading"
                    @click="fetchCollectionsGLAccounts">
                    <span v-if="isCollectionsLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Calculate2
                </button>
            </div>
        </template>
    </Card>
</template>

<script setup>
import { onMounted, ref } from 'vue';

import { useCompanies } from '@/composables/data/companies';
import { useReconFinSummaryLocationBases } from '@/composables/data/reconFinSummaryLocationBases';
import { useVouchers } from '@/composables/data/vouchers';
import { useVoucherAccountingApprovals } from '@/composables/data/voucherAccountingApprovals';
import { useVoucherStoreSummaries } from '@/composables/data/voucherStoreSummaries';

import Card from '@/components/utils/Card';
import FormInput from '@/components/utils/FormInput';
import FormSelect from '@/components/utils/FormSelect';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';

const props = defineProps({
    timePeriod: Number,
    class: String,
});

const emit = defineEmits(['populate-success', 'error']);

const { companies, getCompanies } = useCompanies();

const companyCode = ref(null);

const { glAccounts, getSalesGLAccounts, getCollectionsGLAccounts } = useReconFinSummaryLocationBases();

const locationFilter = ref('SM_');

const voucherId = ref(null);

const { voucher, getVoucher } = useVouchers();
const { getVoucherAccountingApproval } = useVoucherAccountingApprovals();
const { postVoucherStoreSummary } = useVoucherStoreSummaries();

const voucherType = ref(null);

const isSalesLoading = ref(false);
const isCollectionsLoading = ref(false);

onMounted(async () => {
    try {
        await getCompanies();
    } catch(e) {
        emit('error', e.message);
    }
});

async function fetchSalesGLAccounts() {
    voucherType.value = null;
    glAccounts.value = null;

    if(!companyCode.value || !props.timePeriod || isCollectionsLoading.value)
        return;

    isSalesLoading.value = true;
    try {
        await getSalesGLAccounts(companyCode.value, props.timePeriod);
        voucherType.value = 'SALES';
    } catch(e) {
        emit('error', e.message);
    }
    isSalesLoading.value = false;
}

async function fetchCollectionsGLAccounts() {
    voucherType.value = null;
    glAccounts.value = null;

    if(!companyCode.value || !props.timePeriod || isSalesLoading.value)
        return;

    isCollectionsLoading.value = true;
    try {
        await getCollectionsGLAccounts(companyCode.value, props.timePeriod, locationFilter.value);
        voucherType.value = 'COLLECTION';
    } catch(e) {
        emit('error', e.message);
    }
    isCollectionsLoading.value = false;
}

async function populate() {
    if(!voucherId.value) {
        window.alert("Please specify a VOUCHER to use.");
        return;
    }

    // Check if voucher exists
    voucher.value = null;
    try {
        await getVoucher(voucherId.value);
    } catch(e) {
        if(e.status == 404)
            window.alert("Voucher does not exist.");
        else
            emit('error', e.message);
        return;
    }

    // Prevent if voucher is already approved
    try {
        await getVoucherAccountingApproval(voucherId.value);
        // Since 404 not encountered, approval exists.
        window.alert(`Cannot use voucher ${voucherId.value}. Already approved.`);
        return;
    } catch(e) {
        if(e.status != 404) {
            emit('error', e.message);
            return;
        }
    }

    if (!window.confirm(`This will reset the GL Accounts for voucher ${voucherId.value}. Are you sure?`))
        return;

    try {
        const response = await postVoucherStoreSummary(
            companyCode.value, props.timePeriod, voucherType.value, voucherId.value,
            locationFilter.value);
        emit('populate-success', response.data.message);
    } catch(e) {
        emit('error', e.message);
    }
}
</script>
