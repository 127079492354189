import { ref } from "vue";

import constructFetchError from "./constructFetchError";

import axios from 'axios';

export function useInventoryReconDeletes() {
    const inventoryReconDeletes = ref(null);
    const inventoryReconDeletesTotalRecords = ref(null);

    const getInventoryReconDeletes = async (location, style, params) => {
        try {
            const getInventoryReconDeletesResponse = await axios.get(route('api.inventory-recon-delete.index', {
                LOCATION: location,
                STYLE: style,
                ...params,
            }));
            inventoryReconDeletes.value = getInventoryReconDeletesResponse.data.data;
            inventoryReconDeletesTotalRecords.value = getInventoryReconDeletesResponse.data.meta.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching inventory recon deletes.');
        }
    };
    
    return { inventoryReconDeletes, inventoryReconDeletesTotalRecords, getInventoryReconDeletes };
}
