<template>
    <form @submit.prevent="_ => { emit('export', pulloutLetterDetails); }">
        <Card>
            <template #header>
                Export SM Pullout Letter
            </template>
            <FormInput type="text" v-model="pulloutLetterDetails.vendor_code" required
                label="Vendor Code" class="mb-3" is-horizontal />
            <FormInput type="text" v-model="pulloutLetterDetails.scpoa_number" required
                label="SCPOA No." class="mb-3" is-horizontal />
            <FormInput type="text" v-model="pulloutLetterDetails.store_code" required
                label="Store Code" class="mb-3" is-horizontal />
            <FormInput type="date" v-model="pulloutLetterDetails.expected_pullout_date" required
                label="Expected Pullout Date" class="mb-3" is-horizontal />
            <FormInput type="text" v-model="pulloutLetterDetails.department_code" required
                label="Department Code" class="mb-3" is-horizontal />
            <FormInput type="text" v-model="pulloutLetterDetails.sub_department_code" required
                label="Sub Department Code" class="mb-3" is-horizontal />
            <FormInput type="text" v-model="pulloutLetterDetails.class_code" required
                label="Class Code" class="mb-3" is-horizontal />
            <template #footer>
                <div class="text-end">
                    <button type="submit" class="btn btn-primary"
                        :disabled="disableExport">Create SM Pullout Letter</button>
                </div>
            </template>
        </Card>
    </form>
</template>

<script setup>
import { ref } from 'vue';

import Card from '@/components/utils/Card.vue';
import FormInput from '@/components/utils/FormInput.vue';

const props = defineProps({
    disableExport: Boolean
})

const emit = defineEmits(['export']);

const pulloutLetterDetails = ref({
    vendor_code: null,
    scpoa_number: null,
    store_code: null,
    expected_pullout_date: null,
    department_code: null,
    sub_department_code: null,
    class_code: null
});
</script>
