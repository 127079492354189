<template>
    <Card>
        <template #header>
            Top Attendance (WEEKLY) (please select time period)
        </template>
        <DataTable :loading="isLoading" :value="topFiveWeeklyAttendance"
            :pt="{ table: { class: 'table table-bordered table-hover' }}">
            <Column field="DEPT" header="Dept." />
            <Column field="FNAME" header="First Name" />
            <Column field="LNAME" header="Last Name" />
            <Column field="DOW" header="Days of Work" />
            <template #empty>
                <div class="text-center">
                    No data yet.
                </div>
            </template>
        </DataTable>
        <template #footer>
            <div class="text-end">
                <button type="button" class="btn btn-primary"
                    :disabled="!timePeriod || isLoading"
                    @click="fetchTopFiveAttendance">
                    <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Generate
                </button>
            </div>
        </template>
    </Card>
</template>

<script setup>
import { ref } from 'vue';

import { useDaysOfWork } from '@/composables/data/daysOfWork';

import Card from '@/components/utils/Card.vue';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';

const props = defineProps({
    timePeriod: Number,
});

const emit = defineEmits(['error']);

const { topFiveWeeklyAttendance, getTopFiveWeeklyAttendance } = useDaysOfWork();

const isLoading = ref(false);

async function fetchTopFiveAttendance() {
    topFiveWeeklyAttendance.value = null;
    if(!props.timePeriod)
        return;
    isLoading.value = true;
    try {
        await getTopFiveWeeklyAttendance(props.timePeriod);
    } catch(e) {
        emit('error', e.message);
    }
    isLoading.value = false;
}
</script>
