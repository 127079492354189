<template>
    <div class="card p-4">
        <section class="mb-2 d-flex justify-content-evenly">
            <div class="d-flex align-items-center w-100 me-2">
                <label for="" class="text-nowrap me-2">EMPID</label>
                <input
                    type="text"
                    class="form-control"
                    v-model="formEmployeeEMPID"
                    disabled
                />
            </div>
            <div class="d-flex align-items-center w-100 me-2">
                <label for="" class="text-nowrap me-2">FIRST NAME:</label>
                <input
                    type="text"
                    class="form-control"
                    v-model="formEmployee.FNAME"
                    disabled
                />
            </div>
            <div class="d-flex align-items-center w-100 me-2">
                <label for="" class="text-nowrap me-2">LAST NAME:</label>
                <input
                    type="text"
                    class="form-control"
                    v-model="formEmployee.LNAME"
                    disabled
                />
            </div>
            <div class="d-flex align-items-center w-100 me-2">
                <label for="" class="text-nowrap me-2">DEPARMENT:</label>
                <input
                    type="text"
                    class="form-control"
                    v-model="formEmployee.DEPT"
                    disabled
                />
            </div>
            <button type="button"
                class="btn btn-primary"
                @click="_ => { isSelectEmployeePopupOpen = true; }"
            >
                SEARCH
            </button>
        </section>
        <div
            class="alert alert-success mb-2"
            role="alert"
            v-show="displayDeleteLocationSuccessAlert"
        >
            Successfully Deleted Location!
        </div>
        <div
            class="alert alert-success mb-2"
            role="alert"
            v-show="displayUpdateLocationSuccessAlert"
        >
            Successfully Updated Location!
        </div>
        <div
            class="alert alert-success mb-2"
            role="alert"
            v-show="displayInsertLocationSuccessAlert"
        >
            Successfully Inserted Location!
        </div>
        <div class="d-flex h-20">
            <section class="w-75 me-2">
                <div class="employee-location-table-wrapper">
                    <table
                        class="table table-sm table-bordered table-striped table-hover"
                    >
                        <thead>
                            <tr>
                                <th>LOCATION</th>
                                <th>START DATE</th>
                                <th>END DATE</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="employeeAssigned in employeesLocations"
                                @click="populateFormLocation(employeeAssigned)"
                            >
                                <td>{{ employeeAssigned.LOCATION }}</td>
                                <td>{{ employeeAssigned.START_DATE }}</td>
                                <td>{{ employeeAssigned.END_DATE }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
            <section class="w-25 d-flex flex-column justify-content-evenly">
                <form
                    @submit.prevent="addLocations"
                    id="employee-locations-form"
                >
                    <input type="hidden" v-model="formLocation.id" />
                    <input type="hidden" v-model="formLocation.EMPID" />
                    <div class="d-flex align-items-center mb-2">
                        <label for="location">LOCATION </label>
                        <select
                            name="LOCATION"
                            id="location"
                            class="form-select form-select-sm ms-2"
                            v-model="formLocation.LOCATION"
                        >
                            <option
                                v-for="location in locations"
                                :value="location.LOCATION"
                            >
                                {{ location.LOCATION }}
                            </option>
                        </select>
                    </div>
                    <div v-if="errors.LOCATION" key="LOCATION">
                        <p
                            v-for="error in errors.LOCATION"
                            :key="error"
                            class="text-danger fs-6 mt-2 text-end"
                        >
                            {{ error }}
                        </p>
                    </div>
                    <div class="d-flex align-items-center mb-2">
                        <label for="start-date" class="text-nowrap"
                            >START DATE
                        </label>
                        <input
                            type="date"
                            class="ms-3 form-control form-control-sm"
                            name="START_DATE"
                            v-model="formLocation.START_DATE"
                        />
                    </div>
                    <div v-if="errors.START_DATE" key="START_DATE">
                        <p
                            v-for="error in errors.START_DATE"
                            :key="error"
                            class="text-danger fs-6 mt-2 text-end"
                        >
                            {{ error }}
                        </p>
                    </div>
                    <div class="d-flex align-items-center mb-2">
                        <label for="end-date" class="text-nowrap"
                            >END DATE
                        </label>
                        <input
                            type="date"
                            name="END_DATE"
                            class="ms-4 form-control form-control-sm"
                            v-model="formLocation.END_DATE"
                        />
                    </div>
                    <div v-if="errors.END_DATE" key="END_DATE">
                        <p
                            v-for="error in errors.END_DATE"
                            :key="error"
                            class="text-danger fs-6 mt-2 text-end"
                        >
                            {{ error }}
                        </p>
                    </div>
                    <div class="d-flex width-100 mb-2">
                        <button
                            type="submit"
                            class="btn btn-secondary w-50 me-2"
                            :disabled="
                                insertLocationLoading || disableInsertButton
                            "
                        >
                            <span v-show="!insertLocationLoading">
                                INSERT TO LIST
                            </span>
                            <div
                                v-show="insertLocationLoading"
                                class="spinner-border spinner-border-sm text-light"
                                role="status"
                            >
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </button>
                        <button
                            class="btn btn-secondary w-50"
                            @click="deleteLocation()"
                            :disabled="
                                deleteLocationLoading || disableDeleteButton
                            "
                        >
                            <span v-show="!deleteLocationLoading">
                                DELETE FROM LIST
                            </span>
                            <div
                                v-show="deleteLocationLoading"
                                class="spinner-border spinner-border-sm text-light"
                                role="status"
                            >
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </button>
                    </div>
                    <div class="d-flex">
                        <button
                            type="button"
                            class="btn btn-secondary me-2 w-50"
                            @click="updateLocation()"
                            :disabled="!formLocation.id"
                        >
                            UPDATE
                        </button>
                        <button
                            type="button"
                            class="btn btn-secondary w-50"
                            @click="resetFields()"
                        >
                            RESET FIELDS
                        </button>
                    </div>
                </form>
            </section>
        </div>
    </div>

    <SelectEmployee :is-open="isSelectEmployeePopupOpen"
        :on-close="_ => { isSelectEmployeePopupOpen = false; }"
        :on-select-employee="onEmployeeSelect" />
</template>

<script setup>
import { ref, onMounted, reactive } from "vue";

import SelectEmployee from "@/components/utils/SelectEmployee.vue";

import axios from "axios";

const departments = ref([]);
const errors = ref("");
const formEmployeeEMPID = ref("");
const employeesLocations = ref([]);
const locations = ref([]);
const deleteLocationLoading = ref(false);
const displayDeleteLocationSuccessAlert = ref(false);
const displayInsertLocationSuccessAlert = ref(false);
const insertLocationLoading = ref(false);
const selectedEmployeeID = ref("");
const disableInsertButton = ref(true);
const disableDeleteButton = ref(true);
const disableSelectEmployeeButton = ref(true);
const displayUpdateLocationSuccessAlert = ref(false);
const isSelectEmployeePopupOpen = ref(false);

onMounted(async () => {
    try {
        const result = await axios.get(route('api.departments.index'), {});
        departments.value = result.data.data;
    } catch (e) {
        console.log(e);
    }
});

const formEmployee = reactive({
    EMPID: formEmployeeEMPID,
    FNAME: "",
    LNAME: "",
    DEPT: "",
});

const onEmployeeSelect = (selectedEmployee) => {
    disableButton(false, true);
    selectedEmployeeID.value = selectedEmployee.EMPID;
    formEmployee.EMPID = selectedEmployee.EMPID;
    formLocation.EMPID = selectedEmployee.EMPID;
    formEmployee.FNAME = selectedEmployee.FNAME;
    formEmployee.LNAME = selectedEmployee.LNAME;
    formEmployee.DEPT = selectedEmployee.DEPT;
    disableSelectEmployeeButton.value = false;
    isSelectEmployeePopupOpen.value = false;
    fetchEmployeeLocations();
};

async function fetchEmployeeLocations() {
    try {
        const result = await axios.get(
            route('api.employee-locations.show', [selectedEmployeeID.value]),
            {}
        );
        employeesLocations.value = result.data;
    } catch (e) {
        console.log(e);
        if (e.response.status === 422) {
            errors.value = e.response.data.errors;
        }
    }
}

onMounted(async () => {
    try {
        const result = await axios.get(route('api.locations.index'), {});
        locations.value = result.data;
    } catch (e) {
        console.log(e);
        if (e.response.status === 422) {
            errors.value = e.response.data.errors;
        }
    }
});

const formLocation = reactive({
    id: "",
    EMPID: selectedEmployeeID.value,
    LOCATION: "",
    START_DATE: "",
    END_DATE: "",
});

function disableButton(insert, remove) {
    disableInsertButton.value = insert;
    disableDeleteButton.value = remove;
}

function populateFormLocation(location) {
    disableButton(false, false);
    const selectedLocation = employeesLocations.value.find(
        (loc) => loc.id === location.id
    );
    if (!selectedLocation) {
        return;
    } else {
        formLocation.id = selectedLocation.id;
        formLocation.EMPID = selectedLocation.EMPID;
        formLocation.LOCATION = selectedLocation.LOCATION;
        formLocation.START_DATE = selectedLocation.START_DATE;
        formLocation.END_DATE = selectedLocation.END_DATE;
        disableInsertButton.value = true;
    }
}

function resetFields() {
    formLocation.id = "";
    formLocation.LOCATION = "";
    formLocation.START_DATE = "";
    formLocation.END_DATE = "";
    errors.value = false;
    disableButton(false, true);
    resetAlerts();
}

function clearFields() {
    formLocation.LOCATION = "";
    formLocation.START_DATE = "";
    formLocation.END_DATE = "";
}

const deleteLocation = async () => {
    resetAlerts();
    deleteLocationLoading.value = true;
    errors.value = false;
    try {
        const result = await axios.delete(
            route('api.employee-locations.destroy', [formLocation.id])
        );
        if (result.status === 204) {
            displayDeleteLocationSuccessAlert.value = true;
            clearFields();
            fetchEmployeeLocations();
            disableDeleteButton.value = true;
        }
    } catch (e) {
        console.log(e);
    }
    deleteLocationLoading.value = false;
};

const addLocations = async () => {
    resetAlerts();
    errors.value = false;
    insertLocationLoading.value = true;
    try {
        const result = await axios.post(route('api.employee-locations.store'), {
            EMPID: selectedEmployeeID.value,
            ...formLocation,
        });
        if (result.status === 201) {
            fetchEmployeeLocations();
            displayInsertLocationSuccessAlert.value = true;
            clearFields();
        }
    } catch (e) {
        console.log(e.response);
        if (e.response.status === 422) {
            errors.value = e.response.data.errors;
        }
    }
    insertLocationLoading.value = false;
};

async function updateLocation() {
    resetAlerts();
    try {
        const result = await axios.patch(
            route('api.employee-locations.update', [formLocation.id]),
            {
                EMPID: selectedEmployeeID.value,
                ...formLocation,
            }
        );
        if (result.status === 201) {
            fetchEmployeeLocations();
            clearFields();
            displayUpdateLocationSuccessAlert.value = true;
        }
    } catch (e) {
        console.log(e);
        errors.value = e.response.data.errors;
    }
}

function resetAlerts(){
    displayInsertLocationSuccessAlert.value = false;
    displayDeleteLocationSuccessAlert.value = false;
    displayUpdateLocationSuccessAlert.value = false;
}
</script>

<style scoped>
section {
    padding: 1rem;
    background-color: #f9fafb;
    border-radius: 0.5rem;
    border: 2px solid #d1d5db;
}

.select-employee-table-wrapper {
    height: 16rem;
    overflow-y: auto;
}

.table-striped tbody:nth-child(even) {
    background-color: white;
}

.employee-location-table-wrapper {
    height: 100%;
    max-height: 20rem;
    overflow-y: auto;
}

thead {
    top: 0;
    position: sticky;
    background-color: white;
}

td {
    cursor: pointer;
}
</style>
