import axios from "axios";

import constructFetchError from "./constructFetchError";

export function useReconMainWarehouseActions() {
    const postRRToOriginalReplacement = async (originalBarcode, replacementBarcode) => {
        try {
            const postRRToOriginalReplacementResponse =
                await axios.post(route('api.recon-main-warehouse-actions.rr-to-original-replacements.store'), {
                    ORIGINAL_BARCODE: originalBarcode,
                    RR_BARCODE: replacementBarcode,
                });
            return postRRToOriginalReplacementResponse;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while replacing with original barcode.');
        }
    };

    const postMainWarehouseReturn = async (barcode, commentId) => {
        try {
            const postMainWarehouseReturnResponse =
                await axios.post(route('api.recon-main-warehouse-actions.main-warehouse-returns.store'), {
                    BARCODE: barcode,
                    PULLOUT_COMMENT_ID: commentId,
                });
            return postMainWarehouseReturnResponse;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while returning barcode to main warehouse.');
        }
    };
    
    return {
        postRRToOriginalReplacement,
        postMainWarehouseReturn,
    };
}
