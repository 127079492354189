<template>
    <table class="listbox table table-lg table-bordered table-striped table-hover">
        <thead v-if="this.$slots.header">
            <tr>
                <slot name="header"></slot>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(row, key) in model" :key="key" @click="rowclick(row, $event)" ref="rowElements">
                <slot name="row" :row="row" :key="key"></slot>
            </tr>
        </tbody>
    </table>
</template>

<script setup>
import { useAttrs, ref } from 'vue'
const props = defineProps({
    model: Array,
});
const attrs = useAttrs();
const emit = defineEmits(['rowClick'])
const rowElements = ref(null);

function rowclick(row, event) {
    if (event) event.preventDefault()

    let rowClicked = event.currentTarget;
    for (let rowElement of rowElements.value)rowElement.classList.remove('table-active');
    rowClicked.classList.add('table-active');

    emit('rowClick', row);
}

</script>

