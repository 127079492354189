import { ref } from 'vue';

import axios from 'axios';

import constructFetchError from "./constructFetchError";

export function useSampleApprovals() {
    const sampleApprovals = ref(null);
    const totalRecords = ref(null);

    const getSampleApprovals = async (params) => {
        try {
            const sampleApprovalsResponse = await axios.get(route('api.sample-approvals.index', params));
            sampleApprovals.value = sampleApprovalsResponse.data.data;
            totalRecords.value = sampleApprovalsResponse.data.meta.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching sample approvals.');
        }
    };

    // This makes the approval request. Confusing because the request is named sample_approval in the system
    const postSampleApproval = async (sampleId, sampleApproval) => {
        try {
            const postSampleApprovalResponse = await axios.post(route('api.samples.sample-approvals.store', {
                sample: sampleId
            }), sampleApproval);
            return postSampleApprovalResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while making approval request.');
        }
    };

    // This approves the request
    const postApproval = async (id) => {
        try {
            return await axios.post(route('api.sample-approvals.approvals.store', {
                sample_approval: id
            }));
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while approving.');
        }
    };

    const postDisapproval = async (id) => {
        try {
            return await axios.post(route('api.sample-approvals.disapprovals.store', {
                sample_approval: id
            }));
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while disapproving.');
        }
    };
    
    return { sampleApprovals, totalRecords, getSampleApprovals, postSampleApproval, postApproval, postDisapproval };
}
