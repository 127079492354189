<template>
    <Card>
        <DataTable lazy paginator :rows="10" :value="otherInfoTable" :total-records="otherTotalRecords"
            filter-display="row" v-model:filters="filters"
            v-model:selection="selectedOtherInfo" selection-mode="single" data-key="EMPID"
            @page="e => { onPage(e); getOtherInfoTable(); }"
            @filter="e => { onFilter(e); getOtherInfoTable(); }"
            :row-class="data => [{'bg-primary text-white': data.EMPID == selectedOtherInfo?.EMPID}]"
            :pt="{ table: { class: 'table table-bordered table-hover' } }">
            <Column field="EMPID" header="Employee ID" class="small"/>
            <Column field="EMPNAME" header="Employee Name" class="small"/>
            <Column field="SOLD_AMT" header="Sold AMT" class="small"/>
            <Column field="SOLD_DAYS" header="Sold Days" class="small"/>
            <Column field="COMMISSION_PCT" header="Commission PCT" class="small"/>
            <Column field="COMMISSION_AMT" header="Commission AMT" class="small"/>
            <Column field="MISSING_PCT" header="Missing PCT" class="small"/>
            <Column field="MISSING_AMT" header="Missing AMT" class="small"/>
            <template #empty>Empty summary.</template>
        </DataTable>
    </Card>
</template>

<script setup>
import axios from 'axios';
import { ref, watch } from 'vue';

import Card from '@/components/utils/Card.vue';
import { useDataTableParams } from '@/composables/data/dataTableParams';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';

const { params, filters, onPage, onFilter } = useDataTableParams(
    ['EMPID', 'SOLD_AMT', 'SOLD_DAYS']
);

const props = defineProps({
  location2: {
    type: Object,
    required: true
  },
  timePeriod: {
    type: Object,
    required: true
  }
});

watch(() => props.location2, () => {
    getOtherInfoTable();
})

const selectedOtherInfo = ref(null);
const otherTotalRecords = ref(null);
const otherInfoTable = ref([]);

async function getOtherInfoTable() {
    try {
        const response = await axios.get(route('api.recon-store-summary-dist.index', {
            ...params.value,
            TIME_PERIOD: props.timePeriod.TIME_PERIOD,
            LOCATION: props.location2.LOCATION,
        }))

        otherInfoTable.value = response.data.data;
        otherTotalRecords.value = response.data.meta.total;
    } catch (e) {
        console.log(e);
    }
}

defineExpose({ getOtherInfoTable });
</script>
