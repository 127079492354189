<template>
    <Card>
        <template #header>
            Inventory Records
        </template>
        <DataTable
            lazy
            :value="inventoryRecords"
            :total-records="inventoryTotalRecords"
            paginator
            :rows="10"
            @page="e => { onPage(e); fetchInventoryRecords(); }"
            filter-display="row"
            v-model:filters="filters"
            @filter="tableOnFilter"
            sort-mode="multiple"
            @sort="e => { onSort(e); fetchInventoryRecords(); }"
            :pt="{ table: { class: 'table table-bordered table-hover' }}">
            <Column field="STYLE" header="Style" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="DATE" header="Date" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="SERIAL" header="Serial" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
                bodyCell: { class: 'barcode' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="SEQ_NO" header="Seq. No." sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="PREV_LOC" header="Prev. Loc." sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="LOCATION" header="Location" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="COMMENT_RPT_DATE" header="Comment Date" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="SOLD_DT" header="Sold Date" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <template #footer>
                {{ inventoryTotalRecords ?? 0 }} total records.
            </template>
            <template #empty>
                <div class="text-center">
                    No data.
                </div>
            </template>
        </DataTable>
    </Card>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';

import { useLoadingFlagsStore } from '@/stores/loadingFlags';
import { useToastsStore } from '@/stores/toasts';
import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useInventory } from '@/composables/data/inventory';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

import Card from '@/components/utils/Card.vue';

const props = defineProps({
    style: String,
    date: String,
    serial: String,
    refreshFlag: null,
});

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const { params, filters, onPage, onFilter, onSort } = useDataTableParams([
    'STYLE',
    'DATE',
    'SERIAL',
    'SEQ_NO',
    'PREV_LOC',
    'LOCATION',
    'COMMENT_RPT_DATE',
    'SOLD_DT',
]);

const {
    inventoryRecords,
    inventoryTotalRecords,
    getInventoryRecords,
} = useInventory();

const fetchTimeout = ref(null);

async function fetchInventoryRecords() {
    inventoryRecords.value = null;

    if (!props.style || !props.date || !props.serial) {
        inventoryTotalRecords.value = null;
        return;
    }

    loadingFlags.add("fetchInventoryRecords");
    try {
        await getInventoryRecords({
            ...params.value,
            hardFilters: {
                STYLE: {
                    matchMode: 'equals',
                    value: props.style,
                },
                DATE: {
                    matchMode: 'equals',
                    value: props.date,
                },
                SERIAL: {
                    matchMode: 'equals',
                    value: props.serial,
                },
            },
        });
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("fetchInventoryRecords");
}

function tableOnFilter(e) {
    onFilter(e);
    if (fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(fetchInventoryRecords, 300);
}

onMounted(fetchInventoryRecords);

watch(() => [props.style, props.date, props.serial, props.refreshFlag], fetchInventoryRecords);
</script>
