<template>
    <form @submit.prevent="addNOBC">
        <Card>
            <template #header>
                Add NOBC to Inventory Check
            </template>
            <FormInput type="text" v-model="inventoryCheckNoBarcode.STYLE"
                label="Style" id-prefix="style"
                :errors="errors.STYLE"
                class="mb-1" />
            <div class="text-end mb-3">
                <button type="button" class="btn btn-primary"
                    @click="_ => { isSelectingStyle = true; }">
                    Select
                </button>
            </div>
            <FormInput type="text" v-model="inventoryCheckNoBarcode.COLOR"
                label="Color" id-prefix="color"
                :errors="errors.COLOR"
                class="mb-1" />
            <div class="text-end">
                <button type="button" class="btn btn-primary"
                    @click="_ => { isSelectingColor = true; }">
                    Select
                </button>
            </div>
            <template #footer>
                <div class="text-end">
                    <button type="submit" class="btn btn-primary">
                        Add NOBC
                    </button>
                </div>
            </template>
        </Card>
    </form>
    <SelectStyle :is-open="isSelectingStyle"
        @select="selectedStyle => {
            inventoryCheckNoBarcode.STYLE = selectedStyle.STYLE;
            isSelectingStyle = false;
        }"
        @close="_ => { isSelectingStyle = false; }" />
    <SelectColor :is-open="isSelectingColor"
        @select="selectedColor => {
            inventoryCheckNoBarcode.COLOR = selectedColor.COLOR;
            isSelectingColor = false;
        }"
        @close="_ => { isSelectingColor = false; }" />
</template>

<script setup>
import { ref } from 'vue';

import { useStyles } from '@/composables/data/styles';
import { useColors } from '@/composables/data/colors';

import Card from '@/components/utils/Card';
import FormInput from '@/components/utils/FormInput';

import SelectColor from '@/components/utils/SelectColor';
import SelectStyle from '@/components/utils/style/SelectStyle';

const emit = defineEmits(['add']);

const isSelectingStyle = ref(false);
const isSelectingColor = ref(false);

const { getStyle } = useStyles();
const { getColor } = useColors();

const errors = ref({});

const inventoryCheckNoBarcode = ref({
    STYLE: null,
    COLOR: null,
});

async function addNOBC() {
    errors.value = {};

    try {
        await getStyle(inventoryCheckNoBarcode.value.STYLE);
    } catch(e) {
        errors.value.STYLE = ['Invalid style.'];
    }

    try {
        await getColor(inventoryCheckNoBarcode.value.COLOR);
    } catch(e) {
        errors.value.COLOR = ['Invalid color.'];
    }

    if(Object.keys(errors.value).length > 0)
        return;

    emit('add', inventoryCheckNoBarcode.value);
    inventoryCheckNoBarcode.value = {
        STYLE: null,
        COLOR: null,
    };
}
</script>
