import { ref } from 'vue';

import axios from 'axios';

import constructFetchError from "./constructFetchError";

export function useDaysOfWork() {
    const topFiveWeeklyAttendance = ref(null);

    const getTopFiveWeeklyAttendance = async (timePeriod) => {
        try {
            const getTopFiveWeeklyAttendanceResponse = await axios.get(route('api.time-periods.top-five-weekly-attendance.show', {
                time_period: timePeriod
            }));
            topFiveWeeklyAttendance.value = getTopFiveWeeklyAttendanceResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching top five attendance.');
        }
    };

    return { topFiveWeeklyAttendance, getTopFiveWeeklyAttendance };
}
