<template>
    <div v-if="messages?.length > 0" class="vstack">
        <div v-for="message, index in messages" role="alert" class="alert fade show" :class="{
            'alert-dismissible': message.dismissible,
            'alert-primary': message.type === 'PRIMARY',
            'alert-success': message.type === 'SUCCESS',
            'alert-danger': message.type === 'ERROR',
            'alert-warning': message.type === 'WARNING'
        }">
            {{ message.content }}
            <button v-if="message.dismissible" class="btn-close" aria-label="Close"
                @click="_ => { removeFlashMessage(index); }">
            </button>
            <template v-else-if="message.onYes != null || message.onNo != null">
                <a href="javascript:void(0)"
                    @click="() => {message.onYes?.(); removeFlashMessage(index);}">Yes</a>
                <a href="javascript:void(0)"
                    @click="() => {message.onNo?.(); removeFlashMessage(index);}" class="ms-2">No</a>
            </template>
        </div>
    </div>
</template>

<script setup>
import { useFlashMessages } from "../../composables/flashmessages";

const { messages, removeFlashMessage } = useFlashMessages();
</script>
