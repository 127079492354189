import { ref } from "vue";

import axios from 'axios';

import constructFetchError from "./constructFetchError";

export function useStyleSkuPrices() {
    const styleSkuPrices = ref(null);

    const getStyleSkuPrices = async (style, params) => {
        try {
            const getStyleSkuPricesResponse = await axios.get(route('api.styles.style-sku-prices.index', {
                style: style,
                ...params
            }));
            styleSkuPrices.value = getStyleSkuPricesResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching style SKU prices.');
        }
    };
    
    return { styleSkuPrices, getStyleSkuPrices };
}
