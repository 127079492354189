<template>
    <div class="row g-3">
        <div class="col-md-4">
            <ReconInfoGenerationsTableCard :refresh-flag="reconInfoGenerationsTableRefreshFlag" class="mb-3" />
            <TimePeriodTableCard v-model="selectedTimePeriod" class="mb-3" />
            <form @submit.prevent="generateReconInfo" class="mb-3">
                <Card>
                    <template #header>
                        Details
                    </template>
                    <!-- Time Period errors are shown in start date and end date -->
                    <FormInput v-model="timePeriodStartDate" disabled
                        label="Start Date" id-prefix="startDateDisplay"
                        class="mb-3"
                        :errors="errors.TIME_PERIOD" />
                    <FormInput v-model="timePeriodEndDate" disabled
                        label="End Date" id-prefix="endDateDisplay"
                        class="mb-3"
                        :errors="errors.TIME_PERIOD" />
                    <FormInput v-model="startAdjDate"
                        label="Start Adj Date" id-prefix="startAdjDate"
                        class="mb-3"
                        :errors="errors.START_ADJ_DATE" />
                    <FormInput v-model="endAdjDate"
                        label="End Adj Date" id-prefix="endAdjDate"
                        class="mb-3"
                        :errors="errors.END_ADJ_DATE" />
                    <FormSelect v-model="reconType"
                        :options="[
                            {value: 'R', verbose: 'REGULAR'},
                            {value: 'A', verbose: 'ALTERNATE'},
                        ]"
                        :option-value="option => option.value"
                        :option-to-string="option => option.verbose"
                        label="Recon Type" id-prefix="reconType"
                        class="mb-3"
                        :errors="errors.RECON_TYPE" />
                    <template #footer>
                        <div class="text-end">
                            <button type="submit" class="btn btn-primary"
                                :disabled="isGeneratingReconInfo">
                                <span v-if="isGeneratingReconInfo" class="spinner-border spinner-border-sm"
                                    role="status" aria-hidden="true"></span>
                                Generate Recon Info (only do this once per store)
                            </button>
                        </div>
                    </template>
                </Card>
            </form>
            <Card>
                <template #header>
                    Actions
                </template>
                <div class="d-grid gap-3">
                    <button type="button" class="btn btn-primary"
                        :disabled="!selectedLocationReconParm?.id"
                        @click="investigate">
                        Investigate
                    </button>
                    <button type="button" class="btn btn-primary"
                        :disabled="!selectedLocationReconParm?.id"
                        @click="viewDetailReconReport">
                        1. Detail Recon Report
                    </button>
                    <button type="button" class="btn btn-primary" disabled>
                        2. Summary Recon Report
                    </button>
                    <button type="button" class="btn btn-primary"
                        :disabled="!selectedTimePeriod"
                        @click="viewDetailReconReportAllStores">
                        3. Detail Recon Report (all stores)
                    </button>
                </div>
            </Card>
        </div>
        <div class="col-md-8">
            <ReconLocationsTableCard :time-period="selectedTimePeriod"
                v-model="selectedLocationReconParm"
                class="mb-3">
            </ReconLocationsTableCard>
            <InventoryCheckCountsTableCard :location="selectedLocationReconParm?.LOCATION"
                v-model="selectedInventoryCheckCount" class="mb-3" />
            <DuplicateInventoryCheckBarcodesTableCard
                @delete-duplicate-success="message => {
                    addFlashMessage('SUCCESS', message);
                    scrollToTop();
                }"
                @error="message => {
                    addFlashMessage('ERROR', message);
                    scrollToTop();
                }" />
        </div>
    </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';

import { useFlashMessages } from '@/composables/flashmessages';
import { useReconParms } from '@/composables/data/reconParms';
import { useReconInitializations } from '@/composables/data/reconInitializations';

import Card from '@/components/utils/Card.vue';
import FormInput from '@/components/utils/FormInput.vue';
import FormSelect from '@/components/utils/FormSelect.vue';

import ReconInfoGenerationsTableCard from './ReconInfoGenerationsTableCard.vue';
import TimePeriodTableCard from './TimePeriodTableCard.vue';
import ReconLocationsTableCard from './ReconLocationsTableCard.vue';
import InventoryCheckCountsTableCard from '../../utils/InventoryCheckCountsTableCard.vue';
import DuplicateInventoryCheckBarcodesTableCard from './DuplicateInventoryCheckBarcodesTableCard.vue';

import moment from 'moment';

import scrollToTop from '@/utils/scrollToTop';

const { addFlashMessage } = useFlashMessages();

const { reconParms, getReconParms } = useReconParms();
const { postReconInitialization } = useReconInitializations();

const selectedTimePeriod = ref(null);
const selectedLocationReconParm = ref(null);
const selectedInventoryCheckCount = ref(null);

const timePeriodStartDate = computed(() => selectedTimePeriod.value?.START_DATE);
const timePeriodEndDate = computed(() => selectedTimePeriod.value?.END_DATE);

const startAdjDate = ref(null);
const endAdjDate = ref(null);
const reconType = ref(null);

const errors = ref({});

const isGeneratingReconInfo = ref(false);

const reconInfoGenerationsTableRefreshFlag = ref(false);

watch(selectedLocationReconParm, () => {
    selectedInventoryCheckCount.value = null;

    startAdjDate.value = null;
    endAdjDate.value = null;
    reconType.value = null;

    if(!selectedLocationReconParm.value?.id)
        return;

    startAdjDate.value = selectedLocationReconParm.value.RECON_RAW_ADJ_START_DATE;
    endAdjDate.value = selectedLocationReconParm.value.RECON_RAW_ADJ_END_DATE;
    reconType.value = selectedLocationReconParm.value.RECON_FLAG;
});

watch(selectedInventoryCheckCount, async () => {
    if(!selectedInventoryCheckCount.value)
        return;

    try {
        await getReconParms({
            filters: {
                TIME_PERIOD: {
                    value: selectedTimePeriod.value.TIME_PERIOD
                },
                LOCATION: {
                    value: selectedLocationReconParm.value.LOCATION
                }
            }
        });

        const reconParm = reconParms.value[0];

        const fromDate = moment(timePeriodStartDate.value);
        const toDate = moment(timePeriodEndDate.value);
        const inventoryDate = moment(selectedInventoryCheckCount.value.INVENTORY_DONE);

        if (reconParm) {
            startAdjDate.value = reconParm.RECON_RAW_ADJ_START_DATE;
            endAdjDate.value = reconParm.RECON_RAW_ADJ_END_DATE;
            reconType.value = reconParm.RECON_FLAG;
        } else if (inventoryDate.isSameOrAfter(fromDate) && inventoryDate.isSameOrBefore(toDate)) {
            reconType.value = 'A';
            if (!reconParm) {
                startAdjDate.value = inventoryDate.format('YYYY-MM-DD');
                endAdjDate.value = toDate.format('YYYY-MM-DD');
            }
        } else {
            reconType.value = 'R';
            if (!reconParm) {
                startAdjDate.value = toDate.add(1, 'days').format('YYYY-MM-DD');
                endAdjDate.value = inventoryDate.subtract(1, 'days').format('YYYY-MM-DD');
            }
        }
    } catch(e) {
        addFlashMessage('ERROR', e.message);
        scrollToTop();
    }
});

async function generateReconInfo() {
    isGeneratingReconInfo.value = true;
    errors.value = {};
    try {
        const response = await postReconInitialization({
            TIME_PERIOD: selectedTimePeriod.value.TIME_PERIOD,
            INVENTORY_DATE: selectedInventoryCheckCount.value.INVENTORY_DONE,
            LOCATION: selectedLocationReconParm.value.LOCATION,
            START_ADJ_DATE: startAdjDate.value,
            END_ADJ_DATE: endAdjDate.value,
            RECON_TYPE: reconType.value
        });
        addFlashMessage('SUCCESS', response.data.message);
        // Trigger refresh of table
        reconInfoGenerationsTableRefreshFlag.value = !reconInfoGenerationsTableRefreshFlag.value;
    } catch(e) {
        addFlashMessage('ERROR', e.message);
        errors.value = e.errors ?? {};
    }
    scrollToTop();
    isGeneratingReconInfo.value = false;
}

function investigate() {
    window.open(route('inventory.report-recon-full.investigation-form', {
        recon_parm: selectedLocationReconParm.value.id
    }));
}

function viewDetailReconReport() {
    window.open(route('api.recon-parms.detail-report.show', {
        recon_parm: selectedLocationReconParm.value.id
    }));
}

function viewDetailReconReportAllStores() {
    window.open(route('api.recon.detail-report-all-stores.show', {
        time_period: selectedTimePeriod.value.TIME_PERIOD,
        LOCATION: selectedLocationReconParm.value?.LOCATION,
    }));
}
</script>
