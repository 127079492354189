<template>
    <Card :class="class">
        <button type="button" class="btn btn-primary mb-2" :disabled="employee === null"
            @click="checkTime">
            Check Time
        </button>
        <table v-if="!loadingFlagStore.has('checkTime')" class="table table-sm table-bordered table-striped table-hover">
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Day of Week</th>
                    <th>Total Hours</th>
                </tr>
            </thead>
            <tbody>
                <tr v-if="employeeHoursPerDay.length > 0" v-for="employeeHoursOnDay in employeeHoursPerDay">
                    <td>{{ employeeHoursOnDay.date.format('MMM DD, YYYY') }}</td>
                    <td>{{ employeeHoursOnDay.dayOfWeek }}</td>
                    <td>{{ employeeHoursOnDay.hours }}</td>
                </tr>
                <tr v-else>
                    <td colspan="3" class="text-center">No time information saved.</td>
                </tr>
            </tbody>
        </table>
        <div v-else class="text-center">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    </Card>
</template>

<script setup>
import { inject, ref, watch } from 'vue';

import Card from '../../../utils/Card.vue';

import axios from 'axios';
import moment from 'moment';

const props = defineProps({
    employee: Object,
    timePeriod: Object,
    class: String
});

const emit = defineEmits(['error']);

const loadingFlagStore = inject('loadingFlagStore');

const employeeHoursPerDay = ref([]);

watch(() => props.employee, () => {
    employeeHoursPerDay.value = [];
});

async function checkTime() {
    loadingFlagStore.value.add('checkTime');

    try {
        const employeeHoursResponse = await axios.get(
            route('api.employees.hours.time-period.index',
                [props.employee.EMPID, props.timePeriod.TIME_PERIOD]));
        const allHoursObj = employeeHoursResponse.data.data
            .reduce((allHours, employeeHoursOnJob) => {
                const newAllHours = {...allHours};
                const dateOfJob = moment(employeeHoursOnJob.DATE, "YYYY-MM-DD");
                newAllHours[dateOfJob] = {
                    date: dateOfJob,
                    dayOfWeek: dateOfJob.format('dddd'),
                    hours: employeeHoursOnJob.HOURS + (newAllHours[dateOfJob]?.hours || 0)
                };
                return newAllHours;
            }, {});
        employeeHoursPerDay.value = Object.keys(allHoursObj).map(jobDate => allHoursObj[jobDate])
            .sort((a, b) => {
                if(a.date.isBefore(b.date))
                    return -1;
                else if(a.date.isAfter(b.date))
                    return 1;
                return 0;
            });
    } catch(e) {
        console.log(e);
        emit('error', 'Unable to fetch employee times. Please contact your administrator.');
    }

    loadingFlagStore.value.delete('checkTime');
}
</script>
