<template>
    <Card>
        <template #header>
            Years of Service Report
        </template>
        <FormInput type="number" v-model="minimumYears" min="1"
            label="Minimum Years" id-prefix="minimum-years"
            class="mb-3" />
        <template #footer>
            <div class="text-end">
                <button type="button" class="btn btn-primary"
                    :disabled="!minimumYears"
                    @click="generateReport">
                    Generate
                </button>
            </div>
        </template>
    </Card>
</template>

<script setup>
import { ref } from 'vue';

import Card from '@/components/utils/Card.vue';
import FormInput from '@/components/utils/FormInput.vue';

const minimumYears = ref(null);

function generateReport() {
    window.open(route('api.employees.accounting-years-of-service-report.show', {
        minimum_years: minimumYears.value
    }));
}
</script>
