export function constructBarcode(style, date, serial) {
    return style.padEnd(10, ' ') + date + serial;
}

export function deconstructBarcode(barcode) {
    if(barcode.length != 19)
        throw new Error('Invalid barcode.');

    const style = barcode.substring(0, 10).trim();
    const date = barcode.substring(10, 14);
    const serial = barcode.substring(14);

    if(isNaN(parseInt(serial)))
        throw new Error('Invalid serial.');

    return {
        style: barcode.substring(0, 10).trim(),
        date: barcode.substring(10, 14),
        serial: barcode.substring(14),
    };
}
