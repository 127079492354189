<template>
    <transition name="fade">
        <div v-if="props.toggle" class="preloader-container">
            <div class="preloader">
                <div class="spinner-border text-primary" role="status"></div>
            </div>
        </div>
    </transition>
</template>


<script setup>
const props = defineProps({
    toggle: Boolean,
});
</script>

<style scoped>
.preloader-container {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: whitesmoke;
    z-index: 999999999;
}

.preloader {
    position: absolute;
    top: 50%;
    right: 50%;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>