<template>
    <div class="row g-3 my-3">
        <div class="col-xl-6">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-barcode me-2"></i>Generated Barcodes (please validate)
                </legend>
                <DataTable :value="generatedBarcodes"
                    paginator
                    :rows="10"
                    filter-display="row"
                    v-model:filters="generatedBarcodesFilters"
                    :pt="{ table: { class: 'table table-bordered table-hover' } }">
                    <Column field="BARCODE"
                        header="Barcode"
                        :pt="{
                            bodyCell: { class: 'barcode' },
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search by Barcode"
                            />
                        </template>
                    </Column>
                    <Column field="COLOR"
                        header="Color"
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search by Color"
                            />
                        </template>
                    </Column>
                    <Column
                        field="PRICE"
                        header="Price"
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search by Price"
                            />
                        </template>
                    </Column>
                    <template #empty>No barcodes.</template>
                </DataTable>
                <hr class="my-4" />
                <div class="text-end">
                    <button type="button"
                        class="btn btn-primary"
                        @click="exportNewBarcodesAsSpreadsheet">
                        <i class="icon ph-bold ph-file-xls me-2"></i>Export as Spreadsheet
                    </button>
                </div>
            </fieldset>
        </div>
        <div class="col-xl-6">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-gps-fix me-2"></i>Select Location for New Bags
                </legend>
                <form @submit.prevent="writeBarcodesToInventory">
                    <DataTable
                        :value="commentDates"
                        paginator
                        :rows="5"
                        filter-display="row"
                        v-model:filters="commentDatesFilters"
                        v-model:selection="commentDate"
                        selection-mode="single"
                        data-key="date"
                        :pt="{ table: { class: 'table table-bordered table-hover' } }"
                        class="mb-3">
                        <Column
                            field="date"
                            header="Date"
                            :pt="{
                                filterInput: { class: 'input-group input-group-sm' },
                                filterMenuButton: { class: 'd-none' },
                                headerFilterClearButton: { class: 'd-none' },
                            }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText
                                    type="text"
                                    v-model="filterModel.value"
                                    @input="filterCallback()"
                                    class="form-control"
                                    placeholder="Search by Date"
                                    @keydown.enter.prevent=""
                                />
                            </template>
                        </Column>
                        <template #empty>No dates.</template>
                    </DataTable>
                    <FormSelect
                        label="Comment Type"
                        v-model="commentType"
                        is-horizontal
                        :options="commentTypes"
                        id-prefix="commentType"
                        class="mb-md-1 mb-2"
                        label-class="col-xl-4 col-md-5 label-sm"
                        select-class="form-select-sm"
                        select-container-class="col-xl-8 col-md-7 col-12"
                    />
                    <FormSelect
                        label="Comment"
                        v-model="comment"
                        is-horizontal
                        required
                        :options="comments"
                        :option-value="(comment) => comment.COMMENT_ID"
                        :option-to-string="
                            (comment) =>
                                [
                                    comment.COMMENT_ID,
                                    comment.COMMENT_RPT_DATE,
                                    comment.COMMENT_TEXT,
                                    comment.LOCATION,
                                    comment.DESCRIPTION,
                                ].join(' | ')"
                        :errors="errors.COMMENT_ID"
                        class="mb-md-1 mb-2"
                        label-class="col-xl-4 col-md-5 label-sm"
                        select-class="form-select-sm"
                        select-container-class="col-xl-8 col-md-7 col-12"
                    />
                    <hr class="my-4" />
                    <div class="text-end">
                        <button type="submit" class="btn btn-primary">
                            <i class="icon ph-bold ph-file-plus me-2"></i>Add Barcodes to Database
                        </button>
                    </div>
                </form>
            </fieldset>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import FormSelect from "../../../utils/FormSelect.vue";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";
import { FilterMatchMode } from "primevue/api";
import handleFetchErrors from "../../../../utils/handleFetchErrors";
import scrollToTop from "../../../../utils/scrollToTop";
import { useFlashMessages } from "../../../../composables/flashmessages";

const { addFlashMessage } = useFlashMessages();

const generatedBarcodesFilters = ref({
    BARCODE: { value: null, matchMode: FilterMatchMode.CONTAINS },
    COLOR: { value: null, matchMode: FilterMatchMode.CONTAINS },
    PRICE: { value: null, matchMode: FilterMatchMode.CONTAINS },
});
const commentDatesFilters = ref({
    date: { value: null, matchMode: FilterMatchMode.CONTAINS },
});

const generatedBarcodes = ref();

const commentDates = ref();
const commentTypes = [
    "Initial Inventory",
    "Delivery",
    "Pull-out",
    "Sales",
    "Reconciliation",
    "New Stock",
    "Return",
    "Replacement",
];
const comments = ref();

const errors = ref({});
const commentDate = ref(null);
const commentType = ref(null);
const comment = ref(null);

async function fetchAvailableCommentDates() {
    try {
        const commentDatesResponse = await axios.get(
            route("api.comments.dates.index")
        );
        commentDates.value = commentDatesResponse.data.data.map(
            (commentDate) => ({
                date: commentDate,
            })
        );
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, "Error while fetching available comment dates.");
        scrollToTop();
    }
}

async function fetchGeneratedBarcodes() {
    try {
        const newBarcodeOutputsResponse = await axios.get(
            route("api.new-barcode-outputs.index")
        );
        generatedBarcodes.value = newBarcodeOutputsResponse.data.data;
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, "Error while fetching generated barcodes.");
        scrollToTop();
    }
}

onMounted(() => {
    fetchGeneratedBarcodes();
    fetchAvailableCommentDates();
});

async function fetchComments() {
    comments.value = null;
    comment.value = null;
    if (!commentDate.value || !commentType.value) return;

    try {
        const commentsResponse = await axios.get(
            route("api.comments.index", {
                MONTH: commentDate.value,
                COMMENT_TYPE: commentType.value,
                COMMENT_STATUS: "O",
            })
        );
        comments.value = commentsResponse.data.data;
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, "Error while fetching comments.");
        scrollToTop();
    }
}

watch([commentDate, commentType], fetchComments);

async function writeBarcodesToInventory() {
    errors.value = {};

    if (window.prompt('Type "PROCEED" to write to inventory.') != "PROCEED")
        return;

    try {
        await axios.post(route("api.new-barcode-outputs.movements.store"), {
            COMMENT_ID: comment.value,
        });
        addFlashMessage(
            "SUCCESS",
            "Successfully wrote barcodes to inventory table."
        );
    } catch (e) {
        console.log(e);
        errors.value =
            handleFetchErrors(e, "Error while writing barcodes.") ?? {};
    }
    scrollToTop();
}

function exportNewBarcodesAsSpreadsheet() {
    window.open(route("api.new-barcode-outputs.spreadsheet.show"));
}
</script>
