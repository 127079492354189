<template>
    <div v-if="isOpen" class="pop-up d-flex justify-content-center align-items-center">
        <Card>
            <template #header>
                <div class="d-flex justify-content-between pt-2">
                    <h4 class="title">Details</h4>
                    <button type="button" class="btn-close" @click="emit('close')"></button>
                </div>
            </template>
            <h3>Location Discrepancies</h3>
            <template v-if="details?.location_warnings?.length">
                <DataTable :value="details.location_warnings"
                    paginator :rows="10"
                    v-model:filters="locationWarningsFilters" filter-display="row"
                    :pt="{ table: { class: 'table table-bordered table-hover' }}">
                    <Column field="LOCATION" header="Location" :pt="{
                        filterInput: { class: 'input-group input-group-sm' },
                        filterMenuButton: { class: 'd-none' },
                        headerFilterClearButton: { class: 'd-none' } }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText type="text" v-model="filterModel.value"
                                @input="filterCallback"
                                class="form-control" placeholder="Search" />
                        </template>
                    </Column>
                    <Column field="FROM_REMITTANCE" header="Uploaded Remittance Advice" :pt="{
                        filterInput: { class: 'input-group input-group-sm' },
                        filterMenuButton: { class: 'd-none' },
                        headerFilterClearButton: { class: 'd-none' } }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText type="text" v-model="filterModel.value"
                                @input="filterCallback"
                                class="form-control" placeholder="Search" />
                        </template>
                    </Column>
                    <Column field="FROM_SALES_ACTUAL_SUMMARY" header="Sales Actual Summary" :pt="{
                        filterInput: { class: 'input-group input-group-sm' },
                        filterMenuButton: { class: 'd-none' },
                        headerFilterClearButton: { class: 'd-none' } }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText type="text" v-model="filterModel.value"
                                @input="filterCallback"
                                class="form-control" placeholder="Search" />
                        </template>
                    </Column>
                    <Column header="Discrepancy" :pt="{
                        filterInput: { class: 'input-group input-group-sm' },
                        filterMenuButton: { class: 'd-none' },
                        headerFilterClearButton: { class: 'd-none' } }">
                        <template #body="{ data }">
                            {{ (data.FROM_REMITTANCE - data.FROM_SALES_ACTUAL_SUMMARY).toFixed(2) }}
                        </template>
                    </Column>
                </DataTable>
            </template>
            <template v-else>
                <p>No location discrepancies.</p>
            </template>
            <h3>Errors</h3>
            <template v-if="details?.errors?.length">
                <DataTable :value="details.errors"
                    paginator :rows="10"
                    v-model:filters="errorsFilters" filter-display="row"
                    :pt="{ table: { class: 'table table-bordered table-hover' }}">
                    <Column field="message" header="Message" :pt="{
                        filterInput: { class: 'input-group input-group-sm' },
                        filterMenuButton: { class: 'd-none' },
                        headerFilterClearButton: { class: 'd-none' } }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText type="text" v-model="filterModel.value"
                                @input="filterCallback"
                                class="form-control" placeholder="Search" />
                        </template>
                    </Column>
                    <Column field="file_type" header="From File" :pt="{
                        filterInput: { class: 'input-group input-group-sm' },
                        filterMenuButton: { class: 'd-none' },
                        headerFilterClearButton: { class: 'd-none' } }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText type="text" v-model="filterModel.value"
                                @input="filterCallback"
                                class="form-control" placeholder="Search" />
                        </template>
                    </Column>
                </DataTable>
            </template>
            <template v-else>
                <p>No errors.</p>
            </template>
            <h3>Warnings</h3>
            <template v-if="details?.other_warnings?.length">
                <DataTable :value="details.other_warnings"
                    paginator :rows="10"
                    v-model:filters="otherWarningsFilters" filter-display="row"
                    :pt="{ table: { class: 'table table-bordered table-hover' }}">
                    <Column field="message" header="Message" :pt="{
                        filterInput: { class: 'input-group input-group-sm' },
                        filterMenuButton: { class: 'd-none' },
                        headerFilterClearButton: { class: 'd-none' } }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText type="text" v-model="filterModel.value"
                                @input="filterCallback"
                                class="form-control" placeholder="Search" />
                        </template>
                    </Column>
                    <Column field="file_type" header="From File" :pt="{
                        filterInput: { class: 'input-group input-group-sm' },
                        filterMenuButton: { class: 'd-none' },
                        headerFilterClearButton: { class: 'd-none' } }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText type="text" v-model="filterModel.value"
                                @input="filterCallback"
                                class="form-control" placeholder="Search" />
                        </template>
                    </Column>
                </DataTable>
            </template>
            <template v-else>
                <p>No location warnings.</p>
            </template>
        </Card>
    </div>    
</template>

<script setup>
import { useDataTableParams } from '@/composables/data/dataTableParams';

import Card from '@/components/utils/Card';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

const props = defineProps({
    details: Object,
    isOpen: Boolean,
});

const emit = defineEmits(['close']);

const { filters: locationWarningsFilters } = useDataTableParams([
    'LOCATION', 'FROM_REMITTANCE', 'FROM_SALES_ACTUAL_SUMMARY'
]);

const { filters: errorsFilters } = useDataTableParams([
    'message', 'file_type'
]);

const { filters: otherWarningsFilters } = useDataTableParams([
    'message', 'file_type'
]);
</script>

<style scoped>
.pop-up {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: rgb(0, 0, 0, 0.5);
}

.pop-up .card {
    width: 40%;
}

@media (max-width: 1199.98px) {
    .pop-up .card {
        width: 60%;
    }
}

@media (max-width: 991.98px) {
    .pop-up .card {
        width: 75%;
    }
}

@media (max-width: 767.98px) {
    .pop-up .card {
        width: 100%;
    }
}
</style>
