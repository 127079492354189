<template>
    <div v-if="isOpen" class="pop-up d-flex justify-content-center align-items-center">
        <Card>
            <template #header>
                <div class="d-flex justify-content-between pt-2">
                    <h4 class="title">Select Purchase Order</h4>
                    <button type="button" class="btn-close" @click="emit('close')"></button>
                </div>
            </template>
            <PurchaseOrdersTable v-model="selectedPurchaseOrder" />
            <template #footer>
                <div class="d-grid">
                    <button type="button" class="btn btn-primary"
                        :disabled="!selectedPurchaseOrder"
                        @click="_ => { emit('select', selectedPurchaseOrder); }">
                        Select Purchase Order
                    </button>
                </div>
            </template>
        </Card>
    </div>
</template>

<script setup>
import { ref } from 'vue';

import Card from '../Card.vue';

import PurchaseOrdersTable from '../tables/PurchaseOrdersTable.vue';

const props = defineProps({
    isOpen: Boolean,
});

const emit = defineEmits(['select', 'close'])

const selectedPurchaseOrder = ref(null);
</script>

<style scoped>
.pop-up {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: rgb(0, 0, 0, 0.5);
}

.pop-up .card {
    width: 100%;
}

@media (min-width: 768px) {
    .pop-up .card {
        width: 85%;
    }
}

@media (min-width: 992px) {
    .pop-up .card {
        width: 70%;
    }
}
</style>
