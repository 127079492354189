<template>
    <div v-if="isOpen" class="pop-up d-flex justify-content-center align-items-center">
        <Card>
            <template #header>
                <div class="d-flex justify-content-between pt-2">
                    <h4 class="title">Upload Govt Vouchers</h4>
                    <button type="button" class="btn-close" @click="emit('close')"></button>
                </div>
            </template>
            <div class="mb-3">
                <label for="imageFileInput" class="form-label">Images</label>
                <input type="file"
                    multiple
                    required
                    ref="imageFilesInput"
                    accept="image/jpeg"
                    :disabled="isUploading"
                    @change="e => { imageFiles = e.target.files; }"
                    class="form-control form-control-sm" />
            </div>
            <div v-if="currentUploadingFile" class="text-center">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                Currently uploading {{ currentUploadingFile }}
            </div>
            <DataTable
                :value="imageUploads"
                :key="uploadCounter"
                paginator
                :rows="10"
                :pt="{ table: { class: 'table table-sm table-bordered table-hover' }}">
                <Column field="name" header="Name" />
                <Column field="status" header="Status">
                    <template #body="{ data }">
                        <span v-if="data.status == 'SUCCESS'" class="badge bg-success">
                            Success
                        </span>
                        <span v-else-if="data.status == 'UPLOADING'" class="badge bg-primary">
                            Uploading
                        </span>
                        <span v-else-if="data.status == 'ERROR'" class="badge bg-danger">
                            Error
                        </span>
                    </template>
                </Column>
                <Column field="message" header="Message" />
                <template #empty>
                    <div class="text-center">
                        No data yet.
                    </div>
                </template>
                <template #footer>
                    {{ imageUploads?.length ?? 0 }} total records
                </template>
            </DataTable>
            <template #footer>
                <div class="text-end">
                    <button type="button" class="btn btn-primary"
                        :disabled="!imageFiles?.length || isUploading"
                        @click="uploadGovtVouchers">
                        <span v-if="isUploading" class="spinner-border spinner-border-sm"
                            role="status" aria-hidden="true"></span>
                        Upload
                    </button>
                </div>
            </template>
        </Card>
    </div>
</template>

<script setup>
import { ref } from 'vue';

import { useVouchers } from '@/composables/data/vouchers';

import Card from '@/components/utils/Card.vue';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';

const props = defineProps({
    isOpen: Boolean
});

const emit = defineEmits(['close']);

const { postGovtVoucherImage } = useVouchers();

const imageFilesInput = ref(null);
const imageFiles = ref(null);

const imageUploads = ref(null);
const isUploading = ref(false);

const currentUploadingFile = ref(null);

const uploadCounter = ref(0);

async function uploadGovtVouchers() {
    imageUploads.value = [];

    isUploading.value = true;
    for(const imageFile of imageFiles.value) {
        let imageUploadStatus = { name: imageFile.name, status: 'UPLOADING' };
        currentUploadingFile.value = imageFile.name;
        imageUploads.value.push(imageUploadStatus);
        try {
            const govtVoucherId = imageFile.name.split('.');
            govtVoucherId.pop(); // Remove extension
            const formData = new FormData();
            formData.append("image", imageFile);
            await postGovtVoucherImage(govtVoucherId.join('.'), formData);
            imageUploadStatus.status = 'SUCCESS';
        } catch(e) {
            console.log(e);
            imageUploadStatus.status = 'ERROR';
            imageUploadStatus.message = e.message;
        }
        uploadCounter.value++;
    }
    currentUploadingFile.value = null;
    isUploading.value = false;
    imageFilesInput.value.value = null;
    imageFiles.value = null;
}
</script>

<style scoped>
.pop-up {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: rgb(0, 0, 0, 0.5);
}

.pop-up .card {
    width: 100%;
}

@media (min-width: 768px) {
    .pop-up .card {
        width: 70%;
    }
}

@media (min-width: 992px) {
    .pop-up .card {
        width: 50%;
    }
}
</style>