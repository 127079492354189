<template>
    <div v-if="isOpen" class="pop-up d-flex justify-content-center align-items-center">
        <Card>
            <template #header>
                <div class="d-flex justify-content-between pt-2">
                    <h4 class="title">Select Order Supplier</h4>
                    <button type="button" class="btn-close" @click="emit('close')"></button>
                </div>
            </template>
            <DataTable
                :loading="isLoading"
                lazy
                :value="orderSuppliers"
                :total-records="totalRecords"
                paginator
                :rows="10"
                @page="e => { onPage(e); fetchOrderSuppliers(); }"
                filter-display="row"
                v-model:filters="filters"
                @filter="tableOnFilter"
                selection-mode="single"
                v-model:selection="selectedOrderSupplier"
                data-key="SUPPLIER_ID"
                :pt="{ table: { class: 'table table-bordered table-hover' } }">
                <Column field="SUPPLIER_ID" header="ID" :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                            class="form-control" placeholder="Search" />
                    </template>
                </Column>
                <Column field="SUPPLIER_NAME" header="Name" :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                            class="form-control" placeholder="Search" />
                    </template>
                </Column>
                <Column field="SUPPLIER_LOCATION" header="Location" :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                            class="form-control" placeholder="Search" />
                    </template>
                </Column>
                <Column field="CONTACT_PERSON" header="Contact Person" :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                            class="form-control" placeholder="Search" />
                    </template>
                </Column>
                <Column field="CONTACT_NUMBER" header="Contact Number" :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                            class="form-control" placeholder="Search" />
                    </template>
                </Column>
                <Column field="CONTACT_EMAIL" header="Contact Email" :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                            class="form-control" placeholder="Search" />
                    </template>
                </Column>
                <template #empty>
                    <div class="text-center">
                        No data yet.
                    </div>
                </template>
            </DataTable>
            <template #footer>
                <div class="d-grid">
                    <button type="button" class="btn btn-primary"
                        :disabled="!selectedOrderSupplier"
                        @click="_ => { emit('select', selectedOrderSupplier); }">
                        Select Order Supplier
                    </button>
                </div>
            </template>
        </Card>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';

import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useOrderSuppliers } from '@/composables/data/orderSuppliers';

import Card from "@/components/utils/Card.vue";

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

const props = defineProps({
    isOpen: Boolean,
});

const emit = defineEmits(['select', 'error', 'close']);

const { params, filters, onPage, onFilter } = useDataTableParams([
    'SUPPLIER_ID', 'SUPPLIER_NAME', 'SUPPLIER_LOCATION', 'CONTACT_PERSON', 'CONTACT_NUMBER', 'CONTACT_EMAIL'
]);

const { orderSuppliers, totalRecords, getOrderSuppliers } = useOrderSuppliers();

const selectedOrderSupplier = ref(null);

const isLoading = ref(false);

const fetchTimeout = ref(false);

async function fetchOrderSuppliers() {
    orderSuppliers.value = null;

    isLoading.value = true;
    try {
        await getOrderSuppliers(params.value);
    } catch(e) {
        emit('error', e.message);
    }
    isLoading.value = false;
}

function tableOnFilter(e) {
    onFilter(e);
    if(fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(fetchOrderSuppliers, 300);
}

onMounted(fetchOrderSuppliers);
</script>

<style scoped>
.pop-up {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: rgb(0, 0, 0, 0.5);
}

.pop-up .card {
    width: 100%;
}

@media (min-width: 768px) {
    .pop-up .card {
        width: 85%;
    }
}

@media (min-width: 992px) {
    .pop-up .card {
        width: 70%;
    }
}
</style>
