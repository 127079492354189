import { ref } from "vue";

import constructFetchError from "./constructFetchError";

import axios from 'axios';

export function useAccountingYearlyReportGenerations() {
    const accountingYearlyReportGenerations = ref(null);
    const totalRecords = ref(null);

    const getAccountingYearlyReportGenerations = async (params) => {
        try {
            const getAccountingYearlyReportGenerationsResponse =
                await axios.get(route('api.accounting-yearly-report-generations.index', params));
            accountingYearlyReportGenerations.value = getAccountingYearlyReportGenerationsResponse.data.data;
            totalRecords.value = getAccountingYearlyReportGenerationsResponse.data.meta.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching accounting yearly report generations.');
        }
    };

    const postAccountingYearlyReportGeneration = async (timePeriod, paytype, companyCode, reportType) => {
        try {
            const response = await axios.post(route('api.time-periods.report-spreadsheet-generation.store', {
                time_period: timePeriod,
                PAYTYPE: paytype,
                COMPANY_CODE: companyCode,
                REPORT_TYPE: reportType
            }));
            return response;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while generating accounting yearly report.');
        }
    }
    
    return { accountingYearlyReportGenerations, totalRecords, getAccountingYearlyReportGenerations,
        postAccountingYearlyReportGeneration };
}
