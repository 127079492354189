import { ref } from "vue";

import constructFetchError from "./constructFetchError";

import axios from 'axios';

export function useColors() {
    const colors = ref(null);
    const color = ref(null);

    const getColors = async () => {
        try {
            const colorsResponse = await axios.get(route('api.colors.index'));
            colors.value = colorsResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching colors.');
        }
    };

    const getActiveColors = async () => {
        try {
            const colorsResponse = await axios.get(route('api.colors.index', {
                filters: {
                    IS_ACTIVE: {
                        value: true,
                        matchMode: 'equals'
                    }
                }
            }));
            colors.value = colorsResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching active colors.');
        }
    };

    const getColor = async (colorName) => {
        try {
            const colorResponse = await axios.get(route('api.colors.show', { COLOR: colorName }));
            color.value = colorResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching color.');
        }
    };

    const postColor = async (color) => {
        try {
            await axios.post(route('api.colors.store'), color);
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while saving color.');
        }
    };

    const putColor = async (colorName, color) => {
        try {
            await axios.put(route('api.colors.update'), {
                OLD_COLOR: colorName,
                ...color
            });
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while updating color.');
        }
    };
    
    return { colors, color, getColors, getActiveColors, getColor, postColor, putColor };
}
