<template>
    <div v-if="isOpen" class="pop-up d-flex justify-content-center align-items-center">
        <div class="container w-50 bg-light p-3 rounded">
            <div class="d-flex justify-content-between">
                <h4 class="title">Select Account</h4>
                <button type="button" class="btn-close" @click="close()"></button>
            </div>
            <Card>
                <template #header>
                    SEARCH
                </template>
                <div class="d-flex">
                    <FormInput is-horizontal label="NAME:"
                        class="mb-1 w-75" v-model="accountName" type="text"
                        input-class="form-control-sm" id-prefix="acctName"/>
                    <button type="button" class="btn btn-primary w-25 ms-1" @click="searchAccount">SEARCH</button>
                </div>
                <FormSelect label="TYPE:" v-model="accountType"
                    id-prefix="acctType" is-horizontal :options="accountTypes"
                    :option-to-string="(accountType) => accountType.ACCOUNT_TYPE"
                    :option-value="(accountType) => accountType.ACCOUNT_TYPE"
                    class="mb-1 w-75" select-class="form-select-sm"/>
            </Card>
            <Card>
                <div id="table-container">
                    <table class="table table-bordered table-hover">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>ACCOUNT NAME</th>
                                <th>ACCOUNT DESCRIPTION</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                class="cursor-pointer"
                                v-for="account in accounts"
                                :key="account.ACCOUNT_ID"
                                @click="selectAccount(account)"
                                :class="{ 'bg-primary text-white':
                                account.ACCOUNT_ID === selectedRow.ACCOUNT_ID }"
                            >
                                <td>{{ account.ACCOUNT_ID }}</td>
                                <td>{{ account.ACCOUNT_NAME }}</td>
                                <td>{{ account.ACCOUNT_DESCRIPTION }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Card>
            <Card>
                <button type="button" class="btn btn-primary w-100" @click="handleSubmit"
                    :disabled="!selectedRow">SELECT ACCOUNT
                </button>
            </Card>
        </div>
    </div>
</template>

<script>
    import { ref, onMounted, watch } from 'vue';
    import axios from 'axios';

    import FormSelect from "./FormSelect.vue";
    import FormInput from "./FormInput.vue";
    import Card from "./Card.vue";

    export default {
        components: {
            FormSelect,
            FormInput,
            Card,
        },
        props: ['isOpen', 'onClose', 'onSelectAccount', 'voucherType'],
        setup(props) {
            const accounts = ref([]);
            const accountName = ref('');
            const accountType = ref('');
            const selectedRow = ref('');
            const accountTypes = ref([]);

            onMounted(async () => {
                getAccountTypes();
            })

            async function getAccountTypes() {
                try {
                    const response = await axios.get(route('api.account-types.index'));
                    accountTypes.value = response.data.data;
                } catch (e) {
                    console.log(e);
                }
            }

            async function searchAccount() {
                try {
                    const result = await axios.get(route('api.accounts.index'), {
                        params: {
                            name: accountName.value.trim()
                        },
                    });
                    accounts.value = result.data.data;
                } catch (e) {
                    console.log(e);
                }
            };

            watch([accountType], async () => {
                try {
                    const result = await axios.get(route('api.accounts.index'), {
                        params: {
                                accountType: accountType.value,
                            },
                    });
                    accounts.value = result.data.data;
                } catch (e) {
                    console.log(e);
                }
            })

            function selectAccount(row) {
                selectedRow.value = row;
            }

            function handleSubmit(){
                props.onSelectAccount(selectedRow.value, props.voucherType);
                close();
            }

            function close() {
                props.onClose();
            }

            return {
                accountName,
                accountType,
                accountTypes,
                accounts,
                searchAccount,
                selectAccount,
                handleSubmit,
                selectedRow,
                close,
            }
        }
    }
</script>

<style scoped>
.pop-up {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: rgb(0, 0, 0, 0.5);
}

.container {
    height: max-content;
}

#table-container {
    width: 100%;
    height: 15rem;
    overflow-y: auto;
    background-color: #FFF;
}

#table-container table thead {
    position: sticky;
    top: 0;
    background-color: var(--bs-card-bg);
}

.cursor-pointer {
    cursor: pointer;
}

</style>
