<template>
    <Card :class="class">
        <template #header>
            Replacement Barcodes
        </template>
        <DataTable :loading="isLoading" lazy :value="inventoryRecords" :total-records="inventoryTotalRecords"
            paginator :rows="10" @page="e => { onPage(e); fetchReplacementBarcodes(); }"
            filter-display="row" v-model:filters="filters" @filter="tableOnFilter"
            v-model:selection="selectedRecord" selection-mode="single"
            :pt="{ table: { class: tableClass } }">
            <Column field="STYLE" header="Style"
                :pt="{ filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                    <Button type="button" class="btn btn-primary" @click="filterCallback()">
                        Search
                    </Button>
                </template>
            </Column>
            <Column field="DATE" header="Date"
                :pt="{ filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                    <Button type="button" class="btn btn-primary" @click="filterCallback()">
                        Search
                    </Button>
                </template>
            </Column>
            <Column field="SERIAL" header="Serial"
                :pt="{ filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                    <Button type="button" class="btn btn-primary" @click="filterCallback()">
                        Search
                    </Button>
                </template>
            </Column>
            <Column field="SEQ_NO" header="Seq. No"
                :pt="{ filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" disabled />
                    <Button type="button" class="btn btn-primary" @click="filterCallback()" disabled>
                        Search
                    </Button>
                </template>
            </Column>
            <Column field="EVENT_DT" header="Event Date"
                :pt="{ filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                    <Button type="button" class="btn btn-primary" @click="filterCallback()">
                        Search
                    </Button>
                </template>
            </Column>
            <Column field="COLOR" header="Color"
                :pt="{ filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                    <Button type="button" class="btn btn-primary" @click="filterCallback()">
                        Search
                    </Button>
                </template>
            </Column>
            <template #empty>No data.</template>
        </DataTable>
        <template #footer>
            <div class="text-end">
                <button type="button" class="btn btn-primary"
                    :disabled="!selectedRecord"
                    @click="_ => { isMovementsTablePopupOpen = true; }">
                    View Movements
                </button>
            </div>
        </template>
    </Card>
    <MovementsTablePopup :barcode="selectedRecordBarcode"
        :is-open="isMovementsTablePopupOpen"
        @close="_ => { isMovementsTablePopupOpen = false; }"
        @error="message => {
            isMovementsTablePopupOpen = false;
            emit('error', message);
        }" />
</template>

<script setup>
import { computed, ref, watch } from 'vue';

import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useInventory } from '@/composables/data/inventory';

import Card from '@/components/utils/Card.vue';

import MovementsTablePopup from './MovementsTablePopup.vue';

import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

const props = defineProps({
    location: String,
    tableClass: String,
    style: String,
    class: String,
});

const emit = defineEmits(['error']);

const tableClass = computed(() => {
    let tableClass = 'table table-bordered table-hover';
    if(props.tableClass)
        tableClass += ' ' + props.tableClass;
    return tableClass;
});

const { params, filters, onPage, onFilter, onSort } = useDataTableParams(
    ['STYLE', 'DATE', 'SERIAL', 'SEQ_NO', 'EVENT_DT', 'COLOR']);

const { inventoryRecords, inventoryTotalRecords, getInventoryRecords } = useInventory();

const selectedRecord = ref(null);

const selectedRecordBarcode = computed(() => {
    if(!selectedRecord.value)
        return null;
    return {
        STYLE: selectedRecord.value.STYLE,
        DATE: selectedRecord.value.DATE,
        SERIAL: selectedRecord.value.SERIAL,
    };
})

const fetchTimeout = ref(null);

const isLoading = ref(false);

const isMovementsTablePopupOpen = ref(false);

async function fetchReplacementBarcodes() {
    inventoryRecords.value = null;

    if(!props.location)
        return;

    isLoading.value = true;
    try {
        await getInventoryRecords({
            ...params.value,
            filters: {
                ...params.value.filters,
                // Fetch only first logs of each barcode (to get unique barcodes,
                // similar to old code setting PREV_LOC = '' or no previous location)
                SEQ_NO: {
                    value: 1,
                    matchMode: 'equals'
                },
                LOCATION: {
                    value: props.location,
                    matchMode: 'equals'
                }
            },
            isRR: true
        });
    } catch(e) {
        emit('error', e.message);
    }
    isLoading.value = false;
}

function tableOnFilter(e) {
    onFilter(e);
    if(fetchTimeout.value)
        clearTimeout(fetchTimeout.value);
    fetchTimeout.value = setTimeout(fetchReplacementBarcodes, 300);
}

watch(() => props.location, fetchReplacementBarcodes);

watch(() => props.style, async () => {
    filters.value.STYLE.value = props.style;
    onFilter();
    await fetchReplacementBarcodes();
});
</script>
