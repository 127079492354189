import { ref } from "vue";

import axios from 'axios';

import constructFetchError from "./constructFetchError";

export function useUploadedRemittanceAdviceFileCollections() {
    const uploadedRemittanceAdviceFileCollections = ref(null);
    const totalRecords = ref(null);

    const getUploadedRemittanceAdviceFileCollections = async (params) => {
        try {
            const getUploadedRemittanceAdviceFileCollectionsResponse =
                await axios.get(route('api.uploaded-remittance-advice-file-collections.index', params));
                uploadedRemittanceAdviceFileCollections.value = getUploadedRemittanceAdviceFileCollectionsResponse.data.data;
            totalRecords.value = getUploadedRemittanceAdviceFileCollectionsResponse.data.meta?.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching uploaded remittance advice files.');
        }
    };

    return { uploadedRemittanceAdviceFileCollections, totalRecords, getUploadedRemittanceAdviceFileCollections };
}
