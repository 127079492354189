<template>
    <form @submit.prevent="insertEmployee">
        <div class="row g-3">
            <div class="col-md-8">
                <Card class="mb-3">
                    <template #header>
                        Personal Information
                    </template>
                    <div class="row g-3 mb-3">
                        <div class="col-md-4">
                            <FormInput type="text" label="ID" v-model="employee.EMPID"
                                disabled id-prefix="empid" input-class="form-control-sm" />
                        </div>
                        <div class="col-md-4">
                            <FormInput type="text" label="First Name" v-model="employee.FNAME"
                                id-prefix="fname" input-class="form-control-sm"
                                :errors="errors.FNAME"
                                @keydown-enter="focusNextInput('mnameFormInput')" />
                        </div>
                        <div class="col-md-4">
                            <FormInput type="date" label="Birthdate" v-model="employee.BIRTHDATE"
                                id-prefix="birthdate" input-class="form-control-sm"
                                :errors="errors.BIRTHDATE"
                                @keydown-enter="focusNextInput('phoneFormInput')" />
                        </div>
                        <div class="col-md-4">
                            <FormSelect label="Paytype" v-model="employee.PAYTYPE"
                                :options="paytypes"
                                :option-to-string="paytype => paytype.PAYTYPE"
                                :option-value="paytype => paytype.PAYTYPE"
                                id-prefix="paytype" select-class="form-select-sm"
                                :errors="errors.PAYTYPE"
                                @keydown-enter="focusNextInput('atmCodeFormInput')" />
                        </div>
                        <div class="col-md-4">
                            <FormInput type="text" label="Middle Name" v-model="employee.MNAME"
                                id-prefix="mname" input-class="form-control-sm"
                                :errors="errors.MNAME"
                                @keydown-enter="focusNextInput('lnameFormInput')" />
                        </div>
                        <div class="col-md-4">
                            <FormInput type="text" label="Phone" v-model="employee.PHONE"
                                id-prefix="phone" input-class="form-control-sm"
                                :errors="errors.PHONE"
                                @keydown-enter="focusNextInput('civilStatusFormInput')" />
                        </div>
                        <div class="col-md-4">
                            <FormSelect label="ATM Code" v-model="employee.ATM_CODE"
                                :options="atmCodes"
                                :option-to-string="atmCode => atmCode.verbose"
                                :option-value="atmCode => atmCode.id"
                                id-prefix="atmCode" select-class="form-select-sm"
                                :errors="errors.ATM_CODE"
                                @keydown-enter="focusNextInput('fnameFormInput')" />
                        </div>
                        <div class="col-md-4">
                            <FormInput type="text" label="Last Name" v-model="employee.LNAME"
                                id-prefix="lname" input-class="form-control-sm"
                                :errors="errors.LNAME"
                                @keydown-enter="focusNextInput('birthdateFormInput')" />
                        </div>
                        <div class="col-md-4">
                            <FormSelect label="Civil Status" v-model="employee.CIVIL_STATUS"
                                :options="civilStatuses"
                                :option-to-string="civilStatus => civilStatus.verbose"
                                :option-value="civilStatus => civilStatus.id"
                                id-prefix="civilStatus" select-class="form-select-sm"
                                :errors="errors.CIVIL_STATUS"
                                @keydown-enter="focusNextInput('presentAddressFormInput')" />
                        </div>
                    </div>
                    <FormInput type="text" label="Present Address" v-model="employee.PRESENT_ADDRESS"
                        id-prefix="presentAddress" input-class="form-control-sm" class="mb-3"
                        :errors="errors.PRESENT_ADDRESS"
                        @keydown-enter="focusNextInput('permanentAddressFormInput')" />
                    <FormInput type="text" label="Permanent Address" v-model="employee.PERMANENT_ADDRESS"
                        id-prefix="permanentAddress" input-class="form-control-sm" class="mb-3"
                        :errors="errors.PERMANENT_ADDRESS"
                        @keydown-enter="focusNextInput('spouseNameFormInput')" />
                    <FormInput type="text" label="Spouse Name" v-model="employee.SPOUSE_NAME"
                        id-prefix="spouseName" input-class="form-control-sm" class="mb-3"
                        :errors="errors.SPOUSE_NAME"
                        @keydown-enter="focusNextInput('motherMaidenNameFormInput')" />
                    <FormInput type="text" label="Mother's Full Maiden Name" v-model="employee.MOTHER_MAIDEN_NAME"
                        id-prefix="motherMaidenName" input-class="form-control-sm"
                        :errors="errors.MOTHER_MAIDEN_NAME"
                        @keydown-enter="focusNextInput('companyFormInput')" />
                </Card>
                <Card class="mb-3">
                    <template #header>
                        Company Information
                    </template>
                    <div class="row g-3 mb-3">
                        <div class="col-md-4">
                            <FormSelect label="Company" v-model="employee.COMPANY_CODE"
                                :options="companies"
                                :option-to-string="company => company.COMPANY_DESCRIPTION"
                                :option-value="company => company.COMPANY_CODE"
                                id-prefix="company" select-class="form-select-sm"
                                :errors="errors.COMPANY_CODE"
                                @keydown-enter="focusNextInput('titleFormInput')" />
                        </div>
                        <div class="col-md-4">
                            <FormSelect label="Title" v-model="employee.TITLE"
                                :options="titles"
                                :option-to-string="title => title.TITLE"
                                :option-value="title => title.TITLE"
                                id-prefix="title" select-class="form-select-sm"
                                :errors="errors.TITLE"
                                @keydown-enter="focusNextInput('flexFlagFormInput')" />
                        </div>
                        <div class="col-md-4">
                            <FormSelect label="Flex Flag" v-model="employee.FLEX_FLAG"
                                :options="['Y', 'N']"
                                id-prefix="flexFlag" select-class="form-select-sm"
                                :errors="errors.FLEX_FLAG"
                                @keydown-enter="focusNextInput('departmentFormInput')" />
                        </div>
                        <div class="col-md-4">
                            <FormSelect label="Department" v-model="employee.DEPT"
                                :options="departments"
                                :option-to-string="department => department.DEPT"
                                :option-value="department => department.DEPT"
                                id-prefix="department" select-class="form-select-sm"
                                :errors="errors.DEPT"
                                @keydown-enter="focusNextInput('statusFormInput')" />
                        </div>
                        <div class="col-md-2">
                            <FormSelect label="Status" v-model="employee.STATUS"
                                :options="['E', 'F']"
                                id-prefix="status" select-class="form-select-sm"
                                :errors="errors.STATUS"
                                @keydown-enter="focusNextInput('hiredateFormInput')" />
                        </div>
                        <div class="col-md-2">
                            <FormInput type="date" label="Hiredate" v-model="employee.HIREDATE"
                                id-prefix="hiredate" input-class="form-control-sm"
                                :errors="errors.HIREDATE"
                                @keydown-enter="focusNextInput('paymentFlagFormInput')" />
                        </div>
                        <div class="col-md-4">
                            <FormSelect label="Payment Flag" v-model="employee.PAYMENT_FLAG"
                                :options="paymentFlags"
                                :option-to-string="paymentFlag => paymentFlag.verbose"
                                :option-value="paymentFlag => paymentFlag.id"
                                id-prefix="paymentFlag" select-class="form-select-sm"
                                :errors="errors.PAYMENT_FLAG"
                                @keydown-enter="focusNextInput('memoFormInput')" />
                        </div>
                    </div>
                    <FormTextarea type="date" label="Memo" v-model="employee.MEMO"
                        id-prefix="memo" :rows="3" :errors="errors.MEMO"
                        @keydown-enter="focusNextInput('sssFormInput')" />
                </Card>
                <Card class="mb-3">
                    <template #header>
                        Employee Dependents
                    </template>
                    <table class="table table-sm table-hover table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">NAME</th>
                                <th scope="col">STATUS</th>
                                <th scope="col">BIRTHDATE</th>
                                <th scope="col">GENDER</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="dependent in dependents">
                                <td>{{ dependent.NAME }}</td>
                                <td>{{ dependent.STATUS }}</td>
                                <td>{{ dependent.BIRTHDATE }}</td>
                                <td>{{ dependent.GENDER }}</td>
                            </tr>
                        </tbody>
                    </table>
                </Card>
                <Card>
                    <template #header>
                        Employee Violations
                    </template>
                    <table class="table table-sm table-hover table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">ID</th>
                                <th scope="col">DATE</th>
                                <th scope="col">TYPE</th>
                                <th scope="col">DAYS</th>
                                <th scope="col">MEMO</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="violation in violations">
                                <td>{{ violation.id }}</td>
                                <td>{{ violation.VIOLATION_DATE }}</td>
                                <td>{{ violation.VIOLATION_TYPE }}</td>
                                <td>{{ violation.VIOLATION_DAYS }}</td>
                                <td>{{ violation.VIOLATION_MEMO }}</td>
                            </tr>
                        </tbody>
                    </table>
                </Card>
            </div>
            <div class="col-md-4">
                <Card class="mb-3">
                    <template #header>
                        Actions
                    </template>
                    <div class="d-grid gap-3">
                        <button type="button" class="btn btn-primary"
                            data-bs-toggle="modal" data-bs-target="#modalSearch"
                            @click="_ => { isSelectEmployeePopupOpen = true; }">
                            Search
                        </button>
                        <button type="button" class="btn btn-primary"
                            @click="clearFormEmployee()">
                            Reset Fields
                        </button>
                        <button type="submit" class="btn btn-primary" id="insertButton"
                            :disabled="employee.EMPID != null || isInserting">
                            Insert New Employee
                            <div v-show="isInserting" class="spinner-border spinner-border-sm text-light"
                                role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </button>
                        <button type="button" class="btn btn-primary flex-grow-1"
                            @click="updateEmployee()"
                            :disabled="employee.EMPID == null || isUpdating">
                            Update Employee
                            <div v-show="isUpdating"
                                class="spinner-border spinner-border-sm text-light"
                                role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </button>
                    </div>
                </Card>
                <Card class="mb-3">
                    <FormInput type="text" label="SSS" v-model="employee.SSS"
                        id-prefix="sss" input-class="form-control-sm" class="mb-3"
                        :errors="errors.SSS"
                        @keydown-enter="focusNextInput('philhealthFormInput')" />
                    <FormInput type="text" label="Philhealth" v-model="employee.PHILHEALTH"
                        id-prefix="philhealth" input-class="form-control-sm" class="mb-3"
                        :errors="errors.PHILHEALTH"
                        @keydown-enter="focusNextInput('pagibigFormInput')" />
                    <FormInput type="text" label="PAGIBIG" v-model="employee.PAGIBIG"
                        id-prefix="pagibig" input-class="form-control-sm" class="mb-3"
                        :errors="errors.PAGIBIG"
                        @keydown-enter="focusNextInput('tinFormInput')" />
                    <FormInput type="text" label="TIN" v-model="employee.TIN"
                        id-prefix="tin" input-class="form-control-sm" class="mb-3"
                        :errors="errors.TIN"
                        @keydown-enter="focusNextInput('insuranceFormInput')" />
                    <FormInput type="text" label="Insurance" v-model="employee.INSURANCE"
                        id-prefix="insurance" input-class="form-control-sm" class="mb-3"
                        :errors="errors.INSURANCE"
                        @keydown-enter="focusNextInput('bdoFormInput')" />
                    <FormInput type="text" label="BDO" v-model="employee.BDO_ACCOUNT"
                        id-prefix="bdo" input-class="form-control-sm" class="mb-3"
                        :errors="errors.BDO_ACCOUNT"
                        @keydown-enter="focusNextInput('bpiFormInput')" />
                    <FormInput type="text" label="BPI" v-model="employee.BPI_ACCOUNT"
                        id-prefix="bpi" input-class="form-control-sm" class="mb-3"
                        :errors="errors.BPI_ACCOUNT"
                        @keydown-enter="focusNextInput('gcashFormInput')" />
                    <FormInput type="text" label="GCASH" v-model="employee.GCASH_ACCOUNT"
                        id-prefix="gcash" input-class="form-control-sm" class="mb-3"
                        :errors="errors.GCASH_ACCOUNT"
                        @keydown-enter="focusNextInput('nidekaEmpidFormInput')" />
                </Card>
                <Card class="mb-3">
                    <FormInput type="text" label="NIDEKA" v-model="employee.NIDEKA_EMPID"
                        id-prefix="nidekaEmpid" input-class="form-control-sm" class="mb-3"
                        :errors="errors.NIDEKA_EMPID"
                        @keydown-enter="focusNextInput('insertButton')" />
                </Card>
            </div>
        </div>
    </form>
    <SelectEmployee :is-open="isSelectEmployeePopupOpen"
        :on-close="_ => { isSelectEmployeePopupOpen = false; }"
        :on-select-employee="selectedEmployee => {
            employee = Object.assign({}, selectedEmployee);
            isSelectEmployeePopupOpen = false;
        }" />
</template>

<script setup>
import { ref, onMounted } from "vue";

import axios from "axios";

import Card from "@/components/utils/Card.vue";
import FormInput from "@/components/utils/FormInput.vue";
import FormSelect from "@/components/utils/FormSelect.vue";
import FormTextarea from "@/components/utils/FormTextarea.vue";
import SelectEmployee from "@/components/utils/SelectEmployee.vue";

import focusNextInput from "@/utils/focusnext";
import handleFetchErrors from "@/utils/handleFetchErrors";
import scrollToTop from "@/utils/scrollToTop";

import { useFlashMessages } from "@/composables/flashmessages";

const { addFlashMessage } = useFlashMessages();

const atmCodes = ref();
const civilStatuses = ref();
const paymentFlags = ref();

const isSelectEmployeePopupOpen = ref(false);

const isInserting = ref(false);
const isUpdating = ref(false);

const departments = ref();
const paytypes = ref();
const titles = ref();
const companies = ref();

const errors = ref({});

const employee = ref({
    EMPID: null,
    PAYTYPE: null,
    FNAME: null,
    MNAME: null,
    LNAME: null,
    DEPT: null,
    BRANCH: '',
    POSITION: '',
    TITLE: null,
    EMP_STATUS: null,
    EMERG_CONTACT: null,
    FLEX_FLAG: null,
    PAYMENT_FLAG: null,
    LOGIN_ID: null,
    LOGIN_INVENTORY: null,
    LOGIN_FINANCE: null,
    LOGIN_ADMIN: null,
    HIREDATE: null,
    BIRTHDATE: null,
    PHONE: null,
    SSS: null,
    PHILHEALTH: null,
    PAGIBIG: null,
    TIN: null,
    INSURANCE: null,
    MEMO: null,
    STATUS: null,
    BPI_ACCOUNT: null,
    BDO_ACCOUNT: null,
    GCASH_ACCOUNT: null,
    COMPANY_CODE: 'K',
    ATM_CODE: null,
    PRESENT_ADDRESS: null,
    PERMANENT_ADDRESS: null,
    MOTHER_MAIDEN_NAME: null,
    CIVIL_STATUS: null,
    SPOUSE_NAME: null,
    NIDEKA_EMPID: null
});

const violations = ref();
const dependents = ref();

async function fetchAtmCodes() {
    try {
        const atmCodesResponse = await axios.get(route('api.atm-codes.index'));
        atmCodes.value = atmCodesResponse.data.data;
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, 'Error while fetching ATM codes.');
        scrollToTop();
    }
}

async function fetchCivilStatuses() {
    try {
        const civilStatusesResponse = await axios.get(route('api.civil-statuses.index'));
        civilStatuses.value = civilStatusesResponse.data.data;
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, 'Error while fetching civil statuses.');
        scrollToTop();
    }
}

async function fetchPaymentFlags() {
    try {
        const paymentFlagsResponse = await axios.get(route('api.payment-flags.index'));
        paymentFlags.value = paymentFlagsResponse.data.data;
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, 'Error while fetching payment flags.');
        scrollToTop();
    }
}

async function fetchDepartments() {
    try {
        const result = await axios.get(route('api.departments.index'));
        departments.value = result.data.data;
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, 'Error while fetching departments.');
        scrollToTop();
    }
}

async function fetchTitles() {
    try {
        const result = await axios.get(route('api.titles.index'), {});
        titles.value = result.data;
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, 'Error while fetching titles.');
        scrollToTop();
    }
}

async function fetchPaytypes() {
    try {
        const result = await axios.get(route('api.paytypes.index'));
        paytypes.value = result.data;
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, 'Error while fetching paytypes.');
        scrollToTop();
    }
}

async function fetchCompanies() {
    try {
        const result = await axios.get(route('api.companies.index'));
        companies.value = result.data.data;
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, 'Error while fetching companies.');
        scrollToTop();
    }
}

onMounted(async () => {
    fetchAtmCodes();
    fetchCivilStatuses();
    fetchPaymentFlags();
    fetchDepartments();
    fetchTitles();
    fetchPaytypes();
    fetchCompanies();
});

async function fetchDependents() {
    try {
        const dependentsResponse = await axios.get(route('api.employees.dependents.index', {
            employee: employee.value.EMPID
        }));
        dependents.value = dependentsResponse.data.data;
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, 'Error while fetching dependents.');
        scrollToTop();
    }
}

async function fetchViolations() {
    try {
        const violationsResponse = await axios.get(route('api.employees.violations.index', {
            employee: employee.value.EMPID
        }));
        violations.value = violationsResponse.data.data;
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, 'Error while fetching violations.');
        scrollToTop();
    }
}

function onEmployeeSelect(selectedEmployee) {
    employee.value = selectedEmployee;
    errors.value = {};
    fetchDependents();
    fetchViolations();
};

function clearFormEmployee() {
    employee.value = {
        EMPID: null,
        PAYTYPE: null,
        FNAME: null,
        MNAME: null,
        LNAME: null,
        DEPT: null,
        BRANCH: '',
        POSITION: '',
        TITLE: null,
        EMP_STATUS: null,
        EMERG_CONTACT: null,
        FLEX_FLAG: null,
        PAYMENT_FLAG: null,
        LOGIN_ID: null,
        LOGIN_INVENTORY: null,
        LOGIN_FINANCE: null,
        LOGIN_ADMIN: null,
        HIREDATE: null,
        BIRTHDATE: null,
        PHONE: null,
        SSS: null,
        PHILHEALTH: null,
        PAGIBIG: null,
        TIN: null,
        INSURANCE: null,
        MEMO: null,
        STATUS: null,
        BPI_ACCOUNT: null,
        BDO_ACCOUNT: null,
        GCASH_ACCOUNT: null,
        COMPANY_CODE: 'K',
        ATM_CODE: null,
        PRESENT_ADDRESS: null,
        PERMANENT_ADDRESS: null,
        MOTHER_MAIDEN_NAME: null,
        CIVIL_STATUS: null,
        SPOUSE_NAME: null
    };

    dependents.value = null;
    violations.value = null;
    errors.value = {};
}

const insertEmployee = async () => {
    isInserting.value = true;
    errors.value = {};
    try {
        const insertEmployeeResponse = await axios.post(route('api.employees.store'),
            employee.value);
        employee.value = insertEmployeeResponse.data.data;
        addFlashMessage('SUCCESS', 'Successfully created employee.');
        errors.value = {};
    } catch (e) {
        console.log(e);
        errors.value = handleFetchErrors(e, 'Error while saving employee.');
    }
    scrollToTop();
    isInserting.value = false;
};

const updateEmployee = async () => {
    isUpdating.value = true;
    errors.value = {};
    try {
        const updateEmployeeResponse = await axios.put(
            route('api.employees.update', { employee: employee.value.EMPID }),
            employee.value);
        employee.value = updateEmployeeResponse.data.data;
        addFlashMessage('SUCCESS', 'Successfully updated employee.');
        errors.value = {};
    } catch (e) {
        console.log(e);
        errors.value = handleFetchErrors(e, 'Error while updating employee.');
    }
    scrollToTop();
    isUpdating.value = false;
};
</script>
