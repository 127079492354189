import { ref } from 'vue';

import axios from 'axios';

import constructFetchError from "./constructFetchError";

export function useInventoryDates() {
    const inventoryDates = ref(null);
    const totalRecords = ref(null);

    const getInventoryDates = async (params) => {
        try {
            const getInventoryDatesResponse = await axios.get(route('api.inventory.dates.index', params));
            inventoryDates.value = getInventoryDatesResponse.data.data;
            totalRecords.value = getInventoryDatesResponse.data.meta.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching inventory dates.');
        }
    };

    return { inventoryDates, totalRecords, getInventoryDates };
};
