<template>
    <div v-if="isOpen" class="pop-up d-flex justify-content-center align-items-center">
        <Card>
            <template #header>
                <div class="d-flex justify-content-between pt-2">
                    <h4 class="title">Upload New Quota2</h4>
                    <button type="button" class="btn-close" @click="() => emit('close')"></button>
                </div>
            </template>
            <Card class="w-100">
                <template #header>Upload Quota2 File</template>
                <form @submit.prevent="fileOnSubmit">
                    <div class="mb-3">
                        <small>* File must not be larger than 2MB.</small><br/>
                        <small>* File must be in .csv .xlsx .xls file type.</small>
                        <input type="file" required ref="fileInput" accept=".csv, .xlsx, .xls"
                            @change="fileOnChange"
                            id="fileInput" class="form-control" />
                    </div>
                    <button type="submit" class="btn btn-primary"
                        :disabled="isSubmitting">
                        Upload quotaDAW2
                        <div class="spinner-border spinner-border-sm" role="status" v-if="isSubmitting">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </button>
                </form>
            </Card>
        </Card>
    </div>
</template>
<script setup>
import { ref, watch } from 'vue';
import Card from "@/components/utils/Card.vue";

const props = defineProps({
    isOpen: Boolean,
});

const emit = defineEmits(['success', 'error', 'close']);

const isSubmitting = ref(false);
const fileInput = ref();
const file = ref(null);

function fileOnChange(event) {
    file.value = event.target.files[0];
}

async function fileOnSubmit() {
    isSubmitting.value = true;
    try {
        const formData = new FormData();
        formData.append("file", file.value);
        const response = await axios.post(route('api.location-quota2.store'), formData);
        emit('success', response.data.message);
        if(response.data.errors) {
            Object.entries(response.data.errors).forEach(([field, errors]) => {
                errors.forEach(error => {
                    emit('error', error);
                });
            });
        }
        fileInput.value.value = null;
        file.value = null;
    } catch(e) {
        console.log(e.response);
        if (e.response.status == 400)
            emit('error', e.response.data.message);
        else
            emit('error', 'Error while submitting CSV. Please contact your administrator.');
    }
    isSubmitting.value = false;
}
</script>
<style scoped>
.pop-up {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: rgb(0, 0, 0, 0.5);
}
.pop-up .card {
    width: 100%;
}
@media (min-width: 768px) {
    .pop-up .card {
        width: 85%;
    }
}
@media (min-width: 992px) {
    .pop-up .card {
        width: 70%;
    }
}
</style>
