<template>
    <Card :class="class">
        <template #header>
            Select Time Period
        </template>
        <DataTable :value="timePeriods"
            paginator :rows="10"
            v-model:filters="filters" filter-display="row"
            v-model:selection="selectedTimePeriod" selection-mode="single"
            :pt="{ table: { class: 'table table-bordered table-hover' } }">
            <Column field="TIME_PERIOD" header="Time Period" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="START_DATE" header="Start Date" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="END_DATE" header="End Date" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <template #empty>
                <div class="text-center">
                    No time periods.
                </div>
            </template>
        </DataTable>
    </Card>
</template>

<script setup>
import { onMounted } from 'vue';

import { useFlashMessages } from '@/composables/flashmessages';

import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useTimePeriods } from '@/composables/data/timePeriods';

import Card from '@/components/utils/Card.vue';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

import moment from 'moment';

const props = defineProps({
    class: String
});

const { addFlashMessage } = useFlashMessages();

const { filters } = useDataTableParams(['TIME_PERIOD', 'START_DATE', 'END_DATE']);

const { timePeriods, getTimePeriods } = useTimePeriods();

const selectedTimePeriod = defineModel();

async function fetchMonthlyTimePeriods() {
    try {
        await getTimePeriods({
            PAYTYPE: 'MONTHLY',
            START_DATE_LTE: moment().format('YYYY-MM-DD'),
            ORDER_DESC: 'START_DATE',
        });
    } catch(e) {
        addFlashMessage('ERROR', e.message);
    }
}

onMounted(fetchMonthlyTimePeriods);
</script>
