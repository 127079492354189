<template>
    <div class="row g-3">
        <div class="col-8 py-5 px-3">
            <Card>
                <div class="row">
                    <FormSelect label="Start Date:" v-model="startDate"
                        :options="timePeriods"
                        :option-to-string="timePeriod => timePeriod.START_DATE"
                        :option-value="timePeriod => timePeriod.START_DATE"
                        class="col-md-6 mb-3"
                        select-class="form-select-sm"
                        id-prefix="startDate"/>
                    <FormSelect label="End Date:" v-model="endDate"
                        :options="timePeriods"
                        :option-to-string="timePeriod => timePeriod.END_DATE"
                        :option-value="timePeriod => timePeriod.END_DATE"
                        class="col-md-6 mb-3"
                        select-class="form-select-sm"
                        id-prefix="endDate"/>
                    <div class="col-md-2 mb-3 d-flex align-items-center">
                        <input type="checkbox" id="enableReceipt"
                            v-model="withReceipt">
                        <label for="enableReceipt" class="ms-1">Enable receipt no.</label>
                    </div>
                    <FormInput label="Start Receipt No:" v-model="startReceiptNumber"
                        :disabled="!withReceipt"
                        class="col-md-4 mb-3"
                        input-class="form-control-sm"
                        id-prefix="endDate"/>
                    <FormInput label="End Receipt No:" v-model="endReceiptNumber"
                        :disabled="!withReceipt"
                        class="col-md-4 mb-3"
                        input-class="form-control-sm"
                        id-prefix="endDate"/>
                    <button class="btn btn-primary col-md-4 ms-3 mt-2"
                        :disabled="!startDate || !endDate"
                        @click="generateDRSpreadsheet('sold')"
                    >
                        Sold Report
                    </button>
                    <button class="btn btn-primary col-md-4 ms-3 mt-2"
                        :disabled="!startDate || !endDate"
                        @click="generateDRSpreadsheet('gift')"
                    >
                        Gift Report
                    </button>
                </div>
            </Card>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';
import Card from '@/components/utils/Card.vue';
import FormSelect from '@/components/utils/FormSelect.vue';

import { useTimePeriods } from '@/composables/data/timePeriods';
import FormInput from '@/components/utils/FormInput.vue';

const startDate = ref(null);
const endDate = ref(null);
const withReceipt = ref(false);
const startReceiptNumber = ref(null);
const endReceiptNumber = ref(null);

const { timePeriods, getTimePeriodsLatest } = useTimePeriods();

watch(withReceipt, () => {
    if(!withReceipt.value) {
        startReceiptNumber.value = null;
        endReceiptNumber.value = null;
    }
})

onMounted(async () => {
    await getTimePeriodsLatest({
        PAYTYPE: 'MONTHLY'
    });
})

function generateDRSpreadsheet(type) {
    window.open(route(`api.inventory.monthly-${type}-dr-report-spreadsheet.show`, {
        START_DATE: startDate.value,
        END_DATE: endDate.value,
        START_RECEIPT_NO: startReceiptNumber.value,
        END_RECEIPT_NO: endReceiptNumber.value,
    }));
}
</script>
