<template>
    <Card :class="class">
        <template #header>
            Select User
        </template>
        <DataTable lazy paginator :rows="10" :total-records="totalRecords" :value="users"
            v-model:filters="filters" filter-display="row"
            :globalFilterFields="['name', 'email']"
            v-model:selection="selectedUser" selection-mode="single" data-key="id"
            @row-select="onRowSelect" @page="event => onPage(event)" @filter="event => onFilter(event)"
            :row-class="userRowClass" :pt="{
                table: { class: 'table table-bordered table-hover' }
            }">
            <Column field="name" header="Name" :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' }
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @keydown="filterCallback()"
                        class="form-control" placeholder="Search" />
                    <Button type="button" class="btn btn-primary" @click="filterCallback()">
                        Search
                    </Button>
                </template>
            </Column>
            <Column field="email" header="Email" :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' }
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @keydown="filterCallback()"
                        class="form-control" placeholder="Search" />
                    <Button type="button" class="btn btn-primary" @click="filterCallback()">
                        Search
                    </Button>
                </template>
            </Column>
        </DataTable>
        <template #footer>
            <div class="text-end">
                <button type="button" class="btn btn-primary"
                    :disabled="selectedUser == null" @click="saveButtonOnClick">
                    Save Roles and Permissions
                </button>
            </div>
        </template>
    </Card>
</template>

<script setup>
import { ref, onMounted } from "vue";

import Card from "../../../utils/Card.vue";

import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';
import { FilterMatchMode } from 'primevue/api';

import handleFetchErrors from "../../../../utils/handleFetchErrors";
import scrollToTop from "../../../../utils/scrollToTop";

import { useFlashMessages } from "../../../../composables/flashmessages";

import axios from "axios";

const props = defineProps({
    class: String
});

const emit = defineEmits(['user-select', 'save']);

const { addFlashMessage } = useFlashMessages();

const filters = ref({
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    email: { value: null, matchMode: FilterMatchMode.CONTAINS },
});

const params = ref({});

const totalRecords = ref(0);
const users = ref();

const selectedUser = ref();

async function fetchUsers() {
    try {
        const usersResponse = await axios.get(route('api.users.index', params.value));
        users.value = usersResponse.data.data;
        totalRecords.value = usersResponse.data.meta.total;
    } catch(e) {
        console.log(e);
        handleFetchErrors(e, 'Error while fetching users.');
        scrollToTop();
    }
}

onMounted(() => {
    params.value = {
        first: 0,
        page: 0,
        rows: 10,
        sortField: null,
        sortOrder: null,
        filters: filters.value
    };

    fetchUsers();
});

const onPage = (event) => {
    params.value = event;
    fetchUsers();
}

const onFilter = _ => {
    params.value.filters = filters.value;
    params.value.page = 0;
    params.value.first = 0;
    fetchUsers();
}

const userRowClass = (data) => {
    return [{'bg-primary text-white': data.id == selectedUser.value?.id}];
}

function onRowSelect() {
    emit('user-select', selectedUser.value);
}

function saveButtonOnClick() {
    emit('save');
}
</script>
