<template>
    <div v-if="isOpen" class="pop-up d-flex justify-content-center align-items-center">
        <form @submit.prevent="createInventoryCheckDescription">
            <Card>
                <template #header>
                    <div class="d-flex justify-content-between">
                        <span>Create Inventory Check Description</span>
                        <button type="button" class="btn-close" @click="emit('close')"></button>
                    </div>
                </template>
                <FormInput type="text" v-model="inventoryCheckDescription.LOCATION" disabled
                    label="Location" id-prefix="location"
                    :errors="errors.LOCATION" class="mb-3"
                    @keydown-enter="focusnext('inventoryDoneFormInput')" />
                <FormInput type="date" v-model="inventoryCheckDescription.INVENTORY_DONE"
                    label="Inventory Done" id-prefix="inventoryDone"
                    :errors="errors.INVENTORY_DONE" class="mb-3"
                    @keydown-enter="focusnext('descriptionFormInput')" />
                <FormInput type="text" v-model="inventoryCheckDescription.DESCRIPTION"
                    label="Description" id-prefix="description"
                    :errors="errors.DESCRIPTION"
                    @keydown-enter="focusnext('createInventoryCheckDescriptionButton')" />
                <template #footer>
                    <div class="text-end">
                        <button type="submit" class="btn btn-primary"
                            id="createInventoryCheckDescriptionButton"
                            :disabled="isCreatingInventoryCheckDescription">
                            <span v-if="isCreatingInventoryCheckDescription" class="spinner-border spinner-border-sm"
                                role="status" aria-hidden="true"></span>
                            Create
                        </button>
                    </div>
                </template>
            </Card>
        </form>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';

import { useInventoryCheckDescriptions } from '@/composables/data/inventoryCheckDescriptions';

import Card from '@/components/utils/Card';
import FormInput from '@/components/utils/FormInput';

import focusnext from '@/utils/focusnext';

const { postInventoryCheckDescription } = useInventoryCheckDescriptions();

const props = defineProps({
    location: String,
    isOpen: Boolean,
});

const emit = defineEmits(['success', 'error', 'close']);

const inventoryCheckDescription = ref({
    LOCATION: null,
    INVENTORY_DONE: null,
    DESCRIPTION: null,
});

const errors = ref({});

const isCreatingInventoryCheckDescription = ref(false);

watch(() => props.location, () => {
    inventoryCheckDescription.value.LOCATION = props.location;
});

watch(() => props.isOpen, () => {
    errors.value = {};
    inventoryCheckDescription.value = {
        LOCATION: props.location,
        INVENTORY_DONE: null,
        DESCRIPTION: null
    };
});

async function createInventoryCheckDescription() {
    isCreatingInventoryCheckDescription.value = true;
    try {
        const saveResponse = await postInventoryCheckDescription(inventoryCheckDescription.value);
        emit('success', saveResponse.data.data);
        inventoryCheckDescription.value = {
            LOCATION: props.location,
            INVENTORY_DONE: null,
            DESCRIPTION: null
        };
    } catch(e) {
        emit('error', e.message);
        errors.value = e.errors;
    }
    isCreatingInventoryCheckDescription.value = false;
}
</script>

<style scoped>
.pop-up {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: rgb(0, 0, 0, 0.5);
}

.pop-up .card {
    width: 100%;
}

@media (min-width: 992px) {
    .pop-up .card {
        width: 70%;
    }
}

@media (min-width: 768px) {
    .pop-up .card {
        width: 85%;
    }
}
</style>
