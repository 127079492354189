<template>
    <Card class="mb-3">
        <template #header>
            Create SP
        </template>
        <div class="row g-3">
            <div class="col-md-4">
                <FormInput type="text" v-model="account.ACCOUNT_ID"
                    label="Client" id-prefix="client"
                    :form-text="account.ACCOUNT_NAME"
                    is-horizontal disabled />
            </div>
            <div class="col-md-4">
                <button type="button" class="btn btn-primary"
                    @click="_ => { isSelectingAccount = true; }">
                    Search Client
                </button>
            </div>
        </div>
        <template #footer>
            <div class="text-end">
                <button type="button" class="btn btn-primary me-2"
                    :disabled="!account.ACCOUNT_ID"
                    @click="_ => { isViewingPaymentDetailsPopup = true; }">
                    View Payment Details
                </button>
                <button type="button" class="btn btn-primary"
                    :disabled="!account.ACCOUNT_ID"
                    @click="_ => { isViewingPaymentSummaryPopup = true; }">
                    View Payment Summary
                </button>
            </div>
        </template>
    </Card>
    <div class="row g-3 mb-3">
        <div class="col-md-7">
            <!-- When saving joborder from JONOFormCard, propagate to the table so it gets viewed -->
            <JONOTableCard :account="account"
                :saved-joborder-new="savedJoborderNew"
                @select="joborderNew => { selectedJoborderFromTable = joborderNew; }"
                class="mb-3" />
            <!-- When selecting joborder from table, propagate to this form for updating -->
            <JONOFormCard :account="account"
                :selected-joborder-from-table="selectedJoborderFromTable"
                @create-or-update="joborderNew => { savedJoborderNew = joborderNew; }" />
        </div>
        <div class="col-md-5">
            <OrdersByStyleCard :joborder-new="selectedJoborderFromTable" class="mb-3">
                <template #footer>
                    <div class="text-end">
                        <button type="button" class="btn btn-primary"
                            :disabled="!selectedJoborderFromTable"
                            @click="updateOrdersOnClick">
                            Update Orders
                        </button>
                    </div>
                </template>
            </OrdersByStyleCard>
        </div>
    </div>
        
    <PaymentDetailsTablePopup :account="account"
        :is-open="isViewingPaymentDetailsPopup"
        @close="_ => { isViewingPaymentDetailsPopup = false; }" />

    <PaymentSummaryTablePopup :account="account"
        :is-open="isViewingPaymentSummaryPopup"
        @close="_ => { isViewingPaymentSummaryPopup = false; }" />

    <SelectAccount :is-open="isSelectingAccount"
        :on-select-account="selectedAccount => { account = selectedAccount; }"
        :on-close="_ => { isSelectingAccount = false; }" />
</template>

<script setup>
import { ref } from 'vue';

import Card from '@/components/utils/Card.vue';
import FormInput from '@/components/utils/FormInput.vue';

import SelectAccount from '@/components/utils/SelectAccount.vue';

import JONOTableCard from './JONOTableCard.vue';
import JONOFormCard from './JONOFormCard.vue';
import OrdersByStyleCard from './utils/OrdersByStyleCard.vue';
import PaymentDetailsTablePopup from './PaymentDetailsTablePopup.vue';
import PaymentSummaryTablePopup from './PaymentSummaryTablePopup.vue';

const account = ref({
    ACCOUNT_ID: null,
    ACCOUNT_NAME: null,
});

const isSelectingAccount = ref(false);
const isViewingPaymentDetailsPopup = ref(false);
const isViewingPaymentSummaryPopup = ref(false);

const selectedJoborderFromTable = ref(null);

const savedJoborderNew = ref(null);

function updateOrdersOnClick() {
    if(!selectedJoborderFromTable.value)
        return;

    window.open(route('activity.update-sp-status.update-order-details', {
        JONO: selectedJoborderFromTable.value.JONO
    }), '_self');
}
</script>
