import { ref } from "vue";

import axios from 'axios';

import constructFetchError from "./constructFetchError";

export function useVoucherImages() {
    const voucherImages = ref(null);

    const getVoucherImages = async (voucherId) => {
        try {
            const getVoucherImagesResponse = await axios.get(route('api.vouchers.images.index', {
                voucher: voucherId
            }));
            voucherImages.value = getVoucherImagesResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching voucher images.');
        }
    };

    return {
        voucherImages,
        getVoucherImages
    };
}
