import { ref } from "vue";

import axios from 'axios';

import constructFetchError from "./constructFetchError";

export function useStylePrices() {
    const getStylePrices = async (style, params) => {
        try {
            const getStylePricesResponse = await axios.get(route('api.styles.style-prices.index', {
                style: style,
                ...params
            }));
            return getStylePricesResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching style prices.');
        }
    };
    
    return { getStylePrices };
}
