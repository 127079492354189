import { ref } from "vue";

import constructFetchError from "./constructFetchError";

import axios from "axios";

export function useLocations() {
    const locations = ref(null);
    const locationsTotalRecords = ref(null);

    const getLocations = async (params) => {
        try {
            const locationsResponse = await axios.get(route('api.locations.index', params));
            locations.value = locationsResponse.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching locations.');
        }
    };

    const getLocationsForComments = async (params, location) => {
        try {
            const locationsResponse = await axios.get(route('api.locations.activity', {
                ...params,
                LOCATION: location
            }));
            locations.value = locationsResponse.data.data;
            locationsTotalRecords.value = locationsResponse.data.meta.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching locations.');
        }
    }

    const updateLocation = async (location, data) => {
        try {
            const updateLocationResponse = await axios.put(route('api.locations.update', {
                location: location
            }), data);
            return updateLocationResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while updating location.');
        }
    };

    return { locations, locationsTotalRecords, getLocations,
        getLocationsForComments, updateLocation };
}
