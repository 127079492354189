import { ref } from "vue";

import constructFetchError from "./constructFetchError";

import axios from 'axios';

export function useSMMappings() {
    const mapping = ref(null);

    const getSMMapping = async (empid) => {
        try {
            const getSMMappingResponse = await axios.get(route('api.employee-sm-mappings.index', {
                KIMDB_EMPID: empid
            }));
            mapping.value = getSMMappingResponse.data.data[0] ?? null;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching current mapping.');
        }
    };

    const postSMMapping = async (empid, nidekaEmpid) => {
        try {
            const response = await axios.post(route('api.employee-sm-mappings.store'), {
                KIMDB_EMPID: empid,
                NIDEKA_EMPID: nidekaEmpid
            });
            return response.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while saving mapping.');
        }
    };
    
    return { mapping, getSMMapping, postSMMapping };
}
