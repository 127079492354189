<template>
    <div class="d-flex justify-content-center">
        <Card class="w-25">
            <div class="row g-3 p-2">
                <button @click="redirectLink('delivery-pullout-reports')"
                    class="col-12 btn btn-primary">Delivery and Pull-out Reports</button>
                <button @click="redirectLink('quantity-per-store')"
                    class="col-12 btn btn-primary">Quantiy Per Store</button>
                <button @click="redirectLink('sales-reports')"
                    class="col-12 btn btn-primary">Sales Reports</button>
                <button @click="redirectLink('monthly-discrepancy')"
                    class="col-12 btn btn-primary">Monthly Discrepancy Report</button>
                <button @click="redirectLink('delivery-receipt')"
                    class="col-12 btn btn-primary">Delivery Receipt Report</button>
                <button @click="redirectLink('location-detail-report')"
                    class="col-12 btn btn-primary">Location Detail Report</button>
                <button @click="redirectLink('recon-lost-reports')"
                    class="col-12 btn btn-primary">Recon Lost Report</button>
            </div>
        </Card>
    </div>
</template>

<script setup>
import Card from '@/components/utils/Card.vue';

function redirectLink(link) {
    window.location.href = link;
}
</script>
