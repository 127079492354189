import { useFlashMessages } from '../composables/flashmessages';

const { addFlashMessage } = useFlashMessages();

export default function(e, genericErrorMessage) {
    if(e.response) {
        addFlashMessage('ERROR', `${genericErrorMessage} Message: ${e.response.data.message}`);
        if(e.response.status == 422)
            return e.response.data.errors;
    }

    else addFlashMessage('ERROR', `${genericErrorMessage} Please contact your administrator.`);
}
