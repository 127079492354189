<template>
    <Card :class="class">
        <template #header>
            Robinsons SKU
        </template>
        <DataTable :loading="isLoading" :value="robinsonsSkus" table-class="table table-bordered table-hover"
            paginator :rows="10" class="mb-3"
            v-model:selection="selectedRobinsonsSku"
            selection-mode="single" data-key="id">
            <Column field="id" header="ID" />
            <Column field="COLOR" header="Color" />
            <Column field="ROBINSONS_SKU" header="Robinsons SKU" />
            <template #empty>No Robinsons Skus found.</template>
        </DataTable>
        <form>
            <FormSelect label="Color" v-model="robinsonsSku.COLOR" :options="colors"
                is-horizontal id-prefix="color" class="mb-3"
                select-class="form-select-sm" :errors="errors.COLOR"
                @keydown-enter="focusNextInput('robinsonsSkuFormInput')" />
            <FormInput label="Robinsons SKU" type="text" v-model="robinsonsSku.ROBINSONS_SKU"
                is-horizontal id-prefix="robinsonsSku" class="mb-3"
                input-class="form-control-sm" :errors="errors.ROBINSONS_SKU" />
            <button type="button" class="btn btn-primary me-1"
                :disabled="!style.STYLE || robinsonsSku.id != null || isLoading || isInserting"
                @click="insertButtonOnClick">
                <span v-if="isInserting" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Insert
            </button>
            <button type="button" class="btn btn-primary me-1"
                :disabled="!style.STYLE || robinsonsSku.id == null || isLoading || isUpdating"
                @click="updateButtonOnClick">
                <span v-if="isUpdating" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Update
            </button>
            <button type="reset" class="btn btn-outline-secondary"
                @click="clearButtonOnClick">
                Clear
            </button>
        </form>
    </Card>
</template>

<script setup>
import { ref, watch } from 'vue';

import Card from '../../../utils/Card.vue';
import FormInput from '../../../utils/FormInput.vue';
import FormSelect from '../../../utils/FormSelect.vue';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';

import axios from 'axios';

import focusNextInput from '../../../../utils/focusnext';
import handleFetchErrors from "../../../../utils/handleFetchErrors";
import scrollToTop from "../../../../utils/scrollToTop";

import { useFlashMessages } from "../../../../composables/flashmessages";

const { addFlashMessage } = useFlashMessages();

const props = defineProps({
    style: Object,
    colors: Array,
    class: String
});

const errors = ref({});

const robinsonsSkus = ref();

const selectedRobinsonsSku = ref({
    id: null,
    STYLE: null,
    COLOR: null,
    ROBINSONS_SKU: null
});

const robinsonsSku = ref({});

const isLoading = ref(false);
const isInserting = ref(false);
const isUpdating = ref(false);

async function loadRobinsonsSkus() {
    isLoading.value = true;
    try {
        const robinsonsSkusResponse = await axios.get(route('api.styles.robinsons-skus.index', {
            style: props.style.STYLE
        }));
        robinsonsSkus.value = robinsonsSkusResponse.data.data;
    } catch(e) {
        console.log(e);
        handleFetchErrors(e, 'Error while fetching Robinsons SKUs.');
    }
    isLoading.value = false;
}

watch(selectedRobinsonsSku, () => {
    errors.value = {};
    robinsonsSku.value = Object.assign({}, selectedRobinsonsSku.value);
});

watch(() => props.style, async () => {
    errors.value = {};
    robinsonsSku.value = {};
    if(props.style?.STYLE == null)
        return;
    await loadRobinsonsSkus();
});

async function insertButtonOnClick() {
    errors.value = {};
    isInserting.value = true;
    try {
        const saveRobinsonsSkuResponse = await axios.post(
            route('api.styles.robinsons-skus.store', {
                style: props.style.STYLE
            }), robinsonsSku.value);
        robinsonsSku.value = saveRobinsonsSkuResponse.data.data;
        addFlashMessage('SUCCESS', 'Successfully created Robinsons SKU ' +
            robinsonsSku.value.ROBINSONS_SKU + '.');
        loadRobinsonsSkus();
    } catch(e) {
        console.log(e);
        errors.value = handleFetchErrors(e, 'Error while saving Robinsons SKU.');
    }
    isInserting.value = false;
    scrollToTop();
}

async function updateButtonOnClick() {
    errors.value = {};
    isUpdating.value = true;
    try {
        const updateRobinsonsSkuResponse = await axios.put(
            route('api.styles.robinsons-skus.update', {
                style: props.style.STYLE,
                robinsons_sku: robinsonsSku.value.id
            }), robinsonsSku.value);
        robinsonsSku.value = updateRobinsonsSkuResponse.data.data;
        addFlashMessage('SUCCESS', 'Successfully updated Robinsons SKU ' +
            robinsonsSku.value.ROBINSONS_SKU + '.');
        loadRobinsonsSkus();
    } catch(e) {
        console.log(e);
        errors.value = handleFetchErrors(e, 'Error while updating Robinsons SKU.');
    }
    isUpdating.value = false;
    scrollToTop();
}

function clearButtonOnClick() {
    selectedRobinsonsSku.value = {
        id: null,
        STYLE: null,
        COLOR: null,
        ROBINSONS_SKU: null
    };
    errors.value = {};
}
</script>
