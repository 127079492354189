import axios from "axios";

import constructFetchError from "./constructFetchError";

export function useReconActions() {
    const postDuplicateDeletion = async (raw, location, inventoryDone) => {
        try {
            const postDuplicateDeletionResponse = await axios.post(route('api.recon-actions.duplicate-deletions.store', {
                RAW: raw,
                LOCATION: location,
                INVENTORY_DONE: inventoryDone,
            }));
            return postDuplicateDeletionResponse;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while deleting duplicates.');
        }
    };

    const postMissingReturnReplacement = async (replaceWithId, toDeleteId) => {
        try {
            const postMissingReturnReplacementResponse = await axios.post(route('api.recon-actions.missing-return-replacements.store', {
                REPLACE_WITH_ID: replaceWithId,
                TO_DELETE_ID: toDeleteId,
            }));
            return postMissingReturnReplacementResponse;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while replacing missing return.');
        }
    };

    const postMissingSaleRRReplacement = async (replaceWithId, toDeleteId, location) => {
        try {
            const postMissingSaleRRReplacementResponse = await axios.post(route('api.recon-actions.missing-sale-rr-replacements.store', {
                REPLACE_WITH_ID: replaceWithId,
                TO_DELETE_ID: toDeleteId,
                LOCATION: location
            }));
            return postMissingSaleRRReplacementResponse;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while replacing missing sale/RR.');
        }
    };

    const postMissingItemAdditionToInventoryCheck = async (reconRawFullId, location, inventoryDone) => {
        try {
            const postMissingItemAdditionToInventoryCheckResponse = await axios.post(
                route('api.recon-actions.additions-of-missing-items-to-inventory-check.store'),
                {
                    id: reconRawFullId,
                    LOCATION: location,
                    INVENTORY_DONE: inventoryDone,
                }
            );
            return postMissingItemAdditionToInventoryCheckResponse;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while adding missing item to inventory check.');
        }
    }

    const postNOBCSaleReplacementWithAlreadySold = async (reconRawFullNOBCId, inventoryCheckToReplaceWithId) => {
        try {
            const postBarcodeMissingDeletionResponse = await axios.post(
                route('api.recon-actions.nobc-sale-replacements-with-already-sold.store', {
                    RECON_RAW_FULL_NOBC_TO_DELETE_ID: reconRawFullNOBCId,
                    INVENTORY_CHECK_TO_REPLACE_WITH_ID: inventoryCheckToReplaceWithId 
                })
            );
            return postBarcodeMissingDeletionResponse;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while replacing NOBC sale with already sold item.');
        }
    };

    const postBarcodeMissingDeletion = async (reconRawFullId, commentId) => {
        try {
            const postBarcodeMissingDeletionResponse = await axios.post(
                route('api.recon-actions.barcode-missing-deletions.store'),
                {
                    RECON_RAW_FULL_ID: reconRawFullId,
                    COMMENT_ID: commentId 
                }
            );
            return postBarcodeMissingDeletionResponse;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while marking barcode as LOST.');
        }
    };

    const postBarcodeMultipleMissingDeletion = async (commentId, reconRawFullIds) => {
        try {
            const postBarcodeMultipleMissingDeletionResponse = await axios.post(
                route('api.recon-actions.barcode-multiple-missing-deletions.store'),
                {
                    COMMENT_ID: commentId,
                    RECON_RAW_FULL_IDS: reconRawFullIds,
                }
            );
            return postBarcodeMultipleMissingDeletionResponse;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while marking all barcodes as LOST.');
        }
    };

    const postBarcodeMissingUndeletion = async (style, date, serial) => {
        try {
            const postBarcodeMissingUndeletionResponse = await axios.post(
                route('api.recon-actions.barcode-missing-undeletions.store'),
                {
                    STYLE: style,
                    DATE: date,
                    SERIAL: serial
                }
            );
            return postBarcodeMissingUndeletionResponse;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while undeleting missing barcode.');
        }
    };

    const postBarcodeMultipleMissingUndeletion = async (barcodes) => {
        try {
            const postBarcodeMultipleMissingUndeletionResponse = await axios.post(
                route('api.recon-actions.barcode-multiple-missing-undeletions.store'),
                {
                    BARCODES: barcodes
                }
            );
            return postBarcodeMultipleMissingUndeletionResponse;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while undeleting missing barcodes.');
        }
    };

    const postDuplicateNoBarcodeDeletion = async (reconRawFullNOBCToDeleteId) => {
        try {
            const postDuplicateNoBarcodeDeletionResponse = await axios.post(
                route('api.recon-actions.duplicate-nobc-sale-deletions.store', {
                    RECON_RAW_FULL_NOBC_TO_DELETE_ID: reconRawFullNOBCToDeleteId,
                })
            );
            return postDuplicateNoBarcodeDeletionResponse;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while deleting duplicate NOBC sale.');
        }
    };
    
    return { postDuplicateDeletion, postMissingReturnReplacement,
        postMissingSaleRRReplacement, postMissingItemAdditionToInventoryCheck,
        postNOBCSaleReplacementWithAlreadySold, postBarcodeMissingDeletion, postBarcodeMultipleMissingDeletion,
        postBarcodeMissingUndeletion, postDuplicateNoBarcodeDeletion, postBarcodeMultipleMissingUndeletion };
}
