<template>
    <div v-if="isOpen" class="pop-up d-flex justify-content-center align-items-center">
        <Card>
            <template #header>
                <div class="d-flex justify-content-between pt-2">
                    <h4 class="title">Mass Generate</h4>
                    <button type="button" class="btn-close" @click="() => emit('close')"></button>
                </div>
            </template>
            <Card class="w-100">
                <template #header>Mass Generate Store Summary</template>
                <div>
                    <FormSelect label="From:" v-model="startTimePeriod"
                        class="mb-1" :options="timePeriods"
                        :option-to-string="timePeriods => timePeriods.TIME_PERIOD + ' | ' + timePeriods.START_DATE"
                        id-prefix="start-time-period" select-class="form-select-sm"
                        is-horizontal :errors="errors.TIME_PERIOD_FROM"/>
                    <FormSelect label="To:" v-model="endTimePeriod"
                        class="mb-1" :options="timePeriods"
                        :option-to-string="(timePeriods) => timePeriods.TIME_PERIOD + ' | ' + timePeriods.END_DATE"
                        id-prefix="end-time-period" select-class="form-select-sm"
                        is-horizontal :errors="errors.TIME_PERIOD_TO"/>
                </div>
                <div class="d-flex justify-content-center">
                    <h6>{{ message }}</h6>
                </div>
            </Card>
            <template #footer>
                <button class="btn btn-primary btn-sm w-25"
                        :disabled="!startTimePeriod || !endTimePeriod"
                        @click="massGenerate">
                    <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status"
                        aria-hidden="true"></span>UPDATE
                </button>
            </template>
        </Card>
    </div>
</template>

<script setup>
import axios from 'axios';
import { onMounted, ref } from 'vue';
import Card from '@/components/utils/Card.vue';
import FormSelect from '@/components/utils/FormSelect.vue';
import { useTimePeriods } from '@/composables/data/timePeriods';

const startTimePeriod = ref(null);
const endTimePeriod = ref(null);
const errors = ref({});
const message = ref();

const props = defineProps({
    isOpen: Boolean,
});

const emit = defineEmits(['success', 'error', 'close']);

const { timePeriods, getTimePeriodsLatest } = useTimePeriods();

const params = ref({
    filters: {"PAYTYPE": { value: 'MONTHLY', matchMode: null }},
});

onMounted(async () => {
    await getTimePeriodsLatest(params.value);
})

const isLoading = ref(false);
async function massGenerate() {
    errors.value = {};
    try {
        console.log(startTimePeriod.value, endTimePeriod.value);
        isLoading.value = true;
        const response = await axios.post(route('api.recon.mass-generate', {
            TIME_PERIOD_FROM: startTimePeriod.value.TIME_PERIOD,
            TIME_PERIOD_TO: endTimePeriod.value.TIME_PERIOD,
        }));
        message.value = response.data.message;
        isLoading.value = false;
    } catch (e) {
        console.log(e)
        errors.value = e.response.data.errors;
        isLoading.value = false;
    }
}
</script>

<style scoped>
.pop-up {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: rgb(0, 0, 0, 0.5);
}
.pop-up .card {
    width: 100%;
}
@media (min-width: 768px) {
    .pop-up .card {
        width: 85%;
    }
}
@media (min-width: 992px) {
    .pop-up .card {
        width: 50%;
    }
}
</style>

