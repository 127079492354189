<template>
    <div class="row g-3">
        <div class="col-md-8">
            <Card>
                <template #header>
                    Materials
                </template>
                <DataTable lazy :value="materials" :total-records="totalRecords"
                    paginator :rows="10" 
                    filter-display="row" v-model:filters="filters"
                    v-model:selection="selectedMaterial" selection-mode="single" data-key="id"
                    @page="e => onPage(e)"
                    @filter="e => onFilter(e)"
                    :row-class="data => [{'bg-primary text-white': data.id == selectedMaterial?.id}]"
                    :pt="{ table: { class: 'table table-bordered table-hover' } }">
                    <Column field="MATERIAL" header="Material" :pt="{
                        filterInput: { class: 'input-group input-group-sm' },
                        filterMenuButton: { class: 'd-none' },
                        headerFilterClearButton: { class: 'd-none' } }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText type="text" v-model="filterModel.value" @keydown="filterCallback()"
                                class="form-control" placeholder="Search" />
                            <Button type="button" class="btn btn-primary" @click="filterCallback()">
                                Search
                            </Button>
                        </template>
                    </Column>
                    <Column field="COLOR" header="Color" :pt="{
                        filterInput: { class: 'input-group input-group-sm' },
                        filterMenuButton: { class: 'd-none' },
                        headerFilterClearButton: { class: 'd-none' } }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText type="text" v-model="filterModel.value" @keydown="filterCallback()"
                                class="form-control" placeholder="Search" />
                            <Button type="button" class="btn btn-primary" @click="filterCallback()">
                                Search
                            </Button>
                        </template>
                    </Column>
                    <Column field="UNIT" header="Unit" :pt="{
                        filterInput: { class: 'input-group input-group-sm' },
                        filterMenuButton: { class: 'd-none' },
                        headerFilterClearButton: { class: 'd-none' } }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText type="text" v-model="filterModel.value" @keydown="filterCallback()"
                                class="form-control" placeholder="Search" />
                            <Button type="button" class="btn btn-primary" @click="filterCallback()">
                                Search
                            </Button>
                        </template>
                    </Column>
                    <Column field="PRICE" header="Price" :pt="{
                        filterInput: { class: 'input-group input-group-sm' },
                        filterMenuButton: { class: 'd-none' },
                        headerFilterClearButton: { class: 'd-none' } }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText type="text" v-model="filterModel.value" @keydown="filterCallback()"
                                class="form-control" placeholder="Search" />
                            <Button type="button" class="btn btn-primary" @click="filterCallback()">
                                Search
                            </Button>
                        </template>
                    </Column>
                    <Column field="supplier_id" header="Supplier" filter-field="relation_supplier_ACCOUNT_NAME"
                        :pt="{ filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' } }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText type="text" v-model="filterModel.value" @keydown="filterCallback()"
                                class="form-control" placeholder="Search" />
                            <Button type="button" class="btn btn-primary" @click="filterCallback()">
                                Search
                            </Button>
                        </template>
                        <template #body="slotProps">
                            {{ slotProps.data.supplier?.ACCOUNT_NAME }}
                        </template>
                    </Column>
                    <Column field="CREATED_BY" header="Created By" :pt="{
                        filterInput: { class: 'input-group input-group-sm' },
                        filterMenuButton: { class: 'd-none' },
                        headerFilterClearButton: { class: 'd-none' } }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText type="text" v-model="filterModel.value" @keydown="filterCallback()"
                                class="form-control" placeholder="Search" />
                            <Button type="button" class="btn btn-primary" @click="filterCallback()">
                                Search
                            </Button>
                        </template>
                    </Column>
                    <template #empty>No materials.</template>
                </DataTable>
            </Card>
        </div>
        <div class="col-md-4">
            <form @submit.prevent="insertMaterial">
                <Card>
                    <template #header>
                        Material Form
                    </template>
                    <FormInput type="text" label="Material"
                        v-model="material.MATERIAL"
                        :errors="errors.MATERIAL"
                        is-horizontal class="mb-3" />
                    <FormInput type="text" label="Color"
                        v-model="material.COLOR"
                        :errors="errors.COLOR"
                        is-horizontal class="mb-3" />
                    <FormSelect label="Unit"
                        :options="units"
                        :option-value="unit => unit.value"
                        :option-to-string="unit => [unit.value, unit.verbose].join(' | ')"
                        v-model="material.UNIT"
                        :errors="errors.UNIT"
                        is-horizontal class="mb-3" />
                    <FormInput type="number" step=".01" label="Price"
                        v-model="material.PRICE"
                        :errors="errors.PRICE"
                        is-horizontal class="mb-3" />
                    <FormInput type="number" label="Supplier"
                        v-model="material.supplier_id" disabled
                        :errors="errors.supplier_id"
                        :form-text="selectedRelationships.supplier?.ACCOUNT_NAME"
                        is-horizontal class="mb-1" />
                    <div class="text-end mb-3">
                        <button type="button" class="btn btn-primary"
                            @click="_ => { isSelectingAccount = true; }">
                            Select
                        </button>
                    </div>
                    <template #footer>
                        <button type="reset" class="btn btn-outline-secondary me-2"
                            @click="reset">
                            Reset
                        </button>
                        <button type="button" class="btn btn-outline-danger me-2"
                            :disabled="!material.id"
                            @click="destroyMaterial">
                            Delete
                        </button>
                        <button type="submit" class="btn btn-primary me-2"
                            :disabled="material.id">
                            Insert
                        </button>
                        <button type="button" class="btn btn-primary"
                            :disabled="!material.id"
                            @click="updateMaterial">
                            Update
                        </button>
                    </template>
                </Card>
            </form>
        </div>
    </div>

    <SelectAccount :is-open="isSelectingAccount"
        :on-select-account="selectedAccount => {
            material.supplier_id = selectedAccount.ACCOUNT_ID;
            selectedRelationships.supplier = selectedAccount;
        }"
        :on-close="_ => { isSelectingAccount = false; }" />
</template>

<script setup>
import { onMounted, ref, watch } from "vue";

import { useFlashMessages } from "@/composables/flashmessages";

import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useMaterials } from '@/composables/data/materials';

import Card from "@/components/utils/Card.vue";
import FormInput from "@/components/utils/FormInput.vue";
import FormSelect from "@/components/utils/FormSelect.vue";
import SelectAccount from '@/components/utils/SelectAccount.vue';

import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

import handleFetchErrors from "@/utils/handleFetchErrors";
import scrollToTop from "@/utils/scrollToTop";

const { addFlashMessage } = useFlashMessages();

const units = [
    { value: 'P', verbose: 'Pieces' },
    { value: 'M', verbose: 'Meters' },
];

const { materials, totalRecords, getMaterials,
    postMaterial, putMaterial, deleteMaterial } = useMaterials();

const { params, filters, onPage, onFilter } = useDataTableParams(
    ['MATERIAL', 'COLOR', 'UNIT', 'PRICE', 'CREATED_BY', 'relation_supplier_ACCOUNT_NAME'],
    { include_relations: 'supplier' },
);

const selectedMaterial = ref();

const errors = ref({});

const material = ref({
    MATERIAL: null,
    COLOR: null,
    UNIT: null,
    PRICE: null,
    supplier_id: null,
});

const selectedRelationships = ref({
    supplier: null
});

const isSelectingAccount = ref(false);

async function fetchMaterials() {
    try {
        await getMaterials(params.value);
    } catch(e) {
        handleFetchErrors(e, 'Error while fetching materials.');
        scrollToTop();
    }
}

onMounted(fetchMaterials);

watch(params, fetchMaterials, { deep: true });

watch(selectedMaterial, () => {
    errors.value = {};
    material.value = Object.assign({}, selectedMaterial.value);
    selectedRelationships.value.supplier = selectedMaterial.value?.supplier;
});

function reset() {
    selectedMaterial.value = null;
    material.value = {
        MATERIAL: null,
        COLOR: null,
        UNIT: null,
        PRICE: null
    };
}

async function insertMaterial() {
    errors.value = {};
    try {
        const savedMaterial = await postMaterial(material.value);
        selectedMaterial.value = savedMaterial;
        addFlashMessage('SUCCESS', 'Successfully saved material.');
        fetchMaterials();
    } catch(e) {
        addFlashMessage('ERROR', e.message);
        errors.value = e.errors ?? {};
    }
    scrollToTop();
}

async function updateMaterial() {
    errors.value = {};
    try {
        const updatedMaterial = await putMaterial(material.value.id, material.value);
        selectedMaterial.value = updatedMaterial;
        addFlashMessage('SUCCESS', 'Successfully updated material.');
        fetchMaterials();
    } catch(e) {
        addFlashMessage('ERROR', e.message);
        errors.value = e.errors ?? {};
    }
    scrollToTop();
}

async function destroyMaterial() {
    errors.value = {};

    if(window.prompt('Type "DELETE" to proceed.') != 'DELETE') {
        addFlashMessage('WARNING', 'Invalid keyword. Did not proceed.');
        return;
    }

    try {
        await deleteMaterial(material.value.id);
        selectedMaterial.value = null;
        addFlashMessage('SUCCESS', 'Successfully deleted material.');
        fetchMaterials();
    } catch(e) {
        addFlashMessage('ERROR', e.message);
    }
    scrollToTop();
}
</script>
