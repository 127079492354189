<template>
    <Card :class="class">
        <template #header>
            Masterlist Generations
        </template>
        <DataTable
            lazy :value="reconMasterlistGenerations" :total-records="totalRecords"
            paginator :rows="10" @page="e => { onPage(e); fetchReconMasterlistGenerations(); }"
            v-model:filters="filters" filter-display="row" @filter="tableOnFilter"
            :pt="{ table: { class: 'table table-bordered table-hover' } }">
            <Column field="time_period" header="TIME_PERIOD" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="status" header="Status" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
                <template #body="{ data }">
                    <span v-if="data.status == 'PENDING'" class="badge bg-secondary">
                        PENDING
                    </span>
                    <span v-else-if="data.status == 'RUNNING'" class="badge bg-primary">
                        RUNNING
                    </span>
                    <span v-else-if="data.status == 'DONE'" class="badge bg-success">
                        DONE
                    </span>
                    <span v-else-if="data.status == 'FAILED'" class="badge bg-danger">
                        FAILED
                    </span>
                </template>
            </Column>
            <template #empty>
                <div class="text-center">
                    No data.
                </div>
            </template>
        </DataTable>
        <template #footer>
            <div class="text-end">
                <button type="button" class="btn btn-primary"
                    @click="fetchReconMasterlistGenerations">
                    Refresh
                </button>
            </div>
        </template>
    </Card>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';

import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useReconMasterlists } from '@/composables/data/reconMasterlists';

import Card from '@/components/utils/Card.vue';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

const props = defineProps({
    timePeriod: Object,
    refreshFlag: Boolean,
    class: String,
});

const emit = defineEmits(['error']);

const { reconMasterlistGenerations, totalRecords, getReconMasterlistGenerations } = useReconMasterlists();

const { params, filters, onPage, onFilter } = useDataTableParams(['time_period', 'status']);

const fetchTimeout = ref(null);

async function fetchReconMasterlistGenerations() {
    reconMasterlistGenerations.value = null;

    try {
        await getReconMasterlistGenerations(params.value);
    } catch(e) {
        emit('error', e.message);
    }
}

onMounted(fetchReconMasterlistGenerations);

function tableOnFilter(e) {
    onFilter(e);
    if(fetchTimeout.value)
        clearTimeout(fetchTimeout.value);
    fetchTimeout.value = setTimeout(fetchReconMasterlistGenerations, 300);
}

watch(() => props.refreshFlag, fetchReconMasterlistGenerations);
</script>
