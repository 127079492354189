<template>
    <div class="row g-3 mb-3">
        <div class="col-md-4">
            <Card class="mb-3">
                <template #header>
                    Details
                </template>
                <FormInput type="text" v-model="timePeriodDisplay" disabled
                    label="Time Period" id-prefix="timePeriodDisplay"
                    class="mb-3" />
                <FormInput type="text" v-model="locationDisplay" disabled
                    label="Location" id-prefix="locationDisplay" />
            </Card>
        </div>
        <div class="col-md-8">
            <Card>
                <template #header>
                    Select Style
                </template>
                <StyleTable v-model="style" table-class="recon-table"
                    :preset-filters="initialStyleTableFilters" />
            </Card>
        </div>
    </div>
    <PulloutCommentsOnMonthTableCard :time-period="reconParm?.TIME_PERIOD"
        :location="reconParm?.LOCATION"
        table-class="recon-table" class="mb-3" />
    <ReplacementBarcodesTableCard :location="reconParm?.LOCATION"
        :style="style?.STYLE"
        table-class="recon-table" class="mb-3"
        @error="message => {
            addFlashMessage('ERROR', message);
            scrollToTop();
        }" />
    <NoBarcodesTableCard :location="reconParm?.LOCATION"
        :style="style?.STYLE"
        table-class="recon-table"
        @error="message => {
            addFlashMessage('ERROR', message);
            scrollToTop();
        }" />
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';

import { useFlashMessages } from '@/composables/flashmessages';
import { useReconParms } from '@/composables/data/reconParms';
import { useStyles } from '@/composables/data/styles';

import Card from '@/components/utils/Card.vue';
import FormInput from '@/components/utils/FormInput.vue';

import StyleTable from '@/components/utils/style/StyleTable.vue';
import PulloutCommentsOnMonthTableCard from './PulloutCommentsOnMonthTableCard.vue';
import ReplacementBarcodesTableCard from './ReplacementBarcodesTableCard.vue';
import NoBarcodesTableCard from './NoBarcodesTableCard.vue';

import scrollToTop from '@/utils/scrollToTop';

const { addFlashMessage } = useFlashMessages();

const { reconParm, getReconParm } = useReconParms();

const timePeriodDisplay = computed(() => reconParm.value?.TIME_PERIOD);
const locationDisplay = computed(() => reconParm.value?.LOCATION);

const { style, getStyle } = useStyles();

const initialStyleTableFilters = ref(null);

onMounted(async () => {
    // Get recon_parm id from URL parameters
    const urlParams = new URLSearchParams(window.location.search);
    const reconParmId = urlParams.get('recon_parm');
    await getReconParm(reconParmId, { include_relations: 'location,location.reconComment' });

    const preselectedStyle = urlParams.get('preselected_style');
    if(!preselectedStyle)
        return;
    try {
        await getStyle(preselectedStyle);
        initialStyleTableFilters.value = {
            STYLE: {
                value: style.value.STYLE,
                matchMode: 'equals',
            }
        };
    } catch(e) {
        addFlashMessage('ERROR', e.message);
        scrollToTop();
    }
});
</script>

<style scoped>
.pop-up {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: rgb(0, 0, 0, 0.5);
}

.pop-up .card {
    width: 100%;
}

@media (min-width: 768px) {
    .pop-up .card {
        width: 85%;
    }
}

@media (min-width: 992px) {
    .pop-up .card {
        width: 70%;
    }
}
</style>
