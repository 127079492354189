<template>
    <Card>
        <Card class="mb-3">
            <div class="row g-3">
                <div class="col-md-6">
                    <div class="row mb-3">
                        <label for="companyFilter" class="col-form-label col-sm-3">Company:</label>
                        <div class="col-sm-9">
                            <select v-model="companyFilter" id="companyFilter" class="form-select">
                                <option :value="null">-- SELECT A COMPANY --</option>
                                <option v-for="company in companyList" :value="company">
                                    {{ company.COMPANY_DESCRIPTION }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label for="timePeriodTypeFilter" class="col-form-label col-sm-3">Time Period:</label>
                        <div class="col-sm-9">
                            <select v-model="timePeriodTypeFilter" @change="timePeriodTypeFilterOnChange"
                                id="timePeriodTypeFilter" class="form-select">
                                <option :value="null">-- SELECT TIME PERIOD --</option>
                                <option value="WEEKLY">WEEKLY</option>
                                <option value="BIMONTHLY - OFFICE">BIMONTHLY - OFFICE</option>
                                <option value="BIMONTHLY - SALES(1,2)">BIMONTHLY - SALES(1,2)</option>
                                <option value="BIMONTHLY - SALES(3)">BIMONTHLY - SALES(3)</option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <label for="ptrDisplay" class="col-form-label col-sm-3">PTR:</label>
                        <div class="col-sm-9">
                            <input type="text" id="ptrDisplay" :value="ptrDisplay" class="form-control"
                                disabled>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row g-3 mb-3">
                        <div class="col-md-6">
                            <label for="fromTimePeriodFilter" class="form-label">From</label>
                            <select v-model="fromTimePeriodFilter" id="fromTimePeriodFilter"
                                class="form-control">
                                <option :value="null">-- SELECT FROM TIME PERIOD --</option>
                                <option v-for="timePeriod in timePeriodsWithPaytype"
                                    :value="timePeriod">
                                    {{ timePeriod.TIME_PERIOD }} - {{ timePeriod.PAYTYPE }} -
                                    {{ timePeriod.START_DATE }} to {{ timePeriod.END_DATE }} -
                                    {{ timePeriod.STATUS }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-6">
                            <label for="toTimePeriodFilter" class="form-label">To</label>
                            <select v-model="toTimePeriodFilter" id="toTimePeriodFilter"
                                class="form-control">
                                <option :value="null">-- SELECT TO TIME PERIOD --</option>
                                <option v-for="timePeriod in timePeriodsWithPaytype"
                                    :value="timePeriod">
                                    {{ timePeriod.TIME_PERIOD }} - {{ timePeriod.PAYTYPE }} -
                                    {{ timePeriod.START_DATE }} to {{ timePeriod.END_DATE }} -
                                    {{ timePeriod.STATUS }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <button type="button" class="btn btn-primary me-2 mb-2"
                        :disabled="exportPayrollToExcelLink == null"
                        @click="exportPayrollToExcelButtonOnClick">
                        Export Payroll to Excel
                    </button>
                    <button type="button" class="btn btn-primary me-2 mb-2"
                        :disabled="exportBDOToExcelLink == null"
                        @click="exportBDOToExcelButtonOnClick">
                        Export BDO to Excel
                    </button>
                    <button type="button" class="btn btn-primary me-2 mb-2"
                        :disabled="exportBPIToExcelLink == null"
                        @click="exportBPIToExcelButtonOnClick">
                        Export BPI to Excel
                    </button>
                </div>
            </div>
        </Card>
        <div class="row justify-content-center g-3">
            <div class="col-md-8">
                <Card>
                    <p v-if="fromTimePeriodFilter || toTimePeriodFilter">
                        <b v-if="fromTimePeriodFilter">
                            ({{ fromTimePeriodFilter.TIME_PERIOD }}) 
                            {{ fromTimePeriodFilter.START_DATE }}
                        </b>
                        to
                        <b v-if="toTimePeriodFilter">
                            ({{ toTimePeriodFilter.TIME_PERIOD }}) 
                            {{ toTimePeriodFilter.END_DATE }}
                        </b>
                    </p>
                    <div v-if="!isPayrollEntriesLoading" class="table-responsive">
                        <table class="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>AFLAG</th>
                                    <th>GL ID</th>
                                    <th>Class</th>
                                    <th>Acct Name</th>
                                    <th>Debit</th>
                                    <th>Credit</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="payrollEntries && payrollEntries.length > 0"
                                    v-for="payrollEntry in payrollEntries">
                                    <td>{{ payrollEntry.AFLAG }}</td>
                                    <td>{{ payrollEntry.GL_ID }}</td>
                                    <td>{{ payrollEntry.CLASS }}</td>
                                    <td>{{ payrollEntry.ACCTNAME }}</td>
                                    <td>{{ payrollEntry.AMOUNT1?.toFixed(2) }}</td>
                                    <td>{{ payrollEntry.AMOUNT2?.toFixed(2) }}</td>
                                </tr>
                                <tr v-else>
                                    <td colspan="6" class="text-center">No entries yet.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div v-else class="text-center mb-3">
                        <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label for="totalDebitDisplay" class="col-form-label col-sm-3">
                            Total Debit:
                        </label>
                        <div class="col-sm-9">
                            <input type="number" step=".01" id="totalDebitDisplay" :value="totalDebit"
                                class="form-control">
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label for="totalCreditDisplay" class="col-form-label col-sm-3">
                            Total Credit:
                        </label>
                        <div class="col-sm-9">
                            <input type="number" step=".01" id="totalCreditDisplay" :value="totalCredit"
                                class="form-control">
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    </Card>
    <SearchVoucherFormModal id="searchVoucherFormModal"
        @select-voucher="voucher => selectedVoucher = voucher"/>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";

import Card from "../../../utils/Card.vue";

import axios from "axios";

import handleFetchErrors from "../../../../utils/handleFetchErrors";
import scrollToTop from "../../../../utils/scrollToTop";

import { useFlashMessages } from "../../../../composables/flashmessages";

const { addFlashMessage } = useFlashMessages();

const companyList = ref([]);

const companyFilter = ref(null);
const timePeriodTypeFilter = ref(null);
const openTimePeriod = ref(null);

const timePeriodsWithPaytype = ref(null);
const fromTimePeriodFilter = ref(null);
const toTimePeriodFilter = ref(null);

const isPayrollEntriesLoading = ref(false);
const payrollEntries = ref(null);
const totalDebit = ref(null);
const totalCredit = ref(null);

const exportPayrollToExcelLink = computed(() => {
    if(companyFilter.value == null || fromTimePeriodFilter.value == null ||
        toTimePeriodFilter.value == null)
        return null;

    return route('api.payroll.payroll-spreadsheet', {
        FROM_TIME_PERIOD: fromTimePeriodFilter.value.TIME_PERIOD,
        TO_TIME_PERIOD: toTimePeriodFilter.value.TIME_PERIOD,
        SFLAG: timePeriodFilterMapping[timePeriodTypeFilter.value].sflag,
        COMPANY_CODE: companyFilter.value.COMPANY_CODE
    });
});

const exportBDOToExcelLink = computed(() => {
    if(companyFilter.value == null || fromTimePeriodFilter.value == null ||
        toTimePeriodFilter.value == null)
        return null;

    return route('api.payroll.bdo-spreadsheet', {
        FROM_TIME_PERIOD: fromTimePeriodFilter.value.TIME_PERIOD,
        TO_TIME_PERIOD: toTimePeriodFilter.value.TIME_PERIOD,
        SFLAG: timePeriodFilterMapping[timePeriodTypeFilter.value].sflag
    });
});

const exportBPIToExcelLink = computed(() => {
    if(companyFilter.value == null || fromTimePeriodFilter.value == null ||
        toTimePeriodFilter.value == null)
        return null;

    return route('api.payroll.bpi-spreadsheet', {
        FROM_TIME_PERIOD: fromTimePeriodFilter.value.TIME_PERIOD,
        TO_TIME_PERIOD: toTimePeriodFilter.value.TIME_PERIOD,
        SFLAG: timePeriodFilterMapping[timePeriodTypeFilter.value].sflag
    });
});

const timePeriodFilterMapping = {
    "WEEKLY": {
        paytype: "WEEKLY",
        ptrDisplayStart: "PY/W/",
        sflag: "W",
        bdoflag: ""
    },
    "BIMONTHLY - OFFICE": {
        paytype: "BIMONTHLY",
        ptrDisplayStart: "PY/A/",
        sflag: "O",
        bdoflag: ""
    },
    "BIMONTHLY - SALES(1,2)": {
        paytype: "BIMONTHLY",
        ptrDisplayStart: "PY/S/",
        sflag: "S",
        bdoflag: "2"
    },
    "BIMONTHLY - SALES(3)": {
        paytype: "BIMONTHLY",
        ptrDisplayStart: "PY/S/",
        sflag: "S",
        bdoflag: "3"
    }
};

const ptrDisplay = computed(() => {
    if(openTimePeriod.value == null)
        return;

    return timePeriodFilterMapping[timePeriodTypeFilter.value].ptrDisplayStart +
        String(openTimePeriod.value.TIME_PERIOD).padStart(6, '0');
});

onMounted(async() => {
    try {
        const companyListResponse = await axios.get(route('api.companies.index'));
        companyList.value = companyListResponse.data.data;
    } catch(e) {
        console.log(e);
        handleFetchErrors(e, 'Error while fetching companies.');
        scrollToTop();
    }
});

async function timePeriodTypeFilterOnChange() {
    try {
        const allTimePeriodsWithPaytypeResponse = await axios.get(
            route('api.time-periods.index'),
            {
                params: {
                    PAYTYPE: timePeriodFilterMapping[timePeriodTypeFilter.value].paytype,
                    ORDER_DESC: 'TIME_PERIOD'
                }
            }
        );

        timePeriodsWithPaytype.value = allTimePeriodsWithPaytypeResponse.data.data;

        const openTimePeriodResponse = await axios.get(
            route('api.time-periods.index'),
            {
                params: {
                    STATUS: 'O',
                    PAYTYPE: timePeriodFilterMapping[timePeriodTypeFilter.value].paytype
                }
            }
        );

        const openTimePeriods = openTimePeriodResponse.data.data;
        if(openTimePeriods.length == 0 ) {
            addFlashMessage('WARNING', `There are no open time periods with this filter.`);
            return;
        } else if(openTimePeriods.length > 1) {
            addFlashMessage('WARNING', `Multiple time periods are open.
                Please check on time period maintenance.`);
            return;
        }

        openTimePeriod.value = openTimePeriodResponse.data.data[0];

        fromTimePeriodFilter.value = toTimePeriodFilter.value =
            timePeriodsWithPaytype.value.filter(timePeriod => timePeriod.TIME_PERIOD == openTimePeriod.value.TIME_PERIOD)[0];
    } catch(e) {
        console.log(e);
        handleFetchErrors(e, 'Error while fetching open time period.');
        scrollToTop();
    }
}

function exportPayrollToExcelButtonOnClick() {
    window.open(exportPayrollToExcelLink.value, '_blank');
}

function exportBDOToExcelButtonOnClick() {
    window.open(exportBDOToExcelLink.value, '_blank');
}

function exportBPIToExcelButtonOnClick() {
    window.open(exportBPIToExcelLink.value, '_blank');
}
</script>
