<template>
    <div class="modal fade" tabindex="-1" :id="props.id">
        <div class="modal-dialog" :class="dialogClass">
            <div class="modal-content">
                <div class="modal-header">
                    <slot name="header"></slot>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" @click="close" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <slot></slot>
                </div>
                <div v-if="slots.footer" class="modal-footer">
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useSlots } from 'vue';

const slots = useSlots();

const props = defineProps({
    id: String,
    dialogClass: String
});

const emit = defineEmits(['close'])

function close() {
    emit('close');
}
</script>
