<template>
    <Card>
        <template #header>
            Inventory Checks
        </template>
        <DataTable :loading="isLoading" lazy :value="inventoryChecks" :total-records="totalRecords"
            paginator :rows="10" @page="e => { onPage(e); fetchInventoryChecksOfBarcode(); }" 
            filter-display="row" v-model:filters="filters" @filter="tableOnFilter"
            sort-mode="multiple" @sort="e => { onSort(e); fetchInventoryChecksOfBarcode(); }"
            :pt="{ table: { class: ' table table-bordered table-hover' } }">
            <Column field="INVENTORY_DONE" header="Inventory Done" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                    <Button type="button" class="btn btn-primary" @click="filterCallback()">
                        Search
                    </Button>
                </template>
            </Column>
            <Column field="LOCATION" header="Location" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                    <Button type="button" class="btn btn-primary" @click="filterCallback()">
                        Search
                    </Button>
                </template>
            </Column>
            <template #empty>
                <div class="text-center">
                    No data.
                </div>
            </template>
        </DataTable>
    </Card>
</template>

<script setup>
import { ref, watch } from 'vue';

import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useInventoryChecks } from '@/composables/data/inventoryChecks';

import Card from '@/components/utils/Card';

import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

const props = defineProps({
    barcode: String,
    refreshFlag: null,
});

const emit = defineEmits(['error']);

const { params, filters, onPage, onFilter, onSort } = useDataTableParams(['INVENTORY_DONE', 'LOCATION']);

const { inventoryChecks, totalRecords, getInventoryChecksOfBarcode } = useInventoryChecks();

const isLoading = ref(false);

const fetchTimeout = ref(null);

async function fetchInventoryChecksOfBarcode() {
    inventoryChecks.value = null;

    if(!props.barcode)
        return;

    isLoading.value = true;
    try {
        await getInventoryChecksOfBarcode(props.barcode, params.value);
    } catch(e) {
        emit('error', e.message);
    }
    isLoading.value = false;
}

function tableOnFilter(e) {
    onFilter(e);
    if(fetchTimeout.value)
        clearTimeout(fetchTimeout.value);
    fetchTimeout.value = setTimeout(fetchInventoryChecksOfBarcode, 300);
}

watch(() => props.barcode, fetchInventoryChecksOfBarcode);
watch(() => props.refreshFlag, fetchInventoryChecksOfBarcode);
</script>
