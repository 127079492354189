<style scoped>
#violationsTableContainer {
    height: 350px;
    overflow-y: auto;
}

#violationsTableContainer table thead {
    position: sticky;
    top: 0;
    background-color: var(--bs-card-bg);
}
</style>

<template>
    <div class="row g-3 mb-3">
        <div class="col-md-8">
            <Card>
                <template #header>
                    Selected Employee
                </template>
                <div class="row">
                    <div class="col-sm-3">
                        <FormInput type="text" label="ID" v-model="employee.EMPID"
                            id-prefix="empid" input-class="form-control-sm"
                            disabled />
                    </div>
                    <div class="col-sm-3">
                        <FormInput type="text" label="First Name" v-model="employee.FNAME"
                            id-prefix="fname" input-class="form-control-sm"
                            disabled />
                    </div>
                    <div class="col-sm-3">
                        <FormInput type="text" label="Middle Name" v-model="employee.MNAME"
                            id-prefix="fname" input-class="form-control-sm"
                            disabled />
                    </div>
                    <div class="col-sm-3">
                        <FormInput type="text" label="Last Name" v-model="employee.LNAME"
                            id-prefix="fname" input-class="form-control-sm"
                            disabled />
                    </div>
                </div>
            </Card>
        </div>
        <div class="col-md-4">
            <Card>
                <div class="d-grid">
                    <button type="button" class="btn btn-primary my-auto"
                        @click="_ => { isSelectEmployeePopupOpen = true; }">
                        Search Employee
                    </button>
                </div>
            </Card>
        </div>
    </div>
    <Card class="mb-3">
        <template #header>
            Violation Form
        </template>
        <form @submit.prevent="saveViolation">
            <div class="row g-3">
                <div class="col-md-8">
                    <FormTextarea label="Memo" v-model="violation.VIOLATION_MEMO"
                        id-prefix="memo" :rows="5" :errors="errors.VIOLATION_MEMO"
                        @keydown-enter="focusNextInput('dateFormInput')" />
                </div>
                <div class="col-md-4">
                    <FormInput type="date" label="Date" v-model="violation.VIOLATION_DATE"
                        id-prefix="date" input-class="form-control-sm" class="mb-3"
                        :errors="errors.VIOLATION_DATE"
                        @keydown-enter="focusNextInput('daysFormInput')" />
                    <FormInput type="number" label="Days" v-model="violation.VIOLATION_DAYS"
                        id-prefix="days" input-class="form-control-sm" class="mb-3"
                        :errors="errors.VIOLATION_DAYS"
                        @keydown-enter="focusNextInput('typeFormInput')" />
                    <FormSelect label="Type" v-model="violation.VIOLATION_TYPE"
                        :options="violationTypes" :option-to-string="(type) => type.verbose"
                        :option-value="(type) => type.value"
                        id-prefix="type" select-class="form-select-sm" class="mb-3"
                        :errors="errors.VIOLATION_TYPE"
                        @keydown-enter="focusNextInput('insertButton')" />
                    <div class="d-grid gap-3">
                        <button type="submit" class="btn btn-primary" id="insertButton"
                            :disabled="employee.EMPID == null">
                            Insert Violation
                        </button>
                        <button type="reset" class="btn btn-outline-secondary"
                            @click="resetButtonOnClick">
                            Reset Fields
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </Card>
    <Card>
        <template #header>
            Employee Violations
        </template>
        <div class="table-responsive" id="violationsTableContainer">
            <table class="table table-bordered table-hover">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>DATE</th>
                        <th>TYPE</th>
                        <th>DAYS</th>
                        <th>MEMO</th>
                        <th>DELETE</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="employee.EMPID == null">
                        <td colspan="6" class="text-center">Please select an employee.</td>
                    </tr>
                    <tr v-else-if="violations.length == 0">
                        <td colspan="6" class="text-center">No violations.</td>
                    </tr>
                    <tr v-else v-for="violation in violations">
                        <td>{{ violation.id }}</td>
                        <td>{{ violation.VIOLATION_DATE }}</td>
                        <td>{{ violation.VIOLATION_TYPE }}</td>
                        <td>{{ violation.VIOLATION_DAYS }}</td>
                        <td>{{ violation.VIOLATION_MEMO }}</td>
                        <td>
                            <button type="button" class="btn btn-sm btn-danger"
                                @click="deleteViolation(violation.id)">
                                <i class="fa-solid fa-trash" />
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </Card>

    <SelectEmployee :is-open="isSelectEmployeePopupOpen"
        :on-close="_ => { isSelectEmployeePopupOpen = false; }"
        :on-select-employee="onEmployeeSelect" />
</template>

<script setup>
import { ref } from "vue";

import { useFlashMessages } from "@/composables/flashmessages";

import Card from "@/components/utils/Card.vue";
import FormInput from "@/components/utils/FormInput.vue";
import FormSelect from "@/components/utils/FormSelect.vue";
import FormTextarea from "@/components/utils/FormTextarea.vue";

import SelectEmployee from "@/components/utils/SelectEmployee.vue";

import axios from "axios";

import focusNextInput from "@/utils/focusnext.js";
import handleFetchErrors from "@/utils/handleFetchErrors";
import scrollToTop from "@/utils/scrollToTop";

const { addFlashMessage } = useFlashMessages();

const errors = ref({});

const violationTypes = [
    { value: 'VW', verbose: 'Verbal Warning' },
    { value: 'WW', verbose: 'Written Warning' },
    { value: 'S', verbose: 'Suspension' },
    { value: 'T', verbose: 'Terminated' },
    { value: 'R', verbose: 'Resign' }
];

const violations = ref();

const isSelectEmployeePopupOpen = ref(false);

const employee = ref({});
const violation = ref({});

const isSubmitting = ref(false);

async function fetchViolations() {
    try {
        const violationsResponse = await axios.get(route('api.employees.violations.index', {
            employee: employee.value.EMPID
        }));
        violations.value = violationsResponse.data.data;
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, 'Error while fetching violations.');
        scrollToTop();
    }
}

const onEmployeeSelect = (selectedEmployee) => {
    employee.value = selectedEmployee;
    violation.value = {};
    errors.value = {};
    fetchViolations();
};

const saveViolation = async () => {
    isSubmitting.value = true;
    errors.value = {};
    try {
        await axios.post(route('api.employees.violations.store', {
            employee: employee.value.EMPID
        }), violation.value);
        addFlashMessage('SUCCESS', 'Successfully added violation.');
        fetchViolations();
        violation.value = {};
    } catch (e) {
        console.log(e);
        errors.value = handleFetchErrors(e, 'Error while saving violation.');
    }
    scrollToTop();
    isSubmitting.value = false;
};

const deleteViolation = async (id) => {
    try {
        await axios.delete(route('api.employees.violations.destroy', {
            employee: employee.value.EMPID,
            violation: id
        }));
        addFlashMessage('SUCCESS', 'Successfully deleted violation.');
        fetchViolations();
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, 'Error while deleting violation.');
    }
    scrollToTop();
};

function resetButtonOnClick() {
    violation.value = {};
}
</script>
