<template>
    <Card>
        <div class="row g-3">
            <div class="col-7">
                <Card>
                    <template #header>
                        Select a Location
                    </template>
                    <LocationTable v-model="location"
                        @error="message => {
                            addFlashMessage('ERROR', message);
                            scrollToTop();
                        }" />
                </Card>
            </div>
            <div class="col-5 py-5 px-3">
                <FormSelect label="Start Date:" v-model="startDate"
                    :options="timePeriods"
                    :option-to-string="timePeriod => timePeriod.START_DATE"
                    :option-value="timePeriod => timePeriod.START_DATE"
                    class="mb-3"
                    select-class="form-select-sm"
                    id-prefix="startDate"
                    :errors="errors.START_DATE"/>
                <FormSelect label="End Date:" v-model="endDate"
                    :options="timePeriods"
                    :option-to-string="timePeriod => timePeriod.END_DATE"
                    :option-value="timePeriod => timePeriod.END_DATE"
                    class="mb-3"
                    select-class="form-select-sm"
                    id-prefix="endDate"
                    :errors="errors.END_DATE"/>
                <div class="d-flex mt-4">
                    <div class="p-1 w-50">
                        <button class="btn btn-primary w-100"
                            :disabled="!location || !startDate || !endDate"
                            @click="fetchReports">Display Report</button>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <Card>
                    <template #header>MONTHLY DISCREPANCY REPORT</template>
                    <DataTable :loading="isLoading" :value="inventoryRecords"
                        :pt="{ table: { class: 'table table-bordered table-hover' } }">
                        <Column field="SOLD_MONTH" header="SOLD_MONTH"/>
                        <Column field="QTY" header="QTY"/>
                        <Column field="AMOUNT" header="AMOUNT"/>
                        <template #loading>
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            Loading report...
                        </template>
                        <template #empty>No data.</template>
                    </DataTable>
                </Card>
            </div>
        </div>
    </Card>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import Card from '@/components/utils/Card.vue';
import LocationTable from '@/components/utils/tables/LocationTable';
import FormSelect from '@/components/utils/FormSelect.vue';

import handleFetchErrors from "@/utils/handleFetchErrors";
import scrollToTop from "@/utils/scrollToTop";

import { useTimePeriods } from '@/composables/data/timePeriods';
import { useInventory } from '@/composables/data/inventory';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';

const startDate = ref(null);
const endDate = ref(null);
const location = ref(null);
const isLoading = ref(false);
const errors = ref({});

const { inventoryRecords, getMonthlyDiscrepancies } = useInventory();
const { timePeriods, getTimePeriodsLatest } = useTimePeriods();

onMounted(async () => {
    await getTimePeriodsLatest({
        PAYTYPE: 'MONTHLY'
    });
})

async function fetchReports() {
    errors.value = {};
    isLoading.value = true;
    try {
        await getMonthlyDiscrepancies(
            location.value.LOCATION,
            startDate.value,
            endDate.value,
        );
    } catch (e) {
        inventoryRecords.value = [];
        console.log(e);
        if (e.status == 422) {
            errors.value = e.errors;
        } else {
            handleFetchErrors(e, 'Error while fetching reports.');
            scrollToTop();
        }
    }
    isLoading.value = false;
}
</script>
