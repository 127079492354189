<template>
    <Card class="mb-3">
        <template #header>
            Filters
        </template>
        <div class="row g-3 mb-3">
            <div class="col-md-4">
                <FormInput type="text" v-model="filters['PREV_LOC'].value"
                    label="Prev. Loc." id-prefix="prevLoc"
                    class="mb-1" />
                <div class="text-end">
                    <button type="button" class="btn btn-sm btn-primary"
                        @click="_ => { isSelectLocationPopupOpen = true; locationPopupFieldToSet = 'PREV_LOC'; }">
                        Select
                    </button>
                </div>
            </div>
            <div class="col-md-4">
                <FormInput type="text" v-model="filters['comment.LOCATION'].value"
                    label="Location" id-prefix="commentLocation"
                    class="mb-1" />
                <div class="text-end">
                    <button type="button" class="btn btn-sm btn-primary"
                        @click="_ => { isSelectLocationPopupOpen = true; locationPopupFieldToSet = 'comment.LOCATION'; }">
                        Select
                    </button>
                </div>
            </div>
            <div class="col-md-4">
                <FormInput type="text" v-model="filters['COMMENT_ID'].value"
                    label="Comment ID" id-prefix="commentId" />
            </div>
            <div class="col-md-4">
                <FormInput type="text" v-model="filters['STYLE'].value"
                    label="Style" id-prefix="style"
                    class="mb-1" />
                <div class="text-end">
                    <button type="button" class="btn btn-sm btn-primary"
                        @click="_ => { isSelectStylePopupOpen = true; }">
                        Select
                    </button>
                </div>
            </div>
            <div class="col-md-4">
                <FormInput type="text" v-model="filters['DATE'].value"
                    label="Date" id-prefix="date" />
            </div>
            <div class="col-md-4">
                <FormInput type="text" v-model="filters['SERIAL'].value"
                    label="Serial" id-prefix="serial" />
            </div>
            <div class="col-md-4">
                <FormInput type="number" v-model="filters['SEQ_NO'].value"
                    label="Seq. No." id-prefix="seqNo"
                    class="mb-2" />
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" v-model="showOnlyLatestLogs" id="showOnlyLatestLogsCheckbox">
                    <label class="form-check-label" for="showOnlyLatestLogsCheckbox">
                        Show only Latest (Max SEQ_NO)
                    </label>
                </div>
            </div>
            <div class="col-md-4">
                <FormInput type="text" v-model="filters['COLOR'].value"
                    label="Color" id-prefix="color"
                    class="mb-1" />
                <div class="text-end">
                    <button type="button" class="btn btn-sm btn-primary"
                        @click="_ => { isSelectColorPopupOpen = true; }">
                        Select
                    </button>
                </div>
            </div>
            <div class="col-md-4">
                <FormInput type="number" step=".01" v-model="filters['PRICE'].value"
                    label="Price" id-prefix="price" />
            </div>
            <div class="col-md-4">
                <FormInput type="text" v-model="filters['EMPID'].value"
                    label="Employee ID" id-prefix="empid"
                    class="mb-1" />
                <div class="text-end">
                    <button type="button" class="btn btn-sm btn-primary"
                        @click="_ => { isSelectEmployeePopupOpen = true; }">
                        Select
                    </button>
                </div>
            </div>
        </div>
        <template #footer>
            <div class="text-end">
                <button type="button" class="btn btn-primary"
                    @click="tableOnFilter">
                    Apply Filters
                </button>
            </div>
        </template>
    </Card>
    <Card>
        <template #header>
            Inventory Table
        </template>
        <DataTable :loading="isLoading" lazy scrollable
            :value="inventoryRecords" :total-records="inventoryTotalRecords"
            paginator :rows="10" @page="e => { onPage(e); fetchInventoryRecords(); }"
            :pt="{ table: { class: 'table table-bordered table-hover' } }">
            <Column header="Barcode" :pt="{ bodyCell: { class: 'barcode' }}">
                <template #body="{ data }">
                    {{ constructBarcode(data.STYLE, data.DATE, data.SERIAL) }}
                </template>
            </Column>
            <Column field="STYLE" header="Style" />
            <Column field="DATE" header="Date" />
            <Column field="SERIAL" header="Serial" />
            <Column field="SEQ_NO" header="Seq. No." />
            <Column field="COLOR" header="Color" />
            <Column field="COMMENT_ID" header="Comment ID" />
            <Column field="comment.COMMENT_TIMESTAMP" header="Comment Timestamp" />
            <Column field="comment.COMMENT_TEXT" header="Comment ID" />
            <Column field="comment.LOCATION" header="Location" />
            <Column field="PREV_LOC" header="Prev. Loc." />
            <Column field="PRICE" header="Price" />
            <Column field="DISCOUNT" header="Discount" />
            <Column field="EMPID" header="Employee ID" />
            <Column field="employee.FNAME" header="Employee First Name" />
            <Column field="employee.LNAME" header="Employee Last Name" />
            <Column field="CREATED_BY" header="Created By" />
            <template #loading>
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                Fetching inventory records...
            </template>
            <template #empty>
                <div class="text-center">
                    No data.
                </div>
            </template>
            <template #footer>
                {{ inventoryTotalRecords ?? 0 }} total records
            </template>
        </DataTable>
    </Card>

    <SelectLocationPopup :is-open="isSelectLocationPopupOpen"
        @select="selectedLocation => {
            filters[locationPopupFieldToSet].value = selectedLocation.LOCATION;
            locationPopupFieldToSet = null;
            isSelectLocationPopupOpen = false;
        }"
        @close="_ => { isSelectLocationPopupOpen = false; }"
        @error="message => {
            addFlashMessage('ERROR', message);
            scrollToTop();
        }" />
    <SelectStyle :is-open="isSelectStylePopupOpen"
        @select="selectedStyle => {
            filters['STYLE'].value = selectedStyle.STYLE;
            isSelectStylePopupOpen = false;
        }"
        @close="_ => { isSelectStylePopupOpen = false; }"
        @error="message => {
            addFlashMessage('ERROR', message);
            scrollToTop();
        }" />
    <SelectColor :is-open="isSelectColorPopupOpen"
        @select="selectedColor => {
            filters['COLOR'].value = selectedColor.COLOR;
            isSelectColorPopupOpen = false;
        }"
        @close="_ => { isSelectColorPopupOpen = false; }"
        @error="message => {
            addFlashMessage('ERROR', message);
            scrollToTop();
        }" />
    <SelectEmployee :is-open="isSelectEmployeePopupOpen"
        :on-select-employee="selectedEmployee => {
            filters['EMPID'].value = selectedEmployee.EMPID;
            isSelectEmployeePopupOpen = false;
        }"
        :on-close="_ => { isSelectEmployeePopupOpen = false; }" />
</template>

<script setup>
import { onMounted, ref } from 'vue';

import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useFlashMessages } from '@/composables/flashmessages';
import { useInventory } from '@/composables/data/inventory';

import Card from '@/components/utils/Card.vue';
import FormInput from '@/components/utils/FormInput.vue';
import SelectLocationPopup from '@/components/utils/popups/SelectLocationPopup.vue';
import SelectStyle from '@/components/utils/style/SelectStyle.vue';
import SelectColor from '@/components/utils/SelectColor.vue';
import SelectEmployee from '@/components/utils/SelectEmployee.vue';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';

import scrollToTop from '@/utils/scrollToTop';

import { constructBarcode } from '@/utils/barcodes';

const { addFlashMessage } = useFlashMessages();

const { params, filters, onPage, onFilter } = useDataTableParams(
    [
        'STYLE', 'DATE', 'SERIAL', 'SEQ_NO', 'COLOR', 'COMMENT_ID', 'comment.COMMENT_TIMESTAMP',
        'comment.COMMENT_TEXT', 'PRICE', 'comment.LOCATION', 'PREV_LOC', 'EMPID', 'employee.FNAME', 'employee.LNAME'
    ]
);

const { inventoryRecords, inventoryTotalRecords, getInventoryReportRecords } =
    useInventory();

const isLoading = ref(false);

const fetchTimeout = ref(null);

const isSelectLocationPopupOpen = ref(false);
const isSelectStylePopupOpen = ref(false);
const isSelectColorPopupOpen = ref(false);
const isSelectEmployeePopupOpen = ref(false);

const locationPopupFieldToSet = ref(null); // Either PREV_LOC or comment.LOCATION

const showOnlyLatestLogs = ref(false);

async function fetchInventoryRecords() {
    inventoryRecords.value = null;

    isLoading.value = true;
    try {
        await getInventoryReportRecords({
            ...params.value,
            only_latest: showOnlyLatestLogs.value,
        });
    } catch(e) {
        addFlashMessage('ERROR', e.message);
        scrollToTop();
    }
    isLoading.value = false;
}

function tableOnFilter(e) {
    onFilter(e);
    if(fetchTimeout.value)
        clearTimeout(fetchTimeout.value);
    fetchTimeout.value = setTimeout(fetchInventoryRecords, 300);
}

onMounted(() => {
    for(const filterField of Object.keys(filters.value))
        filters.value[filterField].matchMode = 'equals';
});
</script>
