<style scoped>
img {
    max-height: 200px;
}
</style>

<template>
    <Card :class="class">
        <template #header>
            Style Image
        </template>
        <div v-if="style != null" class="text-center">
            <span v-if="isImageLoading" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span>
            <img :src="imageURL" :alt="'Style ' + (style?.STYLE ?? '') + ' image'"
                class="img-fluid"
                @load="_ => { isImageLoading = false; }"
                @error="setDefaultImage" />
        </div>
        <div v-else class="text-center no-style-message">
            Please select a style.
        </div>
    </Card>
</template>

<script setup>
import { ref, watch } from 'vue';

import { useFlashMessages } from '@/composables/flashmessages';

import Card from './Card.vue';
import scrollToTop from '@/utils/scrollToTop';

const { addFlashMessage } = useFlashMessages();

const props = defineProps({
    style: String,
    class: String
});

const imageURL = ref(null);
const isImageLoading = ref(false);

watch(() => props.style, async () => {
    if(!props.style)
        return;

    try {
        isImageLoading.value = true;
        imageURL.value = (await axios.get(route('api.styles.image-url.show', {
            style: props.style
        }))).data.data;
    } catch(e) {
        setDefaultImage();
        if(e.response.status != 404) {
            addFlashMessage('ERROR', e.message);
            scrollToTop();
        }
    }
});

function setDefaultImage() {
    isImageLoading.value = true;
    imageURL.value = `${Ziggy.url}/img/bagnoimage.jpg`;
}
</script>
