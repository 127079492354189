<template>
    <div class="row g-3">
        <div class="col-md-3">
            <DatesTableCard v-model="selectedDate" @error="message => {
                addFlashMessage('ERROR', message);
                scrollToTop();
            }" />
        </div>
        <div class="col-md-5">
            <LandedCostEnteredTableCard v-model="selectedLandedCost"
                :date="selectedDate?.DATE"
                :refresh-flag="landedCostsEnteredRefreshFlag"
                @update="message => {
                    addFlashMessage('SUCCESS', message);
                    noLandedCostsRefreshFlag = !noLandedCostsRefreshFlag;
                    scrollToTop();
                }"
                @error="message => {
                    addFlashMessage('ERROR', message);
                    scrollToTop();
                }"
                class="mb-3" />
            <SameStyleLandedCosts :style="style" />
        </div>
        <div class="col-md-4">
            <NoLandedCostEnteredTableCard v-model="selectedNoLandedCost"
                :date="selectedDate?.DATE"
                :refresh-flag="noLandedCostsRefreshFlag"
                @update="message => {
                    addFlashMessage('SUCCESS', message);
                    landedCostsEnteredRefreshFlag = !landedCostsEnteredRefreshFlag;
                    scrollToTop();
                }"
                @error="message => {
                    addFlashMessage('ERROR', message);
                    scrollToTop();
                }" />
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';

import { useFlashMessages } from '@/composables/flashmessages';

import DatesTableCard from './DatesTableCard.vue';
import LandedCostEnteredTableCard from './LandedCostEnteredTableCard.vue';
import NoLandedCostEnteredTableCard from './NoLandedCostEnteredTableCard.vue';

import scrollToTop from '@/utils/scrollToTop';
import SameStyleLandedCosts from './SameStyleLandedCosts.vue';

const { addFlashMessage } = useFlashMessages();

const selectedDate = ref(null);
const selectedLandedCost = ref(null);
const selectedNoLandedCost = ref(null);

const landedCostsEnteredRefreshFlag = ref(false);
const noLandedCostsRefreshFlag = ref(false);

const style = ref(null);

watch(selectedLandedCost, () => {
    style.value = selectedLandedCost.value?.STYLE;
});

watch(selectedNoLandedCost, () => {
    style.value = selectedNoLandedCost.value?.STYLE;
});
</script>
