<template>
    <div class="row g-3">
        <div class="col-md-4">
            <Card class="mb-3">
                <template #header>
                    Select Location
                </template>
                <LocationTable v-model="selectedLocation" />
            </Card>
            <SelectStyleUsedInLocationCard :location="selectedLocation" />
        </div>
        <div class="col-md-4">
            <Card class="mb-3">
                <template #header>
                    Select Time Period
                </template>
                <TimePeriodTable v-model="selectedTimePeriod"
                    :show-from-current-date="true"
                    :hide-properties="new Set(['PAYTYPE', 'STATUS'])"
                    paytype="MONTHLY" />
            </Card>
            <ReconMasterlistGenerationsTableCard :refresh-flag="reconMasterlistGenerationsTableRefreshFlag"
                @error="message => {
                    addFlashMessage('ERROR', message);
                    scrollToTop();
                }" />
        </div>
        <div class="col-md-4">
            <div class="row g-3">
                <div class="col-6">
                    <Card class="mb-3">
                        <template #header>
                            Masterlist
                        </template>
                        <div class="d-grid gap-2">
                            <button type="button" class="btn btn-primary"
                                :disabled="!selectedTimePeriod || isGenerating"
                                @click="_ => { generateMasterlist(); }">
                                <span v-if="isGenerating" class="spinner-border spinner-border-sm" role="status"
                                    aria-hidden="true"></span>
                                1. GENERATE MASTER LIST (monthly)
                            </button>
                            <button type="button" class="btn btn-primary"
                                :disabled="!selectedTimePeriod"
                                @click="_ => { generateMasterlistReport('recon-masterlist-detail-report'); }">
                                2a. VIEW MASTER LIST (detail)
                            </button>
                            <button type="button" class="btn btn-primary"
                                :disabled="!selectedTimePeriod"
                                @click="_ => { generateMasterlistReport('recon-masterlist-summary-report'); }">
                                2b. VIEW MASTER LIST (summary)
                            </button>
                            <button type="button" class="btn btn-primary"
                                :disabled="!selectedTimePeriod"
                                @click="_ => { generateMasterlistReport('recon-masterlist-summary-by-style-type-report'); }">
                                2c. VIEW MASTER LIST (summary+)
                            </button>
                        </div>
                    </Card>
                    <Card class="mb-3">
                        <template #header>
                            KIMBEL
                        </template>
                        <div class="d-grid gap-2">
                            <button type="button" class="btn btn-primary"
                                :disabled="!selectedTimePeriod"
                                @click="_ => { generateMasterlistReport('recon-masterlist-detail-report', 'K'); }">
                                3a. VIEW MASTER LIST (detail) [KIMBEL]
                            </button>
                            <button type="button" class="btn btn-primary"
                                :disabled="!selectedTimePeriod"
                                @click="_ => { generateMasterlistReport('recon-masterlist-summary-report', 'K'); }">
                                3b. VIEW MASTER LIST (summary) [KIMBEL]
                            </button>
                            <button type="button" class="btn btn-primary"
                                :disabled="!selectedTimePeriod"
                                @click="_ => { generateMasterlistReport('recon-masterlist-summary-by-style-type-report', 'K'); }">
                                3c. VIEW MASTER LIST (summary+) [KIMBEL]
                            </button>
                        </div>
                    </Card>
                    <Card>
                        <template #header>
                            LEB-ASI
                        </template>
                        <div class="d-grid gap-2">
                            <button type="button" class="btn btn-primary"
                                :disabled="!selectedTimePeriod"
                                @click="_ => { generateMasterlistReport('recon-masterlist-detail-report', 'L'); }">
                                4a. VIEW MASTER LIST (detail) [LEB-ASI]
                            </button>
                            <button type="button" class="btn btn-primary"
                                :disabled="!selectedTimePeriod"
                                @click="_ => { generateMasterlistReport('recon-masterlist-summary-report', 'L'); }">
                                4b. VIEW MASTER LIST (summary) [LEB-ASI]
                            </button>
                            <button type="button" class="btn btn-primary"
                                :disabled="!selectedTimePeriod"
                                @click="_ => { generateMasterlistReport('recon-masterlist-summary-by-style-type-report', 'L'); }">
                                4c. VIEW MASTER LIST (summary+) [LEB-ASI]
                            </button>
                        </div>
                    </Card>
                </div>
                <div class="col-6">
                    <Card class="mb-3">
                        <template #header>
                            Bestsellers
                        </template>
                        <div class="d-grid gap-2">
                            <button type="button" class="btn btn-primary"
                                :disabled="!selectedTimePeriod"
                                @click="_ => { generateBestsellersReport('by_month_and_employee'); }">
                                1. by MONTH, EMP, STYLE
                            </button>
                            <button type="button" class="btn btn-primary"
                                :disabled="!selectedTimePeriod"
                                @click="_ => { generateBestsellersReport('by_month'); }">
                                2. by MONTH, STYLE
                            </button>
                            <button type="button" class="btn btn-primary"
                                :disabled="!selectedTimePeriod"
                                @click="_ => { generateBestsellersReport('by_date_and_employee'); }">
                                3. by DATE, EMP, STYLE
                            </button>
                            <button type="button" class="btn btn-primary"
                                :disabled="!selectedTimePeriod"
                                @click="_ => { generateBestsellersReport('by_date'); }">
                                4. by DATE, STYLE
                            </button>
                        </div>
                    </Card>
                    <Card class="mb-3">
                        <template #header>
                            MATIBAY
                        </template>
                        <div class="d-grid gap-2">
                            <button type="button" class="btn btn-primary"
                                :disabled="!selectedTimePeriod"
                                @click="_ => { generateMasterlistReport('recon-masterlist-detail-report', 'M'); }">
                                5a. VIEW MASTER LIST (detail) [MATIBAY]
                            </button>
                            <button type="button" class="btn btn-primary"
                                :disabled="!selectedTimePeriod"
                                @click="_ => { generateMasterlistReport('recon-masterlist-summary-report', 'M'); }">
                                5b. VIEW MASTER LIST (summary) [MATIBAY]
                            </button>
                            <button type="button" class="btn btn-primary"
                                :disabled="!selectedTimePeriod"
                                @click="_ => { generateMasterlistReport('recon-masterlist-summary-by-style-type-report', 'M'); }">
                                5c. VIEW MASTER LIST (summary+) [MATIBAY]
                            </button>
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';

import { useFlashMessages } from '@/composables/flashmessages';
import { useReconMasterlists } from '@/composables/data/reconMasterlists';

import Card from '@/components/utils/Card.vue';
import LocationTable from '@/components/utils/tables/LocationTable.vue';
import TimePeriodTable from '@/components/utils/tables/TimePeriodTable.vue';

import SelectStyleUsedInLocationCard from './SelectStyleUsedInLocationCard.vue';
import ReconMasterlistGenerationsTableCard from './ReconMasterlistGenerationsTableCard.vue';

import scrollToTop from '@/utils/scrollToTop';

const { addFlashMessage } = useFlashMessages();

const { postReconMasterlistGeneration } = useReconMasterlists();

const selectedTimePeriod = ref(null);
const selectedLocation = ref(null);

const reconMasterlistGenerationsTableRefreshFlag = ref(false);

const isGenerating = ref(false);

async function generateMasterlist() {
    isGenerating.value = true;
    try {
        const response = await postReconMasterlistGeneration(selectedTimePeriod.value);
        addFlashMessage('SUCCESS', response.data.message);
        reconMasterlistGenerationsTableRefreshFlag.value = !reconMasterlistGenerationsTableRefreshFlag.value;
    } catch(e) {
        addFlashMessage('ERROR', e.message);
    }
    scrollToTop();
    isGenerating.value = false;
}

function generateMasterlistReport(reportResource, companyCode = null) {
    window.open(route(`api.time-periods.${reportResource}.show`, {
        time_period: selectedTimePeriod.value.TIME_PERIOD,
        COMPANY_CODE: companyCode,
    }));
}

function generateBestsellersReport(reportType) {
    window.open(route(`api.time-periods.accounting-bestsellers-report.show`, {
        time_period: selectedTimePeriod.value.TIME_PERIOD,
        LOCATION: selectedLocation.value?.LOCATION,
        TYPE: reportType
    }));
}
</script>
