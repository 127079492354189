import { ref } from "vue";

import axios from "axios";

import constructFetchError from "./constructFetchError";

export function useRawOrders() {
    const rawOrders = ref(null);
    const totalRecords = ref(null);

    const getRawOrders = async (params) => {
        try {
            const getRawOrdersResponse = await axios.get(route('api.raw-orders.index', params));
            rawOrders.value = getRawOrdersResponse.data.data;
            totalRecords.value = getRawOrdersResponse.data.meta.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching raw orders.');
        }
    };

    const postRawOrder = async (rawOrder) => {
        try {
            const postRawOrderResponse = await axios.post(route('api.raw-orders.store'), rawOrder);
            return postRawOrderResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while saving raw order.');
        }
    };

    const putRawOrder = async (rawOrderId, rawOrder) => {
        try {
            const putRawOrderResponse = await axios.put(route('api.raw-orders.update', {
                raw_order: rawOrderId,
            }), rawOrder);
            return putRawOrderResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while updating raw order.');
        }
    };
    
    return {
        rawOrders,
        totalRecords,
        getRawOrders,
        postRawOrder,
        putRawOrder,
    };
}
