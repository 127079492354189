<template>
    <div class="row g-3">
        <div class="col-md-4">
            <Card>
                <template #header>
                    Select Time Period
                </template>
                <FormSelect v-model="paytype"
                    label="Paytype" id-prefix="paytype"
                    :options="['WEEKLY', 'BIMONTHLY']"
                    class="mb-3" />
                <TimePeriodTable show-from-current-date
                    :paytype="paytype"
                    :hide-properties="new Set(['PAYTYPE', 'STATUS'])"
                    :sort="[{ field: 'TIME_PERIOD', order: -1 }]"
                    v-model="timePeriod" />
            </Card>
        </div>
        <div class="col-md-4">
            <Card>
                <template #header>
                    Reports
                </template>
                <div class="d-grid gap-3">
                    <button type="button" class="btn btn-primary"
                        :disabled="!timePeriod"
                        @click="generateReport('encoded-hours-spreadsheet')">
                        Encoded Hours Sheet
                    </button>
                    <button type="button" class="btn btn-primary"
                        :disabled="!timePeriod"
                        @click="generateReport('with-disbursements-without-emphours-spreadsheet')">
                        W/ Disbursements W/O Hours
                    </button>
                </div>
            </Card>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';

import Card from '@/components/utils/Card.vue';
import FormSelect from '@/components/utils/FormSelect.vue';
import TimePeriodTable from '@/components/utils/tables/TimePeriodTable.vue';

const paytype = ref('WEEKLY');
const timePeriod = ref(null);

function generateReport(report) {
    window.open(route(`api.time-periods.${report}.show`, {
        time_period: timePeriod.value.TIME_PERIOD
    }));
}
</script>
