<style scoped>
</style>

<template>
    <Card>
        <div class="row g-3">
            <div class="col-8">
                <Card>
                    <div class="row">
                        <div class="col-8"></div>
                        <div class="col-4">
                            <button class="btn btn-primary btn-sm w-100 mb-1"
                                :disabled="!timePeriod.TIME_PERIOD || !location.LOCATION"
                                @click="generateRecon">
                                <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status"
                                aria-hidden="true"></span>
                                GENERATE</button>
                        </div>
                        <div class="col-5 d-flex mb-1">
                            <label class="small w-50 my-auto">TIME_PERIOD:</label>
                            <input class="form-control form-control-sm w-50"
                                type="text"
                                v-model="timePeriod.TIME_PERIOD"
                                disabled>
                        </div>
                        <button class="btn btn-primary btn-sm col-3 mb-1"
                                @click="_ => { isSelectTimePeriodPopupOpen = true; }">
                                Select</button>
                        <div class="col-4 d-flex justify-content-between">
                            <p class="mx-auto my-auto">{{ timePeriod.START_DATE }} - {{ timePeriod.END_DATE }}</p>
                        </div>
                        <div class="col-5 d-flex mb-1">
                            <label class="small w-50 my-auto">LOCATION:</label>
                            <input class="form-control form-control-sm w-50"
                                type="text"
                                v-model="location.LOCATION"
                                disabled>
                        </div>
                        <button class="btn btn-primary btn-sm col-3 mb-1"
                            @click="_ => { isSelectLocationPopupOpen = true; }">
                            Select</button>
                        <div class="col-4 d-flex justify-content-between">
                            <p class="mx-auto my-auto">{{ location.LOCATION_KEY2 }}</p>
                            <p class="mx-auto my-auto">{{ location.DESCRIPTION }}</p>
                        </div>
                    </div>
                </Card>
            </div>
            <div class="col-4">
                <Card>
                    <button class="btn btn-primary btn-sm w-100 mb-1"
                        @click="openCommissionReportPDF"
                        :disabled="!timePeriod.TIME_PERIOD">
                        Commission Report 1
                    </button>
                    <button class="btn btn-primary btn-sm w-100 mb-1"
                        :disabled="!timePeriod.TIME_PERIOD || !location.LOCATION"
                        @click="commissionLocker">
                        {{ commissionLockerCaption }}</button>
                    <button class="btn btn-primary btn-sm w-100 mb-1"
                        @click="redirectToUpdateStoreSummary">Update Summary (CO)</button>
                </Card>
            </div>
            <div class="col-5 d-flex">
                <div class="w-100">
                    <Card>
                        <div class="row">
                            <div class="d-flex mb-1">
                                <button class="btn btn-primary btn-sm w-50"
                                    :disabled="!timePeriod.TIME_PERIOD || !location.LOCATION"
                                    @click="totalSalesUpdate">
                                    TOTAL SALES (current month):</button>
                                <input class="form-control form-control-sm ms-1 w-50"
                                       v-model="mainInformation.TOTAL_SALES"/>
                            </div>
                            <div class="d-flex mb-1">
                                <label class="small w-50 my-auto">TOTAL SALES (same month, -1 year):</label>
                                <input class="form-control form-control-sm ms-1 w-50"
                                       v-model="mainInformation.TOTAL_SALES_1"
                                       disabled/>
                            </div>
                            <div class="d-flex mb-5">
                                <label class="small w-50 my-auto">TOTAL SALES (same month, -2 year):</label>
                                <input class="form-control form-control-sm ms-1 w-50"
                                       v-model="mainInformation.TOTAL_SALES_2"
                                       disabled/>
                            </div>
                            <div class="d-flex mb-1">
                                <label class="small w-50 my-auto">NUMBER SELLING AREAS:</label>
                                <input class="form-control form-control-sm ms-1 w-50"
                                       v-model="mainInformation.SELLING_AREA_COUNT"
                                       disabled/>
                            </div>
                            <div class="d-flex mb-1">
                                <label class="small w-50 my-auto">SELLING AREA CHARGES:</label>
                                <input class="form-control form-control-sm ms-1 w-50"
                                       v-model="mainInformation.SELLING_AREA_CHARGES"
                                       disabled/>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
            <div class="col-7">
                <Card class="d-flex">
                    <div class="d-flex">
                        <div class="w-50 p-1">
                            <div class="d-flex mb-1">
                                <button class="btn btn-primary btn-sm w-50"
                                    :disabled="!timePeriod.TIME_PERIOD || !location.LOCATION"
                                    @click="quotaUpdate">BASE (update):</button>
                                <input class="form-control form-control-sm ms-1 w-50"
                                    v-model="mainInformation.QUOTA"/>
                            </div>
                            <div class="d-flex mb-1">
                                <label class="small w-50 my-auto">QUOTA:</label>
                                <input class="form-control form-control-sm ms-1 w-50"
                                    v-model="mainInformation.QUOTA2"
                                    disabled/>
                            </div>
                            <div class="d-flex mb-1">
                                <label class="small w-50 my-auto">SALES CHANGE PCT:</label>
                                <input class="form-control form-control-sm ms-1 w-50"
                                    v-model="mainInformation.SALES_CHANGE_PCT"
                                    disabled/>
                            </div>
                            <div class="d-flex mb-1">
                                <label class="small w-50 my-auto">SALES COMMISSION PCT:</label>
                                <input class="form-control form-control-sm ms-1 w-50"
                                    v-model="mainInformation.SALES_COMMISSION_PCT"
                                    disabled/>
                            </div>
                            <div class="d-flex mb-1">
                                <label class="small w-50 my-auto">SALES COMMISSION AMT:</label>
                                <input class="form-control form-control-sm ms-1 w-50"
                                    v-model="mainInformation.SALES_COMMISSION_AMT"
                                    disabled/>
                            </div>
                            <div class="d-flex mb-1">
                                <button class="btn btn-primary btn-sm w-50"
                                    :disabled="!timePeriod.TIME_PERIOD || !location.LOCATION"
                                    @click="salesMissingAmountUpdate">MISSING AMT(update):</button>
                                <input class="form-control form-control-sm ms-1 w-50"
                                    v-model="mainInformation.SALES_MISSING_AMT"/>
                            </div>
                        </div>
                        <div class="w-50 p-1">
                            <div class="d-flex mb-1">
                                <label class="small w-50 my-auto">QUOTA 1 (FIN):</label>
                                <input class="form-control form-control-sm ms-1 w-50"
                                    v-model="mainInformation.QUOTA1_FIN"
                                    disabled/>
                            </div>
                            <div class="d-flex mb-1">
                                <label class="small w-50 my-auto">QUOTA 2 (FIN):</label>
                                <input class="form-control form-control-sm ms-1 w-50"
                                    v-model="mainInformation.QUOTA2_FIN"
                                    disabled/>
                            </div>
                            <div class="mb-2">
                                <Card>
                                    <div class="d-flex mb-1">
                                        <label class="small w-50 my-auto">QUOTA (17% INC):</label>
                                        <input class="form-control form-control-sm ms-1 w-50"
                                            v-model="mainInformation.QUOTA_DAW"
                                            disabled/>
                                    </div>
                                </Card>
                            </div>
                            <div class="d-flex justify-content-center">
                                <p class="small">{{ commissionLabel }}</p>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
            <div class="col-12">
                <MainInfoTable :location="location"
                :time-period="timePeriod" ref="mainInfoTable"/>
            </div>

            <!-- MEGA INFORMATIONS -->

            <div class="col-5 d-flex">
                <div class="w-100">
                    <Card>
                        <div class="row">
                            <div class="d-flex mb-1">
                                <label class="small w-50 my-auto">OTR LOCATION:</label>
                                <select class="form-control form-control-sm w-50" v-model="location2">
                                    <option v-for="location in location2List" :value="location">{{ location.LOCATION }}</option>
                                </select>
                            </div>
                            <div class="d-flex mb-1">
                                <button class="btn btn-primary btn-sm w-50"
                                    :disabled="!timePeriod.TIME_PERIOD || !location2.LOCATION"
                                    @click="otherTotalSalesUpdate">OTR SALES (update):</button>
                                <input class="form-control form-control-sm ms-1 w-50"
                                       v-model="otherInformation.TOTAL_SALES"/>
                            </div>
                            <div class="d-flex mb-1">
                                <label class="small w-50 my-auto">OTR # OF SELLING AREAS:</label>
                                <input class="form-control form-control-sm ms-1 w-50"
                                       v-model="otherInformation.SELLING_AREA_COUNT"
                                       disabled/>
                            </div>
                            <div class="d-flex mb-1">
                                <label class="small w-50 my-auto">OTR SELLING AREA CHARGES:</label>
                                <input class="form-control form-control-sm ms-1 w-50"
                                       v-model="otherInformation.SELLING_AREA_CHARGES"
                                       disabled/>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
            <div class="col-7">
                <Card class="d-flex">
                    <div class="d-flex">
                        <div class="w-75 p-1">
                            <div class="d-flex justify-content-center w-100">
                                <small class="mb-1">YOU MUST ENTER SALES AMOUNT MANUALLY FOR OTR</small>
                            </div>
                            <div class="d-flex mb-1">
                                <label class="small w-50 my-auto">OTR SALES COMMISSION AMT:</label>
                                <input class="form-control form-control-sm ms-1 w-50"
                                       v-model="otherInformation.SALES_COMMISSION_AMT"
                                       disabled/>
                            </div>
                            <div class="d-flex mb-1">
                                <button class="btn btn-primary btn-sm w-50"
                                    :disabled="!timePeriod.TIME_PERIOD || !location2.LOCATION"
                                    @click="otherSalesMissingAmountUpdate">OTR SALES MISSING AMT(update):</button>
                                <input class="form-control form-control-sm ms-1 w-50"
                                       v-model="otherInformation.SALES_MISSING_AMT"/>
                            </div>
                        </div>
                        <div class="w-25 p-1">
                            <div>
                                <Card>
                                    <label class="small my-auto mx-auto">QUOTA (15% INC):</label>
                                    <input class="form-control form-control-sm mb-1"
                                            v-model="otherInformation.QUOTA"/>
                                    <button class="btn btn-primary btn-sm w-100"
                                        :disabled="!timePeriod.TIME_PERIOD || !location2.LOCATION"
                                        @click="otherQuotaUpdate">UPDATE QUOTA OTR</button>
                                </Card>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
            <div class="col-12">
                <OtherInfoTable :location2="location2"
                    :time-period="timePeriod" ref="otherInfoTable"/>
            </div>
            <div class="col-12">
                <Card>
                    <div class="d-flex m-1">
                        <div class="w-50">
                            <button class="btn btn-primary btn-sm mb-1"
                                @click="() => isUploadingQuota2 = true">UPLOAD NEW QUOTA2</button>
                            <p>Upload .xlsx, .xls, or .csv for quota2.</p>
                        </div>
                        <div class="w-50">
                            <button class="btn btn-primary btn-sm"
                                @click="() => isMassGenerating = true">MASS GENERATE</button>
                            <p>Update total sales for all branches.</p>
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    </Card>

    <SelectTimePeriodPopup :is-open="isSelectTimePeriodPopupOpen"
        @select="handleSelectTimePeriod"
        @close="_ => { isSelectTimePeriodPopupOpen = false; }"/>
    <SelectLocationPopup :is-open="isSelectLocationPopupOpen"
        @select="handleSelectLocation"
        @close="_ => { isSelectLocationPopupOpen = false; }"/>
    <MassGenerateStoreSummaryPopup :is-open="isMassGenerating"
        @close="_ => { isMassGenerating = false; }"/>
    <UploadQuota2Popup :is-open="isUploadingQuota2"
        @close="_ => { isUploadingQuota2 = false; }"
        @error="message => { addFlashMessage('ERROR', message); scrollToTop(); }"
        @success="message => { addFlashMessage('SUCCESS', message); scrollToTop();}"/>
</template>

<script setup>
import axios from 'axios';
import { ref, onMounted, watch } from 'vue';

import Card from '@/components/utils/Card.vue';
import SelectTimePeriodPopup from '@/components/utils/popups/SelectTimePeriodPopup.vue';
import SelectLocationPopup from '@/components/utils/popups/SelectLocationPopup.vue';
import MassGenerateStoreSummaryPopup from './MassGenerateStoreSummaryPopup.vue';
import UploadQuota2Popup from './UploadQuota2Popup.vue';
import OtherInfoTable from './OtherInfoTable.vue';
import MainInfoTable from './MainInfoTable.vue';

import handleFetchErrors from "@/utils/handleFetchErrors";
import scrollToTop from "@/utils/scrollToTop";
import { useFlashMessages } from "@/composables/flashmessages";

const { addFlashMessage } = useFlashMessages();

const commissionLockerCaption = ref('LOCK COMMISSION');
const jan2014TimePeriod = ref(500037);

const isSelectTimePeriodPopupOpen = ref(false);
const isSelectLocationPopupOpen = ref(false);

const timePeriod = ref({
    TIME_PERIOD: null,
    START_DATE: null,
    END_DATE: null,
});

const location = ref({
    LOCATION: null,
    LOCATION_KEY2: null,
    DESCRIPTION: null,
});

const mainInformation = ref({
    QUOTA: null,
    QUOTA2: null,
    QUOTA1_FIN: null,
    QUOTA2_FIN: null,
    SALES_CHANGE_PCT: null,
    SALES_COMMISSION_AMT: null,
    SALES_COMMISSION_PCT: null,
    SALES_MISSING_AMT: null,
    SELLING_AREA_CHARGES: null,
    SELLING_AREA_COUNT: null,
    TOTAL_SALES: null,
    TOTAL_SALES_1: null,
    TOTAL_SALES_2: null,
});
const mainInfoTable = ref(null);
const commissionLabel = ref("WITH COMMISSION");

const location2List = ref([]);
const location2 = ref({
    LOCATION: null,
    DESCRIPTION: null,
});
const otherInformation = ref({
    QUOTA: null,
    SALES_COMMISSION_AMT: null,
    SALES_MISSING_AMT: null,
    SELLING_AREA_CHARGES: null,
    SELLING_AREA_COUNT: null,
    TOTAL_SALES: null,
    TOTAL_SALES_1: null,
    TOTAL_SALES_2: null,
});
const otherInfoTable = ref(null);

const isUploadingQuota2 = ref(false);
const isMassGenerating = ref(false);

onMounted(() => {
    const items = document.querySelector('#store-summary').getAttribute('data-item');
    const parsedTimePeriod = JSON.parse(items);
    timePeriod.value = {
        TIME_PERIOD: parsedTimePeriod.time_period,
        START_DATE: parsedTimePeriod.start_date,
        END_DATE: parsedTimePeriod.end_date
    }
    getQuotaLabel();
})

const quotaLabel = ref(null);
function getQuotaLabel() {
    if (timePeriod.value.TIME_PERIOD < jan2014TimePeriod.value)
        quotaLabel.value = "QUOTA (15% INC):";
    else
        quotaLabel.value = "QUOTA (17% INC):";
}

watch(timePeriod, () => {
    getQuotaLabel();
    if(timePeriod.value.TIME_PERIOD && location.value.LOCATION){
        updateInformation();
        commissionLockerStatus();
    }
})

watch(location, () => {
    commissionLockerStatus();
    updateInformation();
})

function updateInformation() {
    getMainInformation();
    getLocation2();
}

watch(location2, () => {
    getOtherInformation();
})

async function getLocation2() {
    if(!location.value.LOCATION_KEY2) {
        otherInformation.value = {
            QUOTA: null,
            SALES_COMMISSION_AMT: null,
            SALES_MISSING_AMT: null,
            SELLING_AREA_CHARGES: null,
            SELLING_AREA_COUNT: null,
            TOTAL_SALES: null,
            TOTAL_SALES_1: null,
            TOTAL_SALES_2: null,
        };
        location2List.value = [];
        location2.value = {
            LOCATION: null,
            DESCRIPTION: null,
        }
        return;
    }

    try {
        const response = await axios.get(route('api.locations.mega', {
            LOCATION_KEY2:  location.value.LOCATION_KEY2,
            LOCATION: location.value.LOCATION,
        }))

        location2List.value = response.data.data;
        location2.value = response.data.data[0];
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, 'Error while fetching location mega.');
    }
}

async function getMainInformation() {
    try {
        const response = await axios.get(route('api.recon-store-summary-with-quota', {
            TIME_PERIOD: timePeriod.value.TIME_PERIOD,
            LOCATION: location.value.LOCATION,
        }));

        if(response.data.data?.summary){
            mainInformation.value = response.data.data.summary;
            commissionLabel.value = response.data.data.commission_label
        } else {
            mainInformation.value = {
                QUOTA: null,
                QUOTA2: null,
                QUOTA1_FIN: null,
                QUOTA2_FIN: null,
                SALES_CHANGE_PCT: null,
                SALES_COMMISSION_AMT: null,
                SALES_COMMISSION_PCT: null,
                SALES_MISSING_AMT: null,
                SELLING_AREA_CHARGES: null,
                SELLING_AREA_COUNT: null,
                TOTAL_SALES: null,
                TOTAL_SALES_1: null,
                TOTAL_SALES_2: null,
                QUOTA_DAW: null,
            };
        }
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, 'Error while fetching main information');
    }
}

async function getOtherInformation() {
    if(!location2.value || !location2.value.LOCATION)
        return;

    try {
        const response = await axios.get(route('api.recon-store-summary.show', {
            TIME_PERIOD: timePeriod.value.TIME_PERIOD,
            LOCATION: location2.value.LOCATION,
        }));
        otherInformation.value = response.data.data;
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, 'Error while fetching mega information.');
    }
}

function openCommissionReportPDF() {
    window.open(route('api.reports.accounting.commission-report', {
        TIME_PERIOD: timePeriod.value.TIME_PERIOD,
        LOCATION: location.value.LOCATION,
    }));
}

const isLoading = ref(false);
async function generateRecon() {
    try {
        isLoading.value = true;
        const response = await axios.post(route('api.recon.generate-store-summary', {
            TIME_PERIOD: timePeriod.value.TIME_PERIOD,
            LOCATION: location.value.LOCATION
        }));
        addFlashMessage('SUCCESS', response.data.message);
        getMainInformation();
        mainInfoTable.value.getMainInfoTable();
        getOtherInformation();
        otherInfoTable.value.getOtherInfoTable();
        scrollToTop();
        isLoading.value = false;
    } catch (e) {
        console.log(e)
        handleFetchErrors(e, 'Error while generating recon.');
        isLoading.value = false;
    }
}

async function commissionLockerStatus() {
    try {
        const response = await axios.get(route('api.commission-locker.show', {
            time_period: timePeriod.value.TIME_PERIOD,
            location: location.value.LOCATION,
        }));

        commissionLockerCaption.value = response.data[0].length > 0 ? 'UNLOCK COMMISSION' : 'LOCK COMMISSION';
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, 'Error while fetching commission locker.');
    }
}

const LOCK_COMMISSION = 'LOCK COMMISSION';
const UNLOCK_COMMISSION = 'UNLOCK COMMISSION';

async function commissionLocker() {
    try {
        if (commissionLockerCaption.value === LOCK_COMMISSION) {
            const response = await axios.post(route('api.commission-locker.store'), {
                TIMEPERIOD: timePeriod.value.TIME_PERIOD,
                LOCATION: location.value.LOCATION,
            });

            addFlashMessage('SUCCESS', response.data.message);
            commissionLockerCaption.value = UNLOCK_COMMISSION;
        } else {
            const response = await axios.delete(route('api.commission-locker.destroy', {
                time_period: timePeriod.value.TIME_PERIOD,
                location: location.value.LOCATION,
            }));

            addFlashMessage('SUCCESS', response.data.message);
            commissionLockerCaption.value = LOCK_COMMISSION;
        }
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, 'Error while updating commission locker.');
        scrollToTop();
    }
}

async function totalSalesUpdate() {
    try {
        const response = await axios.post(route('api.location-mega.store-update', {
            time_period: timePeriod.value.TIME_PERIOD,
            location: location.value.LOCATION,
        }), {
            SALES: mainInformation.value.TOTAL_SALES,
        });

        addFlashMessage('SUCCESS', response.data.message);
        scrollToTop();
        generateRecon();
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, 'Error while updating total sales');
        scrollToTop();
    }
}

async function quotaUpdate() {
    try {
        const response = await axios.post(route('api.location-quota.store-update', {
            time_period: timePeriod.value.TIME_PERIOD,
            location: location.value.LOCATION,
        }), {
            QUOTA: mainInformation.value.QUOTA,
            TOTAL_SALES_1: mainInformation.value.TOTAL_SALES_1,
            TOTAL_SALES_2: mainInformation.value.TOTAL_SALES_2,
        });

        addFlashMessage('SUCCESS', response.data.message);
        scrollToTop();
        generateRecon();
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, 'Error while updating quota.');
        scrollToTop();
    }
}

async function salesMissingAmountUpdate() {
    try {
        const response = await axios.post(route('api.inventory-missing.store-update', {
            date: timePeriod.value.START_DATE,
            location: location.value.LOCATION,
        }), {
            MISSING_AMT: mainInformation.value.SALES_MISSING_AMT,
        });

        addFlashMessage('SUCCESS', response.data.message);
        scrollToTop();
        generateRecon();
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, 'Error while updating missing amount.');
        scrollToTop();
    }
}

async function otherTotalSalesUpdate() {
    try {
        const response = await axios.post(route('api.location-mega.store-update', {
            time_period: timePeriod.value.TIME_PERIOD,
            location: location2.value.LOCATION,
        }), {
            SALES: otherInformation.value.TOTAL_SALES,
        });

        addFlashMessage('SUCCESS', response.data.message);
        scrollToTop();
        generateRecon();
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, 'Error while updating mega location total sales');
        scrollToTop();
    }
}

async function otherSalesMissingAmountUpdate() {
    try {
        const response = await axios.post(route('api.inventory-missing.store-update', {
            date: timePeriod.value.START_DATE,
            location: location2.value.LOCATION,
        }), {
            MISSING_AMT: otherInformation.value.SALES_MISSING_AMT,
        });

        addFlashMessage('SUCCESS', response.data.message);
        scrollToTop();
        generateRecon();
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, 'Error while updating mega location missing amount.');
        scrollToTop();
    }
}

async function otherQuotaUpdate() {
    try {
        const response = await axios.post(route('api.location-quota.store-update', {
            time_period: timePeriod.value.TIME_PERIOD,
            location: location2.value.LOCATION,
        }), {
            QUOTA: otherInformation.value.QUOTA,
            TOTAL_SALES_1: otherInformation.value.TOTAL_SALES_1,
            TOTAL_SALES_2: otherInformation.value.TOTAL_SALES_2,
        });

        addFlashMessage('SUCCESS', response.data.message);
        scrollToTop();
        generateRecon();
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, 'Error while updating mega location quota.');
        scrollToTop();
    }
}

function handleSelectTimePeriod(selectedTimePeriod) {
    timePeriod.value = {
        TIME_PERIOD: selectedTimePeriod.TIME_PERIOD,
        START_DATE: selectedTimePeriod.START_DATE,
        END_DATE: selectedTimePeriod.END_DATE,
    };
    isSelectTimePeriodPopupOpen.value = false;
}

function handleSelectLocation(selectedLocation) {
    location.value = {
        LOCATION: selectedLocation.LOCATION,
        LOCATION_KEY2: selectedLocation.LOCATION_KEY2,
        DESCRIPTION: selectedLocation.DESCRIPTION
    };
    isSelectLocationPopupOpen.value = false;
}

function redirectToUpdateStoreSummary() {
    window.open(route('catalog.update-store-summary', {
        time_period: timePeriod.value.TIME_PERIOD,
        location: location.value.LOCATION
    }));
}
</script>
