<template>
    <div class="row g-3">
        <div class="col-md-6">
            <Card>
                <template #header>
                    Create/Update Detail
                </template>
                <FormInput type="text" v-model="joborderDetail.STYLE" disabled
                    label="Style" id-prefix="style" class="mb-1"
                    :errors="errors.STYLE"
                    @keydown-enter="focusnext('selectStyleButton')" />
                <div class="text-end mb-3">
                    <button type="button" class="btn btn-primary me-2"
                        @click="_ => { isSelectingUsedStyle = true; }">
                        Select from Styles used by Account
                    </button>
                    <button type="button" class="btn btn-primary"
                        @click="_ => { isSelectingStyle = true; }">
                        Select from KIMBEL-SP Styles
                    </button>
                </div>
                <FormInput type="text" v-model="joborderDetail.COMPOSITION"
                    label="Composition" id-prefix="composition" class="mb-3"
                    :errors="errors.COMPOSITION"
                    @keydown-enter="focusnext('quantityFormInput')" />
                <FormInput type="number" v-model="joborderDetail.QUANTITY"
                    label="Quantity" id-prefix="quantity" class="mb-3"
                    :errors="errors.QUANTITY"
                    @keydown-enter="focusnext('eventDateFormInput')" />
                <FormInput type="date" v-model="joborderDetail.EVENT_DATE"
                    label="Event Date" id-prefix="eventDate" class="mb-3"
                    :errors="errors.EVENT_DATE"
                    @keydown-enter="focusnext('commentFormInput')" />
                <FormTextarea v-model="joborderDetail.COMMENT"
                    label="Comment" id-prefix="comment" class="mb-3"
                    :errors="errors.COMMENT"
                    @keydown-enter="focusnext('payeeTypeFormInput')" />
                <FormSelect v-model="joborderDetail.PAYEE_TYPE"
                    :options="[{value: 'E', verbose: 'External'}, {value: 'I', verbose: 'Internal'}]"
                    :option-value="option => option.value"
                    :option-to-string="option => option.verbose"
                    label="Payee Type" id-prefix="payeeType" class="mb-3"
                    @change="_ => {
                        if(joborderDetail.PAYEE_TYPE == 'I') isSelectingEmployee = true;
                        else if(joborderDetail.PAYEE_TYPE == 'E') isSelectingAccount = true;
                        joborderDetail.PAYEE_ID = null;
                        selectedRelationships.account = null;
                        selectedRelationships.employee = null;
                    }"
                    :errors="errors.PAYEE_TYPE" />
                <FormInput type="text" v-model="joborderDetail.PAYEE_ID" disabled
                    label="Payee ID" id-prefix="payeeId"
                    :errors="errors.PAYEE_ID"
                    :form-text="payeeName" />
                <template #footer>
                    <div class="text-end">
                        <button type="button" class="btn btn-primary me-2"
                            :disabled="!joborderNew"
                            @click="saveJoborderDetail(1)">
                            1. Receive Order
                        </button>
                        <button type="button" class="btn btn-primary me-2"
                            :disabled="!joborderNew"
                            @click="saveJoborderDetail(2)">
                            2. Complete Order
                        </button>
                        <button type="button" class="btn btn-primary me-2"
                            @click="saveJoborderDetail(3)">
                            3. Deliver Order
                        </button>
                        <button type="button" class="btn btn-primary"
                            @click="saveJoborderDetail(0)">
                            Comment Only
                        </button>
                    </div>
                </template>
            </Card>
        </div>
        <div class="col-md-4">
            <StyleImageCard :style="selectedRelationships.style?.STYLE" />
        </div>
    </div>

    <SelectStyle :is-open="isSelectingStyle"
        @select="selectedStyle => {
            joborderDetail.STYLE = selectedStyle.STYLE;
            selectedRelationships.style = selectedStyle;
            isSelectingStyle = false;
        }"
        @close="_ => { isSelectingStyle = false; }"
        :preset-filters="{ STYLE_TYPE: { value: 'KIMBEL-SP', matchMode: 'contains' } }" />

    <SelectUsedStyleByAccount :account-id="joborderNew?.ACCOUNT_ID"
        :is-open="isSelectingUsedStyle"
        @select="async (selectedStyle) => {
            joborderDetail.STYLE = selectedStyle.STYLE;
            selectedRelationships.style = await getStyle(selectedStyle.STYLE);
            isSelectingUsedStyle = false;
        }"
        @close="_ => { isSelectingUsedStyle = false; }" />

    <SelectAccount :is-open="isSelectingAccount"
        :on-select-account="selectedAccount => {
            joborderDetail.PAYEE_ID = selectedAccount.ACCOUNT_ID;
            selectedRelationships.account = selectedAccount;
            isSelectingAccount = false;
        }"
        :on-close="_ => { isSelectingAccount = false; }" />

    <SelectEmployee :is-open="isSelectingEmployee"
        :on-select-employee="selectedEmployee => {
            joborderDetail.PAYEE_ID = selectedEmployee.EMPID;
            selectedRelationships.employee = selectedEmployee;
            isSelectingEmployee = false;
        }"
        :on-close="_ => { isSelectingAccount = false; }" />
</template>

<script setup>
import { computed, ref, watch } from 'vue';

import { useFlashMessages } from '@/composables/flashmessages';

import { useJoborderDetails } from '@/composables/data/joborderDetails';
import { useStyles } from '@/composables/data/styles';

import SelectStyle from '@/components/utils/style/SelectStyle.vue';
import SelectUsedStyleByAccount from './SelectUsedStyleByAccount.vue';
import SelectAccount from '@/components/utils/SelectAccount.vue';
import SelectEmployee from '@/components/utils/SelectEmployee.vue';

import StyleImageCard from '@/components/utils/StyleImageCard.vue';

import Card from '@/components/utils/Card.vue';
import FormInput from '@/components/utils/FormInput.vue';
import FormSelect from '@/components/utils/FormSelect.vue';
import FormTextarea from '@/components/utils/FormTextarea.vue';

import focusnext from '@/utils/focusnext';
import scrollToTop from '@/utils/scrollToTop';

const props = defineProps({
    joborderNew: Object,
    detailToUpdate: Object,
    refreshFlag: Boolean,
});

const emit = defineEmits(['action-done']);

const { getStyle } = useStyles();

const { addFlashMessage } = useFlashMessages();

const { getJoborderDetails, postJoborderDetailReception, postJoborderDetailCompletion,
    postJoborderDetailDelivery, postJoborderDetailComment } = useJoborderDetails();

const errors = ref({});

const joborderDetail = ref({
    STYLE: null,
    COMPOSITION: null,
    QUANTITY: null,
    EVENT_DATE: null,
    COMMENT: null,
    PAYEE_TYPE: null,
    PAYEE_ID: null,
});

const selectedRelationships = ref({
    style: null,
    account: null,
    employee: null,
});

const payeeName = computed(() => {
    if(joborderDetail.value.PAYEE_TYPE == 'E')
        return selectedRelationships.value.account?.ACCOUNT_NAME;
    else if(joborderDetail.value.PAYEE_TYPE == 'I') {
        const employee = selectedRelationships.value.employee;
        return `${employee?.FNAME} ${employee?.LNAME}`;
    }
});

const isSelectingStyle = ref(false);
const isSelectingUsedStyle = ref(false);
const isSelectingAccount = ref(false);
const isSelectingEmployee = ref(false);

watch(() => props.detailToUpdate, async () => {
    joborderDetail.value = {
        STYLE: null,
        COMPOSITION: null,
        QUANTITY: null,
        EVENT_DATE: null,
        COMMENT: null,
        PAYEE_TYPE: null,
        PAYEE_ID: null,
    };

    if(props.detailToUpdate) {
        joborderDetail.value.STYLE = props.detailToUpdate.style;
        joborderDetail.value.COMPOSITION = props.detailToUpdate.composition;
        selectedRelationships.value.style = await getStyle(props.detailToUpdate.style);
    }
});

async function saveJoborderDetail(action) {
    errors.value = {};
    try {
        switch(action) {
            case 0:
                await postJoborderDetailComment(props.joborderNew.JONO, {
                    STYLE: joborderDetail.value.STYLE,
                    COMPOSITION: joborderDetail.value.COMPOSITION,
                    EVENT_DATE: joborderDetail.value.EVENT_DATE,
                    COMMENT: joborderDetail.value.COMMENT,
                });
                addFlashMessage('SUCCESS', 'Successfully commented on order.');
                break;
            case 1:
                const currentJoborderDetails = await getJoborderDetails(props.joborderNew.JONO, {
                    filters: {
                        STYLE: {
                            value: joborderDetail.value.STYLE,
                            matchMode: 'equals'
                        },
                        COMPOSITION: {
                            value: joborderDetail.value.COMPOSITION,
                            matchMode: 'equals'
                        }
                    }
                });
                let price = null;
                if(currentJoborderDetails.length == 0)
                    price = window.prompt("Enter unit price for the new orders:");
                await postJoborderDetailReception(props.joborderNew.JONO, {
                    ...joborderDetail.value,
                    PRICE: price,
                });
                addFlashMessage('SUCCESS', 'Successfully received order.');
                break;
            case 2:
                await postJoborderDetailCompletion(props.joborderNew.JONO, joborderDetail.value);
                addFlashMessage('SUCCESS', 'Successfully completed order.');
                break;
            case 3:
                await postJoborderDetailDelivery(props.joborderNew.JONO, joborderDetail.value);
                addFlashMessage('SUCCESS', 'Successfully delivered order.');
                break;
        }
        
        joborderDetail.value = {
            STYLE: null,
            COMPOSITION: null,
            QUANTITY: null,
            EVENT_DATE: null,
            COMMENT: null,
            PAYEE_TYPE: null,
            PAYEE_ID: null,
        };
        selectedRelationships.value = {
            account: null,
            employee: null,
        };
        emit('action-done');
    } catch(e) {
        addFlashMessage('ERROR', e.message);
        errors.value = e.errors;
    }
    scrollToTop();
}
</script>
