import { ref } from "vue";

import axios from "axios";

import constructFetchError from "./constructFetchError";

export function useMaterials() {
    const materials = ref(null);
    const totalRecords = ref(null);

    const getMaterials = async (params) => {
        try {
            const materialsResponse = await axios.get(route('api.materials.index', params));
            materials.value = materialsResponse.data.data;
            totalRecords.value = materialsResponse.data.meta.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching materials.');
        }
    };

    const postMaterial = async (material) => {
        try {
            const postMaterialResponse = await axios.post(route('api.materials.store'), material);
            return postMaterialResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while saving material.');
        }
    };

    const putMaterial = async (id, material) => {
        try {
            const putMaterialResponse = await axios.put(route('api.materials.update', {
                material: id
            }), material);
            return putMaterialResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while updating material.');
        }
    };

    const deleteMaterial = async (id) => {
        try {
            await axios.delete(route('api.materials.destroy', {
                material: id
            }));
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while deleting material.');
        }
    };
    
    return { materials, totalRecords, getMaterials, postMaterial, putMaterial, deleteMaterial };
}
