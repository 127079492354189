<template>
    <div :class="class">
        <Card class="mb-3">
            <template #header>
                Scanned Barcodes
            </template>
            <div class="row mb-3">
                <div class="col-md-6">
                    <form @submit.prevent="addBarcode">
                        <div class="row g-1">
                            <div class="col-9">
                                <FormInput type="text" v-model="barcodeToUpload"
                                    label="Enter barcode" id-prefix="barcodeToUpload"
                                    is-horizontal
                                    :errors="barcodeToUploadErrors" class="mb-1"
                                    @keydown-enter="addBarcode" />
                            </div>
                            <div class="col-3">
                                <button type="submit" class="btn btn-primary">
                                    Add
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="col-md-6">
                    <div class="row g-1">
                        <div class="col-9">
                            <div class="row">
                                <label for="barcodesFileInput" class="col-sm-3 col-form-label">Import File (.txt, .csv)</label>
                                <div class="col-sm-9">
                                    <input type="file" required ref="barcodesFileInput"
                                        accept=".txt,.xlsx" @change="barcodesFileOnChange"
                                        id="barcodesFileInput" class="form-control"
                                        @keydown.enter.prevent />
                                </div>
                            </div>
                        </div>
                        <div class="col-3">
                            <button type="button" class="btn btn-primary"
                                :disabled="!barcodesFile || !inventoryCheckDescription"
                                @click="processBarcodesFile">
                                Import
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <DataTable :value="barcodesToUpload" 
                paginator :rows="10"
                filter-display="row" v-model:filters="filters"
                sort-mode="multiple"
                :pt="{ table: { class: 'table table-bordered table-hover' } }" class="mb-3">
                <Column field="index" header="#" sortable :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                            class="form-control" placeholder="Search" />
                        <Button type="button" class="btn btn-primary" @click="filterCallback()">
                            Search
                        </Button>
                    </template>
                </Column>
                <Column field="RAW" header="Raw" sortable :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                    bodyCell: { class: 'barcode' } }">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                            class="form-control" placeholder="Search" />
                        <Button type="button" class="btn btn-primary" @click="filterCallback()">
                            Search
                        </Button>
                    </template>
                </Column>
                <Column field="STYLE" header="Style" sortable :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                            class="form-control" placeholder="Search" />
                        <Button type="button" class="btn btn-primary" @click="filterCallback()">
                            Search
                        </Button>
                    </template>
                </Column>
                <template #footer>
                    There are {{ barcodesToUpload?.length ?? 0 }} records.
                </template>
                <template #empty>
                    <div class="text-center">
                        No barcodes yet.
                    </div>
                </template>
            </DataTable>
            <DataTable :value="noBarcodesToUpload" 
                paginator :rows="10"
                filter-display="row" v-model:filters="noBarcodesFilters"
                sort-mode="multiple"
                :pt="{ table: { class: 'table table-bordered table-hover' } }">
                <Column field="index" header="#" sortable :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                            class="form-control" placeholder="Search" />
                        <Button type="button" class="btn btn-primary" @click="filterCallback()">
                            Search
                        </Button>
                    </template>
                </Column>
                <Column field="STYLE" header="Style" sortable :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                            class="form-control" placeholder="Search" />
                        <Button type="button" class="btn btn-primary" @click="filterCallback()">
                            Search
                        </Button>
                    </template>
                </Column>
                <Column field="COLOR" header="Color" sortable :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' } }">
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                            class="form-control" placeholder="Search" />
                        <Button type="button" class="btn btn-primary" @click="filterCallback()">
                            Search
                        </Button>
                    </template>
                </Column>
                <template #footer>
                    There are {{ noBarcodesToUpload?.length ?? 0 }} records.
                </template>
                <template #empty>
                    <div class="text-center">
                        No data yet.
                    </div>
                </template>
            </DataTable>
            <template #footer>
                <div class="text-end">
                    <button type="button" class="btn btn-outline-danger me-2"
                        :disabled="barcodesToUpload.length < 1 && noBarcodesToUpload.length < 1"
                        @click="clear">
                        Clear
                    </button>
                    <button type="button" class="btn btn-primary"
                        :disabled="!inventoryCheckDescription ||
                            (barcodesToUpload.length < 1 && noBarcodesToUpload.length < 1) ||
                            isAddingToInventoryCheck"
                        @click="addToInventoryCheck">
                        <span v-if="isAddingToInventoryCheck" class="spinner-border spinner-border-sm"
                            role="status" aria-hidden="true"></span>
                        Add to Inventory Check
                    </button>
                </div>
            </template>
        </Card>
        <div class="row g-3">
            <div class="col-md-6">
                <Card class="mb-3">
                    <template #header>
                        Malformed Barcodes
                    </template>
                    <DataTable :value="malformedBarcodes" 
                        paginator :rows="10"
                        filter-display="row" v-model:filters="malformedBarcodesFilters"
                        sort-mode="multiple"
                        selection-mode="single" v-model:selection="selectedMalformedBarcode"
                        @rowSelect="e => { selectedMalformedBarcodeIndex = e.index; }"
                        @rowUnselect="e => { selectedMalformedBarcodeIndex = null; }"
                        :pt="{ table: { class: 'table table-bordered table-hover' } }">
                        <Column field="barcode" header="Barcode" sortable :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                            bodyCell: { class: 'barcode' } }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                                    class="form-control" placeholder="Search" />
                                <Button type="button" class="btn btn-primary" @click="filterCallback()">
                                    Search
                                </Button>
                            </template>
                        </Column>
                        <Column field="status" header="Status" sortable :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                            bodyCell: { class: 'barcode' } }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                                    class="form-control" placeholder="Search" />
                                <Button type="button" class="btn btn-primary" @click="filterCallback()">
                                    Search
                                </Button>
                            </template>
                        </Column>
                        <Column field="status_conversion_nobc" header="Status (after clicking NOBC)" sortable :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                            bodyCell: { class: 'barcode' } }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                                    class="form-control" placeholder="Search" />
                                <Button type="button" class="btn btn-primary" @click="filterCallback()">
                                    Search
                                </Button>
                            </template>
                        </Column>
                        <template #footer>
                            There are {{ malformedBarcodes?.length ?? 0 }} records.
                        </template>
                        <template #empty>
                            <div class="text-center">
                                No data.
                            </div>
                        </template>
                    </DataTable>
                    <template #footer>
                        <div class="text-end">
                            <button type="button" class="btn btn-outline-danger me-2"
                                :disabled="!selectedMalformedBarcode"
                                @click="deleteSelectedMalformedBarcode">
                                Delete Selected from Table
                            </button>
                            <button type="button" class="btn btn-primary"
                                :disabled="malformedBarcodes.length < 1"
                                @click="addStyleColorNOBC">
                                Add (STYLE COLOR) as NOBC
                            </button>
                        </div>
                    </template>
                </Card>
            </div>
            <div class="col-md-6">
                <AddNoBarcodeForm @add="addNoBarcode" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';

import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useColors } from '@/composables/data/colors';
import { useStyles } from '@/composables/data/styles';
import { useInventoryChecks } from '@/composables/data/inventoryChecks';

import Card from '@/components/utils/Card';
import FormInput from '@/components/utils/FormInput';

import AddNoBarcodeForm from './AddNoBarcodeForm.vue';

import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

import axios from 'axios';

import { deconstructBarcode } from '@/utils/barcodes';

const { getColor } = useColors();
const { getStyle } = useStyles();

const props = defineProps({
    inventoryCheckDescription: Object,
    class: String,
});

const emit = defineEmits(['success', 'error']);

const barcodeToUpload = ref(null);
const barcodeToUploadErrors = ref(null);

const barcodesToUpload = ref([]);
const noBarcodesToUpload = ref([]);
const malformedBarcodes = ref([]);

const selectedMalformedBarcode = ref(null);
const selectedMalformedBarcodeIndex = ref(null);

const { filters } = useDataTableParams(['index', 'STYLE', 'RAW']);
const { filters : noBarcodesFilters } = useDataTableParams(['index', 'COLOR', 'STYLE']);
const { filters : malformedBarcodesFilters } = useDataTableParams(['barcode', 'status', 'status_conversion_nobc']);

const { postMultipleInventoryChecks } = useInventoryChecks();

const isAddingToInventoryCheck = ref(false);

// For text file importing
const barcodesFileInput = ref(null);
const barcodesFile = ref();

function barcodesFileOnChange(event) {
    const file = event.target.files[0];
    barcodesFile.value = file;
}

function addBarcode() {
    barcodeToUploadErrors.value = null;
    try {
        barcodesToUpload.value.push({
            index: barcodesToUpload.value.length + 1,
            STYLE: deconstructBarcode(barcodeToUpload.value).style,
            RAW: barcodeToUpload.value
        });
        barcodeToUpload.value = null;
    } catch(e) {
        malformedBarcodes.value.push({
            barcode: barcodeToUpload.value,
            status: 'Malformed'
        });
        barcodeToUpload.value = null;
    }
}

function addNoBarcode(noBarcode) {
    noBarcodesToUpload.value.push({
        index: noBarcodesToUpload.value.length + 1,
        ...noBarcode,
    });
}

function processBarcodesFile() {
    if(barcodesFile.value.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
        importFromExcelFile();
    else
        importFromTextFile();
}

function importFromTextFile() {
    const reader = new FileReader();
    reader.onload = e => {
        const malformedBarcodesCount = malformedBarcodes.value.length;
        for(let barcode of e.target.result.split(/[\r\n]+/g)) {
            barcodeToUpload.value = barcode;
            addBarcode();
        }
        window.alert('Barcodes have been added.');
        if(malformedBarcodes.value.length > malformedBarcodesCount)
            window.alert('There are malformed barcodes from the text file. Please check.');
        barcodesFileInput.value.value = null;
        barcodesFile.value = null;
    };
    reader.readAsText(barcodesFile.value);
}

async function importFromExcelFile() {
    // Hit API to extract contents
    try {
        const formData = new FormData();
        formData.append("file", barcodesFile.value);

        const response = await axios.post(route('api.spreadsheet-barcode-extractions.store'), formData);
        for(const barcode of response.data.data) {
            if(!barcode)
                continue;

            barcodeToUpload.value = barcode;
            addBarcode();
        }
        
        barcodesFileInput.value.value = null;
        barcodesFile.value = null;
    } catch(e) {
        console.log(e);
        emit('error', e.message);
    }
}

function deleteSelectedMalformedBarcode() {
    malformedBarcodes.value.splice(selectedMalformedBarcodeIndex.value, 1);
    selectedMalformedBarcode.value = null;
}

function clear() {
    barcodesToUpload.value = [];
    noBarcodesToUpload.value = [];
    malformedBarcodes.value = [];
    barcodeToUpload.value = null;
}

async function addStyleColorNOBC() {
    // Attempt to parse a "STYLE<space>COLOR". If valid, add to NOBC
    let nonResolvedMalformedBarcodes = [];
    for(let malformedBarcode of malformedBarcodes.value) {
        const malformedBarcodeSplit = malformedBarcode.barcode.replace(/\s+/g, ' ').trim().split(' ');
        try {
            await getStyle(malformedBarcodeSplit[0]);
        } catch(e) {
            nonResolvedMalformedBarcodes.push({
                ...malformedBarcode,
                'status_conversion_nobc': 'Style not valid (as NOBC).'
            });
            continue;
        }
        try {
            await getColor(encodeURIComponent(malformedBarcodeSplit[1]));
        } catch(e) {
            nonResolvedMalformedBarcodes.push({
                ...malformedBarcode,
                'status_conversion_nobc': 'Color not valid (as NOBC)'
            });
            continue;
        }
        noBarcodesToUpload.value.push({
            index: noBarcodesToUpload.value.length + 1,
            STYLE: malformedBarcodeSplit[0],
            COLOR: malformedBarcodeSplit[1],
        });
    }
    malformedBarcodes.value = nonResolvedMalformedBarcodes;
    window.alert('Successfully transformed valid STYLE-COLOR to NOBC entries.');
}

async function addToInventoryCheck() {
    isAddingToInventoryCheck.value = true;
    try {
        const response = await postMultipleInventoryChecks(
            props.inventoryCheckDescription.LOCATION,
            props.inventoryCheckDescription.INVENTORY_DONE,
            barcodesToUpload.value.map((barcodeEntry) => barcodeEntry.RAW),
            noBarcodesToUpload.value);
        
        barcodesToUpload.value = [];
        noBarcodesToUpload.value = [];

        emit('success',
            response.data.valid_barcodes.map((barcode) => ({ RAW: barcode })), // Saved Barcodes
            response.data.no_barcodes, // Saved no barcodes
            response.data.duplicate_barcodes.map((barcode) => ({ RAW: barcode }))); // Duplicate Barcodes

        barcodeToUploadErrors.value = null;
    } catch(e) {
        emit('error', e.message);
    }
    isAddingToInventoryCheck.value = false;
}
</script>
