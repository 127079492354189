import { ref } from "vue";

import constructFetchError from "./constructFetchError";

import axios from 'axios';

export function useEmployeeSoldFromOtherStores() {
    const employeeSoldFromOtherStores = ref(null);

    const getEmployeeSoldFromOtherStores = async (employeeId, startTimePeriod, endTimePeriod, location) => {
        try {
            const getEmployeeSoldFromOtherStoresResponse = await axios.get(route('api.employees.selling-months-from-other-stores.index', {
                employee: employeeId,
                LOCATION: location,
            }), {
                params: {
                    START_TIME_PERIOD: startTimePeriod,
                    END_TIME_PERIOD: endTimePeriod,
                }
            });
            employeeSoldFromOtherStores.value = getEmployeeSoldFromOtherStoresResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching employee commissions.');
        }
    };

    return { employeeSoldFromOtherStores, getEmployeeSoldFromOtherStores };
}
