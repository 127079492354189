import { ref } from "vue";

import constructFetchError from "./constructFetchError";

import axios from 'axios';

export function useReconRawFinalFulls() {
    const reconRawFinalFulls = ref(null);
    const totalRecords = ref(null);

    const getReconRawFinalFulls = async (params) => {
        try {
            const getReconRawFinalFullsResponse = await axios.get(route('api.recon-raw-final-fulls.index', params));
            reconRawFinalFulls.value = getReconRawFinalFullsResponse.data.data;
            totalRecords.value = getReconRawFinalFullsResponse.data.meta.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching recon summaries.');
        }
    };
    
    return { reconRawFinalFulls, totalRecords, getReconRawFinalFulls };
}
