<template>
    <form @submit.prevent="createSample">
        <Card :class="class">
            <template #header>
                Create or Update Sample
            </template>
            <FormInput type="text" v-model="sample.LEGACY_SAMPNO"
                label="Legacy Sample No." is-horizontal
                id-prefix="legacySampNo" class="mb-3"
                :errors="errors.LEGACY_SAMPNO"
                @keydown-enter="focusnext('descriptionFormInput')" />
            <FormInput type="text" v-model="sample.DESCRIPTION"
                label="Description" is-horizontal
                id-prefix="description" class="mb-3"
                :errors="errors.DESCRIPTION"
                @keydown-enter="focusnext('dateCreatedFormInput')" />
            <FormInput type="text" v-model="sample.ACCOUNT_ID"
                label="Account ID" is-horizontal disabled
                id-prefix="accountID" class="mb-1"
                :form-text="sample.account?.ACCOUNT_NAME"
                :errors="errors.ACCOUNT_ID" />
            <div class="text-end mb-3">
                <button type="button" class="btn btn-primary"
                    @click="_ => isSelectingAccount = true">
                    Search
                </button>
            </div>
            <FormInput type="date" v-model="sample.DATE_CREATED"
                label="Date Created" is-horizontal
                id-prefix="dateCreated" class="mb-3"
                :errors="errors.DATE_CREATED"
                @keydown-enter="focusnext('dateFinishedFormInput')" />
            <FormInput type="date" v-model="sample.DATE_FINISHED"
                label="Date Finished" is-horizontal
                id-prefix="dateFinished" class="mb-3"
                :errors="errors.DATE_FINISHED"
                @keydown-enter="focusnext('quantityFormInput')" />
            <FormInput type="text" v-model="sample.MEASURED_BY"
                label="Measured By" is-horizontal disabled
                id-prefix="measuredBy" class="mb-1"
                :form-text="`${sample.measured_by?.FNAME ?? ''} ${sample.measured_by?.LNAME ?? ''}`"
                :errors="errors.MEASURED_BY" />
            <div class="text-end mb-3">
                <button type="button" class="btn btn-primary"
                    @click="_ => isSelectingMeasuredBy = true">
                    Search
                </button>
            </div>
            <FormInput type="text" v-model="sample.SAMPLE_MAKER"
                label="Sample Maker" is-horizontal disabled
                id-prefix="sampleMaker" class="mb-1"
                :form-text="`${sample.sample_maker?.FNAME ?? ''} ${sample.sample_maker?.LNAME ?? ''}`"
                :errors="errors.SAMPLE_MAKER" />
            <div class="text-end mb-3">
                <button type="button" class="btn btn-primary"
                    @click="_ => isSelectingSampleMaker = true">
                    Search
                </button>
            </div>
            <FormInput type="text" v-model="sample.CREATED_BY"
                label="Created By" is-horizontal disabled
                id-prefix="createdBy" class="mb-3"
                :errors="errors.CREATED_BY" />
            <FormInput type="number" step=".01" v-model="sample.LENGTH"
                label="Length" is-horizontal class="mb-3"
                id-prefix="length"
                :errors="errors.LENGTH"
                @keydown-enter="focusnext('widthFormInput')" />
            <FormInput type="number" step=".01" v-model="sample.WIDTH"
                label="Width" is-horizontal class="mb-3"
                id-prefix="width"
                :errors="errors.WIDTH"
                @keydown-enter="focusnext('heightFormInput')" />
            <FormInput type="number" step=".01" v-model="sample.HEIGHT"
                label="Height" is-horizontal
                id-prefix="height"
                :errors="errors.HEIGHT"
                @keydown-enter="_ => {
                    if(!sample.SAMPLE_ID) focusnext('insertSampleButton');
                    else focusnext('updateSampleButton');
                }" />
            <template #footer>
                <div class="text-end">
                    <button type="button" class="btn btn-outline-secondary me-2"
                        @click="reset">
                        Reset
                    </button>
                    <button type="submit" class="btn btn-primary me-2"
                        id="insertSampleButton"
                        :disabled="sample.SAMPLE_ID">
                        Insert
                    </button>
                    <button type="button" class="btn btn-primary me-2"
                        id="updateSampleButton"
                        :disabled="!sample.SAMPLE_ID"
                        @click="updateSample">
                        Update
                    </button>
                </div>
            </template>
        </Card>
    </form>

    <SelectAccount :is-open="isSelectingAccount"
        :on-close="_ => isSelectingAccount = false"
        :on-select-account="selectedAccount => {
            sample.account = selectedAccount;
            sample.ACCOUNT_ID = selectedAccount.ACCOUNT_ID;
        }" />
    <!-- Measured By -->
    <SelectEmployee :is-open="isSelectingMeasuredBy"
        :on-close="_ => isSelectingMeasuredBy = false"
        :on-select-employee="selectedEmployee => {
            sample.measured_by = selectedEmployee;
            sample.MEASURED_BY = selectedEmployee.EMPID;
        }" />
    <!-- Sample Maker -->
    <SelectEmployee :is-open="isSelectingSampleMaker"
        :on-close="_ => isSelectingSampleMaker = false"
        :on-select-employee="selectedEmployee => {
            sample.sample_maker = selectedEmployee;
            sample.SAMPLE_MAKER = selectedEmployee.EMPID;
        }" />
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';

import { useLoggedInUser } from '@/composables/data/loggedInUser';
import { useSamples } from '@/composables/data/samples';

import Card from '@/components/utils/Card.vue';
import FormInput from '@/components/utils/FormInput.vue';

import SelectAccount from '@/components/utils/SelectAccount.vue';
import SelectEmployee from '@/components/utils/SelectEmployee.vue';

import focusnext from '@/utils/focusnext';

const props = defineProps({
    selectedSample: Object,
    class: String
});

const emit = defineEmits(['success', 'reset', 'error']);

const { postSample, putSample } = useSamples();

const isSelectingAccount = ref(false);
const isSelectingMeasuredBy = ref(false);
const isSelectingSampleMaker = ref(false);

const { loggedInUser, getLoggedInUser } = useLoggedInUser();

const errors = ref({});

const sample = ref({
    LEGACY_SAMPNO: null,
    DESCRIPTION: null,
    ACCOUNT_ID: 0,
    DATE_CREATED: null,
    DATE_FINISHED: null,
    MEASURED_BY: 0,
    SAMPLE_MAKER: 0,
    CREATED_BY: null,
    LENGTH: null,
    WIDTH: null,
    HEIGHT: null,
});

onMounted(async() => {
    try {
        await getLoggedInUser();
        sample.value.CREATED_BY = loggedInUser.value.name_from_email;
    } catch(e) {
        emit('error', e.message);
    }
});

watch(() => props.selectedSample, () => {
    if(!props.selectedSample) {
        reset();
        return;
    }

    sample.value = props.selectedSample;
});

function reset() {
    errors.value = {};
    sample.value = {
        LEGACY_SAMPNO: null,
        DESCRIPTION: null,
        ACCOUNT_ID: 0,
        DATE_CREATED: null,
        DATE_FINISHED: null,
        MEASURED_BY: 0,
        SAMPLE_MAKER: 0,
        CREATED_BY: loggedInUser.value.name_from_email,
        LENGTH: null,
        WIDTH: null,
        HEIGHT: null,
    };
    emit('reset');
}

async function createSample() {
    errors.value = {};

    try {
        const savedSample = await postSample(sample.value);
        sample.value = savedSample;
        emit('success', 'Successfully updated sample.', sample.value);
    } catch(e) {
        emit('error', e.message);
        errors.value = e.errors;
    }
}

async function updateSample() {
    errors.value = {};

    try {
        const savedSample = await putSample(sample.value.SAMPLE_ID, sample.value);
        sample.value = savedSample;
        emit('success', 'Successfully updated sample.', sample.value);
    } catch(e) {
        emit('error', e.message);
        errors.value = e.errors;
    }
}
</script>
