<style scoped>
.wrapper {
    width: 100%;
    height: 15rem;
    overflow-y: auto;
    background-color: #FFF;
}

.wrapper table thead {
    position: sticky;
    top: 0;
    background-color: var(--bs-card-bg);
}
.cursor-pointer {
    cursor: pointer;
}
</style>
<template>
    <Card>
        <template #header>
            PRICE
        </template>
        <div class="row">
            <div class="col-md-7">
                <div class="wrapper">
                    <table class="table table-bordered table-hover">
                        <thead>
                            <tr>
                                <th>DATE_EFF</th>
                                <th>PRICE</th>
                                <th>CREATED BY</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="price in accountItemPriceList"
                                :class="{ 'bg-primary text-white': price.id === accountItemPrice.id }"
                                @click="selectAccountItemPrice(price)"
                                class="cursor-pointer"
                            >
                                <td>{{ price.DATE_EFF }}</td>
                                <td>{{ price.PRICE }}</td>
                                <td>{{ price.CREATED_BY }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-md-5">
                <div class="d-flex flex-column">
                    <FormInput type="date" label="DATE EFF:"
                        is-horizontal id-prefix="dateEFF" class="mb-1"
                        input-class="form-control-sm"
                        v-model="accountItemPrice.DATE_EFF"
                        :errors="errors.DATE_EFF"
                        :disabled="disableControl"/>
                    <FormInput type="text" label="PRICE:"
                        is-horizontal id-prefix="price" class="mb-1"
                        input-class="form-control-sm"
                        v-model="accountItemPrice.PRICE"
                        :errors="errors.PRICE"
                        :disabled="disableControl"/>
                    <button class="btn btn-primary w-50 mb-1"
                        :disabled="disableControl"
                        :hidden="accountItemPrice.id !== null"
                        @click="handleInsertAccountItemPrice">INSERT</button>
                    <button class="btn btn-primary w-50 mb-1"
                        :disabled="disableControl"
                        :hidden="accountItemPrice.id === null"
                        @click="handleUpdateAccountItemPrice">UPDATE</button>
                    <button class="btn btn-primary w-50 mb-1"
                        :disabled="disableControl"
                        @click="handleResetAccountItemPrice">RESET</button>
                </div>
            </div>
        </div>
    </Card>
</template>

<script setup>
import { ref, watch } from "vue";

import Card from "../../../utils/Card.vue";
import FormInput from "../../../utils/FormInput.vue";

import scrollToTop from "../../../../utils/scrollToTop";

const props = defineProps({
    item: Object,
    account: Object
});

const emit = defineEmits(['success', 'error']);

const errors = ref({});

const accountItemPriceList = ref([]);
const accountItemPrice = ref({
    id: null,
    DATE_EFF: null,
    PRICE: null
});

const disableControl = ref(true);

watch(() => props.item, async() => {
    disableControls(props.item.id);
    handleResetAccountItemPrice();
    getAccountItemPriceList();
});

function selectAccountItemPrice(price) {
    accountItemPrice.value = {
        id: price.id,
        DATE_EFF: price.DATE_EFF,
        PRICE: price.PRICE
    }
}

async function getAccountItemPriceList() {
    if (props.account.ACCOUNT_ID === 'New Emp') {
        accountItemPriceList.value = [];
        handleResetAccountItemPrice();
        return;
    }
    if (props.item.id === null) {
        accountItemPriceList.value = [];
        handleResetAccountItemPrice();
        return;
    }

    try {
        const response = await axios.get(route('api.account-item-prices.index'), {
            params: {
                ITEM_NAME: props.item.ITEM_NAME,
                ACCOUNT_ID: props.account.ACCOUNT_ID,
            }
        });
        accountItemPriceList.value = response.data.data;
    } catch(e) {
        console.log(e);
        scrollToTop();
        emit('error', 'An error occurred while fetching account item prices. ' +
            'Please contact your administrator.');
    }
}

async function handleInsertAccountItemPrice() {
    try {
        const response = await axios.post(route('api.account-item-prices.store'), {
            ACCOUNT_ID: props.account.ACCOUNT_ID,
            ITEM_NAME: props.item.ITEM_NAME,
            DATE_EFF: accountItemPrice.value.DATE_EFF,
            PRICE: accountItemPrice.value.PRICE,
        })
        errors.value = {};
        scrollToTop();
        emit('success', response.data);
        getAccountItemPriceList();
        handleResetAccountItemPrice();
    } catch (e) {
        console.log(e);
        if(e.response && e.response.status == 422) {
            errors.value = e.response.data.errors;
        } else {
            scrollToTop();
            emit('error', `An error occurred while saving account item price. Please contact your administrator.`);
        }
    }
}

async function handleUpdateAccountItemPrice() {
    try {
        const response = await axios.patch(route('api.account-item-prices.update', { id: accountItemPrice.value.id }), {
            DATE_EFF: accountItemPrice.value.DATE_EFF,
            PRICE: accountItemPrice.value.PRICE,
        })
        errors.value = {};
        scrollToTop();
        emit('success', response.data);
        getAccountItemPriceList();
    } catch (e) {
        console.log(e);
        if(e.response && e.response.status == 422) {
            errors.value = e.response.data.errors;
        } else {
            scrollToTop();
            emit('error', `An error occurred while saving account item price. Please contact your administrator.`);
        }
    }
}

function disableControls(id) {
    if(id === null)
        disableControl.value = true;
    else
        disableControl.value = false;
}

function handleResetAccountItemPrice() {
    accountItemPrice.value = {
        id: null,
        DATE_EFF: null,
        PRICE: null
    }
}
</script>
