<template>
    <div v-if="isOpen" class="pop-up d-flex justify-content-center align-items-center">
        <Card>
            <template #header>
                <div class="d-flex justify-content-between pt-2">
                    <h4 class="title">Voucher Images</h4>
                    <button type="button" class="btn-close" @click="emit('close')"></button>
                </div>
            </template>
            <div v-if="isLoading" class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
            <div v-else-if="voucherImages?.length > 0" class="row g-3">
                <div v-for="(imageURL, index) in imageURLs" class="col-md-4 text-center">
                    <p v-if="imageLoadingStatuses[index]">
                        <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        Loading image #{{ index + 1 }}
                    </p>
                    <img :src="imageURL" :alt="voucherId + ' voucher image ' + index"
                        @load="_ => { imageLoadingStatuses[index] = false; }"
                        class="img-fluid"
                        @error="_ => { imageLoadingStatuses[index] = false; }" />
                </div>
            </div>
            <div v-else class="text-danger text-center">
                Nothing uploaded.
            </div>
        </Card>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';

import { useVoucherImages } from '@/composables/data/voucherImages';

import Card from '@/components/utils/Card.vue';

const props = defineProps({
    voucherId: String,
    isOpen: Boolean,
});

const emit = defineEmits(['close', 'error']);

const { voucherImages, getVoucherImages } = useVoucherImages();

const isLoading = ref(false);

const imageLoadingStatuses = ref(null);
const imageURLs = ref(null);

async function fetchImages() {
    voucherImages.value = null;

    if(!props.voucherId) {
        return;
    }

    isLoading.value = true;
    try {
        await getVoucherImages(props.voucherId);
        setTimeout(() => {
            imageURLs.value = voucherImages.value.map(voucherImage => voucherImage.url);
            imageLoadingStatuses.value = voucherImages.value.map(_ => true);
        });
    } catch(e) {
        emit('error', e.message);
    }
    isLoading.value = false;
}

watch(() => [props.isOpen, props.voucherId], () => {
    if(props.isOpen) {
        fetchImages();
    }
});
</script>

<style scoped>
.pop-up {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: rgb(0, 0, 0, 0.5);
}

.pop-up .card {
    width: 100%;
}

@media (min-width: 768px) {
    .pop-up .card {
        width: 85%;
    }
}

@media (min-width: 992px) {
    .pop-up .card {
        width: 70%;
    }
}
</style>
