<template>
    <Card :class="class">
        <template #header>
            Style Price
        </template>
        <DataTable :value="stylePrices" :pt="{
                table: {
                    class: 'table table-bordered table-hover'
                }
            }">
            <Column field="STYLE" header="Style" />
            <Column field="START_DATE" header="Start Date" />
            <Column field="END_DATE" header="End Date" />
            <Column field="SM_PRICE" header="SM Price" />
            <Column field="LM_REG_PRICE" header="LM Reg Price" />
            <Column field="LM_SALE_PRICE" header="LM Sale Price" />
            <Column field="COMMENT" header="Comment" />
            <template #empty>
                <template v-if="style == null">
                    Please select a Style first.
                </template>
                <template v-else>
                    There are no prices for this Style.
                </template>
            </template>
        </DataTable>
        <form @submit.prevent="saveStylePrice">
            <div class="row g-3 mb-3">
                <div class="col-6">
                    <FormInput type="date" label="Start Date"
                        v-model="stylePrice.START_DATE"
                        is-horizontal id-prefix="startDate" class="mb-3"
                        input-class="form-control-sm"
                        :errors="errors.START_DATE"
                        @keydown-enter="focusNextInput('smPriceFormInput')" />
                    <FormInput type="number" step=".01" label="SM Price"
                        v-model="stylePrice.SM_PRICE"
                        is-horizontal id-prefix="smPrice"
                        input-class="form-control-sm"
                        :errors="errors.SM_PRICE"
                        @keydown-enter="focusNextInput('lmRegPriceFormInput')" />
                </div>
                <div class="col-6">
                    <FormInput type="number" step=".01" label="LM Reg Price"
                        v-model="stylePrice.LM_REG_PRICE"
                        is-horizontal id-prefix="lmRegPrice" class="mb-3"
                        input-class="form-control-sm"
                        :errors="errors.LM_REG_PRICE"
                        @keydown-enter="focusNextInput('lmSalesPriceFormInput')" />
                    <FormInput type="number" step=".01" label="LM Sale Price"
                        v-model="stylePrice.LM_SALE_PRICE"
                        is-horizontal id-prefix="lmSalesPrice"
                        input-class="form-control-sm"
                        :errors="errors.LM_SALE_PRICE"
                        @keydown-enter="focusNextInput('saveStylePriceButton')" />
                </div>
            </div>
            <button type="submit" class="btn btn-primary" id="saveStylePriceButton"
                :disabled="style == null">
                Save Price
            </button>
        </form>
    </Card>
</template>

<script setup>
import { ref, watch } from 'vue';

import Card from '../../../utils/Card.vue';
import FormInput from '../../../utils/FormInput.vue';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';

import axios from 'axios';

import focusNextInput from '../../../../utils/focusnext';
import handleFetchErrors from "../../../../utils/handleFetchErrors";
import scrollToTop from "../../../../utils/scrollToTop";

import { useFlashMessages } from "../../../../composables/flashmessages";

const { addFlashMessage } = useFlashMessages();

const props = defineProps({
    style: Object,
    class: String
});

const errors = ref({});

const stylePrices = ref();

const stylePrice = ref({
    START_DATE: null,
    SM_PRICE: null,
    LM_REG_PRICE: null,
    LM_SALE_PRICE: null
});

async function loadStylePrices() {
    try {
        const stylePricesResponse = await axios.get(route('api.styles.style-prices.index', {
            style: props.style.STYLE
        }));
        stylePrices.value = stylePricesResponse.data.data;
    } catch(e) {
        console.log(e);
        handleFetchErrors(e, 'Error while fetching style prices.');
        scrollToTop();
    }
}

watch(() => props.style, () => {
    stylePrices.value = null;
    stylePrice.value = {
        START_DATE: null,
        SM_PRICE: null,
        LM_REG_PRICE: null,
        LM_SALE_PRICE: null
    };
    errors.value = {};
    if(props.style == null)
        return;
    loadStylePrices();
});

async function saveStylePrice() {
    errors.value = {};
    try {
        await axios.post(route('api.styles.style-prices.store', {
            style: props.style.STYLE
        }), stylePrice.value);
        addFlashMessage('SUCCESS', 'Successfully saved style price.');
        stylePrice.value = {
            START_DATE: null,
            SM_PRICE: null,
            LM_REG_PRICE: null,
            LM_SALE_PRICE: null
        };
        loadStylePrices();
    } catch(e) {
        console.log(e);
        errors.value = handleFetchErrors(e, 'Error while saving style price.');
    }
    scrollToTop();
}
</script>
