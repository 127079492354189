<template>
    <form @submit.prevent="fetchTopList">
        <Modal id="bestsellersStyleModal" dialog-class="modal-xl">
            <template #header>
                <h5 class="modal-title">Bestsellers Style</h5>
            </template>
            <div class="row">
                <div class="col-md-7">
                    <div v-if="isFetching">
                        <div class="spinner-border me-2" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        Fetching bestseller styles...
                    </div>
                    <DataTable v-else :value="bestsellers" paginator :rows="10"
                        :pt="{ table: { class: 'table table-bordered table-hover' } }">
                        <Column field="rank" header="Rank" />
                        <Column field="style" header="Style" />
                        <Column field="total_sold" header="Total Sold" />
                        <Column field="total_in_warehouse" header="Count in MAIN_1" />
                        <template #empty>
                            <div class="text-center">No bestsellers.</div>
                        </template>
                    </DataTable>
                </div>
                <div class="col-md-5">
                    <FormSelect label="Style Type" v-model="bestsellerFilters.style_type"
                        id-prefix="styleTypeFilter"
                        :options="styleTypes"
                        :option-value="styleType => styleType.STYLE_TYPE"
                        :option-to-string="styleType => styleType.STYLE_TYPE"
                        class="mb-3"
                        :errors="filterErrors.style_type"
                        @keydown-enter="focusNext('startDateFilterFormInput')" />
                    <FormInput type="date" label="Start Date" v-model="bestsellerFilters.start_date"
                        id-prefix="startDateFilter" class="mb-3"
                        :errors="filterErrors.start_date"
                        @keydown-enter="focusNext('endDateFilterFormInput')" />
                    <FormInput type="date" label="End Date" v-model="bestsellerFilters.end_date"
                        id-prefix="endDateFilter" class="mb-3"
                        :errors="filterErrors.end_date"
                        @keydown-enter="focusNext('topLimitFilterFormInput')" />
                    <FormInput type="number" label="Top Limit" v-model="bestsellerFilters.limit"
                        id-prefix="topLimitFilter" class="mb-3"
                        :errors="filterErrors.limit"
                        @keydown-enter="focusNext('viewTopListButton')" />
                </div>
            </div>
            <template #footer>
                <button type="submit" class="btn btn-primary" id="viewTopListButton">View Top List</button>
            </template>
        </Modal>
    </form>
</template>

<script setup>
import { onMounted, ref } from "vue";

import FormInput from "../../../utils/FormInput.vue";
import FormSelect from "../../../utils/FormSelect.vue";
import Modal from "../../../utils/Modal.vue";

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';

import focusNext from "../../../../utils/focusnext.js";
import handleFetchErrors from "../../../../utils/handleFetchErrors";

const isFetching = ref(false);

const filterErrors = ref({});
const bestsellerFilters = ref({
    style_type: null,
    start_date: null,
    end_date: null,
    limit: 5
});

const styleTypes = ref(null);

const bestsellers = ref(null);

async function fetchStyleTypes() {
    try {
        const styleTypesResponse = await axios.get(route('api.styles.types.index'));
        styleTypes.value = styleTypesResponse.data.data;
    } catch(e) {
        console.log(e);
        handleFetchErrors(e, 'Error while fetching style types.');
    }
}

onMounted(fetchStyleTypes);

async function fetchTopList() {
    isFetching.value = true;
    filterErrors.value = {};
    try {
        const bestsellersResponse = await axios.get(
            route('api.reports.inventory.bestseller-styles', bestsellerFilters.value));
        bestsellers.value = bestsellersResponse.data.data;
    } catch(e) {
        console.log(e);
        filterErrors.value = handleFetchErrors(e, 'Error while fetching bestsellers.');
    }
    isFetching.value = false;
}
</script>
