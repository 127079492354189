<template>
    <div class="row g-3 mb-3">
        <div class="col-md-3">
            <LocationSelectTableCard v-model="selectedLocation" />
        </div>
        <div class="col-md-9">
            <InventoryCheckDescriptionsTableCard
                :location-filter="selectedLocation?.LOCATION"
                :id-filter="idFilter"
                v-model="selectedInventoryCheckDescription">
                <template #footer>
                    <div class="text-end">
                        <button type="button" class="btn btn-primary me-1"
                            :disabled="!selectedInventoryCheckDescription"
                            @click="exportInventoryChecks">
                            Export Inventory Checks
                        </button>
                        <button type="button" class="btn btn-primary"
                            :disabled="!selectedLocation"
                            @click="_ => { isCreatingInventoryCheckDescription = true; }">
                            Create Inventory Check Description
                        </button>
                    </div>
                </template>
            </InventoryCheckDescriptionsTableCard>
        </div>
    </div>

    <UploadBarcodesToInventoryCheckTableCards
        :inventory-check-description="selectedInventoryCheckDescription"
        @success="(savedBarcodesReturned, savedNoBarcodesReturned, duplicateBarcodesReturned) => {
            addFlashMessage('SUCCESS', 'Successfully saved barcodes.');
            if(duplicateBarcodesReturned && duplicateBarcodesReturned.length > 0)
                addFlashMessage('WARNING', 'There are DUPLICATE barcodes, please check.');
            savedBarcodes = savedBarcodesReturned;
            savedNoBarcodes = savedNoBarcodesReturned;
            duplicateBarcodes = duplicateBarcodesReturned;
            scrollToTop();
        }"
        @error="message => {
            addFlashMessage('ERROR', message);
            scrollToTop();
        }"
        class="mb-3" />

    <hr class="mb-3" />

    <div class="row g-3">
        <div class="col-md-4">
            <ResultBarcodesTableCard :barcodes="savedBarcodes"
                title="Saved Barcodes" />
        </div>
        <div class="col-md-4">
            <ResultNoBarcodesTableCard :noBarcodes="savedNoBarcodes" />
        </div>
        <div class="col-md-4">
            <ResultBarcodesTableCard :barcodes="duplicateBarcodes"
                title="Duplicate Barcodes (exists already or inputted twice)" />
        </div>
    </div>

    <CreateInventoryCheckDescriptionPopup :location="selectedLocation?.LOCATION"
        :is-open="isCreatingInventoryCheckDescription"
        @success="inventoryCheckDescription => {
            selectedInventoryCheckDescription = inventoryCheckDescription;
            idFilter = inventoryCheckDescription.id;
            isCreatingInventoryCheckDescription = false;
            addFlashMessage('SUCCESS', 'Successfully saved inventory check description.');
            scrollToTop();
        }"
        @error="message => {
            addFlashMessage('ERROR', message);
            scrollToTop();
        }"
        @close="_ => { isCreatingInventoryCheckDescription = false; }" />
</template>

<script setup>
import { ref } from 'vue';

import { useFlashMessages } from '@/composables/flashmessages';

import LocationSelectTableCard from '@/components/utils/LocationSelectTableCard.vue';
import InventoryCheckDescriptionsTableCard from './InventoryCheckDescriptionsTableCard.vue';
import CreateInventoryCheckDescriptionPopup from './CreateInventoryCheckDescriptionPopup.vue';
import UploadBarcodesToInventoryCheckTableCards from './UploadBarcodesToInventoryCheckTableCards.vue';
import ResultBarcodesTableCard from './ResultBarcodesTableCard.vue';
import ResultNoBarcodesTableCard from './ResultNoBarcodesTableCard.vue';

import scrollToTop from '@/utils/scrollToTop';

const { addFlashMessage } = useFlashMessages();

const selectedLocation = ref(null);

const idFilter = ref(null);

const selectedInventoryCheckDescription = ref(null);

const isCreatingInventoryCheckDescription = ref(false);

const savedBarcodes = ref(null);
const savedNoBarcodes = ref(null);
const duplicateBarcodes = ref(null);

function exportInventoryChecks() {
    window.open(route('api.inventory-check-descriptions.report.show', {
        inventory_check_description: selectedInventoryCheckDescription.value.id
    }));
}
</script>
