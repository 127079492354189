<template>
    <Card :class="class">
        <template #header>
            SKUs to Pullout
        </template>
        <DataTable :value="skusToPullout" paginator :rows="10" 
            :pt="{ table: { class: ' table table-bordered table-hover' } }">
            <Column field="style" header="Style" />
            <Column field="color" header="Color" />
            <Column field="sku" header="SKU" />
            <Column field="quantity" header="Qty." />
            <Column field="box" header="Box" />
            <template #empty>
                <div class="text-center">No styles.</div>
            </template>
        </DataTable>
        <template #footer>
            <div class="text-end">
                <button type="button" class="btn btn-outline-secondary"
                    @click="emit('clear')">Clear</button>
            </div>
        </template>
    </Card>
</template>

<script setup>
import Card from '@/components/utils/Card.vue';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';

const props = defineProps({
    skusToPullout: Array,
    class: String
});

const skusToPullout = defineModel();

function clearSkusToPullout() {
    skusToPullout.value = [];
}
</script>
