<template>
    <Card>
        <template #header>
            Uploaded Files
        </template>
        <DataTable :loading="isLoading" lazy
            :value="uploadedRemittanceAdviceFileCollections" :total-records="totalRecords"
            paginator :rows="10" @page="e => { onPage(e); fetchUploadedRemittanceAdviceFileCollections(); }"
            filter-display="row" v-model:filters="filters" @filter="tableOnFilter"
            sort-mode="multiple" @sort="e => { onSort(e); fetchUploadedRemittanceAdviceFileCollections(); }"
            v-model:selection="selectedUploadedRemittanceAdviceFileCollection" selection-mode="single"
            :pt="{ table: { class: 'table table-bordered table-hover' } }">
            <Column field="time_period" header="Time Period" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value"
                        @input="filterCallback"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="si_file_name" header="SI File" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value"
                        @input="filterCallback"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="dm_file_name" header="DM File" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value"
                        @input="filterCallback"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="main_file_name" header="Main File" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value"
                        @input="filterCallback"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="status" header="Status" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value"
                        @input="filterCallback"
                        class="form-control" placeholder="Search" />
                </template>
                <template #body="{ data }">
                    <span v-if="data.status == 'SUCCESS'" class="badge bg-success">
                        Success
                    </span>
                    <span v-else-if="data.status == 'WARNING'" class="badge bg-warning">
                        Warning
                    </span>
                    <span v-else-if="data.status == 'ERROR'" class="badge bg-danger">
                        Error
                    </span>
                </template>
            </Column>
            <template #empty>
                <div class="text-center">
                    No data.
                </div>
            </template>
        </DataTable>
        <template #footer>
            <div class="text-end">
                <button type="button" class="btn btn-primary me-1"
                    :disabled="!selectedUploadedRemittanceAdviceFileCollection"
                    @click="_ => { isViewDetailsPopupOpen = true; }">
                    View Details
                </button>
                <button type="button" class="btn btn-primary"
                    :disabled="!selectedUploadedRemittanceAdviceFileCollection"
                    @click="downloadFiles">
                    Download Files
                </button>
            </div>
        </template>
    </Card>
    <ViewDetailsPopup :is-open="isViewDetailsPopupOpen"
        @close="_ => { isViewDetailsPopupOpen = false; }"
        :details="selectedUploadedRemittanceAdviceFileCollection?.details != null ?
            JSON.parse(selectedUploadedRemittanceAdviceFileCollection.details) : null" />
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';

import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useUploadedRemittanceAdviceFileCollections } from '@/composables/data/uploadedRemittanceAdviceFileCollections';

import Card from '@/components/utils/Card';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

import ViewDetailsPopup from './ViewDetailsPopup.vue';

const props = defineProps({
    refreshFlag: null,
});

const emit = defineEmits(['error']);

const { params, filters, onPage, onFilter, onSort } = useDataTableParams([
    'time_period', 'si_file_name', 'dm_file_name', 'main_file_name', 'status'
]);

const { uploadedRemittanceAdviceFileCollections, totalRecords, getUploadedRemittanceAdviceFileCollections } =
    useUploadedRemittanceAdviceFileCollections();

const selectedUploadedRemittanceAdviceFileCollection = ref(null);

const isLoading = ref(false);

const fetchTimeout = ref(null);

const isViewDetailsPopupOpen = ref(false);

async function fetchUploadedRemittanceAdviceFileCollections() {
    uploadedRemittanceAdviceFileCollections.value = null;

    isLoading.value = true;
    try {
        await getUploadedRemittanceAdviceFileCollections(params.value);
    } catch(e) {
        emit('error', e.message);
    }
    isLoading.value = false;
}

function tableOnFilter(e) {
    onFilter(e);
    if(fetchTimeout.value)
        clearTimeout(fetchTimeout.value);
    fetchTimeout.value = setTimeout(fetchUploadedRemittanceAdviceFileCollections, 300);
}

onMounted(fetchUploadedRemittanceAdviceFileCollections);
watch(() => props.refreshFlag, fetchUploadedRemittanceAdviceFileCollections);

function downloadFiles() {
    window.open(route('api.uploaded-remittance-advice-file-collections.files-zip.show', {
        file_collection: selectedUploadedRemittanceAdviceFileCollection.value.id
    }));
}
</script>
