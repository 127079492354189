<template>

<Card :class="class">
        <template #header>
            A. INVENTORY_CHECK (in recon, same month)
        </template>
        <DataTable :loading="isTableLoading" lazy :value="inventoryChecksInReconSameMonth"
            :total-records="inventoryChecksInReconSameMonthTotalRecords"
            paginator :rows="10" @page="e => { onPage(e); fetchInReconSameMonth(); }"
            v-model:filters="filters" filter-display="row" @filter="tableOnFilter"
            sort-mode="multiple" @sort="e => { onSort(e); fetchInReconSameMonth(); }"
            v-model:selection="selectedRecord" selection-mode="single"
            :pt="{ table: { class: 'table table-sm table-bordered table-hover recon-table' } }">
            <Column field="RAW" header="Raw" sortable :pt="{
                bodyCell: { class: 'barcode' },
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="STYLE" header="Style" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="COLOR" header="Color" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <template #empty>
                <div class="text-center">
                    No data.
                </div>
            </template>
        </DataTable>
    </Card>
</template>

<script setup>
import { ref, watch } from 'vue';

import Card from '@/components/utils/Card.vue';

import { useFlashMessages } from '@/composables/flashmessages';
import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useInventoryChecks } from '@/composables/data/inventoryChecks';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

import { deconstructBarcode } from '@/utils/barcodes';

import scrollToTop from '@/utils/scrollToTop';

const props = defineProps({
    location: String,
    timePeriod: Number,
    inventoryDone: String,
    style: String,
    refreshFlag: Boolean,
    class: String,
});

const { addFlashMessage } = useFlashMessages();

const { params, filters, onPage, onFilter, onSort } = useDataTableParams(['STYLE', 'RAW', 'COLOR']);

const { inventoryChecksInReconSameMonth, inventoryChecksInReconSameMonthTotalRecords,
    getInventoryChecksInReconSameMonth } = useInventoryChecks();

const isTableLoading = ref(false);

const fetchTimeout = ref(null);

const selectedRecord = defineModel();
const selectedBagIdentifier = defineModel('selectedBagIdentifier');

async function fetchInReconSameMonth() {
    inventoryChecksInReconSameMonth.value = null;

    if(!props.location || !props.timePeriod || !props.inventoryDone || !props.style)
        return;

    isTableLoading.value = true;
    try {
        await getInventoryChecksInReconSameMonth(props.timePeriod, props.location, props.inventoryDone,
            props.style, params.value);
        selectedRecord.value = null;
    } catch(e) {
        addFlashMessage('ERROR', e.message);
        scrollToTop();
    }
    isTableLoading.value = false;
}

async function tableOnFilter(e) {
    onFilter(e);
    if(fetchTimeout.value)
        clearTimeout(fetchTimeout.value);
    fetchTimeout.value = setTimeout(fetchInReconSameMonth, 300);
}

watch(selectedRecord, () => {
    if(!selectedRecord.value)
        return;

    const barcode = deconstructBarcode(selectedRecord.value.RAW);
    selectedBagIdentifier.value = {
        STYLE: barcode.style,
        DATE: barcode.date,
        SERIAL: barcode.serial,
    };
});

watch(() => props.style, fetchInReconSameMonth);

watch(() => props.refreshFlag, fetchInReconSameMonth);
</script>
