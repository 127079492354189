<template>
    <Card class="mb-3">
        <template #header>
            Details
        </template>
        <div class="row g-3">
            <div class="col-md-4">
                <FormInput type="text" disabled
                    v-model="joborderNewJono"
                    label="JONO" id-prefix="jono" />
            </div>
            <div class="col-md-4">
                <FormInput type="text" disabled
                    v-model="joborderNewAccountId"
                    label="Account" id-prefix="accountId" />
            </div>
        </div>
    </Card>
    <OrdersByStyleCard :joborder-new="joborderNew" class="mb-3"
        :refresh-flag="orderDetailsTableRefreshFlag"
        @select="selectedDetail => { selectedDetailFromTable = selectedDetail; }">
        <template #footer>
            <div class="text-end">
                <button type="button" class="btn btn-primary"
                    @click="_ => { isViewingHistory = true; }">
                    View History
                </button>
            </div>
        </template>
    </OrdersByStyleCard>
    <div class="row g-3">
        <div class="col-md-10">
            <OrderDetailsFormCard :joborder-new="joborderNew"
                :detail-to-update="selectedDetailFromTable"
                @action-done="_ => { orderDetailsTableRefreshFlag = !orderDetailsTableRefreshFlag; }" />
        </div>
    </div>

    <JoborderDetailHistoryPopup :joborder-new="joborderNew"
        :refresh-flag="orderDetailsTableRefreshFlag"
        :is-open="isViewingHistory"
        @close="_ => { isViewingHistory = false; }" />
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';

import { useFlashMessages } from '@/composables/flashmessages';

import { useJobordersNew } from '@/composables/data/jobordersNew';

import JoborderDetailHistoryPopup from './JoborderDetailHistoryPopup.vue';

import Card from '@/components/utils/Card.vue';
import FormInput from '@/components/utils/FormInput.vue';

import OrderDetailsFormCard from './OrderDetailsFormCard.vue';
import OrdersByStyleCard from '../utils/OrdersByStyleCard.vue';

const { addFlashMessage } = useFlashMessages();

const { joborderNew, getJoborderNew } = useJobordersNew();

// Just serves as a value that the table component watches via prop for refreshing
// Flip the value to trigger refresh
const orderDetailsTableRefreshFlag = ref(false);

const joborderNewJono = computed(() => joborderNew.value?.JONO);
const joborderNewAccountId = computed(() => joborderNew.value?.ACCOUNT_ID);

const selectedDetailFromTable = ref(null);

const isViewingHistory = ref(false);

onMounted(async () => {
    try {
        await getJoborderNew(new URLSearchParams(window.location.search).get('JONO'));
    } catch(e) {
        if(e.status == 404)
            addFlashMessage('ERROR', 'Unknown Joborder. Please check.');
    }
});
</script>
