<template>
    <div v-if="isOpen" class="pop-up d-flex justify-content-center align-items-center">
        <form @submit.prevent="addNoBarcodeToInventoryCheck">
            <Card>
                <template #header>
                    <div class="d-flex justify-content-between pt-2">
                        <h4 class="title">Insert NOBC to Inventory Check</h4>
                        <button type="button" class="btn-close" @click="emit('close')"></button>
                    </div>
                </template>
                <FormInput type="text" v-model="locationDisplay" disabled
                    label="Style" id-prefix="locationDisplay"
                    is-horizontal class="mb-2" input-class="form-control-sm" />
                <FormInput type="text" v-model="inventoryDoneDisplay" disabled
                    label="Style" id-prefix="inventoryDoneDisplay"
                    is-horizontal class="mb-2" input-class="form-control-sm" />
                <FormInput type="text" v-model="styleDisplay" disabled
                    label="Style" id-prefix="styleDisplay"
                    is-horizontal class="mb-2" input-class="form-control-sm" />
                <FormSelect label="Color" v-model="color"
                    :options="colors" required
                    is-horizontal
                    id-prefix="color" select-class="form-select-sm" />
                <template #footer>
                    <div class="d-grid">
                        <button type="submit" class="btn btn-primary"
                            :disabled="!color">
                            Add to Inventory Check
                        </button>
                    </div>
                </template>
            </Card>
        </form>
    </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';

import { useColors } from '@/composables/data/colors';
import { useInventoryChecks } from '@/composables/data/inventoryChecks';

import Card from "@/components/utils/Card.vue";
import FormInput from "@/components/utils/FormInput.vue";
import FormSelect from "@/components/utils/FormSelect.vue";

const props = defineProps({
    location: String,
    inventoryDone: String,
    style: String,
    isOpen: Boolean
});

const emit = defineEmits(['close', 'done', 'error']);

const locationDisplay = computed(() => props.location);
const inventoryDoneDisplay = computed(() => props.inventoryDone)
const styleDisplay = computed(() => props.style);

const { colors, getActiveColors } = useColors();

const color = ref(null);

const { postInventoryCheck } = useInventoryChecks();

async function fetchColors() {
    colors.value = null;
    try {
        await getActiveColors();
        colors.value = colors.value.map(color => color.COLOR);
    } catch(e) {
        emit('error', e.message);
    }
}

onMounted(fetchColors);

async function addNoBarcodeToInventoryCheck() {
    try {
        await postInventoryCheck({
            LOCATION: props.location,
            INVENTORY_DONE: props.inventoryDone,
            STYLE: props.style,
            COLOR: color.value
        });
        emit('done', 'Successfully added NOBC to inventory check.');
    } catch(e) {
        emit('error', e.message);
    }
}
</script>

<style scoped>
.pop-up {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: rgb(0, 0, 0, 0.5);
}
</style>
